import React from "react";
import "./ShareInfo.css";
import {
  getShareURL,
  noop,
} from "../../../../utils/helpers/geralHelpers/RandomHelper";
import {
  Email,
  Facebook,
  Linkedin,
  Messenger,
  WhatsApp,
} from "../newComponents/NewSocialMediaIcons";
import { SHARE_RESULTS_PAGE_ID } from "../../../../utils/constants/constants";
import GuestVersion from "./ShareInfoGuestVersion";
import { useResize } from "../../../Geral/hooks/useResize";
import { triggerShareIconEvent } from "../../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: ShareInfo
 * This renders the share info section of the results
 *
 * @param {String} share_url
 * @param {Object} currentUser
 * @param {String} page
 * @param {Function} setFlashProps
 * @param {String} language
 * @param {Object} copies
 * @returns {Element}
 * */
const ShareInfo = ({
  share_url,
  currentUser,
  page,
  setFlashProps,
  language,
  copies,
}) => {
  if (page !== SHARE_RESULTS_PAGE_ID) {
    return (
      <UserVersion
        resultShareUrl={share_url}
        currentUser={currentUser}
        setFlashProps={setFlashProps}
        language={language}
        copies={copies.USER_VERSION}
      />
    );
  } else {
    return <GuestVersion page={page} copies={copies.GUEST_VERSION} />;
  }
};

/**
 * the user version is the one that is rendered for people that are logged in
 * @param {String} resultShareUrl
 * @param {Object} currentUser
 * @param {Function} setFlashProps
 * @param {Object} copies
 * @param {String} language
 * */
const UserVersion = ({
  resultShareUrl,
  currentUser,
  setFlashProps,
  copies,
  language,
}) => {
  //the url for app sharing
  const appShareUrl = getShareURL(currentUser, language);

  const [width] = useResize();

  //all the icons for shared results
  const ShareResultIcons = {
    facebook: (
      <Facebook
        goal="result"
        mainUrl={resultShareUrl}
        onClick={() => triggerShareIconEvent("facebook-result")}
        language={language}
      />
    ),
    messenger: (
      <Messenger
        goal="result"
        mainUrl={resultShareUrl}
        onClick={() => triggerShareIconEvent("messenger-result")}
        language={language}
        setFlashProps={setFlashProps}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
    whatsapp: (
      <WhatsApp
        goal="result"
        mainUrl={resultShareUrl}
        onClick={noop}
        language={language}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
    linkedin: (
      <Linkedin
        goal="result"
        mainUrl={resultShareUrl}
        onClick={() => triggerShareIconEvent("linkedin-result")}
        language={language}
      />
    ),
    email: (
      <Email
        goal="result"
        mainUrl={resultShareUrl}
        onClick={noop}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
  };

  const ShareAppIcons = {
    messenger: (
      <Messenger
        goal="app"
        mainUrl={appShareUrl}
        onClick={() => triggerShareIconEvent("messenger-app")}
        language={language}
        setFlashProps={setFlashProps}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
    whatsapp: (
      <WhatsApp
        goal="app"
        language={language}
        mainUrl={appShareUrl}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
    email: (
      <Email
        goal="app"
        mainUrl={appShareUrl}
        customStyles={{
          margin: `0 ${width > 576 ? "0.5rem" : "1.5rem"}`,
        }}
      />
    ),
    facebook: (
      <Facebook
        goal="app"
        mainUrl={appShareUrl}
        language={language}
        onClick={noop}
      />
    ),
    linkedin: (
      <Linkedin
        goal="app"
        mainUrl={appShareUrl}
        language={language}
        onClick={noop}
      />
    ),
  };

  /**
   * returns the layout for the sharing icons
   * @param {Object} icons
   * @returns {Object}
   */
  const getLayout = (icons) => {
    return {
      small: (
        <>
          <div className="d-flex justify-content-center mx-auto custom-flex-space-wraped">
            {icons.facebook}
            {icons.messenger}
            {icons.whatsapp}
          </div>
          <div className="d-flex justify-content-center mx-auto custom-flex-space-wraped">
            {icons.linkedin}
            {icons.email}
          </div>
        </>
      ),
      medium: (
        <div className="d-flex justify-content-center mx-auto custom-flex-space-all">
          {icons.facebook}
          {icons.messenger}
          {icons.whatsapp}
          {icons.linkedin}
          {icons.email}
        </div>
      ),
    };
  };

  return (
    <div
      className="d-flex flex-column flex-lg-row"
      style={{ backgroundColor: "#f9f8fc" }}
      id="share-info"
    >
      <div className="col-lg-6 text-center mb-4 mb-lg-0">
        <h3 className="fw-bold ff-popp-med share-info__title">
          {copies["results-title"]}
        </h3>

        {width < 576
          ? getLayout(ShareResultIcons).small
          : getLayout(ShareResultIcons).medium}
      </div>

      <div className="col-lg-6 text-center mt-5 mt-lg-0">
        <h3 className="fw-bold ff-popp-med share-info__title">
          {copies["app-title"]}
        </h3>

        {width < 576
          ? getLayout(ShareAppIcons).small
          : getLayout(ShareAppIcons).medium}
      </div>
    </div>
  );
};

export default ShareInfo;
