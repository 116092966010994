import { getResultReadyToRender } from "../../Results/services/preliminaryResults.service";
import {
  getDimensionForQuestion,
  getTips,
  getZoneCopyFromLanguage,
} from "./questionContainer.service";

/**
 * returns the updated answers object
 * @param {String} content
 * @param {Object} answerValues
 * @param {Int} questionNumber
 * @param {Array} dimensions
 * @param {Array} reOrderedQuestions
 * @returns {Object}
 */
const getAnswersObject = (
  content,
  answerValues,
  questionNumber,
  dimensions,
  reOrderedQuestions
) => {
  let answersObj = { ...answerValues };

  let dimension = getDimensionForQuestion(
    reOrderedQuestions[
      questionNumber === 10 ? questionNumber - 1 : questionNumber
    ],
    dimensions
  );

  if (dimension.dimension_type === "purpose") {
    let otherQuestionsWithSameDimension = reOrderedQuestions.filter(
      (question) => {
        return question.attributes.dimension_id === dimension.id;
      }
    );

    if (
      reOrderedQuestions[questionNumber].attributes.description ===
      otherQuestionsWithSameDimension[0].attributes.description
    ) {
      answersObj[dimension.dimension_type][dimension.sub_type][0] =
        parseInt(content) / 2;
    } else if (
      reOrderedQuestions[questionNumber].attributes.description ===
      otherQuestionsWithSameDimension[1].attributes.description
    ) {
      answersObj[dimension.dimension_type][dimension.sub_type][1] =
        parseInt(content) / 2;
    }
  } else if (!dimension.sub_type && !answersObj[dimension.dimension_type][0]) {
    answersObj[dimension.dimension_type][0] = parseInt(content) / 2;
  } else if (!dimension.sub_type) {
    let otherQuestionsWithSameDimension = reOrderedQuestions.filter(
      (question) => {
        return question.attributes.dimension_id === 5;
      }
    );

    if (
      reOrderedQuestions[questionNumber].attributes.description ===
      otherQuestionsWithSameDimension[0].attributes.description
    ) {
      answersObj[dimension.dimension_type][0] = parseInt(content) / 2;
    } else if (
      reOrderedQuestions[questionNumber].attributes.description ===
      otherQuestionsWithSameDimension[1].attributes.description
    ) {
      answersObj[dimension.dimension_type][1] = parseInt(content) / 2;
    }
  } else {
    answersObj[dimension.dimension_type][dimension.sub_type][0] =
      parseInt(content) / 2;
  }

  return answersObj;
};

/**
 * reorders the trial survey questions depending on the choosen dimensions on welcome survey
 * @param {Array} dimensions
 * @param {Object} answerValues
 * @param {Object} survey
 * @returns {Array}
 */
const reOrderQuestions = (dimensions, answerValues, survey) => {
  let reOrderedQuestions = [];
  let dimensionIdsToDefineQuestionOrder = [];

  for (let choosen_dimension of answerValues.feel.dimensions_choosen) {
    for (let dimension of dimensions) {
      if (dimension.ids.includes(choosen_dimension.dimension_id)) {
        dimension.ids.forEach((id) => {
          dimensionIdsToDefineQuestionOrder.push(id);
        });
      }
    }
  }

  for (let id of dimensionIdsToDefineQuestionOrder) {
    for (let question of survey.questions) {
      if (id === question.attributes.dimension_id) {
        reOrderedQuestions.push(question);
      }
    }
  }

  const theOtherQuestions = survey.questions.filter((question) => {
    return !dimensionIdsToDefineQuestionOrder.includes(
      question.attributes.dimension_id
    );
  });

  theOtherQuestions
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .forEach((element) => {
      reOrderedQuestions.push(element);
    });

  return reOrderedQuestions;
};

/**
 * handles the submission of a trial survey
 * @param {String} surveyCode
 * @param {Object} answerValues
 * @param {Object} survey
 * @returns {Object}
 */
const onTrialSurveySubmit = async (surveyCode, answerValues, survey) => {
  const {
    copyHeader,
    copyId,
    zone,
    dimensionValues,
    total,
    numberOfDimensionsThatContribute,
    highestDimension,
    lowestDimension,
  } = await getResultReadyToRender(answerValues, survey);

  const tips = await getTips({
    highestDimension,
    lowestDimension,
    zone: getZoneCopyFromLanguage({ zone, language: "en-GB" }),
    userId: "",
  });

  return {
    isTakingSurvey: false,
    zone,
    surveyCode,
    total,
    copyHeader,
    numberOfDimensionsThatContribute,
    dimensionValues,
    highestDimension: highestDimension.dimension_id,
    lowestDimension: lowestDimension.dimension_id,
    lowestTip: tips.lowestTip.id,
    highestTip: tips.highestTip.id,
    copyId,
  };
};

/**
 * checks if a user left the trial survey at some point without finishing it
 * @param {Object} answerValues
 * @returns {Boolean}
 */
const checkIfThereAreTrialValues = (answerValues) => {
  let entries = Object.keys(answerValues);

  entries = entries.filter((entry) => entry !== "feel");

  let outcome = false;

  for (let i = 0; i < entries.length; i++) {
    let innerEntries = Object.keys(answerValues[entries[i]]);
    if (!innerEntries && answerValues[entries[i]].length > 0) {
      outcome = true;
    } else {
      for (let j = 0; j < innerEntries.length; j++) {
        if (answerValues[entries[i]][innerEntries[j]].length > 0) {
          outcome = true;
        }
      }
    }
  }

  return outcome;
};

export {
  getAnswersObject,
  reOrderQuestions,
  onTrialSurveySubmit,
  checkIfThereAreTrialValues,
};
