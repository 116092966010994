import { LANGUAGE_SAVE } from "../../../../store/actions/types";

/**
 * the reducer for the language changing action
 * the final step to save the language into redux
 * @param {String} state
 * @param {Object} action
 * @returns {String}
 */
const languageReducer = (state = "en-GB", action) => {
  switch (action.type) {
    case LANGUAGE_SAVE:
      return action.payload;
    default:
      return state;
  }
};

export { languageReducer };
