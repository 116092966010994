import Cookies from "universal-cookie";
import { useDispatch, useStore } from "react-redux";
import { useEffect } from "react";
import { saveLanguage } from "../../../store/actions";
const cookies = new Cookies();

//hook for language changing handling
export function useLanguage() {
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    const languageCookie = cookies.get("language") || navigator.language;

    if (languageCookie && languageCookie !== store.getState().language)
      dispatch(saveLanguage(languageCookie));
  }, [dispatch, store]);
}
