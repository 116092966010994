import React from "react";
import { useResize } from "../../modules/Geral/hooks/useResize";
import "./DancingEllipses.css";
import FirstTopEllipse from "../../assets/svg/FirstTopEllipse.svg";
import SecondTopEllipse from "../../assets/svg/SecondTopEllipse.svg";
import FirstLeftBottomEllipse from "../../assets/svg/FirstLeftBottomEllipse.svg";
import SecondLeftBottomEllipse from "../../assets/svg/SecondLeftBottomEllipse.svg";
import GreyRectangleTopEllipse from "../../assets/svg/GreyRectangleTopEllipse.svg";
import GreyRectangleLeftSmallEllipse from "../../assets/svg/GreyRectangleLeftSmallEllipse.svg";
import GreyRectangleLeftBigEllipse from "../../assets/svg/GreyRectangleLeftBigEllipse.svg";
import PurpleBackgroundTopEllipse from "../../assets/svg/PurpleBackgroundTopEllipse.svg";
import PurpleBackgroundBottomEllipse from "../../assets/svg/PurpleBackgroundBottomEllipse.svg";

/**
 * Functional Component: DancingEllipses
 * Renders the four ellipses present in every page on this front end
 *
 *
 * @param {Boolean} noAnimation
 * @param {Boolean} isGreyRectangle
 * @param {Boolean} purpleBackground
 * @returns {Element}
 */
const AllEllipses = ({ noAnimation, isGreyRectangle, purpleBackground }) => {
  useResize();

  /**
   * renders the ellipses on the homepage and survey pages
   * @returns {element}
   * */
  const renderFullPageEllipses = () => {
    return (
      <div>
        <div
          id="top-elipse-container"
          style={{
            animation: window.innerWidth < 992 || noAnimation ? "none" : null,
          }}
        >
          <img src={FirstTopEllipse} alt="" id="first-ellipse-top"></img>
        </div>
        {window.innerWidth < 992 ? null : (
          <div
            id="second-top-elipse-container"
            style={{
              animation: window.innerWidth < 992 || noAnimation ? "none" : null,
            }}
          >
            <img src={SecondTopEllipse} alt="" id="second-ellipse-top"></img>
          </div>
        )}
        <div
          id="left-bottom-elipse-container"
          style={{
            animation: window.innerWidth < 992 || noAnimation ? "none" : null,
          }}
        >
          <img
            src={FirstLeftBottomEllipse}
            alt=""
            id="first-ellipse-bottom"
          ></img>
        </div>
        <div
          id="second-left-bottom-elipse-container"
          style={{
            animation: window.innerWidth < 992 || noAnimation ? "none" : null,
          }}
        >
          <img
            src={SecondLeftBottomEllipse}
            alt=""
            id="second-ellipse-bottom"
          ></img>
        </div>
      </div>
    );
  };

  /**
   * renders the ellipses on profile, analytics, meet violet top rectangle,
   * @returns {element}
   * */
  const renderGreyRectangleEllipses = () => {
    return (
      <div>
        <div id="grey-rectangle-top-ellipse-container">
          <img
            src={GreyRectangleTopEllipse}
            alt=""
            id="first-ellipse-top"
          ></img>
        </div>
        <div id="grey-rectangle-left-small-ellipse-container">
          <img
            src={GreyRectangleLeftSmallEllipse}
            alt=""
            id="grey-rectangle-first-ellipse-bottom"
          ></img>
        </div>
        <div id="grey-rectangle-left-big-ellipse-container">
          <img
            src={GreyRectangleLeftBigEllipse}
            alt=""
            id="grey-rectangle-second-ellipse-bottom"
          ></img>
        </div>
      </div>
    );
  };

  /**
   * renders the ellipses on the welcome back page top rectangle
   * @returns {element}
   * */
  const renderPurpleBackgroundEllipses = () => {
    return (
      <div>
        <div id="purple-background-top-ellipse-container">
          <img
            src={PurpleBackgroundTopEllipse}
            alt=""
            id="purple-background-top-ellipse"
          ></img>
        </div>
        <div id="purple-background-bottom-ellipse-container">
          <img
            src={PurpleBackgroundBottomEllipse}
            alt=""
            id="purple-background-bottom-ellipse"
          ></img>
        </div>
      </div>
    );
  };

  //decides wich ellipses to render
  if (!isGreyRectangle && !purpleBackground) {
    return renderFullPageEllipses();
  } else if (isGreyRectangle && !purpleBackground) {
    return renderGreyRectangleEllipses();
  } else if (purpleBackground) {
    return renderPurpleBackgroundEllipses();
  }
};

/**
 * @param {Boolean} noAnimation
 * @param {Boolean} isGreyRectangle
 * @param {Boolean} purpleBackground
 * @param {Boolean} dontShowSmall
 * @returns {Element}
 */
const DancingEllipses = ({
  noAnimation,
  isGreyRectangle,
  purpleBackground,
  dontShowSmall,
}) => {
  return (
    <div className="custom-ellipses" style={{}}>
      {window.innerWidth < 576 && dontShowSmall ? null : (
        <AllEllipses
          noAnimation={noAnimation}
          isGreyRectangle={isGreyRectangle}
          purpleBackground={purpleBackground}
        />
      )}
    </div>
  );
};

export default DancingEllipses;
