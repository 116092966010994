import { createAnalytic } from "./BackendHelper";

const EVENTS = {
  INVITE_MESSENGER_HAMBURGUER: {
    action: "Sending messenger message to invite through header or hamburguer.",
    category: "Invite_Messenger_Hamburguer",
  },
  INVITE_EMAIL_HAMBURGUER: {
    action: "Sending email invite through header or hamburguer.",
    category: "Invite_Email_Hamburguer",
  },
  MEET_VIOLET_HAMBURGUER: {
    category: "Meet_violet_Hamburguer",
    action: "Visiting meet violet through header or hamburguer.",
  },
  ANALYTICS_HAMBURGUER: {
    category: "View_Analytics_Hamburguer",
    action: "Go to analytics from header or hamburguer.",
  },
  PURPLE_FOOTER: {
    category: "Visit_purple_Footer",
    action: "Visiting pur'ple website trough footer icon.",
  },
  MEET_VIOLET_HOMEPAGE: {
    category: "Meet_violet_Homepage",
    action: "Visiting meet violet through home page.",
  },
  INVITE_MESSENGER_MEET: {
    category: "Invite_Messenger_Meet",
    action: "Sending messenger message to invite through meet violet.",
  },
  INVITE_EMAIL_MEET: {
    category: "Invite_Email_Meet",
    action: "Sending email invite through Meet Violet.",
  },
  VIEW_ANALYTICS_MEET: {
    category: "View_Analytics_Meet",
    action: "Go to analytics from meet violet.",
  },
  VISIT_PURPLE_MEET: {
    category: "Visit_purple_Meet",
    action: "Visiting pur'ple website trough meet violet.",
  },
  PERSONAL_INFO_PROFILE: {
    category: "Personal_Info_Profile",
    action: "Save info on compare with others forever.",
  },
  PERSONAL_INFO_RESULTS: {
    category: "Personal_Info_Results",
    action: "Save info on compare with others only for 24h.",
  },
  VIEW_ANALYTICS_RESULTS: {
    category: "View_Analytics_Results",
    action: "Go to analytics from check previous results.",
  },
  VIEW_PROGRESS_ANALYTICS: {
    category: "View_Progress_Analytics",
    action: "Go to progress overtime from analytics.",
  },
  SURVEY_COMPLETED_CORE_FAILED: {
    category: "Survey_Completed_Core_Failed",
    action: "Core survey was submitted but failed.",
  },
  SURVEY_COMPLETED_CORE_SUCCESSFUL: {
    category: "Survey_Completed_Core_Successful",
    action: "Core survey was submitted and completed.",
  },
  GLOSSARY_TRIAL: {
    category: "Glossary_Trial",
    action: "Clicked glossary on trial survey.",
  },
  GLOSSARY_CORE: {
    category: "Glossary_Core",
    action: "Clicked glossary on core survey.",
    label: "user_id",
  },
  SURVEY_STARTED_TRIAL: {
    category: "Survey_Started_Trial",
    action: "Started trial survey.",
  },
  SURVEY_STARTED_CORE: {
    category: "Survey_Started_Core",
    action: "Started core survey.",
  },
  SURVEY_COMPLETED_TRIAL_FAILED: {
    category: "Survey_Completed_Trial_Failed",
    action: "Trial was submited but failed.",
  },
  SURVEY_COMPLETED_TRIAL_SUCCESSFUL: {
    category: "Survey_Completed_Trial_Successful",
    action: "Trial was submited and completed.",
  },
  PHOTO_DELETE_PROFILE: {
    category: "Photo_Delete_Profile",
    action: "User deleted photo.",
  },
  PHOTO_SAVE_PROFILE: {
    category: "Photo_Profile",
    action: "User saved photo.",
  },
  LOGOUT_ACCOUNT: {
    category: "Logout_Account",
    action: "User logged out.",
  },
  PASSWORD_PROFILE_INFO_SUCCESSFUL: {
    category: "Password_Profile_Info_Successful",
    action: "Changed password successfully.",
  },
  PASSWORD_PROFILE_INFO_FAILED: {
    category: "Password_Profile_Info_Failed",
    action: "Failed to change password.",
  },
  CHANGED_DATA_ABOUT_ME_SUCCESSFUL: {
    category: "Changed_Data_AboutMe_Successfully",
    action: "Data was changed successfully.",
  },
  CHANGED_DATA_ABOUT_ME_FAILED: {
    category: "Changed_Data_AboutMe_Error",
    action: "Error in data changing on about me.",
  },
  REMINDERS_PROFILE_INFO_SUCCESSFUL: {
    category: "Reminders_Profile_Info_Successful",
    action: "Changed alerts successfully.",
  },
  REMINDERS_PROFILE_INFO_FAILED: {
    category: "Reminders_Profile_Info_Failed",
    action: "Failed to change alert periodicity.",
  },
  DELETE_ACCOUNT_SUCCESSFUL: {
    category: "Delete_Account_Successful",
    action: "User deleted account successfully.",
  },
  DELETE_ACCOUNT_FAILED: {
    category: "Delete_Account_Fail",
    action: "User failed to delete account.",
  },
  AGE_INFO_ANALYTICS: {
    category: `Age_Info_Analytics`,
    action: `Changed Age field on analytics.`,
  },
  GENDER_INFO_ANALYTICS: {
    category: `Gender_Info_Analytics`,
    action: `Changed Gender field on analytics.`,
  },
  LOCATION_INFO_ANALYTICS: {
    category: `Location_Info_Analytics`,
    action: `Changed Location field on analytics.`,
  },
  REMINDER_OFF_PROFILE: {
    category: `Reminders_Info_Off_Profile`,
    action: `Changed Reminders field on profile.`,
  },
  LOGIN_INTERNAL: {
    category: `Login_Internal`,
    action: `Successful internal login.`,
  },
  VIEW_PROGRESS_RESULTS: {
    category: "View_Progress_Results",
    action: "Go to progress overtime from results.",
  },
};

const getAboutMeFieldEvent = (label) => {
  return {
    category: `${label}_Info_Profile`,
    action: `Changed ${label} field on profile.`,
  };
};

const getLoginWithIdentityProviderEvent = (identity_provider) => {
  return {
    category: `Login_${identity_provider}`,
    action: `Successful login with ${identity_provider}`,
  };
};

const getSignupWithIdentityProviderEvent = (identity_provider) => {
  return {
    category: `Sign_up_${identity_provider}_successfull`,
    action: `Created an Account with ${identity_provider}`,
  };
};

const getInfoAnalyticsEvent = (place) => {
  return {
    category: `${place}_Info_Analytics`,
    action: `Changed ${place} field on analytics.`,
  };
};

//

//contains the share icons events
const SHARE_ICONS_EVENTS = {
  "whatsapp-app": {
    category: "Invite_Whatsapp_Results",
    action: "Invite others through whatsapp.",
  },
  "messenger-app": {
    category: "Invite_Messenger_Results",
    action: "Invite others through messenger.",
  },
  "email-app": {
    category: "Invite_Email_Results",
    action: "Invite others through email.",
  },
  //
  "whatsapp-app-header": {
    category: "Invite_WhatsApp_Hamburguer",
    action: "Sending whatsapp message to invite through header or hamburguer.",
  },
  "whatsapp-app-meet-violet": {
    category: "Invite_WhatsApp_Meet",
    action: "Sending whatsapp message to invite through meet violet.",
  },

  //
  "whatsapp-result": {
    category: "Share_Results_Whatsapp",
    action: "Sharing results through whatsapp.",
  },
  "facebook-result": {
    category: "Share_Results_Facebook",
    action: "Sharing results through facebook.",
  },
  "messenger-result": {
    category: "Share_Results_Messenger",
    action: "Sharing results through messenger.",
  },
  "linkedin-result": {
    category: "Share_Results_Linkedin",
    action: "Sharing results through linkedin.",
  },
  "email-result": {
    category: "Share_Results_Email",
    action: "Sharing results through email.",
  },
};

const triggerShareIconEvent = (id) => createAnalytic(SHARE_ICONS_EVENTS[id]);

export {
  triggerShareIconEvent,
  EVENTS,
  getAboutMeFieldEvent,
  getLoginWithIdentityProviderEvent,
  getSignupWithIdentityProviderEvent,
  getInfoAnalyticsEvent,
};
