import login_backend from "../../../core/apis/login_backend";
import {
  getAgeRangeFromFilteredUsers,
  getCountryRangeFromFilteredUsers,
  getDimensionRange,
  getGenderRange,
  getGenderRangeFromFilteredUsers,
  getAgeRange,
} from "./showResultComponent.service";

/**
 * this updates the filters values depending on the ones that are actually chosen
 * @param {Object} filterState
 * @param {Function} dispatch
 */
const dynamicFilterValuesUpdater = async (filterState, dispatch) => {
  const {
    minAge,
    maxAge,
    country,
    gender,
    baseGenderRange,
    baseCountryRange,
    previousCountryValue,
    previousGenderValue,
    previousMinAgeRange,
    previousMaxAgeRange,
  } = filterState;

  try {
    const users = await login_backend.post("/filterByDemographics", {
      min_age: minAge,
      max_age: maxAge,
      country: country,
      gender: gender,
    });

    if (!country && !gender && (minAge || maxAge)) {
      ageControl(filterState, dispatch, users);
    } else if (!minAge && !maxAge && !country & !gender) {
      setAllDefaultValues(users, dispatch);
    } else {
      const newGenderRange = !gender
        ? getGenderRangeFromFilteredUsers(users.data.data)
        : null;
      const newCountryRange = !country
        ? getCountryRangeFromFilteredUsers(users.data.data)
        : null;

      const newAgeRange = getAgeRangeFromFilteredUsers(users.data.data);

      dispatch({
        type: "genderRange",
        payload: newGenderRange
          ? getGenderRange(newGenderRange)
          : baseGenderRange,
      });

      dispatch({ type: "previousGenderValue", payload: gender });

      dispatch({
        type: "countryRange",
        payload: newCountryRange ?? baseCountryRange,
      });
      dispatch({ type: "previousCountryValue", payload: country });

      if (previousCountryValue !== country || previousGenderValue !== gender) {
        if (minAge || maxAge) {
          const users = await login_backend.post(
            "/filterByDemographics",
            previousCountryValue !== country ? { country } : { gender }
          );

          const newAgeRange = getAgeRangeFromFilteredUsers(users.data.data);

          ageControl(
            filterState,
            dispatch,
            users,
            newAgeRange[1].value,
            newAgeRange[newAgeRange.length - 1].value
          );
        } else {
          dispatch({ type: "minAgeRange", payload: newAgeRange });
          dispatch({ type: "maxAgeRange", payload: newAgeRange });
          dispatch({ type: "previousMinAgeRange", payload: newAgeRange });
          dispatch({ type: "previousMaxAgeRange", payload: newAgeRange });
        }
      } else if (country || gender) {
        const users = await login_backend.post("/filterByDemographics", {
          country: country,
          gender: gender,
        });

        const usersByAge = await login_backend.post("/filterByDemographics", {
          min_age: minAge,
          max_age: maxAge,
        });

        const newAgeRange = getAgeRangeFromFilteredUsers(users.data.data);

        limitedAgeControl(
          newAgeRange[1].value,
          newAgeRange[newAgeRange.length - 1].value,
          filterState,
          dispatch,
          usersByAge
        );
      } else {
        ageControl(filterState, dispatch, users);
      }
    }
  } catch (e) {
    dispatch({ type: "minAgeRange", payload: previousMinAgeRange });
    dispatch({ type: "maxAgeRange", payload: previousMaxAgeRange });
  }
};

/**
 * this controls the age limits depending on filter state
 * @param {Int} min
 * @param {Int} max
 * @param {Object} filterState
 * @param {Function} dispatch
 * @param {Object} users
 */
const limitedAgeControl = (min, max, filterState, dispatch, users) => {
  const { minAge, maxAge } = filterState;

  dispatch({
    type: "minAgeRange",
    payload: getAgeRange(min, maxAge ?? max),
  });

  dispatch({
    type: "previousMinAgeRange",
    payload: getAgeRange(min, maxAge ?? max),
  });

  dispatch({
    type: "maxAgeRange",
    payload: getAgeRange(minAge ?? min, max),
  });

  dispatch({
    type: "previousMaxAgeRange",
    payload: getAgeRange(minAge ?? min, max),
  });

  const newGenderRange = getGenderRangeFromFilteredUsers(users.data.data);
  const newCountryRange = getCountryRangeFromFilteredUsers(users.data.data);

  dispatch({
    type: "genderRange",
    payload: getGenderRange(newGenderRange),
  });

  dispatch({
    type: "countryRange",
    payload: newCountryRange,
  });
};

/**
 * this controls the possible ages depending on the filter state
 * @param {Object} filterState
 * @param {Function} dispatch
 * @param {Object} users
 * @param {Int} minForThisCase
 * @param {Int} maxForThisCase
 */
const ageControl = (
  filterState,
  dispatch,
  users,
  minForThisCase,
  maxForThisCase
) => {
  const { minAge, maxAge, baseAgeRange } = filterState;

  if (minForThisCase && maxForThisCase) {
    dispatch({
      type: "minAgeRange",
      payload: getAgeRange(
        minForThisCase,
        maxAge < maxForThisCase ? maxAge : maxForThisCase
      ),
    });

    dispatch({
      type: "previousMinAgeRange",
      payload: getAgeRange(
        minForThisCase,
        maxAge < maxForThisCase ? maxAge : maxForThisCase
      ),
    });

    dispatch({
      type: "maxAgeRange",
      payload: getAgeRange(
        minAge > minForThisCase ? minAge : minForThisCase,
        maxForThisCase
      ),
    });

    dispatch({
      type: "previousMaxAgeRange",
      payload: getAgeRange(
        minAge > minForThisCase ? minAge : minForThisCase,
        maxForThisCase
      ),
    });
  } else if (minAge && !maxAge) {
    dispatch({
      type: "maxAgeRange",
      payload: getAgeRange(minAge, baseAgeRange[baseAgeRange.length - 1].value),
    });
    dispatch({
      type: "previousMaxAgeRange",
      payload: getAgeRange(minAge, baseAgeRange[baseAgeRange.length - 1].value),
    });
  } else if (!minAge && maxAge) {
    dispatch({
      type: "minAgeRange",
      payload: getAgeRange(baseAgeRange[1].value, maxAge),
    });

    dispatch({
      type: "previousMinAgeRange",
      payload: getAgeRange(baseAgeRange[1].value, maxAge),
    });
  } else if (minAge && maxAge) {
    dispatch({
      type: "minAgeRange",
      payload: getAgeRange(baseAgeRange[1].value, maxAge),
    });

    dispatch({
      type: "previousMinAgeRange",
      payload: getAgeRange(baseAgeRange[1].value, maxAge),
    });

    dispatch({
      type: "maxAgeRange",
      payload: getAgeRange(minAge, baseAgeRange[baseAgeRange.length - 1].value),
    });

    dispatch({
      type: "previousMaxAgeRange",
      payload: getAgeRange(minAge, baseAgeRange[baseAgeRange.length - 1].value),
    });
  }

  if (users) {
    const newGenderRange = getGenderRangeFromFilteredUsers(users.data.data);
    const newCountryRange = getCountryRangeFromFilteredUsers(users.data.data);

    dispatch({ type: "genderRange", payload: getGenderRange(newGenderRange) });

    dispatch({ type: "countryRange", payload: newCountryRange });
  }
};

/**
 * this sets all default values for the filter state
 * @param {Object} users
 * @param {Function} dispatch
 */
const setAllDefaultValues = (users, dispatch) => {
  const newGenderRange = getGenderRangeFromFilteredUsers(users.data.data);
  const newCountryRange = getCountryRangeFromFilteredUsers(users.data.data);

  const newAgeRange = getAgeRangeFromFilteredUsers(users.data.data);

  const newDimensionRange = getDimensionRange();

  dispatch({ type: "genderRange", payload: getGenderRange(newGenderRange) });
  dispatch({ type: "countryRange", payload: newCountryRange });
  dispatch({ type: "minAgeRange", payload: newAgeRange });
  dispatch({ type: "maxAgeRange", payload: newAgeRange });
  dispatch({ type: "previousMinAgeRange", payload: newAgeRange });
  dispatch({ type: "previousMaxAgeRange", payload: newAgeRange });
  dispatch({
    type: "previousGenderValue",
    payload: getGenderRange(newGenderRange).map((value) => value.value),
  });
  dispatch({
    type: "previousCountryValue",
    payload: newCountryRange.map((value) => value.value),
  });
  dispatch({
    type: "baseAgeRange",
    payload: newAgeRange,
  });
  dispatch({
    type: "baseCountryRange",
    payload: newCountryRange,
  });
  dispatch({
    type: "baseGenderRange",
    payload: getGenderRange(newGenderRange),
  });
  dispatch({ type: "dimensionRange", payload: newDimensionRange });
};

/**
 * a custom reducer to handle the actions dispatched to update filter state
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "minAge":
      return { ...state, minAge: action.payload };
    case "maxAge":
      return { ...state, maxAge: action.payload };
    case "country":
      return { ...state, country: action.payload };
    case "gender":
      return { ...state, gender: action.payload };
    case "minAgeRange":
      return { ...state, minAgeRange: action.payload };
    case "maxAgeRange":
      return { ...state, maxAgeRange: action.payload };
    case "countryRange":
      return { ...state, countryRange: action.payload };
    case "genderRange":
      return { ...state, genderRange: action.payload };
    case "previousCountryValue":
      return { ...state, previousCountryValue: action.payload };
    case "previousGenderValue":
      return { ...state, previousGenderValue: action.payload };
    case "baseCountryRange":
      return { ...state, baseCountryRange: action.payload };
    case "baseGenderRange":
      return { ...state, baseGenderRange: action.payload };
    case "baseAgeRange":
      return { ...state, baseAgeRange: action.payload };
    case "previousMinAgeRange":
      return { ...state, previousMinAgeRange: action.payload };
    case "previousMaxAgeRange":
      return { ...state, previousMaxAgeRange: action.payload };
    case "dimension":
      return { ...state, dimension: action.payload };
    case "dimensionRange":
      return { ...state, dimensionRange: action.payload };
    case "reset":
      return initialState;
    default:
      throw new Error();
  }
};

//the initial state
const initialState = {
  minAge: null,
  maxAge: null,
  country: null,
  gender: null,
  minAgeRange: null,
  maxAgeRange: null,
  countryRange: null,
  genderRange: null,
  previousCountryValue: null,
  previousGenderValue: null,
  baseCountryRange: null,
  baseGenderRange: null,
  baseAgeRange: null,
  previousMinAgeRange: null,
  previousMaxAgeRange: null,
  dimension: null,
  dimensionRange: null,
};

export { dynamicFilterValuesUpdater, reducer, initialState };
