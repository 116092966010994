import {
  isThereCoreSurveyResultToday,
  isThereWelcomingSurveyResultsToday,
} from "../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import {
  redirectToProfilePage,
  redirectToSurveyPage,
  redirectToWelcomeSurveyPage,
} from "../../../core/Routing/RoutesRedirects";

/**
 * chooses the path after login in
 * depending if a person answered surveys today or not redirects to the surveys
 * if all have been answered redirects to welcome back page
 * @param {Object} currentUser
 * */
const chooseNextPath = async (currentUser) => {
  const welcomeResultToday = await isThereWelcomingSurveyResultsToday(
    currentUser.attributes.user_id
  );

  const coreSurveyResultToday = await isThereCoreSurveyResultToday(
    currentUser.attributes.user_id
  );

  if (!welcomeResultToday) {
    redirectToWelcomeSurveyPage();
  } else if (coreSurveyResultToday) {
    redirectToProfilePage();
  } else {
    redirectToSurveyPage();
  }
};

export default chooseNextPath;
