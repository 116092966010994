import React, { useState } from "react";
import "./ResultsGraphImage.css";
import { MAIN_PURPLE } from "../../../../utils/constants/colorsConstants";
import { PRELIMINARY_RESULTS_PAGE_ID } from "../../../../utils/constants/constants";
import { MouseTooltip } from "../../../../components/modal/CustomTooltips";
import { useResize } from "../../../Geral/hooks/useResize";
import { zoneObjectFromScore } from "../../../../utils/helpers/geralHelpers/ZonesHelper";

/**
 * Functional Component: ResultsGraphImage
 * The svg that will be rendered as the results graph
 * @param {Array} results
 * @param {String} page
 * @returns {Element}
 */
const ResultsGraphImage = ({ results, page }) => {
  const [activeTooltip, setActiveTooltip] = useState({});

  const filter = page === PRELIMINARY_RESULTS_PAGE_ID ? "7.2" : "0";
  const [windowWidth] = useResize();
  const getFactor = () => {
    if (windowWidth < 576) return "xs";
    else if (windowWidth < 768) return "md";
    else if (windowWidth < 992) return "lg";
    else return "xl";
  };

  const thisQuadrantCircle = (props) =>
    renderQuadrantCircle({
      ...props,
      setTooltipProps: setActiveTooltip,
      windowSize: getFactor(),
    });

  const coords = {
    "left top": leftTopQuadrantCoords(results["left top"].score),
    "right top": rightTopQuadrantCoords(results["right top"].score),
    "right bottom": rightBottomQuadrantCoords(results["right bottom"].score),
    "left bottom": leftBottomQuadrantCoords(results["left bottom"].score),
  };

  const coordsPoints = Object.keys(coords).reduce((prev, arrow) => {
    const newCoords = coords[arrow].cx + " " + coords[arrow].cy;
    return prev + newCoords + " ";
  }, "");

  const renderDynamicParts = () => {
    return (
      <>
        <polygon
          points={coordsPoints}
          fill={MAIN_PURPLE}
          fillOpacity=".1"
          stroke={MAIN_PURPLE}
          strokeWidth="5"
        />
        {Object.keys(coords).map((arrow) => {
          return thisQuadrantCircle({
            ...coords[arrow],
            tooltip: { text: results[arrow].tooltip, arrow },
            fillColor: zoneObjectFromScore(results[arrow].score).colors.main,
          });
        })}

        <text
          id="quadrant-one-dimension"
          y="188"
          fill={texts.color}
          {...texts.right}
          {...texts.dimension}
        >
          {results["left top"].label}
        </text>
        <g filter="url(#blurScore)">
          <text
            id="quadrant-one-score"
            y="225"
            fill={texts.color}
            {...texts.right}
            {...texts.score}
          >
            {results["left top"].score}
          </text>
        </g>

        <text
          id="quadrant-two-dimension"
          y="188"
          fill={texts.color}
          {...texts.left}
          {...texts.dimension}
        >
          {results["right top"].label}
        </text>
        <g filter="url(#blurScore)">
          <text
            id="quadrant-two-score"
            y="225"
            fill={texts.color}
            {...texts.left}
            {...texts.score}
          >
            {results["right top"].score}
          </text>
        </g>

        <text
          id="quadrant-three-dimension"
          y="582"
          fill={texts.color}
          {...texts.left}
          {...texts.dimension}
        >
          {results["right bottom"].label}
        </text>

        <g filter="url(#blurScore)">
          <text
            id="quadrant-three-score"
            y="618"
            fill={texts.color}
            {...texts.left}
            {...texts.score}
          >
            {results["right bottom"].score}
          </text>
        </g>

        <text
          id="quadrant-four-dimension"
          y="582"
          fill={texts.color}
          {...texts.right}
          {...texts.dimension}
        >
          {results["left bottom"].label}
        </text>

        <g filter="url(#blurScore)">
          <text
            id="quadrant-four-score"
            y="618"
            fill={texts.color}
            {...texts.right}
            {...texts.score}
          >
            {results["left bottom"].score}
          </text>
        </g>
      </>
    );
  };

  return (
    <div className="results-graph-image" style={{ position: "relative" }}>
      <MouseTooltip
        {...activeTooltip}
        disableTooltip={() => setActiveTooltip({})}
      />

      <svg
        className="results-radar-graph"
        viewBox="0 0 845 800"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="93"
          cy="635"
          transform="rotate(180 93 635)"
          fill="url(#g1wmtzk9ja)"
          r="45"
        />
        <circle
          cx="70"
          cy="66"
          transform="rotate(180 70 66)"
          fill="url(#bxme2ala5b)"
          r="45"
        />
        <circle
          cx="775"
          cy="482"
          transform="rotate(180 775 482)"
          fill="url(#oxtea61tec)"
          r="40"
        />
        <circle cx="399.5" cy="395.5" r="287" stroke="#E3E3E3" />
        <circle cx="400" cy="396" r="344.5" stroke="#E3E3E3" />
        <g filter="url(#2pls9jfcud)">
          <circle cx="400" cy="396" r="375" fill="#F7F6F9" />
        </g>

        <g filter="url(#t5hgjpg5ge)">
          <circle cx="400" cy="397" r="230" fill="#fff" />
        </g>
        <g filter="url(#kyyx0nhqlf)">
          <circle cx="401.5" cy="397.5" r="172.5" fill="#fff" />
        </g>
        <g filter="url(#414ylv4iwg)">
          <circle cx="401" cy="397" r="115" fill="#fff" />
        </g>
        <g filter="url(#fyq1a6swwh)">
          <circle cx="401.5" cy="397.5" r="57.5" fill="#fff" />
        </g>
        <path
          stroke="#E8E8E8"
          strokeWidth="2"
          d="m238.072 233.658 325.269 325.269M236.658 558.928l325.269-325.269"
        />

        {renderDynamicParts()}

        <circle
          cx="25"
          cy="683"
          transform="rotate(180 25 683)"
          fill="url(#pd473jfmsi)"
          r="10"
        />
        <circle
          cx="237"
          cy="11"
          transform="rotate(180 237 11)"
          fill="url(#htni2gvapj)"
          r="10"
        />
        <circle
          cx="835"
          cy="408"
          transform="rotate(180 835 408)"
          fill="url(#6siuf7vi7k)"
          r="10"
        />
        <defs>
          <linearGradient
            id="g1wmtzk9ja"
            x1="93"
            y1="590"
            x2="93"
            y2="680"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="bxme2ala5b"
            x1="70"
            y1="21"
            x2="70"
            y2="111"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="oxtea61tec"
            x1="775"
            y1="442"
            x2="775"
            y2="522"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="pd473jfmsi"
            x1="25"
            y1="673"
            x2="25"
            y2="693"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="htni2gvapj"
            x1="237"
            y1="1"
            x2="237"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="6siuf7vi7k"
            x1="835"
            y1="398"
            x2="835"
            y2="418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DCCFED" />
            <stop offset="1" stopColor="#fff" stopOpacity=".5" />
          </linearGradient>
          <filter
            id="blurScore"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation={filter} />
          </filter>
          <filter
            id="2pls9jfcud"
            x="0"
            y="0"
            width="800"
            height="800"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_270_4563"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_270_4563"
              result="shape"
            />
          </filter>
          <filter
            id="t5hgjpg5ge"
            x="145"
            y="146"
            width="510"
            height="510"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_270_4563"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_270_4563"
              result="shape"
            />
          </filter>
          <filter
            id="kyyx0nhqlf"
            x="204"
            y="204"
            width="395"
            height="395"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_270_4563"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_270_4563"
              result="shape"
            />
          </filter>
          <filter
            id="414ylv4iwg"
            x="261"
            y="261"
            width="280"
            height="280"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_270_4563"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_270_4563"
              result="shape"
            />
          </filter>
          <filter
            id="fyq1a6swwh"
            x="319"
            y="319"
            width="165"
            height="165"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_270_4563"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_270_4563"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

// do not touch - important stuff

/**
 * @param {Int} score
 * @returns {Object}
 */
const firstThirdQuadrantEquation = (score) => {
  const x = (89 * score + 21995) / 55;
  return { cx: x, cy: -x + 795 };
};

/**
 * @param {Int} score
 * @returns {Object}
 */
const secondForthQuadrantEquation = (score) => {
  const x = (7843 * score + 1933625) / 4805;
  return { cx: x, cy: x - 4 };
};

const leftTopQuadrantCoords = (score) => firstThirdQuadrantEquation(score);

const rightTopQuadrantCoords = (score) => secondForthQuadrantEquation(-score);

const rightBottomQuadrantCoords = (score) => firstThirdQuadrantEquation(-score);

const leftBottomQuadrantCoords = (score) => secondForthQuadrantEquation(score);

const renderQuadrantCircle = ({
  cx,
  cy,
  tooltip,
  setTooltipProps,
  windowSize,
  fillColor = "#fff",
}) => {
  const radius = ["xl", "lg"].includes(windowSize) ? "7.5" : "10.5";
  return (
    <circle
      key={cx + "" + cy}
      cx={cx}
      cy={cy}
      r={radius}
      fill={fillColor}
      stroke={MAIN_PURPLE}
      strokeWidth="5"
      className="radar-graph-quadrant-circle"
      onMouseMove={() => setTooltipProps(tooltip)}
      onMouseOut={() => setTooltipProps({})}
    />
  );
};

const texts = {
  color: "#000",
  score: {
    fontSize: 25,
    fontFamily: "PoppinsMedium",
  },
  dimension: {
    fontSize: 20,
    fontFamily: "PoppinsRegular",
  },
  left: {
    x: 217,
    textAnchor: "end",
  },
  right: {
    x: 583,
    textAnchor: "start",
  },
};

export default ResultsGraphImage;
