import backend from "../../../core/apis/backend";
//import login_backend from "../../../core/apis/login_backend";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";
import { renderCountriesDropdown } from "../../../utils/helpers/graphsHelpers/GraphsFiltersHelper";
import { genderFormatter } from "../../Users/services/aboutMe.service";
const _ = require("lodash");

/**
 * returns a range between two numbers
 * @param {Int} min
 * @param {Int} max
 * @returns {Array}
 */
const getAgeRange = (min, max) => {
  let range = _.range(min, max + 1);

  return range.map((value) => {
    return { value: value, label: value };
  });
};

/**
 * returns the possible gender range for the filter
 * @param {Array} genders
 * @returns {Array}
 */
const getGenderRange = (genders) => {
  return genders.map((gender) => {
    return {
      value: gender,
      label: genderFormatter(gender),
    };
  });
};

/**
 * returns the dimensions range
 * @returns {Array}
 */
const getDimensionRange = () => {
  return [
    { value: 1, label: "Physical" },
    { value: 3, label: "Mental" },
    { value: 5, label: "Social" },
    { value: 6, label: "Work" },
  ];
};

/**
 * returns the genders that exist on a certain group of users
 * @param {Object} users
 * @returns {Array}
 */
const getGenderRangeFromFilteredUsers = (users) => {
  let genders = ["M", "F", "O"];

  const thisUsersGenders = users
    .map((user) => user.attributes.gender)
    .filter((value) => value);

  return genders.filter((gender) => thisUsersGenders.includes(gender));
};

/**
 * returns the countries that exist on a certain group of users
 * @param {Array} users
 * @returns {Array}
 */
const getCountryRangeFromFilteredUsers = (users) => {
  let countries = renderCountriesDropdown();

  const thisUsersCountries = users.map((user) => user.attributes.country);

  return countries.filter((country) =>
    thisUsersCountries.includes(country.value)
  );
};

/**
 * returns the age range that exist on a certain group of users
 * @param {Array} users
 * @returns {Array}
 */
const getAgeRangeFromFilteredUsers = (users) => {
  const allYears = users
    .map((user) => {
      if (user.attributes.birthdate) {
        return (
          new Date().getFullYear() -
          new Date(user.attributes.birthdate).getFullYear()
        );
      }
      return null;
    })
    .filter((value) => value);

  if (allYears.length < 1) return allYears;

  allYears.sort((a, b) => {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  });

  let removeDuplicates = [...new Set(allYears)];

  return getAgeRange(
    removeDuplicates[0],
    removeDuplicates[removeDuplicates.length - 1]
  );
};

/**
 * updates the average result and ranges depending on filters changing
 * @param {Object} filterState
 * @param {Int} min_range_value
 * @param {Int} max_range_value
 * @param {Function} changeState
 * @param {String} stateType
 */
const updateAverageAndRanges = async (
  filterState,
  min_range_value,
  max_range_value,
  changeState,
  stateType
) => {
  const { minAge, maxAge, country, gender } = filterState;

  try {
    const average = await backend.post(`/filteredAverage/${CORE_SURVEY_ID}`, {
      filters: { minAge, maxAge, country, gender },
    });

    const usersOnSameRange = await backend.post(
      `/filteredUsersInSameRange/${CORE_SURVEY_ID}`,
      {
        filters: {
          min_age: minAge,
          max_age: maxAge,
          country: country,
          gender: gender,
        },
        min_value: min_range_value,
        max_value: max_range_value,
      }
    );

    switch (stateType) {
      case "average":
        changeState(average.data.average);
        break;
      case "percentage":
        changeState(usersOnSameRange.data.usersPercentageWithSameScoreRange);
        break;
      default:
        break;
    }
  } catch (e) {
    changeState("No info for this range.");
  }
};

export {
  getGenderRange,
  getAgeRange,
  getGenderRangeFromFilteredUsers,
  getCountryRangeFromFilteredUsers,
  getAgeRangeFromFilteredUsers,
  updateAverageAndRanges,
  getDimensionRange,
};
