import React from "react";
import { connect } from "react-redux";
import {
  redirectToAboutMePage,
  redirectToAnalyticsPage,
  redirectToKeycloakEndpoint,
  redirectToMeetVioletPage,
  redirectToSignupEndpoint,
} from "../../core/Routing/RoutesRedirects";
import {
  renderEmailShareIconForApp,
  renderFacebookMessengerShareIcon,
  renderWhatsAppShareIcon,
} from "../../modules/Results/components/ExtraRenders";
import { getShareURL } from "../../utils/helpers/geralHelpers/RandomHelper";
import {
  anonymousUser,
  registeredUser,
} from "../../utils/helpers/IAmVioletHelper";
import ActionsMenu from "../actionsMenu/ActionsMenu";
import "./HeaderFirstVersion.css";
import logo from "../../assets/images/logo.svg";
import EmailIcon from "../../assets/images/emailIconWhiteBackground.png";
import EmailIcon2 from "../../assets/images/emailIconWhiteBackground2x.png";
import EmailIcon3 from "../../assets/images/emailIconWhiteBackground3x.png";
import WhatsAppIcon from "../../assets/images/whatsappIconWhiteBackground.png";
import WhatsAppIcon2 from "../../assets/images/whatsappIconWhiteBackground2x.png";
import WhatsAppIcon3 from "../../assets/images/whatsappIconWhiteBackground3x.png";
import MessengerIcon from "../../assets/images/messengerIconWhiteBackground.png";
import MessengerIcon2 from "../../assets/images/messengerIconWhiteBackground2x.png";
import MessengerIcon3 from "../../assets/images/messengerIconWhiteBackground3x.png";
import { MAIN_PURPLE } from "../../utils/constants/colorsConstants";
import { useResize } from "../../modules/Geral/hooks/useResize";

import { lngs } from "../../utils/constants/languages";
import { getStaticActionsMenuCopies } from "../../utils/constants/copies/copies.helper";
import { fetchUserWithAllAttributes, saveLanguage } from "../../store/actions";
import Cookies from "universal-cookie";
import login_backend from "../../core/apis/login_backend";
import { cookiesOptions } from "../../utils/helpers/cookiesHelpers/cookiesHelper";
import { createAnalytic } from "../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: HeaderFirstVersion
 * The header for violet, is present on every page and contains links to meet violet, login, sign up, my profile and analytics
 *
 * @param {Object} currentUser
 * @param {Object} preliminaryResult
 * @param {Object} answerValues
 * @param {Boolean} onProfile
 * @param {Boolean} meetViolet
 * @param {Boolean} myAnalytics
 * @param {Boolean} survey
 * @param {Object} queryParams
 * @returns {Element}
 * */
const HeaderFirstVersion = ({
  currentUser,
  preliminaryResult,
  answerValues,
  onProfile,
  meetViolet,
  myAnalytics,
  survey,
  queryParams,
  language,
  saveLanguage,
  fetchUserWithAllAttributes,
  adminInterface,
  pages,
  onPageChange,
  currentPage,
}) => {
  const [innerWidth] = useResize();
  const cookies = new Cookies();

  // contains all the copies for the header component
  const SELF_COPIES = getStaticActionsMenuCopies({ language: language });

  //returns the url for app sharing
  let appShareUrl = getShareURL(currentUser, language);

  //the image for the messenger sharing button
  const messengerImage = (
    <img
      alt=""
      src={MessengerIcon}
      srcSet={`${MessengerIcon2}, ${MessengerIcon3}`}
      className="share-icons-header"
      onClick={() => createAnalytic(EVENTS.INVITE_MESSENGER_HAMBURGUER)}
    />
  );

  //the image for whatsapp sharing button
  const whatsappImage = (
    <img
      alt=""
      src={WhatsAppIcon}
      srcSet={`${WhatsAppIcon2}, ${WhatsAppIcon3}`}
      className="share-icons-header"
    />
  );

  //the image for email sharing button
  const emailImage = (
    <img
      alt=""
      src={EmailIcon}
      srcSet={`${EmailIcon2}, ${EmailIcon3}`}
      className="share-icons-header"
      onClick={() => createAnalytic(EVENTS.INVITE_EMAIL_HAMBURGUER)}
    />
  );

  /**
   * the icon for my profile
   * @returns {element}
   * */
  const renderProfileIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-person-fill"
        viewBox="0 0 16 16"
      >
        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    );
  };

  /**
   * render the language switching interface in the header
   * @returns {Element}
   */
  const renderLanguage = () => {
    let arr = [];

    Object.keys(lngs).forEach((a) => {
      arr.push({
        final: lngs[a].abreviation,
        changeTo: lngs[a].internalName,
      });
    });

    return (
      <div
        className="d-flex justify-content-center align-items-center"
        id="languages-container"
      >
        {arr.map((a, index) => {
          return (
            <>
              <div
                style={{
                  cursor: "pointer",
                  color: a.changeTo === language ? MAIN_PURPLE : null,
                }}
                onClick={async () => {
                  if (a.changeTo === language) return;

                  saveLanguage(a.changeTo);
                  cookies.set("language", a.changeTo, cookiesOptions);

                  if (!currentUser) return;

                  await login_backend.put("/updateLanguage", {
                    user_id: currentUser.attributes.user_id,
                    language: a.changeTo,
                  });

                  await fetchUserWithAllAttributes(
                    currentUser.attributes.user_id
                  );
                }}
              >
                {a.final}
              </div>
              <div className="mr-2 ml-2">{index === 0 ? " | " : null}</div>
            </>
          );
        })}
      </div>
    );
  };

  const renderShareSection = () => {
    return (
      <>
        <div
          id="invite-others-container"
          className="d-flex justify-content-end"
        >
          <div className="d-flex flex-column align-items-center w-25 mr-5">
            <span
              className="right-side-header d-flex justify-content-center"
              id="invite-others"
            >
              {SELF_COPIES.INVITE_OTHERS}
            </span>

            <div
              className="right-side-header d-flex align-items-center"
              id="icons-container"
            >
              <div className="right-side-header">
                {renderEmailShareIconForApp({
                  appShareUrl,
                  customStyles: {},
                  img: emailImage,
                  place: "Header",
                })}
              </div>
              <div className="right-side-header">
                {renderWhatsAppShareIcon({
                  appShareUrl,
                  isApp: true,
                  customStyles: {},
                  img: whatsappImage,
                  place: "Header",
                })}
              </div>

              <div className="right-side-header">
                {renderFacebookMessengerShareIcon({
                  appShareUrl,
                  isApp: true,
                  img: messengerImage,
                  place: "Header",
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex vertical-line right-side-header">
          <div className="vr right-side-header" />
        </div>
      </>
    );
  };
  const renderSignupButton = () => {
    return (
      <div
        id="Sign-up-button-container"
        className="right-side-header Main-Button-Fill-Avenir-Desktop-signup"
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#573C7C")}
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = MAIN_PURPLE)
        }
        onClick={() => {
          document.getElementById(
            "Sign-up-button-container"
          ).style.backgroundColor = "#CDBAE7";

          redirectToSignupEndpoint({ preliminaryResult, queryParams });
        }}
      >
        {" "}
        <span className="right-side-header" id="Sign-up-button">
          {SELF_COPIES.SIGNUP_BUTTON}
        </span>
      </div>
    );
  };

  const renderMeetVioletButton = () =>
    renderButton({
      className: "Meet-violet",
      onClick: () => {
        redirectToMeetVioletPage();
        createAnalytic(EVENTS.MEET_VIOLET_HAMBURGUER);
      },
      children: SELF_COPIES.MEET_VIOLET_BUTTON,
      id: "meet-violet",
      customStyles: { width: language === "en-GB" ? "6vw" : "7vw" },
      selected: meetViolet,
    });

  const renderMyAnalyticsButton = () =>
    renderButton({
      className: currentUser ? "myAnalytics" : "Login",
      onClick: currentUser
        ? () => {
            redirectToAnalyticsPage();
            createAnalytic(EVENTS.ANALYTICS_HAMBURGUER);
          }
        : () => {
            redirectToKeycloakEndpoint(language);
          },
      children: (
        <span>
          {currentUser
            ? SELF_COPIES.MY_ANALYTICS_BUTTON
            : SELF_COPIES.LOGIN_BUTTON}
        </span>
      ),
      selected: myAnalytics,
      customStyles: { width: language === "en-GB" ? "6.5vw" : "10vw" },
    });

  const renderMyProfileButton = () =>
    renderButton({
      className: "Profile",
      onClick: redirectToAboutMePage,
      children: (
        <div className="d-flex">
          <span>
            {renderProfileIcon()} {SELF_COPIES.ABOUT_ME_BUTTON}
          </span>
        </div>
      ),
      selected: onProfile,
      id: "profile",
    });

  const renderButton = ({
    children,
    id,
    className = "",
    onClick,
    selected,
    customStyles = {},
  }) => {
    return (
      <span
        className={`right-side-header header-button ${className} d-flex flex-column align-items-center`}
        onClick={onClick}
      >
        {children}
        {selected ? (
          <div
            id={`${id}-selector`}
            className="purple-page-selector"
            style={{ ...customStyles }}
          />
        ) : null}
      </span>
    );
  };

  //

  const renderAdminInterfaceButton = ({
    id,
    navbar_label,
    customNavbarStyles,
  }) =>
    renderButton({
      children: navbar_label,
      id,
      onClick: () => onPageChange(id),
      selected: id === currentPage,
      customStyles: customNavbarStyles,
    });

  const renderOptions = () => {
    if (!adminInterface) {
      return (
        <div className="collapse navbar-collapse" id="navbar">
          <div className="navbar-nav mt-3 mt-md-0 justify-content-lg-end w-100 align-items-center">
            {renderShareSection()}
            {renderMeetVioletButton()}
            {renderMyAnalyticsButton()}
          </div>

          {currentUser ? renderMyProfileButton() : renderSignupButton()}
        </div>
      );
    } else {
      return (
        <div className="collapse navbar-collapse" id="navbar">
          <div className="navbar-nav mt-3 mt-md-0 offset-6 justify-content-between w-50 align-items-center">
            {Object.values(pages).map(renderAdminInterfaceButton)}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {innerWidth < 992 ? (
        <div className="header">
          <nav className="top-rectangle" />
        </div>
      ) : null}
      <header
        style={
          innerWidth < 992
            ? { position: "fixed", width: "100%", zIndex: "6", top: "0" }
            : {}
        }
        className="header"
      >
        <nav className="navbar navbar-expand-md navbar-light shadow top-rectangle ">
          <div className="container-fluid">
            <img
              onClick={
                survey
                  ? null
                  : () => {
                      if (!currentUser) {
                        anonymousUser({ preliminaryResult, answerValues });
                      } else {
                        registeredUser({ currentUser });
                      }
                    }
              }
              src={logo}
              alt="logo"
              className="logo"
              style={{ cursor: survey ? null : "pointer" }}
            />
            {renderLanguage()}

            {window.innerWidth < 992 ? (
              <ActionsMenu copies={SELF_COPIES} />
            ) : (
              renderOptions()
            )}
          </div>
        </nav>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    preliminaryResult: state.preliminaryResult,
    answerValues: state.answerValues,
    queryParams: state.queryParams,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  saveLanguage: saveLanguage,
  fetchUserWithAllAttributes,
})(HeaderFirstVersion);
