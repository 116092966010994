//contains all translated definitions in portuguese
const PT_DEFINITIONS = [
  {
    id: 1,
    word: "Sentir",
    description:
      "Os sentimentos são a perceção subjetiva das emoções, ou seja, a forma como cada um de nós interpreta uma emoção e lhe dá um nome. Assim, dependendo da nossa personalidade, crenças e experiências passadas, interpretamos as nossas emoções de uma forma diferente, e isso produz sentimentos diferentes.",
  },
  {
    id: 2,
    word: "Emoção",
    description:
      "As emoções são as respostas automáticas que damos a certos estímulos, tais como a tristeza que sentimos quando perdemos alguém próximo, por exemplo. As emoções estão ligadas ao nosso cérebro e, por isso, produzem alterações psicofisiológicas que nos permitem adaptar-nos ao ambiente que nos rodeia. Quando somos confrontados com uma determinada situação, as nossas emoções ativam os nossos pensamentos, atitudes, e crenças sobre o que está a acontecer. Deste modo, influenciam a forma como percebemos e interpretamos essa situação, o que acaba por determinar a forma como agimos.",
  },
  {
    id: 3,
    word: "Energia",
    description:
      "Energia pode ser definida como uma sensação geral de vitalidade, física e mental. Habitualmente, quando nos sentimos energizados, o nosso estado de espírito melhora, o nosso desempenho aumenta, e somos capazes de influenciar quem nos rodeia de uma forma positiva.",
  },
  {
    id: 4,
    word: "Descansar",
    description:
      "Descansar é o ato ou capacidade de relaxar, dormir e recuperar forças, sem sentir qualquer culpa.",
  },
  {
    id: 5,
    word: "Conforto",
    description:
      "Estar confortável significa estar bem, cómodo/a e rodeado/a de condições que fomentam o meu bem-estar geral e respeitam as minhas necessidades, físicas e mentais.",
  },
  {
    id: 6,
    word: "Segurança",
    description:
      "A segurança é a necessidade humana mais importante, e pode ser descrita como um estado no qual nos sentimos livres de qualquer perigo ou dano. A capacidade de sentir segurança é influenciada por diferentes condições externas, mas também pela nossa perceção individual do perigo. Assim, é importante garantir que nos sentimos protegidos e confortáveis, não só em relação ao mundo exterior, mas também na nossa própria pele e na nossa vida quotidiana.",
  },
  {
    id: 7,
    word: "Estado de espírito",
    description:
      "Estados de espírito são estados internos associados a sentimentos, que se manifestam de forma menos intensa, mas mais prolongada, do que as emoções. As emoções podem ser exteriorizadas, enquanto que os estados de espírito não. Alguns exemplos de estados de espírito são: triste, deprimido/a, desapegado/a, tranquilo/a.",
  },
  {
    id: 8,
    word: "Consciência",
    description:
      "Estar consciente significa ter perceção de algo, estar alerta e ciente do que se passa em nós e à nossa volta. A consciência é uma qualidade e uma competência extremamente importante para o nosso bem-estar e, como qualquer outra competência, pode ser treinada todos os dias.",
  },
  {
    id: 9,
    word: "Ansiedade",
    description:
      "A ansiedade é uma emoção caracterizada por sentimentos de tensão, pensamentos de preocupação e mudanças físicas, tais como o aumento da pressão arterial. Todos nós experienciamos ansiedade, em diferentes níveis. Dependendo da sua intensidade, a ansiedade pode ser um catalisador do foco e concentração, mas também pode ser paralisante. Pessoas que sofrem de distúrbios de ansiedade são invadidas por pensamentos negativos e preocupações de uma forma recorrente, o que faz com que, muitas vezes, tendam a evitar situações de maior tensão. Em casos mais extremos, podem também experienciar sintomas físicos, tais como transpiração, tremores, tonturas ou aceleramento batimento.",
  },
  {
    id: 10,
    word: "Stress",
    description:
      "O stress é uma sensação de tensão emocional e/ou física. É uma reação natural do nosso corpo a um determinado desafio ou exigência. Assim, o stress pode surgir como resposta a qualquer acontecimento ou pensamento que nos faça sentir mais nervosos/as, zangados/as ou frustrados/as, por exemplo.",
  },
  {
    id: 11,
    word: "Autoestima",
    description:
      "A autoestima pode ser definida como a opinião geral que temos de nós (como nos sentimos em relação às nossas próprias capacidades e limitações). Quando temos uma autoestima elevada, sentimo-nos bem connosco próprios e tendemos a ver-nos como merecedores do respeito dos outros. Quando temos uma baixa autoestima, sentimo-nos menos bem connosco próprios, e tendemos a dar pouco valor às nossas próprias opiniões e ideias.",
  },
  {
    id: 12,
    word: "Autoconfiança",
    description:
      "A autoconfiança pode ser definida como a nossa atitude relativamente às nossas aptidões e capacidades. Ser autoconfiante significa aceitar e confiar em si próprio, ter um sentido de controlo da sua vida, conhecer bem os seus pontos fortes e fracos, e ter uma visão positiva sobre si próprio. As pessoas autoconfiantes têm capacidade de estabelecer expectativas e objetivos realistas, comunicar assertivamente e lidar com as críticas.",
  },
  {
    id: 13,
    word: "Conexão",
    description:
      "Conexão é a capacidade de nos sentirmos ligados/as ao nosso próprio corpo e próximos/as dos outros e do que nos rodeia. Sermos capazes de nos conectarmos connosco próprios permite-nos reconhecer as nossas necessidades físicas e psicológicas. Sermos capazes de estabelecer interações positivas com quem nos rodeia, de forma a que todos sejam ouvidos, vistos, conhecidos e valorizados, é o que nos faz sentir verdadeiramente ligados/as aos outros.",
  },
  {
    id: 14,
    word: "Desperto/a",
    description:
      "Estar desperto/a ou alerta significa estar atento/a e pronto/a para enfrentar o perigo ou emergência. Já o termo mental remete para algo relacionado com o processo de pensar. Assim, quando estamos mentalmente alerta ou despertos, estamos mais e melhor preparados/as para enfrentar um novo desafio, por exemplo, ou para lidar com um qualquer possível problema ou perigo.",
  },
  {
    id: 15,
    word: "Comunidade",
    description:
      "Comunidade é um conjunto de pessoas que partilham um território comum e satisfazem as suas necessidades físicas e sociais básicas através da interação diária umas com as outras.",
  },
  {
    id: 16,
    word: "Compaixão",
    description:
      'Compaixão significa literalmente "sofrer em conjunto". Pode ser definida como o sentimento que surge quando somos confrontados/as com o sofrimento de outra pessoa e nos sentimos motivados/as para aliviar esse sofrimento. A compaixão pode ser dirigida aos outros ou a nós próprios.',
  },
  {
    id: 17,
    word: "Empatia",
    description:
      "Empatia é a capacidade psicológica de nos identificarmos com outra pessoa e de nos colocarmos no seu lugar, procurando imaginar como agiríamos ou pensaríamos caso estivéssemos na mesma situação, aliada à capacidade de sentirmos as emoções que essa pessoa está a sentir. Existem três componentes fundamentais na empatia: cognitiva, compassiva e emocional.",
  },
  {
    id: 18,
    word: "Inteligência emocional",
    description:
      "A inteligência emocional define-se como a capacidade de gerir as nossas próprias emoções e de compreender as emoções das pessoas que nos rodeiam. Há cinco elementos-chave para a inteligência emocional: autoconsciência, autoregulação, motivação, empatia, e capacidades sociais.",
  },
  {
    id: 19,
    word: "Conexão social",
    description:
      "Conexão social é a capacidade de fomentar e manter ligações sociais. Ligações sociais são as relações que estabelecemos com as pessoas à nossa volta; podem ser mais próximas, com familiares, amigos e colegas de trabalho, ou mais distantes, com pessoas que conhecemos casualmente. Sermos capazes de nos ligarmos socialmente a outros é o que nos permite sentirmo-nos parte de um grupo, apoiados/as, integrados/as, vistos/as e ouvidos/as. A conexão social ajuda ainda a regular as emoções e pode conduzir a uma maior autoestima, prevenir a ansiedade ou depressão, e até melhorar o sistema imunitário.",
  },
  {
    id: 20,
    word: "Propósito",
    description:
      "Propósito é um fim último, um objetivo ou uma meta a alcançar. Quando aplicado à nossa vida, o propósito é aquilo que dá sentido e significado à nossa existência, que nos oferece uma orientação e nos guia no nosso caminho individual, nas nossas decisões, escolhas e nos nossos comportamentos no dia-a-dia.",
  },
  {
    id: 21,
    word: "Reconhecimento",
    description:
      "Reconhecimento pode ser definido como um sentimento ou expressão de admiração, aprovação ou gratidão. É uma apreciação crítica favorável sobre nós. Outros à nossa volta podem mostrar reconhecimento ou apreço por nós ou pelo nosso desempenho, mas isso não significa que o vejamos como tal. Para sermos capazes de perceber e experienciar o reconhecimento de outros é fundamental que tenhamos uma autoconsciência e uma autoestima saudáveis.",
  },
];

/**
 * translates all definitions
 * @param {Array} definitions
 * @param {String} language
 * @returns {Array}
 */
const translateDefinitions = (definitions, language, gender) => {
  if (language === "pt-PT")
    return definitions.map((def) => {
      const thisDef = PT_DEFINITIONS.find((ptDef) => {
        return ptDef.id === def.id;
      });

      const substs = () => {
        if (!gender) {
          return { singular: "o/a", plural: "os/as" };
        } else if (gender === "M") {
          return { singular: "o", plural: "os" };
        } else if (gender === "F") {
          return { singular: "a", plural: "as" };
        } else {
          return { singular: "o/a", plural: "os/as" };
        }
      };
      const thisSubsts = substs();

      return {
        ...thisDef,
        description: thisDef.description
          .replaceAll("o/a", thisSubsts.singular)
          .replaceAll("os/as", thisSubsts.plural),
      };
    });
  else return definitions;
};

//contains the translated glossary title
const STATICS = {
  MAIN_TITLE: {
    "en-GB": "Glossary",
    "pt-PT": "Glossário",
  },
  MAIN_BODY: {
    "en-GB": "Need some help? Click here",
    "pt-PT": "Precisas de ajuda? Clica aqui",
  },
};

/**
 * returns the glossary main title
 * @param {String} language
 * @returns
 */
const getMainTitleGlossary = (language) => {
  const { MAIN_TITLE, MAIN_BODY } = STATICS;
  return { MAIN_TITLE: MAIN_TITLE[language], MAIN_BODY: MAIN_BODY[language] };
};

export { translateDefinitions, getMainTitleGlossary };
