import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  fetchUserWithAllAttributes,
  saveRoles,
  setFlashProps,
} from "../../../store/actions";
import backend from "../../../core/apis/backend";
import login_backend from "../../../core/apis/login_backend";
import Loading from "../../../components/loading/Loading";
import jwt_decode from "jwt-decode";
import chooseNextPath from "../services/chooseNextPath";
import { useQuery } from "../../Geral/hooks/useQuery";
import { redirectToLandingPage } from "../../../core/Routing/RoutesRedirects";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";
import { isThereCoreSurveyResultToday } from "../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import { createAnalytic } from "../../../utils/helpers/geralHelpers/BackendHelper";
import {
  EVENTS,
  getLoginWithIdentityProviderEvent,
  getSignupWithIdentityProviderEvent,
} from "../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: LoginComponent
 * This component handles the authentication part of violet after coming from keycloak
 * @param {Function} saveRoles
 * @param {Function} fetchUserWithAllAttributes
 * @returns {Component}
 * */
const LoginComponent = ({
  saveRoles,
  fetchUserWithAllAttributes,
  currentUser,
  preliminaryResult,
  language,
}) => {
  const [authorized, setAuthorized] = useState(true);

  let query = useQuery();

  useEffect(() => {
    const getToken = async () => {
      try {
        //tries to get access token from backend using code from keycloak
        const response = await login_backend.get("/auth", {
          params: { code: query.get("code") },
        });

        //decodes the access token
        let decoded = jwt_decode(response.data);

        let roles = [];

        roles.push(decoded.resource_access.login_api.roles[0]);
        roles.push(decoded.resource_access.wellbeing_api.roles[0]);

        //saves the user roles on redux
        saveRoles(roles);

        try {
          console.log(64, 1267);
          //checks if the users exists on our database
          const { data } = await login_backend.get("/find_by_email", {
            params: { email: decoded.email },
          });

          console.log(70, 1267);

          //if the user is not verified he cant login so we kick him out
          if (!data.data.attributes.verified) {
            setFlashProps(FLASH_TYPES.EMAIL_NOT_VERIFIED);
            setAuthorized(false);
            return;
          }

          console.log(79, 1267);

          await login_backend.put("/updateLanguage", {
            user_id: data.data.attributes.user_id,
            language,
          });

          console.log(87, 1267);
          console.log(data?.data?.attributes?.user_id);

          //fetch all data from the user to save on redux
          await fetchUserWithAllAttributes(data.data.attributes.user_id);

          console.log(91, 1267);

          //check if the user already answered today
          const coreSurveyResultToday = await isThereCoreSurveyResultToday(
            data.data.attributes.user_id,
            CORE_SURVEY_ID
          );

          console.log(99, 1267);

          //if a user answered the trial survey and has not answered today a result will be created
          if (preliminaryResult.surveyCode && !coreSurveyResultToday) {
            console.log(103, 1267);
            await backend.post("/createWithSurveyCode", {
              surveyCode: preliminaryResult.surveyCode,
              userId: data.data.attributes.user_id,
            });
          }

          console.log(109, 1267);

          //if we are on production saves analytics data

          if (decoded.identity_provider) {
            createAnalytic(
              getLoginWithIdentityProviderEvent(decoded.identity_provider)
            );
          } else {
            createAnalytic(EVENTS.LOGIN_INTERNAL);
          }

          console.log(121, 1267);
        } catch (err) {
          //if the user dosnt exist on our database but we have a token that means it was a social login user
          //login in for the first time, so we need to create him on our side of the database

          //creates the user object
          const userObjForLoginBackend = {
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            email: decoded.email,
            identity_provider: decoded.identity_provider,
            isSocialLogin: true,
          };

          console.log("HERE0");

          //creates the user, if there is a survey code, creates user with result if not only the user
          const { data } = await login_backend.post("/users", {
            ...userObjForLoginBackend,
            language,
          });
          console.log("HERE1");

          console.log(data, 1267, 145);

          if (preliminaryResult.surveyCode) {
            await backend.post("/wellbeing_users/full", {
              user_id: data.user.data.attributes.user_id,
              surveyCode: preliminaryResult.surveyCode,
            });
            console.log(1267, 152);
          } else {
            await backend.post("/wellbeing_users", {
              user_id: data.user.data.attributes.user_id,
            });
          }
          console.log(1267, 158);

          //fetches the user and saves it on redux
          await fetchUserWithAllAttributes(data.user.data.attributes.user_id);

          console.log(1267, 163);

          //saves analytics data

          createAnalytic(
            getSignupWithIdentityProviderEvent(decoded.identity_provider)
          );
          console.log(1267, 170);
        }
      } catch (error) {
        //if all goes wrong user is not allowed to enter and is kicked out
        setAuthorized(false);
      }
    };
    getToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //if we have current user, navigates him into the app
    if (currentUser) chooseNextPath(currentUser);
  }, [currentUser]);

  useEffect(() => {
    //if the user is not authorized kick him to landing page
    if (!authorized) redirectToLandingPage();
  });

  return <Loading />;
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    preliminaryResult: state.preliminaryResult,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  saveRoles: saveRoles,
  fetchUserWithAllAttributes: fetchUserWithAllAttributes,
})(LoginComponent);
