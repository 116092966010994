import { capitalize } from "@material-ui/core";
import LikeSVG from "../../../components/imageComponents/LikeSVG";
import backend from "../../../core/apis/backend";
import {
  CORE_SURVEY_ID,
  DIMENSIONS_TO_EVALUATE,
  MAX_POINTS_TARGET,
} from "../../../utils/constants/constants";
import { TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT } from "../../../utils/constants/copies/variableCopies.helper";
import {
  fetchDimensions,
  fetchFeels,
} from "../../../utils/helpers/geralHelpers/BackendHelper";

/**
 * makes all needed calculations to render the result on screen
 * @param {Object} answerValues
 * @param {Object} survey
 * @returns {Object}
 */
const getResultReadyToRender = async (answerValues, survey) => {
  const feels = await fetchFeels();
  const dimensions = await fetchDimensions();

  //gets the majorator depending on the choosen feel
  let majorator = getMajorator(feels, answerValues);

  //gets the dimensions who will be majorated
  let dimensionsToMajorate = getDimensionsToMajorate(dimensions, answerValues);

  //calculates the final result
  let result = calculateResult(
    majorator.weight,
    dimensionsToMajorate,
    answerValues
  );

  //gets the result copy for this score
  let possibleResultsForThisScore = getThisScorePossibleResults(
    result.finalResult,
    survey
  );

  //gets the zone for this score
  let zone = getScoreZone(possibleResultsForThisScore);

  //gets the dimension that will be used for the copy
  let dimensionForCopy = getDimensionForCopy(
    result.sortedDimensionsValues,
    zone,
    answerValues
  );

  //gets the lowest dimension in the score
  let lowestDimension = getLowerDimensions(result.sortedDimensionsValues);

  //gets the highest dimension in the score
  let highestDimension = getHigherDimensions(
    result.sortedDimensionsValues,
    lowestDimension
  );

  //gets the copy content and header for the dimension for copy
  let { copyHeader, copyId } = getCopyContentFromPossibleResults(
    dimensionForCopy.dimension,
    dimensions,
    possibleResultsForThisScore
  );

  return {
    copyHeader,
    copyId,
    zone,
    dimensionValues: result.sortedDimensionsValues,
    total: result.finalResult,
    feel_id: majorator.feel_id,
    numberOfDimensionsThatContribute: dimensionForCopy.quantity,
    highestDimension: highestDimension.dimension,
    lowestDimension: lowestDimension.dimension,
  };
};

/**
 * this calculates the majorator
 * @param {Array} feels
 * @param {Object} answerValues
 * @returns {Object}
 */
const getMajorator = (feels, answerValues) => {
  const thisFeel = feels.find((feel) => {
    return feel.attributes.feel_type === answerValues.feel.feel_type;
  });

  return {
    weight: thisFeel.attributes.weight,
    feel_id: thisFeel.attributes.id,
  };
};

/**
 * this calculates the dimensions to majorate
 * @param {Array} dimensions
 * @param {Object} answerValues
 * @returns {Array}
 */
const getDimensionsToMajorate = (dimensions, answerValues) => {
  let dimensionsToMajorate = [];

  for (let dimension of dimensions) {
    for (let choosen_dimension of answerValues.feel.dimensions_choosen) {
      if (dimension.ids.includes(choosen_dimension.dimension_id)) {
        dimensionsToMajorate.push(dimension.dimension_type);
      }
    }
  }

  return dimensionsToMajorate;
};

/**
 * this sorts all dimensions by their scores
 * @param {Array} resultsByDimension
 * @returns {Array}
 */
const sortDimensions = (resultsByDimension) => {
  return resultsByDimension.sort((a, b) => {
    if (a.value > b.value) {
      return -1;
    }
    if (a.value < b.value) {
      return 1;
    }
    return 0;
  });
};

/**
 * this calculates each dimension result
 * @param {Int} first_value
 * @param {Int} second_value
 * @param {Int} majorator
 * @returns {Int}
 */
const calculateDimensionResult = (first_value, second_value, majorator) => {
  let total = (first_value + second_value) * (1 + majorator / 100);
  return (total * 100) / MAX_POINTS_TARGET;
};

/**
 * this calculates the full result
 * @param {Int} majorator
 * @param {Array} dimensionsToMajorate
 * @param {Object} answerValues
 * @returns {Object}
 */
const calculateResult = (majorator, dimensionsToMajorate, answerValues) => {
  let resultsByDimension = [
    {
      dimension: "physical",
      dimension_id: 1,
      value: calculateDimensionResult(
        answerValues.physical["physical health"][0],
        answerValues.physical["environmental mastery"][0],
        dimensionsToMajorate.includes("physical") ? majorator : 0
      ),
    },
    {
      dimension: "mental",
      dimension_id: 3,
      value: calculateDimensionResult(
        answerValues.mental["feeling"][0],
        answerValues.mental["thinking"][0],
        dimensionsToMajorate.includes("mental") ? majorator : 0
      ),
    },
    {
      dimension: "social",
      dimension_id: 5,
      value: calculateDimensionResult(
        answerValues.social[0],
        answerValues.social[1],
        dimensionsToMajorate.includes("social") ? majorator : 0
      ),
    },
    {
      dimension: "work",
      dimension_id: 6,
      value: calculateDimensionResult(
        answerValues.work["balance & recognition"][0],
        answerValues.work["reward"][0],
        dimensionsToMajorate.includes("work") ? majorator : 0
      ),
    },
  ];

  return {
    finalResult: Math.round(
      (resultsByDimension[0].value +
        resultsByDimension[1].value +
        resultsByDimension[2].value +
        resultsByDimension[3].value) /
        DIMENSIONS_TO_EVALUATE
    ),
    sortedDimensionsValues: sortDimensions(resultsByDimension),
  };
};

/**
 * this calculates the possible result copy for this score
 * @param {Int} finalResult
 * @param {Object} survey
 * @returns {Array}
 */
const getThisScorePossibleResults = (finalResult, survey) => {
  return survey.possible_results.filter((possible_result) => {
    return (
      possible_result.attributes.min_value <= finalResult &&
      possible_result.attributes.max_value >= finalResult
    );
  });
};

/**
 * this calculates the score zone
 * @param {Array} possibleResults
 * @returns {String}
 */
const getScoreZone = (possibleResults) => {
  return possibleResults.filter((possible_result) => {
    return !possible_result.attributes.possible_result_content.attributes
      .dimension_id;
  })[0].attributes.possible_result_content.attributes.content;
};

/**
 * this gets the higher dimensions on this score
 * @param {Array} sortedDimensionsValues
 * @param {Object} lowestDimension
 * @returns {Object}
 */
const getHigherDimensions = (sortedDimensionsValues, lowestDimension) => {
  let higherDimensions = [sortedDimensionsValues[0]];

  for (let i = 1; i < sortedDimensionsValues.length; i++) {
    if (sortedDimensionsValues[i].value === higherDimensions[0].value) {
      higherDimensions.push(sortedDimensionsValues[i]);
    } else {
      break;
    }
  }

  let random = 0;
  do {
    random = Math.floor(Math.random() * higherDimensions.length);
  } while (
    higherDimensions[random].dimension_id === lowestDimension.dimension_id
  );

  return {
    dimension: higherDimensions[random],
    quantity: higherDimensions.length,
  };
};

/**
 * this gets the lower dimensions on this score
 * @param {Array} sortedDimensionsValues
 * @returns {Object}
 */
const getLowerDimensions = (sortedDimensionsValues) => {
  let lowerDimensions = [
    sortedDimensionsValues[sortedDimensionsValues.length - 1],
  ];

  for (let i = sortedDimensionsValues.length - 2; i >= 0; i--) {
    if (sortedDimensionsValues[i].value === lowerDimensions[0].value) {
      lowerDimensions.push(sortedDimensionsValues[i]);
    } else {
      break;
    }
  }

  return {
    dimension:
      lowerDimensions[Math.floor(Math.random() * lowerDimensions.length)],
    quantity: lowerDimensions.length,
  };
};

/**
 * this calculates the dimension that will be used to get the copy
 * @param {Array} sortedDimensionsValues
 * @param {String} zone
 * @param {Object} answerValues
 * @returns {Array}
 */
const getDimensionForCopy = (sortedDimensionsValues, zone, answerValues) => {
  const lowerDimensions = getLowerDimensions(sortedDimensionsValues);

  if (
    zone === "red zone" ||
    answerValues.feel.feel_type === "stressed" ||
    answerValues.feel.feel_type === "sad"
  ) {
    return lowerDimensions;
  } else {
    return getHigherDimensions(sortedDimensionsValues, lowerDimensions);
  }
};

/**
 * gets the content of the copy depending on the calculated possible result
 * @param {Object} dimensionForCopy
 * @param {Array} dimensions
 * @param {Array} possibleResults
 * @returns {Object}
 */
const getCopyContentFromPossibleResults = (
  dimensionForCopy,
  dimensions,
  possibleResults
) => {
  const dimensionIdsForDimensionForCopy = dimensions.find((dimension) => {
    return dimension.dimension_type === dimensionForCopy.dimension;
  }).ids;

  const dimensionCopy = possibleResults.filter((possible_result) => {
    return dimensionIdsForDimensionForCopy.includes(
      possible_result.attributes.possible_result_content.attributes.dimension_id
    );
  });

  return {
    copyHeader:
      dimensionCopy[0].attributes.possible_result_content.attributes.header,
    copyId: dimensionCopy[0].id,
  };
};

/**
 * this saves the anonymous result on trial survey completion
 * @param {String} survey_code
 * @param {Int} total
 * @param {Array} dimensionValues
 * @param {Int} highestDimension
 * @param {Int} lowestDimension
 * @param {Int} highestTip
 * @param {Int} lowestTip
 */
const saveAnonymousScore = async (
  survey_code,
  total,
  dimensionValues,
  highestDimension,
  lowestDimension,
  highestTip,
  lowestTip
) => {
  try {
    await backend.post("/anonymous_results", {
      survey_id: CORE_SURVEY_ID,
      survey_code: survey_code,
      total: total,
      highestDimension: highestDimension,
      lowestDimension: lowestDimension,
      highestTip: highestTip,
      lowestTip: lowestTip,
      resultValues: dimensionValues.map((value) => {
        return {
          dimension_id: value.dimension_id,
          value: Math.round(value.value),
        };
      }),
    });
  } catch (error) {}
};

const getHeaderDimensions = (language) => {
  if (language === "en-GB") {
    return [
      "mental dimension",
      "physical dimension",
      "social dimension",
      "work dimension",
    ];
  } else {
    return [
      "dimensão mental",
      "dimensão física",
      "dimensão social",
      "dimensão trabalho",
    ];
  }
};

/**
 * formats the focus copy
 * @param {String} mood
 * @param {String} focus
 * @param {String} language
 * @returns {String}
 */
const formatYellowZoneFocus = (mood, focus, language) => {
  if (language === "en-GB") {
    focus = focus.replace(
      "the biggest contributor/ one standing out",
      "pulling you up/dragging you down these days"
    );

    if (mood === "stressed" || mood === "sad") {
      return focus.replace("pulling you up/", "");
    } else {
      return focus.replace("/dragging you down", "");
    }
  } else {
    if (mood === "stressed" || mood === "sad") {
      return focus.replace("/ destaca-se pela positiva", "");
    } else {
      return focus.replace("destaca-se pela negativa/", "");
    }
  }
};

const idunno = ({ zone, numberOfDimensionsThatContribute, mood }) => {
  if (zone === "yellow zone" || zone === "zona amarela") {
    switch (mood) {
      case "stressed":
      case "sad":
        return "struggling";
      default:
        return "fine";
    }
  } else if (numberOfDimensionsThatContribute > 1) {
    return "more_dimensions";
  } else {
    return "normal";
  }
};

const formatFocus = ({
  language,
  zone,
  header,
  mood,
  numberOfDimensionsThatContribute,
}) => {
  if (language === "en-GB") {
    return zone === "yellow zone"
      ? formatYellowZoneFocus(mood, header, language)
      : numberOfDimensionsThatContribute > 1
      ? header.replace("biggest contributor/", "")
      : header.replace("/ one standing out", "");
  } else {
    return zone === "zona amarela"
      ? formatYellowZoneFocus(mood, header, language)
      : numberOfDimensionsThatContribute > 1
      ? header.replace("a que se destaca pela positiva/", "")
      : header.replace("/ uma das que se destaca pela positiva", "");
  }
};

const getPreliminaryPossibleResult = ({ language, survey, copyId }) => {
  if (language !== "en-GB") {
    return TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT.find(
      (possible_result) => possible_result.id === copyId
    ).header;
  } else {
    return survey.possible_results.find(
      (possible_result) => possible_result.id === copyId
    ).attributes.possible_result_content.attributes.header;
  }
};

/**
 * formats the header zone part
 * @param {String} language
 * @param {Object} survey
 * @param {Object} preliminaryResult
 * @returns {String}
 */
const getPreliminaryResultHeader = ({
  language,
  survey,
  preliminaryResult,
}) => {
  const { zone, mood, numberOfDimensionsThatContribute } = preliminaryResult;

  const header = getPreliminaryPossibleResult({
    language,
    survey,
    copyId: preliminaryResult.copyId,
  });

  if (!header) return "";

  const dimension = getHeaderDimensions(language).find((dim) =>
    header.includes(dim)
  );

  const thiese = idunno({ zone, mood, numberOfDimensionsThatContribute });

  const formattedFocus = formatFocus({
    language,
    zone,
    header,
    mood,
    numberOfDimensionsThatContribute,
  });

  return {
    COPY_PART_1: capitalize(formattedFocus.split(dimension)[0].trim()),
    DIMENSION: dimension,
    COPY_PART_2: formattedFocus.split(dimension)[1].trim(),
    IMAGE: <LikeSVG type={thiese === "struggling" ? "lowest" : "highest"} />,
  };
};

export {
  getResultReadyToRender,
  getMajorator,
  saveAnonymousScore,
  getPreliminaryResultHeader,
};
