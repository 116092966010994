import React, { useEffect } from "react";
import { useResize } from "../../modules/Geral/hooks/useResize";
import {
  MY_ANALYTICS_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
} from "../../utils/constants/constants";

/**
 * Functional Component: ScrollToTopArrow
 * The arrow used for auto scrolling to top on welcome back, results, analytics and preliminary results pages
 *
 * @param {String} page
 * @returns {Element}
 * */
const ScrollToTopArrow = ({ page }) => {
  useResize();

  /**
   * defines when the arrow appears on screen
   * @returns {void}
   * */
  const makeAppearFunction = () => {
    if (window.innerWidth < 576) {
      return null;
    }

    let arrow = document.getElementById("scroll-up-arrow");

    if (!arrow) {
      return;
    }

    if (page === PRELIMINARY_RESULTS_PAGE_ID) {
      if (window.scrollY > 320) {
        arrow.style.visibility = "visible";
      } else {
        arrow.style.visibility = "hidden";
      }
    } else {
      if (window.scrollY > 500) {
        arrow.style.visibility = "visible";
      } else {
        arrow.style.visibility = "hidden";
      }
    }
  };

  //adds and event listener for scroll to check if its time for the arrow to appear or disappear
  useEffect(() => {
    window.addEventListener("scroll", makeAppearFunction);

    return () => {
      window.removeEventListener("scroll", makeAppearFunction);
    };
  });

  //no arrow bellow 576px width
  if (window.innerWidth < 576) {
    return null;
  }

  return (
    <div
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      style={{ position: "relative", zIndex: 10 }}
    >
      <svg
        id="scroll-up-arrow"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "fixed",
          bottom:
            page === PRELIMINARY_RESULTS_PAGE_ID
              ? "21%"
              : page === MY_ANALYTICS_PAGE_ID
              ? "15%"
              : "20%",
          right: "1.2%",
          width: window.innerWidth < 992 ? "25px" : "35px",
          transform: "rotate(90deg)",
          cursor: "pointer",
          visibility: "hidden",
        }}
      >
        <circle
          cx="25"
          cy="25"
          r="25"
          fill={page === PRELIMINARY_RESULTS_PAGE_ID ? "white" : "#604586"}
        />
        <path
          d="M27 18L19 25L27 32"
          stroke={page === PRELIMINARY_RESULTS_PAGE_ID ? "black" : "white"}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ScrollToTopArrow;
