import { useEffect } from "react";
import { withRouter } from "react-router-dom";

/**
 * Functional Component: ScrollToTop
 * Scrolls the window to the top position
 * @param {Object} history
 * @returns {void}
 * */
function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      document.documentElement.style.scrollBehavior = "auto";
      setTimeout(() => window.scrollTo(0, 0), 5);
      setTimeout(
        () => (document.documentElement.style.scrollBehavior = "smooth"),
        5
      );
    });
    return () => unlisten();

    //eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
