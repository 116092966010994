import React, { useCallback, useEffect, useState } from "react";
import close_button from "../../assets/svg/close-button.svg";
import { noop } from "../../utils/helpers/geralHelpers/RandomHelper";
import "./CustomModals.css";

/**
 * Functional Component: CustomModal
 * The background of any modal
 *
 * @param {Boolean} open: open state controlled by parent
 * @param {Function} onClick: the function that is invoked when it is clicked
 * @param {Object} customStyles: custom css styles controlled by parent
 * @returns {Element}: a black backgorund
 */
const CustomModal = ({ open, onClick = noop, customStyles }) => {
  const [isOpen, setOpenStatus] = useState(open);

  useEffect(() => setOpenStatus(open), [open]);

  return (
    <div
      className={`custom-modal__container ${isOpen ? "on" : "off"}`}
      onClick={onClick}
      style={customStyles}
    />
  );
};

/**
 * Functional Component: RegularModal
 * A usual modal, with background and some custom text
 *
 * @param {Boolean} open: open state controlled by parent
 * @param {Object} customStyles: custom css styles controlled by parent
 * @param {String, Int or Elements} children: what will be rendered inside the modal
 * @param {Function} onChange: function that is triggered when the open status is changed
 * @param {Function} onClose: function that is triggered when the modal is closed
 * @param {Boolean} canClose: boolean to determine if the modal can close
 * @param {String} customClassName: custom css classname for the content part of the modal, controlled by parent
 * @param {Object} backgroundStyles: custom css styles for the background controlled by parent
 * @returns {Element}
 */
const RegularModal = ({
  open,
  customStyles = {},
  children = "Sample Text",
  onChange = noop,
  onClose = noop,
  canClose,
  customClassName,
  backgroundStyles,
}) => {
  const [isOpen, setOpenStatus] = useState();

  //handles the opening and closing of the modal
  const closeTooltip = useCallback(() => {
    if (canClose) {
      setOpenStatus(false);
      onChange(false);
    }
  }, [canClose, onChange]);

  useEffect(() => setOpenStatus(open), [open]);

  return (
    <>
      <CustomModal
        open={isOpen}
        onClick={closeTooltip}
        customStyles={backgroundStyles}
      />
      <div
        style={{ ...customStyles }}
        className={`regular-modal ${isOpen ? "on" : "off"} ${customClassName}`}
      >
        {canClose ? (
          <div
            onClick={() => {
              closeTooltip();
              onClose();
            }}
            className="regular-modal__close-button"
          >
            <img alt="close-button" src={close_button} />
          </div>
        ) : null}
        {children}
      </div>
    </>
  );
};

export { CustomModal, RegularModal };
