import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import { connect } from "react-redux";
import {
  cleanAllRedux,
  cleanPreliminaryResultData,
  fetchUserWithAllAttributes,
  saveQueryParams,
  saveLanguage,
} from "../../../store/actions";
import chooseNextPath from "../../Auth/services/chooseNextPath";
import { useQuery } from "../hooks/useQuery";
import login_backend from "../../../core/apis/login_backend";
import Loading from "../../../components/loading/Loading";
import {
  redirectStartSurvey,
  redirectToMeetVioletPage,
  redirectToPreliminaryResultsPage,
  redirectToTrialSurveyPage,
  redirectToWelcomeSurveyPage,
} from "../../../core/Routing/RoutesRedirects";
import { hasTrialResultToday } from "../../../utils/helpers/surveysHelpers/PreliminaryResultsHelper";
import { checkIfThereAreTrialValues } from "../../Surveys/services/trialSurvey.service";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import Footer from "../../../components/footer/Footer";
import DancingEllipses from "../../../components/dancingEllipses/DancingEllipses";
import { MAIN_PURPLE } from "../../../utils/constants/colorsConstants";
import { TRANSPARENT } from "../../../utils/constants/constants";
import { useResize } from "../hooks/useResize";

import Cookies from "universal-cookie";
import { getStaticLandingPageCopies } from "../../../utils/constants/copies/copies.helper";
import { cookiesOptions } from "../../../utils/helpers/cookiesHelpers/cookiesHelper";
import { createAnalytic } from "../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: LandingPage
 * This is the violet landing page, the first interaction any user has with violet
 *
 * @param {Array} roles
 * @param {Object} currentUser
 * @param {Function} saveQueryParams
 * @param {Object} preliminaryResult
 * @param {Object} answerValues
 * @param {Function} fetchUserWithAllAttributes
 * @param {Function} cleanPreliminaryResultData
 * @param {String} language
 * @param {Function} saveLanguage
 * @returns {Element}
 * */
const LandingPage = ({
  roles,
  currentUser,
  saveQueryParams,
  preliminaryResult,
  answerValues,
  fetchUserWithAllAttributes,
  cleanPreliminaryResultData,
  language,
  saveLanguage,
}) => {
  const [display, setDisplay] = useState(false);
  const cookies = new Cookies();

  //contains all copies for the landing page
  const SELF_COPIES = getStaticLandingPageCopies({ language: language });

  //const { t } = useTranslation();

  var slideIndex = 0;

  let query = useQuery();

  //this refetches the user to update user data that may have been changed in another session on another device
  useEffect(() => {
    const refetchUser = async () => {
      await fetchUserWithAllAttributes(currentUser.attributes.user_id);
    };

    if (currentUser) refetchUser();

    //eslint-disable-next-line
  }, []);

  //checks if theres a current user on redux, if it exists sends him inside the app with choose next path
  //it also gets query params
  useEffect(() => {
    const checkUserAndGetParams = async () => {
      if (roles.length > 0) {
        try {
          await login_backend.get("/sessionExpired");
          await chooseNextPath(currentUser);
        } catch (e) {
          console.error(e);
        }
        setDisplay(true);
      } else {
        let queryParams = {};

        if (query.get("r")) {
          queryParams.r = query.get("r");
        }

        if (query.get("toFill")) {
          queryParams.toFill = query.get("toFill");
        }

        saveQueryParams(queryParams);

        setDisplay(true);
      }
    };

    checkUserAndGetParams();

    // eslint-disable-next-line
  }, []);

  //if a person has answered the trial survey today then the preliminary results page becomes the landing page
  useEffect(() => {
    if (hasTrialResultToday({ preliminaryResult })) {
      redirectToPreliminaryResultsPage();
    } else {
      cleanPreliminaryResultData();
    }

    // eslint-disable-next-line
  }, []);

  //if the query params include the check survey param, checks if the user has an incomplete trial survey and sends him to
  //complete it
  useEffect(() => {
    if (query.get("checkSurvey")) {
      if (checkIfThereAreTrialValues(answerValues)) {
        redirectToTrialSurveyPage();
      } else if (answerValues.feel.feel_type) {
        redirectToWelcomeSurveyPage();
      }
    }
  });

  //detects if a language cookie is already in place and
  //if it is saves the pre determined language
  useEffect(() => {
    if (query.get("lang")) {
      saveLanguage(query.get("lang"));
      cookies.set("language", query.get("lang"), cookiesOptions);
    }

    //eslint-disable-next-line
  }, []);

  //detects the browser default language and if it is the first time
  //the user visits the website it saves that one as the predetermined language
  useEffect(() => {
    if (window.navigator.language === "pt" && !cookies.get("language")) {
      saveLanguage("pt-PT");
      cookies.set("language", "pt-PT", cookiesOptions);
    } else if (!cookies.get("language")) {
      saveLanguage("en-GB");
      cookies.set("language", "en-GB", cookiesOptions);
    }
  });

  useResize();

  //if the page can render shows the image slide
  useEffect(() => {
    if (display) showSlides();
    // eslint-disable-next-line
  }, [display]);

  //this handles the images animation on landing page right size
  const showSlides = () => {
    var i;
    var slides = document.getElementsByClassName("Artboard-102x-100-1");
    for (i = 0; i < slides.length; i++) slides[i].style.display = "none";

    slideIndex++;
    if (slideIndex > slides.length) slideIndex = 1;

    const nextSlide = slides[slideIndex - 1];
    if (nextSlide) nextSlide.style.display = "block";
    setTimeout(showSlides, 4000); // Change image every 2 seconds
  };

  if (!display) return <Loading />;

  return (
    <div id="landing-page-container">
      <HeaderFirstVersion />

      <div
        className="top-container"
        style={{ paddingTop: "7%", position: "relative" }}
      >
        <DancingEllipses dontShowSmall={true} />
        <div className="d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column align-items-center left-side-container">
            {window.innerWidth < 576 ? (
              <>
                <h2 className="mb-4 Make-your-well-being-a-priority">
                  {SELF_COPIES.title}
                </h2>
                <div className="d-flex flex-column align-items-center">
                  <div className="texto">
                    <div>{SELF_COPIES.textFirstPartMobile}</div>
                    <div>{SELF_COPIES.textSecondPart}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 className="mb-4 Make-your-well-being-a-priority">
                  {SELF_COPIES.title}
                </h2>
                <div className="d-flex flex-column align-items-center">
                  <div className="texto">
                    <div>
                      <div>{SELF_COPIES.textFirstPart.first}</div>
                      <p>{SELF_COPIES.textFirstPart.second}</p>
                    </div>
                    <div>{SELF_COPIES.textSecondPart}</div>
                  </div>
                </div>
              </>
            )}
            <div className="d-flex flex-column align-items-center flex-sm-row justify-content-between justify-content-lg-start buttons-container">
              <div>
                <div
                  onClick={() => {
                    document.getElementById(
                      "landing-page-start-survey-button"
                    ).style.backgroundColor = "#CDBAE7";

                    redirectStartSurvey({
                      checkIfThereAreTrialValues,
                      answerValues,
                      language,
                    });
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#573C7C";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = MAIN_PURPLE;
                  }}
                  className="Main-Button-Fill-Avenir-Desktop"
                  id="landing-page-start-survey-button"
                >
                  <span className="Start-survey">
                    {SELF_COPIES.buttons.startSurvey}
                  </span>
                </div>
              </div>
              <div>
                <div
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#F9F5FE";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = TRANSPARENT;
                  }}
                  onClick={() => {
                    document.getElementById(
                      "landing-page-meet-violet-button"
                    ).style.backgroundColor = "#CDBAE7";
                    redirectToMeetVioletPage();

                    createAnalytic(EVENTS.MEET_VIOLET_HOMEPAGE);
                  }}
                  id="landing-page-meet-violet-button"
                  className="Main-Button-Outline-Avenir-Desktop "
                >
                  <span className="Meet-violet-button">
                    {SELF_COPIES.buttons.meetViolet}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center image-container">
            <img
              src="images/yoga-guy.png"
              srcSet="images/yoga-guy2x.png, images/yoga-guy3x.png"
              className="firstImage Artboard-102x-100-1"
              alt="logo"
              style={{ display: "block" }}
              id="first-image"
            ></img>
            <img
              src="images/peopleTalking.png"
              srcSet="images/peopleTalking2x.png,
          images/peopleTalking3x.png"
              className="secondImage Artboard-102x-100-1"
              alt="logo"
              style={{ display: "none" }}
            ></img>
            <img
              src="images/yogaGirl3.png"
              srcSet="images/yogaGirl32x.png,
          images/yogaGirl33x.png"
              className="thirdImage Artboard-102x-100-1"
              alt="logo"
              style={{ display: "none" }}
            ></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.roles,
    currentUser: state.currentUser,
    preliminaryResult: state.preliminaryResult,
    answerValues: state.answerValues,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  saveQueryParams: saveQueryParams,
  cleanRedux: cleanAllRedux,
  fetchUserWithAllAttributes,
  cleanPreliminaryResultData,
  saveLanguage,
})(LandingPage);
