import React, { useState } from "react";
import { connect } from "react-redux";
import Footer from "../../../components/footer/Footer";
import login_backend from "../../../core/apis/login_backend";
import "./ConfirmPassword.css";
import logo from "../../../assets/images/logo.svg";
import { redirectToConfirmPasswordPageWithProps } from "../../../core/Routing/RoutesRedirects";
import PasswordInput from "../../../components/inputs/PasswordInput";

/**
 * Functional Component: ConfirmPassword
 * This component is used for the user to confirm his password before changing it on my profile
 * @param {Object} currentUser
 * @returns {Element}
 */
const ConfirmPassword = ({ currentUser }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  //checks if the password the user inputed is correct
  const checkOldPassword = async () => {
    try {
      await login_backend.post("/login", {
        email: currentUser.attributes.email,
        password: password,
      });

      redirectToConfirmPasswordPageWithProps(password);
    } catch (error) {
      setError("Incorrect password.");
      return false;
    }
  };

  return (
    <div id="password-recovery-page">
      <div class="container">
        <div class="row justify-content-center pt-5 pb-5">
          <img src={logo} alt="logo" class="logo"></img>
        </div>
        <div class="row justify-content-center">
          <div class="card card-container ">
            <div class="card-body">
              <div class="card-title d-flex justify-content-center">
                Please confirm your password
              </div>
              <div class="card-text">
                <div class="form-group confirm-password-page__input">
                  <label class="input-labels text-muted">Password</label>
                  <PasswordInput
                    onChange={setPassword}
                    value={password}
                    class="form-control signup-inputs"
                  />
                  <small class="form-text text-muted">{error}</small>
                </div>
                <div class="d-flex justify-content-center agree-button-container">
                  <div
                    onMouseOver={(e) => {
                      if (password) {
                        e.currentTarget.style.backgroundColor = "#4C366B";
                      }
                    }}
                    onMouseOut={(e) => {
                      if (password) {
                        e.currentTarget.style.backgroundColor = "#604586";
                      }
                    }}
                    style={{
                      backgroundColor: password ? "#604586" : "#c2c0c7",
                    }}
                    onClick={() => checkOldPassword()}
                    class="agree-button"
                  >
                    <span class="agree-button-text">Confirm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center justify-content-lg-start">
          <img
            src="images/weightsGirl.png"
            srcSet="images/weightsGirl2x.png, images/weightsGirl3x.png"
            class={`yoga-3`}
            alt=""
          ></img>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(ConfirmPassword);
