import React, { useState } from "react";
import "./PurposeGraph.css";
import { MAIN_PURPLE } from "../../utils/constants/colorsConstants";
import VisibilitySensor from "react-visibility-sensor";

const XCENTER = "275.334";

/**
 * @param {String or Int} height
 * @returns {Element}
 */
const renderRegularBall = ({ height }) => {
  return (
    <g filter="url(#purpose-graph__pink-filter)">
      <circle
        cx={XCENTER}
        cy={height}
        r="12"
        fill="#EDE0FF"
        stroke="#DDC9F9"
        strokeWidth="3"
      />
      <g filter="url(#purpose-graph__pink-filter)">
        <circle cx={XCENTER} cy={height} r="4" fill="#DDC9F9" />
      </g>
    </g>
  );
};

/**
 * @param {String or Int} height
 * @returns {Element}
 */
const renderLevelBall = ({ height }) => {
  return (
    <g filter="url(#purpose-graph__purple-filter)">
      <circle cx={XCENTER} cy={height} r="39.56" fill={MAIN_PURPLE} />
      <circle cx={XCENTER} cy={height} r="17.56" fill="#FEC804" />
    </g>
  );
};

/**
 * @param {Boolean} isLevel
 * @param {String or Int} height
 * @returns {Element}
 */
const renderBall = ({ isLevel, height }) => {
  return isLevel ? renderLevelBall({ height }) : renderRegularBall({ height });
};

/**
 * @param {Boolean} selectedPath
 * @param {Int} startingY
 * @returns {Element}
 */
const renderPath = ({ selectedPath, startingY }) => {
  const stroke = selectedPath ? MAIN_PURPLE : "#EDE0FF";
  const filter = selectedPath
    ? "url(#upper-path-filter)"
    : "url(#down-path-filter)";

  return (
    <g filter={filter}>
      <path
        stroke={stroke}
        strokeWidth="3"
        d={`M${XCENTER} ${startingY}V${startingY - 179.673}`}
      />
    </g>
  );
};

/**
 * @param {Boolean} isVisible
 * @param {String} purposeLevel
 * @returns {Element}
 */
const renderIndicator = ({ isVisible, purposeLevel }) => {
  return (
    <g filter="url(#purpose-graph__purple-filter)" key={isVisible.toString()}>
      <ellipse
        className={`purpose-graph__indicator-${purposeLevel}`}
        cx={XCENTER}
        cy={454.172 - 179.673 - 77.06}
        rx="17.15"
        ry="17.119"
        fill="#fff"
      />

      <path
        className={`purpose-graph__indicator-${purposeLevel}`}
        d="M251 198.453c0-13.507 10.969-24.456 24.5-24.456s24.5 10.949 24.5 24.456c0 13.506-10.969 24.455-24.5 24.455S251 211.959 251 198.453zm12.848 2.635 2.887 2.882 8.765-8.747 8.765 8.747 2.887-2.882-11.652-11.631-11.652 11.631z"
        fill={MAIN_PURPLE}
      />
    </g>
  );
};

/**
 * Functional Component: PurposeGraph
 * The purpose graph present in welcome back page, results page, shared results and analytics page
 *
 * @param {String} purposeLevel: the purpose level of user's result: positive, neutral or negative
 * @returns {Element}: an svg with a visibility sensor
 */
const PurposeGraph = ({ purposeLevel }) => {
  const [isVisible, setVisibility] = useState(false);

  const renderUpperBall = () =>
    renderBall({ height: 94.235, isLevel: purposeLevel === "positive" });

  const renderMiddleBall = () =>
    renderBall({ height: 274.835, isLevel: purposeLevel === "neutral" });

  const renderDownBall = () =>
    renderBall({ height: 454.309, isLevel: purposeLevel === "negative" });

  const renderUpperPath = () =>
    renderPath({ selectedPath: purposeLevel === "positive", startingY: 274.5 });

  const renderDownPath = () =>
    renderPath({
      selectedPath: purposeLevel === "negative",
      startingY: 454.172,
    });

  const renderCorrectIndicator = () =>
    ["positive", "negative"].includes(purposeLevel)
      ? renderIndicator({ purposeLevel, isVisible })
      : null;

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(e) => {
        if (e) setVisibility(true);
      }}
    >
      <svg
        width="550"
        height="549"
        viewBox="0 0 550 549"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M524.5 274.501c0 137.543-111.704 249.045-249.5 249.045S25.5 412.044 25.5 274.501C25.5 136.957 137.204 25.455 275 25.455s249.5 111.502 249.5 249.046z"
          stroke="#F7F7F7"
        />
        <path
          d="M549.5 274.5c0 151.325-122.897 274-274.5 274S.5 425.825.5 274.5 123.397.5 275 .5s274.5 122.675 274.5 274z"
          stroke="#F7F7F7"
        />
        <path
          d="M492.5 274.499c0 119.883-97.365 217.091-217.5 217.091S57.5 394.382 57.5 274.499c0-119.883 97.365-217.09 217.5-217.09s217.5 97.207 217.5 217.09z"
          stroke="#F9F8FC"
          strokeWidth="15"
        />
        <g filter="url(#ctdaj2z7da)">
          <ellipse
            cx={XCENTER}
            cy="274.501"
            rx="180"
            ry="179.673"
            fill="#fff"
          />
        </g>

        {renderUpperPath()}
        {renderDownPath()}
        {renderUpperBall()}

        <ellipse
          cx="423"
          cy="508.075"
          rx="20"
          ry="19.964"
          transform="rotate(-180 423 508.075)"
          fill="url(#yfernq563e)"
        />
        <ellipse
          cx="131.5"
          cy="39.429"
          rx="12.5"
          ry="12.477"
          transform="rotate(-180 131.5 39.429)"
          fill="url(#pfy42tpoyf)"
        />
        <ellipse
          cx="111.5"
          cy="85.344"
          rx="7.5"
          ry="7.486"
          transform="rotate(-180 111.5 85.344)"
          fill="url(#cm48m7cnfg)"
        />
        <ellipse
          cx="497.5"
          cy="160.209"
          rx="7.5"
          ry="7.486"
          transform="rotate(-180 497.5 160.209)"
          fill="url(#8anwc0p5ih)"
        />
        <ellipse
          cx="68.5"
          cy="414.744"
          rx="7.5"
          ry="7.486"
          transform="rotate(-180 68.5 414.744)"
          fill="url(#d6h2018qui)"
        />
        <ellipse
          cx="481.5"
          cy="132.26"
          rx="7.5"
          ry="7.486"
          transform="rotate(-180 481.5 132.26)"
          fill="url(#fpf8whtq6j)"
        />

        {renderMiddleBall()}
        {renderDownBall()}
        {renderCorrectIndicator()}

        <ellipse
          cx="462.5"
          cy="475.633"
          rx="7.5"
          ry="7.486"
          transform="rotate(-180 462.5 475.633)"
          fill="url(#prq5j5ykup)"
        />
        <defs>
          <filter
            id="ctdaj2z7da"
            x="70"
            y="73.828"
            width="410"
            height="409.346"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            {STANDARD_FILTERS.flood}
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            {STANDARD_FILTERS.offset}
            {STANDARD_FILTERS.gaussianBlur}
            {STANDARD_FILTERS.composite}
            {STANDARD_FILTERS.colorMatrixVersion1}
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_421_6613"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_421_6613"
              result="shape"
            />
          </filter>

          {renderFilterUpperPath()}
          {renderFilterDownPath()}
          {renderPinkFilter()}
          {renderPurpleFilter()}

          <linearGradient
            id="yfernq563e"
            x1="423"
            y1="488.112"
            x2="423"
            y2="528.039"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="pfy42tpoyf"
            x1="131.5"
            y1="26.952"
            x2="131.5"
            y2="51.906"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="cm48m7cnfg"
            x1="111.5"
            y1="77.857"
            x2="111.5"
            y2="92.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="8anwc0p5ih"
            x1="497.5"
            y1="152.723"
            x2="497.5"
            y2="167.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="d6h2018qui"
            x1="68.5"
            y1="407.258"
            x2="68.5"
            y2="422.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="fpf8whtq6j"
            x1="481.5"
            y1="124.773"
            x2="481.5"
            y2="139.746"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="prq5j5ykup"
            x1="462.5"
            y1="468.146"
            x2="462.5"
            y2="483.119"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CDBAE9" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
        </defs>
      </svg>
    </VisibilitySensor>
  );
};

/**
 * Object: extraProps
 * Some more props to be included on filters
 *
 * @returns {Object} the extra props
 */
const extraProps = {
  filterUnits: "userSpaceOnUse",
  colorInterpolationFilters: "sRGB",
};

/**
 * Object: STANDARD_FILTERS
 * Filters that are used some times throughou the graph
 *
 * @return {Object}: An object with the filters
 */
const STANDARD_FILTERS = {
  flood: <feFlood floodOpacity="0" result="BackgroundImageFix" />,
  colorMatrix: (
    <feColorMatrix
      in="SourceAlpha"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      result="hardAlpha"
    />
  ),
  offset: <feOffset dy="4" />,
  gaussianBlur: <feGaussianBlur stdDeviation="12.5" />,
  composite: <feComposite in2="hardAlpha" operator="out" />,
  blend: (
    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_421_6613" />
  ),
  secondBlend: (
    <feBlend
      in="SourceGraphic"
      in2="effect1_dropShadow_421_6613"
      result="shape"
    />
  ),
  colorMatrixVersion1: (
    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
  ),
  colorMatrixVersion2: (
    <feColorMatrix values="0 0 0 0 0.709804 0 0 0 0 0.584314 0 0 0 0 0.890196 0 0 0 0.5 0" />
  ),
};

// filters - do not touch

/**
 * Functional Component: renderPinkFilter
 * Renders the filter for pink shading
 *
 * @returns {Element} the filter
 */
const renderPinkFilter = () => {
  return (
    <filter id="purpose-graph__pink-filter" {...extraProps}>
      {STANDARD_FILTERS.flood}
      {STANDARD_FILTERS.colorMatrix}
      {STANDARD_FILTERS.offset}
      {STANDARD_FILTERS.gaussianBlur}
      {STANDARD_FILTERS.composite}
      {STANDARD_FILTERS.colorMatrixVersion1}
      {STANDARD_FILTERS.blend}
      {STANDARD_FILTERS.secondBlend}
    </filter>
  );
};

/**
 * Functional Component: renderPurpleFilter
 * Renders the filter for purple shading
 *
 * @returns {Element} the filter
 */
const renderPurpleFilter = () => {
  return (
    <filter id="purpose-graph__purple-filter" {...extraProps}>
      {STANDARD_FILTERS.flood}
      {STANDARD_FILTERS.colorMatrix}
      {STANDARD_FILTERS.offset}
      {STANDARD_FILTERS.gaussianBlur}
      {STANDARD_FILTERS.composite}
      {STANDARD_FILTERS.colorMatrixVersion2}
      {STANDARD_FILTERS.blend}
      {STANDARD_FILTERS.secondBlend}
    </filter>
  );
};

/**
 * Functional Component: renderPurpleFilter
 * Renders the filter for the path between the center and the top center of the graph
 *
 * @returns {Element} the filter
 */
const renderFilterUpperPath = () => {
  return (
    <filter id="upper-path-filter" {...extraProps}>
      {renderPathFilter()}
      <feColorMatrix values="0 0 0 0 0.71139 0 0 0 0 0.583299 0 0 0 0 0.891667 0 0 0 1 0" />
    </filter>
  );
};

/**
 * Functional Component: renderPurpleFilter
 * Renders the filter for the path between the center and the bottom center of the graph
 *
 * @returns {Element} the filter
 */
const renderFilterDownPath = () => {
  return (
    <filter id="down-path-filter" {...extraProps}>
      {renderPathFilter()}
      <feColorMatrix values="0 0 0 0 0.709804 0 0 0 0 0.584314 0 0 0 0 0.890196 0 0 0 0.5 0" />
    </filter>
  );
};

/**
 * Functional Component: renderPathFilter
 * Renders the filter for down and upper paths
 *
 * @returns {Element} the filter
 */
const renderPathFilter = () => {
  return (
    <>
      {STANDARD_FILTERS.flood}
      {STANDARD_FILTERS.colorMatrix}
      <feOffset />
      <feGaussianBlur stdDeviation="7.5" />
      {STANDARD_FILTERS.composite}
      {STANDARD_FILTERS.blend}
      {STANDARD_FILTERS.secondBlend}
    </>
  );
};

export default PurposeGraph;
