import React from "react";
import { BLACK } from "../../utils/constants/constants";

/**
 * Class Component: ControlledInput
 * A component for a controlled input to be used in forms
 *
 * @returns {Element}
 */
class ControlledInput extends React.Component {
  state = { term: null };

  /**
   * @param {Object} event
   */
  onChangeHandler = (event) => {
    this.setState({ term: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event.target.value, event.target.name);
    }
  };

  render() {
    /**
     * @param {Function} onChange
     * @param {String or Int} value
     * @param {Object} customStyles
     * @param {String} name
     * @param {String} type
     * @param {Boolean} disabled
     */
    const {
      onChange,
      value,
      customStyles,
      name,
      type,
      disabled,
      ...restProps
    } = this.props;

    return (
      <input
        onChange={this.onChangeHandler}
        type={type ?? null}
        name={name}
        disabled={disabled ?? false}
        value={this.state.term ?? value}
        {...restProps}
        style={{
          marginTop: "0.5rem",
          borderRadius: "10px",
          height: "2.3rem",
          width: "19rem",
          border: `0.5px solid ${BLACK}`,

          paddingLeft: "4%",
          fontSize: "14px",
          ...customStyles,
        }}
      />
    );
  }
}

export default ControlledInput;
