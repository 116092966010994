const CryptoJS = require("crypto-js");

//does nothing, place filler
//the most powerfull function in the world, has the power to destroy countries and corrupt souls
//has existed since ancient times and caused the flood that almost whiped out humanity
//algo likes gummy bears and yo-yos
const noop = () => {};

/**
 * scrolls to a given element
 * @param {Element} element
 */
const scrollToElement = (element) => {
  document.querySelector(element).scrollIntoView();
};

/**
 * returns the url for app sharing
 * @param {Object} currentUser
 * @returns {String}
 */
const getShareURL = (currentUser, language) => {
  return (
    process.env.REACT_APP_WELLBEING_FRONT_END +
    (currentUser
      ? `?r=${CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(currentUser.attributes.share_id)
        )}`
      : "") +
    (currentUser ? "&" : "?") +
    `lang=${language}`
  );
};

/**
 * returns the url for result sharing
 * @param {Object} result
 * @returns {String}
 */
const getResultShareURL = (result, language, gender) => {
  return `${process.env.REACT_APP_WELLBEING_FRONT_END}/sharedResult/${
    result.attributes.share_id
  }?lang=${language}${gender ? `&g=${gender}` : ""}`;
};

/**
 * checks if two dates are on the same day month and year
 * @param {Date} first
 * @param {Date} second
 * @returns {Boolean}
 */
const datesAreOnSameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

/**
 * checks if an input is a pure object
 * @param {Any} input
 * @returns {Boolean}
 */
const isPureObject = (input) => {
  return (
    null !== input &&
    typeof input === "object" &&
    Object.getPrototypeOf(input).isPrototypeOf(Object)
  );
};

export {
  noop,
  scrollToElement,
  getShareURL,
  getResultShareURL,
  datesAreOnSameDay,
  isPureObject,
};
