/**
 * Function: debounce
 * Waits some ms time until triggering fn function
 * @param {*} fn
 * @param {*} ms
 * @returns
 */
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default debounce;
