import React from "react";
import "./GreyBackgroundRectangle.css";
import DancingEllipses from "../../components/dancingEllipses/DancingEllipses";

/**
 * FunctionalComponent: GreyBackgroundRectangle
 * The grey background rectangle seen in meet violet, analytics and about me pages
 *
 * @param {String, Int or Elements} children
 * @param {Object} customStyles
 * @param {Boolean} purpleBackground
 * @param {Boolean} aboutMe
 * @returns {Element}
 * */
const GreyBackgroundRectangle = ({
  children,
  customStyles,
  purpleBackground,
  aboutMe,
}) => {
  return (
    <div
      id={
        aboutMe
          ? "grey-background-rectangle-about-me"
          : "grey-background-rectangle"
      }
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ position: "relative", zIndex: 0, ...customStyles }}
    >
      {children}
      <DancingEllipses
        noAnimation
        isGreyRectangle
        purpleBackground={purpleBackground}
      />
    </div>
  );
};

export default GreyBackgroundRectangle;
