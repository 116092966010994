import React from "react";
import { getQuestion } from "../../services/question.service";
import "./NewQuestion.css";
import OptionsInput from "./OptionsInput";

/**
 * Functional Component: NewQuestion
 * This component represents a question and is used on all surveys at each step
 *
 * @param {Object} question
 * @param {String} feel
 * @param {Object} currentUser
 * @param {Array} optionLabels
 * @param {Function} isOptionClicked
 * @param {Array} externalAnswers
 * @param {String} surveyType
 * @param {Boolean} alreadySelected
 * @param {Boolean} isWelcome
 * @param {Boolean} isTrial
 * @param {Boolean} isCore
 * @param {Int} questionNumber
 * @param {String} greetingCopie
 * @returns {Element}
 */
const NewQuestion = ({
  question,
  feel,
  currentUser,
  optionLabels,
  isOptionClicked,
  externalAnswers,
  surveyType,
  alreadySelected,
  isWelcome,
  isTrial,
  isCore,
  questionNumber,
  greetingCopie,
}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {questionNumber === 0 && currentUser && isWelcome ? (
        <span id="greetings-container" className="d-flex justify-content-start">
          {greetingCopie}
          {currentUser.attributes.first_name}
        </span>
      ) : null}

      <span
        className="d-flex justify-content-start"
        id={isWelcome ? "new-question-content" : "new-question-content-core"}
      >
        {getQuestion(question, feel, currentUser)}
      </span>

      <div>
        <OptionsInput
          questionId={question.id}
          optionLabels={optionLabels}
          answers={question.attributes.answers}
          isOptionClicked={isOptionClicked}
          numberOfOptions={
            externalAnswers
              ? externalAnswers.length
              : question.attributes.answers.length
          }
          surveyType={surveyType}
          questionType={question.attributes.question_type}
          alreadySelected={alreadySelected ?? null}
          externalAnswers={externalAnswers}
          isWelcome={isWelcome}
          isTrial={isTrial}
          isCore={isCore}
          question={question}
        />
      </div>
    </div>
  );
};

export default NewQuestion;
