import React from "react";

/**
 * FunctionalComponent: UpperParagaph
 * Just a paragraph, to be used in pages like PrivacyPolicy and TermsOfService
 * @param {String, Int or Elements} children
 * @returns {Element}
 */
const UpperParagraph = ({ children }) => {
  return <div className="mb-5">{children}</div>;
};

export default UpperParagraph;
