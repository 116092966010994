import backend from "../../../core/apis/backend";
import { CORE_SURVEY_ID } from "../../constants/constants";
import { datesAreOnSameDay } from "../geralHelpers/RandomHelper";

/**
 * checks if the user has already answerd the core survey today
 * @param {String} userId
 * @returns {Boolean}
 */
const isThereCoreSurveyResultToday = async (userId) => {
  try {
    const result = await backend.get(
      `/lastResult/${CORE_SURVEY_ID}/${userId}`,
      { params: { survey_id: CORE_SURVEY_ID } }
    );

    return datesAreOnSameDay(
      new Date(result.data.data.attributes.created_at),
      new Date()
    );
  } catch (e) {
    return false;
  }
};

/**
 * checks if the user has already answered the welcome survey today
 * @param {String} userId
 * @returns {Boolean}
 */
const isThereWelcomingSurveyResultsToday = async (userId) => {
  try {
    const result = await backend.get(`/lastWelcomingResult/${userId}`);

    return datesAreOnSameDay(
      new Date(new Date(result.data.data.attributes.created_at)),
      new Date()
    );
  } catch (e) {
    return false;
  }
};
/*
const hasAtLeastNResults = async (userId, n) => {
  try {
    const number = await backend.get(`/countResults/${userId}`);

    return number.data.value > n;
  } catch (e) {
    return false;
  }
};
*/

export {
  isThereCoreSurveyResultToday,
  isThereWelcomingSurveyResultsToday,
  //hasAtLeastNResults,
};
