import React, { useCallback, useEffect, useState } from "react";
import "./CompareWithOthers.css";
import compare_with_others_yoga from "../../../../assets/svg/compare_with_others_yoga.svg";
import location_icon from "../../../../assets/svg/location_icon.svg";
import ResultsButton from "../newComponents/ResultsButton";
import NewDropdownComponent from "../newComponents/NewDropdownComponent";
import { renderCountriesDropdown } from "../../../../utils/helpers/graphsHelpers/GraphsFiltersHelper";
import { FilterSections } from "../newComponents/ComparationCarousel";
import {
  BLACK,
  MY_ANALYTICS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
} from "../../../../utils/constants/constants";
import { handleSubmit } from "../../services/CompareWithOthers.service";
import NativeDatepicker from "../../../../components/inputs/NativeDatepicker";
import { redirectToProgressOvertimePage } from "../../../../core/Routing/RoutesRedirects";
import { newCheckResults } from "../../../Scores/services/myAnalytics.service";
import { noop } from "../../../../utils/helpers/geralHelpers/RandomHelper";
import { MouseTooltip } from "../../../../components/modal/CustomTooltips";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";
import {
  genderFormatter,
  renderDropdownGenderData,
} from "../../../Users/services/aboutMe.service";
import { createAnalytic } from "../../../../utils/helpers/geralHelpers/BackendHelper";

/**
 * Functional Component: GiveInformationVersion
 * The version of compare with others where we ask for demographic information from user
 *
 * @param {Object} currentUser
 * @param {Function} fetchUserWithAllAttributes
 * @param {Object} copies
 * @param {String} language
 * @returns {Element}
 * */
const GiveInformationVersion = ({
  currentUser,
  fetchUserWithAllAttributes,
  copies: SELF_COPIES,
  language,
  share_id,
}) => {
  const [gender, setGender] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [country, setCountry] = useState(null);
  const [saveForever, setSaveForever] = useState(false);
  const [errorsToDisplay, setErrorsToDisplay] = useState({
    gender: null,
    country: null,
    birthdate: null,
  });

  //resets the errors beeing displayed
  const resetDisplayedError = useCallback(
    (input) => {
      if (errorsToDisplay[input])
        setErrorsToDisplay({ ...errorsToDisplay, [input]: null });
    },
    [errorsToDisplay]
  );

  //called when a gender is selected
  const onGenderChange = useCallback(
    (value) => {
      setGender(value.value);
      resetDisplayedError("gender");
    },
    [resetDisplayedError]
  );

  //called when a birthdate is provided
  const onAgeChange = useCallback(
    (e) => {
      e ? setBirthdate(e.target.value) : setBirthdate(null);
      resetDisplayedError("birthdate");
    },
    [resetDisplayedError]
  );

  //called when a country is selected
  const onCountryChange = useCallback(
    (value) => {
      setCountry(value.value);
      resetDisplayedError("country");
    },
    [resetDisplayedError]
  );

  /**
   * returns the styles for the x icon
   * @returns {Object}
   * */
  const getCustomXStyles = () => {
    if (window.innerWidth >= 992) {
      return { right: -5, left: null, top: 15 };
    } else if (window.innerWidth >= 576) {
      return { right: -5, left: null };
    } else if (window.innerWidth >= 0) {
      return { right: -5, left: null };
    }
  };

  /**
   * clears the dropdowns
   * @param {String} place
   * */
  const clearFunction = (place) => {
    if (place === "country") {
      onCountryChange({ value: null });
    } else if (place === "gender") {
      onGenderChange({ value: null });
    }
  };

  /**
   * returns the labels for countries dropdown
   * @param {String} value
   * */
  const getCountriesLabel = (value) => {
    const countries = renderCountriesDropdown();

    for (let country of countries) {
      if (country.value === value) {
        return country.label;
      }
    }
  };

  /**
   * renders the clear buttons for the dropdowns
   * @param {String} place
   * */
  const renderClearButton = (place) => {
    return (
      <div
        style={{
          position: "absolute",
          left: "50 %",
          display: "flex",
          alignItems: "center",
          height: "100%",
          textAlign: "center",
          cursor: "pointer",
          top: "15px",
          zIndex: 10,
          ...getCustomXStyles(),
        }}
        onClick={() => clearFunction(place)}
      >
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class="date-picker-clear-indicator"
          fill={BLACK}
          color={BLACK}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
      </div>
    );
  };

  /**
   * renders the input for gender selection
   * @returns {Element}
   * */
  const renderGenderInput = () => {
    if (currentUser.attributes.gender) return null;

    return (
      <div className="col-12 col-sm-10 col-lg-12 mx-auto compare-with-others-info__input">
        {gender ? renderClearButton("gender") : null}
        <NewDropdownComponent
          label={SELF_COPIES["gender-input-label"]}
          name="gender"
          placeholder={null}
          data={renderDropdownGenderData(language)}
          onChange={onGenderChange}
          value={{ label: genderFormatter(gender), value: gender }}
        />
        {errorsToDisplay.gender ? (
          <span className="error-labels">{errorsToDisplay.gender.message}</span>
        ) : null}
      </div>
    );
  };

  /**
   * renders the date picker for birthdate selection
   * @returns {Element}
   * */
  const renderBirthdateInput = () => {
    if (currentUser.attributes.birthdate) return null;

    return (
      <div
        id="compare-with-others-date-picker"
        className="mb-4 col-12 col-sm-10 col-lg-12 mx-auto"
      >
        <label forhtml="birthdate">
          {SELF_COPIES["birthdate-input-label"]}
        </label>
        <NativeDatepicker
          name="birthdate"
          style={{
            paddingLeft: "2%",
            borderRadius: "5px",
            height: "5.8vh",
            minHeight: "2.6rem",
            maxHeight: "4.5rem",
            border: "solid 1px #c2c0c7",
            width: "100%",
            fontSize: "var(--bs-body-font-size)",
            color: "black",
            backgroundColor: "white",
          }}
          placeholder={language === "pt-PT" ? "dd/mm/aaaa" : null}
          onChange={onAgeChange}
          value={birthdate}
          isClerable
          hideX={!birthdate}
          xColor={"black"}
        />
        {errorsToDisplay.birthdate ? (
          <span className="error-labels">
            {errorsToDisplay.birthdate.message}
          </span>
        ) : null}
      </div>
    );
  };

  /**
   * renders the input for country selection
   * @returns {Element}
   * */
  const renderCountryInput = () => {
    if (currentUser.attributes.country) return null;

    return (
      <div className="mb-4 col-12 col-sm-10 col-lg-12 mx-auto">
        {country ? renderClearButton("country") : null}
        <NewDropdownComponent
          label={SELF_COPIES["country-input-label"]}
          name="country"
          placeholder={null}
          data={renderCountriesDropdown(language)}
          onChange={onCountryChange}
          value={{ label: getCountriesLabel(country), value: country }}
          controlStyles={{
            backgroundImage: `url(${location_icon})`,
            backgroundPosition: "15px 50%",
            backgroundSize: "15px",
            backgroundRepeat: "no-repeat",
            paddingLeft: "40px",
          }}
        />
        {errorsToDisplay.country ? (
          <span className="error-labels">
            {errorsToDisplay.country.message}
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <div id="compare-with-others" className="d-flex flex-column flex-lg-row">
      <div className="col-12 offset-lg-1 col-lg-4">
        <h3 className="fw-bold col-10 col-sm-12 mx-auto text-center text-lg-start ff-popp-med compare-with-others-info__title">
          {SELF_COPIES.title}
        </h3>

        <div className="col-8 col-sm-12 mx-auto text-center text-lg-start compare-with-others-info__subtitle">
          {SELF_COPIES.subtitle}
        </div>

        {renderGenderInput()}

        {renderBirthdateInput()}

        {renderCountryInput()}

        <div className="mb-4 col-12 col-sm-10 col-lg-12 mx-auto ">
          <label>
            <input
              type="checkbox"
              onChange={(e) => setSaveForever(e.target.checked)}
              checked={saveForever}
            />
            <span style={{ fontSize: "88%" }} className="ps-2">
              {SELF_COPIES["update-demographics-button"]}
            </span>
          </label>
        </div>

        <div className="col-6 mx-auto col-lg-12 text-center text-lg-start">
          <ResultsButton
            onClick={() => {
              handleSubmit({
                gender,
                country,
                birthdate: birthdate ? birthdate.replaceAll("-", "/") : null,
                setErrorsToDisplay,
                saveForever,
                currentUser,
                fetchUserWithAllAttributes,
                language,
                share_id,
              });

              if (saveForever) {
                createAnalytic(EVENTS.PERSONAL_INFO_PROFILE);
              } else {
                createAnalytic(EVENTS.PERSONAL_INFO_RESULTS);
              }
            }}
          >
            {SELF_COPIES["submit-button"]}
          </ResultsButton>
        </div>
      </div>

      <div className="offset-lg-1 col-10 col-lg-4 d-flex justify-content-center align-items-center mx-auto mt-5 mt-lg-0">
        <img src={compare_with_others_yoga} alt="yoga_people" />
      </div>
    </div>
  );
};

/**
 * renders the compare with others page
 * @param {Int} score
 * @param {Function} fetchUserWithAllAttributes
 * @param {Object} currentUser
 * @param {Object} filteredAverages
 * @param {String} page
 * @param {String} share_id
 * @param {Function} setPage
 * @param {String} language
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {String} shareResultGender
 * */
const CompareWithOthers = ({
  score,
  fetchUserWithAllAttributes,
  currentUser,
  page,
  name,
  setPage,
  language,
  copies,
  variableCopies,
  shareResultGender,
  share_id,
}) => {
  if (
    (page === SHARE_RESULTS_PAGE_ID || page === MY_ANALYTICS_PAGE_ID) &&
    (!variableCopies || Object.keys(variableCopies).length < 1)
  ) {
    return null;
  } else if (variableCopies && Object.keys(variableCopies).length > 0) {
    return (
      <FilteredInformationVersion
        score={score}
        currentUser={currentUser}
        page={page}
        name={name}
        setPage={setPage}
        copies={copies.FILTERED_INFORMATION}
        variableCopies={variableCopies}
        shareResultGender={shareResultGender}
        language={language}
      />
    );
  } else if (
    !currentUser.attributes.gender ||
    !currentUser.attributes.country ||
    !currentUser.attributes.birthdate
  ) {
    return (
      <GiveInformationVersion
        currentUser={currentUser}
        fetchUserWithAllAttributes={fetchUserWithAllAttributes}
        copies={copies.GIVE_INFORMATION}
        language={language}
        share_id={share_id}
      />
    );
  } else {
    return null;
  }
};

/**
 * renders the version where user has demographic data so we can filter and render the averages
 * @param {Object} currentUser
 * @param {String} page
 * @param {Function} setPage
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {String} shareResultGender
 * */
const FilteredInformationVersion = ({
  currentUser,
  page,
  setPage,
  copies,
  variableCopies,
  language,
  shareResultGender,
}) => {
  const [progressOvertimeButton, setProgressOvertimeButton] = useState({
    backgroundColor: "",
    onClick: redirectToProgressOvertimePage,
    canShowTooltip: false,
  });
  const [isTooltipVisible, setTooltipVisibility] = useState(false);

  useEffect(() => {
    const saveProgressOvertimeButtonProps = async () => {
      const results = await newCheckResults(currentUser);
      const progressOvertimeButtonProps = results.progressOverTime
        ? {
            backgroundColor: "",
            onClick: redirectToProgressOvertimePage,
            canShowTooltip: false,
          }
        : { backgroundColor: "#c2c0c7", onClick: noop, canShowTooltip: true };
      setProgressOvertimeButton(progressOvertimeButtonProps);
    };
    if (page !== SHARE_RESULTS_PAGE_ID) saveProgressOvertimeButtonProps();
  }, [currentUser, page]);

  if (
    language === "en-GB" &&
    page === SHARE_RESULTS_PAGE_ID &&
    shareResultGender
  ) {
    if (shareResultGender === "M") {
      copies["subtitle-part-1"] = copies["subtitle-part-1"].replaceAll(
        "his/her",
        "his"
      );
    } else if (shareResultGender === "F") {
      copies["subtitle-part-1"] = copies["subtitle-part-1"].replaceAll(
        "his/her",
        "her"
      );
    }
  }

  return (
    <div id="compare-with-others">
      <div className="pb-5">
        <h2 className="fw-bold col-6 col-sm-12 mx-auto text-center compare-with-others__title ff-popp-med">
          {copies.title}
        </h2>

        <div className="mb-5 col-8 col-sm-12 mx-auto text-center compare-with-others__subtitle">
          {copies["subtitle-part-1"]}{" "}
          <span style={{ color: "var(--purple-1)" }}>
            {copies["subtitle-part-2"]}
          </span>
        </div>
      </div>

      <FilterSections
        sections={[
          variableCopies.country,
          variableCopies.countryAndGender,
          variableCopies.countryAndGenderAndAge,
        ]}
      />

      {page !== SHARE_RESULTS_PAGE_ID ? (
        <div className="text-center comparation-with-others__progress-overtime-button">
          <MouseTooltip
            text={<span>{copies.tooltip}</span>}
            arrow={isTooltipVisible ? "left top" : ""}
            disableTooltip={() => setTooltipVisibility(false)}
          />
          <div id="more-details-button-compare-with-others">
            <ResultsButton
              onClick={
                setPage
                  ? () => {
                      setPage("#progress-overtime");

                      document.documentElement.style.scrollBehavior = "auto";
                      setTimeout(() => window.scrollTo(0, 0), 5);
                      setTimeout(
                        () =>
                          (document.documentElement.style.scrollBehavior =
                            "smooth"),
                        5
                      );

                      createAnalytic(EVENTS.VIEW_PROGRESS_RESULTS);
                    }
                  : () => {
                      progressOvertimeButton.onClick();
                      createAnalytic(EVENTS.VIEW_PROGRESS_RESULTS);
                    }
              }
              customStyles={{
                backgroundColor: progressOvertimeButton.backgroundColor,
              }}
              onMouseEnter={() =>
                setTooltipVisibility(progressOvertimeButton.canShowTooltip)
              }
              onMouseLeave={() => setTooltipVisibility(false)}
            >
              {copies["check-overtime-button"]}
            </ResultsButton>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CompareWithOthers;
