//get all the alpha codes that represent every letter of the alphabet
const alpha = Array.from(Array(26)).map((e, i) => i + 97);

//use the codes to get the full alphabet
const alphabet = alpha.map((x) => String.fromCharCode(x));

/**
 * capitales a string turning their first letter innto capital lettering
 * @param {String} string
 * @returns {String}
 */
const capitalize = (string) => {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : "";
};

/**
 * checks if a string is blank
 * @param {String} str
 * @returns {Boolean}
 */
const isStringBlank = (str) => !str.replace(/\s/g, "").length;

export { alphabet, capitalize, isStringBlank };
