import React, { useCallback, useState } from "react";
import "./CookiesPolicy.css";
import {
  isUserInCookiePolicy,
  redirectToCookiePolicy,
} from "../../core/Routing/RoutesRedirects";
import { TRANSPARENT } from "../../utils/constants/constants";
import { RegularModal } from "../modal/CustomModals";
import { useResize } from "../../modules/Geral/hooks/useResize";
import {
  areCookiesEnabled,
  //dispatchCookiesEvent,
  setAcceptanceCookie,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";

const ACCEPTED_COOKIES = {
  functional: true,
  experience: true,
  analytics: true,
};

/**
 * Functional Component: Cookies Policy
 * The modal that appears when an anonymous user enters in the app for the first time and where they can make a basic choice around cookies
 *
 * @param {Function} onManageCookies: function that is activated when the user clicks in "Manage Cookies"
 * @param {Boolean} open: parent controls whether the modal is opened or closed
 * @param {Function} onSubmit: function that is activated when the user accepts all cookies
 * @param {Object} copies
 * @returns {Element} the modal with the options
 */
const CookiesPolicy = ({ onManageCookies, open, onSubmit, copies }) => {
  const [isModalOpen, setModalVisibility] = useState(open);

  const acceptAllCookies = useCallback(() => {
    if (areCookiesEnabled) {
      setAcceptanceCookie(ACCEPTED_COOKIES);
      onSubmit();
    } //else dispatchCookiesEvent();
  }, [onSubmit]);

  // the current width of the page
  const [width] = useResize();

  return (
    <RegularModal
      open={isModalOpen}
      onChange={setModalVisibility}
      customStyles={{
        transition: "top 2s ease 0s",
        width: "100vw",
        backgroundColor: "var(--purple-2)",
        opacity: 0.95,
        transform: "translate(-50%, -100%)",
      }}
      backgroundStyles={{
        background: isUserInCookiePolicy
          ? "rgba(0, 0, 0, 0)"
          : "linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25))",
      }}
      customClassName="cookies-policy-modal"
    >
      <div className="d-flex justify-content-center align-items-center flex-column flex-lg-row text-center text-lg-start cookies-policy__text">
        <span
          style={{
            color: "var(--main-text-1)",
            marginRight: width > 992 ? "1vw" : 0,
          }}
        >
          {copies.BODY}
        </span>

        <div
          style={{
            width:
              width > 1600
                ? "30vw"
                : width > 992
                ? "28%"
                : width > 576
                ? "75%"
                : "100%",
            marginTop: width > 1600 ? "1vw" : width > 992 ? "0rem" : "1.5rem",
            marginBottom:
              width > 1600 ? "1vw" : width > 992 ? "0rem" : "1.5rem",
          }}
          className="d-flex align-items-center justify-content-between"
        >
          <button
            style={{
              border: "2px solid white",
              padding: "0.2vw 0.4vw",
              background: TRANSPARENT,
              color: "var(--main-text-1)",
              width: width < 576 ? "22vw" : "",
            }}
            name="accept-cookies-button"
            className="cookies-policy__text"
            onClick={acceptAllCookies}
          >
            {copies.ACCEPT_COOKIES_BUTTON}
          </button>

          <button
            style={{
              border: "2px solid white",
              padding: "0.2vw 0.4vw",
              background: TRANSPARENT,
              color: "var(--main-text-1)",
              width: width < 576 ? "22vw" : "",
            }}
            className="cookies-policy__text"
            onClick={onManageCookies}
          >
            {copies.MANAGE_COOKIES_BUTTON}
          </button>

          <span
            className="text-center cookies-policy__text"
            onClick={redirectToCookiePolicy}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              width: width < 576 ? "22vw" : "",
            }}
          >
            {copies.KNOW_MORE_BUTTON}
          </span>
        </div>
      </div>
    </RegularModal>
  );
};

export default CookiesPolicy;
