import {
  fetchDimensions,
  fetchOtherFilteredData,
  fetchResultByShareId,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import { getPurposePercentage } from "../../Users/services/welcomeBackPage.service";

const getAllResources = async ({ result_id, query }) => {
  const dimensions = await fetchDimensions();

  const { result, userName, lastResultNotToday } = await fetchResultByShareId(
    result_id
  );

  const filteredData = await fetchOtherFilteredData(result_id);
  const gender = query.get("g");
  const purposePercentage = getPurposePercentage({
    dimensions,
    result: result.data,
  });

  let lastResultBeforeToday = null;
  if (lastResultNotToday && lastResultNotToday.data.id !== result.data.id) {
    lastResultBeforeToday = lastResultNotToday.data;
  }

  return {
    dimensions,
    filteredData: filteredData?.attributes,
    gender,
    lastResultBeforeToday,
    result: result.data,
    name: userName,
    purposePercentage,
  };
};

export { getAllResources };
