import {
  GREEN_COLORS,
  RED_COLORS,
  YELLOW_COLORS,
} from "../../constants/colorsConstants";

/**
 * function that return the zone and some props based on score
 * @param {Number} score
 * @returns {Object}
 */
const zoneObjectFromScore = (score) => {
  if (score < 41)
    return {
      name: "red zone",
      colors: RED_COLORS,
      rangeValues: { min_value: 0, max_value: 40 },
    };
  else if (score < 56)
    return {
      name: "lower yellow zone",
      colors: YELLOW_COLORS,
      rangeValues: { min_value: 41, max_value: 70 },
    };
  else if (score < 71)
    return {
      name: "higher yellow zone",
      colors: YELLOW_COLORS,
      rangeValues: { min_value: 41, max_value: 70 },
    };
  else if (score < 86)
    return {
      name: "lower green zone",
      colors: GREEN_COLORS,
      rangeValues: { min_value: 71, max_value: 100 },
    };
  else
    return {
      name: "higher green zone",
      colors: GREEN_COLORS,
      rangeValues: { min_value: 71, max_value: 100 },
    };
};

export { zoneObjectFromScore };
