import React from "react";
import "./NewSocialMediaIcons.css";
import facebookIcon from "../../../../assets/svg/facebook-icon.svg";
//import googleIcon from "../../assets/images/google-icon.png";
import linkedinIcon from "../../../../assets/svg/linkedin-icon.svg";
import emailIcon from "../../../../assets/svg/email-icon.svg";
import whatsappIcon from "../../../../assets/svg/whatsapp-icon.svg";
import messengerIcon from "../../../../assets/svg/messenger-icon.svg";
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  //WhatsappShareButton,
} from "react-share";
import ShareResultByEmail from "../ShareResultByEmail";
import ShareAppByEmail from "../ShareAppByEmail";
import { detectWhatsapp } from "../../../../utils/helpers/geralHelpers/whatsappDetector";
import { detectMessenger } from "../../../../utils/helpers/geralHelpers/messengerDetector";
import { STATIC_COPIES } from "../../../../utils/constants/copies/staticCopies.helper.js";
import FLASH_TYPES from "../../../../components/flash/FLASH_TYPES";
import { isSafari } from "../../../../utils/helpers/geralHelpers/BrowserHelper";
import { triggerShareIconEvent } from "../../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: Facebook
 * The facebook share button
 * @param {String} goal
 * @param {String} mainUrl
 * @param {String} language
 * @returns {Component}
 */
const Facebook = ({ goal = "result", mainUrl, language, ...props }) => {
  //contains the copies for facebook sharing button
  const copies = STATIC_COPIES.SHARE_SOCIAL_MEDIA_OR_EMAIL.facebook;

  const settings = {
    app: { title: copies["share-app"].title[language] },
    result: { title: copies["share-result"].title[language] },
  };

  return (
    <FacebookShareButton
      url={mainUrl + "&social=fb"}
      quote={settings[goal].title}
      hashtag="#violet #mywellbeingcheckin"
      {...props}
    >
      <div className="social-media-icon__wrapper">
        <img alt="facebook" src={facebookIcon} />
      </div>
    </FacebookShareButton>
  );
};

/**
 * Functional Component: Linkedin
 * The LinkedIn share button
 * @param {String} mainUrl
 * @param {String} goal
 * @param {String} language
 * @returns {Component}
 */
const Linkedin = ({ mainUrl, goal = "result", language, ...props }) => {
  //contains the copies for linkedIn sharing button
  const copies = STATIC_COPIES.SHARE_SOCIAL_MEDIA_OR_EMAIL.linkedin;

  const settings = {
    app: { title: copies["share-app"].title[language] },
    result: { title: copies["share-result"].title[language] },
  };

  return (
    <LinkedinShareButton
      url={mainUrl + "&social=li"}
      title={settings[goal].title}
      summary=""
      {...props}
    >
      <div className="social-media-icon__wrapper">
        <img alt="linkedin" src={linkedinIcon} />
      </div>
    </LinkedinShareButton>
  );
};

/**
 * Functional Component: Email
 * The email share button
 * @param {String} mainUrl
 * @param {String} goal
 * @param {Object} customStyles
 * @returns {Component}
 */
const Email = ({ mainUrl, goal, customStyles, ...props }) => {
  const settings = {
    app: {
      component: ShareAppByEmail,
      onClick: () => triggerShareIconEvent("email-app"),
    },
    result: {
      component: ShareResultByEmail,
      onClick: () => triggerShareIconEvent("email-result"),
    },
  };
  const EmailComponent = settings[goal].component;

  return (
    <EmailComponent share_url={mainUrl} customStyles={customStyles} {...props}>
      <div
        onClick={settings[goal].onClick}
        className="social-media-icon__wrapper"
      >
        <img alt="email" src={emailIcon} />
      </div>
    </EmailComponent>
  );
};

/**
 * Functional Component: WhatsApp
 * The whatsapp share button
 * @param {String} mainUrl
 * @param {String} goal
 * @param {Object} customStyles
 * @returns {Component}
 */
const WhatsApp = ({ mainUrl, goal, customStyles }) => {
  const hasQuestionMark = mainUrl.indexOf("?") > 0;

  const settings = {
    app: {
      title: "Make your well-being a priority with violet",
      url: mainUrl + `${hasQuestionMark ? "&" : "?"}social=wa&mode=app`,
      onClick: () => triggerShareIconEvent("whatsapp-app"),
    },
    result: {
      title: "My well-being results - powered by violet",
      url: mainUrl + "&social=wa&mode=result",
      onClick: () => triggerShareIconEvent("whatsapp-result"),
    },
  };

  return (
    <div
      className="social-media-icon__wrapper"
      style={customStyles}
      onClick={() => {
        settings[goal].onClick();

        if (isSafari) {
          window.open(
            `https://api.whatsapp.com/send?text=${encodeURIComponent(
              settings[goal].url
            )}`
          );
        } else {
          detectWhatsapp("", settings[goal].url).then((hasWhatsapp) => {
            if (!hasWhatsapp)
              window.open(
                `https://web.whatsapp.com/send?text=${settings[goal].url}`
              );
          });
        }
      }}
    >
      <img alt="whatsapp" src={whatsappIcon} />
    </div>
  );
};

/**
 * Functional Component: Messenger
 * The messenger share button
 * @param {String} mainUrl
 * @param {String} goal
 * @param {Function} setFlashProps
 * @param {Object} customStyles
 * @returns {Component}
 */
const Messenger = ({
  goal,
  mainUrl,
  setFlashProps,
  customStyles,
  ...props
}) => {
  const settings = {
    app: {
      url: mainUrl + "&social=msn&mode=app",
    },
    result: {
      url: mainUrl + "&social=msn&mode=result",
    },
  };

  if (window.innerWidth < 992) {
    return (
      <div
        className="social-media-icon__wrapper"
        onClick={() => {
          detectMessenger("", "", settings[goal].url).then((hasMessenger) => {
            if (!hasMessenger) setFlashProps(FLASH_TYPES.MESSENGER_APP);
          });
        }}
        style={customStyles}
      >
        <img alt="messenger" src={messengerIcon} />
      </div>
    );
  } else {
    return (
      <FacebookMessengerShareButton
        url={settings[goal].url}
        appId="571556190878960"
        redirectUri={window.location}
        {...props}
      >
        <div className="social-media-icon__wrapper">
          <img alt="messenger" src={messengerIcon} />
        </div>
      </FacebookMessengerShareButton>
    );
  }
};

export { Facebook, Linkedin, Email, WhatsApp, Messenger };
