/**
 * checks if the user has already answered the trial survey today
 * @param {Object} preliminaryResult
 * @returns {Boolean}
 */
const hasTrialResultToday = ({ preliminaryResult }) => {
  if (!preliminaryResult.date) return false;
  const resultDate = new Date(preliminaryResult.date);
  const today = new Date();

  return (
    resultDate.getFullYear() === today.getFullYear() &&
    resultDate.getMonth() === today.getMonth() &&
    resultDate.getDate() === today.getDate()
  );
};

export { hasTrialResultToday };
