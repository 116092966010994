/**
 * builds the current user object from the info obtained from login and wellbeing backend
 * @param {Object} userInfo
 * @param {Object} wellbeingUser
 * @returns {Object}
 */
const userBuilder = (userInfo, wellbeingUser) => {
  let attributes = {
    ...userInfo.data.attributes,
    ...wellbeingUser.data.attributes,
  };

  attributes.user_id = attributes.user_id.toString();

  return {
    attributes,
    included: wellbeingUser.included,
  };
};

export { userBuilder };
