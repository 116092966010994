import React, { useState } from "react";
import "./PasswordInput.css";
import closedEye from "../../assets/svg/eyelid.svg";
import { noop } from "../../utils/helpers/geralHelpers/RandomHelper";

/**
 * Functional Component: PasswordInput
 * An input for password changing
 *
 * @param {String} value
 * @param {Function} onChange
 * @returns {Element}
 * */
const PasswordInput = ({ value, onChange = noop, ...props }) => {
  const [showPassword, setPasswordVisibility] = useState(value);
  const [password, setPassword] = useState("");

  /**
   * called when input value changes
   * @param {Object} e
   */
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="password-input">
      <img
        onClick={() => setPasswordVisibility(!showPassword)}
        src={closedEye}
        className="password-input__eyelid"
        alt="show-password"
      />

      <input
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handlePasswordChange}
        {...props}
      />
    </div>
  );
};

export default PasswordInput;
