import React, { useCallback, useEffect, useState } from "react";
import "./CustomTooltip.css";
import close_button from "../../assets/svg/close-button.svg";
import { CustomModal } from "./CustomModals";
import { useResize } from "../../modules/Geral/hooks/useResize";
import { noop } from "../../utils/helpers/geralHelpers/RandomHelper";

const THEMES = {
  white: {
    tooltip: {
      color: "var(--main-text-1)",
      horizontalPadding: "1.9rem",
      verticalPadding: "1.4rem",
      backgroundColor: "white",
    },
    closeButton: {
      visibility: true,
    },
    children: {
      marginRight: "1.2rem",
    },
  },
  grey: {
    tooltip: {
      color: "#333",
      horizontalPadding: "1.3rem",
      verticalPadding: "0.8rem",
      backgroundColor: "var(--purple-2)",
    },
    closeButton: {
      visibility: false,
    },
    children: {
      marginRight: "0rem",
    },
  },
};

/**
 * Functional Component: CustomTooltip
 * A small box of text that will be rendered at the side of a component
 *
 * @param {String, Int or Elements} children
 * @param {Boolean} open
 * @param {String} arrow
 * @param {Boolean} withBackground
 * @param {Function} onOpen
 * @param {String} theme
 * @returns {Element}
 */
const CustomTooltip = ({
  children,
  open,
  arrow,
  withBackground,
  onOpen = noop,
  theme = "white",
}) => {
  const [isOpen, setOpenStatus] = useState(open);

  //handles the opening and closing of the modal
  const closeTooltip = useCallback(() => {
    setOpenStatus(false);
    onOpen(false); // ??
  }, [onOpen]);

  useEffect(() => setOpenStatus(open), [open]);

  const [arrowSide, arrowPosition] = arrow.split(" ");
  const openClass = isOpen ? "on" : "off";

  const newCoords = getCoords({ arrowSide, arrowPosition });

  return (
    <>
      {withBackground ? (
        <CustomModal open={isOpen} onClick={closeTooltip} />
      ) : null}
      <style>
        {`
        .custom-tooltip {
          --custom-tooltip-background-color: ${THEMES[theme].tooltip.backgroundColor};
          --custom-horizontal-padding: ${THEMES[theme].tooltip.horizontalPadding};
          --custom-vertical-padding: ${THEMES[theme].tooltip.verticalPadding};
          --custom-tooltip-color: ${THEMES[theme].tooltip.color} 
        }
        `}
      </style>
      <div
        className={`custom-tooltip ${openClass} ${arrowSide}`}
        style={{
          right: "calc(50%)",
          bottom: "calc(50% + 10%)",
          transform: `translate(${newCoords.x}, ${newCoords.y})`,
        }}
      >
        <div className={`custom-tooltip__arrow ${arrowPosition}`} />

        <div className="custom-tooltip__inner">
          {THEMES[theme].closeButton.visibility ? (
            <div
              onClick={closeTooltip}
              className="custom-tooltip__close-button"
            >
              <img alt="close-button" src={close_button} />
            </div>
          ) : null}

          <div
            style={{
              wordWrap: "break-word",
              marginRight: THEMES[theme].children.marginRight,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * returns coordinates
 * @param {String} arrowSide
 * @param {String} arrowPosition
 * @returns {Object}
 */
const getCoords = ({ arrowSide, arrowPosition }) => {
  const STARTING_X = 50;
  const STARTING_Y = 50;

  const coords = {
    top: {
      right: { x: STARTING_X - 35, y: STARTING_Y - 100 },
      center: { x: STARTING_X, y: STARTING_Y - 100 },
      left: { x: STARTING_X + 35, y: STARTING_Y - 100 },
    },
    bottom: {
      right: { x: STARTING_X - 35, y: STARTING_Y + 100 },
      center: { x: STARTING_X, y: STARTING_Y + 100 },
      left: { x: STARTING_X + 35, y: STARTING_Y + 100 },
    },
    right: {
      top: { x: STARTING_X - 70, y: STARTING_Y + 20 },
      center: { x: STARTING_X - 70, y: STARTING_Y },
      bottom: { x: STARTING_X - 70, y: STARTING_Y - 20 },
    },
    left: {
      top: { x: STARTING_X + 70, y: STARTING_Y + 20 },
      center: { x: STARTING_X + 70, y: STARTING_Y },
      bottom: { x: STARTING_X + 70, y: STARTING_Y - 20 },
    },
  };

  return {
    x: coords[arrowSide][arrowPosition].x + "%",
    y: coords[arrowSide][arrowPosition].y + "%",
  };
};

/**
 *
 * @param {String} arrow
 * @param {String} text
 * @param {Function} disableTooltip
 * @returns {Element}
 */
const MouseTooltip = ({ arrow, text, disableTooltip }) => {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const onMouseMoveHandler = (e) => setMouse({ x: e.clientX, y: e.clientY });
    document.addEventListener("mousemove", onMouseMoveHandler);

    return () => document.removeEventListener("mousemove", onMouseMoveHandler);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", disableTooltip);
    return () => document.removeEventListener("scroll", disableTooltip);
  }, [disableTooltip]);

  const [windowWidth] = useResize();

  /**
   * @returns {String}
   */
  const getFactor = () => {
    if (windowWidth < 576) return "xs";
    else if (windowWidth < 768) return "md";
    else if (windowWidth < 992) return "lg";
    else return "xl";
  };

  if (!arrow) return null;

  const { fontSize, lineHeight } = extraProps[getFactor()];
  return (
    <div
      style={{ position: "fixed", left: mouse.x, top: mouse.y }}
      className="tooltip-new"
    >
      <CustomTooltip arrow={arrow} open={true} theme="grey">
        <div style={{ fontSize, textAlign: "center", lineHeight }}>{text}</div>
      </CustomTooltip>
    </div>
  );
};

const extraProps = {
  xl: {
    fontSize: "85%",
    lineHeight: "1.5rem",
  },
  lg: {
    fontSize: "75%",
    lineHeight: "1.2rem",
  },
  md: {
    fontSize: "65%",
    lineHeight: "0.9rem",
  },
  xs: {
    fontSize: "50%",
    lineHeight: "0.7rem",
  },
};

export { CustomTooltip, MouseTooltip };
