import {
  CLEAN_QUESTION_NUMBER,
  INTERNAL_SIGN_OUT,
  SET_QUESTION_NUMBER,
} from "../../../../store/actions/types";

/**
 * reducer for question number actions
 * @param {Int} state
 * @param {Object} action
 * @returns {Int}
 */
const questionNumberReducer = (state = 0, action) => {
  if (action.type === SET_QUESTION_NUMBER) {
    return action.payload;
  } else if (
    action.type === CLEAN_QUESTION_NUMBER ||
    action.type === INTERNAL_SIGN_OUT
  ) {
    return 0;
  }

  return state;
};

export default questionNumberReducer;
