import React from "react";
import "./ResultsButton.css";

/**
 * Functional Component: ResultsButton
 * The template for every button that is rendered in every results page section
 * @param {Object} customStyles
 * @param {String} theme
 * @param {String} customClass
 * @param {String} id
 * @returns {Element}
 */
const ResultsButton = ({
  customStyles,
  theme = "purple",
  customClass,
  id,
  ...props
}) => {
  return (
    <button
      id={id}
      className={`btn main-custom-button ${customClass} ${theme}`}
      style={{ ...customStyles }}
      {...props}
    />
  );
};

export default ResultsButton;
