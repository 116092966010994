import React, { useEffect } from "react";
import "./Modal.css";
import { CSSTransition } from "react-transition-group";
import ReactDOM from "react-dom";

/**
 * Functional Component: Modal
 * A component for a modal
 *
 * @param {Function} onClose
 * @param {Boolean} show
 * @param {String or Int} minHeight
 * @param {String or Int} width
 * @param {String} title
 * @param {String, Int or Elements} children
 * @param {Function} onSubmit
 * @returns {Element}
 * */
const Modal = ({
  onClose,
  show,
  minHeight,
  width,
  title,
  children,
  onSubmit,
}) => {
  //adds and removes event listeners for closing with escape key
  useEffect(() => {
    //handles closing on pressing escape key
    const closeOnEscapeKeyDown = (e) => {
      if (onClose && (e.charCode || e.keyCode) === 27) onClose();
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);

    return () =>
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  }, [onClose]);

  /**
   * @returns {Element}
   */
  const renderSubmitButton = () => {
    if (!onSubmit) return null;
    return (
      <button onClick={onSubmit} className="modal-submit-button">
        Submit
      </button>
    );
  };

  /**
   * @returns {Element}
   */
  const renderCloseButton = () => {
    if (!onClose) return null;
    return (
      <button onClick={onClose} id="modal-close-button">
        Close
      </button>
    );
  };

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div onClick={onClose} className={`modal-internal ${show ? "show" : ""}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="modal-content"
          style={{ minHeight: minHeight, width: width }}
        >
          <div className="modal-internal-header">
            <h4 className="modal-internal-title">{title}</h4>
          </div>
          <div className="modal-internal-body">{children}</div>
          <div className="modal-internal-footer">
            {renderSubmitButton()}
            {renderCloseButton()}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default Modal;
