import { SAVE_QUERY_PARAMS } from "../../../../store/actions/types";

/**
 * saves the query params present on url into redux
 * @param {Object} queryParams
 * @returns {Object}
 */
const saveQueryParams = (queryParams) => {
  return {
    type: SAVE_QUERY_PARAMS,
    payload: queryParams,
  };
};

export { saveQueryParams };
