/**
 * checks if a survey object contains the survey data inside
 * @param {Object} survey
 * @param {String} surveyId
 * @returns {Boolean}
 */
const doesThisSurveyExist = (survey, surveyId) => {
  return (
    survey && Object.keys(survey).length !== 0 && survey.survey.id === surveyId
  );
};

export { doesThisSurveyExist };
