const getDimensionCopyFromLanguage = ({ dimension, language = "pt-PT" }) => {
  const DIMENSIONS = {
    work: { "en-GB": "work", "pt-PT": "trabalho" },
    physical: { "en-GB": "physical", "pt-PT": "físico" },
    mental: { "en-GB": "mental", "pt-PT": "mental" },
    social: { "en-GB": "social", "pt-PT": "social" },
  };

  const thisDimension = translateDimensions(dimension);
  if (!dimension) return "";
  return DIMENSIONS[thisDimension][language];
};

/**
 * translates dimensions to portuguese
 * @param {String} dimension
 * @returns
 */
const translateDimensions = (dimension) => {
  switch (dimension) {
    case "work":
    case "trabalho":
      return "work";
    case "physical":
    case "físico":
      return "physical";
    case "mental":
      return "mental";
    case "social":
      return "social";
    default:
      return "";
  }
};

export { getDimensionCopyFromLanguage, translateDimensions };
