import React from "react";
import "./ZoneAndScore.css";
import ResultsButton from "../../modules/Results/components/newComponents/ResultsButton";
import {
  SCORE_GRAPH_MARKERS,
  SECONDARY_TEXT,
} from "../../utils/constants/colorsConstants";
import {
  MY_ANALYTICS_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
  RESULTS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
} from "../../utils/constants/constants";
import { scrollToElement } from "../../utils/helpers/geralHelpers/RandomHelper";
import { useResize } from "../../modules/Geral/hooks/useResize";
import ConfidentialityIcon from "../imageComponents/ConfidentialityIcon";
import { redirectToSignupEndpoint } from "../../core/Routing/RoutesRedirects";
import StandardScoreGraph from "../imageComponents/StandardScoreGraph";

/**
 * the rectangle that has the zone and score in the welcome back, shared result, result and analytics page
 * @param {Object} colors
 * @param {String} zone
 * @param {Object} copies
 * @param {Object} variableCopies
 * @returns {Element}
 * */
const ZoneCopy = ({ colors, zone, copies, variableCopies }) => {
  return (
    <>
      <h2 className="fw-bold col-7 col-lg-12 mx-auto lh-2 px-0 zone-and-score__intro">
        {variableCopies.title}
      </h2>

      {renderZoneDiv({ colors, zone })}

      {renderDescriptionContainer({ copy: variableCopies.body })}

      <div className="pb-5">
        <ResultsButton onClick={() => scrollToElement("#radar-graph-info")}>
          {copies.BUTTON_TEXT}
        </ResultsButton>
      </div>
    </>
  );
};

/**
 * @param {String} focusCopy
 * @param {Object} copies
 * @returns {Element}
 */
const LastResultCopy = ({
  focusCopy,
  copies,
  dimensionOfFocus,
  lastResultDate,
}) => {
  return (
    <>
      <h3 className="fw-bold col-10 col-xl-8 lh-2 px-0 mx-auto mx-lg-0 zone-and-score__intro last-results__zone-and-score">
        {copies.TITLE} {dimensionOfFocus}
      </h3>

      <div
        style={{ color: SECONDARY_TEXT }}
        className="zone-and-score__last-result-subtitle"
      >
        {copies.SUBTITLE} {lastResultDate}
      </div>

      {renderDescriptionContainer({ copy: focusCopy })}
    </>
  );
};

/**
 * @param {Object} colors
 * @param {String} zone
 * @param {Object} copy
 * @param {Object} copies
 * @returns {Element}
 */
const PreliminaryCopy = ({
  colors,
  zone,
  copy,
  copies,
  type,
  benefits,
  preliminaryTimer,
  preliminaryResultData,
}) => {
  const [width] = useResize();

  if (width >= 992) {
    if (type === "left")
      return (
        <div className="pb-2">
          <h2 className="fw-bold mb-sm-4 col-7 col-lg-12 mx-auto lh-2 px-0 zone-and-score__intro">
            {copies.TITLE}
          </h2>

          {renderZoneDiv({ colors, zone })}

          {width >= 992 && (
            <div
              className="d-flex align-items-center py-4"
              style={{ width: "calc(35vw)" }}
            >
              <div className="preliminary-image-like">{copy.IMAGE}</div>

              <div
                style={{
                  color: "var(--main-text-1)",
                  fontSize: "110%",
                  lineHeight: "2rem",
                  paddingLeft: "1rem",
                }}
                className="mx-auto mx-lg-0 zone-and-score__preiliminary-subtitle"
              >
                {copy.COPY_PART_1}{" "}
                <span className="ff-popp-semi-bold">{copy.DIMENSION}</span>{" "}
                {copy.COPY_PART_2}
              </div>
            </div>
          )}

          <div style={{ marginTop: "3rem" }}>
            <ResultsButton
              onClick={() => redirectToSignupEndpoint(preliminaryResultData)}
            >
              {copies.BUTTON_TEXT_PART_1}{" "}
              <span className="ff-popp-bold">{copies.BUTTON_TEXT_PART_2}</span>
            </ResultsButton>
          </div>

          <div
            className="d-flex justify-content-between mt-4"
            style={{
              paddingRight: width > 1200 ? "5rem" : "0",
              fontSize: width > 1200 ? "90%" : "80%",
            }}
          >
            <div style={{ padding: "1rem 0" }}>
              <div className="d-flex align-items-center">
                <div style={{ width: "3rem" }}>
                  <ConfidentialityIcon />
                </div>

                <div className="ps-3">
                  {benefits[(preliminaryTimer * 2) % 4]}
                </div>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ padding: "0.5rem 0" }}
              >
                <div style={{ width: "3rem" }}>
                  <ConfidentialityIcon />
                </div>

                <div className="ps-3">
                  {benefits[(preliminaryTimer * 2 + 1) % 4]}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    else return null;
  } else {
    if (type === "left")
      return (
        <div className="pb-2">
          <h2 className="fw-bold mb-sm-4 col-7 col-lg-12 mx-auto lh-2 px-0 zone-and-score__intro">
            {copies.TITLE}
          </h2>

          {renderZoneDiv({ colors, zone })}
        </div>
      );
    else if (type === "right")
      return (
        <div className="d-flex justify-content-center text-center">
          <div>
            <div style={{ fontSize: "95%", color: "var(--purple-1)" }}>
              {copies.KNOW_MORE_TEXT}
            </div>

            <div style={{ margin: "1rem 0 2rem 0" }}>
              <ResultsButton
                onClick={() => redirectToSignupEndpoint(preliminaryResultData)}
              >
                {copies.BUTTON_TEXT_PART_2}
              </ResultsButton>
            </div>

            <div>{benefits[preliminaryTimer % 4]}</div>
          </div>
        </div>
      );
  }
};

/**
 * @param {Object} colors
 * @param {String} zone
 * @returns {Element}
 */
const renderZoneDiv = ({ colors, zone }) => {
  return (
    <div
      style={{ color: colors ? colors.main : null }}
      className="display-2 fw-bold ff-popp-bold zone-and-score__zone"
    >
      {zone}
    </div>
  );
};

/**
 * @param {String} copy
 * @returns {Element}
 */
const renderDescriptionContainer = ({ copy }) => {
  return (
    <div className="d-flex justify-content-center justify-content-lg-start zone-and-score__description-container">
      <div className="zone-and-score__description">{copy}</div>
    </div>
  );
};

/**
 * @param {String} zone
 * @param {Object} colors
 * @param {String or Int} score
 * @param {String} focusCopy
 * @param {Date or String} lastResultDate
 * @param {Object} preliminaryResultCopy
 * @param {String} page
 * @param {Object} copies
 * @param {Object} dimensions
 * @returns {Element}
 */
const ZoneAndScore = ({
  zone,
  colors,
  score,
  focusCopy,
  lastResultDate,
  preliminaryResultCopy,
  preliminaryResultData,
  page,
  copies,
  variableCopies,
  dimensions,
  benefits,
  preliminaryTimer,
}) => {
  const renderLeftCopy = () => {
    switch (page) {
      case SHARE_RESULTS_PAGE_ID:
      case WELCOME_BACK_PAGE_ID:
      case RESULTS_PAGE_ID:
        return (
          <ZoneCopy
            colors={colors}
            zone={zone}
            copies={copies.ZONE}
            variableCopies={variableCopies}
          />
        );
      case MY_ANALYTICS_PAGE_ID:
        return (
          <LastResultCopy
            focusCopy={focusCopy}
            copies={copies.LAST_RESULT}
            dimensionOfFocus={dimensions.LOWEST}
            lastResultDate={lastResultDate}
          />
        );
      case PRELIMINARY_RESULTS_PAGE_ID:
        return (
          <PreliminaryCopy
            colors={colors}
            copy={preliminaryResultCopy}
            zone={zone}
            copies={copies.PRELIMINARY}
            benefits={benefits}
            preliminaryTimer={preliminaryTimer}
            preliminaryResultData={preliminaryResultData}
            type="left"
          />
        );
      default:
        return null;
    }
  };

  const renderRightCopy = () => {
    switch (page) {
      case PRELIMINARY_RESULTS_PAGE_ID:
        return (
          <PreliminaryCopy
            colors={colors}
            copy={preliminaryResultCopy}
            zone={zone}
            copies={copies.PRELIMINARY}
            benefits={benefits}
            preliminaryTimer={preliminaryTimer}
            type="right"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row align-items-lg-center zone-and-score">
      <div className="col-lg-6 text-center text-lg-start zone-and-score__text">
        {renderLeftCopy()}
      </div>

      <div className="text-center pt-0 pt-lg-5 pb-5 d-flex justify-content-center justify-content-lg-start">
        <div>
          <StandardScoreGraph
            score={score}
            colors={colors}
            copies={{ ...copies.GRAPH, SCORE: score }}
            markers={SCORE_GRAPH_MARKERS}
          />
        </div>
      </div>

      {renderRightCopy()}
    </div>
  );
};

export default ZoneAndScore;
