import React, { useEffect, useState } from "react";
import "./Flash.css";
import { connect } from "react-redux";
import { resetFlashProps } from "../../store/actions";
import { getStaticFlashPropsCopies } from "../../utils/constants/copies/copies.helper";

// Flash Themes
const themes = {
  blue: {
    backgroundColor: "#cfe2ff",
    color: "#084298",
    borderColor: "#b6d4fe",
  },
  purple: {
    backgroundColor: "var(--purple-2)",
    color: "var(--main-text-1)",
    borderColor: "var(--hover-outline)",
  },
  gray: {},
  green: {},
  red: { color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb" },
  yellow: {},
  aqua: {},
  white: {},
  dark: {},
};

/**
 * Functional Component: Flash
 * Flash alert component, used for alerts all over violet
 *
 * @param {Object} flashProps: the props that come from redux
 * @param {Function} resetFlashProps: resets the props in redux
 * @param {String} language
 * @returns {Element} the flash, when there are props in redux
 */
const Flash = ({ flashProps, resetFlashProps, language }) => {
  const [ownProps, setProps] = useState({ active: false });

  const SELF_COPY = getStaticFlashPropsCopies({
    language,
    type: ownProps.type,
  }).COPY;

  // everytime props for flash are set, show the flash and after some time make it disappear
  useEffect(() => {
    if (flashProps.active) {
      setProps(flashProps);
      resetFlashProps();
      setTimeout(() => {
        flashProps.onClose();
        setProps({ active: false });
      }, flashProps.delay);
    }
  }, [flashProps, resetFlashProps, ownProps]);

  return ownProps.active ? (
    <div className="custom-flash" style={{ ...themes[ownProps.theme] }}>
      {SELF_COPY}
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return { flashProps: state.flashProps, language: state.language };
};

export default connect(mapStateToProps, { resetFlashProps })(Flash);
