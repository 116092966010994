/**
 * converts a file to base64
 * @param {Not sure} data
 * @returns {String}
 */
const convertFileToBase64 = (data) => {
  if (!data) return null;
  return `data:image/svg+xml;base64,${new Buffer.from(data).toString(
    "base64"
  )}`;
};

export { convertFileToBase64 };
