import axios from "axios";
import { cleanAllRedux } from "../../store/actions";
import store from "../../store/storeConfig.js";
import { redirectToLandingPage } from "../Routing/RoutesRedirects";

axios.defaults.withCredentials = true;

//Personalized axios to make request to the login backend
let login_backend = axios.create({
  baseURL: process.env.REACT_APP_LOGIN_BACKEND,
});

login_backend.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    //if the error status code is 403 then we try to refresh the access token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      await login_backend.get("/refresh");

      return login_backend(originalRequest);
    } else if (error.response.status === 403 && originalRequest._retry) {
      store.dispatch(cleanAllRedux());
      redirectToLandingPage();
    }

    return Promise.reject(error);
  }
);

export default login_backend;
