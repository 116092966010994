import backend from "../../../core/apis/backend";
import login_backend from "../../../core/apis/login_backend";
import { CORE_SURVEY_ID } from "../../constants/constants";

/**
 * @param {String} userId
 * @param {String} birthdate
 */
const changeBirthdate = async (userId, birthdate) => {
  let formated = birthdate.replaceAll("-", "/");

  await login_backend.put(`/users/${userId}`, { birthdate: formated });
};

/**
 * @param {String} userId
 * @param {String} gender
 */
const changeGender = async (userId, gender) => {
  await login_backend.put(`/users/${userId}`, { gender });
};

/**
 * @param {String} userId
 * @param {String} country
 */
const changeCountry = async (userId, country) => {
  await login_backend.put(`/users/${userId}`, { country });
};

/**
 * @param {Int} id
 */
const deleteTemporaryScores = async (id) => {
  await backend.delete(`/temporary_scores/${id}`);
};

/**
 * @param {String} userId
 * @returns {Object}
 */
const fetchTimeButtonsObject = async (userId) => {
  const { data } = await backend.post(`/timeButtonsObject/${userId}`);
  return data;
};

/**
 * @param {String} userId
 * @param {Object} filters
 * @param {Int} dimension_id
 * @param {String} selectedTime
 * @param {String} language
 * @returns {Object}
 */
const fetchSpreadedResults = async (
  userId,
  filters,
  dimension_id,
  selectedTime,
  language
) => {
  const { data } = await backend.post(`/spreadedResults/${userId}`, {
    filters,
    dimension_id,
    selectedTime,
    language,
  });

  return data;
};

/**
 * @param {Int} surveyId
 * @param {String} userId
 * @returns {Object}
 */
const fetchUserSurveyLastResult = async (surveyId, userId) => {
  try {
    const { data } = await backend.get(`/lastResult/${surveyId}/${userId}`);
    return data.data;
  } catch (e) {
    return {};
  }
};

const fetchSurvey = async (surveyId) => {
  try {
    const { data } = await backend.get(`/surveys/${surveyId}`);
    return data.data;
  } catch (e) {
    return {};
  }
};

/**
 * @returns {Array}
 */
const fetchDimensions = async () => {
  const { data } = await backend.get("/dimensions");
  return data.data;
};

/**
 * @returns {Array}
 */
const fetchFeels = async () => {
  const { data } = await backend.get("/feels");
  return data.data;
};

/**
 * @param {String} result_share_id
 * @param {String} country
 * @param {String} countryAndGender
 * @param {String} countryAndGenderAndAge
 * @param {String} countryName
 */
const createSharedFilteredData = async ({
  result_share_id,
  country,
  countryAndGender,
  countryAndGenderAndAge,
  countryName,
}) => {
  try {
    await backend.post("/shared_filtered_data", {
      result_share_id,
      country,
      countryAndGender,
      countryAndGenderAndAge,
      countryName,
    });
  } catch (e) {
    console.log(e);
  }
};

/**
 * @param {Int} tipId
 * @returns {Object}
 */
const fetchTip = async (tipId) => {
  try {
    const { data } = await backend.get(`/tips/${tipId}`);
    return data;
  } catch (e) {
    return {};
  }
};

/**
 * @param {Int} String
 * @param {String} zone
 * @param {String} userId
 * @param {String} category
 * @returns {Object}
 */
const fetchTipByAttributes = async ({
  dimensionId,
  zone,
  userId,
  category,
}) => {
  const { data } = await backend.post("/tips", {
    dimension_id: dimensionId,
    zone,
    category,
    survey_id: CORE_SURVEY_ID,
    wellbeing_user_id: userId,
  });

  return data;
};

/**
 * @param {String} userId
 */
const deleteLastResult = async (userId) => {
  await backend.delete("/test_delete_results", {
    data: { user_id: userId },
  });
};

/**
 * @param {String} email
 * @returns {Object}
 */
const doesEmailExist = async (email) => {
  const { data } = await login_backend.post(`/doesEmailExist`, { email });
  return data;
};

/**
 *
 * @param {Object} copyData
 * @returns {Object}
 */
const getCopies = async (copyData) => {
  const { data } = await backend.post("/copies", {
    survey_id: CORE_SURVEY_ID,
    attribute: copyData.attribute,
    copy_versions: copyData.copy_versions,
    language: copyData.language,
    name: copyData.name,
    gender: copyData.gender,
  });

  return data;
};

/**
 *
 * @param {String} userId
 * @returns {Object}
 */
const fetchLastWelcomingResult = async (userId) => {
  try {
    const { data } = await backend.get(`/lastWelcomingResult/${userId}`);
    return data.data;
  } catch (e) {
    return {};
  }
};

/**
 *
 * @param {String} userId
 * @returns {Object}
 */
const fetchLastResultBeforeToday = async (userId) => {
  try {
    const { data } = await backend.get(
      `/lastResultBeforeToday/${CORE_SURVEY_ID}/${userId}`
    );
    return data.data;
  } catch (e) {
    return {};
  }
};

/**
 * fetches filtered data
 * @param {String} share_result_id
 * @returns {Object}
 */
const fetchOtherFilteredData = async (share_result_id) => {
  try {
    const { data } = await backend.get(
      `/share_filtered_data/${share_result_id}`
    );

    return data.data;
  } catch (e) {
    return null;
  }
};

/**
 * gets a result by his share id
 * @param {String} share_result_id
 * @returns {Object}
 */
const fetchResultByShareId = async (share_result_id) => {
  try {
    const { data } = await backend.get(`resultByShareId/${share_result_id}`);
    return data;
  } catch (e) {
    return {};
  }
};

const fetchUsersNumber = async () => {
  try {
    const { data } = await login_backend.post("/countUsers", { filters: {} });
    return data;
  } catch (e) {
    return {};
  }
};

const createAnalytic = async ({ action, category }) => {
  try {
    await backend.post("/analytics", { action, category });
  } catch (e) {}
};

export {
  changeBirthdate,
  changeGender,
  changeCountry,
  deleteTemporaryScores,
  fetchUserSurveyLastResult,
  fetchTimeButtonsObject,
  fetchSpreadedResults,
  fetchDimensions,
  createSharedFilteredData,
  fetchTip,
  fetchTipByAttributes,
  deleteLastResult,
  fetchFeels,
  doesEmailExist,
  getCopies,
  fetchLastWelcomingResult,
  fetchLastResultBeforeToday,
  fetchOtherFilteredData,
  fetchResultByShareId,
  fetchSurvey,
  fetchUsersNumber,
  createAnalytic,
};
