import { LANGUAGE_SAVE } from "../../../../store/actions/types";

/**
 * saves the language choosen by the user into redux
 * @param {String} language
 * @returns
 */
const saveLanguage = (language) => {
  return {
    type: LANGUAGE_SAVE,
    payload: language,
  };
};

export { saveLanguage };
