import {
  RESET_FLASH_PROPS,
  SET_FLASH_PROPS,
} from "../../../../store/actions/types";

/**
 * sets the flash redux state for alerts
 * @param {Object} payload
 * @returns {Object}
 */
const setFlashProps = (payload) => {
  return {
    type: SET_FLASH_PROPS,
    payload,
  };
};

/**
 * resets the flash redux state
 * @returns {Object}
 */
const resetFlashProps = () => {
  return {
    type: RESET_FLASH_PROPS,
  };
};

export { setFlashProps, resetFlashProps };
