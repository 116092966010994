import React from "react";
import Select from "react-select";

/**
 * Class Component: DropdownComponent
 * Renders a dropdown
 *
 * @returns {Element}
 */
class DropdownComponent extends React.Component {
  state = { selectedOptions: null };

  /**
   * handles changes on the dropdown
   * @param {Object} event
   * */
  handleChange = (event) => {
    const { onSelectedOption, onChange } = this.props;

    this.setState({ selectedOptions: event });
    if (onSelectedOption) {
      onSelectedOption(event);
    }
    if (onChange) {
      onChange(event);
    }
  };

  /**
   * returns the custom x styles values
   * @returns {Object}
   */
  getCustomXStyles = () => {
    if (window.innerWidth >= 576) {
      return { width: 13 };
    } else if (window.innerWidth >= 0) {
      return { width: 13, left: "98%" };
    }
  };

  /**
   * clears the dropdown
   * @returns {void}
   */
  clearFunction = () => {
    const { isMulti } = this.props;

    if (isMulti) {
      this.handleChange([]);
    } else {
      this.handleChange(null);
    }
  };

  /**
   * renders the clear button to clean the dropdown
   * @returns {Element}
   */
  renderClearButton = () => {
    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          left: "100%",
          height: "100%",
          zIndex: 10,
          ...this.getCustomXStyles(),
        }}
        onClick={() => this.clearFunction()}
      >
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class="date-picker-clear-indicator"
          fill={"black"}
          color={"black"}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
      </div>
    );
  };

  render() {
    /**
     * @param {Array} data
     * @param {Boolean} isMulti
     * @param {String or Int} defaultValue
     * @param {String or Int} value
     * @param {Boolean} disabled
     * @param {Object} customStyles
     * @param {Int or String} maxMenuHeight
     * @param {Boolean} isClearable
     * @param {Boolean} isSearchable
     * @param {Boolean} progressOverTime
     */
    const {
      data,
      isMulti,
      defaultValue,
      value,
      disabled,
      customStyles,
      maxMenuHeight,
      isClearable,
      isSearchable,
      progressOverTime,
    } = this.props;

    console.log(this.state.selectedOptions ?? value, 1234);

    if (progressOverTime) {
      return (
        <div style={{ position: "relative" }}>
          {!this.state.selectedOptions || this.state.selectedOptions.length < 1
            ? null
            : this.renderClearButton()}
          <Select
            {...this.props}
            classNamePrefix="dropdown-component"
            maxMenuHeight={maxMenuHeight ?? "10rem"}
            options={data}
            onChange={this.handleChange}
            isMulti={isMulti ?? false}
            defaultValue={defaultValue ?? []}
            value={this.state.selectedOptions ?? value}
            isDisabled={disabled ?? false}
            styles={customStyles}
            isClearable={isClearable}
            isSearchable={isSearchable}
          />
        </div>
      );
    } else {
      return (
        <Select
          {...this.props}
          classNamePrefix="dropdown-component"
          maxMenuHeight={maxMenuHeight ?? "10rem"}
          options={data}
          onChange={this.handleChange}
          isMulti={isMulti ?? false}
          defaultValue={defaultValue ?? []}
          value={this.state.selectedOptions ?? value}
          isDisabled={disabled ?? false}
          styles={customStyles}
          isClearable={isClearable}
          isSearchable={isSearchable}
        />
      );
    }
  }
}

export default DropdownComponent;
