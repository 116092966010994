import backend from "../../../core/apis/backend";
import login_backend from "../../../core/apis/login_backend";
import { redirectToLandingPage } from "../../../core/Routing/RoutesRedirects";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";
import {
  createAnalytic,
  fetchUserSurveyLastResult,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import countries from "../../../utils/helpers/usersHelpers/countries.json";
import portugueseCountries from "../../../utils/countriesPortugues.json";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import { EVENTS } from "../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * renders the alert periodicity dropdown data
 * @param {String} language
 * @returns {Array}
 */
const renderDropDownData = (language) => {
  let options = [
    "daily",
    "weekly",
    "biweekly",
    "monthly",
    "don't receive reminders",
  ];
  let otherOptions = [];

  if (language === "pt-PT") {
    otherOptions = [
      "diários",
      "semanais",
      "quinzenais",
      "mensais",
      "não receber alertas por e-mail",
    ];
  }

  return options.map((option, index) => {
    return {
      value: option,
      label: language === "pt-PT" ? otherOptions[index] : option,
    };
  });
};

/**
 * translates the reminders between portuguese and english
 * @param {String} type
 * @param {String} language
 * @returns {String}
 */
const languageToReminderLabel = (type, language) => {
  const a = {
    daily: {
      "en-GB": "daily",
      "pt-PT": "diários",
    },
    weekly: {
      "en-GB": "weekly",
      "pt-PT": "semanais",
    },
    biweekly: {
      "en-GB": "biweekly",
      "pt-PT": "quinzenais",
    },
    monthly: {
      "en-GB": "monthly",
      "pt-PT": "mensais",
    },
    "don't receive reminders": {
      "en-GB": "don't receive reminders",
      "pt-PT": "não receber alertas por e-mail",
    },
  };

  return a[type][language];
};

/**
 * translates the genders to portuguese
 * @param {String} label
 * @returns {String}
 */
const languageToGenderLabel = (label) => {
  if (label === "Male") {
    return "Masculino";
  } else if (label === "Female") {
    return "Feminino";
  } else if (label === "Other") {
    return "Outro";
  }
};

/**
 * renders the gender dropdown data
 * @param {String} language
 * @returns {Array}
 */
const renderDropdownGenderData = (language) => {
  if (language === "pt-PT") {
    return [
      { value: "M", label: "Masculino" },
      { value: "F", label: "Feminino" },
      { value: "O", label: "Outro" },
    ];
  }
  return [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
};

/**
 * converts the gender dropdown values to labels
 * @param {String} gender
 * @returns {String}
 */
const genderFormatter = (gender) => {
  if (!gender) return null;
  const genders = {
    M: "Male",
    F: "Female",
    O: "Other",
  };

  return genders[gender];
};

/**
 * renders the country dropdown data
 * @param {String} language
 * @returns {Array}
 */
const renderDropdownCountryData = (language) => {
  if (language === "en-GB") {
    return countries.map((country) => {
      return {
        value: country.alpha2Code,
        label: country.name,
      };
    });
  } else if (language === "pt-PT") {
    return portugueseCountries.map((country) => {
      return {
        value: country.alpha2Code,
        label: country.name,
      };
    });
  }
};

/**
 * formats the country alpha code to its country name
 * @param {String} country
 * @param {String} language
 * @returns {String}
 */
const countryFormatter = (country, language) => {
  if (!country) return null;

  if (language === "pt-PT") {
    for (let oneCountry of portugueseCountries) {
      if (oneCountry.alpha2Code === country) {
        return oneCountry.name;
      }
    }
  } else {
    for (let oneCountry of countries) {
      if (oneCountry.alpha2Code === country) {
        return oneCountry.name;
      }
    }
  }
};

/**
 * saves the new attributes the user has provided
 * @param {Object} currentUser
 * @param {Function} fetchUserWithAllAttributes
 * @param {Function} setEnabledInputs
 * @param {Object} aboutMeInfo
 * @param {Function} setFlashProps
 * @param {Function} internalSignOut
 */
const saveAboutMeAttributes = async (
  currentUser,
  fetchUserWithAllAttributes,
  setEnabledInputs,
  aboutMeInfo,
  setFlashProps,
  internalSignOut
) => {
  try {
    let info = { ...aboutMeInfo };

    if (info.birthdate) info.birthdate = info.birthdate.replaceAll("-", "/");
    if (info.gender || info.birthdate || info.country)
      info.expiry_date = "none";

    await login_backend.put(`/users/${currentUser.attributes.user_id}`, info);

    await fetchUserWithAllAttributes(currentUser.attributes.user_id);

    setEnabledInputs({
      first_name: false,
      last_name: false,
      gender: false,
      birthdate: false,
      email: false,
      password: false,
      country: false,
      periodicity: false,
    });

    if (info.email !== currentUser.attributes.email) {
      setFlashProps(FLASH_TYPES.CHANGE_EMAIL);

      setTimeout(async () => {
        await internalSignOut();
        redirectToLandingPage();
      }, 2000);
    }

    createAnalytic(EVENTS.CHANGED_DATA_ABOUT_ME_SUCCESSFUL);
  } catch (e) {
    setFlashProps(FLASH_TYPES.UNEXPECTED_ERROR);

    createAnalytic(EVENTS.CHANGED_DATA_ABOUT_ME_FAILED);
  }
};

//represents the time period for each periodicity
const daysForPeriodicity = {
  daily: 1,
  weekly: 7,
  biweekly: 14,
  monthly: 30,
};

/**
 * returns the next email date for alert
 * @param {String} periodicity
 * @param {String} userId
 * @returns {Date}
 */
const getNextEmailDate = async (periodicity, userId) => {
  if (periodicity === "don't receive reminders") return null;

  let lastResult;

  try {
    lastResult = await fetchUserSurveyLastResult(CORE_SURVEY_ID, userId);
  } catch (err) {
    lastResult = null;
  }

  const lastResultDate = lastResult?.attributes?.created_at
    ? new Date(lastResult.attributes.created_at)
    : new Date();
  const nextEmailDate = new Date(
    lastResultDate.getFullYear(),
    lastResultDate.getMonth(),
    lastResultDate.getDate() + daysForPeriodicity[periodicity]
  );

  const today = new Date();
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    3
  );

  return new Date(Math.max(nextEmailDate, tomorrow));
};

/**
 * saves the new alert periodicity
 * @param {Object} currentUser
 * @param {String} alertPeriodicity
 * @param {Function} fetchUserWithAllAttributes
 */
const savePeriodicity = async (
  currentUser,
  alertPeriodicity,
  fetchUserWithAllAttributes
) => {
  const nextEmailDate = await getNextEmailDate(
    alertPeriodicity,
    currentUser.attributes.user_id
  );

  try {
    await backend.put(`/alerts/${currentUser.attributes.user_id}`, {
      periodicity: alertPeriodicity,
      next_email: nextEmailDate,
    });

    await fetchUserWithAllAttributes(currentUser.attributes.user_id);

    createAnalytic(EVENTS.REMINDERS_PROFILE_INFO_SUCCESSFUL);
  } catch (err) {
    createAnalytic(EVENTS.REMINDERS_PROFILE_INFO_FAILED);
  }
};

/**
 * deletes an account on request
 * @param {Object} currentUser
 * @param {Function} cleanAllRedux
 * @param {Function} setFlashProps
 * @param {String} language
 */
const deleteAccount = async (
  currentUser,
  cleanAllRedux,
  setFlashProps,
  language
) => {
  //await backend.delete(`/wellbeing_users/${currentUser.attributes.user_id}`);
  try {
    await login_backend.delete(`/users/${currentUser.attributes.user_id}`, {
      data: { language },
    });
    cleanAllRedux();
    redirectToLandingPage();

    setFlashProps(FLASH_TYPES.DELETE_ACCOUNT);

    createAnalytic(EVENTS.DELETE_ACCOUNT_SUCCESSFUL);
  } catch (err) {
    createAnalytic(EVENTS.DELETE_ACCOUNT_FAILED);
  }
};

/**
 * returns the cate when the user was created
 * @param {Object} currentUser
 * @returns {String}
 */
const getUserSinceDate = (currentUser) => {
  return currentUser.attributes.created_at.split("T")[0];
};

/**
 * encrypts something to base 64
 * @param {Any} arr
 * @returns {String}
 */
const toBase64 = (arr) => {
  return Buffer.from(arr).toString("base64");
};

//clicks the image input when the choose button is clicked
const onChooseImageClick = () => {
  document.getElementById("hidden-image-input").click();
};

/**
 * translates all inputs labels to portuguese
 * @param {String} language
 * @param {String} label
 * @returns {String}
 */
const getTranslatedLabel = (language, label) => {
  if (language === "pt-PT") {
    switch (label) {
      case "First name":
        return "Primeiro Nome";
      case "Last name":
        return "Último nome";
      case "Gender":
        return "Género";
      case "Date of birth":
        return "Data de nascimento";
      case "Current location":
        return "Localização atual";
      case "Reminders":
        return "Receber alertas";
      default:
        return null;
    }
  }
};

export {
  renderDropDownData,
  renderDropdownGenderData,
  renderDropdownCountryData,
  genderFormatter,
  countryFormatter,
  saveAboutMeAttributes,
  savePeriodicity,
  deleteAccount,
  getUserSinceDate,
  toBase64,
  onChooseImageClick,
  getTranslatedLabel,
  languageToReminderLabel,
  languageToGenderLabel,
};
