import backend from "../../../core/apis/backend";
//import login_backend from "../../../core/apis/login_backend";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";

import {
  fetchDimensions,
  fetchLastResultBeforeToday,
  fetchLastWelcomingResult,
  fetchSpreadedResults,
  fetchTip,
  fetchUserSurveyLastResult,
} from "../../../utils/helpers/geralHelpers/BackendHelper";

import focus_on_physical from "../../../assets/svg/focus-on-physical.svg";
import focus_on_mental from "../../../assets/svg/focus-on-mental.svg";
import focus_on_social from "../../../assets/svg/focus-on-social.svg";
import focus_on_work from "../../../assets/svg/focus-on-work.svg";
import physical_tip from "../../../assets/svg/physical-tip.svg";
import mental_tip from "../../../assets/svg/mental-tip.svg";
import social_tip from "../../../assets/svg/social-tip.svg";
import work_tip from "../../../assets/svg/work-tip.svg";
import chooseNextPath from "../../Auth/services/chooseNextPath";
import { datesAreOnSameDay } from "../../../utils/helpers/geralHelpers/RandomHelper";
import { zoneObjectFromScore } from "../../../utils/helpers/geralHelpers/ZonesHelper";
import { translateTipById } from "../../../utils/constants/copies/tipsCopies.helper";
import { capitalize } from "@material-ui/core";
import { getStaticResultsPagesCopies } from "../../../utils/constants/copies/copies.helper";
import { getZoneCopyFromLanguage } from "../../Surveys/services/questionContainer.service";
import { getAllFilteredAverages } from "../../Results/services/CompareWithOthers.service";
import { countryFormatter } from "./aboutMe.service";
import { CORE_SURVEY_POSSIBLE_RESULTS_CONTENT } from "../../../utils/constants/copies/variableCopies.helper";

/**
 * calculates the zone for the score
 * @param {Array} possible_results
 * @param {Object} lastResult
 * @param {String} language
 * @returns {String}
 */
const getZone = (possible_results, lastResult, language) => {
  return possible_results.filter((possible_result) => {
    return (
      possible_result.attributes.min_value <= lastResult.attributes.total &&
      possible_result.attributes.max_value >= lastResult.attributes.total &&
      !possible_result.attributes.possible_result_content.attributes
        .dimension_id
    );
  })[0].attributes.possible_result_content.attributes.content;
};

const formatZone = (zone) => {
  return zone.split(" ").map(capitalize).join(" ");
};

/**
 * returns the results object
 * @param {Object} lastResult
 * @param {Array} dimensions
 * @returns {Object}
 */
const getResultsObject = ({ lastResult, dimensions }) => {
  const dimensionValues = createDimensionValuesObject(
    lastResult.attributes.result_values,
    dimensions
  );
  return convertDimensionValuesToResultsObject(dimensionValues);
};

/**
 * returns the focus image depending on the dimension
 * @param {String} dimension
 * @returns {Object}
 */
const getFocus = ({ dimension }) => {
  switch (dimension) {
    case "physical":
      return { image: focus_on_physical };
    case "mental":
      return { image: focus_on_mental };
    case "social":
      return { image: focus_on_social };
    case "work":
      return { image: focus_on_work };
    default:
      return { image: null };
  }
};

/**
 * returns the tip image depending on the dimension
 * @param {String} dimension
 * @returns {Object}
 */
const getTipImage = ({ dimension }) => {
  switch (dimension) {
    case "physical":
      return { image: physical_tip };
    case "mental":
      return { image: mental_tip };
    case "social":
      return { image: social_tip };
    case "work":
      return { image: work_tip };
    default:
      return { image: null };
  }
};

/**
 * formats the tip into a specific object
 * @param {Object} tip
 * @param {String} dimension
 * @returns {Object}
 */
const formatTip = ({ tip, dimension }) => {
  return {
    topic: tip.data.attributes.topic,
    title: tip.data.attributes.title,
    intro: tip.data.attributes.intro,
    subtitle: tip.data.attributes.subtitle,
    action: tip.data.attributes.action,
    image: getTipImage({ dimension }).image,
  };
};

/**
 * returns the highest dimension
 * @param {Int} dimensionId
 * @param {Array} dimensions
 * @returns {String}
 */
const getDimension = (dimensionId, dimensions) => {
  return dimensions.find((dimension) => {
    return dimension.ids.includes(dimensionId);
  }).dimension_type;
};

/**
 * formats the last results values into the dimension values object
 * @param {Array} lastResultValues
 * @param {Array} dimensions
 * @returns {Array}
 */
const createDimensionValuesObject = (lastResultValues, dimensions) => {
  let dimensionValues = [];

  dimensions = dimensions.filter(
    (dimension) => dimension.dimension_type !== "purpose"
  );

  lastResultValues.forEach((result_value) => {
    dimensions.forEach((dimension) => {
      if (dimension.ids.includes(result_value.attributes.dimension_id)) {
        dimensionValues.push({
          dimension: dimension.dimension_type,
          value: result_value.attributes.value,
        });
      }
    });
  });

  return dimensionValues;
};

/**
 * transforms the dimension values into the final results object
 * @param {Array} dimensionValues
 * @returns {Array}
 */
const convertDimensionValuesToResultsObject = (dimensionValues) => {
  return dimensionValues
    .map((dimension) => {
      return { [dimension.dimension]: Math.round(dimension.value) };
    })
    .reduce((prev, current) => {
      return { ...prev, ...current };
    });
};

/**
 * retrieves all needed info for welcome back componenet rendering
 * @param {Object} currentUser
 * @returns {Object}
 */
const getAllResources = async (currentUser) => {
  const lastWelcomeResult = await fetchLastWelcomingResult(
    currentUser.attributes.user_id
  );

  const lastResult = await fetchUserSurveyLastResult(
    CORE_SURVEY_ID,
    currentUser.attributes.user_id
  );

  if (
    !Object.keys(lastWelcomeResult).length ||
    !Object.keys(lastResult).length ||
    !datesAreOnSameDay(new Date(lastResult.attributes.created_at), new Date())
  ) {
    chooseNextPath(currentUser);
    return;
  }

  const lastResultBeforeToday = await fetchLastResultBeforeToday(
    currentUser.attributes.user_id
  );

  const dimensions = await fetchDimensions();

  const {
    attributes: { country, gender, birthdate },
  } = currentUser;
  let filteredAverages = {};

  if (gender && birthdate && country) {
    const newFilteredAverages = await getAllFilteredAverages(currentUser);

    filteredAverages = {
      data: {
        ...newFilteredAverages,
        countryName: countryFormatter(country),
      },
    };
  }

  const lowestDimension = getDimension(
    lastResult.attributes.lowestDimension,
    dimensions
  );

  const highestDimension = getDimension(
    lastResult.attributes.highestDimension,
    dimensions
  );

  const purposePercentage = getPurposePercentage({
    dimensions,
    result: lastResult,
  });

  return {
    lastResult,
    lastResultBeforeToday,
    dimensions,
    lowestDimension,
    filteredAverages,
    purposePercentage,
    highestDimension,
  };
};

/**
 * returns the filtered average of all users depending on applied filters
 * @param {Object} filterState
 * @returns {Int}
 */
const getGlobalFilteredAverage = async (filterState) => {
  const { minAge, maxAge, country, gender } = filterState;

  try {
    const { data } = await backend.post(`/filteredAverage/${CORE_SURVEY_ID}`, {
      country,
      gender,
      minAge,
      maxAge,
    });

    return data.average;
  } catch (e) {
    return "No info for this range.";
  }
};

/**
 * returns the percentage of users in the same score range depending on filters and score
 * @param {Object} filterState
 * @param {Object} lastResult
 * @returns {Int}
 */
const getUsersOnSameRange = async (filterState, lastResult) => {
  try {
    const { min_value, max_value } = zoneObjectFromScore(
      lastResult.attributes.total
    ).rangeValues;

    const usersOnSameRange = await backend.post(
      `/filteredUsersInSameRange/${CORE_SURVEY_ID}`,
      {
        filters: {
          ...filterState,
        },
        min_value: min_value,
        max_value: max_value,
      }
    );

    return usersOnSameRange.data.usersPercentageWithSameScoreRange;
  } catch (e) {
    return "No info for this range.";
  }
};

/**
 * returns the spreaded resuls by time periods
 * @param {Object} filterState
 * @param {String} userId
 * @param {String} selectedTime
 * @param {String} language
 * @returns {Object}
 */
const getFilteredResultsByPeriods = async (
  filterState,
  userId,
  selectedTime,
  language
) => {
  let filters = {
    minAge: filterState.minAge,
    maxAge: filterState.maxAge,
    country: filterState.country,
    gender: filterState.gender,
  };

  try {
    return await fetchSpreadedResults(
      userId,
      filters,
      filterState.dimension,
      selectedTime,
      language
    );
  } catch (e) {
    return await fetchSpreadedResults(
      userId,
      filters,
      filterState.dimension,
      selectedTime,
      language
    );
  }
};

/**
 * sorts dimensions by their score
 * @param {Array} resultsByDimension
 * @returns {Array}
 */
const sortDimensions = (resultsByDimension) => {
  resultsByDimension.sort((a, b) => {
    if (a.value > b.value) {
      return -1;
    }
    if (a.value < b.value) {
      return 1;
    }
    return 0;
  });

  let filtered = [];

  resultsByDimension.forEach((result) => {
    if (result.dimension !== "purpose") {
      filtered.push(result);
    }
  });

  return filtered;
};

/**
 * calculates witch dimension will be used for the focus copy
 * @param {Array} sortedDimensionsValues
 * @param {Int} lowestDimensionId
 * @param {Array} dimensions
 * @returns {Object}
 */
const getDimensionForCopy = (
  sortedDimensionsValues,
  lowestDimensionId,
  dimensions
) => {
  let lowerDimensions = [];
  const dimensionType = dimensions.find((dimension) => {
    return dimension.ids.includes(lowestDimensionId);
  }).dimension_type;

  lowerDimensions.push(
    sortedDimensionsValues[sortedDimensionsValues.length - 1]
  );

  for (let i = sortedDimensionsValues.length - 2; i >= 0; i--) {
    if (sortedDimensionsValues[i].value === lowerDimensions[0].value) {
      lowerDimensions.push(sortedDimensionsValues[i]);
    } else {
      break;
    }
  }

  const lowerDimension = lowerDimensions.find((dimension) => {
    return dimension.dimension === dimensionType;
  });

  return {
    dimension: lowerDimension,
    numberOfDimensionsThatContribute: lowerDimensions.length,
  };
};

/**
 * uses the dimension for copy to get the copy from possible results
 * @param {Object} dimensionForCopy
 * @param {Array} dimensions
 * @param {Array} possibleResults
 * @returns {Object}
 */
const getCopyContentFromPossibleResults = (
  dimensionForCopy,
  dimensions,
  possibleResults
) => {
  const dimensionIdsForDimensionForCopy = dimensions.find((dimension) => {
    return dimensionForCopy.dimension === dimension.dimension_type;
  }).ids;

  const possibleResult = possibleResults.filter((possible_result) => {
    return dimensionIdsForDimensionForCopy.includes(
      possible_result.attributes.possible_result_content.attributes.dimension_id
    );
  });

  return {
    header:
      possibleResult[0].attributes.possible_result_content.attributes.header,
    content:
      possibleResult[0].attributes.possible_result_content.attributes.content,
    id: possibleResult[0].id,
  };
};

/**
 * Function: getTipsWithDimension
 * Gets tips with dimension attached for TipsInfo on CoreResultsTemplate
 * @param {Object} lastResult
 * @param {Object} dimensions
 * @param {String} language
 * @returns {Object}
 */
const getTipsWithDimension = async ({
  lastResult,
  dimensions,
  language,
  gender,
}) => {
  try {
    const highestTip = await fetchTip(lastResult.attributes.highestTip);
    const translatedHigherTip = translateTipById(highestTip, language, gender);
    const highestDimension = getDimension(
      highestTip.data.attributes.dimension_id,
      dimensions
    );

    const lowestTip = await fetchTip(lastResult.attributes.lowestTip);
    const translatedLowerTip = translateTipById(lowestTip, language, gender);
    const lowestDimension = getDimension(
      lowestTip.data.attributes.dimension_id,
      dimensions
    );

    return {
      highestTip: formatTip({
        dimension: highestDimension,
        tip: translatedHigherTip,
      }),
      lowestTip: formatTip({
        dimension: lowestDimension,
        tip: translatedLowerTip,
      }),
    };
  } catch (e) {
    return { highestTip: "ERROR", lowestTip: "ERROR" };
  }
};

/**
 * calculates the purpose percentage of the result
 * @param {Array} dimensions
 * @param {Object} result
 * @returns {Int}
 */
const getPurposePercentage = ({ dimensions, result }) => {
  const thisDimension = dimensions.find((dimension) => {
    return dimension.dimension_type === "purpose";
  });

  const thisResultValue = result.attributes.result_values.find(
    (result_value) => {
      return thisDimension.ids.includes(result_value.attributes.dimension_id);
    }
  );

  if (!thisResultValue) return NaN;

  return (thisResultValue.attributes.value * 100) / 4;
};

const makeResultsPageCopies = ({
  language,
  name,
  lowestDimension,
  highestDimension,
  lastResult,
  dimensions,
  survey,
  page,
}) => {
  const staticCopies = getStaticResultsPagesCopies({
    language,
    page,
    name,
    dimensions: {
      lowest: lowestDimension,
      highest: highestDimension,
    },
  });

  const focus = getFocus({ dimension: lowestDimension });
  const results = getResultsObject({ lastResult, dimensions });

  const colorsZone = getZone(survey.possible_results, lastResult, language);
  const copyZone = getZoneCopyFromLanguage({ zone: colorsZone, language });

  const middleFocusCopy = getLastResultFocusCopy({
    lastResult,
    dimensions,
    survey,
  });

  const finalFocusCopy =
    language === "en-GB"
      ? middleFocusCopy
      : CORE_SURVEY_POSSIBLE_RESULTS_CONTENT.find(
          (prc) => prc.id === middleFocusCopy.id
        );

  return {
    focus,
    results,
    colorsZone,
    copyZone,
    staticCopies,
    focusCopy: finalFocusCopy,
  };
};

const getLastResultFocusCopy = ({ lastResult, dimensions, survey }) => {
  const dimensionValuesObject = createDimensionValuesObject(
    lastResult.attributes.result_values,
    dimensions
  );

  const sortedDimensions = sortDimensions(dimensionValuesObject);

  const dimensionForCopy = getDimensionForCopy(
    sortedDimensions,
    lastResult.attributes.lowestDimension,
    dimensions
  );

  return getCopyContentFromPossibleResults(
    dimensionForCopy.dimension,
    dimensions,
    survey.possible_results.filter((possible_result) => {
      return (
        possible_result.attributes.min_value === 0 &&
        possible_result.attributes.max_value === 0
      );
    })
  );
};

export {
  getZone,
  createDimensionValuesObject,
  getAllResources,
  sortDimensions,
  getDimensionForCopy,
  getCopyContentFromPossibleResults,
  getGlobalFilteredAverage,
  getUsersOnSameRange,
  getFilteredResultsByPeriods,
  getFocus,
  formatTip,
  convertDimensionValuesToResultsObject,
  getTipsWithDimension,
  getPurposePercentage,
  getDimension,
  getTipImage,
  formatZone,
  getLastResultFocusCopy,
  makeResultsPageCopies,
};
