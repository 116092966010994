import React, { useCallback, useEffect, useReducer, useState } from "react";
import ControlledInput from "../../../components/inputs/ControlledInput";
import DropdownComponent from "../../../components/inputs/DropdownComponent";
import { useResize } from "../hooks/useResize";
import NativeDatepicker from "../../../components/inputs/NativeDatepicker";
import BarGraph from "../../../components/barGraph/BarGraph";
import Loading from "../../../components/loading/Loading";
import {
  dynamicFilterValuesUpdater,
  initialState,
  reducer,
} from "../../Results/services/renderFilters.service";
import "./AdminInterface.css";
import { connect } from "react-redux";
import { setFlashProps } from "../store/actions/flashActions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  convertHMS,
  countAllResults,
  getAgeUsersCount,
  getAnalyticsEvents,
  getAnalyticsUsersAndSessions,
  getAverageReturnRate,
  getAverageReturnRateByUser,
  getCountryUsersCount,
  getDatesForTimePeriods,
  getFullUsersTable,
  getGenderUsersCount,
  getGraphDate,
  getRegisteredUsersGraphData,
  getTotalFilteredUsers,
  getUsersCount,
  getUsersTableFromDB,
  getWelcomingZoneCountAndPercentages,
  getZonesCountAndPercentages,
  onPassSubmit,
  PAGES,
} from "../services/AdminInterface.service";
import { TRANSPARENT } from "../../../utils/constants/constants";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import GreyBackgroundRectangle from "../../../components/rectangles/GreyBackgroundRectangle";
import { useAdminInterfaceStyles } from "../hooks/useAdminInterfaceStyles";
import StandardScoreGraph from "../../../components/imageComponents/StandardScoreGraph";

/**
 * @param {Function} setFlashProps
 * @returns {Element}
 */
const AdminInterface = ({ setFlashProps }) => {
  const [pass, setPass] = useState(null);
  const [step, setStep] = useState(
    process.env.REACT_APP_IS_SERVER ? "one" : "two"
  );
  const [page, setPage] = useState(PAGES.USERS_DASHBOARD.id);
  const [totalUsers, setTotalUsers] = useState(null);
  const [wellbeingTotalUsers, setWellbeingTotalUsers] = useState(null);
  const [genderTotalUsers, setGenderTotalUsers] = useState(0);
  const [countryTotalUsers, setCountryTotalUsers] = useState(0);
  const [ageTotalUsers, setAgeTotalUsers] = useState(0);
  const [totalFilteredUsers, setTotalFilteredUsers] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allResults, setAllResults] = useState(null);
  const [zonesCountAndPercentages, setZonesCountAndPercentages] =
    useState(null);
  const [
    welcomingZoneCountAndPercentages,
    setWelcomingZoneCountAndPercentages,
  ] = useState(null);
  const [returnRate, setReturnRate] = useState(null);
  const [returnRateByUser, setReturnRateByUser] = useState(null);
  const [allGraphAverages, setAllGraphAverages] = useState(null);
  const [analyticsEvents, setAnalyticsEvents] = useState(null);
  const [analyticsUsersAndSessions, setAnalyticsUsersAndSessions] =
    useState(null);
  const [analyticsUsersByOperatingSystem, setAnalyticsUsersByOperatingSystem] =
    useState(null);
  const [renderUsersTableFromDB, setRenderUsersTableFromDB] = useState(false);
  const [fullUsersData, setFullUsersData] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("all");
  const [registeredUsersGraphData, setRegisteredUsersGraphData] =
    useState(null);
  const [filterState, dispatch] = useReducer(reducer, initialState);

  const [innerWidth] = useResize();

  const {
    getCustomDropdownStyles,
    getCustomMenuHeight,
    getNativePickerStyles,
    getCustomXStyles,
  } = useAdminInterfaceStyles(innerWidth);

  const onPassChange = useCallback((e) => setPass(e), []);

  //updates the filters content depending on the other filters
  useEffect(() => {
    dynamicFilterValuesUpdater(filterState, dispatch);
    //eslint-disable-next-line
  }, [
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
    filterState.dimension,
    page,
  ]);

  //gets and organizes data depending on wich page is selected
  useEffect(() => {
    switch (page) {
      case PAGES.USERS_DASHBOARD.id:
        getUsersCount(
          setTotalUsers,
          setWellbeingTotalUsers,
          startDate,
          endDate,
          filterState
        );
        getFullUsersTable(filterState, startDate, endDate, setFullUsersData);
        getRegisteredUsersGraphData(
          filterState,
          selectedTimePeriod,
          setRegisteredUsersGraphData
        );
        getAverageReturnRate("2020/01/01", "2100/01/01", setReturnRate);
        getAverageReturnRateByUser(
          "2020/01/01",
          "2100/01/01",
          setReturnRateByUser
        );
        return;
      case PAGES.RESULTS_DASHBOARD.id:
        countAllResults(startDate, endDate, setAllResults);
        getGraphDate(filterState, startDate, endDate, setAllGraphAverages);
        getZonesCountAndPercentages(
          filterState,
          startDate,
          endDate,
          setZonesCountAndPercentages
        );
        getWelcomingZoneCountAndPercentages(
          filterState,
          startDate,
          endDate,
          setWelcomingZoneCountAndPercentages
        );
        return;
      case PAGES.GOOGLE_ANALYTICS.id:
        getAnalyticsEvents(startDate, endDate, setAnalyticsEvents);
        getAnalyticsUsersAndSessions(
          startDate,
          endDate,
          setAnalyticsUsersAndSessions,
          setAnalyticsUsersByOperatingSystem
        );
        return;
      default:
        return null;
    }
    //eslint-disable-next-line
  }, [page]);

  //gets the count of users by gender
  useEffect(() => {
    getGenderUsersCount(filterState, setGenderTotalUsers);
    //eslint-disable-next-line
  }, [filterState.gender]);

  //gets the count of users by country
  useEffect(() => {
    getCountryUsersCount(filterState, setCountryTotalUsers);
    //eslint-disable-next-line
  }, [filterState.country]);

  //gets the count of users by age range
  useEffect(() => {
    getAgeUsersCount(filterState, setAgeTotalUsers);
    //eslint-disable-next-line
  }, [filterState.minAge, filterState.maxAge]);

  //counts the total filtered users considering the demographics filters
  useEffect(() => {
    getTotalFilteredUsers(filterState, setTotalFilteredUsers);
    //eslint-disable-next-line
  }, [
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
  ]);

  //gets specific data depending on the page
  useEffect(() => {
    switch (page) {
      case PAGES.USERS_DASHBOARD.id:
        getAverageReturnRate(
          startDate ?? "2020/01/01",
          endDate ?? "2100/01/01",
          setReturnRate
        );
        getAverageReturnRateByUser(
          startDate ?? "2020/01/01",
          endDate ?? "2100/01/01",
          setReturnRateByUser
        );
        break;
      case PAGES.RESULTS_DASHBOARD.id:
        countAllResults(startDate, endDate, setAllResults);
        break;
      case PAGES.GOOGLE_ANALYTICS.id:
        getAnalyticsEvents(startDate, endDate, setAnalyticsEvents);
        getAnalyticsUsersAndSessions(
          startDate,
          endDate,
          setAnalyticsUsersAndSessions,
          setAnalyticsUsersByOperatingSystem
        );
        break;
      default:
        break;
    }

    //eslint-disable-next-line
  }, [startDate, endDate]);

  //changes data that is presented to the user depending on the demographics and start or end data changing
  useEffect(() => {
    getGraphDate(filterState, startDate, endDate, setAllGraphAverages);
    getZonesCountAndPercentages(
      filterState,
      startDate,
      endDate,
      setZonesCountAndPercentages
    );
    getWelcomingZoneCountAndPercentages(
      filterState,
      startDate,
      endDate,
      setWelcomingZoneCountAndPercentages
    );

    if (page === PAGES.USERS_DASHBOARD.id) {
      getFullUsersTable(filterState, startDate, endDate, setFullUsersData);
      getUsersCount(
        setTotalUsers,
        setWellbeingTotalUsers,
        startDate,
        endDate,
        filterState
      );
    }
    //eslint-disable-next-line
  }, [
    startDate,
    endDate,
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
  ]);

  //renders and clicks the download button for the users table from database
  //the purpose of this is beeing able to download a users table directly
  //from the the database without ever seeing it
  useEffect(() => {
    if (renderUsersTableFromDB) {
      document.getElementById("full-users-table-xls-button").click();
      setRenderUsersTableFromDB(false);
    }
  }, [renderUsersTableFromDB]);

  //gets data for the registered users depending on demographics filters and time period selected
  useEffect(() => {
    getRegisteredUsersGraphData(
      filterState,
      selectedTimePeriod,
      setRegisteredUsersGraphData
    );

    //eslint-disable-next-line
  }, [
    selectedTimePeriod,
    filterState.gender,
    filterState.country,
    filterState.minAge,
    filterState.maxAge,
  ]);

  //changes the data on each page when the selected time period changes
  //it has its own use effect do act separate from start and end date variables
  useEffect(() => {
    let dates = getDatesForTimePeriods(selectedTimePeriod) || {
      beforeDate: startDate,
      nowDate: endDate,
    };

    if (page === PAGES.RESULTS_DASHBOARD.id) {
      countAllResults(dates.beforeDate, dates.nowDate, setAllResults);
      getGraphDate(
        filterState,
        dates.beforeDate,
        dates.nowDate,
        setAllGraphAverages
      );
      getZonesCountAndPercentages(
        filterState,
        dates.beforeDate,
        dates.nowDate,
        setZonesCountAndPercentages
      );
      getWelcomingZoneCountAndPercentages(
        filterState,
        dates.beforeDate,
        dates.nowDate,
        setWelcomingZoneCountAndPercentages
      );
    } else if (page === PAGES.USERS_DASHBOARD.id) {
      getAverageReturnRate(dates.beforeDate, dates.nowDate, setReturnRate);
      getAverageReturnRateByUser(
        dates.beforeDate,
        dates.nowDate,
        setReturnRateByUser
      );
    } else if (page === PAGES.GOOGLE_ANALYTICS.id) {
      getAnalyticsEvents(dates.beforeDate, dates.nowDate, setAnalyticsEvents);
      getAnalyticsUsersAndSessions(
        dates.beforeDate,
        dates.nowDate,
        setAnalyticsUsersAndSessions,
        setAnalyticsUsersByOperatingSystem
      );
    }

    //eslint-disable-next-line
  }, [selectedTimePeriod, page, filterState]);

  /**
   * renders the first step where a user has to input the authorized password
   * to enter the admin interface
   * @returns {Element}
   */
  const renderStepOne = () => {
    return (
      <div className="d-flex flex-column justify-content-center w-100 align-items-center pt-5">
        <label>Input access password</label>
        <ControlledInput onChange={onPassChange} value={pass} />
        <button
          style={{ cursor: "pointer" }}
          class="btn btn-secondary btn-md mt-2"
          onClick={() => onPassSubmit(pass, setStep)}
        >
          Submit
        </button>
      </div>
    );
  };

  const FilterDropdownComponent = (props) => {
    return (
      <DropdownComponent
        customStyles={getCustomDropdownStyles()}
        maxMenuHeight={getCustomMenuHeight()}
        isSearchable={innerWidth >= 992}
        isClearable
        {...props}
      />
    );
  };

  /**
   * renders all the filters that you can use on every page in the admin interface
   * @returns {Element}
   */
  const renderFilters = () => {
    return (
      <>
        <div className="d-flex justify-content-center table-titles mt-5 mb-3">
          Filters
        </div>

        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "60vw" }}
        >
          <div className="d-flex justify-content-center">
            <FilterDropdownComponent
              isMulti
              placeholder="Gender"
              data={filterState.genderRange}
              value={filterState.gender}
              onChange={(value) => dispatch({ type: "gender", payload: value })}
            />
          </div>

          <div className="d-flex justify-content-center">
            <FilterDropdownComponent
              isMulti
              data={filterState.countryRange}
              value={filterState.country}
              onChange={(value) =>
                dispatch({ type: "country", payload: value })
              }
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <FilterDropdownComponent
              data={filterState.minAgeRange}
              value={filterState.minAge}
              onChange={(value) => {
                dispatch({ type: "minAge", payload: value });
              }}
            />
            <FilterDropdownComponent
              data={filterState.maxAgeRange}
              value={filterState.maxAge}
              onChange={(value) => dispatch({ type: "maxAge", payload: value })}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * renders the page section about the user metrics
   * @returns {Element}
   */
  const renderUserMetricsSection = () => {
    return (
      <>
        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Users Metrics
        </h3>

        <div className="d-flex justify-content-between px-5">
          <div className="d-flex justify-content-around mb-5">
            <div className="d-flex flex-column">
              <h3 className="d-flex justify-content-center table-titles">
                Registered Users Count
              </h3>

              <StandardScoreGraph
                score={Math.floor((totalUsers * 100) / 225)}
                colors={{ main: "#444", secondary: "#666", third: "#aaa" }}
                copies={{ SCORE: totalUsers }}
              />
            </div>
          </div>

          {false && (
            <>
              <table id="userMetricsTable" class="table">
                <thead>
                  <tr>
                    {[
                      "#",
                      "Total Users",
                      "Gender",
                      "Country",
                      "Age",
                      "Filtered Total",
                    ].map((label) => {
                      return (
                        <th
                          className="text-center table-content-fonts"
                          scope="col"
                        >
                          {label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th scope="row" className="table-content-fonts">
                      Users Number
                    </th>
                    {[
                      totalUsers,
                      genderTotalUsers,
                      countryTotalUsers,
                      ageTotalUsers,
                      totalFilteredUsers,
                    ].map((label) => {
                      return (
                        <td className="text-center table-content-fonts">
                          {label}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>

              <div className="d-flex flex-column w-25">
                <div>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success mb-3"
                    table="userMetricsTable"
                    filename="userMetrics"
                    sheet="tablexls"
                    buttonText="Export Table to Excel Sheet"
                  />
                </div>

                <button onClick={() => setRenderUsersTableFromDB(true)}>
                  Download Users Table From DB
                </button>
                {renderUsersTableFromDB
                  ? getUsersTableFromDB(fullUsersData)
                  : null}
              </div>
            </>
          )}
          <div className="mt-5 pb-5 w-50">
            <BarGraph
              labels={registeredUsersGraphData.map((a) => a.dateLabel)}
              data={[
                registeredUsersGraphData.map((a) => a.registeredUsers),
                registeredUsersGraphData.map((a) => a.deletedUsers),
              ]}
              maxYValue={null}
              mainLabels={["Registered Users", "DeletedUsers"]}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * renders the page section about the total surveys and results metrics
   * @returns {Element}
   */
  const renderTotalSurveysAndResultsSection = () => {
    return (
      <>
        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Total Surveys and Results
        </h3>
        <table id="surveysAndResultsNumberTable" class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th className="text-center table-content-fonts" scope="col">
                Surveys Number
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="table-content-fonts">
                Total Core Surveys Completed/Results Number
              </th>
              <th scope="row" className="text-center table-content-fonts">
                {allResults}
              </th>
            </tr>
          </tbody>
        </table>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="surveysAndResultsNumberTable"
          filename="surveysAndResultsNumber"
          sheet="tablexls"
          buttonText="Export Table to Excel Sheet"
        />
      </>
    );
  };

  /**
   * renders the page section about the average scores metrics
   * @returns {Element}
   */
  const renderAverageScoresSection = () => {
    return (
      <>
        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Average Scores
        </h3>

        <table id="average_scores_table" class="table">
          <thead>
            <tr>
              <th scope="col" className="table-content-fonts">
                #
              </th>
              <th className="text-center table-content-fonts" scope="col">
                Average Score
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row" className="table-content-fonts">
                Global Average
              </th>
              <td className="text-center table-content-fonts">
                {allGraphAverages.globalAverage[0]["AVG(total)"]}
              </td>
            </tr>

            <tr>
              <th scope="row" className="table-content-fonts">
                Physical Average
              </th>
              <td className="text-center table-content-fonts">
                {allGraphAverages.physicalAverage[0]["AVG(total)"]}
              </td>
            </tr>

            <tr>
              <th scope="row" className="table-content-fonts">
                Mental Average
              </th>
              <td className="text-center table-content-fonts">
                {allGraphAverages.mentalAverage[0]["AVG(total)"]}
              </td>
            </tr>

            <tr>
              <th scope="row" className="table-content-fonts">
                Social Average
              </th>
              <td className="text-center table-content-fonts">
                {allGraphAverages.socialAverage[0]["AVG(total)"]}
              </td>
            </tr>

            <tr>
              <th scope="row" className="table-content-fonts">
                Work Average
              </th>
              <td className="text-center table-content-fonts">
                {allGraphAverages.workAverage[0]["AVG(total)"]}
              </td>
            </tr>
          </tbody>
        </table>

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="average_scores_table"
          filename="averageScores"
          sheet="tablexls"
          buttonText="Export Table to Excel Sheet"
        />

        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Zones Count and Averages
        </h3>

        <table id="zonesCountAndAveragesTable" class="table">
          <thead>
            <tr>
              <th className="text-center table-content-fonts">
                Green Zone Results
              </th>

              <th className="text-center table-content-fonts">
                Green Zone Percentage
              </th>

              <th className="text-center table-content-fonts">
                Yellow Zone Results
              </th>

              <th className="text-center table-content-fonts">
                Yellow Zone Percentage
              </th>

              <th className="text-center table-content-fonts">
                Red Zone Results
              </th>

              <th className="text-center table-content-fonts">
                Red Zone Percentage
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              {[
                zonesCountAndPercentages.greenCount,
                zonesCountAndPercentages.greenPercentage + "%",
                zonesCountAndPercentages.yellowCount,
                zonesCountAndPercentages.yellowPercentage + "%",
                zonesCountAndPercentages.redCount,
                zonesCountAndPercentages.redPercentage + "%",
              ].map((label) => {
                return (
                  <td className="text-center table-content-fonts">{label}</td>
                );
              })}
            </tr>
          </tbody>
        </table>

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="zonesCountAndAveragesTable"
          filename="zonesCountAndAveragesTable"
          sheet="tablexls"
          buttonText="Export Table to Excel Sheet"
        />

        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Feel Count and Averages
        </h3>

        <table class="table" id="welcomeFeelsCountAndPercentages">
          <thead>
            <th className="table-content-fonts">#</th>
            <th className="text-center table-content-fonts">Count</th>
            <th className="text-center table-content-fonts">Percentage</th>
          </thead>
          <tbody>
            <tr>
              <td className="table-content-fonts">Happy Count</td>
              <td className="text-center table-content-fonts">
                {welcomingZoneCountAndPercentages.happyCount}
              </td>
              <td className="text-center table-content-fonts">-</td>
            </tr>
            <tr>
              <td className="table-content-fonts">Happy Percentage</td>
              <td className="text-center table-content-fonts">-</td>
              <td className="text-center table-content-fonts">
                {parseFloat(
                  welcomingZoneCountAndPercentages.happyPercentage
                ).toFixed(2)}
                %
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Calm Count</td>
              <td className="text-center table-content-fonts">
                {welcomingZoneCountAndPercentages.calmCount}
              </td>
              <td className="text-center table-content-fonts">-</td>
            </tr>
            <tr>
              <td className="table-content-fonts">Calm Percentage</td>
              <td className="text-center table-content-fonts">-</td>
              <td className="text-center table-content-fonts">
                {parseFloat(
                  welcomingZoneCountAndPercentages.calmPercentage
                ).toFixed(2)}
                %
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Ok Count</td>
              <td className="text-center table-content-fonts">
                {welcomingZoneCountAndPercentages.okCount}
              </td>
              <td className="text-center table-content-fonts">-</td>
            </tr>
            <tr>
              <td className="table-content-fonts">Ok Percentage</td>
              <td className="text-center table-content-fonts">-</td>
              <td className="text-center table-content-fonts">
                {parseFloat(
                  welcomingZoneCountAndPercentages.okPercentage
                ).toFixed(2)}
                %
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Stressed Count</td>
              <td className="text-center table-content-fonts">
                {welcomingZoneCountAndPercentages.stressedCount}
              </td>
              <td className="text-center table-content-fonts">-</td>
            </tr>
            <tr>
              <td className="table-content-fonts">Stressed Percentage</td>
              <td className="text-center table-content-fonts">-</td>
              <td className="text-center table-content-fonts">
                {parseFloat(
                  welcomingZoneCountAndPercentages.stressedPercentage
                ).toFixed(2)}
                %
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Sad Count</td>
              <td className="text-center table-content-fonts">
                {welcomingZoneCountAndPercentages.sadCount}
              </td>
              <td className="text-center table-content-fonts">-</td>
            </tr>
            <tr>
              <td className="table-content-fonts">Sad Percentage</td>
              <td className="text-center table-content-fonts">-</td>
              <td className="text-center table-content-fonts">
                {parseFloat(
                  welcomingZoneCountAndPercentages.sadPercentage
                ).toFixed(2)}
                %
              </td>
            </tr>
          </tbody>
        </table>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="welcomeFeelsCountAndPercentages"
          filename="welcomeFeelsCountAndPercentages"
          sheet="tablexls"
          buttonText="Export Table to Excel Sheet"
        />
        <div className="mt-5 pb-5">
          <BarGraph
            labels={["Global", "Physical", "Mental", "Social", "Work"]}
            data={[
              [
                allGraphAverages.globalAverage[0]["AVG(total)"],
                allGraphAverages.physicalAverage[0]["AVG(total)"],
                allGraphAverages.mentalAverage[0]["AVG(total)"],
                allGraphAverages.socialAverage[0]["AVG(total)"],
                allGraphAverages.workAverage[0]["AVG(total)"],
              ],
            ]}
            maxYValue={100}
            mainLabels={["Average Scores"]}
          />
        </div>
      </>
    );
  };

  /**
   * renders the page section about the return rate metrics
   * @returns {Element}
   */
  const renderReturnRateSection = () => {
    return (
      <>
        <h3 className="mt-5 d-flex justify-content-center mb-5 table-titles">
          Return rate
        </h3>
        <table id="returnRateTable" class="table">
          <thead>
            <td className="table-content-fonts">Return Rate</td>
            <td className="text-center table-content-fonts">Users Number</td>
          </thead>
          <tbody>
            {returnRateByUser.map((a) => {
              return (
                <tr>
                  <td className="table-content-fonts">{a.resultsNumber}</td>
                  <td className="text-center table-content-fonts">
                    {a.howManyUsers}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="table-content-fonts">{returnRate}</td>
              <td className="text-center table-content-fonts">All (average)</td>
            </tr>
          </tbody>
        </table>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="returnRateTable"
          filename="returnRate"
          sheet="tablexls"
          buttonText="Export Table to Excel Sheet"
        />
      </>
    );
  };

  /**
   * renders all the tables for the google analytics section
   * @returns {Element}
   */
  const renderAnalyticsTables = () => {
    let tables = [
      "login",
      "signup",
      "share",
      "invite",
      "profile",
      "view",
      "survey",
      "reminders",
      "others",
    ];

    let jsxTables = [];

    for (let i = 0; i < tables.length; i++) {
      if (analyticsEvents[tables[i]].length > 0) {
        jsxTables.push(
          <div key={tables[i]}>
            <h5 className="mt-5 w-100 d-flex justify-content-center table-titles">
              {tables[i].charAt(0).toUpperCase() + tables[i].slice(1)} Events
            </h5>
            <table class="table mt-5">
              <thead></thead>
              <tbody>
                {analyticsEvents[tables[i]].map((a) => {
                  return (
                    <tr>
                      <td className="table-content-fonts">{a.category}</td>
                      <td className="text-center table-content-fonts">
                        {a.count}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <thead></thead>
            </table>
          </div>
        );
      }
    }

    if (jsxTables.length > 0) {
      return jsxTables;
    } else {
      return (
        <div className="d-flex justify-content-center mt-5">
          No events in this date range. Select another time frame.
        </div>
      );
    }
  };

  /**
   * renders the table for sessions and user visits metrics on the google analytics section
   * @returns {Element}
   */
  const renderUserAndSessionTable = () => {
    return (
      <div>
        <h5 className="mt-5 w-100 d-flex justify-content-center table-titles">
          Users And Sessions
        </h5>

        <table class="table mt-5">
          <thead></thead>
          <tbody>
            <tr>
              <td className="table-content-fonts">Users</td>
              <td className="table-content-fonts">
                {analyticsUsersAndSessions["ga:users"]}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">New Users</td>
              <td className="table-content-fonts">
                {analyticsUsersAndSessions["ga:newUsers"]}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Sessions</td>
              <td className="table-content-fonts">
                {analyticsUsersAndSessions["ga:sessions"]}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Attrition Rate</td>
              <td className="table-content-fonts">
                {parseFloat(analyticsUsersAndSessions["ga:bounceRate"]).toFixed(
                  2
                )}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Average Session Duration</td>
              <td className="table-content-fonts">
                {convertHMS(analyticsUsersAndSessions["ga:avgSessionDuration"])}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Page views</td>
              <td className="table-content-fonts">
                {analyticsUsersAndSessions["ga:pageviews"]}
              </td>
            </tr>
            <tr>
              <td className="table-content-fonts">Page views per session</td>
              <td className="table-content-fonts">
                {parseFloat(
                  analyticsUsersAndSessions["ga:pageviewsPerSession"]
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
          <thead></thead>
        </table>
      </div>
    );
  };

  const renderUsersByOperatingSystemTable = () => {
    return (
      <div>
        <h5 className="mt-5 w-100 d-flex justify-content-center table-titles">
          Users By Operating System
        </h5>

        <table class="table mt-5">
          <tbody>
            {Object.entries(analyticsUsersByOperatingSystem)
              .sort((os1, os2) => {
                if (os2[1] > os1[1]) return 1;
                else if (os2[1] < os1[1]) return -1;
                else return 0;
              })
              .map(([os, value]) => {
                return (
                  <tr>
                    <td className="table-content-fonts">{os}</td>
                    <td className="table-content-fonts">{value}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  /**
   * renders the entire  analytics page section
   * @returns {Element}
   */
  const renderAnalyticsSection = () => {
    return (
      <>
        <h3 className="d-flex justify-content-center mt-5 analytics-title">
          Analytics Events
        </h3>
        {renderUserAndSessionTable()}
        {renderUsersByOperatingSystemTable()}
        {renderAnalyticsTables()}
      </>
    );
  };

  /**
   * renders a single time selection button for filtering the tables
   * @param {String} value
   * @param {String} label
   * @param {Boolean} show
   * @returns {Element}
   */
  const renderTimeButton = ({ value, label, show }) => {
    return (
      <div
        key={value}
        style={{
          backgroundColor:
            selectedTimePeriod !== value ? TRANSPARENT : "#604586",
          color: selectedTimePeriod === value ? "white" : "#604586",
        }}
        onClick={() => setSelectedTimePeriod(value)}
        className="period-buttons"
      >
        {label}
      </div>
    );
  };

  const getFilterDatepicker = (otherDate, setDate, condition = () => true) => {
    return (
      <NativeDatepicker
        style={getNativePickerStyles()}
        onChange={(value) => {
          let valueToSave;

          if (
            otherDate &&
            value &&
            !condition(new Date(value.target.value), new Date(otherDate))
          ) {
            setFlashProps(FLASH_TYPES.START_DATE_AFTER_END_DATE);
            return;
          } else if (!value) {
            valueToSave = null;
          } else {
            valueToSave = value.target.value;
          }

          setDate(valueToSave);
        }}
        value={startDate}
        isClerable
        xColor="rgb(210, 210, 210)"
        customXStyles={getCustomXStyles()}
      />
    );
  };

  /**
   * renders all the time selection buttons for filtering the tables
   * @returns {Element}
   */
  const renderAllTimeButtons = () => {
    let newPeriods = [
      { value: "all", label: "All" },
      { value: "week", label: "Last Week" },
      { value: "month", label: "Last Month" },
      { value: "year", label: "Last Year" },
      { value: "custom", label: "Custom" },
    ];

    return (
      <>
        <div className="d-flex justify-content-center table-titles mb-3">
          Time Buttons
        </div>

        <div className="d-flex flex-column align-items-center">
          <div
            className="d-flex justify-content-between align-items-center"
            id="period-buttons-container"
          >
            {newPeriods
              .filter(
                (period) =>
                  period.value !== "custom" || selectedTimePeriod !== "custom"
              )
              .map((period) => {
                return renderTimeButton({
                  label: period.label,
                  value: period.value,
                });
              })}
            <div>
              {selectedTimePeriod === "custom" ? (
                <>
                  {getFilterDatepicker(
                    endDate,
                    setStartDate,
                    (date1, date2) => date1 < date2
                  )}
                  {getFilterDatepicker(
                    startDate,
                    setEndDate,
                    (date1, date2) => date1 > date2
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  //renders the second step after inputing the acess password
  //the second step contains all the tables and all the filters
  const renderStepTwo = () => {
    return (
      <>
        <HeaderFirstVersion
          adminInterface
          pages={PAGES}
          onPageChange={setPage}
          currentPage={page}
        />
        <GreyBackgroundRectangle customStyles={{ height: "50vh" }}>
          {renderAllTimeButtons()}
          {renderFilters()}
        </GreyBackgroundRectangle>

        {step2RenderManager()}
      </>
    );
  };

  //this manager handles wich section to show to the user
  const renderManager = () => {
    switch (step) {
      case "one":
        return renderStepOne();
      case "two":
        return renderStepTwo();
      default:
        return null;
    }
  };

  //this manager handles wich page to show on the step 2 section, depending
  //on wich page is selected shows different tables and filters
  const step2RenderManager = () => {
    switch (page) {
      case PAGES.USERS_DASHBOARD.id:
        return (
          <>
            {renderUserMetricsSection()}
            {renderReturnRateSection()}
          </>
        );
      case PAGES.RESULTS_DASHBOARD.id:
        return (
          <>
            {renderAverageScoresSection()}
            {renderTotalSurveysAndResultsSection()}
          </>
        );
      case PAGES.GOOGLE_ANALYTICS.id:
        return renderAnalyticsSection();
      default:
        return null;
    }
  };

  if (
    (((!totalUsers && totalUsers !== 0) ||
      (!wellbeingTotalUsers && wellbeingTotalUsers !== 0) ||
      !registeredUsersGraphData ||
      (!returnRate && returnRate !== 0) ||
      !returnRateByUser) &&
      page === PAGES.USERS_DASHBOARD.id) ||
    ((!allResults ||
      !allGraphAverages ||
      !zonesCountAndPercentages ||
      !welcomingZoneCountAndPercentages) &&
      page === PAGES.RESULTS_DASHBOARD.id) ||
    ((!analyticsEvents ||
      !analyticsUsersAndSessions ||
      !analyticsUsersByOperatingSystem) &&
      page === PAGES.GOOGLE_ANALYTICS.id)
  ) {
    return <Loading />;
  }

  return <div id="admin-interface-container">{renderManager()}</div>;
};

export default connect(null, { setFlashProps })(AdminInterface);
