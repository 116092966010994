import React from "react";
import { PRELIMINARY_RESULTS_PAGE_ID } from "../../../../utils/constants/constants";
import { scrollToElement } from "../../../../utils/helpers/geralHelpers/RandomHelper";
import ResultsButton from "./ResultsButton";
import LikeSVG from "../../../../components/imageComponents/LikeSVG";

/**
 * Functional Component: DimensionWellbeing
 * Renders a seciton at the side of RadarGraphInfo
 * @param {String} type
 * @param {String} dimension
 * @param {String} page
 * @param {String} buttonText
 * @param {String} language
 * @returns {Element}
 */
const DimensionWellbeing = ({
  type,
  dimension,
  page,
  description,
  buttonText,
}) => {
  return (
    <div className="text-lg-start text-center">
      <div className="mx-auto mx-lg-0 dimension-wellbeing__image">
        <LikeSVG type={type} />
      </div>

      <h3 className="fw-bold dimension-wellbeing__title ff-popp-med">
        {dimension}
      </h3>

      <div className="w-75 mx-auto mx-lg-0 dimension-wellbeing__text">
        {description}
      </div>

      {page !== PRELIMINARY_RESULTS_PAGE_ID ? (
        <div>
          <ResultsButton onClick={() => scrollToElement("#tips-info")}>
            {buttonText}
          </ResultsButton>
        </div>
      ) : null}
    </div>
  );
};

export default DimensionWellbeing;
