import React from "react";
import "./FocusAndEvolution.css";
import ResultsButton from "../newComponents/ResultsButton";
import { SHARE_RESULTS_PAGE_ID } from "../../../../utils/constants/constants";
import { scrollToElement } from "../../../../utils/helpers/geralHelpers/RandomHelper";
import { redirectToAnalyticsPage } from "../../../../core/Routing/RoutesRedirects";
import { createAnalytic } from "../../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: FocusAndEvolution
 * This component shows the dimension of focus and the evolution part of the results
 *
 * @param {String} focusCopy
 * @param {Img} focusImage
 * @param {Object} lastResultBeforeToday
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {Object} dimensions
 * @param {String} language
 * @param {String} shareResultGender
 * @returns {Element}
 * */
const FocusAndEvolution = ({
  focusCopy = "ERROR",
  focusImage = "ERROR",
  page = "ERROR",
  copies,
  variableCopies,
  dimensions,
  shareResultGender,
  language,
}) => {
  if (page === SHARE_RESULTS_PAGE_ID && shareResultGender) {
    if (language === "pt-PT") {
      if (shareResultGender === "M") {
        copies.FOCUS.title = copies.FOCUS.title.replace("O/A", "O");
      } else if (shareResultGender === "F") {
        copies.FOCUS.title = copies.FOCUS.title.replace("O/A", "A");
      }
    } else {
      if (shareResultGender === "M") {
        dimensions.LOWEST = dimensions.LOWEST.replace("his/her", "his");
      } else if (shareResultGender === "F") {
        dimensions.LOWEST = dimensions.LOWEST.replace("his/her", "her");
      }
    }
  }

  return (
    <div className="focus-and-evolution">
      <div className="focus-and-evolution__focus-row d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
        <div
          id="focus-and-evolution__focus-image"
          className="col-12 px-5 col-lg-4 mb-4"
        >
          <img src={focusImage} alt={dimensions.LOWEST} />
        </div>

        <div className="col-12 col-lg-6 text-center text-lg-start mt-4">
          <h3 className="fw-bold mx-auto mx-lg-0 col-sm-12 col-md-6 col-lg-10 col-xl-8 ff-popp-semi-bold focus-and-evolution__focus-title">
            {copies.FOCUS.title} {dimensions.LOWEST}
          </h3>

          <div className="mx-auto mx-lg-0 col-sm-10 focus-and-evolution__focus-body">
            {focusCopy}
          </div>

          <div className="col">
            <ResultsButton onClick={() => scrollToElement("#tips-info")}>
              {copies.FOCUS.button}
            </ResultsButton>
          </div>
        </div>
      </div>

      {variableCopies.PROGRESS.progress_type === "no_evo" &&
      page === SHARE_RESULTS_PAGE_ID ? null : (
        <div className="justify-content-lg-start align-items-lg-center mb-5 d-flex flex-column-reverse flex-lg-row focus-and-evolution__progress-row">
          <div className="col-12 col-lg-7 text-center text-lg-start mt-4 pr-lg-5">
            <h3 className="fw-bold mx-auto mx-lg-0 col-md-8 col-lg-10 col-xl-8 ff-popp-semi-bold focus-and-evolution__progress-title">
              {variableCopies.PROGRESS.title}
            </h3>

            <div className="mx-auto mx-lg-0 col-sm-10 focus-and-evolution__progress-body">
              {variableCopies.PROGRESS.body}
            </div>

            {page !== SHARE_RESULTS_PAGE_ID ? (
              <div className="col">
                <ResultsButton
                  onClick={() => {
                    redirectToAnalyticsPage();
                    createAnalytic(EVENTS.VIEW_ANALYTICS_RESULTS);
                  }}
                >
                  {copies.PROGRESS.button}
                </ResultsButton>
              </div>
            ) : null}
          </div>

          <div
            id="focus-and-evolution__progress-image"
            className="col-12 px-5 col-lg-4 text-start"
          >
            <img src={variableCopies.PROGRESS.image} alt="progress" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FocusAndEvolution;
