import reason_compare from "../../../assets/svg/reason_compare.svg";
import reason_insights from "../../../assets/svg/reason_insights.svg";
import reason_progress from "../../../assets/svg/reason_progress.svg";
import reason_share from "../../../assets/svg/reason_share.svg";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import LOADING_TYPES from "../../../components/loading/LOADING_TYPES";
import { isPureObject } from "../../helpers/geralHelpers/RandomHelper";

/**
 * returns static copies depending on copy versions
 * @param {Object} copy_versions
 * @param {String} language
 * @param {String} name
 * @returns {Object}
 */
const getStaticCopies = ({ copy_versions, language, name }) => {
  return Object.entries(copy_versions).reduce((prev, [key, value]) => {
    return {
      ...prev,
      [key]: getCopies(STATIC_COPIES[key], { language, version: value }, name),
    };
  }, {});
};

/**
 * returns copies depending on version and language
 * @param {Object} copies
 * @param {String} language
 * @param {String} name
 * @param {String} version
 * @returns {Object}
 */
const getCopies = (copies, { language, version }, name) => {
  return Object.entries(copies).reduce((prev, [copyVersion, copy]) => {
    let chosenVersion = "";

    if (isPureObject(version)) {
      return Object.keys(copies).reduce((prev, copyKey) => {
        return {
          ...prev,
          [copyKey]: getCopies(
            copies[copyKey],
            { language, version: version[copyKey] },
            name
          ),
        };
      }, {});
    }

    let chosenVersionId = version ? language + "-" + version : language;

    if (Array.isArray(copy)) {
      chosenVersion = copy.map((subCopy) =>
        getSingleCopyVersion({ subCopy, chosenVersionId, name })
      );
    } else if (Object.values(copy).find(isPureObject)) {
      chosenVersion = getCopies(copy, { language, version }, name);
    } else {
      chosenVersion = getSingleCopyVersion({
        subCopy: copy,
        chosenVersionId,
        name,
      });
    }

    return { ...prev, [copyVersion]: chosenVersion };
  }, {});
};

/**
 * returns the version of a copy
 * @param {Array} subCopy
 * @param {Number or String} chosenVersionId
 * @param {String} name
 * @returns {String}
 */
const getSingleCopyVersion = ({ subCopy, chosenVersionId, name }) => {
  let chosenVersion = subCopy[chosenVersionId] || "";

  if (chosenVersion.indexOf("[name]") > -1 && name)
    chosenVersion = chosenVersion.replaceAll("[name]", name);

  return chosenVersion;
};

//contains all static copies
const STATIC_COPIES = {
  WELCOME_BACK_ZONE: {
    TITLE: {
      "en-GB": "Nice to see you again, [name]",
      "pt-PT": "Que bom ver-te por cá, [name]​",
    },
    BODY: {
      "en-GB":
        "Come back tomorrow to complete your survey. Meanwhile, why don't you explore your results below?",
      "pt-PT":
        "Volta amanhã para responderes novamente ao questionário. Até lá, aproveita para explorares os teus resultados abaixo​",
    },
  },
  ZONE_AND_SCORE: {
    GRAPH: {
      GLOBAL_SCORE: {
        "en-GB-self": "Your global score is",
        "en-GB-other": "The global score is",

        "pt-PT-self": "A tua pontuação é",
        "pt-PT-other": "A sua pontuação é",
      },
      OUT_OF_TEXT: {
        "en-GB-self": "out of 100",
        "en-GB-other": "out of 100",
        "pt-PT-self": "em 100",
        "pt-PT-other": "em 100",
      },
    },
    ZONE: {
      BUTTON_TEXT: {
        "en-GB": "View detailed results",
        "pt-PT": "Ver resultados detalhados​",
      },
    },
    LAST_RESULT: {
      TITLE: {
        // HELP
        "en-GB": "You should focus on", // your
        "pt-PT": "Deves focar-te", // no teu bem-estar
      },
      SUBTITLE: {
        "en-GB": "Results from",
        "pt-PT": "Resultados de",
      },
    },
    PRELIMINARY: {
      TITLE: {
        "en-GB": "Today you are in the",
        "pt-PT": "Hoje estás na",
      },
      BUTTON_TEXT_PART_1: {
        "en-GB": "Want to know more?",
        "pt-PT": "Queres saber mais?​",
      },
      BUTTON_TEXT_PART_2: {
        "en-GB": "Join violet now!",
        "pt-PT": "Cria agora a tua conta!​",
      },
      KNOW_MORE_TEXT: {
        "en-GB": "Want to know more?",
        "pt-PT": "Queres saber mais?​",
      },
    },
    PRELIMINARY_MODAL: {
      TITLE: {
        "en-GB": "Join violet now!",
        "pt-PT": "Cria agora a tua conta!​",
      },
      SUBTITLE: {
        "en-GB":
          "Unlock your full results and start taking care of yourself on a regular basis.",
        "pt-PT":
          "Desbloqueia os teus resultados e cria a tua rotina de autocuidado.",
      },
      BODY_PART_1: {
        "en-GB":
          "Get more insights on how you're doing today, find out how to improve and compare yourself with others.",
        "pt-PT":
          "Acede e explora os teus resultados completos, descobre como podes melhorar e compara-te com outros.​",
      },
      BODY_PART_2: {
        "en-GB":
          "Start monitoring your well-being regularly and keep track of your progress over time.",
        "pt-PT":
          "Começa a monitorizar o teu bem-estar regularmente e acompanha o teu progresso ao longo do tempo.​",
      },
      BUTTON: { "en-GB": "Sign up now", "pt-PT": "Regista-te agora​" },
      DOWN_TEXT: {
        "en-GB": "It only takes a minute!",
        "pt-PT": "Só demora 1 minuto!​",
      },
      BACK_TEXT: { "en-GB": "Back", "pt-PT": "Voltar" },
    },
  },
  PRELIMINARY_BENEFITS: [
    {
      "en-GB": "Easy & quick - only takes 1 minute",
      "pt-PT": "Fácil e rápido – só demora 1 minuto​",
    },
    {
      "en-GB": "Free & unlimited access",
      "pt-PT": "Acesso gratuito e ilimitado​",
    },
    {
      "en-GB": "100% data confidentiality",
      "pt-PT": "100% de confidencialidade​",
    },
    {
      "en-GB": "+ [users_number] users joined already",
      "pt-PT": "+ [users_number] utilizadores já registados​",
    },
  ],
  FORMATTED_DIMENSION: {
    ZONE_LAST_RESULT: {
      "en-GB-self-standard": "your [dimension] well-being",
      "en-GB-self-alternative": "your well-being at [dimension]",

      "pt-PT-self-standard": "no teu bem-estar [dimension]",
      "pt-PT-self-alternative": "o teu bem-estar no [dimension]",
    },
    FOCUS: {
      "en-GB-self-standard": "your [dimension] well-being",
      "en-GB-self-alternative": "your well-being at [dimension]",

      "en-GB-other-standard": "his/her [dimension] well-being",
      "en-GB-other-alternative": "his/her well-being at [dimension]",

      "pt-PT-self-standard": "o teu bem-estar [dimension]",
      "pt-PT-self-alternative": "o teu bem-estar no [dimension]",

      "pt-PT-other-standard": "o seu bem-estar [dimension]",
      "pt-PT-other-alternative": "o seu bem-estar no [dimension]",
    },
    RADAR_GRAPH_ZONE: {
      "en-GB-self-standard": "[dimension] well-being",
      "en-GB-other-standard": "[dimension] well-being",
      "en-GB-self-alternative": "well-being at [dimension]",
      "en-GB-other-alternative": "well-being at [dimension]",

      "en-GB-self-incomplete": "which well-being dimension...",
      // MEGA-HELP

      "pt-PT-self-standard": "Bem-estar [dimension]",
      "pt-PT-other-standard": "Bem-estar [dimension]",
      "pt-PT-self-alternative": "Bem-estar no [dimension]",
      "pt-PT-other-alternative": "Bem-estar no [dimension]",

      "pt-PT-self-incomplete": "qual a dimensão do bem-estar...",
    },
    TIPS_ZONE: {
      "en-GB-self-standard": "[dimension] well-being",
      "en-GB-other-standard": "[dimension] well-being",
      "en-GB-self-alternative": "well-being at [dimension]",
      "en-GB-other-alternative": "well-being at [dimension]",

      "pt-PT-self-standard": "Bem-estar [dimension]",
      "pt-PT-other-standard": "Bem-estar [dimension]",
      "pt-PT-self-alternative": "Bem-estar no [dimension]",
      "pt-PT-other-alternative": "Bem-estar no [dimension]",
    },
  },
  FOCUS_AND_PROGRESS: {
    FOCUS: {
      title: {
        // HELP
        "en-GB-self": "You should focus on", // your
        "en-GB-other": "[name] should focus on", // his/her
        "pt-PT-self": "Deves focar-te em trabalhar",
        "pt-PT-other": "O/A [name] deve focar-se em trabalhar", // o seu bem-estar
      },
      button: {
        "en-GB-self": "Explore tips",
        "en-GB-other": "Explore tips",
        "pt-PT-self": "Explorar dicas",
        "pt-PT-other": "Explorar dicas",
      },
    },
    PROGRESS: {
      button: {
        "en-GB": "Check previous results",
        "pt-PT": "Ver resultados anteriores​",
      },
    },
  },
  RADAR_GRAPH_ZONE: {
    MAIN: {
      TITLE: {
        "en-GB": "Detailed results",
        "pt-PT": "Resultados detalhados​",
      },
      BUTTON: { "en-GB": "Explore tips", "pt-PT": "Explorar as dicas​" },
      DIMENSIONS: {
        MENTAL: { "en-GB": "Mental", "pt-PT": "Mental" },
        PHYSICAL: { "en-GB": "Physical", "pt-PT": "Física" },
        SOCIAL: { "en-GB": "Social", "pt-PT": "Social" },
        WORK: { "en-GB": "Work", "pt-PT": "Trabalho" },
      },
      TOOLTIPS: {
        MENTAL: {
          "en-GB":
            "Captures the main predictors of your emotional and mental health, namely how you deal with your feelings and emotional states, as well as your psychological and cognitive needs.",
          "pt-PT":
            "Mede o impacto que a tua saúde mental e os fatores de natureza emocional e psicológica - como lidas com os teus sentimentos e estados emocionais e como suprimes as tuas necessidades cognitivas - têm no teu bem-estar geral.​",
        },
        PHYSICAL: {
          "en-GB":
            "Captures your overall physical health and lifestyle habits (inner layer), and also the conditions of the surrounding environment and their impact on your comfort and safety (outer layer).",
          "pt-PT":
            "Mede o impacto dos teus hábitos de saúde física e estilo de vida, e também das condições do ambiente que te rodeia (como o conforto e a segurança), no teu bem-estar geral.​",
        },
        SOCIAL: {
          "en-GB":
            "Relates to the core intrinsic needs of belonging and social connection, and your ability to satisfy them by creating and maintain healthy relationships with other people.",
          "pt-PT":
            "Ligada às necessidades intrínsecas de pertença e ligação social, mede o impacto que a tua capacidade para as satisfazer, criando e mantendo relações saudáveis com outras pessoas, tem no teu bem-estar.​",
        },
        WORK: {
          "en-GB":
            "Relates to the key aspects that affect the quality of your life at work, from career and recognition, to work-life balance, and also their impact on your financial security.",
          "pt-PT":
            "Mede o impacto dos principais fatores que afetam a tua qualidade de vida no trabalho - aspetos ligados à carreira, reconhecimento, equilíbrio com a vida pessoal, mas também segurança financeira - no teu bem-estar geral.​",
        },
      },
    },
    HIDE_RADAR_ZONE: {
      title: {
        "en-GB": "Join violet today to unlock your full results",
        "pt-PT":
          "Junta-te à violet para acederes aos teus resultados completos​",
      },
      "body-part-1": {
        "en-GB":
          "Different aspects of your life can be impacting your well-being, both positively and negatively.",
        "pt-PT":
          "Diferentes fatores da tua vida podem estar a impactar o teu bem-estar, tanto positiva como negativamente.",
      },
      "body-part-2": {
        "en-GB":
          "Join violet now to get more insights and find out what you can do to take better care of yourself.",
        "pt-PT":
          "Começa a tua viagem na violet hoje e descobre o que podes fazer para cuidar melhor de ti e do teu bem-estar.",
      },
      "signup-button-1": {
        "en-GB": "Sign up now",
        "pt-PT": "Regista-te agora​",
      },
      subtitle: {
        "en-GB": "Why should you join violet?",
        "pt-PT": "Porque me devo registar?​",
      },
      reasons_image: [
        { "en-GB": reason_insights, "pt-PT": reason_insights },
        { "en-GB": reason_progress, "pt-PT": reason_progress },
        { "en-GB": reason_compare, "pt-PT": reason_compare },
        { "en-GB": reason_share, "pt-PT": reason_share },
      ],
      reasons_body: [
        {
          "en-GB":
            "Take the survey and get actionable insights on how you’re doing",
          "pt-PT":
            "Responde ao questionário, reflete sobre ti e ganha consciência de como te sentes",
        },
        {
          "en-GB":
            "Make it a routine: come back and keep track of your progress over time",
          "pt-PT":
            "Cria uma rotina: volta para responder novamente e acompanha a evolução do teu bem-estar",
        },
        {
          "en-GB":
            "Gain a (new) perspective by comparing your results with others’",
          "pt-PT":
            "Ganha uma nova perspetiva ao comparares os teus resultados com os de outros utilizadores",
        },
        {
          "en-GB":
            "Share your insights with other people and keep them in the loop",
          "pt-PT":
            "Partilha as tuas descobertas e envolve outros à tua volta na tua jornada de bem-estar",
        },
      ],
      "know-more-button": {
        "en-GB": "Know more",
        "pt-PT": "Sabe mais​",
      },
      "signup-button-2": {
        "en-GB": "Sign up now",
        "pt-PT": "Regista-te agora",
      },
      "bottom-info-part-1": {
        "en-GB":
          "We care about your privacy and the confidentially of your information.",
        "pt-PT": "A tua privacidade é muito importante para nós.",
      },
      "bottom-info-part-2": {
        "en-GB":
          "Everything you share with violet will stay with violet, at all times.",
        "pt-PT":
          "Garantimos a confidencialidade da tua informação e de tudo o que partilhares com a violet, em todos os momentos.​",
      },
    },
  },
  PURPOSE: {
    TITLE: {
      "en-GB-self": "Your purpose level",
      "en-GB-other": "[name]'s purpose level",
      "pt-PT-self": "O teu nível de propósito​",
      "pt-PT-other": "O nível de propósito do/a [name]​",
    },
  },
  TIPS_ZONE: {
    title: {
      "en-GB-self": "Explore our tips and work on your...",
      "en-GB-other": "Explore our tips for [name]...",
      "pt-PT-self": "Explora as nossas dicas para te focares no teu...​",
      "pt-PT-other": "Explora as nossas dicas para o/a [name]...​",
    },
  },
  COMPARE_WITH_OTHERS: {
    GIVE_INFORMATION: {
      "gender-input-label": {
        "en-GB": "Gender",
        "pt-PT": "Género",
      },
      "birthdate-input-label": {
        "en-GB": "Date of birth",
        "pt-PT": "Data de nascimento",
      },
      "country-input-label": {
        "en-GB": "Current Location",
        "pt-PT": "Localização atual",
      },
      title: {
        "en-GB": "Fill in your details and compare with others",
        "pt-PT": "Compara os teus resultados",
      },
      subtitle: {
        "en-GB":
          "Fill in your details below and find out how your results compare with other users like you",
        "pt-PT":
          "Preenche a tua informação abaixo para descobrires como os teus resultados se comparam com os de outros utilizadores",
      },
      "update-demographics-button": {
        "en-GB": "Update my profile details for quicker access next time.",
        "pt-PT":
          "Atualizar o meu perfil e guardar esta informação para o futuro.​",
      },
      "submit-button": {
        "en-GB": "Submit",
        "pt-PT": "Guardar",
      },
    },
    FILTERED_INFORMATION: {
      title: {
        "en-GB-self": "Compare with others",
        "en-GB-other": "Compare with others",
        "pt-PT-self": "Compara-te com outros​",
        "pt-PT-other": "Comparar com outros",
      },
      "subtitle-part-1": {
        "en-GB-self": "See how your score compares with others users’",
        "en-GB-other": "See how his/her score compares with others users’",
        "pt-PT-self":
          "Vê como a tua pontuação se compara com a de outros utilizadores",
        "pt-PT-other":
          "Vê como a sua pontuação se compara com a de outros utilizadores",
      },
      "subtitle-part-2": {
        "en-GB-self": "most recent score",
        "en-GB-other": "most recent score",
      },
      "check-overtime-button": {
        "en-GB-self": "Check my results over time",
        "pt-PT-self": "Ver a minha evolução",
      },
      tooltip: {
        "en-GB-self":
          "You will be able to see more details in your My analytics tab once you complete the survey for the second time.",
        "pt-PT-self":
          "Vais poder ver mais detalhes na secção Os meus resultados quando responderes ao questionário uma segunda vez",
      },
    },
  },
  COMPARE_WITH_OTHERS_GIVE_INFORMATION_FILTERS_ERRORS: {
    birthdate: {
      message: {
        "en-GB-blank": "You must select a date of birth",
        "en-GB-min": "Invalid date of birth",
        "en-GB-max": "That date is yet to come",

        "pt-PT-blank": "Seleciona uma data de nascimento",
        "pt-PT-min": "Selecionaste uma data inválida",
        "pt-PT-max": "Selecionaste uma data futura",
      },
    },
    gender: {
      message: {
        "en-GB-blank": "You must select a gender",
        "pt-PT-blank": "Seleciona um género",
      },
    },
    country: {
      message: {
        "en-GB-blank": "You must select a country/region",
        "pt-PT-blank": "Seleciona uma localização",
      },
    },
  },
  SHARE_INFO: {
    USER_VERSION: {
      "results-title": {
        "en-GB": "Share your results",
        "pt-PT": "Partilha os teus resultados​",
      },
      "app-title": {
        "en-GB": "Invite others to violet",
        "pt-PT": "Convida outros para a violet​",
      },
    },
    GUEST_VERSION: {
      TITLE: {
        "en-GB": "Start improving your life with violet",
        "pt-PT": "Melhora a tua vida com a violet",
      },
      ALREADY_A_MEMBER: {
        "en-GB": "Already a member?",
        "pt-PT": "Já tens conta?",
      },
      JOIN_NOW_BUTTON: {
        "en-GB": "Join now",
        "pt-PT": "Registar",
      },
      LOGIN_BUTTON: {
        "en-GB": "Login",
        "pt-PT": "Entrar​",
      },
      START_SURVEY_BUTTON: {
        "en-GB": "Start survey",
        "pt-PT": "Começar​",
      },
    },
  },
  MEET_VIOLET: {
    "big-title": {
      "en-GB": "Meet violet",
      "pt-PT": "Sobre a violet",
    },
    "what-is-violet-title": {
      "en-GB": "It's all about you",
      "pt-PT": "Como te sentes hoje?",
    },
    "what-is-violet-body-part-1": {
      "en-GB":
        "We’re not always okay, and sometimes we’re not even aware of that, either because we don’t take the time to take care of ourselves, or just because no one asks us the right questions. Violet was born with a clear purpose: be there for you, so you can be there for yourself, on a regular basis.",
      "pt-PT":
        "Nem sempre estamos bem, e por vezes nem sequer estamos conscientes disso, ou porque não dedicamos tempo suficiente a nós próprios, ou simplesmente porque ninguém nos faz as perguntas certas. A violet nasceu com um propósito claro: ajudar-nos a cuidar de nós, não apenas hoje, mas como rotina.",
    },
    "what-is-violet-body-part-2": {
      "en-GB":
        "As a survey-based screening tool, violet will guide you in a journey of reflection, allowing you to self-assess, learn about and track your well-being over time. This will help you be more in control of where you are and how it impacts your daily life, empowering you to take action, so you can be at your best.",
      "pt-PT":
        "Enquanto ferramenta individual de auto-reflexão e introspeção, a violet ajuda-te a pensares sobre ti de uma forma mais consciente, dando-te pistas sobre o teu bem-estar e permitindo-te acompanhá-lo ao longo do tempo. Desta forma, estarás mais em controlo de ti mesmo, mais consciente de onde estás, e mais preparado para fazeres o teu caminho, estando no teu melhor.",
    },
    "wellbeing-framework-title": {
      "en-GB": "Measuring well-being - what are we talking about exactly?",
      "pt-PT": "Medir bem-estar - do que se trata, afinal?",
    },
    "wellbeing-framework-body-part-1": {
      "en-GB":
        "There is no single definition for well-being. It's actually a very broad concept, but violet introduces a new, unique approach to it: our framework provides a holistic view of an individual’s well-being, considering 4 attributes (and widely validated predictors) – physical, mental, social, and work – connected through a fifth dimension: purpose. All five dimensions are covered by violet’s surveys, with short, direct, and non-intrusive questions.",
      "pt-PT":
        "Não existe uma definição única para bem-estar. É, na verdade, um conceito muito vasto e abrangente. Para o trabalhar, a violet introduz um modelo próprio, que olha para o bem-estar de uma forma simples, mas holística, considerando quatro dimensões (amplamente validadas) - física, mental, social e trabalho - ligadas através de uma quinta dimensão: propósito. Todas as cinco dimensões estão presentes no questionário, e são alvo de auto-reflexão com perguntas curtas, diretas e não intrusivas.",
    },
    "wellbeing-framework-subtitle": {
      "en-GB": "Why purpose?",
      "pt-PT": "Porquê propósito?",
    },
    "wellbeing-framework-body-part-2": {
      "en-GB":
        "It’s human nature to search for purpose in life, from body and mind, to relationships and work life. Therefore, we believe that purpose and well-being are deeply connected, and one cannot be achieved without the other, as there is a delicate balance between finding or pursuing our individual purpose and experiencing well-being.",
      "pt-PT":
        "É da natureza humana procurar um propósito na vida, desde o corpo e mente, até às relações e à vida profissional. Como tal, acreditamos que propósito e bem-estar estão profundamente ligados, um não pode ser alcançado sem o outro, existindo um equilíbrio delicado entre procurar o propósito individual e experienciar bem-estar.",
    },
    "wellbeing-framework-body-conclusion-part-1": {
      "en-GB": "Plus, violet is a solution designed by",
      "pt-PT": "Além disso, a violet é uma solução concebida pela",
    },
    "wellbeing-framework-body-conclusion-part-2": {
      "en-GB": ", and we, at ",
      "pt-PT": "e nós, na ",
    },
    "wellbeing-framework-body-conclusion-part-3": {
      "en-GB": ", are strong ",
      "pt-PT": ",  somos verdadeiros ",
    },
    "wellbeing-framework-body-conclusion-part-4": {
      "en-GB": "purpose believers.",
      "pt-PT": "purpose believers.",
    },
    "self-care-priority-title": {
      "en-GB": "Start making self-care a priority with violet:",
      "pt-PT": "A violet ajuda-te a cuidares melhor de ti:",
    },
    "self-cafe-priority-first-subtitle": {
      "en-GB": "Monitor your well-being",
      "pt-PT": "Avalia o teu bem-estar de forma consciente",
    },
    "self-cafe-priority-first-text": {
      "en-GB":
        "Take the survey periodically, at your own pace, and have real-time access to your results. Dig deeper into them and take control, with actionable insights & tips (designed by health & coaching professionals) to improve, maintain and leverage your well-being.",
      "pt-PT":
        "Responde ao questionário para refletires sobre ti e o teu bem-estar. Explora os teus resultados em tempo real e capacita-te, com dicas acionáveis (criadas por profissionais de saúde e coaching) para melhorares, manteres ou alavancares as várias dimensões.",
    },
    "self-cafe-priority-second-subtitle": {
      "en-GB": "Check your progress",
      "pt-PT": "Analisa o teu progresso ao longo do tempo",
    },
    "self-cafe-priority-second-text": {
      "en-GB":
        "Make it a routine and keep track of your evolution, by checking your last results and progress over time in your individual dashboard. Uncover changing patterns in your well-being and evaluate the impacts of your efforts.",
      "pt-PT":
        "Cria rotinas de auto-reflexão e acompanha a tua evolução, acedendo aos últimos resultados e ao teu progresso ao longo do tempo. Descobre padrões de mudança no teu bem-estar e avalia os impactos dos teus esforços.",
    },
    "self-cafe-priority-third-subtitle": {
      "en-GB": "Spread the word",
      "pt-PT": "Ajuda os outros a cuidar de si também",
    },
    "self-cafe-priority-third-text": {
      "en-GB":
        "Share your insights with others, to keep them in the loop. Spread the word and help them take care of themselves too, by inviting them to join and start their own individual journey on violet - and share their insights with you.",
      "pt-PT":
        "Partilha as tuas descobertas com outros e mantém-nos a par das mudanças. Passa a palavra e ajuda-os a cuidarem de si próprios também, convidando-os a iniciar a sua jornada individual na violet – e a partilhar as suas descobertas contigo.",
    },
    "self-care-invite-text": {
      "en-GB": "Invite others to join violet",
      "pt-PT": "Partilha a violet",
    },
    "take-survey-button": {
      "en-GB": "Take the survey",
      "pt-PT": "Responder ao questionário",
    },
    "already-answered": {
      "en-GB": "You already completed the survey today",
      "pt-PT": "Já respondeste ao questionário hoje",
    },
    "review-results-button": {
      "en-GB": "Review your results",
      "pt-PT": "Ver os meus resultados",
    },
  },
  LANDING_PAGE: {
    title: {
      "en-GB": "Make your well-being a priority today!",
      "pt-PT": "Faz do bem-estar uma prioridade na tua vida!",
    },
    textFirstPart: {
      first: {
        "en-GB": "",
        "pt-PT": "Como te sentes hoje? Tens dedicado tempo a cuidar de ti?",
      },
      second: {
        "en-GB":
          "Violet is a well-being platform that helps you connect with your inner self. As part of your routine, violet will guide you in a self-care journey, give you actionable tips and help you monitor your well-being over time.",
        "pt-PT":
          "A violet é uma plataforma de bem-estar que te ajuda a cuidares melhor de ti e a monitorizares o teu bem-estar de uma forma mais consciente, dando-te dicas acionáveis sobre como o podes melhorar ao longo do tempo.",
      },
    },
    textFirstPartMobile: {
      "en-GB":
        "Violet is a well-being platform that helps you connect with your inner self. As part of your routine, violet will guide you in a self-care journey by helping you monitor your well-being over time.",
      "pt-PT":
        "Como te sentes hoje? Tens dedicado tempo a cuidar de ti? A violet é uma plataforma que te ajuda a monitorizar o teu bem-estar de uma forma consciente e a manter rotinas mais saudáveis de autocuidado",
    },
    textSecondPart: {
      "en-GB":
        "We believe self-care is individual power. It’s time to nurture yours and be at your best.",
      "pt-PT":
        "Cuidarmos do nosso bem-estar é sinónimo de poder. Está na altura de o fazeres, para estares no teu melhor!",
    },
    buttons: {
      startSurvey: {
        "en-GB": "Start survey",
        "pt-PT": "Começar",
      },
      meetViolet: {
        "en-GB": "Meet violet",
        "pt-PT": "Sobre a violet",
      },
    },
  },
  FOOTER: {
    rightsReserved: {
      "en-GB": "© [year], violet. All rights reserved.",
      "pt-PT": "© [year], violet. Todos os direitos reservados.",
    },
    termsOfService: {
      "en-GB": "Terms of Service",
      "pt-PT": "Termos e Condições",
    },
    privacyPolicy: {
      "en-GB": "Privacy Policy",
      "pt-PT": "Política de Privacidade",
    },
  },
  SHARE_SOCIAL_MEDIA_OR_EMAIL: {
    email: {
      "share-app": {
        subject: {
          "en-GB": "How are you today?",
          "pt-PT": "Como te sentes hoje?",
        },
        body: {
          "en-GB":
            "Start your journey on violet. %0A%0A Violet is a well-being platform that will empower you to take care of yourself and be at your best, by helping you assess, learn about, and monitor your well-being over time.%0A Find out more or join violet now: [url].%0A Make your well-being a priority today!",
          "pt-PT":
            "Começa agora a tua jornada na violet! %0A%0A A violet é uma plataforma que te ajuda a monitorizar o teu bem-estar de forma consciente, guiando-te numa jornada de auto-reflexão e fomentando rotinas mais saudáveis de autocuidado.%0A Descobre mais ou junta-te à violet agora: [url].%0A Faz do teu bem-estar uma prioridade!",
        },
      },
      "share-result": {
        subject: {
          "en-GB": "My well-being results - powered by violet",
          "pt-PT": "Os meus resultados - powered by violet",
        },
        body: {
          "en-GB": `[name] wants to share with you the results of his/her last well-being check-in on violet.%0A Click on the link to see it: [url] %0A%0A Different aspects of your life can be impacting your well-being, both positively and negatively. Violet can help you take care of yourself and be at your best.%0A Take some time to start your own journey now: ${process.env.REACT_APP_WELLBEING_FRONT_END.replace(
            "http://",
            ""
          )}. Make your well-being a priority too!`,
          "pt-PT": `O/A [name] quer partilhar contigo os resultados da violet sobre o seu último check-in de bem-estar.%0A Clica no link para aceder: [url] %0A%0A Diferentes aspetos da tua vida podem estar a impactar o teu bem-estar, tanto positiva como negativamente. A violet pode ajudar-te a cuidares melhor de ti e a estares no teu melhor.%0A Começa a tua própria jornada na violet agora: ${process.env.REACT_APP_WELLBEING_FRONT_END.replace(
            "http://",
            ""
          )}. Faz do teu bem-estar uma prioridade também!`,
        },
      },
    },
    whatsapp: {
      "share-app": {
        title: {
          "en-GB": "Make your well-being a priority with violet",
          "pt-PT": "Faz do teu bem-estar uma prioridade com a violet",
        },
      },
      "share-result": {
        title: {
          "en-GB": "My well-being results - powered by violet",
          "pt-PT": "Os meus resultados - powered by violet",
        },
      },
    },
    messenger: {
      "share-app": {
        title: {
          "en-GB": "Make your well-being a priority with violet",
          "pt-PT": "Faz do teu bem-estar uma prioridade com a violet",
        },
      },
      "share-result": {
        title: {
          "en-GB": "My well-being results - powered by violet",
          "pt-PT": "Os meus resultados - powered by violet",
        },
      },
    },
    facebook: {
      "share-app": {
        title: {
          "en-GB": "Make your well-being a priority with violet",
          "pt-PT": "Faz do teu bem-estar uma prioridade com a violet",
        },
      },
      "share-result": {
        title: {
          "en-GB": "My well-being results - powered by violet",
          "pt-PT": "Os meus resultados - powered by violet",
        },
      },
    },
    linkedin: {
      "share-app": {
        title: {
          "en-GB": "Make your well-being a priority with violet",
          "pt-PT": "Faz do teu bem-estar uma prioridade com a violet",
        },
      },
      "share-result": {
        title: {
          "en-GB": "My well-being results - powered by violet",
          "pt-PT": "Os meus resultados - powered by violet",
        },
      },
    },
  },
  ACTIONS_MENU: {
    MEET_VIOLET_BUTTON: {
      "en-GB": "Meet violet",
      "pt-PT": "Sobre a violet",
    },
    LOGIN_BUTTON: {
      "en-GB": "Login",
      "pt-PT": "Entrar",
    },
    SIGNUP_BUTTON: {
      "en-GB": "Sign up",
      "pt-PT": "Registar",
    },
    INVITE_OTHERS: {
      "en-GB": "Invite others",
      "pt-PT": "Partilhar",
    },
    MY_ANALYTICS_BUTTON: {
      "en-GB": "My analytics",
      "pt-PT": "Os meus resultados",
    },
    ABOUT_ME_BUTTON: {
      "en-GB": "My profile",
      "pt-PT": "O meu perfil",
    },
  },
  COOKIES_POLICY: {
    BODY: {
      "en-GB":
        "Help us take care of your privacy! We use cookies to improve your experience. Are you ok with that?",
      "pt-PT":
        "Ajuda-nos a cuidar da tua privacidade! Utilizamos cookies para melhorar a tua experiência. Estás de acordo com isso?​",
    },
    ACCEPT_COOKIES_BUTTON: {
      "en-GB": "Accept cookies",
      "pt-PT": "Aceitar cookies",
    },
    MANAGE_COOKIES_BUTTON: {
      "en-GB": "Manage cookies",
      "pt-PT": "Gerir cookies",
    },
    KNOW_MORE_BUTTON: {
      "en-GB": "Know more",
      "pt-PT": "Saber mais​",
    },
  },
  COOKIES_PREFERENCES: {
    TITLE: {
      "en-GB-enabled": "Help us take care of your privacy:",
      "pt-PT-enabled": "Ajuda-nos a cuidar da tua privacidade:​",

      "en-GB-blocked": "We need your help first...",
      "pt-PT-blocked": "Primeiro, precisamos da tua ajuda...​",
    },
    BODY: {
      "en-GB-enabled":
        "Apart from the functional cookies, which are necessary, we use other cookies, only to help us enhance your experience on violet. We also use external cookies to collect information that will help us make your journey (even more) memorable. To know more visit our",
      "pt-PT-enabled":
        "Além das cookies funcionais, que são necessárias, guardamos outras cookies apenas com o propósito de nos ajudar a melhorar a tua experiência na violet. Também usamos cookies externas para guardar informação que nos ajudará a tornar a tua viagem (ainda mais) memorável. Para saberes mais, visita a nossa página de​",

      "en-GB-blocked":
        "Your browser has all cookies disabled. Please make sure you change your settings to enable them and then try again.",
      "pt-PT-blocked":
        "O navegador que estás a usar tem todas as cookies desativadas. Certifica-te que alteras diretamente as tuas definições no navegador e tenta novamente.",
    },
    FUNCTIONAL_SWITCH: {
      "en-GB-enabled": "Functional",
      "pt-PT-enabled": "Funcionais",
    },
    USER_EXPERIENCE_SWITCH: {
      "en-GB-enabled": "User Experience",
      "pt-PT-enabled": "Experiência",
    },
    ANALYTICS_SWITCH: {
      "en-GB-enabled": "Analytics",
      "pt-PT-enabled": "Analíticos",
    },
    TERMS_OF_SERVICE: {
      "en-GB-enabled": "Terms of Service",
      "pt-PT-enabled": "Termos e Condições",
    },
    SAVE_SETTINGS_BUTTON: {
      "en-GB-enabled": "Save settings",
      "pt-PT-enabled": "Guardar alterações",
    },
    KNOW_MORE_BUTTON: {
      "en-GB-blocked": "Know more",
      "pt-PT-blocked": "Saber mais​",
    },
    TRY_AGAIN_BUTTON: {
      "en-GB-blocked": "Try again",
      "pt-PT-blocked": "Tentar novamente",
    },
  },
  FLASH_PROPS: {
    COPY: {
      [`en-GB-${FLASH_TYPES.BIG_PICK_PROFILE_PHOTO.type}`]:
        "Image is too big! Pick another image...",
      [`pt-PT-${FLASH_TYPES.BIG_PICK_PROFILE_PHOTO.type}`]:
        "Imagem muito grande!​ Escolhe outra imagem...",

      [`en-GB-${FLASH_TYPES.MESSENGER_APP.type}`]:
        "No messenger app detected. Please install it for sharing.",
      [`pt-PT-${FLASH_TYPES.MESSENGER_APP.type}`]:
        "Não tens a aplicação instalada. Por favor instala-a para partilhares.​",

      [`en-GB-${FLASH_TYPES.EMAIL_NOT_VERIFIED.type}`]:
        "That email is not verified!",
      [`pt-PT-${FLASH_TYPES.EMAIL_NOT_VERIFIED.type}`]:
        "E-mail não verificado!",

      [`en-GB-${FLASH_TYPES.START_DATE_AFTER_END_DATE.type}`]:
        "Start date can't be after the end date.",
      [`pt-PT-${FLASH_TYPES.START_DATE_AFTER_END_DATE.type}`]:
        "Data de início não pode anteceder a de fim.​",

      [`en-GB-${FLASH_TYPES.END_DATE_BEFORE_START_DATE.type}`]:
        "End date can't be before the start date.",
      [`pt-PT-${FLASH_TYPES.END_DATE_BEFORE_START_DATE.type}`]:
        "Data de fim não pode preceder a de início.​",

      [`en-GB-${FLASH_TYPES.SURVEY_SUBMIT_ERROR.type}`]:
        "Sorry, something went wrong. Please try again in a moment or reload the page",
      [`pt-PT-${FLASH_TYPES.SURVEY_SUBMIT_ERROR.type}`]:
        "Desculpa, algo correu mal. Tenta novamente mais tarde ou recarrega a página​",

      [`en-GB-${FLASH_TYPES.DELETE_PROFILE_PHOTO.type}`]: "Image deleted",
      [`pt-PT-${FLASH_TYPES.DELETE_PROFILE_PHOTO.type}`]: "Imagem apagada",

      [`en-GB-${FLASH_TYPES.BIG_TAKE_PROFILE_PHOTO.type}`]:
        "Image too big! Please reduce circle size or pick another image...",
      [`pt-PT-${FLASH_TYPES.BIG_TAKE_PROFILE_PHOTO.type}`]:
        "Imagem muito grande! Reduz a área de captura ou escolhe outra imagem...​",

      [`en-GB-${FLASH_TYPES.CHANGE_PASSWORD.type}`]:
        "Password successfully changed!",
      [`pt-PT-${FLASH_TYPES.CHANGE_PASSWORD.type}`]:
        "Password alterada com sucesso!",

      [`en-GB-${FLASH_TYPES.CHANGE_EMAIL.type}`]:
        "Email changed. Please login again",
      [`pt-PT-${FLASH_TYPES.CHANGE_EMAIL.type}`]:
        "E-mail alterado. Usa-o para entrar novamente​",

      [`en-GB-${FLASH_TYPES.UNEXPECTED_ERROR.type}`]:
        "An unexpected error occurred. Please report!",
      [`pt-PT-${FLASH_TYPES.UNEXPECTED_ERROR.type}`]:
        "Ocorreu um erro inesperado. Comunica-o, por favor!​",

      [`en-GB-${FLASH_TYPES.DELETE_ACCOUNT.type}`]: "Account deleted",
      [`pt-PT-${FLASH_TYPES.DELETE_ACCOUNT.type}`]: "Conta apagada​",

      [`en-GB-${FLASH_TYPES.DATE_BIGGER_THAN_MAX.type}`]:
        "That date is yet to come",
      [`pt-PT-${FLASH_TYPES.DATE_BIGGER_THAN_MAX.type}`]:
        "Selecionaste uma data futura",

      [`en-GB-${FLASH_TYPES.DATE_SMALLER_THAN_MIN.type}`]:
        "Invalid date of birth",
      [`pt-PT-${FLASH_TYPES.DATE_SMALLER_THAN_MIN.type}`]:
        "Selecionaste uma data inválida",

      [`en-GB-${FLASH_TYPES.ABOUT_ME_EMAIL_ALREADY_EXISTS.type}`]:
        "Email already exists",
      [`pt-PT-${FLASH_TYPES.ABOUT_ME_EMAIL_ALREADY_EXISTS.type}`]:
        "Este e-mail já existe​",

      [`en-GB-${FLASH_TYPES.ABOUT_ME_INVALID_FIRST_NAME.type}`]:
        "First name field can't be empty",
      [`pt-PT-${FLASH_TYPES.ABOUT_ME_INVALID_FIRST_NAME.type}`]:
        "O campo primeiro nome é obrigatório​",

      [`en-GB-${FLASH_TYPES.ABOUT_ME_INVALID_LAST_NAME.type}`]:
        "Last name field can't be empty",
      [`pt-PT-${FLASH_TYPES.ABOUT_ME_INVALID_LAST_NAME.type}`]:
        "O campo último nome é obrigatório​",

      [`en-GB-${FLASH_TYPES.ABOUT_ME_BIRTHDATE_BIGGER_THAN_MAX.type}`]:
        "That date is yet to come",
      [`pt-PT-${FLASH_TYPES.ABOUT_ME_BIRTHDATE_BIGGER_THAN_MAX.type}`]:
        "Selecionaste uma data futura",

      [`en-GB-${FLASH_TYPES.ABOUT_ME_BIRTHDATE_SMALLER_THAN_MIN.type}`]:
        "Invalid date of birth",
      [`pt-PT-${FLASH_TYPES.ABOUT_ME_BIRTHDATE_SMALLER_THAN_MIN.type}`]:
        "Selecionaste uma data inválida​",
    },
  },
  PROGRESS_BAR: {
    TOOLTIP: {
      "en-GB":
        "Choose one or more options and click here to jump to the next question",
      "pt-PT":
        "Escolhe uma ou mais opções e clica na seta para avançares para a próxima questão​",
    },
  },
  WEB_CAM: {
    CAPTURE_BUTTON: {
      "en-GB": "Capture",
      "pt-PT": "Capturar​",
    },
  },
  LOADING: {
    COPY: {
      [`en-GB-${LOADING_TYPES.WARMING_UP}`]: "Warming up...",
      [`pt-PT-${LOADING_TYPES.WARMING_UP}`]: "São só uns instantes...​",

      [`en-GB-${LOADING_TYPES.WELCOME}`]: "Welcome to violet!",
      [`pt-PT-${LOADING_TYPES.WELCOME}`]: "Bem-vindo à violet!​",

      [`en-GB-${LOADING_TYPES.FILTER_COMPARATION}`]: "Filtering data...",
      [`pt-PT-${LOADING_TYPES.FILTER_COMPARATION}`]: "Calculando...",

      [`en-GB-${LOADING_TYPES.BUILDING_SURVEY}`]:
        "Building your survey for today...",
      [`pt-PT-${LOADING_TYPES.BUILDING_SURVEY}`]:
        "Criando o teu questionário...",

      [`en-GB-${LOADING_TYPES.PREPARING_RESULTS}`]:
        "Stay still. Your results are almost ready...",
      [`pt-PT-${LOADING_TYPES.PREPARING_RESULTS}`]:
        "Não saias daí. Os teus resultados estão quase prontos...​",
    },
  },
  ERROR_PAGE: {
    TITLE: {
      "en-GB-internal": "It's not you, it's us!",
      "pt-PT-internal": "Não és tu, somos nós!​",

      "en-GB-not-found": "Ups, page not found",
      "pt-PT-not-found": "Ups, página não encontrada",

      "en-GB-maintenance": "We will be back soon",
      "pt-PT-maintenance": "Voltamos já​",
    },
    DESCRIPTION: {
      "en-GB-internal":
        "Some internal error occurred, please try again in a moment.",
      "pt-PT-internal":
        "Detetamos a ocorrência de um erro interno, pedimos desculpa. Por favor tenta novamente mais tarde.​",

      "en-GB-not-found":
        "The page you are looking for might have been removed, had its name changed or is temporarily unavailable.",
      "pt-PT-not-found":
        "A página que procuras pode ter sido removida, alterada, ou encontra-se temporariamente indisponível.​",

      "en-GB-maintenance":
        "Service is currently down, please try again in a moment.",
      "pt-PT-maintenance":
        "O nosso serviço encontra-se de momento em baixo, pedimos desculpa. Por favor tenta novamente mais tarde.​",
    },
    BUTTON_TEXT: {
      "en-GB-internal": "Go to Homepage",
      "pt-PT-internal": "Ir para a Homepage",

      "en-GB-not-found": "Go Back",
      "pt-PT-not-found": "Voltar atrás",
    },
  },
  ANALYTICS_FILTERS: {
    GENDER: {
      FILTER: { "en-GB": "Gender", "pt-PT": "Género" },
      PROMPT: {
        INPUT: { "en-GB": "Your gender", "pt-PT": "O teu género​" },
        SAVE_BUTTON: { "en-GB": "Save", "pt-PT": "Guardar​" },
      },
    },
    AGE: {
      MIN_FILTER: { "en-GB": "Age (min)", "pt-PT": "Idade (min)" },
      MAX_FILTER: { "en-GB": "Age (max)", "pt-PT": "Idade (max)" },
      PROMPT: {
        INPUT: {
          "en-GB": "Your date of birth",
          "pt-PT": "A tua data de nascimento​",
        },
        SAVE_BUTTON: { "en-GB": "Save", "pt-PT": "Guardar​" },
      },
    },
    COUNTRY: {
      FILTER: { "en-GB": "Current Location", "pt-PT": "Localização atual" },
      PROMPT: {
        INPUT: { "en-GB": "Your location", "pt-PT": "A tua localização​" },
        SAVE_BUTTON: { "en-GB": "Save", "pt-PT": "Guardar​" },
      },
    },
    DIMENSION: {
      FILTER: { "en-GB": "Dimension", "pt-PT": "Dimensão​" },
    },
  },
  MY_ANALYTICS_NO_DATA: {
    TITLE: {
      "en-GB-results": "[name], you don't have any available data yet...",
      "en-GB-progress": "[name], you don't have enough available data yet...",
      "en-GB-alternative": "[name], you don't have any data to show.",

      "pt-PT-results": "[name], ainda não temos informação para te mostrar...",
      "pt-PT-progress":
        "[name], ainda não temos informação suficiente para te mostrar...",
      "pt-PT-alternative": "[name], não temos informação para te mostrar...",
    },
    BODY: {
      "en-GB-results":
        "Your results will be displayed here as soon as you complete your first survey.",
      "en-GB-progress":
        "Your results will be displayed here as soon as you complete your second survey.",
      "en-GB-alternative":
        "Your results will be displayed here as soon as you complete the survey again.",

      "pt-PT-results":
        "Revisita aqui os teus resultados depois de responderes ao questionário pela primeira vez.",
      "pt-PT-progress":
        "Revisita aqui o teu histórico de resultados depois de responderes ao questionário mais uma vez.",
      "pt-PT-alternative":
        "Revisita aqui os teus resultados depois de responderes novamente ao questionário.",
    },
    BUTTON: {
      "en-GB-results": "Start survey",
      "en-GB-progress": "Start survey",
      "en-GB-alternative": "Start survey",

      "pt-PT-results": "Começar",
      "pt-PT-progress": "Começar",
      "pt-PT-alternative": "Começar",
    },
  },
  ABOUT_ME: {
    photoModal: {
      title: {
        "en-GB": "Profile Photo",
        "pt-PT": "Foto de perfil",
      },
      first_button: {
        "en-GB": "Choose Photo",
        "pt-PT": "Escolher fotografia",
      },
      second_button: {
        "en-GB": "Take Photo",
        "pt-PT": "Tirar fotografia",
      },
      third_button: {
        "en-GB": "Delete Photo",
        "pt-PT": "Apagar fotografia",
      },
      cancel: {
        "en-GB": "Cancel",
        "pt-PT": "Cancelar",
      },
      save: {
        "en-GB": "Save",
        "pt-PT": "Guardar",
      },
    },
    logoutDeleteModal: {
      title: {
        "en-GB": "Are you sure?",
        "pt-PT": "Tens a certeza?",
      },
      logoutDescription: {
        "en-GB":
          "You won’t have access to your information until you log back in.",
        "pt-PT":
          "Não terás acesso às informações da tua conta até voltares a entrar.",
      },
      deleteDescription: {
        "en-GB":
          "All your data will be deleted and you will not be able to restore it.",
        "pt-PT":
          "Toda a tua informação será apagada e não conseguirás restaurar a tua conta.",
      },
    },
    errors: {
      blank: {
        "en-GB": "Fields cant be blank.",
        "pt-PT": "Campos obrigatórios.",
      },
      match: {
        "en-GB": "New password and password confirmation must match.",
        "pt-PT": "As passwords introduzidas não são iguais.",
      },
      size: {
        "en-GB": "Your password should have at least 8 characters",
        "pt-PT": "A password tem de ter no mínimo 8 caracteres",
      },
      geral: {
        "en-GB": "There was some problem setting the new password",
        "pt-PT": "Ocorreu um problema na definição na nova password",
      },
    },
    labels: {
      newPassword: {
        "en-GB": "New password (8+ characters)",
        "pt-PT": "Nova password (8+ caracteres)",
      },
      confirmPassword: {
        "en-GB": "Confirm password (8+ characters)",
        "pt-PT": "Confirmar password (8+ caracteres)",
      },
      email: {
        "en-GB": "Email",
        "pt-PT": "E-mail",
      },
      edit: {
        "en-GB": "Edit",
        "pt-PT": "Editar",
      },
      firstName: {
        "en-GB": "First name",
        "pt-PT": "Primeiro nome",
      },
      lastName: {
        "en-GB": "Last name",
        "pt-PT": "Último nome",
      },
      gender: {
        "en-GB": "Gender",
        "pt-PT": "Género",
      },
      birthdate: {
        "en-GB": "Date of birth",
        "pt-PT": "Data de nascimento",
      },
      currentLocation: {
        "en-GB": "Current location",
        "pt-PT": "Localização atual",
      },
      reminders: {
        "en-GB": "Reminders",
        "pt-PT": "Receber alertas",
      },
    },
    otherButtons: {
      saveDetails: {
        "en-GB": "Save details",
        "pt-PT": "Guardar",
      },
    },
    userSince: {
      "en-GB": "User since",
      "pt-PT": "Utilizador desde",
    },
    logout: {
      "en-GB": "Logout",
      "pt-PT": "Sair",
    },
    deleteAccount: {
      "en-GB": "Delete Account",
      "pt-PT": "Apagar conta",
    },
    aboutMe: {
      "en-GB": "About me",
      "pt-PT": "O meu perfil",
    },
    settings: {
      "en-GB": "Settings",
      "pt-PT": "As minhas definições",
    },
    noInfo: {
      "en-GB": "No info",
      "pt-PT": "Sem informação",
    },
  },
  PROGRESS_OVERTIME: {
    mainPageTitle: {
      "en-GB": "My analytics",
      "pt-PT": "Os meus resultados",
    },
    title: {
      "en-GB": "See your progress and compare with others",
      "pt-PT": "Visualiza o teu progresso e compara-te com outros",
    },
    progressButton: {
      "en-GB": "Progress over time",
      "pt-PT": "A minha evolução",
    },
    lastResultButton: {
      "en-GB": "My last results",
      "pt-PT": "O meu último resultado",
    },
    weekDescription: {
      "en-GB":
        "Check your last week’s results. Compare them with other users’ average daily scores (and use the filters to refine your insights)",
      "pt-PT":
        "Revisita os teus resultados da última semana. Compara-os com a média diária de outros utilizadores (e usa os filtros para refinares as tuas observações)",
    },
    monthDescription: {
      "en-GB":
        "Check your last month’s weekly results. Compare them with other users’ average weekly scores (and use the filters to refine your insights)",
      "pt-PT":
        "Revisita os teus resultados do último mês. Compara-os com a média semanal de outros utilizadores (e usa os filtros para refinares as tuas observações)",
    },
    semesterDescription: {
      "en-GB":
        "Check your last semester’s monthly results. Compare them with other users’ average monthly scores (and use the filters to refine your insights)",
      "pt-PT":
        "Revisita os teus resultados do último semestre. Compara-os com a média mensal de outros utilizadores (e usa os filtros para refinares as tuas observações)",
    },
    yearDescription: {
      "en-GB":
        "Check your last year’s monthly results. Compare them with other users’ average monthly scores (and use the filters to refine your insights)",
      "pt-PT":
        "Revisita os teus resultados do último ano. Compara-os com a média mensal de outros utilizadores (e usa os filtros para refinares as tuas observações)",
    },
    myselfLabel: {
      "en-GB": "Myself",
      "pt-PT": "Eu",
    },
    othersLabel: {
      "en-GB": "Others",
      "pt-PT": "Outros",
    },
  },
  TERMS_OF_SERVICE: {
    mainTitle: {
      "en-GB": "Terms of service",
      "pt-PT": "Termos e condições",
    },
    first_sentence: {
      "en-GB": `Within the scope of its activity, PUR’PLE, Unipessoal, Lda. ("`,
      "pt-PT": `No âmbito da sua atividade, a PUR’PLE, Unipessoal, Lda. (") `,
    },
    second_sentence: {
      "en-GB": `"), provides management consultancy services to companies, organisations
      or individuals, including planning, organisation, control, management,
      process design and implementation, reorganisation of companies, human
      resources management, including, social and demographic studies, human
      dimensioning, definition and evaluation of performance, compensation,
      evaluation and, individual and leadership development, training,
      internal communication, licensing and integration of computer systems
      (software) and technologies to support the implementation of solutions.`,
      "pt-PT": `"), presta serviços de consultoria de gestão às empresas, organismos
       ou indivíduos, compreendendo nomeadamente as atividades de planeamento, organização,
        controlo, gestão, desenho e implementação de processos, reorganização de empresas,
         a gestão de recursos humanos, incluindo, estudos sociais e demográficos, dimensionamento humano,
          definição e avaliação de desempenho, compensação, avaliação e desenvolvimento individual e de liderança,
           formação, comunicação interna, licenciamento e integração de sistemas informáticos (software) e tecnologias
            de suporte à implementação das soluções.`,
    },
    third_sentence: {
      "en-GB": `PUR'PLE is strongly committed to the protection of personal data of
      those who use its website https://violet.pur-ple.io/ (the “`,
      "pt-PT": `A PUR’PLE está fortemente empenhada em proteger os dados pessoais de quem recorre ao seu website  https://violet.pur-ple.io/ (o “`,
    },
    site: {
      "en-GB": "Website",
      "pt-PT": "Site",
    },
    fourth_sentence: {
      "en-GB": `”) or benefit from its services, and hereby intends, on the one hand, to
      govern through its website terms and conditions (the “`,
      "pt-PT": `”) ou aos seus serviços, sendo que através dos presentes termos e condições de utilização do Site (os “`,
    },
    terms: {
      "en-GB": "Terms and Conditions",
      "pt-PT": "Termos e Condições de Utilização",
    },
    fifth_sentence: {
      "en-GB": `”) and navigation on the Site by any person who accesses the Site,
      regardless of whether or not such person is register user (the “`,
      "pt-PT": `”) pretende, por um lado, definir as condições de acesso e de navegação no Site por parte de toda e 
      qualquer pessoa que aceda ao Site, independentemente de nele se registar ou não (o “`,
    },
    user: {
      "en-GB": "User",
      "pt-PT": "Utilizador",
    },
    sixth_sentence: {
      "en-GB": `”), and, on the other hand, to inform through this privacy policy (the "`,
      "pt-PT": `”), e, por outro lado, informar, através da sua política de privacidade (a "`,
    },
    policy: {
      "en-GB": "Policy",
      "pt-PT": "Política de Privacidade",
    },
    seventh_sentence: {
      "en-GB": `"), in a clear and transparent way, among others, the purposes of the
      collection of the personal data of the Users and of the personal data of
      the registered Users who acquire PUR’PLE products and services through
      the Website (the “`,
      "pt-PT": `”), de forma clara e transparente, entre outros, quais as finalidades
       para as quais recolhe os dados pessoais do Utilizador e os dados pessoais do Utilizador
        registado que adquira produtos ou serviços da PUR’PLE através do Site (o “`,
    },
    client: {
      "en-GB": "Clients",
      "pt-PT": "Cliente",
    },
    eight_sentence: {
      "en-GB": ` ”), as well as the way they are processed (i.e. collection, storage,
        processing, transmission and disposal), ensuring that the data is
        collected, shared and saved taking as a reference the best practices in
        the field of security and protection of personal data.`,
      "pt-PT": `”), bem como sob a forma como são tratados (i.e. recolha, armazenamento,
           processamento, transmissão e eliminação), garantindo que os dados são recolhidos,
            partilhados e guardados, tendo como referência as melhores práticas no domínio 
            da segurança e da proteção dos dados pessoais. `,
    },
    ninth_sentence: {
      "en-GB": `It is extremely important that you read this page with due attention and
      fully understand its scope. Should you have any questions or concerns
      regarding this document, please do not hesitate to contact through the
      channels identified in our Privacy Policy which is accessible at
      https://violet.pur-ple.io/`,
      "pt-PT": `É extremamente importante que leia o presente documento com a devida 
      atenção e que perceba integralmente o seu alcance. Caso tenha qualquer dúvida ou questão
       a respeito do mesmo, por favor não hesite em contactar pelos canais identificados na nossa
        Política de Privacidade, a qual está disponível em https://violet.pur-ple.io/`,
    },
    first_title: {
      "en-GB": `I. Terms and Conditions of Use`,
      "pt-PT": `I. Termos e Condições de Utilização`,
    },
    second_title: {
      "en-GB": `1. Use of the Website`,
      "pt-PT": `1. Utilização do Site`,
    },
    third_title: {
      "en-GB": `1.1. Access to the Website`,
      "pt-PT": `1.1. Acesso ao Site`,
    },
    tenth_sentence: {
      "en-GB": `PUR'PLE has the exclusive right, at any time, to suspend, partially or
      totally, the availability of the Website or of the access to it,
      especially (but not exclusively) when performing managing, maintaining,
      repairing, altering or improving operations. PUR’PLE may also choose to
      terminate, permanently or temporarily, partially or totally, at any
      time, the Website or any of the services, without prior notice.`,
      "pt-PT": `A PUR’PLE tem o direito exclusivo de, a todo o tempo, suspender, parcial
       ou totalmente, a disponibilidade do Site ou o acesso ao mesmo, em especial
        (mas não exclusivamente) aquando de operações de gestão, manutenção, reparação,
         alteração ou modernização. A PUR’PLE poderá ainda optar por encerrar, definitiva
          ou temporariamente, parcial ou totalmente, a qualquer momento, 
          o Site ou qualquer um dos serviços, sem aviso prévio.`,
    },
    fourth_title: {
      "en-GB": `1.2. General Use`,
      "pt-PT": `1.2. Utilização Geral`,
    },
    eleventh_sentence: {
      "en-GB": `The User shall use the Website in a responsible, prudent and careful
      manner, and shall not disturb or harm the continuity, integrity and
      quality of the resources and functionalities of the same, nor shall
      use it, and through it, make any misuse, namely, but without limiting,
      infringing the applicable law.`,
      "pt-PT": `O Utilizador deve utilizar o Site de forma responsável, prudente e cuidadosa,
       não devendo perturbar ou degradar a continuidade, integridade e qualidade dos recursos
        e funcionalidades do mesmo, nem devendo dele, a partir dele, e por meio dele fazer
         qualquer uso indevido, nomeadamente, mas sem limitar, ofendendo a legislação em vigor. `,
    },
    twelveth_sentence: {
      "en-GB": `PUR’PLE reserves the right to suspend the availability of the Website
      and / or to prevent access and use of the Website by any User who uses
      it improperly or who violate any of the provisions of these Terms and
      Conditions. The User acknowledges that the use of the Website is at
      his own risk, and she/he is solely responsible for any damage caused
      to her/his computer system and / or equipment or for other damages or
      losses, including loss or damage to data, resulting from the use of
      materials, content or information obtained, in any form, through the
      Website.`,
      "pt-PT": `A PUR’PLE reserva-se o direito de suspender a disponibilidade 
      do Site e/ou de impedir o acesso e utilização do mesmo por quaisquer 
      Utilizadores que o utilizem de forma imprópria ou que violem qualquer 
      das disposições destes Termos e Condições de Utilização. O Utilizador
       reconhece que a utilização que faça do Site é por sua conta e risco, 
       sendo o único responsável por qualquer dano causado ao seu sistema e/ou 
       equipamento informático ou por outros danos ou prejuízos, incluindo
        perda ou danificação de dados, que resultem da utilização dos materiais, 
        conteúdos ou informações obtidas, por qualquer forma, através do Site.`,
    },
    fifth_title: {
      "en-GB": `1.3. Intellectual property`,
      "pt-PT": `1.3. Propriedade Intelectual`,
    },
    thirteen_sentence: {
      "en-GB": `All intellectual property rights over the Website are owned by
      PUR’PLE. All content, text, images and sounds, as well as the logos,
      names, brands and distinctive signs of the Website are protected by
      intellectual property law and are the exclusive property of or are
      licensed in favour of PUR’PLE.`,
      "pt-PT": `Todos os direitos de propriedade intelectual sobre o Site
       são da titularidade da PUR’PLE. Todos os conteúdos, textos, imagens 
       e sons, bem como os logótipos, nomes, marcas e sinais distintivos do
        Site são protegidos por direitos de propriedade intelectual e são 
        exclusiva propriedade ou se encontram licenciados a favor da PUR’PLE. `,
    },
    fourteen_sentence: {
      "en-GB": `The User is not authorized to: (i) remove or use any sign of
      intellectual property, including, but not limited to, mark or other
      disclaimers referring to PUR’PLE's intellectual property; (ii)
      reproduce, modify, perform derivative works, destroy, license, rent,
      sell, resell, transfer, exhibit or publicly display, transmit,
      reproduce, issue, or otherwise exploit the services of this Website,
      except to the extent expressly permitted by PUR'PLE; (iii) create
      links or reproduce through frames any part of the Website or any
      content made available by the Website; (iv) create or launch any
      programs or scripts to capture, index, collect or, in any other way,
      extract data from the Website, or unduly overload and / or make it
      impossible to operate and / or run; or (v) attempt to gain
      unauthorized access to the Website or jeopardize any feature of it.`,
      "pt-PT": `O Utilizador não está autorizado a: (i) remover ou usar 
      qualquer indicação de propriedade intelectual, incluindo, mas sem 
      limitar, marca ou outros avisos referentes à propriedade intelectual 
      da PUR’PLE; (ii) reproduzir, modificar, realizar trabalhos derivados,
       destruir, licenciar, alugar, vender, revender, transferir, expor ou
        apresentar publicamente, transmitir, reproduzir, emitir, ou de qualquer 
        outra forma explorar os serviços do presente Site, exceto na medida do 
        expressamente permitido pela PUR’PLE; (iii) criar ligações ou 
        reproduzir através de frames qualquer parte do Site ou de quaisquer
         conteúdos por este disponibilizados; (iv) criar ou lançar quaisquer
          programas ou scripts para captar, indexar, recolher ou, de qualquer
           outra forma, extrair dados do Site, ou sobrecarregar indevidamente 
           e/ou impossibilitar a operação e/ou funcionalidades do mesmo; ou (v) 
           tentar conseguir o acesso não autorizado ao site ou prejudicar qualquer 
           aspeto do mesmo. `,
    },
    sixth_title: {
      "en-GB": `2. Processing of Personal Data`,
      "pt-PT": `2. Tratamento de Dados Pessoais`,
    },
    fifteen_sentence: {
      "en-GB": `The use of this Website does not necessarily imply the processing of
      personal data. However, if you wish to request any contact or
      clarification, subscribe our newsletter, download of articles and
      materials and / or to purchase products or services from PUR’PLE, your
      personal data will be required. We advise you to read our Privacy Policy
      available at https://violet.pur-ple.io/.`,
      "pt-PT": `A utilização deste Site não implica necessariamente o fornecimento 
      de dados pessoais. No entanto, caso pretenda solicitar algum contacto
       ou esclarecimento, subscrever a nossa newsletter, aceder a conteúdos 
       e artigos e/ou adquirir produtos ou serviços da PUR’PLE, ser-lhe-ão 
       solicitados dados pessoais. Aconselhamos a leitura prévia da nossa 
       Política de Privacidade disponível em https://violet.pur-ple.io/.`,
    },
    seventh_title: {
      "en-GB": `3. Liability and Warranties`,
      "pt-PT": `3. Responsabilidade e Garantias`,
    },
    sixteen_sentence: {
      "en-GB": `This Website was developed having in mind the interests of our
      different types of Users and public of PUR’PLE's products or services.`,
      "pt-PT": `O Site foi desenvolvido a pensar nos interesses dos nossos diferentes
       tipos de Utilizadores e do público dos produtos ou serviços da PUR’PLE. `,
    },
    seventeen_sentence: {
      "en-GB": `PUR’PLE will not be responsible for any damages and / or losses
      suffered by the User or by third parties as a result of the occurrence
      of circumstances classified as incidental and / or force majeure.`,
      "pt-PT": `A PUR’PLE não será responsável por quaisquer danos, prejuízos
       e/ou perdas sofridas pelo Utilizador ou por terceiros em resultado da 
       ocorrência de circunstâncias classificadas como casos fortuitos e/ou de força maior. `,
    },
    eighteen_sentence: {
      "en-GB": `The User acknowledges that the Internet constitutes a public network
      and that, as such, no liability can be attributed for damages
      resulting from its use. PUR’PLE is also not responsible for the
      incorrect use of the Website and / or its services, nor for any losses
      or damages caused by such misuse.`,
      "pt-PT": `O Utilizador reconhece que a internet constitui uma rede
       pública e que, como tal, não poderá ser imputada qualquer responsabilidade 
       por danos resultantes da utilização da mesma. A PUR’PLE não se
        responsabiliza, ainda, pela incorreta utilização do Site e/ou dos 
        seus serviços, nem por prejuízos ou danos causados por essa má utilização. `,
    },
    eight_title: {
      "en-GB": `4. Cookies Policy`,
      "pt-PT": `4. Política de Cookies `,
    },
    nineteen_sentence: {
      "en-GB": `In order to provide and guarantee to the User a unique and
      personalized experience, PUR’PLE needs to remember and store some
      information about how the User navigates in this Website.`,
      "pt-PT": `A fim de garantir e proporcionar ao Utilizador uma experiência única
       e personalizada, a PUR’PLE memoriza e armazena informações sobre como o
        Utilizador navega no Site.  `,
    },
    twenty_sentence: {
      "en-GB": `What are cookies?`,
      "pt-PT": `O que são cookies?`,
    },
    twentyone_sentence: {
      "en-GB": `Cookies are very small text files that are stored on your computer
      when the User visits a website. PUR’PLE uses cookies for a variety of
      purposes and to enhance User’s online experience on its website. The
      User can change her/his preferences and decline certain types of
      cookies to be stored on her/his computer while browsing the Website.
      User can also remove any cookies already stored on her/his computer,
      but deleting cookies may prevent from using parts of Website.`,
      "pt-PT": `Cookies são ficheiros de texto muito reduzidos que são 
      armazenados no seu computador quando o Utilizador visita um site.
       A PUR’PLE utiliza os cookies para várias finalidades e para 
       melhorar a experiência online do Utilizador no seu Site. 
       O Utilizador pode alterar as suas preferências e recusar 
       certos tipos de cookies a serem armazenados no seu computador 
       enquanto navega no Site. O Utilizador pode igualmente remover 
       quaisquer cookies já armazenados no seu computador, mas a exclusão 
       de cookies poderá impedir a utilização de partes do Site. `,
    },
    twentytwo_sentence: {
      "en-GB": `What type of cookies does PUR’PLE use and why?`,
      "pt-PT": `Que tipo de cookies usa a PUR’PLE e porquê?`,
    },
    twentythree_sentence: {
      "en-GB": `Strictly necessary Cookies: required to enable User to move around the
      Website and use its features.`,
      "pt-PT": `Cookies estritamente necessários: necessários para permitir que o Utilizador
      navegue pelo Site e use as respetivas funcionalidades. `,
    },
    twentyfour_sentence: {
      "en-GB": `Session Cookies: record information about the choices Users have made
      and to allow PUR’PLE to tailor the Website to its Users: for example,
      to remember language or region. This information is usually anonymized
      and is not used for any other purpose.`,
      "pt-PT": `Cookies de sessão: registo de informações sobre as escolhas que
       o Utilizador faz e que permite à PUR’PLE adaptar o Site aos seus Utilizadores;
        por exemplo, para memorizar o respetivo idioma ou localização. 
        Estas informações geralmente são anónimas e não são usadas para nenhuma outra finalidade. `,
    },
    twentyfive_sentence: {
      "en-GB": `First-Party Cookies: PUR’PLE or its service providers also use
      analytic services to help understanding how effective Website content
      is, what interests Users have, and to improve how this Website works.
      In addition, PUR’PLE uses web beacons or tracking pixels to count
      visitor numbers and performance cookies to track how many individual
      Users access this Website and how often. This information is used for
      statistical purposes only and it is not PUR’PLE’s intention to use
      such information to personally identify any User.`,
      "pt-PT": `Cookies primários: a PUR´PLE ou seus fornecedores ou prestadores
       de serviços usam igualmente serviços analíticos para ajudar a entender 
       a eficácia dos conteúdos do Site, quais são os interesses do seus
        Utilizadores e para melhorar o funcionamento do Site. Além disso, 
        a PUR’PLE usa web beacons ou pixels de rastreamento para contabilizar
         o número de visitantes e cookies de desempenho para rastrear quantos
          Utilizadores individuais acedem ao Site e com que frequência.
           Essas informações são usadas apenas para fins estatísticos e não
            é intenção da PUR’PLE usar estas informações para identificar
             individualmente qualquer Utilizador.`,
    },
    twentysix_sentence: {
      "en-GB": `This Website does not use third-party cookies to deliver targeted
      advertising to website visitors.`,
      "pt-PT": `Este Site não usa cookies de terceiros para fornecer publicidade 
      direcionada aos Utilizadores do Site. `,
    },
    twentyseven_sentence: {
      "en-GB": `Please contact PUR’PLE if you would like more detailed information on
      the cookies we use.`,
      "pt-PT": `Entre em contato com a PUR’PLE se desejar informações mais detalhadas
       sobre os cookies usados. `,
    },
    nineth_title: {
      "en-GB": `5. Applicable Law`,
      "pt-PT": `5. Lei Aplicável`,
    },
    twentyeight_sentence: {
      "en-GB": `If any provision or part of these Terms and Conditions is not
      enforceable or is contrary to applicable law, the validity of the
      remaining terms or conditions will not be affected. Portuguese law is
      applicable to the management, administration, use and enforceability of
      this Website Terms and Conditions of Use.`,
      "pt-PT": `Se alguma disposição ou parte dos presentes Termos e Condições
       de Utilização não for exequível ou contrariar a lei aplicável, a validade 
       dos restantes termos ou condições não será afetada. À gestão,
        administração, utilização e aplicação dos Termos e Condições de 
        Utilização do Site é aplicável a legislação portuguesa. `,
    },
  },
  PRIVACY_POLICY: {
    mainTitle: {
      "en-GB": `Privacy policy`,
      "pt-PT": `Política de privacidade`,
    },
    PARAGRAPH_1_SENTENCE_1: {
      first_part: {
        "en-GB": `PUR’PLE, Unipessoal, Lda. ("`,
        "pt-PT": `A PUR’PLE, Unipessoal, Lda. ("`,
      },
      second_part: {
        "en-GB": `") operates the
        https://violet.pur-ple.io website (the “`,
        "pt-PT": `") opera o site https://violet.pur-ple.io/ (o "`,
      },
      website: {
        "en-GB": "Website",
        "pt-PT": "Site",
      },
      third_part: {
        "en-GB": `”), which provides the `,
        "pt-PT": `"), que providencia o `,
      },
      service: {
        "en-GB": "SERVICE",
        "pt-PT": "SERVIÇO",
      },
    },
    PARAGRAPH_1_SENTENCE_2: {
      "en-GB": `This page is used to inform website visitors regarding our policies with
      the collection, use, and disclosure of Personal Information if anyone
      decided to use our Service, the website.`,
      "pt-PT": `Esta página tem como finalidade informar os visitantes do Site da 
        nossa política no que respeita à recolha, uso e divulgação de Informação
         Pessoal dos utilizadores do nosso Serviço, o Site`,
    },
    PARAGRAPH_1_SENTENCE_3: {
      "en-GB": `If you choose to use our Service, then you agree to the collection and
      use of information in relation with this policy. The Personal
      Information that we collect are used for providing and improving the
      Service. We will not use or share your information with anyone except as
      described in this Privacy Policy.`,
      "pt-PT": `Ao decidir usar o nosso Serviço, está a concordar com a recolha e uso 
        da sua informação nos termos descritos nesta política. A Informação Pessoal 
        recolhida é usada para os fins de fornecimento e melhoria do Serviço
         fornecido. Essa informação não será usada ou partilhada com qualquer
          outra parte exceto as identificadas e descritas nesta Política de Privacidade.`,
    },
    PARAGRAPH_1_SENTENCE_4: {
      "en-GB": `The terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, which is accessible at https://violet.pur-ple.io/
      unless otherwise defined in this Privacy Policy.`,
      "pt-PT": `As expressões usadas nesta Política de Privacidade têm o mesmo 
        significado das expressões usadas nos Termos e Condições, que podem
         ser consultados em https://violet.pur-ple.io/, salvo definição em 
         contrário na presente Política de Privacidade.`,
    },
    PERSONAL_DATA_RESPONSIBILITY_TITLE: {
      "en-GB": `Controller of the Personal Data Processing`,
      "pt-PT": "Responsável pelo Tratamento dos Dados Pessoais",
    },
    PERSONAL_DATA_RESPONSIBILITY_BODY: {
      first_part: {
        "en-GB": `The entity responsible for processing personal data is the company PUR’PLE, Unipessoal, Lda.
        registered under the single identification and legal person number
        514834242, with registered office at Rua do Açúcar, 76, Armazém 4,
        1950-009 Lisbon (“`,
        "pt-PT": `A entidade responsável pelo tratamento dos dados pessoais é a sociedade PRPL,
         Lda., matriculada sob o número único de identificação e de pessoa coletiva 514834242,
          com sede na Rua do Açúcar, n.º 76, Armazém 4, 1950-009 Lisboa (a "`,
      },
      second_part: {
        "en-GB": `”) or the "`,
        "pt-PT": `") ou "`,
      },
      controller: {
        "en-GB": `Controller`,
        "pt-PT": `Entidade Responsável`,
      },
    },
    PERSONAL_DATA_TREATMENT_TITLE: {
      "en-GB": `Purposes of Personal Data Processing`,
      "pt-PT": "Finalidades do Tratamento de Dados Pessoais",
    },
    PERSONAL_DATA_TREATMENT_BODY_SENTENCE_1: {
      first_part: {
        "en-GB": `The Controller, within the scope of its activities, processes personal
        data for the following purposes (the "`,
        "pt-PT": `A Entidade Responsável, no âmbito das atividades que desenvolve, procede
         ao tratamento de dados pessoais para as seguintes finalidades (as "`,
      },
      purposes: {
        "en-GB": `Purposes"):`,
        "pt-PT": `Finalidades"):`,
      },
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_1: {
      "en-GB": "Website User Management;",
      "pt-PT": "Gestão do Utilizador do Site;",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_2: {
      "en-GB": `Assistance to requests for clarifications and complaints, within the
      scope of Client’s support;`,
      "pt-PT":
        "Atendimento a pedidos de esclarecimentos e reclamações, no âmbito do apoio ao Cliente;",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_3: {
      "en-GB": `Performing of the purchase contract of products or services
      subscribed by the Client through the Website;`,
      "pt-PT":
        "Execução do contrato de compra de produtos ou serviços subscritos pelo Cliente através do Site;",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_4: {
      "en-GB": `Compliance with legal obligations vis-à-vis the authorities or other
      relevant public entities;`,
      "pt-PT":
        "Cumprimento de obrigações legais perante as autoridades ou outras entidades públicas relevantes;",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_5: {
      "en-GB": `In case PUR’PLE has obtained prior and specific consent from the
      User, for marketing purposes, namely:`,
      "pt-PT":
        "Caso tenha obtido consentimento prévio e específico do Utilizador, para fins de marketing, nomeadamente:",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_6: {
      "en-GB": `Newsletters`,
      "pt-PT": "Newsletter",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_7: {
      "en-GB": `Sending of materials such as articles, papers, brochures`,
      "pt-PT": "Envio de conteúdos como artigos, papers, brochuras",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_8: {
      "en-GB": `Invitation to events, such as conferences, congresses,
      festivals, lectures`,
      "pt-PT":
        "Convite para eventos, tais como conferências, congressos, festivais, palestras",
    },
    PERSONAL_DATA_TREATMENT_BODY_LIST_ITEM_9: {
      "en-GB": `Promotional actions.`,
      "pt-PT": "Ações promocionais.",
    },
    PERSONAL_DATA_TITLE: {
      "en-GB": "Personal Data",
      "pt-PT": "Dados Pessoais",
    },
    PERSONAL_DATA_SENTENCE_1: {
      "en-GB": `In this Policy, the term "Personal Data" means the set of information
      that relates to the User and that allows us to identify, directly or
      indirectly, the User, and may include the following data:`,
      "pt-PT": `Nesta Política de Privacidade, o termo “Dados Pessoais” significa o 
      conjunto de informações que se relacionam com o Utilizador e que nos permite identificar,
       direta ou indiretamente, o mesmo, podendo incluir os seguintes dados:`,
    },
    PERSONAL_DATA_SENTENCE_2: {
      "en-GB": `Identification data: name, surname, gender, age / date of birth;`,
      "pt-PT": `Dados de identificação: nome, apelido, género, idade/data de nascimento;`,
    },
    PERSONAL_DATA_SENTENCE_3: {
      "en-GB": `Profession: function, company, sector of activity, size of the
      company;`,
      "pt-PT": `Profissão: função, empresa, setor de atividade, dimensão da empresa;`,
    },
    PERSONAL_DATA_SENTENCE_4: {
      "en-GB": `Contacts: email address, phone / cell phone, address and location;`,
      "pt-PT": `Contactos: endereço de e-mail, telefone/telemóvel, morada e localização;`,
    },
    PERSONAL_DATA_SENTENCE_5: {
      "en-GB": `Language and country from which you are contacting;`,
      "pt-PT": `Língua e país a partir do qual contacta;`,
    },
    PERSONAL_DATA_SENTENCE_6: {
      "en-GB": `Economic and transactional information (for example, payment or card
        data for subscription or purchase of products or services);`,
      "pt-PT": `Informação económica e transacional (por exemplo, dados de pagamento 
        ou de cartão para subscrição ou aquisição de produtos ou serviços);`,
    },
    PERSONAL_DATA_SENTENCE_7: {
      "en-GB": `Connection data, geolocation and / or navigation (in the case of
        interacting via mobile phone);`,
      "pt-PT": `Dados de conexão, geolocalização e/ou navegação (no caso de interagir através do telemóvel);`,
    },
    PERSONAL_DATA_SENTENCE_8: {
      "en-GB": `Data on areas and topics of preference; and`,
      "pt-PT": `Dados sobre áreas e temas de preferência; e`,
    },
    PERSONAL_DATA_SENTENCE_9: {
      "en-GB": `Other personal data that may be collected should it be deemed
      necessary for the Purposes of the processing.`,
      "pt-PT": `Outros dados pessoais que possam vir a ser recolhidos caso os 
      mesmos se revelem necessários para as Finalidades do tratamento.`,
    },
    PERSONAL_DATA_SENTENCE_10: {
      "en-GB": `The Controller is not accountable for the truthfulness, omissions,
      inaccuracies, and falsehoods of the personal data transmitted to it.`,
      "pt-PT": `A Entidade Responsável não é responsável pela veracidade, omissões,
       inexatidões e falsidades dos dados pessoais que lhe são transmitidos. `,
    },
    PERSONAL_DATA_SENTENCE_11: {
      "en-GB": ` The personal data collected may be processed by computer and in an
      automated or non-automated way, guaranteeing in all cases strict
      compliance with personal data protection legislation.`,
      "pt-PT": `Os dados pessoais recolhidos podem ser tratados informaticamente e de forma automatizada 
      ou não automatizada, garantindo em todos os casos o estrito cumprimento da 
      legislação de proteção de dados pessoais.`,
    },
    RETENTION_DATA_TITLE: {
      "en-GB": "Retention of Personal Data",
      "pt-PT": "Conservação dos Dados Pessoais",
    },
    RETENTION_DATA_SENTENCE_1: {
      "en-GB": `The Controller processes User's personal data for the purposes
      identified above based on the following lawful basis and for the
      following storage periods:`,
      "pt-PT": `A Entidade Responsável trata os dados pessoais do Utilizador para
       as finalidades acima identificadas com base nos seguintes fundamentos de 
       licitude e pelos seguintes prazos de conservação:`,
    },
    RETENTION_DATA_TABLE_HEADERS: {
      first: {
        "en-GB": `Purpose`,
        "pt-PT": `Finalidade`,
      },
      second: {
        "en-GB": `Lawfulness Basis`,
        "pt-PT": `Fundamento de Licitude`,
      },
      third: {
        "en-GB": `Storage Periods`,
        "pt-PT": `Prazo de Conservação`,
      },
    },
    RETENTION_DATA_TABLE_ROW_1: {
      one: {
        "en-GB": `Website User Management`,
        "pt-PT": `Gestão do Utilizador do Site`,
      },
      two: {
        "en-GB": `Compliance with the Terms and Conditions of the Website`,
        "pt-PT": `Execução dos Termos e Condições de Utilização do Site`,
      },
      three: {
        "en-GB": ` Period of registration on the Website, or up to 3 months after
      the last visit to the Website (depending on whether it is a
      registered User or not, respectively)`,
        "pt-PT": `Período de duração do registo no Site, ou até 3 meses após a última 
        visita ao Site (consoante se trate de Utilizador registado ou não, respetivamente)`,
      },
    },
    RETENTION_DATA_TABLE_ROW_2: {
      one: {
        "en-GB": `Assistance to requests for clarifications and complaints, within
      the scope of Client’s support`,
        "pt-PT": `Atendimento a pedidos de esclarecimentos e reclamações, no 
        âmbito do apoio ao Cliente`,
      },
      two: {
        "en-GB": `Compliance with legal obligations`,
        "pt-PT": `Cumprimento de obrigações legais`,
      },
      three: {
        "en-GB": ` Period necessary to manage requests for clarifications or
      complaints, in accordance with the applicable legal deadlines`,
        "pt-PT": `Período necessário à gestão dos pedidos de esclarecimento ou reclamações,
         de acordo com os prazos legais aplicáveis`,
      },
    },
    RETENTION_DATA_TABLE_ROW_3: {
      one: {
        "en-GB": `Performing of the purchase contract of products or services
      subscribed by the Client through the Website`,
        "pt-PT": `Execução do contrato de compra de produtos ou serviços
         subscritos pelo Cliente através do Site`,
      },
      two: {
        "en-GB": `Performing of pre-contractual arrangements or contractual
      obligations`,
        "pt-PT": `Diligências pré-contratuais ou execução das condições contratuais`,
      },
      three: {
        "en-GB": `Period required to comply with contractual conditions and / or
      legal obligations`,
        "pt-PT": `Período necessário para o cumprimento das condições contratuais e/ou 
        de obrigações legais`,
      },
    },
    RETENTION_DATA_TABLE_ROW_4: {
      one: {
        "en-GB": `Compliance with legal obligations vis-à-vis the authorities or
      other relevant public entities`,
        "pt-PT": `Cumprimento de obrigações legais perante as autoridades ou outras
         entidades públicas relevantes`,
      },
      two: {
        "en-GB": `Compliance with legal obligations`,
        "pt-PT": `Cumprimento de obrigações legais`,
      },
      three: {
        "en-GB": `Period necessary to fulfill the concerned legal obligations,
      according to the applicable legal deadlines`,
        "pt-PT": `Período necessário ao cumprimento das obrigações legais em causa, de acordo
         com os prazos legais aplicáveis`,
      },
    },
    RETENTION_DATA_TABLE_ROW_5: {
      one: {
        "en-GB": `Sending of newsletter`,
        "pt-PT": `Envio de newsletter `,
      },
      two: { "en-GB": `Consent`, "pt-PT": `Consentimento` },
      three: {
        "en-GB": `Until the cancelation of the subscription`,
        "pt-PT": `Até cancelamento da subscrição`,
      },
    },
    RETENTION_DATA_TABLE_ROW_6: {
      one: {
        "en-GB": `Marketing purposes, download or articles and other materials`,
        "pt-PT": `Atividades comerciais e de marketing, download de artigos e outros conteúdos`,
      },
      two: { "en-GB": `Consent`, "pt-PT": `Consentimento` },
      three: {
        "en-GB": `Until the cancelation of the subscription or until the consent
      is revoked`,
        "pt-PT": `Até cancelamento da subscrição ou até que consentimento seja retirado`,
      },
    },
    RETENTION_DATA_SENTENCE_2: {
      "en-GB": `With regard to newsletters, the processing of personal data only
      starts when the respective data subject submits the subscription form,
      and ends at any time by unsubscribing by clicking a specific link
      available in all newsletters. When removing the subscription, the data
      subject will receive a notification email confirming the removal of
      their data from the newsletter mailing list.`,
      "pt-PT": `Relativamente às newsletters, o tratamento dos dados pessoais apenas
       tem início no momento em que o respetivo titular submeta o formulário de subscrição,
        terminando, a qualquer momento, mediante cancelamento da subscrição através de um 
        link próprio para o efeito disponível em todas as newsletters enviadas. Ao remover
         a subscrição, o titular dos dados em causa receberá um e-mail de notificação 
         confirmando a remoção dos seus dados da lista de envio de newsletters.`,
    },
    RIGHTS_OF_DATA_TITLE: {
      "en-GB": "Rights of the Data Subjects",
      "pt-PT": "Direitos dos Titulares dos Dados Pessoais",
    },
    RIGHTS_OF_DATA_SENTENCE_1: {
      "en-GB": `The data subjects shall have the right, at any time, to obtain clear,
      transparent and easily understandable information on how the
      Controller uses their personal data, to access their personal data and
      to request for their rectifying, erasure, portability, restriction and
      to oppose to the processing.`,
      "pt-PT": `Assegura-se ao titular dos dados pessoais o direito de, a qualquer 
      momento, obter informações claras, transparentes e facilmente compreensíveis 
      sobre como é que a Entidade Responsável usa os seus dados pessoais, aceder aos
       seus dados pessoais, bem como direito à respetiva retificação, apagamento, portabilidade,
        limitação e/ou oposição ao tratamento.`,
    },
    RIGHTS_OF_DATA_SENTENCE_2: {
      "en-GB": `In case the lawfulness basis for the processing of their personal data
      is consent, data subjects are entitled to withdraw their consent at
      any time. The withdrawal of consent does not compromise the lawfulness
      of the processing carried out on the basis of the consent previously
      given.`,
      "pt-PT": `Caso o fundamento de licitude para o tratamento dos seus dados pessoais seja
       o consentimento, o titular dos dados tem ainda o direito de retirar o seu 
       consentimento a qualquer momento. A retirada do consentimento não compromete a 
       licitude do tratamento efetuado com base no consentimento previamente dado.`,
    },
    RIGHTS_OF_DATA_SENTENCE_3: {
      "en-GB": `The exercise of any of these rights shall be done, in writing, through
      communication addressed to the Controller, either to the address of
      its registered office located at Rua do Açúcar, 76, Armazém 4,
      1950-009 in Lisbon, or to the following e-mail:
      dataprotection@prplcompany.com`,
      "pt-PT": `O exercício de quaisquer destes direitos deverá ser efetuado, por escrito,
       através de comunicação dirigida à Entidade Responsável, quer para a morada da sua 
       sede da Entidade Responsável sita na Rua do Açúcar, n.º 76, Armazém 4, 1950-009 em 
       Lisboa, quer para o seguinte correio eletrónico: dataprotection@prplcompany.com`,
    },
    RIGHTS_OF_DATA_SENTENCE_4: {
      "en-GB": `In addition, the personal data subjects will always be able to file
      any complaints they deem necessary with the competent authority
      (currently the CNPD –`,
      "pt-PT": `Adicionalmente, os titulares dos dados pessoais poderão sempre apresentar as
       reclamações que entenderem necessárias junto da autoridade competente para o efeito 
       (atualmente a CNPD -`,
    },
    RIGHTS_OF_DATA_SENTENCE_5: {
      "en-GB": `Comissão Nacional de Proteção de Dados).`,
      "pt-PT": `Comissão Nacional de Proteção de Dados).`,
    },
    SECURITY_TITLE: {
      "en-GB": `Security in the Processing of Personal Data`,
      "pt-PT": `Segurança no Tratamento de Dados Pessoais`,
    },
    SECURITY_TITLE_SENTENCE_1: {
      "en-GB": `The Controller is committed to ensure the security, integrity and
      availability of personal data of its Users. To this effect, it has
      adopted the following measures:`,
      "pt-PT": `A Entidade Responsável assume o compromisso de garantir a segurança,
       integridade e disponibilidade dos dados pessoais dos Utilizadores. Para o efeito,
        adotou as seguintes medidas: `,
    },
    SECURITY_TITLE_SENTENCE_2: {
      "en-GB": `Software antivirus, firewall and anti-malware;`,
      "pt-PT": `Software antivirus, firewall e anti-malware;`,
    },
    SECURITY_TITLE_SENTENCE_3: {
      "en-GB": `Differentiated access profiles according to the function/position of
      the person accessing the information systems;`,
      "pt-PT": `Perfis de acesso diferenciados consoante a função/cargo da 
      pessoa que acede aos sistemas de informação;`,
    },
    SECURITY_TITLE_SENTENCE_4: {
      "en-GB": `Access to information systems through user name and a personal and
      non-transferable password;`,
      "pt-PT": `Acesso aos sistemas de informação através de user name e password pessoal e intransmissível;`,
    },
    SECURITY_TITLE_SENTENCE_5: {
      "en-GB": `Implementation of password quality rules;`,
      "pt-PT": `Implementação de regras de qualidade de password;`,
    },
    SECURITY_TITLE_SENTENCE_6: {
      "en-GB": `Detection of unauthorized access to information systems and physical
      facilities;`,
      "pt-PT": `Deteção de acessos não autorizados aos sistemas de informação e às instalações físicas;`,
    },
    SECURITY_TITLE_SENTENCE_7: {
      "en-GB": `Elimination of user profiles of the Clients that terminate their
      relationship (labour or others), as well as all privileges or access
      rights granted to such users;`,
      "pt-PT": `Eliminação de perfis de Clientes que cessem o seu vínculo (laboral ou outro),
       bem como de todos os privilégios ou direitos de acesso concedidos a tais Utilizadores;`,
    },
    SECURITY_TITLE_SENTENCE_8: {
      "en-GB": `Execution of periodic backups; and,`,
      "pt-PT": `Realização de backups periódicos; e,`,
    },
    SECURITY_TITLE_SENTENCE_9: {
      "en-GB": `Execution of periodic intrusion tests.`,
      "pt-PT": `Realização de testes de intrusão periódicos.`,
    },
    SECURITY_TITLE_SENTENCE_10: {
      "en-GB": `The Controller informs that these security measures will be reviewed
      and updated according to the needs and requirements of these matters.`,
      "pt-PT": `A Entidade Responsável informa que as referidas medidas de segurança serão 
      revistas e atualizadas consoante as necessidades e exigências destas matérias.`,
    },
    SECURITY_TITLE_SENTENCE_11: {
      "en-GB": `Should a breach of security occur for any reason, which causes
      accidental or unlawful destruction, loss, alteration, unauthorised
      disclosure or access to personal data, the Controller undertakes, in
      accordance with applicable law, to notify the competent authorities
      without undue delay and, whenever possible, within 72 hours of
      becoming aware of such an occurrence.`,
      "pt-PT": `Caso, por algum motivo, se verifique uma violação da segurança que provoque,
       de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o 
       acesso, não autorizados aos dados pessoais, a Entidade Responsável compromete-se, 
       nos termos da legislação aplicável, a comunicar junto das autoridades competentes, 
       sem demora justificada e, sempre que possível, até 72 horas após ter tido conhecimento
        de tal ocorrência.`,
    },
    SECURITY_TITLE_SENTENCE_12: {
      "en-GB": `In addition, and under the terms referred to in the previous
      paragraph, the Controller undertakes to report the violation of
      personal data to the respective subject.`,
      "pt-PT": `Adicionalmente, e nos termos referidos no parágrafo anterior, 
      a Entidade Responsável compromete-se a comunicar a violação dos dados 
      pessoais ao respetivo titular dos seus dados.`,
    },
    COMUNICATION_OF_PERSONAL_DATA_TITLE: {
      "en-GB": `Communication of Personal Data to Third Parties`,
      "pt-PT": `Comunicação dos Dados Pessoais a Entidades Terceiras`,
    },
    COMUNICATION_OF_PERSONAL_DATA_SENTENCE_1: {
      "en-GB": `Whenever, in the context of its Services or the Purposes described in
      this Policy, the Controller shares User's personal data with
      subcontracted third parties, it undertakes, under the terms of the
      contracts to be signed with them, to adopt the necessary and
      appropriate measures in order to ensure that all entities, that have
      access to such personal data, offer high guarantees of security,
      limiting the communication of personal data to the minimum required.`,
      "pt-PT": `Sempre que, no contexto dos seus Serviços ou das Finalidades descritas
       nesta Politica de Privacidade, a Entidade Responsável partilhar os dados pessoais
        dos Utilizadores com entidades terceiras subcontratadas, compromete-se a, nos termos 
        dos contratos a celebrar com as mesmas, adotar as medidas necessárias e adequadas 
        por forma a assegurar que todas as entidades que tenham acesso a tais dados pessoais
         ofereçam elevadas garantias de segurança, limitando a comunicação dos dados pessoais 
         ao mínimo indispensável. `,
    },
    COMUNICATION_OF_PERSONAL_DATA_SENTENCE_2: {
      "en-GB": `Where necessary, and in connection with the employment of third
      parties by the Controller, personal data may be transferred outside
      the European Economic Area (the “EEA”) for locations that may not
      guarantee the same level of protection. However, if applicable, the
      Controller will only transfer personal data outside the EEA, in
      accordance with the following measures:`,
      "pt-PT": `Sempre que necessário, e no âmbito da contratação de entidades 
      terceiras pela Entidade Responsável, os dados pessoais poderão ser transferidos
       para fora do Espaço Económico Europeu (o “EEE”), para localizações que 
       poderão não garantir o mesmo nível de proteção. Contudo, caso aplicável, 
       a Entidade Responsável só realizará transferências de dados pessoais para 
       fora do EEE, de acordo com as seguintes medidas:`,
    },
    COMUNICATION_OF_PERSONAL_DATA_SENTENCE_3: {
      "en-GB": `When the transfer is made to a location or through a method or in
      circumstances that the European Commission considers to ensure
      adequate protection of personal data;`,
      "pt-PT": `Quando a transferência for realizada para uma localização ou 
      através de um método ou em circunstâncias que a Comissão Europeia considere 
      garantirem a proteção adequada dos dados pessoais;`,
    },
    COMUNICATION_OF_PERSONAL_DATA_SENTENCE_4: {
      "en-GB": `When it has implemented data protection standard contractual clauses
      approved by the European Commission or a competent supervisory
      authority; or,`,
      "pt-PT": `Quando tiver implementado cláusulas contratuais-tipo de proteção de
       dados aprovadas pela Comissão Europeia ou por uma autoridade de controlo competente; ou,`,
    },
    COMUNICATION_OF_PERSONAL_DATA_SENTENCE_5: {
      "en-GB": `When none of the above apply, but the law still authorizes such a
      transfer, for example, if it is necessary for the declaration,
      exercise, or defence of a right in a judicial proceeding.`,
      "pt-PT": `Quando nenhuma das opções anteriores se aplicar, mas, ainda assim, a lei
       autorizar essa transferência, por exemplo, se a mesma for necessária para a declaração,
        o exercício ou a defesa de um direito num processo judicial.`,
    },
    PRIVACY_POLICY_CHANGES_TITLE: {
      "en-GB": `Privacy Policy Changes`,
      "pt-PT": `Alterações à Política de Privacidade`,
    },
    PRIVACY_POLICY_CHANGES_SENTENCE_1: {
      "en-GB": `The Controller reserves the right to make changes to this Privacy Policy
      at any time, and such changes will be duly published on the Website.`,
      "pt-PT": `A Entidade Responsável reserva-se ao direito de, a qualquer altura, proceder a 
      alterações à presente Política de Privacidade, sendo certo que tais alterações serão 
      devidamente publicadas no Site.`,
    },
  },
  WELCOME_SURVEY: {
    greeting: {
      "en-GB": "Welcome back, ",
      "pt-PT": "Bem-vindo/a de volta, ",
    },
  },
  SURVEY_SUBMIT_BUTTON: {
    text: {
      "en-GB": "Submit",
      "pt-PT": "Submeter",
    },
  },
};

/*
 fourth_button: {
        "en-GB": "",
        "pt-PT": ""
      }
*/

export { getStaticCopies, STATIC_COPIES };
