const CORE_SURVEY_QUESTIONS_NUMBER = 14; // trade for survey.survey.attributes.questions_number
const TRIAL_SURVEY_QUESTIONS_NUMBER = 10; // trade for survey.survey.attributes.questions_number

const WELCOME_SURVEY_ID = "ad0373e4-aea5-4137-bf13-b9bd85c5c2ed";
const TRIAL_SURVEY_ID = "051434d9-bdf4-4ecf-9f7f-5e214ea94491";
const CORE_SURVEY_ID = "1af3e809-4ec0-4fb2-8180-f3c9e4ea2987";

// pages initials
const MY_ANALYTICS_PAGE_ID = "MA";
const RESULTS_PAGE_ID = "CR";
const WELCOME_BACK_PAGE_ID = "WB";
const PRELIMINARY_RESULTS_PAGE_ID = "PR";
const SHARE_RESULTS_PAGE_ID = "SR";
const MEET_VIOLET_PAGE_ID = "MV";

const DIMENSIONS_TO_EVALUATE = 4;
const MAX_POINTS_TARGET = 4.8;

// colors

const VIOLET_PINK = "rgba(199, 124, 235, 1)";

const WHITE = "rgba(255, 255, 255, 1)";
const BLACK = "rgba(0, 0, 0, 1)";
const TRANSPARENT = "rgba(0, 0, 0, 0)";

export {
  CORE_SURVEY_QUESTIONS_NUMBER,
  TRIAL_SURVEY_QUESTIONS_NUMBER,
  WELCOME_SURVEY_ID,
  TRIAL_SURVEY_ID,
  CORE_SURVEY_ID,
  DIMENSIONS_TO_EVALUATE,
  MAX_POINTS_TARGET,
  VIOLET_PINK,
  WHITE,
  BLACK,
  TRANSPARENT,
  MY_ANALYTICS_PAGE_ID,
  RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
  MEET_VIOLET_PAGE_ID,
};
