import React from "react";
import { connect } from "react-redux";
import Mailto from "../../../components/Mailto";
import { getStaticShareEmailCopies } from "../../../utils/constants/copies/copies.helper";

/**
 * Functional Component: ShareResultByEmail
 * component to share a result by email
 * @param {Object} currentUser
 * @param {String} share_url
 * @param {Object} props
 * @returns {Component}
 */
const ShareResultByEmail = ({ currentUser, share_url, ...props }) => {
  //contains all copies for results sharing by email
  const SHARE_EMAIL_COPIES = getStaticShareEmailCopies({
    language: props.language,
  });

  if (currentUser.attributes.gender) {
    if (currentUser.attributes.gender === "M") {
      SHARE_EMAIL_COPIES["share-result"].body = SHARE_EMAIL_COPIES[
        "share-result"
      ].body.replace("O/A", "O");
    } else if (currentUser.attributes.gender === "F") {
      SHARE_EMAIL_COPIES["share-result"].body = SHARE_EMAIL_COPIES[
        "share-result"
      ].body.replace("O/A", "A");
    }
  }

  return (
    <Mailto
      subject={SHARE_EMAIL_COPIES["share-result"].subject.replace(
        "[name]",
        currentUser.attributes.first_name
      )}
      body={SHARE_EMAIL_COPIES["share-result"].body
        .replace("[name]", currentUser.attributes.first_name)
        .replace("[url]", share_url)
        .replace("?", "%3F")
        .replace("&", "%26")}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, language: state.language };
};

export default connect(mapStateToProps)(ShareResultByEmail);
