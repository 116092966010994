import React from "react";
import {
  redirectToPrivacyPolicyPage,
  redirectToTermsOfServicePage,
} from "../../core/Routing/RoutesRedirects";
import "./Footer.css";
import purpleLogo from "../../assets/images/purpleLogo2.png";

import { getStaticFooterCopies } from "../../utils/constants/copies/copies.helper";
import { connect } from "react-redux";
import { createAnalytic } from "../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: Footer
 * The footer for violet, is present in all the pages, has links to privacy policy, terms of service and to pur'ple website
 *
 * @returns {Element}
 */
const Footer = ({ language }) => {
  //contains all the copies for the footer component
  const SELF_COPIES = getStaticFooterCopies({ language: language });

  return (
    <div id="footer-container">
      <div className="d-flex flex-column flex-sm-row">
        <div
          id="left-sentence"
          className="footer-options d-flex justify-content-center align-items-center justify-content-sm-start"
        >
          <div
            id="purple-logo-container"
            className="d-flex justify-content-end"
          >
            <img
              onClick={() => {
                window.open("https://www.purposebypeople.com/", "_blank");

                createAnalytic(EVENTS.PURPLE_FOOTER);
              }}
              id="purple-logo"
              src={purpleLogo}
              alt=""
            />
          </div>
          {SELF_COPIES.rightsReserved.replace(
            "[year]",
            new Date().getFullYear()
          )}
        </div>

        <div
          onClick={() => redirectToTermsOfServicePage()}
          style={{ cursor: "pointer" }}
          className="footer-options d-flex justify-content-center align-items-center justify-content-sm-start"
          id="terms"
        >
          {SELF_COPIES.termsOfService}
        </div>

        <div
          onClick={() => redirectToPrivacyPolicyPage()}
          style={{ cursor: "pointer" }}
          className="footer-options d-flex justify-content-center align-items-center justify-content-sm-start"
          id="policy"
        >
          {SELF_COPIES.privacyPolicy}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Footer);
