import React, { useState } from "react";
import { connect } from "react-redux";
import { getStaticCookiesCopies } from "../../utils/constants/copies/copies.helper";
import {
  areCookiesEnabled,
  doesAcceptanceCookieExist,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";
import CookiesPolicy from "./CookiesPolicy";
import CookiesPreferences from "./CookiesPreferences";

/**
 * Functional Component: CookiesInfo
 * The manager of CookiesPolicy and CookiesPreferences
 *
 * @param {String} language
 * @returns {Element} CookiesPolicy and CookiesPreferences
 */
const CookiesInfo = ({ language }) => {
  const [isPreferencesVisible, setPreferencesVisibility] = useState(false);

  // function that is triggered when user submits in CookiesPolicy/CookiesPreferences
  const handleSubmit = () => {
    setPreferencesVisibility(false);
    window.location.reload();
  };

  const SELF_COPIES = getStaticCookiesCopies({
    language,
    type: areCookiesEnabled ? "enabled" : "blocked",
  });

  return (
    <>
      <CookiesPolicy
        open={!doesAcceptanceCookieExist}
        onManageCookies={() => setPreferencesVisibility(true)}
        onSubmit={handleSubmit}
        copies={SELF_COPIES.COOKIES_POLICY}
      />
      <CookiesPreferences
        open={isPreferencesVisible}
        onChange={setPreferencesVisibility}
        onSubmit={handleSubmit}
        copies={SELF_COPIES.COOKIES_PREFERENCES}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(CookiesInfo);
