import axios from "axios";
import login_backend from "./login_backend";
import { cleanAllRedux } from "../../store/actions";
import store from "../../store/storeConfig.js";
import {
  redirectToErrorPage,
  redirectToLandingPage,
} from "../Routing/RoutesRedirects";

axios.defaults.withCredentials = true;

// Personalized axios to make request to backend
let backend = axios.create({
  baseURL: process.env.REACT_APP_PULSEPLEE_BACKEND,
});

backend.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    //if no error response, backend went down or something similar so we show the error page
    if (!error.response) {
      console.log(error);
      redirectToErrorPage();
      return Promise.reject(error);
    }

    //if the error status code is 403 then we try to refresh the access token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      await login_backend.get("/refresh");

      return backend(originalRequest);
    } else if (error.response.status === 403 && originalRequest._retry) {
      store.dispatch(cleanAllRedux());
      redirectToLandingPage();
    }

    return Promise.reject(error);
  }
);

export default backend;
