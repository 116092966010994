import React from "react";
import "./HideRadarZone.css";
import ResultsButton from "../newComponents/ResultsButton";
import lock from "../../../../assets/svg/lock.svg";
import {
  redirectToMeetVioletPage,
  redirectToSignupEndpoint,
} from "../../../../core/Routing/RoutesRedirects";
import ReasonToJoinViolet from "../newComponents/ReasonToJoinViolet";
import {
  MAIN_TEXT,
  MAIN_PURPLE,
} from "../../../../utils/constants/colorsConstants";

/**
 * Functional Component: HideRadarZone
 * This is used on preliminary results page to show only a little bit of the radar graph
 *
 * @param {Object} preliminaryResultData
 * @param {Int} size
 * @param {Int} factor
 * @param {Object} copies
 * @returns {Element}
 * */
const HideRadarZone = ({
  preliminaryResultData,
  size,
  factor,
  copies: SELF_COPIES,
}) => {
  return (
    <>
      <div
        className="preliminary-hide-fade"
        style={{
          background: `linear-gradient(rgba(255, 255, 255, 0) 0, #604586 calc(${
            (size * factor) / 2
          }px + 3rem))`,
        }}
      />
      <div className="d-flex justify-content-center preliminary-hide-modal-container">
        <div
          className="preliminary-hide-modal"
          style={{
            width: "90%",
            backgroundColor: "white",
            marginTop: `calc(${(size * factor) / 2}px + 3rem)`,
          }}
        >
          <div
            style={{ color: MAIN_TEXT }}
            className="d-flex flex-column justify-content-center align-items-center text-center mb-5"
          >
            <div className="mb-4">
              <img src={lock} alt="lock" />
            </div>

            <h2 className="mb-4 ff-popp-semi-bold w-75">{SELF_COPIES.title}</h2>

            <div className="w-75 mb-4">
              {SELF_COPIES["body-part-1"]}
              <p>{SELF_COPIES["body-part-2"]}</p>
            </div>

            <ResultsButton
              onClick={() => redirectToSignupEndpoint(preliminaryResultData)}
            >
              {SELF_COPIES["signup-button-1"]}
            </ResultsButton>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center text-center my-4">
            <h2 className="ff-popp-semi-bold" style={{ paddingTop: "3rem" }}>
              {SELF_COPIES.subtitle}
            </h2>

            <div className="d-flex flex-wrap jusify-content-between pt-4">
              {SELF_COPIES.reasons_body.map((body, index) => (
                <ReasonToJoinViolet
                  image={SELF_COPIES.reasons_image[index]}
                  body={body}
                />
              ))}
            </div>

            <div
              onClick={redirectToMeetVioletPage}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                paddingBottom: "1rem",
                fontSize: "95%",
              }}
            >
              {SELF_COPIES["know-more-button"]}
            </div>

            <div>
              <ResultsButton
                onClick={() => redirectToSignupEndpoint(preliminaryResultData)}
              >
                {SELF_COPIES["signup-button-2"]}
              </ResultsButton>
            </div>

            <div style={{ color: MAIN_PURPLE }} className="mt-5 w-75">
              {SELF_COPIES["bottom-info-part-1"]}{" "}
              <p>{SELF_COPIES["bottom-info-part-2"]}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HideRadarZone;
