import {
  CREATE_RESULT,
  FETCH_RESULTS,
  INTERNAL_SIGN_OUT,
  PRELIMINARY_RESULT_DATA,
  CORE_RESULT_DATA,
  ERASE_PRELIMINARY_RESULT_DATA,
} from "../../../../store/actions/types";

/**
 * reducer for create result action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const resultsReducer = (state = {}, action) => {
  if (action.type === CREATE_RESULT) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return {};
  }
  return state;
};

/**
 * reducer for get results action
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
const fetchResultsReducer = (state = [], action) => {
  if (action.type === FETCH_RESULTS) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return [];
  }

  return state;
};

/**
 * reducer for preliminary result data action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const preliminaryResultReducer = (state = {}, action) => {
  if (action.type === PRELIMINARY_RESULT_DATA) {
    return action.payload;
  } else if (
    action.type === ERASE_PRELIMINARY_RESULT_DATA ||
    action.type === INTERNAL_SIGN_OUT
  ) {
    return {};
  }

  return state;
};

/**
 * reducer for core results action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const coreResultReducer = (state = {}, action) => {
  if (action.type === CORE_RESULT_DATA) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return {};
  }

  return state;
};

export {
  resultsReducer,
  fetchResultsReducer,
  preliminaryResultReducer,
  coreResultReducer,
};
