import React from "react";

/**
 * Functional Component: LargeTitleSection
 * A section where a title can come in in pages like PrivacyPolicy and TermsOfService
 * @param {String, Int or Elements} children
 * @returns {Element}
 */
const LargeTitleSection = ({ children }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        fontFamily: "PoppinsBold",
        padding: "10rem 0",
        backgroundColor: "#f2eff6",
      }}
    >
      <div className="display-6">{children}</div>
    </div>
  );
};

export default LargeTitleSection;
