import {
  SAVE_CORE_ANSWER,
  CLEAN_CORE_ANSWERS,
  SET_CORE_ANSWERS,
  INTERNAL_SIGN_OUT,
} from "../../../../store/actions/types";

/**
 * reducer for core answers actions
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
const coreAnswersReducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_CORE_ANSWER:
      const newState = [...state];
      newState[action.payload.questionNumber] = action.payload.answer;
      return newState;
    case SET_CORE_ANSWERS:
      return action.payload;
    case CLEAN_CORE_ANSWERS:
    case INTERNAL_SIGN_OUT:
      return [];
    default:
      return state;
  }
};

export default coreAnswersReducer;
