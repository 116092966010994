import ResultsButton from "./newComponents/ResultsButton";
import lock from "../../../assets/svg/lock.svg";
import { RegularModal } from "../../../components/modal/CustomModals";
import { redirectToSignupEndpoint } from "../../../core/Routing/RoutesRedirects";
import { useResize } from "../../Geral/hooks/useResize";
import { useScroll } from "../hooks/useScroll";

const PreliminaryResultsModal = ({
  open,
  setOpenStatus,
  copies,
  preliminaryResultData,
}) => {
  const [width, height] = useResize();

  const modalPos = useScroll(height);

  return (
    <>
      <RegularModal
        open={open}
        onChange={setOpenStatus}
        onClose={() => setOpenStatus(false)}
        canClose={width > 768}
        customStyles={{
          width: width >= 992 ? "65%" : "90%",
          top: width >= 992 ? modalPos : width > 576 ? "58%" : "53%",
        }}
        backgroundStyles={{
          background:
            width > 768
              ? "linear-gradient(var(--purple-1), rgba(255, 255, 255, 0.2) 70%), linear-gradient(rgba(255, 255, 255, 0.2) 30%, var(--purple-1))"
              : "linear-gradient(var(--purple-1), rgba(255, 255, 255, 1) 80%)",
          backgroundAttachment: "fixed",
          zIndex: 3,
        }}
      >
        <div
          className={`d-flex flex-column align-items-center ${
            width > 768 ? "py-5" : "py-4"
          }`}
        >
          <h3
            className="ff-popp-med text-center"
            style={{ fontSize: width > 768 ? "190%" : "170%" }}
          >
            {copies.TITLE}
          </h3>

          <div
            className="text-center ff-popp-med"
            style={{
              color: "var(--purple-1)",
              fontSize: width > 768 ? "115%" : "100%",
            }}
          >
            {copies.SUBTITLE}
          </div>

          <div
            style={{
              width: width > 768 ? "5rem" : "4rem",
              margin: width > 768 ? "2.5rem 0" : "1.5rem 0",
            }}
          >
            <img src={lock} alt="lock" />
          </div>

          <div
            className="text-center"
            style={{
              lineHeight: width > 768 ? "2rem" : "",
              width: width > 768 ? "60%" : "100%",
            }}
          >
            {copies.BODY_PART_1}
            {width > 576 ? " " : <br />}
            {copies.BODY_PART_2}
          </div>

          <div style={{ marginTop: "2.5rem", marginBottom: "1.5rem" }}>
            <ResultsButton
              onClick={() => redirectToSignupEndpoint(preliminaryResultData)}
            >
              {copies.BUTTON}
            </ResultsButton>
          </div>

          <div style={{ fontSize: "90%" }}>{copies.DOWN_TEXT}</div>
        </div>

        {width <= 768 && open ? (
          <div
            style={{
              color: "var(--purple-1)",
              cursor: "pointer",
              zIndex: "7",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
            onClick={() => setOpenStatus(false)}
          >
            {copies.BACK_TEXT}
          </div>
        ) : null}
      </RegularModal>
    </>
  );
};

export default PreliminaryResultsModal;
