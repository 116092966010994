import React from "react";
import "./InfoTemplate.css";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import LargeTitleSection from "./LargeTitleSection";
import Footer from "../../../components/footer/Footer";

/**
 * Functional Component: InfoTemplate
 * A template for pages like TermsOfService and PrivacyPolicy
 * @param {String, Int or Elements} children
 * @param {String} id
 * @param {String} title
 * @returns {Element}
 */
const InfoTemplate = ({ children, id, title }) => {
  return (
    <>
      <div id={id} className="info-template pb-5">
        <HeaderFirstVersion />
        <LargeTitleSection>{title}</LargeTitleSection>
        <div style={{ padding: "8rem 10% 0 10%", fontSize: "105%" }}>
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InfoTemplate;
