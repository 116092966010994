import React, { useCallback, useState } from "react";
import "./cameraStyles.css";
import Webcam from "react-webcam";
import { getStaticWebCamCopies } from "../../utils/constants/copies/copies.helper";
import { connect } from "react-redux";
const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

/**
 * Functional Component: WebcamComp
 * A web cam componenet, is used in about me so the user can take a picture of himself and upload it
 *
 * @param {String or Int} height
 * @param {String or Int} width
 * @param {Function} saveImage
 * @param {Object} currentUser
 * @param {Function} setEdit
 * @param {Function} setTakePhoto
 * @param {String} language
 * @returns {Element}
 * */
const WebcamComp = ({
  height,
  width,
  saveImage,
  currentUser,
  setEdit,
  setTakePhoto,
  language,
}) => {
  const [image, setImage] = useState("");
  const webcamRef = React.useRef(null);

  //contains all the copies for webcam component
  const SELF_COPIES = getStaticWebCamCopies({ language });

  //this captures the image
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    saveImage({
      image_name: `${currentUser.attributes.first_name}-${
        currentUser.attributes.last_name
      }-photo-${Math.floor(Math.random() * 127461287346217).toString()}`,
      url: imageSrc,
    });

    setEdit(true);
    setTakePhoto(false);

    setImage(image);

    //eslint-disable-next-line
  }, [webcamRef]);

  return (
    <div className="webcam-container d-flex flex-column align-items-center">
      <Webcam
        audio={false}
        height={height}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={width}
        videoConstraints={videoConstraints}
      />
      <div
        id="capture-button"
        onClick={(e) => {
          e.preventDefault();
          capture();
        }}
      >
        {SELF_COPIES.CAPTURE_BUTTON}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(WebcamComp);
