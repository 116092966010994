import React, { useState } from "react";
import { pickHex } from "../../../../utils/constants/colorsConstants";
import useAnimation from "../../../Geral/hooks/useAnimation";
import describePercentageCircleArc from "../../../../utils/helpers/geralHelpers/CircleArcsHelper";

const RADIUS = 200;
const PERIMETER = 2 * Math.PI * RADIUS;

/**
 * Function: cleanPercentage
 * Deals with a number to make sure it returns a number
 * @param {Int} percentage the input of the user
 * @returns {Int} the percentage
 */
const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

/**
 * Function: constructCorrectPath
 * Receives sections and return all until pct
 * @param {Int} pct
 * @param {Array} sections
 * @returns {Array}
 */
const constructCorrectPath = (pct, sections) => {
  return sections.reduce((prev, curr) => {
    const { min, max, id } = curr;

    if (pct < max && pct >= min) return [...prev, { min, max: pct, id }];
    else if (pct <= min) return [...prev];
    else return [...prev, curr];
  }, []);
};

/**
 * FunctionalComponent: StaticCircle
 * A circle with no behaviour
 * @param {String} colour
 * @returns {Element}
 */
const StaticCircle = ({ colour }) => {
  return (
    <circle
      r={RADIUS}
      strokeDasharray={PERIMETER}
      fill="transparent"
      stroke={colour}
      strokeWidth={20}
      strokeLinecap="round"
      strokeDashoffset={0}
    />
  );
};

/**
 * FunctionalComponent: FillCircle
 * A circle that is fillable by percentage, with animation and divided by sections
 * @param {String or Int} percentage
 * @param {Function} animation
 * @param {Array} sections
 * @returns {Element}
 */
const FillCircle = ({ percentage, animation, sections }) => {
  const [currentPosition, setPosition] = useState(0);
  animation((currentFrame) => {
    setPosition(currentFrame * percentage);
  });

  return (
    <>
      <defs>
        {sections.map((section) => {
          const { min, max, id, minColor, maxColor, props } = section;
          let actualColor = minColor;
          if (percentage >= min && percentage <= max) {
            const middle = (percentage - min) / (max - min);
            actualColor = pickHex(minColor, maxColor, middle);
          }
          return (
            <linearGradient
              {...props}
              key={id}
              id={id}
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0%" stopColor={actualColor} />
              <stop offset="100%" stopColor={maxColor} />
            </linearGradient>
          );
        })}
      </defs>

      <g strokeWidth={20} strokeLinecap="round" strokeDasharray={PERIMETER}>
        {constructCorrectPath(percentage, sections).map(({ min, max, id }) => {
          return (
            <path
              key={id}
              d={describePercentageCircleArc(
                min,
                Math.max(min, Math.min(currentPosition, max))
              )}
              stroke={`url(#${id})`}
            />
          );
        })}
      </g>
    </>
  );
};

/**
 * FunctionalComponent: Pointer
 * A pointer situated somewhere in the graph
 * @param {String} colour: the color of the point
 * @param {Int} startPoint: where the pointer is
 * @param {Function} animation: the animation it does until it reaches startPoint
 * @returns {Element}
 */
const Pointer = ({ colour, startPoint, animation }) => {
  const strokePct = ((100 - 0.1) * PERIMETER) / 100; // where stroke will start, e.g. from 15% to 100%.

  const [currentRotation, setRotation] = useState(269.5);
  animation((currentFrame) => {
    setRotation((currentFrame * startPoint * 18) / 5 + 269.5);
  });

  return (
    <circle
      transform={`rotate(${currentRotation})`}
      r={RADIUS}
      fill="transparent"
      stroke={strokePct !== PERIMETER ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={12}
      strokeLinecap="round"
      strokeDasharray={PERIMETER}
      strokeDashoffset={strokePct}
    />
  );
};

/**
 * FunctionalComponent: Marker
 * A marker situated somewhere in a circle
 * @param {String} colour: the colour of the marker
 * @param {Int} startPoint: where the marker is
 * @returns {Element}
 */
const Marker = ({ colour, startPoint }) => {
  const strokePct = ((100 - 0.1) * PERIMETER) / 100; // where stroke will start, e.g. from 15% to 100%.
  const rotation = 270 + (18 * startPoint) / 5 - 0.5;

  return (
    <>
      <circle
        transform={`rotate(${rotation})`}
        r={RADIUS}
        fill="transparent"
        stroke={strokePct !== PERIMETER ? colour : ""} // remove colour as 0% sets full circumference
        strokeWidth={20}
        strokeLinecap="line"
        strokeDasharray={PERIMETER + 2}
        strokeDashoffset={strokePct}
      />
    </>
  );
};

/**
 * Functional Component: PercentageCircle
 * A circle that is fillable
 * @param {Int} percentage: the point until it is filled
 * @param {Array} markers: markers to be rendered
 * @param {Array} sections: how to graph is sectionized: each seciton has a min, max and id
 * @param {Object} animationProps: the props to feed useAnimation, that is responsible for coordinating every part of the PercentageCircle
 * @returns {Element}
 */
const PercentageCircle = ({
  percentage = 0,
  markers = [],
  sections = [],
  animationProps = {},
}) => {
  const pct = cleanPercentage(percentage);
  const animation = useAnimation(animationProps);

  return (
    <g transform="translate(311, 298)">
      <g filter="url(#bhhjup5z7a)">
        <StaticCircle colour="#E8E8E8" />
      </g>
      <FillCircle percentage={pct} animation={animation} sections={sections} />
      <Pointer colour="white" startPoint={pct} animation={animation} />

      {markers
        .filter((marker) => pct >= marker)
        .map((marker) => {
          return <Marker key={marker} colour="white" startPoint={marker} />;
        })}
    </g>
  );
};

export default PercentageCircle;
