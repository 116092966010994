import { noop } from "../../utils/helpers/geralHelpers/RandomHelper";

//contains all the types and themes regarding flash alerts
const FLASH_TYPES = {
  MESSENGER_APP: { type: "messenger" },
  EMAIL_NOT_VERIFIED: { type: "email-not-verified" },
  SURVEY_SUBMIT_ERROR: { type: "survey-submit-error" },
  SAMPLE: {
    active: true,
    delay: 3000,
    type: "sample-flash",
    theme: "purple",
    onClose: noop,
  },

  START_DATE_AFTER_END_DATE: { type: "start-date-end-date" },
  END_DATE_BEFORE_START_DATE: { type: "end-date-start-date" },
  DATE_BIGGER_THAN_MAX: { type: "date-bigger-than-max", theme: "red" },
  DATE_SMALLER_THAN_MIN: { type: "date-bigger-than-max", theme: "red" },

  ABOUT_ME_EMAIL_ALREADY_EXISTS: {
    type: "about-me-email-exists",
    theme: "red",
  },
  ABOUT_ME_INVALID_FIRST_NAME: {
    type: "about-me-first-name-exists",
    theme: "red",
  },
  ABOUT_ME_INVALID_LAST_NAME: {
    type: "about-me-last-name-exists",
    theme: "red",
  },
  ABOUT_ME_BIRTHDATE_PREFIX: { type: "about-me-birthdate", theme: "red" },
  ABOUT_ME_BIRTHDATE_BIGGER_NON_TYPED: { type: "about-me-birthdate-blank" },
  ABOUT_ME_BIRTHDATE_BIGGER_THAN_MAX: { type: "about-me-birthdate-max" },
  ABOUT_ME_BIRTHDATE_SMALLER_THAN_MIN: { type: "about-me-birthdate-min" },
  BIG_PICK_PROFILE_PHOTO: { type: "big-pick-profile-image" },
  BIG_TAKE_PROFILE_PHOTO: { type: "big-take-profile-data" },
  DELETE_PROFILE_PHOTO: { type: "delete-profile-photo" },
  CHANGE_PASSWORD: { type: "change-password" },
  CHANGE_EMAIL: { type: "change-email" },
  CHANGE_ABOUT_ME_FIELD: { type: "change-about-me-field" },
  UNEXPECTED_ERROR: { type: "unexpected-error" },
  DELETE_ACCOUNT: { type: "delete-account", delay: 4000 },
};

export default FLASH_TYPES;
