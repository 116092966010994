import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./core/Routing/AppContainer";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/storeConfig";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

//starts sentry
if (process.env.REACT_APP_IS_SERVER && !process.env.REACT_APP_IS_TEST) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppContainer />
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
