/**
 * returns the current question answers
 * @param {Array} externalAnswers
 * @param {Array} answers
 * @param {Int} questionId
 * @returns {Array}
 */
const getQuestionAnswers = (externalAnswers, answers, questionId) => {
  const thisQuestionAnswers = externalAnswers ?? answers;

  /*return thisQuestionAnswers.sort((a, b) => {
    if (a.attributes.value > b.attributes.value) {
      return 1;
    }
    if (a.attributes.value < b.attributes.value) {
      return -1;
    }
    return 0;
  });*/

  return thisQuestionAnswers;
};

/**
 * renders the square answer labels
 * @param {Array} answers
 * @param {Int} questionId
 * @returns {Array}
 */
const renderLabels = (answers, questionId) => {
  return answers
    .filter((answer) => {
      return answer.attributes.question_id.toString() === questionId.toString();
    })
    .filter((answer) => {
      return answer.attributes.label !== ""; // change to Regex
    })
    .map((answer) => {
      return (
        <span key={answer.id} className={`option-label`}>
          {answer.attributes.label}
        </span>
      );
    });
};

export { getQuestionAnswers, renderLabels };
