import { INTERNAL_SIGN_OUT, SAVE_ROLES } from "../../../../store/actions/types";

/**
 * reducer for user roles action
 * @param {Array} state
 * @param {Object} action
 * @returns {Object}
 */
const rolesReducer = (state = [], action) => {
  if (action.type === SAVE_ROLES) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return [];
  }

  return state;
};

export { rolesReducer };
