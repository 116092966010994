import React from "react";
import disabledRightArrow from "../../assets/svg/disabledRightArrow.svg";
import disabledLeftArrow from "../../assets/svg/disabledLeftArrow.svg";
import enabledLeftArrow from "../../assets/svg/enabledLeftArrow.svg";
import rightArrowHover from "../../assets/svg/rightArrowHover.svg";
import leftArrowHover from "../../assets/svg/leftArrowHover.svg";
import { CustomTooltip } from "../modal/CustomTooltips";
import { useResize } from "../../modules/Geral/hooks/useResize";
import { getStaticProgressBarCopies } from "../../utils/constants/copies/copies.helper";
import { connect } from "react-redux";

/**
 * Functional Component: PurplseProgressBar
 * The purple progress bar used on surveys for progress description
 *
 * @param {Function} forwardClickFunction
 * @param {Function} backClickFunction
 * @param {Int} totalQuestions
 * @param {Boolean} onWelcome
 * @param {Boolean} onTrial
 * @param {Int} questionNumber
 * @param {Boolean} onCore
 * @param {Boolean} canMoveForward
 * @param {String} language
 * @returns {Element}
 * */
const PurpleProgressBar = ({
  forwardClickFunction,
  backClickFunction,
  totalQuestions,
  onWelcome,
  onTrial,
  questionNumber,
  onCore,
  canMoveForward,
  language,
}) => {
  //contains all the copies for the progress bar
  const SELF_COPIES = getStaticProgressBarCopies({ language });

  /**
   * get the width of the progress indicator depending on the number of questions and how many have been answered
   * @returns {String}
   * */
  const getProgressIndicatorWidth = () => {
    let value = 0;

    if (onWelcome) {
      value = questionNumber + 1;
    } else if (onTrial || onCore) {
      value = questionNumber + 3;
    }

    value *= 100 / totalQuestions;

    if (value > 100) return "100%";

    return value.toString() + "%";
  };

  const [width] = useResize();

  return (
    <div>
      <div className="d-flex align-items-center" id="progress-bar-container">
        {questionNumber > 0 ? (
          <img
            src={enabledLeftArrow}
            id="go-back-icon"
            alt="go back"
            onClick={backClickFunction}
            onMouseOver={(e) => (e.currentTarget.src = leftArrowHover)}
            onMouseOut={(e) => (e.currentTarget.src = enabledLeftArrow)}
          />
        ) : (
          <img src={disabledLeftArrow} id="go-back-icon" alt="go back" />
        )}

        <div id="progress-bar">
          <div
            style={{ width: getProgressIndicatorWidth() }}
            id="progress-indicator"
          />
        </div>

        <div
          className="progress-bar-tooltip-container"
          style={{ position: "relative" }}
        >
          <CustomTooltip
            open={questionNumber === 1 && onWelcome && canMoveForward}
            arrow={width > 767 ? "top right" : "top right"}
          >
            <div className="custom-tooltip-text-progress-bar">
              {SELF_COPIES.TOOLTIP}
            </div>
          </CustomTooltip>

          {(canMoveForward && onTrial && questionNumber < 9) ||
          (onCore && questionNumber < 13 && canMoveForward) ||
          (canMoveForward && onWelcome) ? (
            <img
              src="images/goForwardIcon.svg"
              id="go-forward-icon"
              alt="go forward"
              onClick={forwardClickFunction}
              onMouseOver={(e) => (e.currentTarget.src = rightArrowHover)}
              onMouseOut={(e) =>
                (e.currentTarget.src = "images/goForwardIcon.svg")
              }
            />
          ) : (questionNumber >= 9 && onTrial) ||
            (questionNumber >= 13 && onCore) ? null : (
            <img src={disabledRightArrow} id="go-back-icon" alt="go back" />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(PurpleProgressBar);
