/**
 * Function: describeArc
 * Describes an arc of a circle, from an angle to another angle
 *
 * @param {*} x: starting x
 * @param {*} y: starting y
 * @param {*} radius: radius of the cricle
 * @param {*} startAngle
 * @param {*} endAngle
 * @returns
 */
function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

// converts a percentage to an angle of a circle
const getAngleFromPct = (pct) => (pct * 18) / 5;

// converts a polar coordinate to a cartesian coordinate
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

// describes an arc from a percentage to another percentage
const describePercentageCircleArc = (startScore, endScore) =>
  describeArc(
    0,
    0,
    200,
    getAngleFromPct(startScore),
    getAngleFromPct(endScore)
  );

export default describePercentageCircleArc;
