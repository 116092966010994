import React from "react";
import "./TipsInfo.css";
import TipCard from "../../components/TipCard";
import { MAIN_TEXT } from "../../utils/constants/colorsConstants";
import { SHARE_RESULTS_PAGE_ID } from "../../utils/constants/constants";

/**
 * Functional Component: TipsInfo
 * The component that renders the tips inside welcome back, results and analytics pages
 * @param {Object} tips: object with the properties lowestTip and highestTip
 * @param {Object} copies
 * @param {Object} dimensions
 * @returns {Element}
 * */
const TipsInfo = ({ tips, copies, dimensions, page, shareResultGender }) => {
  if (page === SHARE_RESULTS_PAGE_ID && shareResultGender) {
    if (shareResultGender === "M") {
      copies.title = copies.title.replace("o/a", "o");
    } else if (shareResultGender === "F") {
      copies.title = copies.title.replace("o/a", "a");
    }
  }

  return (
    <div className="pb-5" style={{ backgroundColor: "#f9f8fc" }} id="tips-info">
      <div className="text-center d-flex justify-content-center">
        <h3
          style={{ color: MAIN_TEXT }}
          className="fw-bold tips-info__title ff-popp-med"
        >
          {copies.title}
        </h3>
      </div>

      <div className="mx-auto d-flex flex-column flex-lg-row tips-info__tips">
        <div className="col-12 col-lg-6 mb-4 tips-info__focus-tip">
          <h5
            name="focus-dimension-title"
            className="fw-bold text-center ff-popp-med tip-dimension-title"
          >
            {dimensions.LOWEST}
          </h5>

          <div className="d-flex justify-content-center align-items-center">
            <TipCard tip={tips.lowestTip} />
          </div>
        </div>

        <div className="col-12 col-lg-6 tips-info__excellence-tip">
          <h5
            name="excellence-dimension-title"
            className="fw-bold text-center ff-popp-med tip-dimension-title"
          >
            {dimensions.HIGHEST}
          </h5>

          <div className="d-flex justify-content-center align-items-center">
            <TipCard tip={tips.highestTip} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipsInfo;
