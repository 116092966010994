import backend from "../../../../core/apis/backend";
import {
  CLEAN_TEMPORARY_SCORE,
  FETCH_TEMPORARY_SCORE,
} from "../../../../store/actions/types";

/**
 * action to fetch temporary score
 * @param {String} userId
 * @param {String} surveyId
 * @returns {Object}
 */
const fetchTemporaryScore = (userId, surveyId) => {
  return async (dispatch) => {
    let final = null;

    try {
      const { data } = await backend.get(
        "/temporary_scores/" + userId + "/" + surveyId
      );
      final = data;
    } catch (error) {}

    dispatch({
      type: FETCH_TEMPORARY_SCORE,
      payload: final,
    });
  };
};

/**
 * cleans temporary score from redux
 * @returns {Object}
 */
const cleanTemporaryScore = () => {
  return {
    type: CLEAN_TEMPORARY_SCORE,
  };
};

export { fetchTemporaryScore, cleanTemporaryScore };
