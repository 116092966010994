import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Footer from "../../../components/footer/Footer";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import GreyBackgroundRectangle from "../../../components/rectangles/GreyBackgroundRectangle";
import { redirectToAnalyticsPage } from "../../../core/Routing/RoutesRedirects";
import { MAIN_PURPLE } from "../../../utils/constants/colorsConstants";
import { MEET_VIOLET_PAGE_ID } from "../../../utils/constants/constants";
import { detectMessenger } from "../../../utils/helpers/geralHelpers/messengerDetector";
import { getShareURL } from "../../../utils/helpers/geralHelpers/RandomHelper";
import {
  isThereCoreSurveyResultToday,
  isThereWelcomingSurveyResultsToday,
} from "../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import chooseNextPath from "../../Auth/services/chooseNextPath";
import {
  renderEmailShareIconForApp,
  renderFacebookMessengerShareIcon,
  renderWhatsAppShareIcon,
} from "../../Results/components/ExtraRenders";
import GuestVersion from "../../Results/components/infoParts/ShareInfoGuestVersion";
import { useResize } from "../hooks/useResize";
import { setFlashProps } from "../store/actions/flashActions";
import "./MeetViolet.css";

import {
  getStaticMeetVioletCopies,
  getStaticShareCopies,
} from "../../../utils/constants/copies/copies.helper";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import { createAnalytic } from "../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: MeetViolet
 * This is the meet violet page and contains all info about violet
 *
 * @param {Object} currentUser
 * @param {Function} setFlashProps
 * @param {String} language
 * @returns {Element}
 * */
const MeetViolet = ({ currentUser, setFlashProps, language }) => {
  const [canAnswerSurvey, setSurveyAllowance] = useState(false);

  //contains all copies for the meet violet page
  const SELF_COPIES = getStaticMeetVioletCopies({ language });

  //contains all copies for the share info section
  const SHARE_INFO_COPIES = getStaticShareCopies({ language });

  useResize();

  //the url for app sharing
  const appShareUrl = getShareURL(currentUser, language);

  //renders the messenger image for the messenger share button
  const messengerImage = (
    <img
      alt=""
      src="images/messengerIconWhiteBackground.png"
      srcSet="images/messengerIconWhiteBackground2x.png, images/messengerIconWhiteBackground3x.png"
      className="share-icons"
      onClick={
        window.innerWidth < 992
          ? () => {
              detectMessenger(
                "",
                "",
                appShareUrl + "&social=msn&mode=app"
              ).then((hasMessenger) => {
                if (!hasMessenger) setFlashProps(FLASH_TYPES.MESSENGER_APP);

                createAnalytic(EVENTS.INVITE_MESSENGER_MEET);
              });
            }
          : () => {
              createAnalytic(EVENTS.INVITE_MESSENGER_MEET);
            }
      }
    ></img>
  );

  //renders the whatsapp image for the whatsapp share button
  const whatsappImage = (
    <img
      alt=""
      src="images/whatsappIconWhiteBackground.png"
      srcSet="images/whatsappIconWhiteBackground2x.png, images/whatsappIconWhiteBackground3x.png"
      className="share-icons"
    ></img>
  );

  //renders the email image for the email share button
  const emailImage = (
    <img
      alt=""
      src="images/emailIconWhiteBackground.png"
      srcSet="images/emailIconWhiteBackground2x.png, images/emailIconWhiteBackground3x.png"
      className="share-icons"
      onClick={() => createAnalytic(EVENTS.INVITE_EMAIL_MEET)}
    ></img>
  );

  //if there is current user checks if the user has answered the core survey today to enable or disable the continue to survey button
  useEffect(() => {
    if (!currentUser) return;

    const setSurvey = async () => {
      const survey =
        !(await isThereCoreSurveyResultToday(currentUser.attributes.user_id)) ||
        !(await isThereWelcomingSurveyResultsToday(
          currentUser.attributes.user_id
        ));
      setSurveyAllowance(survey);
    };

    setSurvey();
  }, [currentUser]);

  /**
   * renders the fourth meet violet rectangle for desktop version
   * @returns {Element}
   */
  const renderBigFourthRectangleSection = () => {
    return (
      <div
        className="d-flex align-items-center flex-lg-column justify-content-center"
        id="fourth-rectangle"
      >
        <h3
          className="d-flex align-items-center justify-content-center"
          id="fourth-rectangle-title"
        >
          {SELF_COPIES["self-care-priority-title"]}
        </h3>
        <div className="d-flex justify-content-around w-100">
          <div>
            <img
              alt=""
              src="images/sittingMen.png"
              srcSet="images/sittingMen2x.png, images/sittingMen3x.png"
              className="fourth-section-images"
            ></img>
          </div>
          <div>
            <img
              alt=""
              src="images/arrowsAndBars.png"
              srcSet="images/arrowsAndBars2x.png, images/arrowsAndBars3x.png"
              className="fourth-section-images"
            ></img>
          </div>
          <div>
            <img
              alt=""
              src="images/handsAndHeart.png"
              srcSet="images/handsAndHeart2x.png, images/handsAndHeart3x.png"
              className="fourth-section-images"
            ></img>
          </div>
        </div>
        <div className="d-flex justify-content-around w-100">
          <h4 className="fourth-section-small-titles">
            {SELF_COPIES["self-cafe-priority-first-subtitle"]}
          </h4>
          <h4 className="fourth-section-small-titles">
            {SELF_COPIES["self-cafe-priority-second-subtitle"]}
          </h4>
          <h4 className="d-flex fourth-section-small-titles">
            {SELF_COPIES["self-cafe-priority-third-subtitle"]}
          </h4>
        </div>
        <div
          id={currentUser ? "" : "fourth-rectangle-bottom-section"}
          className={`d-flex justify-content-around w-100 align-items-start ${
            currentUser ? "mb-3" : ""
          }`}
        >
          <div className="fourth-rectangle-description upper-descriptions">
            {SELF_COPIES["self-cafe-priority-first-text"]}
          </div>
          <div className="fourth-rectangle-description upper-descriptions">
            {SELF_COPIES["self-cafe-priority-second-text"]}
          </div>
          <div className="fourth-rectangle-description">
            {SELF_COPIES["self-cafe-priority-third-text"]}
          </div>
        </div>
        {currentUser ? (
          <div
            id={currentUser ? "fourth-rectangle-bottom-section" : ""}
            className="d-flex justify-content-around align-items-start w-100"
          >
            <div className="d-flex flex-column align-items-center justify-content-center fourth-rectangle-buttons">
              <div
                onMouseOver={(e) => {
                  if (canAnswerSurvey) {
                    e.currentTarget.style.backgroundColor = "#573C7C";
                  }
                }}
                onMouseOut={(e) => {
                  if (canAnswerSurvey) {
                    e.currentTarget.style.backgroundColor = MAIN_PURPLE;
                  }
                }}
                onClick={
                  canAnswerSurvey
                    ? () => {
                        document.getElementById(
                          "take-the-survey-button"
                        ).style.backgroundColor = "#CDBAE7";
                        chooseNextPath(currentUser);
                      }
                    : null
                }
                style={{ backgroundColor: !canAnswerSurvey ? "gray" : null }}
                id="take-the-survey-button"
              >
                {SELF_COPIES["take-survey-button"]}
              </div>
              {canAnswerSurvey ? null : (
                <div id="disabled-survey-button-text">
                  {SELF_COPIES["already-answered"]}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center fourth-rectangle-buttons">
              <div
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#573C7C";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = MAIN_PURPLE;
                }}
                onClick={() => {
                  document.getElementById(
                    "review-results-button"
                  ).style.backgroundColor = "#CDBAE7";
                  redirectToAnalyticsPage();

                  createAnalytic(EVENTS.VIEW_ANALYTICS_MEET);
                }}
                id="review-results-button"
              >
                {SELF_COPIES["review-results-button"]}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center fourth-rectangle-buttons">
              <div id="invite-sentence">
                {SELF_COPIES["self-care-invite-text"]}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  {renderFacebookMessengerShareIcon({
                    appShareUrl: appShareUrl,
                    isApp: true,
                    img: messengerImage,
                    setFlashProps,
                  })}
                </div>
                <div>
                  <div>
                    {renderWhatsAppShareIcon({
                      appShareUrl: appShareUrl,
                      isApp: true,
                      img: whatsappImage,
                    })}
                  </div>
                </div>
                <div>
                  {renderEmailShareIconForApp({
                    appShareUrl: appShareUrl,
                    img: emailImage,
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  /**
   * renders the fourth meet violet rectangle for tablet and mobile version
   * @returns {Element}
   */
  const renderSmallFourthRectangleSection = () => {
    return (
      <div
        className="d-flex align-items-center flex-column justify-content-center"
        id="fourth-rectangle"
      >
        <h3
          className="d-flex align-items-center justify-content-center"
          id="fourth-rectangle-title"
        >
          {SELF_COPIES["self-care-priority-title"]}
        </h3>
        <div>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <div>
              <img
                alt=""
                src="images/sittingMen.png"
                srcSet="images/sittingMen2x.png, images/sittingMen3x.png"
                className="fourth-section-images"
                id="first-small-section-image"
              ></img>
            </div>
            <h4
              style={{ width: "70%" }}
              className="fourth-section-small-titles"
            >
              {SELF_COPIES["self-cafe-priority-first-subtitle"]}
            </h4>
            <div className="fourth-rectangle-description upper-descriptions description-text ">
              {SELF_COPIES["self-cafe-priority-first-text"]}
            </div>
            {currentUser ? (
              <div className="d-flex flex-column align-items-center justify-content-center fourth-rectangle-buttons mt-4">
                <div
                  onMouseOver={(e) => {
                    if (canAnswerSurvey) {
                      e.currentTarget.style.backgroundColor = "#573C7C";
                    }
                  }}
                  onMouseOut={(e) => {
                    if (canAnswerSurvey) {
                      e.currentTarget.style.backgroundColor = MAIN_PURPLE;
                    }
                  }}
                  onClick={
                    canAnswerSurvey ? () => chooseNextPath(currentUser) : null
                  }
                  id="take-the-survey-button"
                  style={{ backgroundColor: !canAnswerSurvey ? "gray" : null }}
                >
                  {SELF_COPIES["take-survey-button"]}
                </div>
                {canAnswerSurvey ? null : (
                  <div id="disabled-survey-button-text">
                    {SELF_COPIES["already-answered"]}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div>
              <img
                alt=""
                src="images/arrowsAndBars.png"
                srcSet="images/arrowsAndBars2x.png, images/arrowsAndBars3x.png"
                className="fourth-section-images"
              ></img>
            </div>
            <h4
              style={{ width: "70%" }}
              className="fourth-section-small-titles"
            >
              {SELF_COPIES["self-cafe-priority-second-subtitle"]}
            </h4>
            <div className="fourth-rectangle-description upper-descriptions description-text">
              {SELF_COPIES["self-cafe-priority-second-text"]}
            </div>
            {currentUser ? (
              <div className="d-flex justify-content-center fourth-rectangle-buttons mt-4">
                <div
                  onClick={redirectToAnalyticsPage}
                  id="review-results-button"
                >
                  {SELF_COPIES["review-results-button"]}
                </div>
              </div>
            ) : null}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
            <div>
              <img
                alt=""
                src="images/handsAndHeart.png"
                srcSet="images/handsAndHeart2x.png, images/handsAndHeart3x.png"
                className="fourth-section-images"
              ></img>
            </div>
            <h4
              className="d-flex flex-column align-items-center fourth-section-small-titles"
              style={{ width: "70%" }}
            >
              {SELF_COPIES["self-cafe-priority-third-subtitle"]}
            </h4>
            <div className="fourth-rectangle-description description-text">
              {SELF_COPIES["self-cafe-priority-third-text"]}
            </div>
            {currentUser ? (
              <div className="d-flex flex-column justify-content-center align-items-center fourth-rectangle-buttons mt-4">
                <div id="invite-sentence">
                  {SELF_COPIES["self-care-invite-text"]}
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    {renderFacebookMessengerShareIcon({
                      appShareUrl: appShareUrl,
                      isApp: true,
                      img: messengerImage,
                      setFlashProps,
                    })}
                  </div>
                  <div>
                    {renderWhatsAppShareIcon({
                      appShareUrl: appShareUrl,
                      isApp: true,
                      img: whatsappImage,
                    })}
                  </div>
                  <div>
                    {renderEmailShareIconForApp({
                      appShareUrl: appShareUrl,
                      img: emailImage,
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="meet-violet-container">
      <HeaderFirstVersion meetViolet={true} />
      <GreyBackgroundRectangle>
        <div className="greyBackgroundPageTitle">
          {SELF_COPIES["big-title"]}
        </div>
      </GreyBackgroundRectangle>
      <div
        id="second-rectangle"
        className="d-flex flex-column flex-lg-row align-items-center justify-content-between"
      >
        <div
          id="second-rectangle-left-section"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <h2
            className={
              window.innerWidth < 992 ? "d-flex justify-content-center" : ""
            }
            id="second-rectangle-left-section-title"
          >
            {SELF_COPIES["what-is-violet-title"]}
          </h2>
          <div className="mt-4" id="second-rectangle-left-section-description">
            <p class="description-text">
              {SELF_COPIES["what-is-violet-body-part-1"]}
            </p>
            <p class="description-text">
              {SELF_COPIES["what-is-violet-body-part-2"]}
            </p>
          </div>
        </div>
        <div
          id="second-rectangle-right-section"
          className="d-flex justify-content-center align-items-center"
        >
          <img
            alt=""
            src="images/yogaGirlV.png"
            srcSet="images/yogaGirlV2x.png, images/yogaGirlV3x.png"
            id="second-rectangle-image"
          ></img>
        </div>
      </div>
      <div
        id="third-rectangle"
        className="d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between"
      >
        <div
          className="d-flex justify-content-center align-items-center"
          id="third-rectangle-left-section"
        >
          <img
            src="images/yoga-guy.png"
            srcSet="images/yoga-guy2x.png, images/yoga-guy3x.png"
            className="firstImage Artboard-102x-100-1"
            alt="logo"
            id="third-rectangle-image"
          ></img>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          id="third-rectangle-right-section"
        >
          <h2
            className={
              window.innerWidth < 992 ? "d-flex justify-content-center" : ""
            }
            id="third-rectangle-right-section-title"
          >
            {SELF_COPIES["wellbeing-framework-title"]}
          </h2>
          <div className="mt-4" id="third-rectangle-right-section-description">
            <p class="description-text">
              {SELF_COPIES["wellbeing-framework-body-part-1"]}
            </p>
            <p class="description-text">
              <p id="why-purpose">
                {SELF_COPIES["wellbeing-framework-subtitle"]}
              </p>
              {SELF_COPIES["wellbeing-framework-body-part-2"]}
              <p id="purple-reference-sentencer">
                {SELF_COPIES["wellbeing-framework-body-conclusion-part-1"]}{" "}
                <span
                  onClick={() => {
                    window.open("https://purposebypeople.com", "_blank");
                    createAnalytic(EVENTS.VISIT_PURPLE_MEET);
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  pur’ple
                </span>
                , {SELF_COPIES["wellbeing-framework-body-conclusion-part-2"]}{" "}
                <span
                  onClick={() => {
                    window.open("https://purposebypeople.com", "_blank");
                    createAnalytic(EVENTS.VISIT_PURPLE_MEET);
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  pur’ple
                </span>
                {[SELF_COPIES["wellbeing-framework-body-conclusion-part-3"]]}
                <span
                  style={{ fontStyle: language === "pt-PT" ? "italic" : null }}
                >
                  {[SELF_COPIES["wellbeing-framework-body-conclusion-part-4"]]}
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>
      {window.innerWidth < 992
        ? renderSmallFourthRectangleSection()
        : renderBigFourthRectangleSection()}
      {currentUser ? null : (
        <GuestVersion
          page={MEET_VIOLET_PAGE_ID}
          copies={SHARE_INFO_COPIES.GUEST_VERSION}
        />
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, language: state.language };
};

export default connect(mapStateToProps, { setFlashProps })(MeetViolet);
