import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchUserWithAllAttributes,
  setFlashProps,
} from "../../../store/actions";
import {
  CORE_SURVEY_ID,
  MY_ANALYTICS_PAGE_ID,
} from "../../../utils/constants/constants";
import { getStaticAnalyticsFiltersCopies } from "../../../utils/constants/copies/copies.helper";
import {
  fetchTimeButtonsObject,
  fetchUserSurveyLastResult,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import { RenderAllCurrentComparisonSlots } from "../../Results/components/RenderFilters";
import {
  getGraphRange,
  getSliderRange,
} from "../services/analyticsComparisonZone.service";
import AnalyticsGraph from "./AnalyticsGraph";
import NewTimeButtons from "./NewTimeButtons";

/**
 * this component contains all the progress overtime section of analytics
 * @param {Function} fetchUserWithAllAttributes
 * @param {Object} currentUser
 * @param {Function} setFlashProps
 * @param {Function} setPage
 * @param {String} language
 * @returns {Element}
 */
const NewAnalyticsComparisonZone = ({
  fetchUserWithAllAttributes,
  currentUser,
  setFlashProps,
  setPage,
  language,
  copies,
}) => {
  const [timeButtonsObject, setTimeButtonsObject] = useState({});
  const [resultsByPeriodsObject, setResultsByPeriodsObject] = useState({});

  const [timeButtonSelection, selectTimeButton] = useState({});
  const [todaySelection, selectToday] = useState(0);
  const [userLastResult, setUserLastResult] = useState({});

  const [sliderRange, setSliderRange] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [lastChoosenInput, setLastChoosenInput] = useState(null);
  const [error, setError] = useState({
    minAge: null,
    maxAge: null,
    country: null,
    gender: null,
  });
  const [isThereGlobalData, setIsThereGlobalData] = useState(true);
  const [isThereActiveFilters, setIsThereActiveFilters] = useState(false);
  const [blockedIntervals, setBlockedIntervals] = useState({
    week: false,
    month: false,
    semester: false,
    year: false,
  });

  const [canChange, setCanChange] = useState(false);

  //called everytime any filter changes
  const onFiltersChange = useCallback((newSpreadedResultsObject) => {
    setResultsByPeriodsObject(newSpreadedResultsObject);
  }, []);

  //called when loader needs to be rendered
  const changeLoader = useCallback((value) => {
    setShowLoader(value);
  }, []);

  //setups the time buttons and the user last result when the component renders
  useEffect(() => {
    async function setupTimeButtons() {
      const newTimeButtonsObject = await fetchTimeButtonsObject(
        currentUser.attributes.user_id
      );

      setTimeButtonsObject(newTimeButtonsObject);
      selectTimeButton(newTimeButtonsObject.week);

      const newUserLastResult = await fetchUserSurveyLastResult(
        CORE_SURVEY_ID,
        currentUser.attributes.user_id
      );
      setUserLastResult(newUserLastResult);
    }

    setupTimeButtons();
  }, [currentUser.attributes.user_id]);

  // whenever one selects a time button, the slider range changes
  useEffect(() => {
    if (Object.keys(timeButtonSelection).length === 0) return;

    const newSliderRange = getSliderRange({
      dateRange: timeButtonsObject[timeButtonSelection.value].dateRange,
      periodsNumber: timeButtonsObject[timeButtonSelection.value].periodsNumber,
    });

    setSliderRange(newSliderRange);
    selectToday(newSliderRange.length - 1);
  }, [timeButtonSelection, timeButtonsObject]);

  // whenever one selects a time button or slides the slider, change the graph range
  useEffect(() => {
    if (
      Object.keys(timeButtonSelection).length === 0 ||
      Object.keys(resultsByPeriodsObject).length === 0
    )
      return;

    const dateRange = resultsByPeriodsObject[timeButtonSelection.value];

    const newGraphData = getGraphRange({
      dateRange,
      periodsNumber: timeButtonsObject[timeButtonSelection.value].periodsNumber,
      sliderSelection: todaySelection,
      sliderRangeLength: sliderRange.length,
    });

    setGraphData(newGraphData);
  }, [
    timeButtonsObject,
    resultsByPeriodsObject,
    timeButtonSelection,
    todaySelection,
    sliderRange.length,
  ]);

  //when the graph loads checks wich time period has at least 2 or more data points and displays that one
  useEffect(() => {
    let blockedIntervalsNew = { ...blockedIntervals };

    if (
      firstTime === true &&
      Object.keys(timeButtonSelection).length !== 0 &&
      Object.keys(resultsByPeriodsObject).length !== 0
    ) {
      let keepLooping = true;
      let options = ["week", "month", "semester", "year"];
      let optionsCounter = 0;
      let decidingFactor = 2;

      while (keepLooping) {
        if (optionsCounter >= 4) {
          if (decidingFactor === 2) {
            optionsCounter = 0;
            decidingFactor = 1;
            continue;
          } else {
            setPage("#no-data");
            setFirstTime(false);
            keepLooping = false;
            break;
          }
        }

        const dateRange = resultsByPeriodsObject[options[optionsCounter]];

        const newGraphData = getGraphRange({
          dateRange,
          periodsNumber:
            timeButtonsObject[timeButtonSelection.value].periodsNumber,
          sliderSelection: todaySelection,
          sliderRangeLength: sliderRange.length,
        });

        let dataCounter = 0;

        for (let data of newGraphData) {
          if (data.userData.value) {
            dataCounter += 1;
          }
        }

        if (dataCounter >= decidingFactor) {
          setGraphData(newGraphData);
          setFirstTime(false);
          selectTimeButton(timeButtonsObject[options[optionsCounter]]);
          keepLooping = false;
        } else if (dataCounter === 0) {
          blockedIntervalsNew[options[optionsCounter]] = true;
          optionsCounter += 1;
        } else {
          optionsCounter += 1;
        }
      }
    }

    setBlockedIntervals(blockedIntervalsNew);
    // eslint-disable-next-line
  }, [
    firstTime,
    timeButtonSelection,
    timeButtonsObject,
    resultsByPeriodsObject,
    sliderRange.length,
    todaySelection,
  ]);

  //checks the results by periods object to see if global data exists
  useEffect(() => {
    if (
      resultsByPeriodsObject &&
      Object.keys(resultsByPeriodsObject).length !== 0 &&
      canChange
    ) {
      let globalDataNullCounter = 0;

      if (resultsByPeriodsObject[timeButtonSelection.value]) {
        for (let result of resultsByPeriodsObject[timeButtonSelection.value]) {
          if (!result.globalData.value) {
            globalDataNullCounter += 1;
          }
        }

        if (
          resultsByPeriodsObject[timeButtonSelection.value].length ===
          globalDataNullCounter
        ) {
          setError({ [lastChoosenInput]: "sorry, no available data" });
          if (!isThereActiveFilters) {
            setIsThereGlobalData(false);
          }
          setCanChange(false);
        } else {
          setError({
            minAge: null,
            maxAge: null,
            gender: null,
            country: null,
            dimension: null,
          });
          setIsThereGlobalData(true);
          setCanChange(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [resultsByPeriodsObject, timeButtonSelection]);

  //shows the loader on screen everytime the results by periods object changes
  useEffect(() => {
    setShowLoader(Object.keys(resultsByPeriodsObject).length === 0);
  }, [resultsByPeriodsObject]);

  const ANALYTICS_FILTERS_COPIES = getStaticAnalyticsFiltersCopies({
    language,
  });

  /**
   * renders the loading animation when the graph is fetching data
   * @returns {Element}
   */
  const renderLoadingAnimation = () => {
    return (
      <div
        id="loading-animation-graph"
        style={{
          visibility: showLoader ? "visible" : "hidden",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "auto", display: "block", shapeRendering: "auto" }}
          width="100px"
          height="100px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="rotate(0 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.9166666666666666s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.8333333333333334s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.75s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.6666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.5833333333333334s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.5s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.4166666666666667s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.3333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.25s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.16666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="-0.08333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect
              x="47"
              y="24"
              rx="3"
              ry="6"
              width="6"
              height="12"
              fill="#fe718d"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
        </svg>
      </div>
    );
  };

  /**
   * checks if any filter has values
   * @param {Int} minAge
   * @param {Int} maxAge
   * @param {String} country
   * @param {String} gender
   * @returns {void}
   */
  const checkIfFilteredData = (minAge, maxAge, country, gender) => {
    if (minAge || maxAge || country || gender) {
      setIsThereActiveFilters(true);
      return;
    }

    setIsThereActiveFilters(false);
    return;
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <NewTimeButtons
          onButtonSelect={selectTimeButton}
          value={timeButtonSelection}
          buttonsProps={timeButtonsObject}
          blockedIntervals={blockedIntervals}
          language={language}
          copies={copies}
        />
      </div>
      <div
        className="d-flex justify-content-start justify-content-lg-center mx-auto"
        id="filters-container"
      >
        <div
          id="filters-container-inside"
          className="d-flex justify-content-center"
        >
          <div className="d-flex w-100">
            <div className="d-flex w-100 justify-content-around">
              <RenderAllCurrentComparisonSlots
                currentUser={currentUser}
                fetchCurrentUser={fetchUserWithAllAttributes}
                changeState={onFiltersChange}
                stateType="timePeriods"
                lastResult={userLastResult}
                page={MY_ANALYTICS_PAGE_ID}
                setShowLoader={changeLoader}
                setLastChoosenInput={setLastChoosenInput}
                filterError={error}
                isThereGlobalData={isThereGlobalData}
                setFlashProps={setFlashProps}
                checkIfFilteredData={checkIfFilteredData}
                selectedTime={timeButtonSelection.label}
                setCanChange={setCanChange}
                copies={ANALYTICS_FILTERS_COPIES}
                language={language}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={window.innerWidth < 576 ? "mx-3" : "mx-auto"}
        id="analytics-graph-container"
      >
        <AnalyticsGraph
          labels={graphData.map((r) => r.period.graphLabel)}
          userData={graphData.map((r) => r.userData.value)}
          globalData={graphData.map((r) => r.globalData.value)}
          copies={copies}
        />
      </div>
      {renderLoadingAnimation()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
  fetchUserWithAllAttributes,
  setFlashProps,
})(NewAnalyticsComparisonZone);
