// STANDARD COLORS FOR SCORE GRAPH AND +
const GREEN_COLORS = {
  main: "#7DBA61", // mini-balls and great ball
  secondary: "#E3F4DB", // outer circle of left bottom cicle
  third: "#C2E9AF", // inner circle of left bottom circle
};

const YELLOW_COLORS = {
  main: "#FEC804",
  secondary: "#FFF5CF",
  third: "#FFE99A",
};

const RED_COLORS = {
  main: "#EF592A",
  secondary: "#FFD7CB",
  third: "#F9B8A4",
};

// an object with all the standrd colors
const ZONE_COLORS = {
  "red zone": RED_COLORS,
  "yellow zone": YELLOW_COLORS,
  "green zone": GREEN_COLORS,
};

// functions to pick a color between two colors
const pickHex = (hex1, hex2, weight) =>
  pickRGB(hexToRgb(hex1), hexToRgb(hex2), weight);

const pickRGB = (rgb1, rgb2, weight) => {
  var w1 = weight;
  var w2 = 1 - w1;

  var rgb = [
    Math.round(rgb1[0] * w1 + rgb2[0] * w2),
    Math.round(rgb1[1] * w1 + rgb2[1] * w2),
    Math.round(rgb1[2] * w1 + rgb2[2] * w2),
  ];

  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

// function that converts hex colors to rgb colors
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return null;

  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
}

// some more colors
const MAIN_PURPLE = "#604586";
const MAIN_TEXT = "#333";
const SECONDARY_TEXT = "#666";

// props for the score graph: markers and sections
const SCORE_GRAPH_MARKERS = [40, 70];

const SCORE_GRAPH_SECTIONS = [
  {
    min: 0,
    max: 35,
    id: "redred",
    minColor: RED_COLORS.main,
    maxColor: RED_COLORS.main,
  },
  {
    min: 35,
    max: 45,
    id: "redyel",
    minColor: YELLOW_COLORS.main,
    maxColor: RED_COLORS.main,
    props: { gradientTransform: "rotate(-20)", x1: -0.2 },
  },
  {
    min: 45,
    max: 65,
    id: "yeyel",
    minColor: YELLOW_COLORS.main,
    maxColor: YELLOW_COLORS.main,
  },
  {
    min: 65,
    max: 75,
    id: "yelgre",
    minColor: GREEN_COLORS.main,
    maxColor: YELLOW_COLORS.main,
    props: { x1: 0, y1: 0, x2: 1.1, y2: 0, gradientTransform: "rotate(80)" },
  },
  {
    min: 75,
    max: 100,
    id: "gregre",
    minColor: GREEN_COLORS.main,
    maxColor: GREEN_COLORS.main,
  },
];

export {
  MAIN_PURPLE,
  MAIN_TEXT,
  SECONDARY_TEXT,
  RED_COLORS,
  YELLOW_COLORS,
  GREEN_COLORS,
  ZONE_COLORS,
  pickHex,
  SCORE_GRAPH_MARKERS,
  SCORE_GRAPH_SECTIONS,
};
