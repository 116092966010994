import React from "react";
import "./ExtraRenders.css";
import { TRANSPARENT } from "../../../utils/constants/constants";
import ShareResultByEmail from "./ShareResultByEmail";
import ShareAppByEmail from "./ShareAppByEmail";
import messengerIcon from "../../../assets/images/messengerIcon.png";
import messengerIcon2 from "../../../assets/images/messengerIcon2x.png";
import messengerIcon3 from "../../../assets/images/messengerIcon3x.png";
import emailIcon from "../../../assets/images/emailIcon.png";
import emailIcon2 from "../../../assets/images/emailIcon2x.png";
import emailIcon3 from "../../../assets/images/emailIcon3x.png";
import whatsAppIcon from "../../../assets/images/whatsappIcon.png";
import whatsAppIcon2 from "../../../assets/images/whatsappIcon2x.png";
import whatsAppIcon3 from "../../../assets/images/whatsappIcon3x.png";
import { detectWhatsapp } from "../../../utils/helpers/geralHelpers/whatsappDetector";
import { detectMessenger } from "../../../utils/helpers/geralHelpers/messengerDetector";

import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import { isSafari } from "../../../utils/helpers/geralHelpers/BrowserHelper";
import {
  EVENTS,
  triggerShareIconEvent,
} from "../../../utils/helpers/geralHelpers/EventsHelper";
import { FacebookMessengerShareButton } from "react-share";
import { createAnalytic } from "../../../utils/helpers/geralHelpers/BackendHelper";

/**
 * renders the icon for messenger sharing
 * @param {String} share_url
 * @param {String} appShareUrl
 * @param {Boolean} isApp
 * @param {Img} img
 * @param {Function} setFlashProps
 * @param {String} place
 * @returns {Element or Component}
 * */
const renderFacebookMessengerShareIcon = function ({
  share_url,
  appShareUrl,
  isApp,
  img,
  setFlashProps,
  place,
}) {
  if (window.innerWidth < 992) {
    return (
      <>
        {img ?? (
          <img
            src={messengerIcon}
            srcSet={`${messengerIcon2}, ${messengerIcon3}`}
            className="Group-218"
            alt="messenger"
            onClick={() => {
              if (window.innerWidth < 992) {
                detectMessenger(
                  "",
                  "",
                  isApp
                    ? appShareUrl + "&social=msn&mode=app"
                    : share_url + "&social=msn&mode=result"
                ).then((hasMessenger) => {
                  if (!hasMessenger) setFlashProps(FLASH_TYPES.MESSENGER_APP);
                });

                if (place === "Header") {
                  createAnalytic(EVENTS.INVITE_MESSENGER_HAMBURGUER);
                } else {
                  createAnalytic(EVENTS.INVITE_MESSENGER_MEET);
                }
              } else {
                createAnalytic(EVENTS.INVITE_MESSENGER_MEET);
              }
            }}
          ></img>
        )}
      </>
    );
  } else {
    return (
      <FacebookMessengerShareButton
        url={
          isApp
            ? appShareUrl + "&social=msn&mode=app"
            : share_url + "&social=msn&mode=result"
        }
        appId="571556190878960"
        redirectUri={window.location}
      >
        {img ?? (
          <img
            src={messengerIcon}
            srcSet={`${messengerIcon2}, ${messengerIcon3}`}
            className="Group-218"
            alt="messenger"
            onClick={() => {
              if (place === "Header") {
                createAnalytic(EVENTS.INVITE_MESSENGER_HAMBURGUER);
              } else {
                createAnalytic(EVENTS.INVITE_MESSENGER_MEET);
              }
            }}
          ></img>
        )}
      </FacebookMessengerShareButton>
    );
  }
};

/**
 * renders the whatsapp share icon
 * @param {String} share_url
 * @param {Boolean} isApp
 * @param {String} appShareUrl
 * @param {Img} img
 * @param {String} place
 * @returns {Element}
 * */
const renderWhatsAppShareIcon = ({
  share_url,
  isApp,
  appShareUrl,
  img,
  place,
}) => {
  let hasQuestionMark = appShareUrl.indexOf("?") > 0;

  let url = isApp
    ? appShareUrl + `${hasQuestionMark ? "&" : "?"}social=wa&mode=app`
    : share_url + "&social=wa&mode=result";

  return (
    <button
      style={{
        cursor: "pointer",
        border: 0,
        background: TRANSPARENT,
        padding: "0",
      }}
      onClick={() => {
        if (isSafari) {
          window.open(
            `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`
          );
        } else {
          detectWhatsapp("", url).then((hasWhatsapp) => {
            if (!hasWhatsapp)
              window.open(
                `https://web.whatsapp.com/send?text=${encodeURIComponent(url)}`
              );
          });
        }

        if (place === "Header") {
          triggerShareIconEvent("whatsapp-app-header");
        } else {
          triggerShareIconEvent("whatsapp-app-meet-violet");
        }
      }}
    >
      {img ?? (
        <img
          src={whatsAppIcon}
          srcSet={`${whatsAppIcon2}, ${whatsAppIcon3}`}
          id="whatsapp-icon"
          alt="whatsapp"
        />
      )}
    </button>
  );
};

/**
 * renders the email share icon for results
 * @param {String} share_url
 * @param {Object} customStyles
 * @returns {Component}
 * */
const renderEmailShareIcon = ({ share_url, customStyles }) => {
  return (
    <ShareResultByEmail share_url={share_url}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-envelope"
        viewBox="0 0 16 16"
        color={customStyles ? customStyles.color : "white"}
        cursor="pointer"
      >
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
      </svg>
    </ShareResultByEmail>
  );
};

/**
 * renders the email share icon for app sharing
 * @param {String} appShareUrl
 * @param {Img} img
 * @param {String} place
 * @returns {Component}
 * */
const renderEmailShareIconForApp = ({ appShareUrl, img, place }) => {
  return (
    <ShareAppByEmail share_url={appShareUrl} place={place}>
      {img ?? (
        <img
          src={emailIcon}
          srcSet={`${emailIcon2}, ${emailIcon3}`}
          className="Group-328"
          alt="email"
        />
      )}
    </ShareAppByEmail>
  );
};

export {
  renderEmailShareIcon,
  renderWhatsAppShareIcon,
  renderFacebookMessengerShareIcon,
  renderEmailShareIconForApp,
};
