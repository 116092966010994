/**
 * saves the partial score if the page is closed
 * @param {Object} ev
 * @param {Function} callback
 * @returns {String}
 */
const unloadFunction = ({ ev, callback }) => {
  ev.preventDefault();
  callback();
  return (ev.returnValue = "Are you sure you want to close?");
};

/**
 * executes unloadfunction on window close
 * @param {Function} callback
 */
const onWindowClose = (callback) => {
  window.onbeforeunload = (ev) => unloadFunction({ ev, callback });
};

//removes the function from the onbeforeunload event
const cancelOnWindowClose = () => {
  window.onbeforeunload = undefined;
};

export { onWindowClose, cancelOnWindowClose };
