import {
  redirectToLandingPage,
  redirectToPreliminaryResultsPage,
  redirectToTrialSurveyPage,
  redirectToWelcomeSurveyPage,
} from "../../core/Routing/RoutesRedirects";
import chooseNextPath from "../../modules/Auth/services/chooseNextPath";
import { checkIfThereAreTrialValues } from "../../modules/Surveys/services/trialSurvey.service";
import { hasTrialResultToday } from "./surveysHelpers/PreliminaryResultsHelper";

/**
 * redirects an anonymous user path depending if he has already answered trial survey or not
 * @param {Object} preliminaryResult
 * @param {Object} answerValues
 */
const anonymousUser = ({ preliminaryResult, answerValues }) => {
  if (hasTrialResultToday({ preliminaryResult })) {
    redirectToPreliminaryResultsPage();
  } else if (checkIfThereAreTrialValues(answerValues)) {
    redirectToTrialSurveyPage();
  } else if (answerValues.feel.feel_type) {
    redirectToWelcomeSurveyPage();
  } else {
    redirectToLandingPage();
  }
};

/**
 * redirects a registered user depending if he has already answered core survey today or not
 * @param {Object} currentUser
 */
const registeredUser = ({ currentUser }) => {
  if (currentUser) chooseNextPath(currentUser);
};

export { anonymousUser, registeredUser };
