import React from "react";
import { connect } from "react-redux";

import "./SquareAnswer.css";

/**
 * Functional Component: SquareAnswer
 * Renders a small square with one of the answer options
 *
 * @param {String} content
 * @param {Function} changeStyle
 * @param {Int} id
 * @param {Function} onOptionClick
 * @param {Object} squareRef
 * @param {Boolean} isSelected
 * @param {String or Int} value
 * @param {Function} startTrialOrCore
 * @returns {Element}
 */
const SquareAnswer = ({
  content,
  changeStyle,
  id,
  onOptionClick,
  squareRef,
  isSelected,
  value,
  startTrialOrCore,
}) => {
  return (
    <div
      className="mood_button"
      style={{
        borderStyle: isSelected ? "double" : "none",
      }}
      ref={squareRef}
      onClick={() => {
        onOptionClick(value, changeStyle, id);

        if (startTrialOrCore) {
          startTrialOrCore();
        }
      }}
    >
      <div className="mood_button_label">
        <span>{content}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { survey: state.survey };
};

export default connect(mapStateToProps)(SquareAnswer);
