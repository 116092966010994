import React from "react";
import "./WelcomeAnswer.css";

/**
 * Functional Component: WelcomeAnswer
 * This component represents the answers of welcome survey, is different from the square answer because of its nature
 * beeing completely different
 *
 * @param {String} content
 * @param {Function} changeStyle
 * @param {Int} id
 * @param {Function} onOptionClick
 * @param {Object} squareRef
 * @param {Boolean} isSelected
 * @returns {Element}
 */
const WelcomeAnswer = ({
  content,
  changeStyle,
  id,
  onOptionClick,
  squareRef,
  isSelected,
}) => {
  return (
    <div
      className="row"
      ref={squareRef}
      onClick={() => {
        onOptionClick(content, changeStyle, id);
      }}
      style={{
        backgroundColor: isSelected
          ? "rgb(205, 186, 231)"
          : "rgb(255, 255, 255)",
      }}
      id="welcome-answer"
    >
      <span id="welcome-answer-content">{content}</span>
    </div>
  );
};

export default WelcomeAnswer;
