//contains all available languages
const lngs = {
  english: {
    nativeName: "English",
    internalName: "en-GB",
    abreviation: "EN",
  },
  portuguese: {
    nativeName: "Português",
    internalName: "pt-PT",
    abreviation: "PT",
  },
};

export { lngs };
