import DropdownComponent from "../../../components/inputs/DropdownComponent";
import NativeDatepicker from "../../../components/inputs/NativeDatepicker";
import { renderCountriesDropdown } from "../../../utils/helpers/graphsHelpers/GraphsFiltersHelper";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getFilteredResultsByPeriods,
  getGlobalFilteredAverage,
  getUsersOnSameRange,
} from "../../Users/services/welcomeBackPage.service";
import {
  changeBirthdate,
  changeCountry,
  changeGender,
  createAnalytic,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import {
  dynamicFilterValuesUpdater,
  initialState,
  reducer,
} from "../services/renderFilters.service";
import { updateAverageAndRanges } from "../services/showResultComponent.service";
import { useReducer } from "react";
import {
  MY_ANALYTICS_PAGE_ID,
  RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
} from "../../../utils/constants/constants";
import "./RenderFilters.css";
import { useResize } from "../../Geral/hooks/useResize";
import { isDateBetweenExtremes } from "../../../utils/helpers/geralHelpers/DatesHelper";

import { renderDropdownGenderData } from "../../Users/services/aboutMe.service";
import { getBirthdateError } from "../services/CompareWithOthers.service";
import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import portugueseCountries from "../../../utils/countriesPortugues.json";
import countries from "../../../utils/countries.json";
import { getInfoAnalyticsEvent } from "../../../utils/helpers/geralHelpers/EventsHelper";

//everything here is related to the progress overtime filters

/*const getCustomDropdownStyles = (extras) => {
  return {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      minHeight: "3.2rem",
      width: "14.5rem",
      ...extras,
    }),
  };
};*/

/**
 * returns the different dropdown styles for the filters
 * @param {String} value
 * @param {String} dropdownName
 * @returns {Object}
 * */
const getCustomDropdownStyles = (value, dropdownName) => {
  if (window.innerWidth >= 992) {
    return {
      control: (provided, state) => ({
        ...provided,
        border: "0px solid #c2c0c7",
        height: "3.5vw",
        minHeight: "3.5vw",
        width: "15vw",
        fontSize: "0.8vw",
        display: "flex",
        alignItems: "center",
        borderRadius: "37.5px",
        backgroundColor: "#f2eff6",
      }),
      container: (provided) => ({
        ...provided,
        height: "3.5vw",
        minHeight: "3.5vw",
        fontSize: "0.8vw",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        height: "3.5vw",
        minHeight: "3.5vw",
        display: "flex",
        alignItems: "center",
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: "3.5vw",
        minHeight: "3.5vw",
        display: "flex",
        alignItems: "center",
        fontSize: "0.8vw",
        paddingLeft: "2vw",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        height: "2.5vw",
        minHeight: "2.5w",
        display: "none",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: "3.5vw",
        minHeight: "3.5vw",
        display: "flex",
        alignItems: "center",
        paddingRight: "0.5vw",
      }),
      singleValue: (provided) => ({
        ...provided,
        height: "2.5vw",
        minHeight: "2.5vw",
        display: "flex",
        alignItems: "center",
        fontSize: "0.8vw",
      }),
      menuList: (base) => ({
        ...base,
        fontSize: "1vw",
      }),
    };
  } else if (window.innerWidth >= 576) {
    return {
      control: (provided, state) => ({
        ...provided,
        border: "0px solid #c2c0c7",
        height: "5vw",
        minHeight: "3vw",
        width: "20vw",
        fontSize: "1.3vw !important",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f2eff6",
        borderRadius: "37.5px",
      }),
      container: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5vw",
        fontSize: "1.3vw !important",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5vw",
        width: "5vw",
        display: "flex",
        alignItems: "center",
        paddingRight: "1",
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5vw",
        fontSize: "1.3vw !important",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        height: "4vw",
        minHeight: "4w",
        display: "none",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5vw",
        display: "flex",
        alignItems: "center",
        padding: "0 !important",
      }),
      clearIndicator: (provided) => ({
        ...provided,
        height: "5vw",
        width: "2vw",
        minHeight: "5vw",
        display: "flex",
        alignItems: "center",
        padding: "0 !important",
      }),
      singleValue: (provided) => ({
        ...provided,
        height: "4vw",
        minHeight: "4vw",
        fontSize: "1.3vw !important",
        display: "flex",
        alignItems: "center",
      }),
      multiValue: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        marginTop: value && dropdownName === "country" ? "0.8vw" : null,
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
      }),
      menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
          width: "7px",
          height: "7px",
        },
      }),
    };
  } else if (window.innerWidth >= 0) {
    return {
      control: (provided, state) => ({
        ...provided,
        border: "0px solid #c2c0c7",
        height: "6vw",
        minHeight: "6vw",
        width: "30vw",
        fontSize: "2vw",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f2eff6",
        borderRadius: "37.5px",
      }),
      container: (provided) => ({
        ...provided,
        height: "6vw",
        minHeight: "6vw",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        height: "6vw",
        minHeight: "6vw",
        display: "flex",
        alignItems: "center",
        paddingRight: "1",
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: "6vw",
        minHeight: "6vw",
        fontSize: "2vw",
        paddingLeft: "2vw",
        whiteSpace: "nowrap",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5w",
        display: "none",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: "6vw",
        minHeight: "6vw",
        display: "flex",
        alignItems: "center",
      }),
      singleValue: (provided) => ({
        ...provided,
        height: "5vw",
        minHeight: "5vw",
        display: "flex",
        alignItems: "center",
      }),
      multiValue: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "9vw",
      }),
      clearIndicator: (provided) => ({
        ...provided,
        height: "2.5vw",
        minHeight: "2.5vw",
        padding: "0 !important",
        display: "flex",
        alignItems: "center",
      }),
      menuList: (base) => ({
        ...base,
        fontSize: "2vw",
        "::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
      }),
    };
  }
};

/**
 * returns the styles for the birthdate input
 * @returns {Object}
 */
const getNativePickerStyles = () => {
  if (window.innerWidth >= 992) {
    return {
      height: "3.5vw",
      minHeight: "3.5vw",
      width: "15vw",
      borderRadius: "37.5px",
      backgroundColor: "#f2eff6",
      fontSize: "0.8vw",
      border: "0 !important",
      display: "flex",
      alignItems: "center",
    };
  } else if (window.innerWidth >= 576) {
    return {
      height: "5vw",
      minHeight: "5vw",
      width: "20vw",
      borderRadius: "37.5px",
      backgroundColor: "#f2eff6",
      fontSize: "1.3vw",
      border: "0 !important",
      display: "flex",
      alignItems: "center",
    };
  } else if (window.innerWidth >= 0) {
    return {
      height: "6vw",
      minHeight: "6vw",
      width: "30vw",
      borderRadius: "37.5px",
      backgroundColor: "#f2eff6",
      fontSize: "2vw",
      border: "0 !important",
      display: "flex",
      alignItems: "center",
    };
  }
};

/**
 * returns the style for the x icon
 * @returns {Object}
 */
const getCustomXStyles = () => {
  if (window.innerWidth >= 576) {
    return { width: 13 };
  } else if (window.innerWidth >= 0) {
    return { width: 13, left: "98%" };
  }
};

// renderFilters

/**
 * renders a general filter
 * @param {Element} input
 * @returns {Element}
 */
const renderStandardFilter = ({ input }) => {
  return <div>{input}</div>;
};

/**
 * @returns {String}
 */
const getCustomMenuHeight = () => {
  if (window.innerWidth >= 1500) {
    return "9rem";
  } else if (window.innerWidth >= 576) {
    return "7rem";
  } else if (window.innerWidth >= 0) {
    return "5rem";
  }
};

/**
 * renders the gender filter
 * @param {Object} stateProps
 * @param {String} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {String} placeholder
 * @param {String} language
 * @returns {Element}
 */
const renderGenderFilter = ({
  stateProps,
  value,
  error,
  isThereGlobalData,
  placeholder,
  language,
}) => {
  if (language === "pt-PT" && stateProps && stateProps.data) {
    stateProps.data.forEach((a) => {
      if (a.label === "Male") {
        a.label = "Masculino";
      } else if (a.label === "Female") {
        a.label = "Feminino";
      } else if (a.label === "Other") {
        a.label = "Outro";
      }
    });
  } else if (language !== "pt-PT" && stateProps && stateProps.data) {
    stateProps.data.forEach((a) => {
      if (a.label === "Masculino") {
        a.label = "Male";
      } else if (a.label === "Feminino") {
        a.label = "Female";
      } else if (a.label === "Outro") {
        a.label = "Other";
      }
    });
  }

  const input = (
    <>
      <DropdownComponent
        customStyles={getCustomDropdownStyles(value)}
        placeholder={placeholder}
        isMulti
        maxMenuHeight={
          window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
        }
        {...stateProps}
        isClearable={false}
        progressOverTime={true}
        isSearchable={window.innerWidth < 992 ? false : true}
      />
      <span class="filter-errors">{error}</span>
    </>
  );
  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the min age filter
 * @param {Object} stateProps
 * @param {Int} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {String} placeholder
 * @returns {Element}
 */
const renderMinAgeFilter = ({
  stateProps,
  value,
  error,
  isThereGlobalData,
  placeholder,
}) => {
  const input = (
    <>
      <div className="d-flex justify-content-between">
        <DropdownComponent
          customStyles={getCustomDropdownStyles(value, "minAge")}
          {...stateProps.min}
          placeholder={placeholder}
          maxMenuHeight={
            window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
          }
          progressOverTime={true}
          isSearchable={window.innerWidth < 992 ? false : true}
        />
      </div>
      <span class="filter-errors">{error}</span>
    </>
  );
  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the max age filter
 * @param {Object} stateProps
 * @param {Int} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {String} placeholder
 * @returns {Element}
 */
const renderMaxAgeFilter = ({
  stateProps,
  value,
  error,
  isThereGlobalData,
  placeholder,
}) => {
  const input = (
    <>
      <div className="d-flex justify-content-between">
        <DropdownComponent
          customStyles={getCustomDropdownStyles(value, "maxAge")}
          {...stateProps.max}
          placeholder={placeholder}
          maxMenuHeight={
            window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
          }
          progressOverTime={true}
          isSearchable={window.innerWidth >= 992}
        />
      </div>
      <span class="filter-errors">{error}</span>
    </>
  );
  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the country filter
 * @param {Object} stateProps
 * @param {String} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {String} placeholder
 * @param {String} language
 * @returns {Element}
 */
const renderCountryFilter = ({
  stateProps,
  value,
  error,
  isThereGlobalData,
  placeholder,
  language,
}) => {
  if (language === "pt-PT" && stateProps && stateProps.data) {
    stateProps.data.forEach((a) => {
      for (let i = 0; i < portugueseCountries.length; i++) {
        if (a.value === portugueseCountries[i].alpha2Code) {
          a.label = portugueseCountries[i].name;
          break;
        }
      }
    });
  } else if (language !== "pt-PT" && stateProps && stateProps.data) {
    stateProps.data.forEach((a) => {
      for (let i = 0; i < countries.length; i++) {
        if (a.value === countries[i].alpha2Code) {
          a.label = countries[i].name;
          break;
        }
      }
    });
  }

  const input = (
    <>
      <DropdownComponent
        customStyles={getCustomDropdownStyles(value, "country")}
        isMulti
        {...stateProps}
        placeholder={placeholder}
        maxMenuHeight={
          window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
        }
        isClearable={false}
        progressOverTime
        isSearchable={window.innerWidth >= 992}
      />
      <span class="filter-errors">{error}</span>
    </>
  );
  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the dimension filter
 * @param {Object} stateProps
 * @param {String} value
 * @param {String} placeholder
 * @param {String} language
 * @returns {Element}
 */
const renderDimensionFilter = ({
  stateProps,
  value,
  placeholder,
  language,
}) => {
  if (language === "pt-PT" && stateProps && stateProps.data) {
    stateProps.data = [
      { value: 1, label: "Física" },
      { value: 3, label: "Mental" },
      { value: 5, label: "Social" },
      { value: 6, label: "Trabalho" },
    ];
  } else if (language !== "pt-PT" && stateProps && stateProps.data) {
    stateProps.data = [
      { value: 1, label: "Physical" },
      { value: 3, label: "Mental" },
      { value: 5, label: "Social" },
      { value: 6, label: "Work" },
    ];
  }

  const input = (
    <div>
      <DropdownComponent
        customStyles={getCustomDropdownStyles()}
        {...stateProps}
        placeholder={placeholder}
        maxMenuHeight={
          window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
        }
        progressOverTime
        value={value}
        isSearchable={window.innerWidth >= 992}
      />
    </div>
  );

  return renderStandardFilter({ input });
};

// renderPrompts

/**
 * renders the gender input to ask user for his gender
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {Boolean} isThereGlobalData
 * @param {Object} placeholders
 * @param {String} language
 * @returns {Element}
 */
const renderGenderPrompt = ({
  userId,
  fetchCurrentUser,
  onChoiceProps,
  isThereGlobalData,
  placeholders,
  language,
}) => {
  const callback = async () => {
    await changeGender(userId, onChoiceProps.choice.value);
    fetchCurrentUser(userId);
  };
  const input = (
    <div className="d-flex flex-column">
      <DropdownComponent
        customStyles={getCustomDropdownStyles()}
        placeholder={<em>{placeholders.INPUT}</em>}
        data={renderDropdownGenderData(language)}
        onChange={onChoiceProps.onChange}
        value={onChoiceProps.choice}
        isSearchable={window.innerWidth >= 992}
        maxMenuHeight={
          window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
        }
        progressOverTime
      />
      {renderSaveButton({
        onChoiceProps,
        userId,
        fetchCurrentUser,
        callback,
        place: "Gender",
        placeholder: placeholders.SAVE_BUTTON,
      })}
    </div>
  );

  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the age input to ask user for his age
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {Boolean} isThereGlobalData
 * @param {Function} setFlashProps
 * @param {Object} placeholders
 * @returns {Element}
 */
const renderAgePrompt = ({
  userId,
  fetchCurrentUser,
  onChoiceProps,
  isThereGlobalData,
  setFlashProps,
  placeholders,
}) => {
  const callback = async () => {
    if (!isDateBetweenExtremes(new Date(onChoiceProps.choice.target.value))) {
      setFlashProps({
        ...FLASH_TYPES.ABOUT_ME_BIRTHDATE_PREFIX,
        type: `${
          FLASH_TYPES.ABOUT_ME_BIRTHDATE_PREFIX.type
        }-${getBirthdateError(new Date(onChoiceProps.choice.target.value))}`,
      });
      onChoiceProps.onChange(null);
    } else {
      await changeBirthdate(userId, onChoiceProps.choice.target.value);
      fetchCurrentUser(userId);
    }
  };

  const input = (
    <div id="render-filters-date-container" className="d-flex flex-column">
      <NativeDatepicker
        style={getNativePickerStyles()}
        onChange={onChoiceProps.onChange}
        value={onChoiceProps.choice ? onChoiceProps.choice.target.value : ""}
        placeholder={placeholders.INPUT}
        isClerable
        xColor="black"
        hideX={!(onChoiceProps.choice && onChoiceProps.choice.target.value)}
        customXStyles={getCustomXStyles()}
      />
      {renderSaveButton({
        onChoiceProps,
        userId,
        fetchCurrentUser,
        callback,
        place: "Age",
        placeholder: placeholders.SAVE_BUTTON,
      })}
    </div>
  );

  return isThereGlobalData ? renderStandardFilter({ input }) : null;
};

/**
 * renders the country input to ask user for his country
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {Boolean} isThereGlobalData
 * @param {Object} placeholders
 * @param {String} language
 * @returns {Element}
 */
const renderCountryPrompt = ({
  userId,
  fetchCurrentUser,
  onChoiceProps,
  isThereGlobalData,
  placeholders,
  language,
}) => {
  const callback = async () => {
    await changeCountry(userId, onChoiceProps.choice.value);
    fetchCurrentUser(userId);
  };

  const input = (
    <div className="d-flex flex-column">
      <DropdownComponent
        customStyles={getCustomDropdownStyles()}
        placeholder={<em>{placeholders.INPUT}</em>}
        data={renderCountriesDropdown(language)}
        onChange={onChoiceProps.onChange}
        value={onChoiceProps.choice}
        isSearchable={window.innerWidth >= 992}
        maxMenuHeight={
          window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
        }
        progressOverTime
      />
      {renderSaveButton({
        onChoiceProps,
        userId,
        fetchCurrentUser,
        callback,
        place: "Location",
        placeholder: placeholders.SAVE_BUTTON,
      })}
    </div>
  );
  return isThereGlobalData
    ? renderStandardFilter({ input })
    : isThereGlobalData;
};

/**
 * renders the save button to save the demographics provided by the user on the prompts
 * @param {Object} onChoiceProps
 * @param {Function} callback
 * @param {String} place
 * @param {String} placeholder
 * @returns {Element}
 */
const renderSaveButton = ({ onChoiceProps, callback, place, placeholder }) => {
  if (!onChoiceProps.choice) return null;

  return (
    <span
      onClick={() => {
        createAnalytic(getInfoAnalyticsEvent(place));

        callback();
      }}
      class="save-button"
    >
      {placeholder}
    </span>
  );
};

// renderSlots

/**
 * enders the gender slot wich can have the prompt or the filter
 * @param {Boolean} hasInfo
 * @param {Object} stateProps
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {String or Int} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {Object} placeholders
 * @param {String} language
 * @returns {Element}
 */
const renderGenderSlot = ({
  hasInfo,
  stateProps,
  userId,
  fetchCurrentUser,
  onChoiceProps,
  value,
  error,
  isThereGlobalData,
  placeholders,
  language,
}) => {
  return hasInfo
    ? renderGenderFilter({
        stateProps,
        value,
        error,
        isThereGlobalData,
        placeholder: placeholders.FILTER,
        language,
      })
    : renderGenderPrompt({
        userId,
        fetchCurrentUser,
        onChoiceProps,
        isThereGlobalData,
        placeholders: placeholders.PROMPT,
        language,
      });
};

/**
 * renders the age slot wich can have the prompt or the filter
 * @param {Boolean} hasInfo
 * @param {Object} stateProps
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {String or Int} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {Function} setFlashProps
 * @param {Object} placeholders
 * @returns {Element}
 */
const renderAgeSlot = ({
  hasInfo,
  stateProps,
  userId,
  fetchCurrentUser,
  onChoiceProps,
  value,
  error,
  isThereGlobalData,
  setFlashProps,
  placeholders,
}) => {
  return hasInfo
    ? [
        renderMinAgeFilter({
          stateProps,
          value,
          error: error.minAge,
          isThereGlobalData,
          placeholder: placeholders.MIN_FILTER,
        }),
        window.innerWidth < 992 ? <div style={{ width: "3vw" }}></div> : null,
        renderMaxAgeFilter({
          stateProps,
          value,
          error: error.maxAge,
          isThereGlobalData,
          placeholder: placeholders.MAX_FILTER,
        }),
      ]
    : renderAgePrompt({
        userId,
        fetchCurrentUser,
        onChoiceProps,
        isThereGlobalData,
        setFlashProps,
        placeholders: placeholders.PROMPT,
      });
};

/**
 * renders the country slot wich can have the prompt or the filter
 * @param {Boolean} hasInfo
 * @param {Object} stateProps
 * @param {String} userId
 * @param {Function} fetchCurrentUser
 * @param {Object} onChoiceProps
 * @param {String or Int} value
 * @param {String} error
 * @param {Boolean} isThereGlobalData
 * @param {Object} placeholders
 * @param {String} language
 * @returns {Element}
 */
const renderCountrySlot = ({
  hasInfo,
  stateProps,
  userId,
  fetchCurrentUser,
  onChoiceProps,
  value,
  error,
  isThereGlobalData,
  placeholders,
  language,
}) => {
  return hasInfo
    ? renderCountryFilter({
        stateProps,
        value,
        error,
        isThereGlobalData,
        placeholder: placeholders.FILTER,
        language,
      })
    : renderCountryPrompt({
        userId,
        fetchCurrentUser,
        onChoiceProps,
        isThereGlobalData,
        placeholders: placeholders.PROMPT,
        language,
      });
};

/**
 * renders the dimension slot wich contains only the dimension
 * @param {Boolean} canRender
 * @param {Object} stateProps
 * @param {String} value
 * @param {Object} placeholders
 * @param {String} language
 * @returns {Element}
 */
const renderDimensionSlot = ({
  canRender,
  stateProps,
  value,
  placeholders,
  language,
}) => {
  return canRender
    ? renderDimensionFilter({
        stateProps,
        value,
        placeholder: placeholders.FILTER,
        language,
      })
    : null;
};

// render everything

/**
 * this renders all the filters or prompts or both depending on the case
 * @param {Object} currentUser
 * @param {Function} fetchCurrentUser
 * @param {Int} min_range_value
 * @param {Int} max_range_value
 * @param {Function} changeState
 * @param {String} stateType
 * @param {Object} lastResult´
 * @param {String} page
 * @param {Function} setShowLoader
 * @param {Function} setLastChoosenInput
 * @param {Object} filterError
 * @param {Boolean} isThereGlobalData
 * @param {Function} setFlashProps
 * @param {Function} checkIfFilteredData
 * @param {String} selectedTime
 * @param {Function} setCanChange
 * @param {Object} copies
 * @param {String} language
 * @returns {Element}
 */
const RenderAllCurrentComparisonSlots = ({
  currentUser,
  fetchCurrentUser,
  min_range_value,
  max_range_value,
  changeState,
  stateType = null,
  lastResult,
  page,
  setShowLoader,
  setLastChoosenInput,
  filterError,
  isThereGlobalData,
  setFlashProps,
  checkIfFilteredData,
  selectedTime,
  setCanChange,
  copies,
  language,
}) => {
  // attributes modifier
  const [genderChoice, onGenderChoiceChange] = useState(null);
  const [countryChoice, onCountryChoiceChange] = useState(null);
  const [ageChoice, onAgeChoiceChange] = useState(null);

  const [filterState, dispatch] = useReducer(reducer, initialState);
  const firstUpdate = useRef(true);

  useResize();

  // onChanges

  //called when min age changes
  const onMinAgeChange = useCallback(
    (value) => {
      if (!value) {
        setShowLoader(true);
        dispatch({ type: "minAge", payload: null });
      } else {
        if (value.value !== filterState.minAge) setShowLoader(true);
        dispatch({ type: "minAge", payload: value.value });
      }

      setLastChoosenInput("minAge");
    },
    // eslint-disable-next-line
    [setShowLoader, filterState]
  );

  //called when max age changes
  const onMaxAgeChange = useCallback(
    (value) => {
      if (!value) {
        setShowLoader(true);
        dispatch({ type: "maxAge", payload: null });
      } else {
        if (value.value !== filterState.maxAge) setShowLoader(true);
        dispatch({ type: "maxAge", payload: value.value });
      }

      setLastChoosenInput("maxAge");
    },
    // eslint-disable-next-line
    [setShowLoader, filterState]
  );

  //called when country changes
  const onCountryChange = useCallback(
    (value) => {
      if (value.length < 1) {
        setShowLoader(true);
        dispatch({ type: "country", payload: null });
      } else {
        const countries = value.map((countryObj) => countryObj.value);
        if (countries !== filterState.country) setShowLoader(true);
        dispatch({ type: "country", payload: countries });
      }

      setLastChoosenInput("country");
    },
    // eslint-disable-next-line
    [setShowLoader, filterState]
  );

  //called when dimension changes
  const onDimensionChange = useCallback(
    (value) => {
      if (!value) {
        setShowLoader(true);
        dispatch({ type: "dimension", payload: null });
      } else {
        if (value.value !== filterState.dimension) setShowLoader(true);
        dispatch({ type: "dimension", payload: value.value });
      }
      setLastChoosenInput("dimension");
    },
    // eslint-disable-next-line
    [setShowLoader, filterState]
  );

  //called when gender changes
  const onGenderChange = useCallback(
    (value) => {
      if (value.length < 1) {
        setShowLoader(true);
        dispatch({ type: "gender", payload: null });
      } else {
        const genders = value.map((genderObj) => genderObj.value);
        if (genders !== filterState.gender) setShowLoader(true);
        dispatch({ type: "gender", payload: genders });
      }

      setLastChoosenInput("gender");
    },
    // eslint-disable-next-line
    [setShowLoader, filterState]
  );

  // useEffects

  //updates the averages and spreaded results when any filter or time period changes
  useEffect(() => {
    if (page === WELCOME_BACK_PAGE_ID || page === MY_ANALYTICS_PAGE_ID) {
      const updateAverage = async () => {
        switch (stateType) {
          case "average":
            const newAverage = await getGlobalFilteredAverage(filterState);
            changeState(newAverage);
            break;
          case "percentage":
            const newUsersOnSameRange = await getUsersOnSameRange(
              filterState,
              lastResult
            );
            changeState(newUsersOnSameRange);
            break;
          case "timePeriods":
            if (firstUpdate.current) {
              const newFilteredResults = await getFilteredResultsByPeriods(
                filterState,
                currentUser.attributes.user_id,
                "all",
                language
              );

              changeState(newFilteredResults);

              firstUpdate.current = false;
            } else {
              const newFilteredResults = await getFilteredResultsByPeriods(
                filterState,
                currentUser.attributes.user_id,
                selectedTime,
                language
              );

              setCanChange(true);
              changeState(newFilteredResults);
            }

            break;
          default:
            break;
        }
      };

      checkIfFilteredData(
        filterState.minAge,
        filterState.maxAge,
        filterState.country,
        filterState.gender
      );

      updateAverage();
    }
    // eslint-disable-next-line
  }, [
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
    filterState.dimension,
    selectedTime,
    language,
  ]);

  //updates the filters content depending on the other filters
  useEffect(() => {
    if (
      page === WELCOME_BACK_PAGE_ID ||
      page === RESULTS_PAGE_ID ||
      page === MY_ANALYTICS_PAGE_ID
    ) {
      dynamicFilterValuesUpdater(filterState, dispatch);
    }
    //eslint-disable-next-line
  }, [
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
    filterState.dimension,
  ]);

  //updates averages and ranges on results page
  useEffect(() => {
    if (page === RESULTS_PAGE_ID) {
      updateAverageAndRanges(
        filterState,
        min_range_value,
        max_range_value,
        changeState,
        stateType
      );
    }
    //eslint-disable-next-line
  }, [
    filterState.minAge,
    filterState.maxAge,
    filterState.country,
    filterState.gender,
    filterState.dimension,
  ]);

  return (
    <>
      {renderDimensionSlot({
        stateProps: {
          data: filterState.dimensionRange,
          onChange: onDimensionChange,
        },
        canRender: page === MY_ANALYTICS_PAGE_ID,
        value: filterState.dimension,
        placeholders: copies.DIMENSION,
        language,
      })}
      {window.innerWidth < 992 && isThereGlobalData ? (
        <div style={{ width: "3vw" }}></div>
      ) : null}
      {renderGenderSlot({
        hasInfo: currentUser && currentUser.attributes.gender,
        stateProps: {
          data: filterState.genderRange,
          onChange: onGenderChange,
        },
        userId: currentUser.attributes.user_id,
        fetchCurrentUser,
        onChoiceProps: { choice: genderChoice, onChange: onGenderChoiceChange },
        value: filterState.gender,
        error: filterError.gender,
        isThereGlobalData: isThereGlobalData,
        placeholders: copies.GENDER,
        language,
      })}
      {window.innerWidth < 992 && isThereGlobalData ? (
        <div style={{ width: "3vw" }}></div>
      ) : null}
      {renderAgeSlot({
        hasInfo: currentUser && currentUser.attributes.birthdate,
        stateProps: {
          min: {
            data: filterState.minAgeRange,
            onChange: onMinAgeChange,
            value: filterState.minAge,
          },
          max: {
            data: filterState.maxAgeRange,
            onChange: onMaxAgeChange,
            value: filterState.maxAge,
          },
        },
        userId: currentUser.attributes.user_id,
        fetchCurrentUser,
        onChoiceProps: { choice: ageChoice, onChange: onAgeChoiceChange },
        value: {
          minAge: filterState.minAge,
          maxAge: filterState.maxAge,
        },
        error: {
          minAge: filterError.minAge,
          maxAge: filterError.maxAge,
        },
        isThereGlobalData: isThereGlobalData,
        setFlashProps,
        placeholders: copies.AGE,
      })}
      {window.innerWidth < 992 && isThereGlobalData ? (
        <div style={{ width: "3vw" }}></div>
      ) : null}
      {renderCountrySlot({
        hasInfo: currentUser && currentUser.attributes.country,
        stateProps: {
          data: filterState.countryRange,
          onChange: onCountryChange,
        },
        userId: currentUser.attributes.user_id,
        fetchCurrentUser,
        onChoiceProps: {
          choice: countryChoice,
          onChange: onCountryChoiceChange,
        },
        value: filterState.country,
        error: filterError.country,
        isThereGlobalData: isThereGlobalData,
        placeholders: copies.COUNTRY,
        language,
      })}
    </>
  );
};

export { RenderAllCurrentComparisonSlots };
