import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "./AboutMe.css";
import {
  internalSignOut,
  fetchUserWithAllAttributes,
  cleanAllRedux,
} from "../../../store/actions";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import {
  countryFormatter,
  deleteAccount,
  genderFormatter,
  getTranslatedLabel,
  getUserSinceDate,
  languageToGenderLabel,
  languageToReminderLabel,
  onChooseImageClick,
  renderDropdownCountryData,
  renderDropDownData,
  renderDropdownGenderData,
  saveAboutMeAttributes,
  savePeriodicity,
  toBase64,
} from "../services/aboutMe.service";
import { TRANSPARENT } from "../../../utils/constants/constants";
import Avatar from "react-avatar";
import Footer from "../../../components/footer/Footer";
import Modal from "../../../components/modal/Modal";
import login_backend from "../../../core/apis/login_backend";
import AvatarImage from "../../../components/inputs/AvatarImage";
import {
  redirectToConfirmPasswordPage,
  redirectToLandingPage,
} from "../../../core/Routing/RoutesRedirects";
import WebcamComp from "../../../components/webCam/WebCam";
import DropdownComponent from "../../../components/inputs/DropdownComponent";
import history from "../../../utils/history";
import NativeDatepicker from "../../../components/inputs/NativeDatepicker";
import PoppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import GreyBackgroundRectangle from "../../../components/rectangles/GreyBackgroundRectangle";
import { setFlashProps } from "../../../store/actions";
import { MAIN_PURPLE } from "../../../utils/constants/colorsConstants";
import PageNavbar from "../../Scores/components/PageNavbar";
import { useResize } from "../../Geral/hooks/useResize";
import { isDateBetweenExtremes } from "../../../utils/helpers/geralHelpers/DatesHelper";
import PasswordInput from "../../../components/inputs/PasswordInput";
import {
  createAnalytic,
  doesEmailExist,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import { isStringBlank } from "../../../utils/helpers/geralHelpers/StringsHelper";

import FLASH_TYPES from "../../../components/flash/FLASH_TYPES";
import { getBirthdateError } from "../../Results/services/CompareWithOthers.service";
import { getStaticAboutMeCopies } from "../../../utils/constants/copies/copies.helper";
import {
  EVENTS,
  getAboutMeFieldEvent,
} from "../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * gets the page from the url hash params
 * @returns {String}
 */
const getPage = () => {
  switch (window.location.hash) {
    case "#settings":
      return "settings";
    default:
      return "profile";
  }
};

/**
 * Functional Component: AboutMe
 * This components represents the profile and contains all user information, delete account and logout, also the alert periodicity
 *
 * @param {Function} internalSignOut
 * @param {Function} fetchUserWithAllAttributes
 * @param {Function} cleanAllRedux
 * @param {Object} currentUser
 * @param {Object} location
 * @param {Function} setFlashProps
 * @param {String} language
 * @returns {Element}
 */
const AboutMe = ({
  internalSignOut,
  fetchUserWithAllAttributes,
  cleanAllRedux,
  currentUser,
  location,
  setFlashProps,
  language,
}) => {
  const [page, setPage] = useState(getPage());
  const [showModal, setShowModal] = useState(false);
  const [showLogoutAndDeleteModal, setShowLogoutAndDeleteModal] =
    useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [takePhoto, setTakePhoto] = useState(false);
  const [alertPeriodicity, setAlertPeriodicity] = useState(null);
  const [enabledInputs, setEnabledInputs] = useState({
    first_name: false,
    last_name: false,
    gender: false,
    birthdate: false,
    email: false,
    password: false,
    country: false,
    periodicity: false,
  });
  const [aboutMeInfo, setAboutMeInfo] = useState({
    first_name: currentUser.attributes.first_name,
    last_name: currentUser.attributes.last_name,
    gender: currentUser.attributes.gender ?? undefined,
    birthdate: currentUser.attributes.birthdate ?? undefined,
    email: currentUser.attributes.email,
    country: currentUser.attributes.country ?? undefined,
  });
  const [up, update] = useState(false);
  const [password, setPassword] = useState({
    password: null,
    passwordConfirmation: null,
  });
  const [passwordErrors, setPasswordErrors] = useState({
    password: null,
    passwordConfirmation: null,
  });
  const [userFetch, setUserFetch] = useState(false);

  const [innerWidth] = useResize();

  const passwordRef = useRef(null);

  //contains all the copies needed for about me page
  const SELF_COPIES = getStaticAboutMeCopies({ language });
  //scrolls the page to top
  const executeScroll = () => passwordRef.current.scrollIntoView();

  //opens the take/save photo modal when a user clicks the avatar
  const onImageClick = useCallback(() => {
    setShowModal(true);
  }, []);

  //closes the photo modal
  const closeModal = useCallback(() => {
    setEdit(false);
    setTakePhoto(false);
    setShowModal(false);
  }, []);

  //handles when a user saves a new picture as avatar
  const onImageChange = useCallback((e) => {
    setImage({
      image_name: e.target.files[0].name,
      url: URL.createObjectURL(e.target.files[0]),
    });

    setEdit(true);
  }, []);

  //handles window resize
  useEffect(() => {
    const handleResize = () => {
      update(!up);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  //scrolls to password when a user comes back after going to confirm password
  useEffect(() => {
    if (location.state && location.state.confirmed) {
      executeScroll();
    }

    //eslint-disable-next-line
  }, [location.state]);

  //refetches the current user to update information that might have been changed on another device
  useEffect(() => {
    const refetchUser = async () => {
      await fetchUserWithAllAttributes(currentUser.attributes.user_id);

      setUserFetch(!userFetch);
    };

    refetchUser();

    //eslint-disable-next-line
  }, []);

  //handles when the user resizes the profile image before saving it
  const onCrop = useCallback(
    (value) => {
      setFinalImage({
        file: value,
        fileName: image.image_name,
      });
    },
    [image]
  );

  /**
   * renders the clean icon for dropdowns and fields
   * @returns {Element}
   */
  const renderXIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getXSize().width}
        height={getXSize().height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path fill="#604586" d="m2.121 0 17.678 17.678-2.122 2.12L0 2.122z" />
        <path fill="#604586" d="M0 17.678 17.678 0l2.121 2.122L2.122 19.799z" />
      </svg>
    );
  };

  /**
   * formats date to a specific format
   * @param {String} date
   * @returns {String}
   */
  const dateFormatter = (date) => {
    if (!date) return null;

    const dateArray = date.split("/");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  };

  /**
   * returns the avatar size depending on screen size
   * @returns {String}
   */
  const getAvatarSize = () => {
    if (innerWidth >= 992) {
      return "9vw";
    } else if (innerWidth >= 576) {
      return "14vw";
    } else if (innerWidth >= 0) {
      return "16vw";
    }
  };

  /**
   * returns avatar font size depending on screen size
   * @returns {Object}
   */
  const getAvatarFont = () => {
    if (innerWidth >= 992) {
      return { fontSize: "calc(1rem + 2vw)" };
    } else if (innerWidth >= 576) {
      return { fontSize: "calc(1rem + 5vw)" };
    } else if (innerWidth >= 0) {
      return { fontSize: "calc(1rem + 7vw)" };
    }
  };

  /**
   * returns X size depending on screen size
   * @returns {Object}
   */
  const getXSize = () => {
    if (innerWidth >= 992) {
      return { width: 18, height: 18 };
    } else if (innerWidth >= 576) {
      return { width: 10, height: 10 };
    } else if (innerWidth >= 0) {
      return { width: 10, height: 10 };
    }
  };

  /**
   * returns the logout modal size depending on the screen size
   * @returns {Object}
   */
  const getLogoutModalSize = () => {
    if (innerWidth >= 992) {
      return { width: "40vw", height: "13vw" };
    } else if (innerWidth >= 576) {
      return { width: "55vw", height: "20vw" };
    } else if (innerWidth >= 0) {
      return { width: "80vw", height: "45vw" };
    }
  };

  //handles deleting the profile picture
  const deletePhoto = async () => {
    try {
      await login_backend.delete(
        `/deleteUpload/${currentUser.attributes.user_id}`
      );

      await fetchUserWithAllAttributes(currentUser.attributes.user_id);

      setFlashProps(FLASH_TYPES.DELETE_PROFILE_PHOTO);
    } catch (e) {}

    setEdit(false);
    setShowModal(false);
  };

  //closes the photo editor and saves the picture
  const closeEditor = async () => {
    try {
      await login_backend.post(`/upload/${currentUser.attributes.user_id}`, {
        file: finalImage.file,
        fileName: finalImage.fileName,
      });

      await fetchUserWithAllAttributes(currentUser.attributes.user_id);
    } catch (e) {
      setFlashProps(FLASH_TYPES.BIG_TAKE_PROFILE_PHOTO);
    }

    setEdit(false);
    setShowModal(false);
  };

  /**
   * returns the photo modal size depending on the screen size
   * @returns {Object}
   */
  const getPhotoModalSize = () => {
    if (innerWidth >= 992) {
      return { width: "35vw", height: "25vw" };
    } else if (innerWidth >= 576) {
      return { width: "50vw", height: "40vw" };
    } else if (innerWidth >= 0) {
      return { width: "75vw", height: "70vw" };
    }
  };

  /**
   * returns the avatar image size depending on the screen size
   * @returns {Object}
   */
  const getAvatarImageSize = () => {
    if (innerWidth >= 992) {
      return {
        height: "200",
        width: "300",
      };
    } else if (innerWidth >= 576) {
      return {
        height: "200",
        width: "200",
      };
    } else if (innerWidth >= 0) {
      return {
        height: "200",
        width: "200",
      };
    }
  };

  /**
   * renders the profile picture interface in a modal with all its options
   * @returns {Element}
   */
  const renderModal = () => {
    const sizes = getPhotoModalSize();
    const avatarImageSizes = getAvatarImageSize();

    return (
      <div>
        <Modal show={showModal} width={sizes.width} minHeight={sizes.height}>
          {!edit && !takePhoto ? (
            <div
              className="d-flex flex-column align-items-center"
              id="main-modal-container"
            >
              <div id="photo-modal-title">{SELF_COPIES.photoModal.title}</div>
              <div
                id="photo-modal-buttons-container"
                className="d-flex flex-column justify-content-between"
              >
                <div
                  class="photo-modal-options-buttons"
                  onClick={onChooseImageClick}
                >
                  {SELF_COPIES.photoModal.first_button}
                </div>
                <div
                  onClick={() => setTakePhoto(true)}
                  class="photo-modal-options-buttons"
                >
                  {SELF_COPIES.photoModal.second_button}
                </div>
                {currentUser.attributes.image ? (
                  <div
                    class="photo-modal-options-buttons"
                    onClick={() => {
                      deletePhoto();
                      createAnalytic(EVENTS.PHOTO_DELETE_PROFILE);
                    }}
                  >
                    {SELF_COPIES.photoModal.third_button}
                  </div>
                ) : null}
                <div id="photo-modal-cancel-button" onClick={closeModal}>
                  {SELF_COPIES.photoModal.cancel}
                </div>
              </div>
              <input
                id="hidden-image-input"
                type="file"
                style={{ visibility: "hidden", width: 0, height: 0 }}
                accept="image/*"
                onChange={onImageChange}
              />
            </div>
          ) : !takePhoto ? (
            <div className="d-flex flex-column align-items-center">
              <div id="avatar-image-editor-container">
                <AvatarImage
                  cropFunc={onCrop}
                  src={image.url}
                  onClose={closeEditor}
                  width={avatarImageSizes.width}
                  height={avatarImageSizes.height}
                />
              </div>
              <div
                class="photo-modal-options-buttons"
                onClick={() => {
                  closeEditor();
                  createAnalytic(EVENTS.PHOTO_SAVE_PROFILE);
                }}
              >
                {SELF_COPIES.photoModal.save}
              </div>
              <div onClick={closeModal} id="photo-modal-cancel-button">
                {SELF_COPIES.photoModal.cancel}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-">
              <WebcamComp
                setTakePhoto={setTakePhoto}
                setEdit={setEdit}
                saveImage={setImage}
                width={280}
                height={250}
                currentUser={currentUser}
              />
              <div onClick={closeModal} id="photo-modal-cancel-button">
                {SELF_COPIES.photoModal.cancel}
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  };

  /**
   * renders the logout and delete account options modals
   * @returns {Component}
   */
  const renderLogoutAndDeleteModal = () => {
    const sizes = getLogoutModalSize();

    return (
      <Modal
        show={showLogoutAndDeleteModal}
        width={sizes.width}
        minHeight={sizes.height}
      >
        <div className="flex-d flex-column">
          <div id="logout-question" className="d-flex">
            {SELF_COPIES.logoutDeleteModal.title}
          </div>

          <div id="logout-description" className="d-flex">
            {modalType === "logout"
              ? SELF_COPIES.logoutDeleteModal.logoutDescription
              : SELF_COPIES.logoutDeleteModal.deleteDescription}
          </div>
          <div className="d-flex justify-content-sm-end justify-content-center">
            <div
              id="buttons-container"
              className="d-flex flex-column-reverse flex-sm-row justify-content-around align-items-center"
            >
              <div
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#F9F5FE";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = TRANSPARENT;
                }}
                onClick={() => {
                  document.getElementById(
                    "logout-cancel-button"
                  ).style.backgroundColor = "#CDBAE7";
                  setShowLogoutAndDeleteModal(false);
                }}
                id="logout-cancel-button"
              >
                {SELF_COPIES.photoModal.cancel}
              </div>

              <div
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#573C7C";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = MAIN_PURPLE;
                }}
                onClick={
                  modalType === "logout"
                    ? async () => {
                        await internalSignOut();
                        redirectToLandingPage();
                        createAnalytic(EVENTS.LOGOUT_ACCOUNT);
                      }
                    : () => {
                        deleteAccount(
                          currentUser,
                          cleanAllRedux,
                          setFlashProps,
                          language
                        );
                      }
                }
                id="logout-confirm-button"
              >
                {modalType === "logout"
                  ? SELF_COPIES.logout
                  : SELF_COPIES.deleteAccount}
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => setShowLogoutAndDeleteModal(false)} id="close-icon">
          {renderXIcon()}
        </div>
      </Modal>
    );
  };

  /**
   * formats all labels to strings that can be used on the object that will be sent to the backend to save attributes
   * @param {String} label
   * @returns {String}
   */
  const formatLabelsToSaveValues = (label) => {
    switch (label) {
      case "Email":
        return "email";
      case "Password":
        return "password";
      case "First name":
        return "first_name";
      case "Last name":
        return "last_name";
      case "Date of birth":
        return "birthdate";
      case "Current location":
        return "country";
      case "Gender":
        return "gender";
      case "Reminders":
        return "periodicity";
      default:
        return null;
    }
  };

  /**
   * renders the data for the different dropdowns
   * @param {String} type
   * @returns {Array}
   */
  const renderDropdownsData = (type) => {
    switch (type) {
      case "Reminders":
        return renderDropDownData(language);
      case "Current location":
        return renderDropdownCountryData(language);
      case "Gender":
        return renderDropdownGenderData(language);
      default:
        return null;
    }
  };

  /**
   * renders the values for dyfferent dropdowns
   * @param {String} type
   * @returns {Object}
   */
  const renderDropdownValues = (type) => {
    switch (type) {
      case "Reminders":
        return {
          //HELP
          label: languageToReminderLabel(
            currentUser.attributes.Alert[0].periodicity,
            language
          ),
          value: currentUser.attributes.Alert[0].periodicity,
        };
      case "Current location":
        return {
          label: countryFormatter(aboutMeInfo.country, language),
          value: aboutMeInfo.country,
        };
      case "Gender":
        return {
          label:
            language === "pt-PT"
              ? languageToGenderLabel(genderFormatter(aboutMeInfo.gender))
              : genderFormatter(aboutMeInfo.gender),
          value: aboutMeInfo.gender,
        };
      default:
        return null;
    }
  };

  /**
   * handles changing on each different dropdown
   * @param {String} type
   * @param {String} value
   * @returns {void}
   */
  const onDropdownChange = (type, value) => {
    switch (type) {
      case "Reminders":
        setAlertPeriodicity(value.value);
        return;
      case "Current location":
        setAboutMeInfo({ ...aboutMeInfo, country: value ? value.value : null });
        return;
      case "Gender":
        setAboutMeInfo({ ...aboutMeInfo, gender: value ? value.value : null });
        return;
      default: {
        setAboutMeInfo({ ...aboutMeInfo });
      }
    }
  };

  /**
   * returns the dropdown styles depending on screen size
   * @param {Object} extras
   * @returns {Object}
   */
  const getCustomDropdownStyles = (extras) => {
    if (innerWidth >= 992) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0.1px solid #c2c0c7",
          borderRadius: "3px",
          height: "2.5vw",
          minHeight: "2.5vw",
          width: "60vw",
          fontSize: "1vw",
          display: "flex",
          alignItems: "center",
          ...extras,
        }),
        container: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingRight: 1,
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          overflow: "hidden !important",
          paddingLeft: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "1.5vw",
          minHeight: "1.5w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "0.5vw",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "1.5vw",
          minHeight: "1.5vw",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 0,
          cursor: "pointer",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "1vw",
        }),
      };
    } else if (innerWidth >= 576) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0.1px solid #c2c0c7",
          borderRadius: "3px",
          height: "3vw",
          minHeight: "3vw",
          width: "75vw",
          fontSize: "1.3vw",
          display: "flex",
          alignItems: "center",
          ...extras,
        }),
        container: (provided) => ({
          ...provided,
          height: "3vw",
          minHeight: "3vw",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          width: "2.5vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "1",
          paddingLeft: 1,
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "3vw",
          minHeight: "3vw",
          overflow: "hidden !important",
          paddingLeft: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "2vw",
          minHeight: "2w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "3vw",
          minHeight: "3vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "2vw",
          minHeight: "2vw",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          width: "2.5vw",
          height: "2.5vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 0,
        }),
        menuList: (base) => ({
          ...base,
          "::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
        }),
      };
    } else if (innerWidth >= 0) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0.1px solid #c2c0c7",
          borderRadius: "3px",
          height: "6vw",
          minHeight: "6vw",
          width: "80vw",
          fontSize: "2.8vw",
          display: "flex",
          alignItems: "center",
          ...extras,
        }),
        container: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "1",
          paddingLeft: 1,
          width: "5vw",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          overflow: "hidden !important",
          paddingLeft: 0,
          display: "flex",
          alignItems: "center",
          paddingTop: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          display: "flex",
          alignItems: "center",
          //eslint-disable-next-line
          display: "-webkit-flex",
          "-webkit-align-items": "center",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          width: "4.5vw",
          height: "4.5vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 0,
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "2.8vw",
          "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
          },
        }),
      };
    }
  };

  /**
   * renders password changing errors
   * @param {String} newPassword
   * @param {String} passwordConfirmation
   * @returns {Object}
   */
  const renderErrors = (newPassword, passwordConfirmation) => {
    if (
      !newPassword ||
      !passwordConfirmation ||
      isStringBlank(newPassword) ||
      isStringBlank(passwordConfirmation)
    ) {
      return {
        password: SELF_COPIES.errors.blank,
        passwordConfirmation: SELF_COPIES.errors.blank,
      };
    } else if (newPassword !== passwordConfirmation) {
      return {
        password: SELF_COPIES.errors.match,
        passwordConfirmation: SELF_COPIES.errors.match,
      };
    } else if (newPassword && newPassword.length < 8) {
      return {
        password: SELF_COPIES.errors.size,
        passwordConfirmation: SELF_COPIES.errors.size,
      };
    } else {
      return {
        password: SELF_COPIES.errors.geral,
        passwordConfirmation: SELF_COPIES.errors.geral,
      };
    }
  };

  //handles saving the new password when changed
  const savePassword = async () => {
    if (
      password.password &&
      password.passwordConfirmation &&
      password.password === password.passwordConfirmation
    ) {
      try {
        await login_backend.put(
          `/changePassword/${currentUser.attributes.user_id}`,
          {
            password: password.password,
            email: currentUser.attributes.email,
            oldPassword: location.state.oldPassword,
            language,
          }
        );

        location.state.confirmed = false;
        update();

        createAnalytic(EVENTS.PASSWORD_PROFILE_INFO_SUCCESSFUL);

        setFlashProps(FLASH_TYPES.CHANGE_PASSWORD);
      } catch (error) {
        createAnalytic(EVENTS.PASSWORD_PROFILE_INFO_FAILED);

        setPasswordErrors(
          renderErrors(password.password, password.passwordConfirmation)
        );
      }
    } else {
      createAnalytic(EVENTS.PASSWORD_PROFILE_INFO_FAILED);

      setPasswordErrors(
        renderErrors(password.password, password.passwordConfirmation)
      );
    }
  };

  /**
   * returns all date picker styles depending on screen size
   * @returns {Object}
   */
  const getNativePickerStyles = () => {
    if (innerWidth >= 992) {
      return {
        height: "2.5vw",
        minHeight: "2.5vw",
        width: "60vw",
        borderRadius: "3px",
        fontSize: "1vw",
        fontFamily: PoppinsRegular,
        color: "black",
        paddingLeft: 3,
        backgroundColor: "white",
      };
    } else if (innerWidth >= 576) {
      return {
        height: "3vw",
        minHeight: "3vw",
        width: "75vw",
        borderRadius: "3px",
        fontSize: "1.3vw",
        fontFamily: PoppinsRegular,
        color: "black",
        paddingLeft: 1,
        backgroundColor: "white",
      };
    } else if (innerWidth >= 0) {
      return {
        height: "6vw",
        minHeight: "6vw",
        width: "80vw",
        borderRadius: "3px",
        fontSize: "2.8vw",
        fontFamily: PoppinsRegular,
        color: "black",
        paddingLeft: 2,
        alignItems: "center",
        backgroundColor: "white",
      };
    }
  };

  /**
   * returns all custom x styles depending on screen size
   * @returns {Object}
   */
  const getCustomXStyles = () => {
    if (innerWidth >= 992) {
      return { right: 2, left: null };
    } else if (innerWidth >= 576) {
      return { right: 5, left: null };
    } else if (innerWidth >= 0) {
      return { right: 3, left: null };
    }
  };

  /**
   * returns the menu height depending on screen size
   * @returns {String}
   */
  const getCustomMenuHeight = () => {
    if (window.innerWidth >= 1500) {
      return "9rem";
    } else if (window.innerWidth >= 576) {
      return "7rem";
    } else if (window.innerWidth >= 0) {
      return "5rem";
    }
  };

  /**
   * renders all diferent edit inputs for each user attribute
   * @param {String} label
   * @param {String} value
   * @returns {Element}
   */
  const renderEditInputs = (label, value) => {
    return (
      <div className="d-flex flex-column edit-inputs about-me__inputs">
        <div id="edit-info-label">
          {label === "Password"
            ? SELF_COPIES.labels.newPassword
            : language !== "en-GB"
            ? getTranslatedLabel(language, label)
            : label}
        </div>

        {label === "Reminders" ||
        label === "Gender" ||
        label === "Current location" ? (
          <DropdownComponent
            data={renderDropdownsData(label)}
            customStyles={getCustomDropdownStyles()}
            value={renderDropdownValues(label)}
            onChange={(value) => onDropdownChange(label, value)}
            maxMenuHeight={
              window.innerWidth > 2000 ? "15rem" : getCustomMenuHeight()
            }
            isClearable={label !== "Reminders"}
            isSearchable={!innerWidth < 992}
          />
        ) : label === "Password" ? (
          <>
            <PasswordInput
              className="edit-info-input"
              value={aboutMeInfo[formatLabelsToSaveValues(label)] ?? value}
              onChange={(p) => setPassword({ ...password, password: p })}
            />
            <label class="error-labels-about-me">
              {passwordErrors.password}
            </label>

            <div id="edit-info-label">{SELF_COPIES.labels.confirmPassword}</div>
            <PasswordInput
              className="edit-info-input"
              onChange={(p) =>
                setPassword({ ...password, passwordConfirmation: p })
              }
              value={aboutMeInfo[formatLabelsToSaveValues(label)] ?? value}
            />

            <label class="error-labels-about-me">
              {passwordErrors.passwordConfirmation}
            </label>
          </>
        ) : label === "Date of birth" ? (
          <NativeDatepicker
            style={getNativePickerStyles()}
            onChange={(event) => {
              setAboutMeInfo({
                ...aboutMeInfo,
                [formatLabelsToSaveValues(label)]: event
                  ? event.target.value
                  : null,
              });
            }}
            value={aboutMeInfo.birthdate}
            placeholder={null}
            isClerable={true}
            xColor={"rgb(210, 210, 210)"}
            hideX={false}
            customXStyles={getCustomXStyles()}
          />
        ) : (
          <input
            class="edit-info-input"
            value={aboutMeInfo[formatLabelsToSaveValues(label)] ?? value}
            onChange={(event) => {
              setAboutMeInfo({
                ...aboutMeInfo,
                [formatLabelsToSaveValues(label)]: event.target.value,
              });
            }}
          ></input>
        )}
        <div
          id="edit-inputs-buttons-container"
          className="d-flex justify-content-end"
        >
          <div
            id="container-inside-buttons-container"
            className="d-flex justify-content-between"
          >
            <div
              onClick={
                label === "Password"
                  ? () => {
                      let state = { ...history.location.state };
                      delete state.confirmed;
                      history.replace({ ...history.location, state });
                      setEnabledInputs({
                        first_name: false,
                        last_name: false,
                        gender: false,
                        birthdate: false,
                        email: false,
                        password: false,
                        country: false,
                      });
                    }
                  : () => {
                      setEnabledInputs({
                        ...enabledInputs,
                        [formatLabelsToSaveValues(label)]: false,
                      });
                    }
              }
              id="cancel-button"
            >
              {SELF_COPIES.photoModal.cancel}
            </div>

            <div
              onClick={
                label === "Reminders"
                  ? () => {
                      savePeriodicity(
                        currentUser,
                        alertPeriodicity,
                        fetchUserWithAllAttributes
                      );
                      setEnabledInputs({
                        ...enabledInputs,
                        periodicity: false,
                      });

                      if (alertPeriodicity === "don't receive reminders") {
                        createAnalytic(EVENTS.REMINDER_OFF_PROFILE);
                      } else {
                        createAnalytic(getAboutMeFieldEvent(label));
                      }
                    }
                  : label === "Password"
                  ? () => {
                      savePassword();
                      setEnabledInputs({
                        first_name: false,
                        last_name: false,
                        gender: false,
                        birthdate: false,
                        email: false,
                        password: false,
                        country: false,
                      });

                      createAnalytic(getAboutMeFieldEvent(label));
                    }
                  : async () => {
                      let errors = {};

                      createAnalytic(getAboutMeFieldEvent(label));

                      if (isStringBlank(aboutMeInfo.first_name)) {
                        errors = {
                          ...errors,
                          first_name: FLASH_TYPES.ABOUT_ME_INVALID_FIRST_NAME,
                        };
                      }

                      if (isStringBlank(aboutMeInfo.last_name)) {
                        errors = {
                          ...errors,
                          last_name: FLASH_TYPES.ABOUT_ME_INVALID_LAST_NAME,
                        };
                      }

                      if (
                        aboutMeInfo.birthdate &&
                        !isDateBetweenExtremes(new Date(aboutMeInfo.birthdate))
                      ) {
                        errors = {
                          ...errors,
                          birthdate: {
                            ...FLASH_TYPES.ABOUT_ME_BIRTHDATE_PREFIX,
                            type: `${
                              FLASH_TYPES.ABOUT_ME_BIRTHDATE_PREFIX.type
                            }-${getBirthdateError(
                              new Date(aboutMeInfo.birthdate)
                            )}`,
                          },
                        };
                      }

                      if (aboutMeInfo.email !== currentUser.attributes.email) {
                        if (await doesEmailExist(aboutMeInfo.email)) {
                          errors = {
                            ...errors,
                            email: FLASH_TYPES.ABOUT_ME_EMAIL_ALREADY_EXISTS,
                          };
                        }
                      }

                      if (Object.keys(errors).length) {
                        const newObject = Object.entries(errors).reduce(
                          (prev, [key]) => {
                            return {
                              ...prev,
                              [key]: currentUser.attributes[key],
                            };
                          },
                          aboutMeInfo
                        );
                        setAboutMeInfo(newObject);

                        setFlashProps(Object.values(errors)[0]);
                      } else {
                        saveAboutMeAttributes(
                          currentUser,
                          fetchUserWithAllAttributes,
                          setEnabledInputs,
                          aboutMeInfo,
                          setFlashProps,
                          internalSignOut
                        );
                      }
                    }
              }
              id="save-details-button"
            >
              {SELF_COPIES.otherButtons.saveDetails}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * renders the different color background for each input
   * @param {String} place
   * @returns {Element}
   */
  const renderInputBackground = (place) => {
    if (place === "Password") {
      return (
        <div
          id={`background-rectangle-${place}`}
          class="input-container-big"
        ></div>
      );
    }

    return (
      <div id={`background-rectangle-${place}`} class="input-container"></div>
    );
  };

  /**
   * renders the about me section of my profile, were all the user attributes can be checked and changed
   * @returns {Element}
   */
  const renderAboutMeSection = () => {
    return (
      <>
        <div className="info-field d-flex justify-content-between align-items-end about-me__email-input">
          <div ref={passwordRef} class="d-flex flex-column">
            {!enabledInputs.email ? (
              <div class="info-field-name">{SELF_COPIES.labels.email}</div>
            ) : null}
            {!enabledInputs.email ? (
              <div class="info-field-value">{currentUser.attributes.email}</div>
            ) : null}

            {enabledInputs.email
              ? renderEditInputs("Email", currentUser.attributes.email)
              : null}
            {enabledInputs.email ? renderInputBackground() : null}
          </div>
          {currentUser.attributes.account_type === "internal" ? (
            <div
              onClick={() => {
                setEnabledInputs({
                  first_name: false,
                  last_name: false,
                  gender: false,
                  birthdate: false,
                  email: true,
                  password: false,
                  country: false,
                });
              }}
              class="edit-button"
              style={{ display: enabledInputs.email ? "none" : null }}
            >
              {SELF_COPIES.labels.edit}
            </div>
          ) : null}
        </div>
        <div className="info-field d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            {!enabledInputs.password ? (
              <div class="info-field-name">Password</div>
            ) : null}
            {!enabledInputs.password ? (
              <div class="info-field-value">*********</div>
            ) : null}
            {location.state && location.state.confirmed
              ? renderEditInputs("Password")
              : null}
            {location.state && location.state.confirmed
              ? renderInputBackground("Password")
              : null}
          </div>
          {currentUser.attributes.account_type === "internal" ? (
            <div
              style={{ display: enabledInputs.password ? "none" : null }}
              class="edit-button"
              onClick={() => {
                redirectToConfirmPasswordPage();
                setEnabledInputs({
                  first_name: false,
                  last_name: false,
                  gender: false,
                  birthdate: false,
                  email: false,
                  password: true,
                  country: false,
                });
              }}
            >
              {SELF_COPIES.labels.edit}
            </div>
          ) : null}
        </div>{" "}
        <div class="info-field d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            {!enabledInputs.first_name ? (
              <div class="info-field-name">{SELF_COPIES.labels.firstName}</div>
            ) : null}
            {!enabledInputs.first_name ? (
              <div class="info-field-value">
                {currentUser.attributes.first_name}
              </div>
            ) : null}
            {enabledInputs.first_name
              ? renderEditInputs(
                  "First name",
                  currentUser.attributes.first_name
                )
              : null}
            {enabledInputs.first_name ? renderInputBackground() : null}
          </div>
          <div
            style={{ display: enabledInputs.first_name ? "none" : null }}
            class="edit-button"
            onClick={() => {
              setEnabledInputs({
                first_name: true,
                last_name: false,
                gender: false,
                birthdate: false,
                email: false,
                password: false,
                country: false,
              });
            }}
          >
            {SELF_COPIES.labels.edit}
          </div>
        </div>
        <div class="info-field d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            {!enabledInputs.last_name ? (
              <div class="info-field-name">{SELF_COPIES.labels.lastName}</div>
            ) : null}
            {!enabledInputs.last_name ? (
              <div class="info-field-value">
                {currentUser.attributes.last_name}
              </div>
            ) : null}
            {enabledInputs.last_name
              ? renderEditInputs("Last name", currentUser.attributes.last_name)
              : null}
            {enabledInputs.last_name ? renderInputBackground() : null}
          </div>
          <div
            style={{ display: enabledInputs.last_name ? "none" : null }}
            class="edit-button"
            onClick={() => {
              setEnabledInputs({
                first_name: false,
                last_name: true,
                gender: false,
                birthdate: false,
                email: false,
                password: false,
                country: false,
              });
            }}
          >
            {SELF_COPIES.labels.edit}
          </div>
        </div>
        <div class="info-field d-flex justify-content-between align-items-end">
          <div className=" d-flex flex-column">
            {!enabledInputs.gender ? (
              <div class="info-field-name">{SELF_COPIES.labels.gender}</div>
            ) : null}
            {!enabledInputs.gender ? (
              <div class="info-field-value">
                {currentUser.attributes.gender
                  ? language === "pt-PT"
                    ? languageToGenderLabel(
                        genderFormatter(currentUser.attributes.gender)
                      )
                    : genderFormatter(currentUser.attributes.gender)
                  : SELF_COPIES.noInfo}
              </div>
            ) : null}
            {enabledInputs.gender
              ? renderEditInputs("Gender", currentUser.attributes.gender)
              : null}
            {enabledInputs.gender ? renderInputBackground() : null}
          </div>
          <div
            style={{ display: enabledInputs.gender ? "none" : null }}
            class="edit-button"
            onClick={() => {
              setEnabledInputs({
                first_name: false,
                last_name: false,
                gender: true,
                birthdate: false,
                email: false,
                password: false,
                country: false,
              });
            }}
          >
            {SELF_COPIES.labels.edit}
          </div>
        </div>
        <div class="info-field d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            {!enabledInputs.birthdate ? (
              <div class="info-field-name">{SELF_COPIES.labels.birthdate}</div>
            ) : null}
            {!enabledInputs.birthdate ? (
              <div class="info-field-value">
                {currentUser.attributes.birthdate
                  ? dateFormatter(currentUser.attributes.birthdate)
                  : SELF_COPIES.noInfo}
              </div>
            ) : null}
            {enabledInputs.birthdate
              ? renderEditInputs(
                  "Date of birth",
                  currentUser.attributes.birthdate
                )
              : null}
            {enabledInputs.birthdate ? renderInputBackground() : null}
          </div>
          <div
            style={{ display: enabledInputs.birthdate ? "none" : null }}
            class="edit-button"
            onClick={() => {
              setEnabledInputs({
                first_name: false,
                last_name: false,
                gender: false,
                birthdate: true,
                email: false,
                password: false,
                country: false,
              });
            }}
          >
            {SELF_COPIES.labels.edit}
          </div>
        </div>
        <div class="info-field d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            {!enabledInputs.country ? (
              <div class="info-field-name">
                {SELF_COPIES.labels.currentLocation}
              </div>
            ) : null}
            {!enabledInputs.country ? (
              <div class="info-field-value">
                {countryFormatter(currentUser.attributes.country, language) ??
                  SELF_COPIES.noInfo}
              </div>
            ) : null}
            {enabledInputs.country
              ? renderEditInputs(
                  "Current location",
                  currentUser.attributes.country
                )
              : null}
            {enabledInputs.country ? renderInputBackground() : null}
          </div>
          <div
            style={{ display: enabledInputs.country ? "none" : null }}
            class="edit-button"
            onClick={() => {
              setEnabledInputs({
                first_name: false,
                last_name: false,
                gender: false,
                birthdate: false,
                email: false,
                password: false,
                country: true,
              });
            }}
          >
            {SELF_COPIES.labels.edit}
          </div>
        </div>
      </>
    );
  };

  /**
   * renders the settings sections where the user can change the alert periodicity
   * @returns {Element}
   */
  const renderSettingsSection = () => {
    return (
      <div class="info-field d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          {!enabledInputs.periodicity ? (
            <div class="info-field-name">{SELF_COPIES.labels.reminders}</div>
          ) : null}
          {!enabledInputs.periodicity ? (
            <div class="info-field-value">
              {languageToReminderLabel(
                currentUser.attributes.Alert[0].periodicity,
                language
              )}
            </div>
          ) : null}

          {enabledInputs.periodicity
            ? renderEditInputs(
                "Reminders",
                currentUser.attributes.Alert[0].periodicity
              )
            : null}
          {enabledInputs.periodicity ? renderInputBackground() : null}
        </div>
        <div
          onClick={() => {
            setEnabledInputs({
              first_name: false,
              last_name: false,
              gender: false,
              birthdate: false,
              email: false,
              password: false,
              country: false,
              periodicity: true,
            });
          }}
          class="edit-button"
          style={{ display: enabledInputs.email ? "none" : null }}
        >
          {SELF_COPIES.labels.edit}
        </div>
      </div>
    );
  };

  return (
    <div id="about-me-container">
      <HeaderFirstVersion onProfile={true} />
      {renderModal()}
      {renderLogoutAndDeleteModal()}
      <GreyBackgroundRectangle aboutMe={true}>
        <div id="avatar-container">
          <Avatar
            name={
              currentUser.attributes.first_name +
              " " +
              currentUser.attributes.last_name
            }
            color="#604586"
            round={true}
            onClick={onImageClick}
            src={
              currentUser.attributes.image
                ? `data:image/png;base64,${toBase64(
                    currentUser.attributes.image.data
                  )}`
                : null
            }
            size={getAvatarSize()}
            style={getAvatarFont()}
          />

          <div onClick={onImageClick} id="edit-image">
            <img
              alt="pencil"
              src="images/pencil.png"
              srcSet="images/pencil2x.png, images/pencil3x.png"
              id="pencil-image"
            ></img>
          </div>
        </div>

        <div id="page-title">{`${currentUser.attributes.first_name} ${currentUser.attributes.last_name}`}</div>

        <div id="user-since-section">
          {SELF_COPIES.userSince} {getUserSinceDate(currentUser)}
        </div>

        <PageNavbar
          purpleProps={{ text: SELF_COPIES.aboutMe, pageId: "profile" }}
          whiteProps={{ text: SELF_COPIES.settings, pageId: "settings" }}
          onChange={(newPage) => {
            setPage(newPage);
            setEnabledInputs({
              first_name: false,
              last_name: false,
              gender: false,
              birthdate: false,
              email: false,
              password: false,
              country: false,
              periodicity: false,
            });
          }}
          defaultValue={getPage()}
        />
      </GreyBackgroundRectangle>

      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="second-rectangle"
      >
        <div id="info-fields-container">
          {page === "profile"
            ? renderAboutMeSection()
            : renderSettingsSection()}
        </div>
        <div
          id="bottom-buttons-container"
          className="d-flex flex-column justify-content-between align-items-center"
          style={{ marginBottom: page !== "profile" ? "20%" : null }}
        >
          <div
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#573C7C";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = MAIN_PURPLE;
            }}
            onClick={() => {
              document.getElementById("logout-button").style.backgroundColor =
                "#CDBAE7";
              setShowLogoutAndDeleteModal(true);
              setModalType("logout");
            }}
            id="logout-button"
          >
            {SELF_COPIES.logout}
          </div>

          <div
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#F9F5FE";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = TRANSPARENT;
            }}
            onClick={() => {
              document.getElementById(
                "delete-account-button"
              ).style.backgroundColor = "#CDBAE7";
              setShowLogoutAndDeleteModal(true);
              setModalType("delete-account");
            }}
            id="delete-account-button"
          >
            {SELF_COPIES.deleteAccount}
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          id="bottom-image-container"
        >
          <img
            alt=""
            src="images/yoga-girl.png"
            srcSet="images/yoga-girl2x.png, images/yoga-girl3x.png"
            class="bottom-image"
          ></img>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, language: state.language };
};

export default connect(mapStateToProps, {
  internalSignOut,
  fetchUserWithAllAttributes,
  cleanAllRedux,
  setFlashProps,
})(AboutMe);
