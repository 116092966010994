import React from "react";
import "./ComparationCarousel.css";
import "./Carousel.css";
import { Carousel } from "react-bootstrap";
import { MAIN_PURPLE } from "../../../../utils/constants/colorsConstants";
import { useResize } from "../../../Geral/hooks/useResize";

const { Item } = Carousel;

/**
 * returns specific styles depending on width and number
 * @param {Number} width
 * @param {Number} number
 * @returns
 */
const getStyles = ({ width, number }) => {
  if (number && width < 1200) return { width: "3rem" };
  else if (number) return { width: "3.5vw", height: "auto" };
  else return {};
};

/**
 * Functional Component: singleFilterSection
 * Renders one single filter section
 * @param {Int} number
 * @param {String} text
 * @param {Element} arrow
 * @param {Number} width
 * @returns {Element}
 */
const FilterSection = ({ number, text, arrow }, width) => {
  return (
    <div key={text} className="col-12 col-sm-6 col-lg-4 mx-auto">
      <div className="text-center align-items-center d-flex justify-content-center comparation-carousel__carousel">
        {arrow ? (
          <div
            className="static-image"
            style={{
              position: "absolute",
              transform: `translate(${number ? "-300%" : 0}, 0)`,
            }}
          >
            <img style={getStyles({ width, number })} src={arrow} alt="arrow" />
          </div>
        ) : null}

        <h1
          style={{ color: MAIN_PURPLE }}
          className="ff-popp-bold comparation-carousel__number text-center"
        >
          {number}
        </h1>
      </div>

      <div className="text-center mx-auto comparation-carousel__text">
        <div>{text}</div>
      </div>
    </div>
  );
};

/**
 * Functional Component: RowItem
 * Each item of the carousel
 * @param {String} children
 * @returns {Element}
 */
const RowItem = ({ children }) => {
  return (
    <div className="row-item d-flex justify-content-around">{children}</div>
  );
};

/**
 * Functional Component: ComparationCarousel
 * The carousel on the comparison zone of results
 * @returns {Element}
 */
const ComparationCarousel = ({ ...props }) => {
  return (
    <Carousel
      interval={null}
      className="comparation-carousel d-flex flex-column-reverse"
      controls={false}
      {...props}
    />
  );
};

/**
 * Functional Component: lgVersion
 * @param {Array} sections
 * @param {Number} width
 * @returns {Component}
 */
const lgVersion = (sections, width) => {
  return <RowItem>{sections.map((s) => FilterSection(s, width))}</RowItem>;
};

/**
 * Functional Component: mdVersion
 * @param {Array} sections
 * @param {Number} width
 * @returns {Component}
 */
const mdVersion = (sections, width) => {
  return (
    <ComparationCarousel>
      <Item>
        <RowItem>
          {sections.slice(0, 2).map((s) => FilterSection(s, width))}
        </RowItem>
      </Item>

      <Item>
        <RowItem>{FilterSection(sections[2], width)}</RowItem>
      </Item>
    </ComparationCarousel>
  );
};

/**
 * @param {Array} sections
 * @param {Number} width
 * @returns {Component}
 */
const smVersion = (sections, width) => {
  return (
    <ComparationCarousel>
      {sections.map((section) => {
        return (
          <Item>
            <RowItem>{FilterSection(section, width)}</RowItem>
          </Item>
        );
      })}
    </ComparationCarousel>
  );
};

/**
 * Functional Component: FilterSections
 * Renders the layout of the sections, depending on the window size
 * @param {Array} sections
 * @returns {Element}
 */
const FilterSections = ({ sections }) => {
  const [width] = useResize();

  if (width < 576) return smVersion(sections, width);
  else if (width < 992) return mdVersion(sections, width);
  else return lgVersion(sections, width);
};

export { FilterSections };
