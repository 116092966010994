import Cookies from "universal-cookie";
const cookies = new Cookies();
const acceptanceCookie = cookies.get("cookie_acceptance");
const registeredUserCookie = cookies.get("registered_user");

//sets the cookie options depending if it is localhost or production
const cookiesOptions = process.env.REACT_APP_IS_SERVER
  ? {
      path: "/",
      domain: ".pur-ple.io",
      expires: new Date(2100, 0, 1),
      //maxAge: 63072000000,
    }
  : {
      path: "/",
      expires: new Date(2100, 0, 1),
      //maxAge: 63072000000
    };

//checks if users has taken action about cookies in the past
const doesAcceptanceCookieExist = typeof acceptanceCookie !== "undefined";

//checks if cookies are enabled
const areCookiesEnabled = navigator.cookieEnabled;

/**
 * checks if a user is allowed to login
 * @returns {Boolean}
 */
const canLogin = () =>
  areCookiesEnabled && doesAcceptanceCookieExist && acceptanceCookie.functional;

/**
 * checks if a user accepted glossary cookies
 * @returns {Boolean}
 */
const canTrackGlossary = () =>
  areCookiesEnabled && doesAcceptanceCookieExist && acceptanceCookie.experience;

/**
 * checks if experience cookies are allowed to be used
 * @returns {Boolean}
 */
const canTrackIfUserIsRegistered = () => {
  return (
    areCookiesEnabled &&
    doesAcceptanceCookieExist &&
    acceptanceCookie.experience
  );
};

const canTrackAnalytics = () =>
  areCookiesEnabled && doesAcceptanceCookieExist && acceptanceCookie.analytics;

//sets the acceptance cookies
const setAcceptanceCookie = (newCookie) =>
  cookies.set("cookie_acceptance", newCookie, cookiesOptions);

//removes all the cookies related to experience
const removeExperienceCookies = () => {
  cookies.remove("registered_user", cookiesOptions);
  cookies.remove("saw_core_glossary_teaser", cookiesOptions);
  cookies.remove("saw_trial_glossary_teaser", cookiesOptions);
};

//removes all cookies related to analytics
const removeAnalyticsCookies = () => {
  cookies.remove("_gid", cookiesOptions);
  cookies.remove("_gat_gtag_UA_35311532_3", cookiesOptions);
  cookies.remove("_ga", cookiesOptions);
  cookies.remove("_gat", cookiesOptions);
};

//checks the cookie for registered user
const doesRegisteredUserCookieExist =
  typeof registeredUserCookie !== "undefined";

//sets the cookies for registered user
const setRegisteredUserCookie = () =>
  cookies.set("registered_user", true, cookiesOptions);

//checks if user has already logged in at least once
const hasUserAuthenticated = registeredUserCookie === "true";

export {
  cookiesOptions,
  canLogin,
  canTrackGlossary,
  canTrackIfUserIsRegistered,
  doesAcceptanceCookieExist,
  areCookiesEnabled,
  setAcceptanceCookie,
  removeExperienceCookies,
  removeAnalyticsCookies,
  doesRegisteredUserCookieExist,
  setRegisteredUserCookie,
  hasUserAuthenticated,
  canTrackAnalytics,
};
