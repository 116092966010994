import React from "react";
import { MAIN_TEXT } from "../../../utils/constants/colorsConstants";

/**
 * Functional Component: TextSection
 * A section full of text, to be used in pages like PrivacyPolicy and TermsOfService
 * @param {String} title
 * @param {String, Int or Elements} children
 * @param {Object} customStyles
 * @param {String} customClassName
 * @returns {Element}
 */
const TextSection = ({
  title,
  children,
  customStyles = {},
  customClassName = "",
}) => {
  return (
    <div className={`mb-5 ${customClassName}`} style={{ color: MAIN_TEXT }}>
      <h4
        style={{
          fontFamily: "PoppinsSemiBold",
          marginBottom: "0.6rem",
          ...customStyles.title,
        }}
      >
        {title}
      </h4>
      <div style={{ lineHeight: "1.7rem" }}>{children}</div>
    </div>
  );
};

export default TextSection;
