import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchSurvey,
  fetchUserWithAllAttributes,
  setFlashProps,
} from "../../../store/actions";
import {
  CORE_SURVEY_ID,
  WELCOME_BACK_PAGE_ID,
} from "../../../utils/constants/constants";
import {
  formatZone,
  getAllResources,
  getTipsWithDimension,
  makeResultsPageCopies,
} from "../services/welcomeBackPage.service";
import CoreResultsTemplate from "../../Results/components/infoParts/CoreResultsTemplate";
import Loading from "../../../components/loading/Loading";
import { getResultShareURL } from "../../../utils/helpers/geralHelpers/RandomHelper";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import Footer from "../../../components/footer/Footer";
import { getResultsPageCopies } from "../../../utils/constants/copies/copies.helper";
import { ZONE_COLORS } from "../../../utils/constants/colorsConstants";

/**
 * Functional Component: WelcomeBack
 * The welcome back page is what the user sees if he logs in after already completing the survey today
 * it is almost identical to the results page
 *
 * @param {Object} currentUser
 * @param {Function} fetchSurvey
 * @param {Object} survey
 * @param {Function} fetchUserWithAllAttributes
 * @param {Function} setFlashProps
 * @param {String} language
 * @returns {Element}
 */
const WelcomeBack = ({
  fetchSurvey,
  fetchUserWithAllAttributes,
  setFlashProps,
  language,
  survey,
  currentUser,
}) => {
  const [userFetch, setUserFetch] = useState(false);
  const [resources, setResources] = useState(null);
  const [copies, setCopies] = useState(null);

  //refetches the user to update attributes that might have been changed on another device
  useEffect(() => {
    const refetchUser = async () => {
      await fetchUserWithAllAttributes(currentUser.attributes.user_id);
      setUserFetch(!userFetch);
    };
    refetchUser();
    //eslint-disable-next-line
  }, []);

  //gets all needed resources for the page to render
  useEffect(() => {
    const saveResources = async () => {
      await fetchSurvey(CORE_SURVEY_ID);
      const resources = await getAllResources(currentUser);
      setResources(resources);
    };

    saveResources();
  }, [currentUser, fetchSurvey]);

  useEffect(() => {
    const makeAllCopies = async () => {
      const {
        lastResult,
        lastResultBeforeToday,
        purposePercentage,
        filteredAverages,
        dimensions,
        lowestDimension,
        highestDimension,
      } = resources;

      const variableCopies = await getResultsPageCopies({
        language,
        name: currentUser.attributes.first_name,
        page: WELCOME_BACK_PAGE_ID,
        lastResultScore: lastResult.attributes.total,
        lastResultBeforeToday,
        purposePercentage,
        comparationAverages: filteredAverages.data,
        gender: currentUser.attributes.gender,
      });

      const tips = await getTipsWithDimension({
        lastResult,
        dimensions,
        language,
        gender: currentUser.attributes.gender,
      });

      const newCopies = makeResultsPageCopies({
        language,
        name: currentUser.attributes.first_name,
        lowestDimension,
        highestDimension,
        lastResult,
        dimensions,
        survey,
        page: WELCOME_BACK_PAGE_ID,
      });

      setCopies({ ...newCopies, tips, variableCopies });
    };

    if (currentUser && resources && language && survey.possible_results)
      makeAllCopies();
  }, [currentUser, language, survey, resources]);

  if (!resources || !copies) return <Loading />;

  return (
    <div id="welcome-back-page-container">
      <HeaderFirstVersion />

      <CoreResultsTemplate
        page={WELCOME_BACK_PAGE_ID}
        currentUser={currentUser}
        setFlashProps={setFlashProps}
        language={language}
        //
        colors={ZONE_COLORS[copies.colorsZone]}
        zone={formatZone(copies.copyZone)}
        copies={copies.staticCopies}
        variableCopies={copies.variableCopies}
        fetchCurrentUser={fetchUserWithAllAttributes}
        results={copies.results}
        score={resources.lastResult.attributes.total}
        //
        focusCopy={copies.focusCopy.content}
        focusImage={copies.focus.image}
        tips={copies.tips}
        //
        share_url={getResultShareURL(
          resources.lastResult,
          language,
          currentUser.attributes.gender
        )}
        share_id={resources.lastResult.attributes.share_id}
      />

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    survey: state.survey,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchSurvey,
  fetchUserWithAllAttributes,
  setFlashProps,
})(WelcomeBack);
