import React from "react";

const ConfidentialityIcon = () => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1691.85 1080"
      style={{ height: "auto", width: "100%" }}
    >
      <defs>
        <linearGradient
          id="Gradiente_sem_nome_3"
          x1="1270.11"
          y1="1969.71"
          x2="1270.11"
          y2="2384.34"
          gradientTransform="matrix(0.93, 0, 0, -0.94, 513.06, 2340.65)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#dccee7" />
          <stop offset="1" stop-color="#fff" stop-opacity="0.5" />
        </linearGradient>
        <linearGradient
          id="Gradiente_sem_nome_3-2"
          x1="-137.21"
          y1="1413.25"
          x2="-137.21"
          y2="1828.02"
          xlinkHref="#Gradiente_sem_nome_3"
        />
        <linearGradient
          id="Gradiente_sem_nome_3-3"
          x1="-531.2"
          y1="787.95"
          x2="-531.2"
          y2="-357.51"
          gradientTransform="matrix(-0.93, 0, 0, 0.94, 565.98, 337.08)"
          xlinkHref="#Gradiente_sem_nome_3"
        />
      </defs>
      <g id="surface1">
        <path
          class="cls-1"
          style={{ fill: "url(#Gradiente_sem_nome_3)" }}
          d="M1499.91,288c0-107.9,86.16-195.47,192.53-195.47S1885,180.13,1885,288s-86.3,195.46-192.53,195.46S1499.91,396.05,1499.91,288Z"
          transform="translate(-193.12)"
        />
        <path
          class="cls-2"
          style={{ fill: "url(#Gradiente_sem_nome_3-2)" }}
          d="M193.12,812.56c0-107.9,86.17-195.47,192.53-195.47s192.53,87.57,192.53,195.47S492,1008.15,385.65,1008.15,193.12,920.58,193.12,812.56Z"
          transform="translate(-193.12)"
        />
        <path
          class="cls-3"
          style={{ fill: "url(#Gradiente_sem_nome_3-3)" }}
          d="M527.42,540c0,298.25,238,540,531.82,540s531.82-241.75,531.82-540S1352.89,0,1059.24,0,527.42,241.75,527.42,540Z"
          transform="translate(-193.12)"
        />
      </g>
    </svg>
  );
};

export default ConfidentialityIcon;
