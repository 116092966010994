import {
  FETCH_SURVEY,
  INTERNAL_SIGN_OUT,
  CLEAN_SURVEY,
  SAVE_SURVEY_PROPERTIES,
  CLEAN_SURVEY_PROPERTIES,
  RANDOM_SURVEY,
  SPECIFIC_SURVEY,
  CORE_OR_TRIAL,
  SAVE_REORDERED_QUESTIONS,
  SAVE_SURVEY,
  SAVE_TRIAL,
} from "../../../../store/actions/types";

/**
 * reducer for get survey action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const surveyReducer = (state = {}, action) => {
  if (
    action.type === FETCH_SURVEY ||
    action.type === SPECIFIC_SURVEY ||
    action.type === RANDOM_SURVEY
  ) {
    return action.payload.data;
  } else if (
    action.type === INTERNAL_SIGN_OUT ||
    action.type === CLEAN_SURVEY
  ) {
    return {};
  } else if (action.type === SAVE_SURVEY) {
    return action.payload;
  }
  return state;
};

/**
 * reducer for survey properties action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const surveyPropertiesToCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_SURVEY_PROPERTIES:
      return action.payload;
    case CLEAN_SURVEY_PROPERTIES:
    case INTERNAL_SIGN_OUT:
      return null;
    default:
      return state;
  }
};

/**
 * reducer for core or trial action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const coreOrTrialReducer = (state = null, action) => {
  if (action.type === CORE_OR_TRIAL) {
    return action.payload.data;
  } else if (action.type === SAVE_TRIAL) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return null;
  }
  return state;
};

/**
 * reducer for trial survey questions reordered action
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
const reorderedTrialQuestionsReducer = (state = null, action) => {
  if (action.type === SAVE_REORDERED_QUESTIONS) {
    return action.payload;
  } else if (action.type === INTERNAL_SIGN_OUT) {
    return null;
  }
  return state;
};

export {
  surveyReducer,
  surveyPropertiesToCreateReducer,
  coreOrTrialReducer,
  reorderedTrialQuestionsReducer,
};
