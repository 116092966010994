import "chart.js/auto";
import { Chart, defaults } from "chart.js";
import { Line } from "react-chartjs-2";
import { TRANSPARENT, WHITE } from "../../../utils/constants/constants";
import annotationPlugin from "chartjs-plugin-annotation";
import datalabelsPlugin from "chartjs-plugin-datalabels";
import { zoneObjectFromScore } from "../../../utils/helpers/geralHelpers/ZonesHelper";
Chart.register(annotationPlugin);
Chart.register(datalabelsPlugin);

defaults.font.family = "PoppinsRegular";

//this component contains the progress overtime graph made with chart js

/**
 * returns the graph aspect ratio depending on screen size
 * @returns {Int}
 */
const getAspectRatio = () => {
  if (window.innerWidth >= 768) return 2;
  else return 1;
};

/**
 * returns all graph configurations
 * @param {Array} labels
 * @param {Array} userData
 * @param {Array} globalData
 * @param {Object} copies
 * @returns {Object}
 */
const getConfigs = ({ labels, userData, globalData, copies }) => {
  //the data set that belongs to the current user
  const userDataset = {
    label: copies.myselfLabel,
    //borderColor: "#614586",
    //backgroundColor: "white",
    data: userData,
    spanGaps: true,
  };

  //the data set that belongs to all other users except the current user
  const globalDataset = {
    label: copies.othersLabel,
    //backgroundColor: "white",
    data: globalData,
    //borderColor: DARK_PURPLE
    spanGaps: true,
  };

  /**
   * returns the labels size depending on screen size
   * @returns {Int}
   */
  const getLabelsSize = () => {
    if (window.innerWidth >= 992) {
      return 15;
    } else if (window.innerWidth >= 0) {
      return 10;
    }
  };

  /**
   * returns the ticks size depending on screen size
   * @returns {Int}
   */
  const getXAndYTicksSize = () => {
    if (window.innerWidth >= 992) {
      return 11;
    } else if (window.innerWidth >= 0) {
      return 8;
    }
  };

  /**
   * returns the lines border width depending on screen size
   * @returns {Int}
   */
  const getLineBorderWidth = () => {
    if (window.innerWidth >= 992) {
      return 5;
    } else if (window.innerWidth >= 0) {
      return 3;
    }
  };

  /**
   * returns the points size depending on screen size
   * @returns {Object}
   */
  const getPointSizes = () => {
    if (window.innerWidth >= 992) {
      return {
        radius: 6,
        borderWidth: 4,
        hoverRadius: 7,
        hoverBorderWidth: 5,
      };
    } else if (window.innerWidth >= 0) {
      return {
        radius: 5,
        borderWidth: 3,
        hoverRadius: 6,
        hoverBorderWidth: 4,
      };
    }
  };

  /**
   * returns the labels padding depending on screen size
   * @returns {Int}
   */
  const getLabelsPadding = () => {
    if (window.innerWidth >= 992) {
      return 45;
    } else {
      return 18;
    }
  };

  return {
    type: "line",
    data: { labels, datasets: [globalDataset, userDataset] },
    options: {
      animation: {
        x: {
          duration: 2000,
          from: 0,
        },
        y: {
          duration: 2000,
          from: 500,
        },
      },
      // eslint-disable-next-line
      animation: false,
      responsive: true,
      mantainAspectRatio: false,
      aspectRatio: getAspectRatio(),
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          maxHeight: 60,
          maxWidth: 10,
          reverse: true,
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            boxWidth: 8,
            boxHeight: 8,
            padding: getLabelsPadding(),
            font: {
              size: getLabelsSize(),
            },
            filter: (a) => {
              //sets the labels colors properlly
              if (a.text === copies.othersLabel) {
                a.strokeStyle = "#CDBAE7";
                a.fillStyle = "white";
                return true;
              } else {
                a.strokeStyle = "#614586";
                a.fillStyle = "white";
                return true;
              }
            },
          },
        },
      },
      tension: 0, // 0.4
      elements: {
        line: {
          borderWidth: getLineBorderWidth(),
          borderColor: ["#CDBAE7", "#614586"],

          //borderDashOffset: 0
        },
        point: {
          ...getPointSizes(),
          borderColor: (a) => {
            //sets the border colors properly depending on the label
            if (a.dataset.label === copies.othersLabel) {
              return "#CDBAE7";
            } else {
              return "#614586";
            }
          },
          backgroundColor: (a) => zoneObjectFromScore(a.raw).colors.main,
        },
      },
      scales: {
        y: {
          grid: {
            color: "#efefef",
            display: true,
            tickLength: 1,
            borderColor: TRANSPARENT,
          },
          beginAtZero: true,
          max: 100,
          ticks: {
            display: true,
            stepSize: 2,
            minRotation: 0,
            labelOffset: -5,
            color: "#666666",
            padding: 10,

            callback: (a) => {
              switch (a) {
                case 0:
                  return "";
                case 10:
                  return "";
                case 20:
                  return "20";
                case 30:
                  return "";
                case 40:
                  return "40";
                case 50:
                  return "";
                case 60:
                  return "60";
                case 70:
                  return "";
                case 80:
                  return "80";
                case 90:
                  return "";
                case 100:
                  return "100";
                default:
                  return null;
              }
            },
            font: {
              size: getXAndYTicksSize(),
            },
          },
        },
        x: {
          grid: {
            color: WHITE,
            display: false,
            borderColor: TRANSPARENT,
          },
          ticks: {
            align: "center",
            color: (tick) =>
              tick.index === labels.length - 1 ? "#666666" : "#666666",
            font: {
              size: getXAndYTicksSize(),
            },
          },
        },
      },
    },
  };
};

/**
 * @param {Array} labels
 * @param {Array} userData
 * @param {Array} globalData
 * @param {Object} copies
 * @returns {Component}
 */
const AnalyticsGraph = ({ labels, userData, globalData, copies }) => {
  return <Line {...getConfigs({ labels, userData, globalData, copies })} />;
};

export default AnalyticsGraph;
