import {
  INTERNAL_SIGN_OUT,
  SAVE_QUERY_PARAMS,
} from "../../../../store/actions/types";

/**
 * redcuer for query params action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const queryReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_QUERY_PARAMS:
      return action.payload;
    case INTERNAL_SIGN_OUT:
      return null;
    default:
      return state;
  }
};

export { queryReducer };
