import backend from "../../../core/apis/backend";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";
import { translateTipById } from "../../../utils/constants/copies/tipsCopies.helper";
import {
  fetchDimensions,
  fetchSurvey,
  fetchTip,
  fetchUserSurveyLastResult,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import { isThereCoreSurveyResultToday } from "../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import { getAllFilteredAverages } from "../../Results/services/CompareWithOthers.service";
import { countryFormatter } from "../../Users/services/aboutMe.service";
import {
  formatTip,
  getDimension,
  getLastResultFocusCopy,
  getZone,
} from "../../Users/services/welcomeBackPage.service";

/**
 * returns all the info needed from the last result
 * @param {Object} currentUser
 * @param {String} language
 * @returns {Object}
 */
const getLastResultsInfo = async (currentUser, language) => {
  const lastResult = await fetchUserSurveyLastResult(
    CORE_SURVEY_ID,
    currentUser.attributes.user_id
  );

  const survey = await fetchSurvey(CORE_SURVEY_ID);

  const dimensions = await fetchDimensions();

  let lowestDimension = getDimension(
    lastResult.attributes.lowestDimension,
    dimensions
  );

  let highestDimension = getDimension(
    lastResult.attributes.highestDimension,
    dimensions
  );

  const focusCopy = getLastResultFocusCopy({
    dimensions,
    survey,
    lastResult,
  });

  const zone = getZone(survey.possible_results, lastResult);

  const highestTip = await fetchTip(lastResult.attributes.highestTip);
  const lowestTip = await fetchTip(lastResult.attributes.lowestTip);

  const resultsToday = await isThereCoreSurveyResultToday(
    currentUser.attributes.user_id
  );

  let filteredAverages = {};
  const { gender, birthdate, country } = currentUser.attributes;
  if (gender && birthdate && country) {
    const newFilteredAverages = await getAllFilteredAverages(currentUser);

    filteredAverages = {
      data: {
        ...newFilteredAverages,
        countryName: countryFormatter(country),
      },
    };
  }

  return {
    total: lastResult.attributes.total,
    dimensionValues: getDimensionValuesForGraph(
      lastResult.attributes.result_values,
      dimensions
    ),
    lowestDimension,
    highestDimension,
    tips: {
      highestTip: formatTip({
        tip: translateTipById(
          highestTip,
          language,
          currentUser.attributes.gender
        ),
        dimension: highestDimension,
      }),
      lowestTip: formatTip({
        tip: translateTipById(
          lowestTip,
          language,
          currentUser.attributes.gender
        ),
        dimension: lowestDimension,
      }),
    },
    focusCopy,
    zone,
    resultsToday,
    filteredAverages,
    lastResultDate: lastResult.attributes.created_at,
  };
};

/**
 * returns a date on a specific format
 * @param {String} date
 * @param {String} language
 * @returns {String}
 */
const getLastResultFormattedDate = (date, language) => {
  let simpleDate = date.split("T")[0];
  let dateParts = simpleDate.split("-");

  let months;

  if (language === "pt-PT") {
    months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
  } else {
    months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  return (
    dateParts[2] +
    (language === "en-GB" ? formatDate(dateParts[2]) : "") +
    " " +
    months[dateParts[1] - 1] +
    " " +
    dateParts[0]
  );
};

/**
 * formats a date day number with the appropriate termination
 * @param {Int} d
 * @returns {String}
 */
const formatDate = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

/**
 * gets all dimension values to be used by the graph
 * @param {Array} result_values
 * @param {Array} dimensions
 * @returns {Object}
 */
const getDimensionValuesForGraph = (result_values, dimensions) => {
  let obj = {};

  for (let value of result_values) {
    for (let dimension of dimensions) {
      if (
        dimension.ids.includes(value.attributes.dimension_id) &&
        dimension.dimension_type !== "purpose"
      ) {
        obj[dimension.dimension_type] = value.attributes.value;
      }
    }
  }

  return obj;
};

/**
 * check if results exists to show the pages in analytics
 * @param {Object} currentUser
 * @returns {String}
 */
const checkResults = async (currentUser) => {
  try {
    const results = await backend.get(
      `/results/${currentUser.attributes.user_id}/${CORE_SURVEY_ID}`
    );

    if (results.data.data.length >= 2) {
      return "both";
    } else if (results.data.data.length === 1) {
      return "one";
    }
  } catch (e) {
    return null;
  }
};

/**
 * check if results exists to show the pages in analytics
 * @param {Object} currentUser
 * @returns {Object}
 */
const newCheckResults = async (currentUser) => {
  try {
    const results = await backend.get(
      `/results/${currentUser.attributes.user_id}/${CORE_SURVEY_ID}`
    );

    let resultsNumber = results.data.data.length;

    return {
      myLastResults: resultsNumber >= 1,
      progressOverTime: resultsNumber >= 2,
    };
  } catch (e) {
    return {
      myLastResults: false,
      progressOverTime: false,
    };
  }
};

export {
  getLastResultsInfo,
  checkResults,
  newCheckResults,
  getLastResultFormattedDate,
};
