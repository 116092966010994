import React, { useState } from "react";
import "./CookiesPreferences.css";
import { redirectToCookiePolicy } from "../../core/Routing/RoutesRedirects";
import { RegularModal } from "../modal/CustomModals";
import Switch from "react-switch";
import ResultsButton from "../../modules/Results/components/newComponents/ResultsButton";
import { useResize } from "../../modules/Geral/hooks/useResize";
import {
  areCookiesEnabled,
  removeAnalyticsCookies,
  removeExperienceCookies,
  setAcceptanceCookie,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";

/**
 * Functional Component: CookiesPreferences
 * The big modal that appears to define more granularly whose cookies to accept and whose to reject
 *
 * @param {Boolean} open: parent controls whether the modal is opened or closed
 * @param {Function} onChange: function that is triggered whenever the modal opens/closes
 * @param {Function} onSubmit: function that is triggered when the user clicks on the "Save settings" button
 * @param {Object} copies
 * @returns the modal with the settings
 */
const CookiesPreferences = ({ open, onChange, onSubmit, copies }) => {
  // the current state of the switches
  const [switchesState, setSwitchesState] = useState({
    functional: true,
    experience: true,
    analytics: true,
  });

  // change one switch state
  const changeSwitch = (name, value) => {
    setSwitchesState({ ...switchesState, [name]: value });
  };

  // saves the settings to cookies and invokes the onSubmit function
  const saveCookies = (newCookies) => {
    setAcceptanceCookie(newCookies);
    if (!newCookies.experience) removeExperienceCookies();
    if (!newCookies.analytics) removeAnalyticsCookies();
    onSubmit();
  };

  /**
   * Function: renderSwitch
   * A visual component that represents the state of true/false
   *
   * @param {String} name: the name represented on state
   * @param {String} label: the label that appears on top of the switch
   * @param {Boolean} disabled: whether one can toggle the switch or not
   * @returns {Element} the switch component
   */
  const renderSwitch = ({ name, label, disabled }) => {
    return (
      <div
        className={`mt-3 mb-3 ${
          switchesState[name] ? "cookies-preferences__switch-active" : null
        }`}
      >
        <label className="d-flex align-items-center">
          <Switch
            onChange={(checked) => changeSwitch(name, checked)}
            checked={switchesState[name]}
            disabled={disabled}
          />
          <span className="ms-3">{label}</span>
        </label>
      </div>
    );
  };

  // the current width of the window
  const [width] = useResize();

  return (
    <RegularModal
      customStyles={{ width: width < 992 ? "80%" : "" }}
      open={open}
      onChange={onChange}
    >
      {areCookiesEnabled ? (
        <>
          <h4 className="fw-bold ff-popp-semi-bold">{copies.TITLE}</h4>

          <div>
            {copies.BODY}{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={redirectToCookiePolicy}
            >
              {copies.TERMS_OF_SERVICE}
            </span>
            .
          </div>

          <div className="d-flex justify-content-between">
            <div>
              {renderSwitch({
                name: "functional",
                label: copies.FUNCTIONAL_SWITCH,
                disabled: true,
              })}
              {renderSwitch({
                name: "experience",
                label: copies.USER_EXPERIENCE_SWITCH,
              })}
              {renderSwitch({
                name: "analytics",
                label: copies.ANALYTICS_SWITCH,
              })}
            </div>

            <div
              style={{ alignSelf: "end" }}
              className="d-flex align-items-center justify-content-end mt-4"
            >
              <div>
                <ResultsButton
                  style={{
                    padding: "0.8rem 2rem",
                    fontSize: width < 576 ? "50%" : "90%",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => saveCookies(switchesState)}
                >
                  {copies.SAVE_SETTINGS_BUTTON}
                </ResultsButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4 className="fw-bold ff-popp-semi-bold">{copies.TITLE}</h4>

          <div>{copies.BODY}</div>

          <a
            href="https://support.google.com/accounts/answer/61416?hl=pt-PT"
            target="_blank"
            rel="noopener noreferrer"
          >
            {copies.KNOW_MORE_BUTTON}
          </a>

          <div className="mt-4" style={{ float: "right" }}>
            <ResultsButton
              style={{ padding: "0.8rem 2rem" }}
              onClick={() => window.location.reload()}
            >
              {copies.TRY_AGAIN_BUTTON}
            </ResultsButton>
          </div>
        </>
      )}
    </RegularModal>
  );
};

export default CookiesPreferences;
