import { nameInserter } from "../../../utils/helpers/surveysHelpers/QuestionHelper";

/**
 * formats the question to fill the placeholders
 * @param {Object} questionProps
 * @param {String} feel
 * @param {Object} currentUser
 * @returns {Element or String}
 */
const getQuestion = (questionProps, feel, currentUser) => {
  const question = questionProps.attributes.description;

  if (feel && question.includes(":feelholder:")) {
    const feelQuestion = question.split(":feelholder:");
    return (
      <>
        <div>
          {feelQuestion[0]}
          <span>{feel.toLowerCase()}</span>
          {feelQuestion[1]}
        </div>
      </>
    );
  } else if (question.includes(":placeholder:")) {
    const substitution = currentUser ? currentUser.attributes.first_name : null;
    return nameInserter(question, substitution);
  } else {
    return question;
  }
};

export { getQuestion };
