import React from "react";
import Mailto from "../../../components/Mailto";
import { getStaticShareEmailCopies } from "../../../utils/constants/copies/copies.helper";
import { connect } from "react-redux";

/**
 * Functional Component: ShareAppByEmail
 * component to app sharing by email
 * @returns {Component}
 */
const ShareAppByEmail = (props) => {
  //contains all copies for app sharing email
  const SHARE_EMAIL_COPIES = getStaticShareEmailCopies({
    language: props.language,
  });

  return (
    <Mailto
      subject={SHARE_EMAIL_COPIES["share-app"].subject}
      body={SHARE_EMAIL_COPIES["share-app"].body
        .replace("[url]", props.share_url)
        .replace("?", "%3F")
        .replace("&", "%26")}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(ShareAppByEmail);
