import {
  CLEAN_TEMPORARY_SCORE,
  FETCH_TEMPORARY_SCORE,
  INTERNAL_SIGN_OUT,
} from "../../../../store/actions/types";

/**
 * reducer for temporary score action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object or null}
 */
const temporaryScoreReducer = (state = null, action) => {
  if (action.type === FETCH_TEMPORARY_SCORE) {
    return action.payload;
  } else if (
    action.type === INTERNAL_SIGN_OUT ||
    action.type === CLEAN_TEMPORARY_SCORE
  ) {
    return null;
  }
  return state;
};

export { temporaryScoreReducer };
