import backend from "../../../../core/apis/backend";
import {
  CORE_SURVEY_ID,
  TRIAL_SURVEY_ID,
} from "../../../../utils/constants/constants";
import {
  CLEAN_ANSWER_VALUE,
  CLEAN_CORE_ANSWERS,
  CLEAN_QUESTION_NUMBER,
  CLEAN_SURVEY,
  CLEAN_SURVEY_PROPERTIES,
  DROP_ANSWER_VALUE,
  FETCH_SURVEY,
  RANDOM_SURVEY,
  SAVE_ANSWER_VALUE,
  SAVE_CORE_ANSWER,
  SAVE_SURVEY_PROPERTIES,
  SET_CORE_ANSWERS,
  SET_QUESTION_NUMBER,
  SPECIFIC_SURVEY,
  CORE_OR_TRIAL,
  SAVE_REORDERED_QUESTIONS,
  SAVE_SURVEY,
  SAVE_TRIAL,
} from "../../../../store/actions/types";

/**
 * fetches trial survey
 * @returns {Object}
 */
const fetchTrial = () => {
  return async (dispatch) => {
    const { data } = await backend.get(`/surveys/${TRIAL_SURVEY_ID}`);

    dispatch({
      type: CORE_OR_TRIAL,
      payload: data,
    });
  };
};

/**
 * fetches a survey by his id
 * @param {String} survey_id
 * @returns {Object}
 */
const fetchSurvey = (survey_id) => {
  return async (dispatch) => {
    const { data } = await backend.get(`/surveys/${survey_id}`);

    dispatch({
      type: FETCH_SURVEY,
      payload: data,
    });
  };
};

/**
 * saves a survey after it has been modified
 * @param {Object} survey
 * @returns {Object}
 */
const saveSurvey = (survey) => {
  return {
    type: SAVE_SURVEY,
    payload: survey,
  };
};

/**
 * saves the trial survey after it has been modified
 * @param {Object} survey
 * @returns {Object}
 */
const saveTrial = (survey) => {
  return {
    type: SAVE_TRIAL,
    payload: survey,
  };
};

/**
 * fetches core survey with random questions
 * @param {String} survey_id
 * @param {String} user_id
 * @returns {Object}
 */
const fetchRandomizedCoreSurvey = (survey_id, user_id) => {
  return async (dispatch) => {
    const { data } = await backend.get(`/randomizeSurvey/${survey_id}`, {
      params: {
        user_id: user_id,
      },
    });

    dispatch({
      type: RANDOM_SURVEY,
      payload: data,
    });
  };
};

/**
 * fetches core survey with specific questions
 * @param {String} user_id
 * @returns {Object}
 */
const fetchSpecificCoreSurvey = (user_id) => {
  return async (dispatch) => {
    const { data } = await backend.get(`/specificSurvey/${CORE_SURVEY_ID}`, {
      params: { user_id },
    });

    dispatch({
      type: SPECIFIC_SURVEY,
      payload: data,
    });
  };
};

/**
 * cleans survey from redux state
 * @returns {Object}
 */
const cleanSurvey = () => {
  return {
    type: CLEAN_SURVEY,
  };
};

/**
 * saves the current questionNumber for question iteration
 * @param {Int} number
 * @returns {Object}
 */
const setQuestionNumber = (number) => {
  return {
    type: SET_QUESTION_NUMBER,
    payload: number,
  };
};

/**
 * resets the questionNumber on redux state
 * @returns {Object}
 */
const cleanQuestionNumber = () => {
  return {
    type: CLEAN_QUESTION_NUMBER,
  };
};

/**
 * saves the last answer value for future evaluation
 * @param {Object} answerValue
 * @returns {Object}
 */
const saveAnswerValue = (answerValue) => {
  return {
    type: SAVE_ANSWER_VALUE,
    payload: answerValue,
  };
};

/**
 * deletes the last answer value
 * @returns {Object}
 */
const dropAnswerValue = () => {
  return {
    type: DROP_ANSWER_VALUE,
  };
};

/**
 * cleans the answerValue array
 * @returns {Object}
 */
const cleanAnswerValue = () => {
  return {
    type: CLEAN_ANSWER_VALUE,
  };
};

/**
 * saves all data for a survey creation
 * @param {Object} survey
 * @returns {Object}
 */
const saveSurveyProperties = (survey) => {
  return {
    type: SAVE_SURVEY_PROPERTIES,
    payload: survey,
  };
};

/**
 * cleans redux state data of survey creation
 * @returns {Object}
 */
const cleanSurveyProperties = () => {
  return {
    type: CLEAN_SURVEY_PROPERTIES,
  };
};

/**
 * saves core answer on redux
 * @param {Object} answerObject
 * @returns {Object}
 */
const saveCoreAnswer = (answerObject) => {
  return {
    type: SAVE_CORE_ANSWER,
    payload: answerObject,
  };
};

/**
 * sets all core answers on redux
 * @param {Array} coreAnswers
 * @returns {Object}
 */
const setCoreAnswers = (coreAnswers) => {
  return {
    type: SET_CORE_ANSWERS,
    payload: coreAnswers,
  };
};

/**
 * cleans core answers from redux
 * @returns {Object}
 */
const cleanCoreAnswers = () => {
  return {
    type: CLEAN_CORE_ANSWERS,
  };
};

/**
 * saves trial questions after beeing reordered to redux
 * @param {Array} questions
 * @returns
 */
const saveTrialReorderedQuestions = (questions) => {
  return {
    type: SAVE_REORDERED_QUESTIONS,
    payload: questions,
  };
};

export {
  fetchSurvey,
  fetchRandomizedCoreSurvey,
  fetchSpecificCoreSurvey,
  cleanSurvey,
  setQuestionNumber,
  cleanQuestionNumber,
  saveAnswerValue,
  dropAnswerValue,
  cleanAnswerValue,
  saveSurveyProperties,
  cleanSurveyProperties,
  saveCoreAnswer,
  setCoreAnswers,
  cleanCoreAnswers,
  fetchTrial,
  saveTrialReorderedQuestions,
  saveSurvey,
  saveTrial,
};
