import { combineReducers } from "redux";
import { fetchUserReducer } from "../../modules/Users/store/reducers/usersReducer";
import questionNumberReducer from "../../modules/Surveys/store/reducers/questionsReducer";
import answersReducer from "../../modules/Surveys/store/reducers/answersReducer";
import {
  resultsReducer,
  fetchResultsReducer,
  preliminaryResultReducer,
  coreResultReducer,
} from "../../modules/Results/store/reducers/resultsReducer";
import {
  surveyReducer,
  surveyPropertiesToCreateReducer,
  coreOrTrialReducer,
  reorderedTrialQuestionsReducer,
} from "../../modules/Surveys/store/reducers/surveysReducer";
import { temporaryScoreReducer } from "../../modules/Scores/store/reducers/temporaryScoresReducer";
import { rolesReducer } from "../../modules/Auth/store/reducers/rolesReducer";
import coreAnswersReducer from "../../modules/Surveys/store/reducers/coreAnswersReducer";
import { queryReducer } from "../../modules/Geral/store/reducers/queryReducer";
import { flashReducer } from "../../modules/Geral/store/reducers/flashReducer";
import { languageReducer } from "../../modules/Geral/store/reducers/languageReducer";

const rootReducer = combineReducers({
  currentUser: fetchUserReducer,
  questionNumber: questionNumberReducer,
  answerValues: answersReducer,
  createdResult: resultsReducer,
  survey: surveyReducer,
  graphResultsByUserAndSurvey: fetchResultsReducer,
  surveyProperties: surveyPropertiesToCreateReducer,
  currentUserTemporaryScore: temporaryScoreReducer,
  roles: rolesReducer,
  coreAnswers: coreAnswersReducer,
  preliminaryResult: preliminaryResultReducer,
  coreResult: coreResultReducer,
  queryParams: queryReducer,
  mainSurvey: coreOrTrialReducer,
  reorderedTrialQuestions: reorderedTrialQuestionsReducer,
  flashProps: flashReducer,
  language: languageReducer,
});

export default rootReducer;
