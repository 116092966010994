import React from "react";
import PercentageCircle from "../../modules/Results/components/newComponents/PercentageCircle";
import {
  MAIN_TEXT,
  SCORE_GRAPH_SECTIONS,
  SECONDARY_TEXT,
} from "../../utils/constants/colorsConstants";
import "./ScoreGraph.css";
import BezierEasing from "bezier-easing";

/**
 * Functional Component: ScoreGraph
 * The score graphic present in welcome back, results, shared results, and analytics page
 *
 * @param {Int} score: the user's result score
 * @param {Object} colors: the colors to paint the graph, that contains "main", "secondary" and "third"
 * @param {Object} copies
 * @returns {Element}: an svg/the graph
 */
const StandardScoreGraph = ({ score, colors, copies, markers = [] }) => {
  return (
    <svg
      viewBox="0 0 609 598"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="custom-score-graph"
    >
      <PercentageCircle
        markers={markers}
        sections={SCORE_GRAPH_SECTIONS}
        percentage={score}
        animationProps={{
          step: 5,
          duration: 3000,
          bezierAnimation: BezierEasing(0, 0, 0.58, 1),
        }}
      />

      <path
        d="M218.173 272.32c0-5.44 1.547-10.56 4.64-15.36 3.093-4.8 7.68-8.693 13.76-11.68 6.187-2.987 13.653-4.48 22.4-4.48 8.853 0 16.32 1.493 22.4 4.48 6.187 2.987 10.773 6.88 13.76 11.68 3.093 4.8 4.64 9.92 4.64 15.36 0 5.973-1.387 11.093-4.16 15.36-2.773 4.16-6.827 7.36-12.16 9.6 12.693 5.653 19.04 15.2 19.04 28.64 0 7.68-1.92 14.293-5.76 19.84-3.84 5.44-9.067 9.6-15.68 12.48-6.507 2.773-13.867 4.16-22.08 4.16-8.107 0-15.467-1.387-22.08-4.16-6.613-2.88-11.84-7.04-15.68-12.48-3.84-5.547-5.76-12.16-5.76-19.84 0-13.44 6.347-22.987 19.04-28.64-5.333-2.24-9.387-5.44-12.16-9.6-2.773-4.267-4.16-9.387-4.16-15.36zm26.88 4.64c0 4.373 1.227 7.733 3.68 10.08 2.56 2.24 5.973 3.36 10.24 3.36s7.627-1.12 10.08-3.36c2.56-2.347 3.84-5.707 3.84-10.08 0-4.267-1.28-7.627-3.84-10.08-2.56-2.453-5.92-3.68-10.08-3.68s-7.52 1.227-10.08 3.68c-2.56 2.453-3.84 5.813-3.84 10.08zm-3.04 47.36c0 4.8 1.493 8.587 4.48 11.36 3.093 2.773 7.253 4.16 12.48 4.16s9.333-1.387 12.32-4.16c3.093-2.773 4.64-6.56 4.64-11.36s-1.6-8.533-4.8-11.2c-3.093-2.773-7.147-4.16-12.16-4.16s-9.12 1.387-12.32 4.16c-3.093 2.667-4.64 6.4-4.64 11.2zm75.285-22.08c0 17.707 3.573 31.733 10.72 42.08 7.147 10.347 18.56 15.52 34.24 15.52 15.573 0 26.933-5.173 34.08-15.52 7.253-10.347 10.88-24.373 10.88-42.08 0-17.6-3.627-31.52-10.88-41.76-7.147-10.347-18.507-15.52-34.08-15.52-15.68 0-27.093 5.173-34.24 15.52-7.147 10.24-10.72 24.16-10.72 41.76zm62.72 0c0 10.133-1.227 17.973-3.68 23.52-2.347 5.44-7.04 8.16-14.08 8.16-7.147 0-11.947-2.72-14.4-8.16-2.453-5.547-3.68-13.387-3.68-23.52 0-10.027 1.227-17.76 3.68-23.2 2.453-5.44 7.253-8.16 14.4-8.16 7.04 0 11.733 2.72 14.08 8.16 2.453 5.333 3.68 13.067 3.68 23.2z"
        dfill={colors.main}
      />
      <text
        x="310"
        y="315"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="11.3em"
        fontFamily="PoppinsBold"
        fill={colors.main}
      >
        {copies.SCORE}
      </text>

      <text
        x="309"
        y="200"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="1.4em"
        fontFamily="PoppinsMedium"
        fill={MAIN_TEXT}
      >
        {copies.GLOBAL_SCORE}
      </text>

      <text
        x="304"
        y="400"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="1.4em"
        fontFamily="PoppinsMedium"
        fill={SECONDARY_TEXT}
      >
        {copies.OUT_OF_TEXT}
      </text>

      <circle cx="311.5" cy="298.5" r="250" stroke="#E3E3E3" />
      <circle cx="310" cy="299" r="298.5" stroke="#E3E3E3" />
      <circle cx="40" cy="448" r="40" fill={colors.secondary} />
      <circle cx="40" cy="448" r="30" fill={colors.third} />
      <g
        clipPath="url(#mrlg3vh8qc)"
        stroke={colors.main}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      >
        <path d="M29 438v20.4h21.43" />
        <path d="m32.71 451.88 5.41-5.41 3.13 3.13 5.89-5.89" />
      </g>
      <circle
        cx="506"
        cy="536"
        r="25"
        transform="rotate(-180 506 536)"
        fill="url(#1vrrb4oocd)"
      />
      <circle
        cx="199.5"
        cy="73.5"
        r="12.5"
        transform="rotate(-180 199.5 73.5)"
        fill="url(#d49eb0y05e)"
      />
      <circle
        cx="554.5"
        cy="40.5"
        r="7.5"
        transform="rotate(-180 554.5 40.5)"
        fill="url(#mwjs99iu5f)"
      />
      <defs>
        <linearGradient
          id="1vrrb4oocd"
          x1="506"
          y1="511"
          x2="506"
          y2="561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.main} />
          <stop offset="1" stopColor="#F3EEFA" />
          <stop offset="1" stopColor={colors.main} />
        </linearGradient>
        <linearGradient
          id="d49eb0y05e"
          x1="199.5"
          y1="61"
          x2="199.5"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.main} />
          <stop offset="1" stopColor="#F3EEFA" />
          <stop offset="1" stopColor={colors.main} />
        </linearGradient>
        <linearGradient
          id="mwjs99iu5f"
          x1="554.5"
          y1="33"
          x2="554.5"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.main} />
          <stop offset="1" stopColor="#F3EEFA" />
          <stop offset="1" stopColor={colors.main} />
        </linearGradient>
        <clipPath id="lc8b0ruuib">
          <path
            fill="#fff"
            transform="translate(246 386)"
            d="M0 0h23.43v22.4H0z"
          />
        </clipPath>
        <clipPath id="mrlg3vh8qc">
          <path
            fill="#fff"
            transform="translate(28 437)"
            d="M0 0h23.43v22.4H0z"
          />
        </clipPath>
        <filter
          id="bhhjup5z7a"
          x="-320"
          y="-260"
          width="570"
          height="970"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_254_4902"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_254_4902"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StandardScoreGraph;
