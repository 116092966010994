import React, { useCallback, useEffect, useState } from "react";
import "./GlossaryButton.css";
import GlossaryIcon from "../../../../components/imageComponents/GlossaryIcon";
import { RegularModal } from "../../../../components/modal/CustomModals";
import { CustomTooltip } from "../../../../components/modal/CustomTooltips";
import KeyPanel from "../../../../utils/KeyPanel";
import { MAIN_TEXT } from "../../../../utils/constants/colorsConstants";
import Cookies from "universal-cookie";
import { canTrackGlossary } from "../../../../utils/helpers/cookiesHelpers/cookiesHelper";

import { getMainTitleGlossary } from "../../../../utils/constants/copies/glossaryCopies.helper";
import { createAnalytic } from "../../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";

const cookies = new Cookies();

/**
 * Functional Component: GlossaryButton
 * this component contains the glossary button present on all survey pages
 * @param {Array} definitions
 * @param {Int} questionNumber
 * @param {Boolean} canShowTooltip
 * @param {String} surveyType
 * @param {String} place
 * @param {String} user_id
 * @param {String} language
 * @returns {Element}
 */
const GlossaryButton = ({
  definitions,
  questionNumber,
  canShowTooltip,
  surveyType,
  place,
  user_id,
  language,
}) => {
  const cookieName = `saw_${surveyType}_glossary`;
  const [canOpenTooltip, setTooltipOpenStatus] = useState(
    !cookies.get(cookieName) && canShowTooltip
  ); // questionNumber === 0 && !cookies.get(cookieName)
  const [canOpenModal, setModalOpenStatus] = useState(false);

  const { MAIN_TITLE, MAIN_BODY } = getMainTitleGlossary(language);

  useEffect(() => {
    if (canOpenModal) document.querySelector("html").style.overflow = "hidden";
    else document.querySelector("html").style.overflow = "visible";
  }, [canOpenModal]);

  useEffect(() => {
    if (questionNumber > 0) setTooltipOpenStatus(false);
  }, [questionNumber]);

  useEffect(() => {
    if (
      canShowTooltip &&
      canOpenTooltip &&
      !cookies.get(cookieName) &&
      canTrackGlossary()
    )
      cookies.set(cookieName, true, { expires: new Date(2100, 0, 1) });
  }, [canShowTooltip, canOpenTooltip, cookieName]);

  const openModal = useCallback(() => {
    setModalOpenStatus(definitions.length > 0);
    setTooltipOpenStatus(false);
  }, [definitions.length]);

  const closeModal = useCallback((e) => setModalOpenStatus(e), []);

  return (
    <div className="custom-glossary">
      <div style={{ position: "relative" }}>
        <CustomTooltip
          open={canOpenTooltip}
          onOpen={setTooltipOpenStatus}
          arrow="top right"
          withBackground
        >
          <div className="custom-glossary-tooltip-body">
            <span>{MAIN_BODY}</span>
          </div>
        </CustomTooltip>

        <RegularModal
          open={canOpenModal}
          onChange={setModalOpenStatus}
          canClose
          customStyles={{ overflowY: "scroll", maxHeight: "80vh" }}
        >
          <h4
            className="fw-bold ff-popp-semi-bold"
            style={{ color: MAIN_TEXT, marginBottom: "1.5rem" }}
          >
            {MAIN_TITLE}
          </h4>

          <div style={{ color: MAIN_TEXT }}>
            {definitions.map((definition, i) => {
              return (
                <Definition
                  key={i}
                  title={definition.word}
                  description={definition.description}
                />
              );
            })}
          </div>
        </RegularModal>

        <div
          className="glossary-icon-container"
          style={{ zIndex: 3, position: "relative" }}
        >
          <GlossaryIcon
            color={definitions.length === 0 ? "white" : "purple"}
            onClick={() => {
              openModal();

              if (place === "Trial") {
                createAnalytic(EVENTS.GLOSSARY_TRIAL);
              } else if (place === "Core") {
                createAnalytic(EVENTS.GLOSSARY_CORE);
              }
            }}
            cursor={definitions.length === 0 ? "initial" : "pointer"}
          />
        </div>
      </div>

      <KeyPanel events={[{ key: "Escape", callback: closeModal }]} />
    </div>
  );
};

/**
 * @param {String} title
 * @param {String} description
 * @returns {Element}
 */
const Definition = ({ title, description }) => {
  return (
    <div style={{ fontSize: "115%", marginTop: "1rem" }}>
      <h5
        style={{
          fontFamily: "PoppinsSemiBold",
          fontSize: "120%",
          marginBottom: "1rem",
          display: "inline",
        }}
      >
        {title}:{" "}
      </h5>
      <span>{description}</span>
    </div>
  );
};

export default GlossaryButton;
