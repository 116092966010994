import React, { useCallback, useEffect, useState } from "react";
import { TRANSPARENT } from "../../../utils/constants/constants";
import { noop } from "../../../utils/helpers/geralHelpers/RandomHelper";

/**
 * this component contains the time period buttons on progress overtime
 * @param {String} value
 * @param {Function} onButtonSelect
 * @param {Object} buttonsProps
 * @param {Array} blockedIntervals
 * @param {String} language
 * @param {Object} copies
 * @returns {Element}
 */
const NewTimeButtons = ({
  value,
  onButtonSelect = noop,
  buttonsProps,
  blockedIntervals,
  language,
  copies,
}) => {
  const [selection, select] = useState(value);

  useEffect(() => select(value), [value]);

  //called when a time period button is selected
  const handleButtonSelection = useCallback(
    (newValue) => {
      select(newValue);
      onButtonSelect(newValue);
    },
    [onButtonSelect]
  );

  /**
   * translates the time buttons text
   * @param {String} label
   * @returns {String}
   */
  const translateButtons = (label) => {
    switch (label) {
      case "week":
        return "Semana";
      case "month":
        return "Mês";
      case "semester":
        return "Semestre";
      case "year":
        return "Ano";
      default:
        return label;
    }
  };
  /**
   * renders a single time button
   * @param {String} value
   * @param {String} label
   * @param {Boolean} show
   * @returns {Element}
   */
  const renderTimeButton = ({ value, label, show }) => {
    return (
      <div
        key={label}
        onClick={
          blockedIntervals[label]
            ? null
            : () => handleButtonSelection({ value, label })
        }
        style={{
          backgroundColor: selection.label === label ? "#604586" : TRANSPARENT,
          color: blockedIntervals[label]
            ? "grey"
            : selection.label !== label
            ? "#604586"
            : "white",
          cursor: blockedIntervals[label] ? "initial" : null,
        }}
        class="period-buttons"
      >
        {language === "en-GB"
          ? label.charAt(0).toUpperCase() + label.slice(1)
          : translateButtons(label)}
      </div>
    );
  };

  /**
   * returns the description of each time button period
   * @param {String} timeSelection
   * @returns {String}
   */
  const getTimeSelectionDescription = (timeSelection) => {
    switch (timeSelection) {
      case "week":
        return copies.weekDescription;
      case "month":
        return copies.monthDescription;
      case "semester":
        return copies.semesterDescription;
      case "year":
        return copies.yearDescription;
      default:
        return "";
    }
  };

  /**
   * renders all time buttons at once
   * @returns {Array}
   */
  const renderAllTimeButtons = () => {
    return Object.values(buttonsProps).map((time) => renderTimeButton(time));
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div
        className="d-flex justify-content-between align-items-center"
        id="period-buttons-container"
      >
        {renderAllTimeButtons()}
      </div>
      <div id="period-buttons-description">
        {getTimeSelectionDescription(selection.value)}
      </div>
    </div>
  );
};

export default NewTimeButtons;
