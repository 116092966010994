import React, { useEffect } from "react";
import "./RadarGraphInfo.css";
import DimensionWellbeing from "../../modules/Results/components/newComponents/DimensionWellbeing";
import ResultsGraphImage from "../../modules/Results/components/newComponents/ResultsGraphImage";
import {
  MY_ANALYTICS_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
  RESULTS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
} from "../../utils/constants/constants";
import HideRadarZone from "../../modules/Results/components/infoParts/HideRadarZone";
import { MAIN_PURPLE } from "../../utils/constants/colorsConstants";
import { useResize } from "../../modules/Geral/hooks/useResize";

/**
 * Functional Component: RadarGraphInfo
 * The radar graph present in welcome back, shared result, results, and analytics page
 *
 * @param {Object} results: an object that contains the properties mental, physical, social and work
 * @param {Object} preliminaryResultData: the preliminary result present in redux, to be passed to sign up buttons in the preliminary result page
 * @param {String} page: the page the user is in
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {Object} dimensions
 * @returns {Element}: the section of the RadarGraph
 * */
const RadarGraphInfo = ({
  results = {},
  preliminaryResultData,
  page = "ERROR",
  copies,
  variableCopies,
  dimensions,
}) => {
  const radarGraphResults = {
    "left top": {
      label: copies.MAIN.DIMENSIONS.MENTAL,
      score: results.mental,
      tooltip: copies.MAIN.TOOLTIPS.MENTAL,
    },
    "right top": {
      label: copies.MAIN.DIMENSIONS.PHYSICAL,
      score: results.physical,
      tooltip: copies.MAIN.TOOLTIPS.PHYSICAL,
    },
    "right bottom": {
      label: copies.MAIN.DIMENSIONS.SOCIAL,
      score: results.social,
      tooltip: copies.MAIN.TOOLTIPS.SOCIAL,
    },
    "left bottom": {
      label: copies.MAIN.DIMENSIONS.WORK,
      score: results.work,
      tooltip: copies.MAIN.TOOLTIPS.WORK,
    },
  };

  useEffect(() => {
    const element = document.querySelector("#radar-graph-info");
    if (!element) return;

    const config = {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    };

    const callback = function (mutationsList) {
      if (
        page === PRELIMINARY_RESULTS_PAGE_ID &&
        mutationsList[0].removedNodes.length > 0
      )
        window.location.reload();
    };

    const observer = new MutationObserver(callback);
    observer.observe(element, config);
  });

  const [width] = useResize();

  /**
   * @returns {Int}
   */
  const getFactor = () => {
    if (width < 576) return 1;
    else if (width < 768) return 0.85;
    else if (width < 992) return 0.7;
    else return 0.45;
  };

  const factor = getFactor();

  const BACKGROUNDS = {
    [PRELIMINARY_RESULTS_PAGE_ID]: `linear-gradient(rgba(249, 248, 252, 1) calc(0rem),${MAIN_PURPLE} calc(43rem))`,
    [WELCOME_BACK_PAGE_ID]: "#f9f8fc",
    [SHARE_RESULTS_PAGE_ID]: "#f9f8fc",
    [RESULTS_PAGE_ID]: "#f9f8fc",
    [MY_ANALYTICS_PAGE_ID]: "white",
  };

  return (
    <div id="radar-graph-info" style={{ background: BACKGROUNDS[page] }}>
      <div style={{ gridRowStart: 1, gridColumnStart: 1, width: "100vw" }}>
        <div className="text-center pb-3">
          <h2 className="fw-bold radar-graph-info__title ff-popp-med">
            {copies.MAIN.TITLE}
          </h2>
        </div>

        <div className="mt-1 mx-auto d-flex flex-column flex-lg-row">
          <div
            id="radar-graph-info__radar"
            className="col-xs-12 col-lg-8 d-flex justify-content-center align-items-center"
          >
            <ResultsGraphImage results={radarGraphResults} page={page} />
          </div>

          <div className="col-lg-4 d-flex flex-column flex-sm-row flex-lg-column justify-content-lg-center align-items-lg-center pt-4">
            <div className="pb-5">
              <DimensionWellbeing
                type="lowest"
                description={variableCopies.DIMENSION_HIGHLIGHTS.lowest}
                dimension={dimensions.LOWEST}
                page={page}
                buttonText={copies.MAIN.BUTTON}
              />
            </div>

            <div className="pt-4">
              <DimensionWellbeing
                type="highest"
                description={variableCopies.DIMENSION_HIGHLIGHTS.highest}
                dimension={dimensions.HIGHEST}
                page={page}
                buttonText={copies.MAIN.BUTTON}
              />
            </div>
          </div>
        </div>
      </div>

      {page === PRELIMINARY_RESULTS_PAGE_ID ? (
        <HideRadarZone
          preliminaryResultData={preliminaryResultData}
          size={width}
          factor={factor}
          copies={copies.HIDE_RADAR_ZONE}
        />
      ) : null}
    </div>
  );
};

export default RadarGraphInfo;
