/**
 * gets the slider range
 * @param {Array} dateRange
 * @param {Int} periodsNumber
 * @returns {Array}
 */
const getSliderRange = ({ dateRange, periodsNumber }) => {
  return dateRange.slice(periodsNumber, dateRange.length);
};

/**
 * gets the date graph range depending on the selected period
 * @param {Array} dateRange
 * @param {Int} sliderSelection
 * @param {Int} periodsNumber
 * @param {Int} sliderRangeLength
 * @returns {Array}
 */
const getGraphRange = ({
  dateRange,
  sliderSelection,
  periodsNumber,
  sliderRangeLength,
}) => {
  const sliderPosition = sliderRangeLength - sliderSelection - 1;

  let firstIndex = dateRange.length - periodsNumber - sliderPosition;

  return dateRange.slice(
    firstIndex >= 0 ? firstIndex : 0,
    dateRange.length - sliderPosition
  );
};

export { getSliderRange, getGraphRange };
