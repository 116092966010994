import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading/Loading";
import CoreResultsTemplate from "./infoParts/CoreResultsTemplate";
import { connect } from "react-redux";
import {
  fetchSurvey,
  saveLanguage,
  setFlashProps,
} from "../../../store/actions";
import {
  CORE_SURVEY_ID,
  SHARE_RESULTS_PAGE_ID,
} from "../../../utils/constants/constants";
import {
  formatZone,
  getDimension,
  getTipsWithDimension,
  makeResultsPageCopies,
} from "../../Users/services/welcomeBackPage.service";
import { getAllResources } from "../services/sharedResult.service";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import Footer from "../../../components/footer/Footer";
import { useQuery } from "../../Geral/hooks/useQuery";
import { getResultsPageCopies } from "../../../utils/constants/copies/copies.helper";
import { ZONE_COLORS } from "../../../utils/constants/colorsConstants";
import Cookies from "universal-cookie";
import { cookiesOptions } from "../../../utils/helpers/cookiesHelpers/cookiesHelper";
const cookies = new Cookies();

/**
 * Functional Component: SharedResult
 * This component is used to share , it has no interface options except the possibility to register
 * @param {Object} match
 * @param {Function} fetchSurvey
 * @param {Object} survey
 * @param {Function} setFlashProps
 * @param {Function} saveLanguage
 * @param {String} language
 * @returns {Element}
 */
const SharedResult = ({
  match,
  fetchSurvey,
  survey,
  setFlashProps,
  language,
  saveLanguage,
}) => {
  const [resources, setResources] = useState(null);
  const [moreCopies, setMoreCopies] = useState(null);

  const query = useQuery();

  //fetches all data required for component functionality
  useEffect(() => {
    const saveResources = async () => {
      await fetchSurvey(CORE_SURVEY_ID);

      const resources = await getAllResources({
        result_id: match.params.share_result_id,
        query,
      });
      setResources(resources);
    };
    saveResources();
    //eslint-disable-next-line
  }, [fetchSurvey, match.params.share_result_id]);

  //detects browser default language and assings it to the user the first time
  //he visits the website
  useEffect(() => {
    if (window.navigator.language === "pt" && !cookies.get("language")) {
      saveLanguage("pt-PT");
      cookies.set("language", "pt-PT", cookiesOptions);
    } else if (!cookies.get("language")) {
      saveLanguage("en-GB");
      cookies.set("language", "en-GB", cookiesOptions);
    }
  }, [saveLanguage]);

  //gets all required variable copies for the shared result page
  useEffect(() => {
    const makeAllCopies = async () => {
      const {
        result,
        lastResultBeforeToday,
        purposePercentage,
        filteredData,
        gender,
        name,
        dimensions,
      } = resources;

      const variableCopies = await getResultsPageCopies({
        language,
        name,
        page: SHARE_RESULTS_PAGE_ID,
        lastResultScore: result.attributes.total,
        lastResultBeforeToday,
        purposePercentage,
        comparationAverages: filteredData,
        gender,
      });

      const tips = await getTipsWithDimension({
        lastResult: result,
        dimensions,
        language,
      });
      const lowestDimension = getDimension(
        result.attributes.lowestDimension,
        dimensions
      );

      const highestDimension = getDimension(
        result.attributes.highestDimension,
        dimensions
      );

      const moreCopies = makeResultsPageCopies({
        language,
        name,
        lowestDimension,
        highestDimension,
        lastResult: result,
        dimensions,
        survey,
        page: SHARE_RESULTS_PAGE_ID,
      });

      setMoreCopies({ ...moreCopies, tips, variableCopies });
    };

    if (language && resources && survey.possible_results) makeAllCopies();
  }, [language, survey, resources]);

  if (!resources || !moreCopies) return <Loading />;

  return (
    <div>
      <HeaderFirstVersion />

      <CoreResultsTemplate
        page={SHARE_RESULTS_PAGE_ID}
        language={language}
        setFlashProps={setFlashProps}
        //
        score={resources.result.attributes.total}
        shareResultGender={resources.gender}
        //
        zone={formatZone(moreCopies.copyZone)}
        colors={ZONE_COLORS[moreCopies.colorsZone]}
        results={moreCopies.results}
        //
        focusCopy={moreCopies.focusCopy.content}
        focusImage={moreCopies.focus.image}
        //
        tips={moreCopies.tips}
        copies={moreCopies.staticCopies}
        variableCopies={moreCopies.variableCopies}
      />

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { survey: state.survey, language: state.language };
};

export default connect(mapStateToProps, {
  fetchSurvey,
  setFlashProps,
  saveLanguage,
})(SharedResult);
