import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MY_ANALYTICS_PAGE_ID } from "../../../utils/constants/constants";
import "./Analytics.css";
import CoreResultsTemplate from "../../Results/components/infoParts/CoreResultsTemplate";
import {
  getLastResultFormattedDate,
  getLastResultsInfo,
  newCheckResults,
} from "../services/myAnalytics.service";
import Loading from "../../../components/loading/Loading";
import no_available_data from "../../../assets/svg/no-available-data.svg";
import chooseNextPath from "../../Auth/services/chooseNextPath";
import ResultsButton from "../../Results/components/newComponents/ResultsButton";
import PageNavbar from "../components/PageNavbar";
import NewAnalyticsComparisonZone from "../components/NewAnalyticsComparisonZone";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import Footer from "../../../components/footer/Footer";
import GreyBackgroundRectangle from "../../../components/rectangles/GreyBackgroundRectangle";
import {
  fetchUserWithAllAttributes,
  setFlashProps,
} from "../../../store/actions";

import {
  getResultsPageCopies,
  getStaticNoAvailableDataCopies,
  getStaticProgressOvertimeCopies,
  getStaticResultsPagesCopies,
} from "../../../utils/constants/copies/copies.helper";
import { CORE_SURVEY_POSSIBLE_RESULTS_CONTENT } from "../../../utils/constants/copies/variableCopies.helper";
import { ZONE_COLORS } from "../../../utils/constants/colorsConstants";
import { createAnalytic } from "../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../utils/helpers/geralHelpers/EventsHelper";
import { redirectToSurveyPage } from "../../../core/Routing/RoutesRedirects";

const MY_LAST_RESULTS_PAGE = "#my-last-results";
const PROGRESS_OVER_TIME_PAGE = "#progress-overtime";

//gets the page from the url hash
const getThisPage = () => {
  switch (window.location.hash) {
    case PROGRESS_OVER_TIME_PAGE:
      return PROGRESS_OVER_TIME_PAGE;
    default:
      return MY_LAST_RESULTS_PAGE;
  }
};

/**
 * Functional Component: MyAnalytics
 *
 * This component represents all the analytics page containing the last results and the progress overtime
 * @param {Object} currentUser
 * @param {Function} fetchUserWithAllAttributes
 * @param {Function} setFlashProps
 * @param {String} language
 * @returns {Element}
 */
const MyAnalytics = ({
  currentUser,
  fetchUserWithAllAttributes,
  setFlashProps,
  language,
}) => {
  const [userFetch, setUserFetch] = useState(false);
  const [page, setPage] = useState(getThisPage());
  const [canRender, setRenderAllowance] = useState({
    myLastResults: false,
    progressOverTime: false,
  });
  const [resources, setResources] = useState(null);
  const [lastResultsCopies, setLastResultsCopies] = useState(null);

  //refetches the current user to update it in case attributes have been changed on another device
  useEffect(() => {
    const refetchUser = async () => {
      await fetchUserWithAllAttributes(currentUser.attributes.user_id);
      setUserFetch(!userFetch);
    };
    refetchUser();
    //eslint-disable-next-line
  }, []);

  const SELF_COPIES_PROGRESS_OVERTIME = getStaticProgressOvertimeCopies({
    language,
  });

  //gets all required info for the last results page
  useEffect(() => {
    const getInfoForLastResultsPage = async () => {
      const { myLastResults, progressOverTime } = await newCheckResults(
        currentUser
      );
      setRenderAllowance({ myLastResults, progressOverTime });
      if (!progressOverTime && !myLastResults) {
        redirectToSurveyPage();
        //setResources({});
        return;
      }

      const newResources = await getLastResultsInfo(currentUser, language);
      setResources(newResources);
    };

    getInfoForLastResultsPage();
  }, [currentUser, language]);

  useEffect(() => {
    const makeAllCopies = async () => {
      const variableCopies = await getResultsPageCopies({
        language,
        name: currentUser.attributes.first_name,
        page: MY_ANALYTICS_PAGE_ID,
        lastResultScore: resources.total,
        comparationAverages: resources.filteredAverages.data,
      });

      const focusCopy =
        language === "en-GB"
          ? resources.focusCopy
          : CORE_SURVEY_POSSIBLE_RESULTS_CONTENT.find(
              (prc) => prc.id === resources.focusCopy.id
            );
      setLastResultsCopies({ variableCopies, focusCopy });
    };

    if (currentUser && language && resources && Object.keys(resources).length)
      makeAllCopies();
  }, [currentUser, language, resources]);

  /**
   * renders the analytics layout depending on the page
   * @returns {Element}
   */
  const renderLayout = () => {
    if (
      page === MY_LAST_RESULTS_PAGE &&
      canRender.myLastResults &&
      Object.keys(lastResultsCopies)
    ) {
      return (
        <MyLastResultsPage
          colors={ZONE_COLORS[resources.zone]}
          score={resources.total}
          lastResultsInfo={resources}
          results={resources.dimensionValues}
          tips={resources.tips}
          lastResultDate={resources.lastResultDate}
          currentUser={currentUser}
          setPage={setPage}
          setFlashProps={setFlashProps}
          language={language}
          copies={lastResultsCopies}
        />
      );
    } else if (page === PROGRESS_OVER_TIME_PAGE && canRender.progressOverTime) {
      return (
        <ProgressOvertimePage
          currentUser={currentUser}
          setPage={setPage}
          language={language}
          copies={SELF_COPIES_PROGRESS_OVERTIME}
        />
      );
    } else {
      return (
        <NoAvailableData
          currentUser={currentUser}
          resultsToday={resources.resultsToday}
          page={page}
          language={language}
          name={currentUser.attributes.first_name}
        />
      );
    }
  };

  if (!resources || !lastResultsCopies) return <Loading />;

  return (
    <div id="new-my-analytics">
      <HeaderFirstVersion myAnalytics={true} />

      <div style={{ position: "relative" }}>
        <GreyBackgroundRectangle>
          <div className="greyBackgroundPageTitle">
            {SELF_COPIES_PROGRESS_OVERTIME.mainPageTitle}
          </div>
        </GreyBackgroundRectangle>

        <div class="w-100 d-flex justify-content-center">
          <PageNavbar
            purpleProps={{
              text: SELF_COPIES_PROGRESS_OVERTIME.lastResultButton,
              pageId: MY_LAST_RESULTS_PAGE,
            }}
            whiteProps={{
              text: SELF_COPIES_PROGRESS_OVERTIME.progressButton,
              pageId: PROGRESS_OVER_TIME_PAGE,
            }}
            onChange={setPage}
            defaultValue={page}
            fireGaEvent={() => createAnalytic(EVENTS.VIEW_PROGRESS_ANALYTICS)}
          />
        </div>
      </div>

      {renderLayout()}
      <Footer />
    </div>
  );
};
/**
 * Functional Component: MyLastResultsPage
 * renders the last results page, the default analytics first page
 *
 * @param {String} zone
 * @param {Int} score
 * @param {Object} currentUser
 * @param {Object} results
 * @param {Object} tips
 * @param {Date} lastResultToday
 * @param {String} focusCopy
 * @param {Object} setPage
 * @param {Object} setFlashProps
 * @param {String} language
 * @param {Object} lastResultsInfo
 * @returns
 */
const MyLastResultsPage = ({
  colors,
  score,
  currentUser,
  results,
  tips,
  lastResultDate,
  setPage,
  setFlashProps,
  language,
  lastResultsInfo,
  copies,
}) => {
  const STATIC_COPIES = getStaticResultsPagesCopies({
    language,
    page: MY_ANALYTICS_PAGE_ID,
    name: currentUser.attributes.first_name,
    dimensions: {
      lowest: lastResultsInfo.lowestDimension,
      highest: lastResultsInfo.highestDimension,
    },
  });

  const lastResultFormattedDate = getLastResultFormattedDate(
    lastResultDate,
    language
  );

  if (!copies) return null;

  return (
    <div>
      <CoreResultsTemplate
        colors={colors}
        score={score}
        currentUser={currentUser}
        results={results}
        tips={tips}
        lastResultDate={lastResultFormattedDate}
        focusCopy={copies.focusCopy?.content}
        page={MY_ANALYTICS_PAGE_ID}
        setPage={setPage}
        setFlashProps={setFlashProps}
        language={language}
        copies={STATIC_COPIES}
        variableCopies={copies.variableCopies}
      />
    </div>
  );
};

/**
 * renders the progress overtime section on analytics
 * @param {Object} currentUser
 * @param {Function} setPage
 * @param {String} language
 * @param {Object} copies
 * @returns {Element}
 */
const ProgressOvertimePage = ({ currentUser, setPage, language, copies }) => {
  return (
    <div id="progress-overtime-container">
      <h3
        className="d-flex justify-content-center mx-auto fw-bold"
        id="progress-over-time-title"
      >
        {copies.title}
      </h3>
      <div>
        <NewAnalyticsComparisonZone
          currentUser={currentUser}
          setPage={setPage}
          language={language}
          copies={copies}
        />
      </div>
    </div>
  );
};

/**
 * renders the page when no available data exists telling the user to fill in surveys first
 * @param {Object} copyProps
 * @param {Object} currentUser
 * @param {Boolean} resultsToday
 * @param {String} page
 * @param {String} language
 * @returns {Element}
 */
const NoAvailableData = ({
  currentUser,
  resultsToday,
  page,
  language,
  name,
}) => {
  const typeByPage = {
    "#no-data": "alternative",
    [PROGRESS_OVER_TIME_PAGE]: "progress",
    [MY_LAST_RESULTS_PAGE]: "results",
  };
  const STATIC_COPIES = getStaticNoAvailableDataCopies({
    language,
    type: typeByPage[page],
    name,
  });

  return (
    <div style={{ padding: "10rem 0" }}>
      <div
        style={{ width: "12%" }}
        className="mx-auto display-6 d-flex flex-column justify-content-center align-items-center"
      >
        <img src={no_available_data} alt="no-available-data" />
      </div>

      <div
        className="display-6 text-center d-flex flex-column justify-content-center align-items-center"
        style={{
          fontFamily: "PoppinsSemiBold",
          color: "#333",
          padding: "2rem 0",
        }}
      >
        {STATIC_COPIES.TITLE}
      </div>

      <div
        style={{ fontSize: "120%" }}
        className="mx-auto text-center d-flex flex-column justify-content-center align-items-center"
      >
        {STATIC_COPIES.BODY}
      </div>

      <div
        style={{ paddingTop: "2rem" }}
        className="mx-auto d-flex flex-column justify-content-center align-items-center"
      >
        <ResultsButton
          onClick={() => chooseNextPath(currentUser)}
          customStyles={{
            padding: "1.2rem 5rem",
            fontSize: "105%",
            opacity: resultsToday ? "0.3" : "1",
          }}
          disabled={resultsToday}
        >
          {STATIC_COPIES.BUTTON}
        </ResultsButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, language: state.language };
};

export default connect(mapStateToProps, {
  fetchUserWithAllAttributes,
  setFlashProps,
})(MyAnalytics);
