import React from "react";

// Glossary Themes
const COLORS = {
  red: {
    book: "#DA4953",
    bump: "#C3242F",
  },
  purple: {
    book: "#A084C8",
    bump: "#7D60A5",
  },
  white: {
    book: "#ffffff",
    bump: "hsl(274, 37%, 63%)",
  },
};

/**
 * Functional Component: GlossaryIcon
 * The icon for the glossary button present on survey pages
 *
 * @param {String} color: the chosen theme
 * @param {Function} onClick: function that is triggered when it is clicked
 * @param {String} cursor: the state of the user's cursos, when it passes above the component
 * @returns {Element}: an svg
 * */
const GlossaryIcon = ({ color = "purple", onClick, cursor = "pointer" }) => {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      cursor={cursor}
    >
      <g filter="url(#9zjryhenua)">
        <circle cx="39" cy="35" r="35" fill="#DECFF3" />
      </g>
      <path
        d="M53.565 17v29.548l-26.339 2.874-3.226.352V20.226c0-.89.36-1.698.945-2.281A3.216 3.216 0 0 1 27.226 17h26.339z"
        fill={COLORS[color].book}
      />
      <path
        d="M27.226 17v32.422L24 49.774V20.226c0-.89.36-1.698.945-2.281A3.216 3.216 0 0 1 27.226 17z"
        fill={COLORS[color].bump}
      />
      <path
        d="M53.565 46.548H27.226a3.226 3.226 0 1 0 0 6.452h26.34v-1.81l-1.314-1.416 1.313-1.415v-1.811z"
        fill={COLORS[color].bump}
      />
      <path
        d="M27.226 51.19a1.417 1.417 0 0 1 0-2.83h26.339v2.83h-26.34z"
        fill="#DFEAEF"
      />
      <path
        d="m35.616 23.995-2.345-2.407-2.345 2.407V17h4.69v6.995z"
        fill="#FEC804"
      />
      <path
        d="M45.844 26.017H33.337a2.27 2.27 0 0 0-2.27 2.27v6.928a2.27 2.27 0 0 0 2.27 2.271h12.507a2.27 2.27 0 0 0 2.27-2.27v-6.928a2.27 2.27 0 0 0-2.27-2.27z"
        fill="#DFEAEF"
      />
      <path
        d="M45.842 38.03H33.335a2.817 2.817 0 0 1-2.814-2.815v-6.928a2.817 2.817 0 0 1 2.814-2.813h12.507a2.817 2.817 0 0 1 2.814 2.813v6.928a2.817 2.817 0 0 1-2.814 2.814zM33.335 26.56a1.73 1.73 0 0 0-1.727 1.727v6.928a1.73 1.73 0 0 0 1.727 1.727h12.507a1.73 1.73 0 0 0 1.727-1.727v-6.928a1.73 1.73 0 0 0-1.727-1.727H33.335z"
        fill={COLORS[color].bump}
      />
      <path
        d="M44.694 30.855H34.488a.543.543 0 0 1 0-1.086h10.206a.543.543 0 0 1 0 1.087zM44.694 33.734H34.488a.543.543 0 0 1 0-1.087h10.206a.543.543 0 0 1 0 1.087z"
        fill="#665E66"
      />
      <defs>
        <filter
          id="9zjryhenua"
          x="0"
          y="0"
          width="78"
          height="78"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_399_4960"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_399_4960"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default GlossaryIcon;
