import React from "react";
import { Bar } from "react-chartjs-2";

//colors for the bar graph, they can be any color desirable
const colors = [
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(255, 205, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(201, 203, 207, 0.2)",
];

/**
 * returns all the configuration for the bar graph
 * @param {Array} labels
 * @param {Array} data
 * @param {Number} maxYValue
 * @param {Array} mainLabels
 * @returns {Object}
 */
const getConfigs = (labels, data, maxYValue, mainLabels) => {
  return {
    type: "bar",
    data: {
      labels,
      datasets: data.map((a, index) => {
        return {
          label: mainLabels[index],
          data: a,
          backgroundColor: colors[index],
        };
      }),
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          min: 0, // minimum value
          max: maxYValue, // maximum value
        },
      },
    },
  };
};

/**
 * the bar graph component , returns a bar graph done with chart js
 * @param {Array} labels
 * @param {Array} data
 * @param {Number} maxYValue
 * @param {Array} mainLabels
 * @returns {Element}
 */
const BarGraph = ({ labels, data, maxYValue, mainLabels }) => {
  return <Bar {...getConfigs(labels, data, maxYValue, mainLabels)} />;
};

export default BarGraph;
