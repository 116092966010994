import React from "react";
import { connect } from "react-redux";
import {
  fetchUserWithAllAttributes,
  setFlashProps,
  fetchSurvey,
} from "../../../store/actions";
import {
  convertDimensionValuesToResultsObject,
  formatZone,
  getFocus,
} from "../../Users/services/welcomeBackPage.service";
import {
  createSharedFilteredData,
  fetchLastResultBeforeToday,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import Footer from "../../../components/footer/Footer";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import {
  CORE_SURVEY_ID,
  RESULTS_PAGE_ID,
} from "../../../utils/constants/constants";
import CoreResultsTemplate from "../components/infoParts/CoreResultsTemplate";
import { getAllFilteredAverages } from "../services/CompareWithOthers.service";
import { countryFormatter } from "../../Users/services/aboutMe.service";
import {
  getResultsPageCopies,
  getStaticResultsPagesCopies,
} from "../../../utils/constants/copies/copies.helper";
import Loading from "../../../components/loading/Loading";
import { CORE_SURVEY_POSSIBLE_RESULTS_CONTENT } from "../../../utils/constants/copies/variableCopies.helper";
import { translateTipById } from "../../../utils/constants/copies/tipsCopies.helper";
import { getZoneCopyFromLanguage } from "../../Surveys/services/questionContainer.service";
import { ZONE_COLORS } from "../../../utils/constants/colorsConstants";
import { redirectToProfilePage } from "../../../core/Routing/RoutesRedirects";

/**
 * Class Component: ShowResultContainer
 * Contains the show result component and handles the calculation of the result
 * @returns {Element}
 */
class ShowResultContainer extends React.Component {
  state = {
    copies: null,
    currentLanguage: null,
    currentCurrentUser: null,
    lastResultBeforeToday: null,
    filteredAverages: null,
  };

  componentDidMount = async () => {
    /**
     * @param {Function} fetchUserWithAllAttributes
     * @param {Object} currentUser
     * @param {String} user_id
     * @param {String} first_name
     * @param {String} language
     * @param {Function} fetchSurvey
     */
    const {
      fetchUserWithAllAttributes,
      currentUser: {
        attributes: { user_id },
      },
      coreResult,
      currentUser,
      language,
      fetchSurvey,
    } = this.props;

    if (!Object.keys(coreResult).length) {
      redirectToProfilePage();
      return;
    }

    //gets the current user
    await fetchUserWithAllAttributes(user_id);

    const lastResultBeforeToday = await fetchLastResultBeforeToday(user_id);
    const filteredAverages = await this.createAndSetSharedFilteredData();

    const copies = await this.makeAllCopies({
      filteredAverages,
      lastResultBeforeToday,
    });

    await fetchSurvey(CORE_SURVEY_ID);

    this.setState({
      copies,
      currentLanguage: language,
      lastResultBeforeToday,
      filteredAverages,
      currentCurrentUser: currentUser,
    });
  };

  //fetches new copies when the language changes
  componentDidUpdate = async () => {
    /**
     * @param {String} language
     * @param {String} first_name
     * @param {Object} currentUser
     */
    const { language, currentUser } = this.props;

    if (
      JSON.stringify(currentUser) !==
      JSON.stringify(this.state.currentCurrentUser)
    ) {
      const newFilteredAverages = await this.createAndSetSharedFilteredData();

      const copies = await this.makeAllCopies({
        filteredAverages: newFilteredAverages,
        lastResultBeforeToday: this.state.lastResultBeforeToday,
      });

      this.setState({
        filteredAverages: newFilteredAverages,
        currentCurrentUser: currentUser,
        copies,
      });
    } else if (
      this.state.currentLanguage !== language &&
      this.state.lastResultBeforeToday
    ) {
      const copies = await this.makeAllCopies({
        filteredAverages: this.state.filteredAverages,
        lastResultBeforeToday: this.state.lastResultBeforeToday,
      });

      this.setState({ copies, currentLanguage: language });
    }
  };

  /**
   * mounts the shared filtered data object and saves it
   * @returns {Object}
   */
  createAndSetSharedFilteredData = async () => {
    const {
      currentUser,
      coreResult: { share_id },
    } = this.props;

    const { attributes } = currentUser;
    if (!attributes.gender || !attributes.birthdate || !attributes.country)
      return;

    const { country, countryAndGender, countryAndGenderAndAge } =
      await getAllFilteredAverages(currentUser);

    //saves the filtered data to the database to be used on shared results page
    await createSharedFilteredData({
      result_share_id: share_id,
      country: country.toString(),
      countryAndGender: countryAndGender.toString(),
      countryAndGenderAndAge: countryAndGenderAndAge.toString(),
      countryName: countryFormatter(attributes.country),
    });

    return {
      country,
      countryAndGender,
      countryAndGenderAndAge,
      countryName: countryFormatter(attributes.country),
    };
  };

  /**
   * returns all the needed copies for this component
   * @param {Object} filteredAverages
   * @param {Object} lastResultBeforeToday
   * @returns {Object}
   */
  makeAllCopies = async ({ filteredAverages, lastResultBeforeToday }) => {
    const {
      coreResult: {
        total,
        purposePercentage,
        dimensionValues,
        zone,
        highestDimension,
        lowestDimension,
        tips,
      },
      language,
      currentUser,
    } = this.props;

    const translatedHighestTip = translateTipById(
      { data: { attributes: tips.lowestTip } },
      language,
      currentUser.attributes.gender
    );
    const translatedLowestTip = translateTipById(
      { data: { attributes: tips.highestTip } },
      language,
      currentUser.attributes.gender
    );

    const results = convertDimensionValuesToResultsObject(dimensionValues);
    const focus = getFocus({ dimension: lowestDimension.dimension });

    const STATIC_COPIES = getStaticResultsPagesCopies({
      language,
      page: RESULTS_PAGE_ID,
      name: currentUser.attributes.first_name,
      dimensions: {
        lowest: lowestDimension.dimension,
        highest: highestDimension.dimension,
      },
    });

    const copyZone = getZoneCopyFromLanguage({
      zone: zone,
      language,
    });

    const colorsZone = getZoneCopyFromLanguage({
      zone: zone,
      language: "en-GB",
    });

    const variableCopies = await getResultsPageCopies({
      language,
      name: currentUser.attributes.first_name,
      page: RESULTS_PAGE_ID,
      lastResultScore: total,
      lastResultBeforeToday,
      purposePercentage,
      comparationAverages: filteredAverages,
      gender: currentUser.attributes.gender,
    });

    const focusCopy = this.getPossibleResultCopy(language);

    return {
      variableCopies,
      colorsZone,
      copyZone,
      STATIC_COPIES,
      focus,
      results,
      translatedHighestTip,
      translatedLowestTip,
      focusCopy,
    };
  };

  /**
   * translates to the possible result copy
   * @returns {String}
   */
  getPossibleResultCopy = (language) => {
    const { survey, coreResult } = this.props;

    if (language !== "en-GB") {
      return CORE_SURVEY_POSSIBLE_RESULTS_CONTENT.find((possible_result) => {
        return possible_result.id === coreResult.copyContent.id;
      }).content;
    } else {
      return survey.possible_results.find((possible_result) => {
        return possible_result.id === coreResult.copyContent.id;
      }).attributes.possible_result_content.attributes.content;
    }
  };

  render = () => {
    /**
     * @param {String or Int} total
     * @param {String} url
     * @param {Object} currentUser
     * @param {Function} setFlashProps
     * @param {String} language
     * @param {Function} fetchUserWithAllAttributes
     */
    const {
      coreResult: { total, url, share_id },
      currentUser,
      setFlashProps,
      language,
      fetchUserWithAllAttributes,
    } = this.props;

    if (!this.state.copies) return <Loading />;

    return (
      <div>
        <HeaderFirstVersion />

        <CoreResultsTemplate
          setFlashProps={setFlashProps}
          fetchCurrentUser={fetchUserWithAllAttributes}
          page={RESULTS_PAGE_ID}
          currentUser={currentUser}
          language={language}
          score={total}
          share_url={url}
          zone={formatZone(this.state.copies.copyZone)}
          colors={ZONE_COLORS[this.state.copies.colorsZone]}
          results={this.state.copies.results}
          //
          focusCopy={this.state.copies.focusCopy}
          focusImage={this.state.copies.focus.image}
          //
          tips={{
            lowestTip: this.state.copies.translatedHighestTip.data.attributes,
            highestTip: this.state.copies.translatedLowestTip.data.attributes,
          }}
          copies={this.state.copies.STATIC_COPIES}
          variableCopies={this.state.copies.variableCopies}
          share_id={share_id}
        />

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    coreResult: state.coreResult,
    survey: state.survey,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchUserWithAllAttributes,
  setFlashProps,
  fetchSurvey,
})(ShowResultContainer);
