import { useEffect, useState } from "react";

const listenToScroll = (height) => {
  const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop;

  const headerScrollPosition = document.querySelector(".header").scrollHeight;

  const additional =
    headerScrollPosition < winScroll
      ? 0
      : ((headerScrollPosition - winScroll) * 50) / height;

  return 50 + additional + "%";
};

export function useScroll(height) {
  const [modalPos, setModalPos] = useState(0);

  useEffect(() => {
    const callback = () => setModalPos(() => listenToScroll(height));

    window.addEventListener("scroll", callback);
    return () => window.removeEventListener("scroll", callback);
  }, [height]);

  useEffect(() => {
    setModalPos(() => listenToScroll(height));
  }, [height]);

  return modalPos;
}
