import React from "react";
//import { useState } from "react";
import Avatar from "react-avatar-edit";
import { connect } from "react-redux";
import { setFlashProps } from "../../store/actions";
import { TRANSPARENT, VIOLET_PINK } from "../../utils/constants/constants";
import FLASH_TYPES from "../flash/FLASH_TYPES";

/**
 * Functional Component: AvatarImage
 * This component loads the image or photo uploaded and allows the person to crop it and save the final format
 *
 * @param {Function} onClose
 * @param {String} src
 * @param {Function} cropFunc
 * @param {Int} width
 * @param {Int} height
 * @param {Function} setFlashProps
 * @returns {Element}
 * */
const AvatarImage = ({
  onClose,
  src,
  cropFunc,
  width,
  height,
  setFlashProps,
}) => {
  /**
   * checks the file size to know if it is allowed
   * @param {Element} elem
   * */
  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 10000000) {
      setFlashProps(FLASH_TYPES.BIG_PICK_PROFILE_PHOTO);
      elem.target.value = "";
    }
  };

  return (
    <div>
      <Avatar
        imageWidth={width}
        height={height}
        onCrop={cropFunc}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        src={src}
        cropRadius={60}
        label="Click here"
        closeIconColor={TRANSPARENT}
        cropColor={VIOLET_PINK}
      />
      {/*preview && <img src={preview} alt="Preview" />*/}
    </div>
  );
};

export default connect(null, { setFlashProps })(AvatarImage);
