import React from "react";

/**
 * Functional Component: Mailto
 * A mail to link used to open the default email client on the user computer
 *
 * @param {String} sendTo
 * @param {String} body
 * @param {String} subject
 * @param {String, Int or Elements} children
 * @param {Object} customStyles
 * @returns {Element}
 * */
const Mailto = ({
  sendTo = "",
  body = "",
  subject = "",
  children,
  customStyles,
}) => {
  return (
    <a
      href={`mailto:${sendTo}?body=${body}&subject=${subject}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: "0px",
        font: "inherit",
        color: "inherit",
        cursor: "pointer",
        ...customStyles,
      }}
    >
      {children}
    </a>
  );
};

export default Mailto;
