import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import "./ActionsMenu.css";
import {
  redirectToAboutMePage,
  redirectToAnalyticsPage,
  redirectToKeycloakEndpoint,
  redirectToMeetVioletPage,
  redirectToSignupEndpoint,
} from "../../core/Routing/RoutesRedirects";
import { getShareURL } from "../../utils/helpers/geralHelpers/RandomHelper";
import {
  renderEmailShareIconForApp,
  renderFacebookMessengerShareIcon,
  renderWhatsAppShareIcon,
} from "../../modules/Results/components/ExtraRenders";
import { BLACK } from "../../utils/constants/constants";
import { MAIN_PURPLE } from "../../utils/constants/colorsConstants";
import { setFlashProps } from "../../store/actions";
import EmailIcon from "../../assets/images/emailIconWhiteBackground.png";
import EmailIcon2 from "../../assets/images/emailIconWhiteBackground2x.png";
import EmailIcon3 from "../../assets/images/emailIconWhiteBackground3x.png";
import WhatsAppIcon from "../../assets/images/whatsappIconWhiteBackground.png";
import WhatsAppIcon2 from "../../assets/images/whatsappIconWhiteBackground2x.png";
import WhatsAppIcon3 from "../../assets/images/whatsappIconWhiteBackground3x.png";
import MessengerIcon from "../../assets/images/messengerIconWhiteBackground.png";
import MessengerIcon2 from "../../assets/images/messengerIconWhiteBackground2x.png";
import MessengerIcon3 from "../../assets/images/messengerIconWhiteBackground3x.png";
import { detectMessenger } from "../../utils/helpers/geralHelpers/messengerDetector";
import FLASH_TYPES from "../flash/FLASH_TYPES";
import { createAnalytic } from "../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: ActionsMenu
 * The hamburguer menu component
 *
 * @param {Object} currentUser
 * @param {Object} preliminaryResult
 * @param {Object} queryParams
 * @param {Function} setFlashProps
 * @param {Object} copies
 * @returns {Element}
 * */
const ActionsMenu = ({
  currentUser,
  preliminaryResult,
  queryParams,
  setFlashProps,
  copies,
  language,
}) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  //closes the hamburguer menu
  const closeSelf = () => {
    document.getElementById("list").classList.remove("showMenu");
    document.getElementById("list").classList.add("closeMenu");
    document.body.style.overflowY = "visible";
    setNavbarOpen(false);
  };

  //opens the hamburguer menu
  const openSelf = () => {
    document.getElementById("list").classList.remove("closeMenu");
    document.getElementById("list").classList.add("showMenu");
    document.body.style.overflowY = "hidden";
    setNavbarOpen(true);
  };

  //handle the closing and opening of the hamburguer
  const handleToggle = () => {
    if (!navbarOpen) openSelf();
    else closeSelf();
  };

  //the image for the messenger share button
  const messengerImage = (
    <img
      alt=""
      src={MessengerIcon}
      srcSet={`${MessengerIcon2}, ${MessengerIcon3}`}
      className="share-icons-header"
      onClick={
        window.innerWidth < 992
          ? () => {
              detectMessenger(
                "",
                "",
                getShareURL(currentUser, language) + "&social=msn&mode=app"
              ).then((hasMessenger) => {
                if (!hasMessenger) setFlashProps(FLASH_TYPES.MESSENGER_APP);

                createAnalytic(EVENTS.INVITE_MESSENGER_HAMBURGUER);
              });
            }
          : null
      }
    />
  );

  //the image for the whatsapp share button
  const whatsappImage = (
    <img
      alt=""
      src={WhatsAppIcon}
      srcSet={`${WhatsAppIcon2}, ${WhatsAppIcon3}`}
      className="share-icons-header"
    />
  );

  //the image for the email share button
  const emailImage = (
    <img
      alt=""
      src={EmailIcon}
      srcSet={`${EmailIcon2}, ${EmailIcon3}`}
      className="share-icons-header"
      onClick={() => createAnalytic(EVENTS.INVITE_EMAIL_HAMBURGUER)}
    />
  );

  /**
   * renders the X closing icon of the hamburguer
   *@returns {svg}
   */
  const renderXIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill={MAIN_PURPLE}
          d="m2.121 0 17.678 17.678-2.122 2.12L0 2.122z"
        />
        <path
          fill={MAIN_PURPLE}
          d="M0 17.678 17.678 0l2.121 2.122L2.122 19.799z"
        />
      </svg>
    );
  };

  /**
   * renders the opening icon for the hamburguer
   *@returns {svg}
   */
  const renderOpenIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
      >
        <path fill={MAIN_PURPLE} d="M0 0h25v3H0zM0 8h25v3H0zM0 16h25v3H0z" />
      </svg>
    );
  };

  /**
   * renders the meet violet button
   * @returns {element}
   */
  const renderAboutUsButton = () => {
    return (
      <div>
        <button
          id="about-us-button"
          onClick={() => {
            closeSelf();
            redirectToMeetVioletPage();

            createAnalytic(EVENTS.MEET_VIOLET_HAMBURGUER);
          }}
        >
          {copies.MEET_VIOLET_BUTTON}
        </button>
      </div>
    );
  };

  /**
   * renders the login button
   * @returns {element}
   */
  const renderLoginButton = () => {
    return (
      <div>
        <button
          id="to-login-button"
          name="menu-login-button"
          onClick={() => {
            redirectToKeycloakEndpoint(language);
          }}
        >
          {copies.LOGIN_BUTTON}
        </button>
      </div>
    );
  };

  /**
   * renders the sign up button
   * @returns {element}
   */
  const renderSignUpButton = () => {
    return (
      <div>
        <button
          name="menu-signup-button"
          id="signup-button"
          onClick={() =>
            redirectToSignupEndpoint({ preliminaryResult, queryParams })
          }
        >
          {copies.SIGNUP_BUTTON}
        </button>
      </div>
    );
  };

  /**
   * renders all the share buttons for social media
   * @returns {element}
   */
  const renderShareButton = () => {
    let appShareUrl = getShareURL(currentUser, language);
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div id="share-button">{copies.INVITE_OTHERS}</div>

        <div
          id="icons-container"
          className="d-flex justify-content-between align-items-center mt-2"
        >
          <button id="email-sharing-button-actions-menu">
            {renderEmailShareIconForApp({
              appShareUrl,
              customStyles: { color: BLACK },
              img: emailImage,
            })}
          </button>
          <div id="whatsapp-sharing-button-actions-menu">
            {renderWhatsAppShareIcon({
              appShareUrl,
              isApp: true,
              customStyles: { color: BLACK },
              img: whatsappImage,
              place: "Header",
            })}
          </div>
          <div id="messenger-sharing-button-actions-menu">
            {renderFacebookMessengerShareIcon({
              appShareUrl,
              isApp: true,
              img: messengerImage,
              setFlashProps,
              place: "Header",
            })}
          </div>
        </div>
      </div>
    );
  };

  /**
   * renders my analytics button
   * @returns {element}
   */
  const renderMyAnaliticsButton = () => {
    return (
      <div>
        <button
          id="my-analitics-button"
          onClick={() => {
            closeSelf();
            redirectToAnalyticsPage();
            createAnalytic(EVENTS.ANALYTICS_HAMBURGUER);
          }}
        >
          {copies.MY_ANALYTICS_BUTTON}
        </button>
      </div>
    );
  };

  /**
   * renders the my profile button
   * @returns {element}
   */
  const renderAboutMeButton = () => {
    return (
      <div>
        <button
          id="about-me-button"
          onClick={() => {
            closeSelf();
            redirectToAboutMePage();
          }}
        >
          {renderAboutMeIcon()} {copies.ABOUT_ME_BUTTON}
        </button>
      </div>
    );
  };

  /**
   * renders the profile button icon
   * @returns {element}
   */
  const renderAboutMeIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4%"
        height="4%"
        fill="black"
        color="black"
        className="bi bi-person-fill aboutme-icon"
        viewBox="0 0 16 16"
        style={{
          left:
            language === "pt-PT"
              ? window.innerWidth < 576
                ? "32%"
                : "38%"
              : null,
          bottom:
            language === "pt-PT"
              ? window.innerWidth < 576
                ? "42.5%"
                : "46%"
              : null,
        }}
      >
        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    );
  };

  const renderOptions = () => {
    if (currentUser) {
      return (
        <>
          {renderMyAnaliticsButton()}
          <div>{renderAboutUsButton()}</div>
          {renderAboutMeButton()}
        </>
      );
    } else {
      return (
        <>
          <div>{renderAboutUsButton()}</div>
          <div>{renderLoginButton()}</div>
          <div>{renderSignUpButton()}</div>
        </>
      );
    }
  };

  return (
    <nav className="navBarActionMenu">
      <div className="hamburguer-button" onClick={handleToggle}>
        {navbarOpen ? renderXIcon() : renderOpenIcon()}
      </div>

      <ul id="list" className="menuNav">
        <div
          id="items-container"
          className="d-flex flex-column align-items-center justify-content-between"
        >
          <div
            id="first-element"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            {renderOptions()}
          </div>

          <div>{renderShareButton()}</div>
        </div>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    preliminaryResult: state.preliminaryResult,
    queryParams: state.queryParams,
    language: state.language,
  };
};

export default connect(mapStateToProps, { setFlashProps })(ActionsMenu);
