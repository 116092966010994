import backend from "../../../../core/apis/backend";
import {
  CREATE_RESULT,
  FETCH_RESULTS,
  SAVE_RESULTS_BY_TEAM,
  CLEAN_RESULTS_BY_TEAM,
  CLEAN_ORDERED_RESULTS,
  PRELIMINARY_RESULT_DATA,
  CORE_RESULT_DATA,
  ERASE_PRELIMINARY_RESULT_DATA,
} from "../../../../store/actions/types";

/**
 * post request to create and save a result from a survey in the backend
 * @param {String} content
 * @param {Int} result_value
 * @param {Not sure} result_collection
 * @param {String} survey_id
 * @param {Array} spread_results
 * @returns {Object}
 */
const createResult = (
  content,
  result_value,
  result_collection,
  survey_id,
  spread_results
) => {
  return async (dispatch, getState) => {
    const wellbeing_user_id = getState().currentUser.attributes.user_id;

    const { data } = await backend.post("/results", {
      content,
      wellbeing_user_id,
      result_value,
      result_collection,
      survey_id,
      spread_results,
    });

    dispatch({
      type: CREATE_RESULT,
      payload: data,
    });
  };
};

/**
 * fetches results of a specific survey and user
 * @param {String} survey_id
 * @param {String} user_id
 * @returns {Object}
 */
const fetchResultsBySurveyAndUser = (survey_id, user_id) => {
  return async (dispatch) => {
    const { data } = await backend.get("/results/" + user_id + "/" + survey_id);

    dispatch({
      type: FETCH_RESULTS,
      payload: data,
    });
  };
};

/**
 * saves organisaded results of a selected team and survey
 * @param {Array} teamUsers
 * @param {String} surveyId
 * @returns {Object}
 */
const saveResultsByTeam = (teamUsers, surveyId) => {
  let usersInTheTeam = teamUsers.data;
  let resultsToDisplay = teamUsers.included;

  let finalResults = [];

  resultsToDisplay.forEach((element) => {
    if (element.attributes.survey_id === surveyId) {
      finalResults.push(element);
    }
  });

  return {
    type: SAVE_RESULTS_BY_TEAM,
    payload: {
      resultsToDisplay: finalResults,
      users: {
        data: usersInTheTeam,
      },
    },
  };
};

/**
 * cleans results by team state from redux
 * @returns {Object}
 */
const cleanResulsByTeam = () => {
  return {
    type: CLEAN_RESULTS_BY_TEAM,
  };
};

/**
 * cleans ordered results from redux state
 * @returns {Object}
 */
const cleanOrderedResults = () => {
  return {
    type: CLEAN_ORDERED_RESULTS,
  };
};

/**
 * saves the data from preliminary result
 * @param {Object} preliminaryResult
 * @returns {Object}
 */
const savePreliminaryResultData = (preliminaryResult) => {
  return {
    type: PRELIMINARY_RESULT_DATA,
    payload: preliminaryResult,
  };
};

/**
 * cleans preliminary result from redux
 * @returns {Object}
 */
const cleanPreliminaryResultData = () => {
  return {
    type: ERASE_PRELIMINARY_RESULT_DATA,
  };
};

/**
 * saves data from core survey
 * @param {Object} coreResultData
 * @returns
 */
const saveCoreResultData = (coreResultData) => {
  return {
    type: CORE_RESULT_DATA,
    payload: coreResultData,
  };
};

export {
  createResult,
  fetchResultsBySurveyAndUser,
  saveResultsByTeam,
  cleanResulsByTeam,
  cleanOrderedResults,
  savePreliminaryResultData,
  saveCoreResultData,
  cleanPreliminaryResultData,
};
