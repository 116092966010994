//get the min an max possible birthdates for user to select as their birthdate
const extremeBirthdates = {
  min: new Date().setFullYear(new Date().getFullYear() - 100),
  max: new Date(),
};

/**
 * check if a provided date is between the min and max allowed
 * @param {Date} date
 * @returns {Boolean}
 */
const isDateBetweenExtremes = (date) => {
  return date > extremeBirthdates.min && date < extremeBirthdates.max;
};

export { isDateBetweenExtremes, extremeBirthdates };
