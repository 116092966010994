import React, { useState } from "react";
import { WHITE } from "../../utils/constants/constants";
import { generate_token } from "../../utils/helpers/geralHelpers/TokenHelper";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import DatePicker from "react-datepicker";
import DatePickerMobile from "react-mobile-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./NativeDatepicker.css";
import { isSafari } from "../../utils/helpers/geralHelpers/BrowserHelper";

/**
 * Functional Component: NativeDatepicker
 * This renders the date picker to input our date. Renders different date pickers for mobile or desktop, android or safari
 * @param {Boolean} isClerable
 * @param {Function} onChange
 * @param {String} xColor
 * @param {Boolean} hideX
 * @param {Object} customXStyles
 * @param {String or Date} value
 * @returns {Element}
 */
const NativeDatepicker = ({
  isClerable,
  onChange,
  xColor,
  hideX,
  customXStyles,
  value,
  ...props
}) => {
  const [type, setType] = useState("text");
  const [id] = useState(generate_token());
  const [open, setOpen] = useState(false);
  const [showHolder, setShowHolder] = useState(true);

  /**
   * clears the date picker value
   * @param {Date} newValue
   * */
  const clearValue = (newValue) => {
    /*document.getElementById(`custom-native-date-picker__${id}`).value =
      newValue;*/
    value = "no value";
    onChange(newValue);

    if (window.innerWidth < 992) {
      document.getElementsByClassName("custom-native-date-picker")[0].value =
        "";
    }
  };

  /**
   * formats the date on date change
   * @param {String} event
   * */
  const onDateChange = (event) => {
    let date = new Date(event);

    let day = date.getDate();
    let month = parseInt(date.getMonth());
    let year = date.getFullYear();

    let final = year + "/" + (month + 1) + "/" + day;

    onChange({ target: { value: final } });
  };

  /**
   * reverses the date to display for the user in european format
   * @param {String} date
   * @returns {String}
   * */
  const reverseDate = (date) => {
    if (date) {
      let dateArray = date.split("/");

      return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
    } else {
      return date;
    }
  };

  /**
   * renders the clear button to clean the date picker value
   * @returns {Element}
   * */
  const renderClearButton = () => {
    if (!isClerable) return null;
    return (
      <div
        style={{
          position: "absolute",
          left: "100%",
          display: "flex",
          alignItems: "center",
          height: "100%",
          textAlign: "center",
          cursor: "pointer",
          visibility: hideX ? "hidden" : "visible",
          zIndex: 10,
          ...customXStyles,
        }}
        onClick={() => clearValue(null)}
      >
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          className="date-picker-clear-indicator"
          fill={xColor}
          color={xColor}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
      </div>
    );
  };

  //the custom input to be passed to date picker
  const customInput = (
    <input
      id={`custom-native-date-picker__${id}`}
      className="custom-native-date-picker"
      type={type}
      {...props}
      onChange={onChange}
      style={{
        backgroundColor: WHITE,
        border: `1px solid rgba(205, 205, 205, 1)`,
        ...props.style,
        fontFamily: PoppinsRegular,
      }}
      value={value}
    />
  );

  if (window.innerWidth >= 992) {
    //the date picker for desktop browsers
    return (
      <div style={{ position: "relative" }}>
        {renderClearButton()}
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={onDateChange}
          dateFormat="dd/MM/yyyy"
          customInput={customInput}
          autoComplete="bday"
          onFocus={() => setType("date")}
          onBlur={() => setType("text")}
          onClickOutside={() => setType("text")}
          placeholderText={props.placeholder ?? "dd/mm/yyyy"}
        />
      </div>
    );
  } else {
    if (!isSafari) {
      //the date picker for android mobile phones and tablets
      return (
        <div style={{ position: "relative" }}>
          {renderClearButton()}
          <input
            id={`custom-native-date-picker__${id}`}
            className="custom-native-date-picker"
            type={type}
            onFocus={() => setOpen(true)}
            placeholder="dd/mm/yyyy"
            {...props}
            style={{
              backgroundColor: WHITE,
              border: `1px solid rgba(205, 205, 205, 1)`,
              ...props.style,
              fontFamily: PoppinsRegular,
            }}
            focusable={false}
            readOnly="readonly"
            value={reverseDate(value)}
          />
          <DatePickerMobile
            confirmText="Ok"
            cancelText="Cancel"
            isOpen={open}
            onCancel={() => setOpen(false)}
            onSelect={(event) => {
              onDateChange(event);
              setOpen(false);
            }}
            max={new Date()}
            min={
              new Date(
                new Date().getFullYear() - 100,
                new Date().getMonth(),
                new Date().getDate()
              )
            }
            dateConfig={{
              date: {
                format: "D",
                caption: "Day",
                step: 1,
              },
              month: {
                format: "M",
                caption: "Mon",
                step: 1,
              },
              year: {
                format: "YYYY",
                caption: "Year",
                step: 1,
              },
            }}
            headerFormat={"DD/MM/YYYY"}
          />
        </div>
      );
    } else {
      //the date picker for safari mobile and tablets
      return (
        <div style={{ position: "relative" }}>
          {renderClearButton()}
          <input
            id={`custom-native-date-picker__${id}`}
            className="custom-native-date-picker safari-mobile-datepicker"
            type="date"
            onFocus={() => setShowHolder(false)}
            onBlur={() => setShowHolder(true)}
            placeholder="dd/mm/yyyy"
            {...props}
            onChange={onChange}
            style={{
              border: `1px solid rgba(205, 205, 205, 1)`,
              ...props.style,
              fontFamily: PoppinsRegular,
            }}
            value={value}
          />
          {showHolder && (!value || value.length < 1) ? (
            <div
              id="safari-placeholder"
              style={{
                position: "absolute",
                left: "0",
                bottom: "6px",
                textAlign: "start",
                color: "rgb(128, 128, 128)",
                marginLeft: "5%",
                fontFamily: PoppinsRegular,
                fontStyle: "italic",
              }}
            >
              {props.placeholder}
            </div>
          ) : null}
        </div>
      );
    }
  }
};

export default NativeDatepicker;

/*
<input
        id={`custom-native-date-picker__${id}`}
        className="custom-native-date-picker"
        type={type}
        onFocus={() => setType("date")}
        placeholder="dd/mm/yyyy"
        {...props}
        onChange={onChange}
        style={{
          backgroundColor: WHITE,
          border: `1px solid rgba(205, 205, 205, 1)`,
          ...props.style,
          fontFamily: PoppinsRegular,
        }}
      />*/

/*
       {showHolder && !value ? (
            <div
              style={{
                position: "absolute",
                bottom: "8px",
                fontSize: "2vw",
                color: "rgb(128, 128, 128)",
                marginLeft: "5%",
                fontFamily: PoppinsRegular,
                fontStyle: "italic",
              }}
            >
              {props.placeholder}
            </div>
          ) : null}*/
