import {
  MY_ANALYTICS_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
  RESULTS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
} from "../constants";
import { getCopies } from "../../helpers/geralHelpers/BackendHelper";
import { convertFileToBase64 } from "../../helpers/geralHelpers/FileHelper";
import { zoneObjectFromScore } from "../../helpers/geralHelpers/ZonesHelper";
import { getStaticCopies } from "./staticCopies.helper";
import { Markup } from "interweave";
import { getDimensionCopyFromLanguage } from "../../../modules/Geral/services/geral.service";
import { capitalize } from "@material-ui/core";

const pageToPerson = {
  [WELCOME_BACK_PAGE_ID]: "self",
  [RESULTS_PAGE_ID]: "self",
  [SHARE_RESULTS_PAGE_ID]: "other",
  [MY_ANALYTICS_PAGE_ID]: "self",
  [PRELIMINARY_RESULTS_PAGE_ID]: "self",
};

/**
 * @param {String} copy
 * @returns {Element}
 */
const getTransformedCopy = (copy = "") => {
  const substitutions = [
    { before: "[line_break]", after: "<br />" },
    { before: "[list_start]", after: "<ol>" },
    { before: "[list_end]", after: "</ol>" },
    { before: "[list_item]", after: "<li>" },
    { before: "[subtitle_start]", after: "<span class='fw-bold ff-popp-med'>" },
    { before: "[subtitle_end]", after: "</span>" },
  ];

  const newCopy = substitutions.reduce((prevCopy, { before, after }) => {
    return prevCopy.replaceAll(before, after);
  }, copy);

  return <Markup content={newCopy} />;
};

/**
 * This checks the user progress type
 * @param {Number} lastResultTotal
 * @param {Object} lastResultBeforeToday
 * @returns {String}
 */
const getProgressTypeByLastResults = ({
  lastResultTotal,
  lastResultBeforeToday,
}) => {
  if (!lastResultBeforeToday) {
    return "no_evo";
  } else if (lastResultTotal < lastResultBeforeToday.attributes.total) {
    return "has_worsened";
  } else if (lastResultTotal > lastResultBeforeToday.attributes.total) {
    return "has_improved";
  } else {
    return "hasnt_changed";
  }
};

/**
 * This checks the user purpose level
 * @param {Number} purposePercentage
 * @param {Number} score
 * @returns {String}
 */
const getPurposeLevel = ({ purposePercentage, score }) => {
  if (isNaN(purposePercentage)) {
    return "no_purpose";
  } else if (score < 25) {
    return "neutral";
  } else if (purposePercentage > score) {
    return "positive";
  } else if (purposePercentage < score) {
    return "negative";
  } else {
    return "neutral";
  }
};

/**
 * This checks the type of comparation between score and the average score
 * @param {Number} score
 * @param {Number} average
 * @returns {String}
 */
const getComparationType = ({ score, average }) => {
  if (average === "not enough") {
    return "not-enough";
  } else if (score > average) {
    return "above";
  } else if (score < average) {
    return "below";
  } else {
    return "same";
  }
};

/**
 * gets the final number to be displayed on each filter section
 * @param {Int} score
 * @param {Int} relation
 * @returns {String or Int or void}
 */
const getFinalNumber = (score, relation) => {
  if (relation === "not enough") return null;
  else if (score === relation) return "=";
  else return Math.abs(score - relation);
};

/**
 * gets the copies for all the result pages
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {Number} lastResultScore
 * @param {Object} lastResultBeforeToday
 * @param {Number} purposePercentage
 * @param {Object} comparationAverages
 * @param {String} gender
 * @returns {Object}
 */
const getResultsPageCopies = async ({
  language,
  name,
  page,
  lastResultScore,
  lastResultBeforeToday,
  purposePercentage,
  comparationAverages,
  gender,
}) => {
  const ZONE_AND_SCORE = await getZoneCopy({
    score: lastResultScore,
    language,
    name,
    page,
    gender,
  });

  const PROGRESS = await getProgressCopy({
    lastResultTotal: lastResultScore,
    lastResultBeforeToday,
    language,
    name,
    page,
    gender,
  });

  const DIMENSION_HIGHLIGHTS = await getDimensionHighlightsCopies({
    language,
    name,
    page,
    gender,
  });

  const PURPOSE = await getPurposeCopy({
    purposePercentage,
    score: lastResultScore,
    language,
    name,
    page,
    gender,
  });

  const COMPARE_WITH_OTHERS = await getComparationCopies({
    score: lastResultScore,
    averages: comparationAverages,
    language,
    name,
    page,
    gender,
  });

  return {
    ZONE_AND_SCORE,
    FOCUS_AND_PROGRESS: { PROGRESS },
    RADAR_GRAPH_ZONE: { DIMENSION_HIGHLIGHTS },
    PURPOSE,
    COMPARE_WITH_OTHERS,
  };
};

/**
 * Function: getPurposeCopy
 * Gets the copy of the progress section for FocusAndEvolution on CoreResultsTemplate
 * @param {Int} purposePercentage
 * @param {Int} score
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {String} gender
 * @returns {Object}
 */
const getZoneCopy = async ({ score, language, name, page, gender }) => {
  const zoneType = zoneObjectFromScore(score).name;

  const newZone = await getCopies({
    language,
    copy_versions: {
      "zone.body": `${pageToPerson[page]}-${zoneType}`,
      "zone.title": pageToPerson[page],
    },
    name,
    gender,
  });

  return {
    zone_type: zoneType,
    title: newZone["zone.title"],
    body: newZone["zone.body"],
  };
};

/**
 * Function: getProgressCopy
 * Gets the copy of the progress section for FocusAndEvolution on CoreResultsTemplate
 * @param {Int} lastResultTotal
 * @param {Object} lastResultBeforeToday
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {String} gender
 * @returns {Object}
 */
const getProgressCopy = async ({
  lastResultTotal,
  lastResultBeforeToday,
  language,
  name,
  page,
  gender,
}) => {
  if ([MY_ANALYTICS_PAGE_ID, PRELIMINARY_RESULTS_PAGE_ID].includes(page))
    return {};
  const progressType = getProgressTypeByLastResults({
    lastResultTotal,
    lastResultBeforeToday,
  });

  if (progressType === "no_evo" && page === SHARE_RESULTS_PAGE_ID)
    return { progress_type: progressType };

  const newProgress = await getCopies({
    language,
    copy_versions: {
      "progress.title": `${pageToPerson[page]}-${progressType}`,
      "progress.body": `${pageToPerson[page]}-${progressType}`,
      "progress.image": `${pageToPerson[page]}-${progressType}`,
    },
    name,
    gender,
  });

  return {
    progress_type: progressType,
    title: newProgress["progress.title"],
    body: newProgress["progress.body"],
    image: convertFileToBase64(newProgress["progress.image"]),
  };
};

/**
 * Function: getProgressCopy
 * Gets the copy of the progress section for DimensionWellbeing on CoreResultsTemplate
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {String} gender
 * @returns {Object}
 */
const getDimensionHighlightsCopies = async ({
  language,
  name,
  page,
  gender,
}) => {
  const newDimensionHighlights = await getCopies({
    language,
    copy_versions: {
      "dimension-highlight.lowest": pageToPerson[page],
      "dimension-highlight.highest": pageToPerson[page],
    },
    name,
    gender,
  });

  return {
    lowest: newDimensionHighlights["dimension-highlight.lowest"],
    highest: newDimensionHighlights["dimension-highlight.highest"],
  };
};

/**
 * Function: getPurposeCopy
 * Gets the copy of the progress section for FocusAndEvolution on CoreResultsTemplate
 * @param {Int} purposePercentage
 * @param {Int} score
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {String} gender
 * @returns {Object}
 */
const getPurposeCopy = async ({
  purposePercentage,
  score,
  language,
  name,
  page,
  gender,
}) => {
  if ([MY_ANALYTICS_PAGE_ID, PRELIMINARY_RESULTS_PAGE_ID].includes(page))
    return {};
  const purposeType = getPurposeLevel({ purposePercentage, score });

  const newPurpose = await getCopies({
    language,
    copy_versions: {
      "purpose.intro": purposeType !== "no_purpose" ? "show" : "hide",
      "purpose.body": `${pageToPerson[page]}-${purposeType}`,
    },
    name,
    gender,
  });

  return {
    purpose_type: purposeType,
    intro: getTransformedCopy(newPurpose["purpose.intro"]),
    body: getTransformedCopy(newPurpose["purpose.body"]),
  };
};

/**
 * gets all comparation copies depending on the averages
 * @param {Number} score
 * @param {Object} averages
 * @param {String} language
 * @param {String} name
 * @param {String} page
 * @param {String} gender
 * @returns {Object}
 */
const getComparationCopies = async ({
  score,
  averages,
  language,
  name,
  page,
  gender,
}) => {
  if ([PRELIMINARY_RESULTS_PAGE_ID].includes(page) || !averages) return {};
  const countryComparationType = getComparationType({
    score,
    average: averages.country,
  });

  const countryAndGenderComparationType = getComparationType({
    score,
    average: averages.countryAndGender,
  });

  const countryAndGenderAndAgeComparationType = getComparationType({
    score,
    average: averages.countryAndGenderAndAge,
  });

  const newComparation = await getCopies({
    language,
    copy_versions: {
      "comparation.country": `${pageToPerson[page]}-${countryComparationType}`,
      "comparation.countryAndGender": `${pageToPerson[page]}-${countryAndGenderComparationType}`,
      "comparation.countryAndGenderAndAge": `${pageToPerson[page]}-${countryAndGenderAndAgeComparationType}`,
      "comparation.countryArrow": countryComparationType,
      "comparation.countryAndGenderArrow": countryAndGenderComparationType,
      "comparation.countryAndGenderAndAgeArrow":
        countryAndGenderAndAgeComparationType,
    },
    name,
    gender,
  });

  return {
    country: {
      comparation_type: countryComparationType,
      text: newComparation["comparation.country"].replace(
        "[country_name]",
        averages.countryName
      ),
      number: getFinalNumber(score, averages.country),
      arrow: convertFileToBase64(newComparation["comparation.countryArrow"]),
    },
    countryAndGender: {
      comparation_type: countryAndGenderComparationType,
      text: newComparation["comparation.countryAndGender"].replace(
        "[country_name]",
        averages.countryName
      ),
      number: getFinalNumber(score, averages.countryAndGender),
      arrow: convertFileToBase64(
        newComparation["comparation.countryAndGenderArrow"]
      ),
    },
    countryAndGenderAndAge: {
      comparation_type: countryAndGenderAndAgeComparationType,
      text: newComparation["comparation.countryAndGenderAndAge"].replace(
        "[country_name]",
        averages.countryName
      ),
      number: getFinalNumber(score, averages.countryAndGenderAndAge),
      arrow: convertFileToBase64(
        newComparation["comparation.countryAndGenderAndAgeArrow"]
      ),
    },
  };
};

/**
 * static copies
 * @param {String} language
 * @returns {Object}
 */
const getStaticShareCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      SHARE_INFO: { USER_VERSION: null, GUEST_VERSION: null },
    },
    language,
  }).SHARE_INFO;
};

/**
 * gets all the error copies for the comparations
 * @param {String} language
 * @param {String} type
 * @returns {Object}
 */
const getStaticComparationFiltersErrorsCopies = ({ language, type }) => {
  return getStaticCopies({
    copy_versions: {
      COMPARE_WITH_OTHERS_GIVE_INFORMATION_FILTERS_ERRORS: type,
    },
    language,
  }).COMPARE_WITH_OTHERS_GIVE_INFORMATION_FILTERS_ERRORS;
};

const getPreliminaryBenefits = ({ language, usersCount }) => {
  const benefits = Object.values(
    getStaticCopies({
      copy_versions: {
        PRELIMINARY_BENEFITS: null,
      },
      language,
    }).PRELIMINARY_BENEFITS
  );

  const substitute = benefits.find((b) => b.indexOf("[users_number]") > -1);
  const rest = benefits.filter((b) => b.indexOf("[users_number]") === -1);

  return [...rest, substitute.replace("[users_number]", usersCount)];
};

/**
 * returns all the static copies for the results page
 * @param {String} language
 * @param {String} page
 * @param {String} name
 * @param {Object} dimensions
 * @returns {Object}
 */
const getStaticResultsPagesCopies = ({
  language,
  page,
  name,
  dimensions = {},
  usersCount,
}) => {
  const DIMENSIONS = getStaticFormattedDimensionsCopies({
    language,
    dimensions,
    page,
  });

  const PRELIMINARY_BENEFITS =
    page === PRELIMINARY_RESULTS_PAGE_ID && usersCount
      ? getPreliminaryBenefits({ language, page, usersCount })
      : {};

  return {
    DIMENSIONS,
    PRELIMINARY_BENEFITS,
    ...getStaticCopies({
      copy_versions: {
        WELCOME_BACK_ZONE: null,
        ZONE_AND_SCORE: {
          GRAPH: pageToPerson[page],
          LAST_RESULT: null,
          ZONE: null,
          PRELIMINARY: null,
          PRELIMINARY_MODAL: null,
        }, // what if I could specify like {GRAPH: true, or PRELIMINARY: true}
        FOCUS_AND_PROGRESS: {
          FOCUS: pageToPerson[page],
          PROGRESS: null,
        },
        RADAR_GRAPH_ZONE: { MAIN: null, HIDE_RADAR_ZONE: null },
        PURPOSE: pageToPerson[page],
        TIPS_ZONE: pageToPerson[page],
        COMPARE_WITH_OTHERS: {
          FILTERED_INFORMATION: pageToPerson[page],
          GIVE_INFORMATION: null,
        },
        SHARE_INFO: {
          USER_VERSION: null,
          GUEST_VERSION: null,
        },
      },
      language,
      name,
    }),
  };
};

/**
 * returns all the static copies for the survey button
 * @param {String} language
 * @returns {Object}
 */
const getStaticSurveyButtonCopy = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      SURVEY_SUBMIT_BUTTON: null,
    },
    language,
  }).SURVEY_SUBMIT_BUTTON;
};

/**
 * returns all the static copies for the actions menu
 * @param {String} language
 * @returns {Object}
 */
const getStaticActionsMenuCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      ACTIONS_MENU: null,
    },
    language,
  }).ACTIONS_MENU;
};

/**
 * returns all the static copies for the cookies
 * @param {String} language
 * @param {String} type
 * @returns {Object}
 */
const getStaticCookiesCopies = ({ language, type }) => {
  return getStaticCopies({
    copy_versions: {
      COOKIES_POLICY: null,
      COOKIES_PREFERENCES: type,
    },
    language,
  });
};

/**
 * returns all the static copies for the footer
 * @param {String} language
 * @returns {Object}
 */
const getStaticFooterCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      FOOTER: null,
    },
    language,
  }).FOOTER;
};

/**
 * returns all the static copies for the progress overtime section
 * @param {String} language
 * @returns {Object}
 */
const getStaticProgressOvertimeCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      PROGRESS_OVERTIME: null,
    },
    language,
  }).PROGRESS_OVERTIME;
};

/**
 * returns all the static copies for the meet violet page
 * @param {String} language
 * @returns {Object}
 */
const getStaticMeetVioletCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      MEET_VIOLET: null,
    },
    language,
  }).MEET_VIOLET;
};

/**
 * returns all the static copies for the landing page
 * @param {String} language
 * @returns {Object}
 */
const getStaticLandingPageCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      LANDING_PAGE: null,
    },
    language,
  }).LANDING_PAGE;
};

/**
 * returns all the static copies for share email
 * @param {String} language
 * @returns {Object}
 */
const getStaticShareEmailCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      SHARE_SOCIAL_MEDIA_OR_EMAIL: null,
    },
    language,
  }).SHARE_SOCIAL_MEDIA_OR_EMAIL.email;
};

/**
 * returns all the static copies for the flash alerts
 * @param {String} language
 * @param {String} type
 * @returns {Object}
 */
const getStaticFlashPropsCopies = ({ language, type }) => {
  return getStaticCopies({
    copy_versions: {
      FLASH_PROPS: type,
    },
    language,
  }).FLASH_PROPS;
};

/**
 * returns all the static copies for the progress bar component
 * @param {String} language
 * @returns {Object}
 */
const getStaticProgressBarCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      PROGRESS_BAR: null,
    },
    language,
  }).PROGRESS_BAR;
};

/**
 * returns all the static copies for the webcam component
 * @param {String} language
 * @returns {Object}
 */
const getStaticWebCamCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      WEB_CAM: null,
    },
    language,
  }).WEB_CAM;
};

/**
 * returns all the static copies for the loading
 * @param {String} language
 * @param {String} type
 * @returns {Object}
 */
const getStaticLoadingCopies = ({ language, type }) => {
  return getStaticCopies({
    copy_versions: {
      LOADING: type,
    },
    language,
  }).LOADING;
};

/**
 * returns all the static copies for the terms of service page
 * @param {String} language
 * @returns {Object}
 */
const getStaticTermsOfServiceCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      TERMS_OF_SERVICE: null,
    },
    language,
  }).TERMS_OF_SERVICE;
};

/**
 * returns all the static copies for the privacy policy page
 * @param {String} language
 * @returns {Object}
 */
const getStaticPrivacyPolicyCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      PRIVACY_POLICY: null,
    },
    language,
  }).PRIVACY_POLICY;
};

/**
 * returns all the static copies for the error pages
 * @param {String} language
 * @param {String} type
 * @returns {Object}
 */
const getStaticErrorPageCopies = ({ language, type }) => {
  return getStaticCopies({
    copy_versions: {
      ERROR_PAGE: type,
    },
    language,
  }).ERROR_PAGE;
};

/**
 * returns all the static copies for the analytics filter section
 * @param {String} language
 * @returns {Object}
 */
const getStaticAnalyticsFiltersCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      ANALYTICS_FILTERS: null,
    },
    language,
  }).ANALYTICS_FILTERS;
};

/**
 * gets the copy type depending on dimension
 * @param {String} dimension
 * @returns {String}
 */
const copyTypeFromDimension = (dimension) => {
  if (!dimension) return "incomplete";
  else if (dimension === "work") return "alternative";
  else return "standard";
};

/**
 * replaces a dimension placeholder for the provided dimension
 * @param {String} copy
 * @param {String} dimension
 * @returns {String}
 */
const replaceDimension = (copy, dimension) =>
  copy.replace("[dimension]", dimension);

/**
 * returns all the static copies for dimensions
 * @param {String} language
 * @returns {Object}
 */
const getStaticFormattedDimensionsCopies = ({
  language,
  dimensions: { lowest, highest },
  page,
}) => {
  const lowestDimensionCopy = getStaticCopies({
    copy_versions: {
      FORMATTED_DIMENSION:
        pageToPerson[page] + "-" + copyTypeFromDimension(lowest),
    },
    language,
  }).FORMATTED_DIMENSION;

  const highestDimensionCopy = getStaticCopies({
    copy_versions: {
      FORMATTED_DIMENSION:
        pageToPerson[page] + "-" + copyTypeFromDimension(highest),
    },
    language,
  }).FORMATTED_DIMENSION;

  const lowestDimensionPlaceholder = getDimensionCopyFromLanguage({
    dimension: lowest,
    language,
  });

  const highestDimensionPlaceholder = getDimensionCopyFromLanguage({
    dimension: highest,
    language,
  });

  return {
    ZONE_LAST_RESULT: {
      LOWEST: replaceDimension(
        lowestDimensionCopy.ZONE_LAST_RESULT,
        lowestDimensionPlaceholder
      ),
    },
    FOCUS: {
      LOWEST: replaceDimension(
        lowestDimensionCopy.FOCUS,
        lowestDimensionPlaceholder
      ),
    },
    RADAR_GRAPH_ZONE: {
      LOWEST: capitalize(
        replaceDimension(
          lowestDimensionCopy.RADAR_GRAPH_ZONE,
          lowestDimensionPlaceholder
        )
      ),
      HIGHEST: capitalize(
        replaceDimension(
          highestDimensionCopy.RADAR_GRAPH_ZONE,
          highestDimensionPlaceholder
        )
      ),
    },
    TIPS_ZONE: {
      LOWEST: capitalize(
        replaceDimension(
          lowestDimensionCopy.RADAR_GRAPH_ZONE,
          lowestDimensionPlaceholder
        )
      ),
      HIGHEST: capitalize(
        replaceDimension(
          highestDimensionCopy.RADAR_GRAPH_ZONE,
          highestDimensionPlaceholder
        )
      ),
    },
  };
};

/**
 * returns all the static copies for the about me page
 * @param {String} language
 * @returns {Object}
 */
const getStaticAboutMeCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      ABOUT_ME: null,
    },
    language,
  }).ABOUT_ME;
};

/**
 * returns all the static copies for the welcome survey
 * @param {String} language
 * @returns {Object}
 */
const getStaticWelcomeSurveyCopies = ({ language }) => {
  return getStaticCopies({
    copy_versions: {
      WELCOME_SURVEY: null,
    },
    language,
  }).WELCOME_SURVEY;
};

/**
 * returns all the static copies for the no available data page
 * @param {String} language
 * @param {String} type
 * @param {String} name
 * @returns {Object}
 */
const getStaticNoAvailableDataCopies = ({ language, type, name }) => {
  return getStaticCopies({
    copy_versions: {
      MY_ANALYTICS_NO_DATA: type,
    },
    language,
    name,
  }).MY_ANALYTICS_NO_DATA;
};

export {
  getResultsPageCopies,
  getStaticShareCopies,
  getStaticComparationFiltersErrorsCopies,
  getStaticActionsMenuCopies,
  getStaticCookiesCopies,
  getStaticFooterCopies,
  getStaticMeetVioletCopies,
  getStaticLandingPageCopies,
  getStaticShareEmailCopies,
  getStaticFlashPropsCopies,
  getStaticProgressBarCopies,
  getStaticWebCamCopies,
  getStaticLoadingCopies,
  getStaticErrorPageCopies,
  getStaticAnalyticsFiltersCopies,
  getStaticResultsPagesCopies,
  getStaticAboutMeCopies,
  getStaticProgressOvertimeCopies,
  getStaticTermsOfServiceCopies,
  getStaticPrivacyPolicyCopies,
  getStaticWelcomeSurveyCopies,
  getStaticSurveyButtonCopy,
  getStaticNoAvailableDataCopies,
};
