import React, { useEffect } from "react";
import { Markup } from "interweave";
import "./TipCard.css";

/**
 * Functional Component: TipCard
 * This component is used to render a tip inside a card as seen in welcome back, result, analytics and share results pages
 * @param {Object} tip
 * @param {String} category
 * @returns {Element}
 * */
const TipCard = ({ tip }) => {
  /**
   * returns the values to now when to auto scroll tips up when they are out of viewport
   * @returns {Object}
   * */
  const getOffsetValueForScrollingTips = () => {
    if (window.innerWidth >= 3000) {
      return {
        negative: -1400,
        positive: 1400,
      };
    } else if (window.innerWidth >= 1500) {
      return {
        negative: -800,
        positive: 800,
      };
    } else if (window.innerWidth >= 1200) {
      return {
        negative: -570,
        positive: 570,
      };
    } else if (window.innerWidth >= 992) {
      return {
        negative: -500,
        positive: 500,
      };
    } else if (window.innerWidth >= 576) {
      return {
        negative: -1300,
        positive: 700,
      };
    } else {
      return {
        negative: -800,
        positive: 500,
      };
    }
  };

  /**
   * checks if tips are in viewport, and if they are not scrolls them to top
   * @param {Element} element
   * @returns {void}
   * */
  const isInViewport = (element) => {
    if (!element) return;

    let cards = document.getElementsByClassName("tip-cards");
    let counter = 0;

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].scrollTop !== 0) counter += 1;
    }

    if (counter === 0) return;

    const rect = element.getBoundingClientRect();
    const offSetValues = getOffsetValueForScrollingTips();

    let isIt =
      rect.top >= offSetValues.negative &&
      rect.left >= offSetValues.negative &&
      rect.bottom <=
        (window.innerHeight + offSetValues.positive ||
          document.documentElement.clientHeight + offSetValues.positive) &&
      rect.right <=
        (window.innerWidth + offSetValues.positive ||
          document.documentElement.clientWidth + offSetValues.positive);

    if (!isIt) {
      for (let i = 0; i < cards.length; i++) {
        cards[i].scroll(0, 0);
      }
    }
  };

  //calls the isInViewport function , made to be used on the event listener
  const scrollFunction = () => {
    return isInViewport(document.getElementById("tip-card-main-div"));
  };

  //sets the event listener to know when to scroll
  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);

    return () => window.removeEventListener("scroll", scrollFunction);
  });

  return (
    <div
      style={{ boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.1)" }}
      className="w-100 mx-sm-5 tip-cards"
      id="tip-card-main-div"
    >
      <div>
        <img src={tip.image} alt={`${tip.title} tip`} />
      </div>

      <h5
        className="fw-bold ff-popp-med tip-card__topic"
        style={{ color: "var(--purple-1)" }}
      >
        {tip.topic}
      </h5>

      <div className="tip-card__text">
        <div
          className="ff-popp-med tip-card__title"
          style={{ color: "var(--purple-1)" }}
        >
          {tip.title}
        </div>

        <div className="tip-card__copy">{getTransformedCopy(tip.intro)}</div>

        <div className="ff-popp-med tip-card__subtitle">
          {tip.subtitle ? tip.subtitle + ":" : ""}
        </div>

        <div className="tip-card__copy">{getTransformedCopy(tip.action)}</div>
      </div>
    </div>
  );
};

/**
 * @param {String} copy
 * @returns
 */
const getTransformedCopy = (copy = "") => {
  const substitutions = [
    { before: "[line_break]", after: "<br />" },
    { before: "[list_start]", after: "<ol>" },
    { before: "[list_end]", after: "</ol>" },
    { before: "[list_item]", after: "<li>" },
    { before: "[subtitle_start]", after: "<span class='fw-bold ff-popp-med'>" },
    { before: "[subtitle_end]", after: "</span>" },
  ];

  const newCopy = substitutions.reduce((prevCopy, { before, after }) => {
    return prevCopy.replaceAll(before, after);
  }, copy);

  return <Markup content={newCopy} />;
};

export default TipCard;
