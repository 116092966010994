import React, { useState } from "react";
import Select from "react-select";
import { SECONDARY_TEXT } from "../../../../utils/constants/colorsConstants";
import { useResize } from "../../../Geral/hooks/useResize";

/**
 * Functional Component: NewDropdownComponent
 * Renders a dropdown
 * @param {String} label
 * @param {String or Int} name
 * @param {Function} onChange
 * @param {Array} data
 * @param {String or Int} value
 * @param {Object} controlStyles
 * @returns {Element}
 */
const NewDropdownComponent = ({
  label,
  name,
  onChange,
  data,
  value,
  controlStyles,
  ...props
}) => {
  const [selectionOptions, selectOptions] = useState(value);

  const handleChange = (event) => {
    selectOptions(event);
    if (onChange) onChange(event);
  };

  useResize();

  const newCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "5px",
      /*
      borderRadius: width < 1200 ? "5px" : "0.2vw",
      padding: width < 1200 ? "" : "1vw 3.6vw",
      marginBottom: width < 1200 ? "" : "1vw",
      */
      height: "5.8vh",
      minHeight: "2.6rem",
      maxHeight: "4.5rem",
      color: "#eeeeee",
      border: "solid 1px #c2c0c7",
      ...controlStyles,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: SECONDARY_TEXT,
    }),
    indicatorSeparator: () => null,
  };

  /*
  const newLabelStyles = {
    fontSize: width < 1200 ? "" : "1vw",
    marginBottom: width < 1200 ? "" : "1vw",
  };
  */

  return (
    <>
      {label ? <label forhtml={name}>{label}</label> : null}
      <Select
        key={selectionOptions}
        options={data}
        onChange={handleChange}
        value={value}
        styles={{ ...newCustomStyles }}
        maxMenuHeight="10rem"
        {...props}
      />
    </>
  );
};

export default NewDropdownComponent;
