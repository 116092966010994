import login_backend from "../../../../core/apis/login_backend";
import { INTERNAL_SIGN_OUT, SAVE_ROLES } from "../../../../store/actions/types";

/**
 * logs out a user
 * @returns {Object}
 */
const internalSignOut = () => {
  return async (dispatch) => {
    await login_backend.post("/logout");

    dispatch({
      type: INTERNAL_SIGN_OUT,
    });
  };
};

/**
 * saves user roles on redux
 * @param {Array} roles
 * @returns {Object}
 */
const saveRoles = (roles) => {
  return {
    type: SAVE_ROLES,
    payload: roles,
  };
};

/**
 * cleans all redux data
 * @returns {Object}
 */
const cleanAllRedux = () => {
  return {
    type: INTERNAL_SIGN_OUT,
  };
};

export { internalSignOut, saveRoles, cleanAllRedux };
