import backend from "../../../core/apis/backend";
import login_backend from "../../../core/apis/login_backend";
import { CORE_SURVEY_ID } from "../../../utils/constants/constants";
import { getStaticComparationFiltersErrorsCopies } from "../../../utils/constants/copies/copies.helper";
import { createSharedFilteredData } from "../../../utils/helpers/geralHelpers/BackendHelper";
import { extremeBirthdates } from "../../../utils/helpers/geralHelpers/DatesHelper";
import { countryFormatter } from "../../Users/services/aboutMe.service";

/**
 * gets the average result for the users in the current user country
 * @param {Object} currentUser
 * @returns {Int}
 */
const getCountryFilteredAverage = async (currentUser) => {
  try {
    const average = await backend.post(`/filteredAverage/${CORE_SURVEY_ID}`, {
      filters: {
        country: currentUser.attributes.country,
      },
    });

    return average.data.average;
  } catch (e) {
    return "not enough";
  }
};

/**
 * gets the average result for the users in the current user country and gender
 * @param {Object} currentUser
 * @returns {Int}
 */
const getCountryAndGenderFilteredAverage = async (currentUser) => {
  try {
    const average = await backend.post(`/filteredAverage/${CORE_SURVEY_ID}`, {
      filters: {
        country: currentUser.attributes.country,
        gender: currentUser.attributes.gender,
      },
    });

    return average.data.average;
  } catch (e) {
    console.error(e);
    return "not enough";
  }
};

/**
 * gets the average result for the users in the current user country and gender and age range
 * @param {Object} currentUser
 * @returns {Int}
 */
const getCountryAndGenderAndAgeFilteredAverage = async (currentUser) => {
  try {
    const dateOfBirth = new Date(currentUser.attributes.birthdate);
    const nowDate = new Date();
    const personAge = nowDate.getFullYear() - dateOfBirth.getFullYear();

    const average = await backend.post(`/filteredAverage/${CORE_SURVEY_ID}`, {
      filters: {
        country: currentUser.attributes.country,
        gender: currentUser.attributes.gender,
        minAge: personAge - 5,
        maxAge: personAge + 5,
      },
    });

    return average.data.average;
  } catch (e) {
    return "not enough";
  }
};

/**
 * uses the above functions to get all averages in one go
 * @param {Object} currentUser
 * @returns {Object}
 */
const getAllFilteredAverages = async (currentUser) => {
  const country = await getCountryFilteredAverage(currentUser);
  const countryAndGender = await getCountryAndGenderFilteredAverage(
    currentUser
  );
  const countryAndGenderAndAge = await getCountryAndGenderAndAgeFilteredAverage(
    currentUser
  );

  return { country, countryAndGender, countryAndGenderAndAge };
};

/**
 * formats a date
 * @param {Date} date
 * @returns {String}
 */
const formatDate = (date) => {
  const day = parseInt(date.getDate()) + 1;

  return (
    date.getFullYear() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    (day.toString().length === 1 ? "0" + day : day)
  );
};

/**
 * gets error for gender
 * @param {String} gender
 * @returns {Object}
 */
const getGenderError = (gender) => {
  return !gender ? "blank" : null;
};

/**
 * gets error for country
 * @param {String} country
 * @returns {Object}
 */
const getCountryError = (country) => {
  return !country ? "blank" : null;
};

/**
 * gets error for birthdate
 * @param {String} birthdate
 * @returns {Object}
 */
const getBirthdateError = (birthdate) => {
  if (!birthdate) return "blank";
  else if (birthdate < extremeBirthdates.min) return "min";
  else if (birthdate > extremeBirthdates.max) return "max";
  else return null;
};

/**
 * gets error for each filter
 * @param {String} gender
 * @param {String} country
 * @param {String} birthdate
 * @returns {Object}
 */
const getErrors = ({ gender, country, birthdate }) => {
  const genderError = getGenderError(gender);
  const countryError = getCountryError(country);
  const birthdateError = getBirthdateError(birthdate && new Date(birthdate));

  return {
    gender: genderError,
    country: countryError,
    birthdate: birthdateError,
  };
};

/**
 * updates the users demographics
 * @param {String} gender
 * @param {String} country
 * @param {Int} age
 * @param {Function} setErrorsToDisplay
 * @param {Boolean} saveForever
 * @param {Object} currentUser
 * @param {Function} fetchUserWithAllAttributes
 * @param {String} language
 */
const handleSubmit = async ({
  gender,
  country,
  birthdate,
  setErrorsToDisplay,
  saveForever,
  currentUser,
  fetchUserWithAllAttributes,
  language,
  share_id,
}) => {
  const updatedGender = gender || currentUser.attributes.gender;
  const updatedCountry = country || currentUser.attributes.country;
  const updatedBirthdate = birthdate || currentUser.attributes.birthdate;

  const errors = getErrors({
    gender: updatedGender,
    country: updatedCountry,
    birthdate: updatedBirthdate,
  });

  if (errors.gender || errors.age || errors.country) {
    const ERRORS_COPIES = getStaticComparationFiltersErrorsCopies({
      language,
      type: {
        gender: errors.gender,
        country: errors.country,
        birthdate: errors.birthdate,
      },
    });
    setErrorsToDisplay(ERRORS_COPIES);
  } else {
    await login_backend.put(`/users/${currentUser.attributes.user_id}`, {
      gender: updatedGender,
      birthdate: updatedBirthdate,
      country: updatedCountry,
      expiry_date: saveForever ? "none" : formatDate(new Date()),
    });

    await fetchUserWithAllAttributes(currentUser.attributes.user_id);

    const { country, countryAndGender, countryAndGenderAndAge } =
      await getAllFilteredAverages({
        attributes: {
          country: updatedCountry,
          gender: updatedGender,
          birthdate: updatedBirthdate,
        },
      });

    await createSharedFilteredData({
      result_share_id: share_id,
      country: country.toString(),
      countryAndGender: countryAndGender.toString(),
      countryAndGenderAndAge: countryAndGenderAndAge.toString(),
      countryName: countryFormatter(updatedCountry),
    });
  }
};

export { handleSubmit, getAllFilteredAverages, getBirthdateError };
