import ReactHtmlTableToExcel from "react-html-table-to-excel";
import backend from "../../../core/apis/backend";
import login_backend from "../../../core/apis/login_backend";
import { redirectToProfilePage } from "../../../core/Routing/RoutesRedirects";

const PAGES = {
  USERS_DASHBOARD: {
    id: "userMetrics",
    navbar_label: "Users Dashboard",
    customNavbarStyles: { width: "9vw" },
  },
  RESULTS_DASHBOARD: {
    id: "averageScores",
    navbar_label: "Results Dashboard",
    customNavbarStyles: { width: "9vw" },
  },
  GOOGLE_ANALYTICS: {
    id: "googleAnalytics",
    navbar_label: "Analytics Events",
    customNavbarStyles: { width: "8vw" },
  },
};

/**
 * checks if the password the user inputs on step1 is valid
 * so the user can pass on to step 2 section
 * @param {String} pass
 * @param {Function} setStep
 */
const onPassSubmit = async (pass, setStep) => {
  if (pass) {
    try {
      await backend.post("/identity", {
        password: pass,
      });

      setStep("two");
    } catch (e) {
      redirectToProfilePage();
    }
  }
};

const formatDate = (date) => {
  return date.toISOString().split("T")[0].replaceAll("-", "/");
};

/**
 * returns the start and end date for a selected time period
 * @param {String} timePeriod
 * @returns {Object}
 */
const getDatesForTimePeriods = (timePeriod) => {
  let nowDate = new Date();
  nowDate.setDate(nowDate.getDate() + 1);

  if (timePeriod === "week") {
    let beforeDate = new Date();
    beforeDate.setDate(beforeDate.getDate() - 7);

    return {
      beforeDate: formatDate(beforeDate),
      nowDate: formatDate(nowDate),
    };
  } else if (timePeriod === "month") {
    let beforeDate = new Date();
    beforeDate.setMonth(beforeDate.getMonth() - 1);

    return {
      beforeDate: formatDate(beforeDate),
      nowDate: formatDate(nowDate),
    };
  } else if (timePeriod === "year") {
    let beforeDate = new Date();
    beforeDate.setFullYear(beforeDate.getFullYear() - 1);

    return {
      beforeDate: formatDate(beforeDate),
      nowDate: formatDate(nowDate),
    };
  } else if (timePeriod === "all") {
    let beforeDate = new Date(2020, 0, 1);

    return {
      beforeDate: formatDate(beforeDate),
      nowDate: formatDate(nowDate),
    };
  }
};

/**
 * counts how many registered users exist on the login database
 * and on the violet database depending on filters
 * @param {Function} setTotalUsers
 * @param {Function} setWellbeingTotalUsers
 * @param {String} startDate
 * @param {String} endDate
 * @param {Object} filterState
 */
const getUsersCount = async (
  setTotalUsers,
  setWellbeingTotalUsers,
  startDate,
  endDate,
  filterState
) => {
  let number = "ERROR";
  let wellbeingNumber = "ERROR";

  let newDate = null;

  if (endDate) {
    let newDate = new Date(endDate);
    newDate.setDate(newDate.getDate() + 1);
  }

  try {
    number = await login_backend.post("/countUsers", {
      startDate,
      endDate: newDate,
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
    });
    wellbeingNumber = await backend.post("/countUsers", {
      startDate,
      endDate: newDate,
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
    });

    setTotalUsers(number.data.total);
    setWellbeingTotalUsers(wellbeingNumber.data.total);
  } catch (e) {
    setTotalUsers(number);
    setWellbeingTotalUsers(wellbeingNumber);
  }
};

/**
 * counts how many users of specific gender exist
 * @param {Object} filterState
 * @param {Function} setGenderTotalUsers
 */
const getGenderUsersCount = async (filterState, setGenderTotalUsers) => {
  let number = "ERROR";

  try {
    number = await login_backend.post("/countUsersFiltered", {
      gender: filterState.gender,
    });

    setGenderTotalUsers(number.data.total);
  } catch (e) {
    setGenderTotalUsers(number);
  }
};

/**
 * counts how many users of a specific country exist
 * @param {Object} filterState
 * @param {Function} setCountryTotalUsers
 */
const getCountryUsersCount = async (filterState, setCountryTotalUsers) => {
  let number = "ERROR";

  try {
    number = await login_backend.post("/countUsersFiltered", {
      country: filterState.country,
    });

    setCountryTotalUsers(number.data.total);
  } catch (e) {
    setCountryTotalUsers(number);
  }
};

/**
 * counts how many users in a specific age range exist
 * @param {Object} filterState
 * @param {Function} setAgeTotalUsers
 */
const getAgeUsersCount = async (filterState, setAgeTotalUsers) => {
  let number = "ERROR";

  if (filterState.minAge || filterState.maxAge) {
    try {
      number = await login_backend.post("/countUsersFiltered", {
        min_age: filterState.minAge ?? 0,
        max_age: filterState.maxAge ?? 150,
      });

      setAgeTotalUsers(number.data.total);
    } catch (e) {
      setAgeTotalUsers(number);
    }
  } else {
    setAgeTotalUsers(0);
  }
};

/**
 * counts how many users exist with all the filters mixed
 * @param {Object} filterState
 * @param {Function} setTotalFilteredUsers
 */
const getTotalFilteredUsers = async (filterState, setTotalFilteredUsers) => {
  let number = "ERROR";

  try {
    number = await login_backend.post("/countTotalUsersFiltered", {
      country: filterState.country,
      gender: filterState.gender,
      min_age: filterState.minAge,
      max_age: filterState.maxAge,
    });

    setTotalFilteredUsers(number.data.total);
  } catch (e) {
    setTotalFilteredUsers(number);
  }
};

/**
 * counts the total results that exist
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setAllResults
 */
const countAllResults = async (startDate, endDate, setAllResults) => {
  try {
    let newDate = null;

    if (endDate) {
      let newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let number = await backend.post("/countAllResults", {
      startDate,
      endDate: newDate,
    });

    setAllResults(number.data.value.toString());
  } catch (e) {
    setAllResults("ERROR");
  }
};

/**
 * counts how many results exist on a specific zone and wich percentage each
 * one of the zones occupies
 * @param {Object} filterState
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setZonesCountAndPercentages
 */
const getZonesCountAndPercentages = async (
  filterState,
  startDate,
  endDate,
  setZonesCountAndPercentages
) => {
  try {
    let newDate = null;

    if (endDate) {
      let newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let { data } = await backend.post("/zonesCount", {
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
      startDate,
      endDate: newDate,
    });

    setZonesCountAndPercentages(data);
  } catch (e) {
    setZonesCountAndPercentages({});
  }
};

/**
 * counts how many results exist for each feel and wich percentage each
 * one of them occupies
 * @param {Object} filterState
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setWelcomingZoneCountAndPercentages
 */
const getWelcomingZoneCountAndPercentages = async (
  filterState,
  startDate,
  endDate,
  setWelcomingZoneCountAndPercentages
) => {
  try {
    let newDate = null;

    if (endDate) {
      let newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let { data } = await backend.post("/welcomingZonesCountAndAverage", {
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
      startDate,
      endDate: newDate,
    });

    setWelcomingZoneCountAndPercentages(data);
  } catch (e) {
    setWelcomingZoneCountAndPercentages({});
  }
};

/**
 * calculates the average return rate for all users depending on a date interval
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setReturnRate
 */
const getAverageReturnRate = async (startDate, endDate, setReturnRate) => {
  console.log(startDate, endDate, "HERE");
  try {
    let newDate = null;

    if (endDate) {
      newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let outcome = await backend.post("/averageReturnRate", {
      startDate,
      endDate: newDate,
    });

    console.log(outcome, "HERE", 2);

    setReturnRate(outcome.data[0]["avg(count)"] || 0);
  } catch (e) {
    setReturnRate("No value.");
  }
};

/**
 * calculates the average return rate each user depending on a date interval
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setReturnRateByUser
 */
const getAverageReturnRateByUser = async (
  startDate,
  endDate,
  setReturnRateByUser
) => {
  try {
    let newDate = null;

    if (endDate) {
      newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let outcome = await backend.post("/returnRateByUser", {
      startDate,
      endDate: newDate,
    });

    let sorted = outcome.data.sort((a, b) => {
      if (a.resultsNumber > b.resultsNumber) return 1;
      if (a.resultsNumber < b.resultsNumber) return -1;
      return 0;
    });

    setReturnRateByUser(sorted);
  } catch (e) {
    setReturnRateByUser([]);
  }
};

/**
 * returns the data for the bar graph on the averages section for results
 * @param {Object} filterState
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setAllGraphAverages
 */
const getGraphDate = async (
  filterState,
  startDate,
  endDate,
  setAllGraphAverages
) => {
  try {
    let newDate = null;

    if (endDate) {
      newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let outcome = await backend.post("/averagesWithFilters", {
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
      startDate,
      endDate: newDate,
    });

    setAllGraphAverages(outcome.data);
  } catch (e) {
    setAllGraphAverages({
      globalAverage: [{ "AVG(total)": "ERROR" }],
      physicalAverage: [{ "AVG(total)": "ERROR" }],
      mentalAverage: [{ "AVG(total)": "ERROR" }],
      socialAverage: [{ "AVG(total)": "ERROR" }],
      workAverage: [{ "AVG(total)": "ERROR" }],
    });
  }
};

/**
 * gets all the google analytics events depending on a time interval
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setAnalyticsEvents
 */
const getAnalyticsEvents = async (startDate, endDate, setAnalyticsEvents) => {
  try {
    let { data } = await backend.post("/analyticsAll", {
      startDate: startDate
        ? startDate
            .split("/")
            .map((value) => (value.length === 1 ? "0" + value : value))
            .join("-")
        : null,
      endDate: endDate
        ? endDate
            .split("/")
            .map((value, index) => (index === 2 ? +value + 1 : value))
            .map((value) => (value.length === 1 ? "0" + value : value))
            .join("-")
        : null,
    });

    console.log(data, 1235);

    let loginEvents = [];
    let signupEvents = [];
    let shareEvents = [];
    let inviteEvents = [];
    let remindersEvents = [];
    let viewEvents = [];
    let profileEvents = [];
    let surveyEvents = [];
    let others = [];

    for (let i = 0; i < data.length; i++) {
      let event = data[i];

      if (
        event.category.includes("Login") ||
        event.category.includes("Logout")
      ) {
        loginEvents.push(event);
      } else if (
        event.category.includes("Sign_up") ||
        event.category.includes("Signup")
      ) {
        signupEvents.push(event);
      } else if (event.category.includes("Share")) {
        shareEvents.push(event);
      } else if (event.category.includes("Invite")) {
        inviteEvents.push(event);
      } else if (event.category.includes("Reminders")) {
        remindersEvents.push(event);
      } else if (event.category.includes("View")) {
        viewEvents.push(event);
      } else if (event.category.includes("Profile")) {
        profileEvents.push(event);
      } else if (event.category.includes("Survey")) {
        surveyEvents.push(event);
      } else {
        others.push(event);
      }
    }

    setAnalyticsEvents({
      login: loginEvents,
      signup: signupEvents,
      share: shareEvents,
      others: others,
      invite: inviteEvents,
      reminders: remindersEvents,
      view: viewEvents,
      profile: profileEvents,
      survey: surveyEvents,
    });
  } catch (e) {
    setAnalyticsEvents({
      login: [],
      signup: [],
      share: [],
      others: [],
      invite: [],
      reminders: [],
      view: [],
      profile: [],
      survey: [],
    });
  }
};

/**
 * gets all the user visits and sessions calculations from google analytics
 * depending on a time interval
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setAnalyticsUsersAndSessions
 * @param {Function} setAnalyticsUsersByOperatingSystem
 */
const getAnalyticsUsersAndSessions = async (
  startDate,
  endDate,
  setAnalyticsUsersAndSessions,
  setAnalyticsUsersByOperatingSystem
) => {
  try {
    let { data } = await backend.post("/analyticsUsersAndSessions", {
      startDate: startDate
        ? startDate
            .split("/")
            .map((value) => (value.length === 1 ? "0" + value : value))
            .join("-")
        : null,
      endDate: endDate
        ? endDate
            .split("/")
            .map((value, index) => (index === 2 ? parseInt(value) + 1 : value))
            .map((value) => (value.length === 1 ? "0" + value : value))
            .join("-")
        : null,
    });

    setAnalyticsUsersAndSessions(data.geral);
    setAnalyticsUsersByOperatingSystem(data.usersByOperatingSystem);
  } catch (e) {
    console.log(124, "error", e);
    setAnalyticsUsersAndSessions({});
  }
};

/**
 * formats hours for a specific layout
 * @param {String or Number} value
 * @returns {String}
 */
const convertHMS = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
};

/**
 * gets all the data needed to mount the users table from the database
 * so it can be downloaded by the user on the admin interface
 * it depends on the filters and time interval
 * @param {Object} filterState
 * @param {String} startDate
 * @param {String} endDate
 * @param {Function} setFullUsersData
 */
const getFullUsersTable = async (
  filterState,
  startDate,
  endDate,
  setFullUsersData
) => {
  try {
    let newDate = null;

    if (endDate) {
      let newDate = new Date(endDate);
      newDate.setDate(newDate.getDate() + 1);
    }

    let { data } = await backend.post("/getUsersTableForAdmnInterface", {
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
      startDate,
      endDate: newDate,
    });

    setFullUsersData(data);
  } catch (e) {
    setFullUsersData([]);
  }
};

/**
 * mounts the users table from the database so it can be downloaded after
 * @param {Array} data
 * @returns {Element}
 */
const getUsersTableFromDB = (data) => {
  let table = (
    <div style={{ display: "none" }}>
      <table id="FullUsersTable">
        <thead>
          <tr>
            <th className="text-center table-content-fonts" scope="col">
              User ID
            </th>
            <th className="text-center table-content-fonts" scope="col">
              Created At
            </th>
            <th className="text-center table-content-fonts" scope="col">
              Gender
            </th>
            <th className="text-center table-content-fonts" scope="col">
              Country
            </th>
            <th className="text-center table-content-fonts" scope="col">
              Age
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((a) => {
            return (
              <tr>
                <td>{a.wellbeing_user_id}</td>
                <td>{a.created_at}</td>
                <td>{a.gender}</td>
                <td>{a.country}</td>
                <td>{a.age}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ReactHtmlTableToExcel
        id="full-users-table-xls-button"
        className="download-table-xls-button btn btn-success mb-3"
        table="FullUsersTable"
        filename="FullUsersTable"
        sheet="tablexls"
        buttonText="Export Table to Excel Sheet"
      />
    </div>
  );

  return table;
};

/**
 * gets all the data for the bar graph on the registered users section
 * @param {Object} filterState
 * @param {String} timeFrame
 * @param {Function} setRegisteredUsersGraphData
 */
const getRegisteredUsersGraphData = async (
  filterState,
  timeFrame,
  setRegisteredUsersGraphData
) => {
  try {
    let { data } = await backend.post("/countRegisteredUsersInTimeFrames", {
      filters: {
        gender: filterState.gender,
        country: filterState.country,
        minAge: filterState.minAge,
        maxAge: filterState.maxAge,
      },
      timeFrame: timeFrame,
    });

    console.log(data);

    setRegisteredUsersGraphData(data, 1267);
  } catch (e) {
    setRegisteredUsersGraphData([]);
  }
};

export {
  onPassSubmit,
  getUsersCount,
  getGenderUsersCount,
  getCountryUsersCount,
  getAgeUsersCount,
  getTotalFilteredUsers,
  countAllResults,
  getZonesCountAndPercentages,
  getWelcomingZoneCountAndPercentages,
  getAverageReturnRate,
  getAverageReturnRateByUser,
  getGraphDate,
  getAnalyticsEvents,
  getAnalyticsUsersAndSessions,
  convertHMS,
  getUsersTableFromDB,
  getFullUsersTable,
  getRegisteredUsersGraphData,
  PAGES,
  getDatesForTimePeriods,
};
