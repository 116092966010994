import React from "react";
import { connect } from "react-redux";
import {
  redirectStartSurvey,
  redirectToKeycloakEndpoint,
  redirectToSignupEndpoint,
} from "../../../../core/Routing/RoutesRedirects";
import { MAIN_PURPLE } from "../../../../utils/constants/colorsConstants";
import {
  MEET_VIOLET_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
} from "../../../../utils/constants/constants";
import { useResize } from "../../../Geral/hooks/useResize";
import { checkIfThereAreTrialValues } from "../../../Surveys/services/trialSurvey.service";
import ResultsButton from "../newComponents/ResultsButton";
import "./ShareInfoGuestVersion.css";

/**
 * Functional Component: ShareInfoGuestVersion
 * This is the share info version for people that are not logged in, it is shown on shared results page
 *
 * @param {Object} answerValues
 * @param {String} page
 * @param {String} language
 * @param {Object} copies
 * @returns {Element}
 * */
const GuestVersion = ({ answerValues, page, language, copies }) => {
  let [width] = useResize();

  //the styles depending on the page
  const getStyles = {
    [MEET_VIOLET_PAGE_ID]: {
      show: true,
      callToActionStyles: {
        padding: "1rem 0",
      },
      joinNowButtonStyles: {
        padding: "1rem 0",
      },
      mainPageStyles: { padding: "3vw 0" },
    },
    [SHARE_RESULTS_PAGE_ID]: {
      show: false,
      callToActionStyles: {
        position: "absolute",
        top: "100%",
        transform: "translate(0, 50%)",
      },
      joinNowButtonStyles: {},
      mainPageStyles: { padding: "7vw 0" },
    },
  };

  //the layouts depending on screen sizes
  const layouts = {
    medium: (
      <>
        <div
          className="display-6 mb-5"
          style={{ fontFamily: "PoppinsSemiBold", padding: "0 4rem" }}
          id="guest-version-title"
        >
          <div>{copies.TITLE}</div>
        </div>

        {getStyles[page].show ? (
          <div className="mb-4">
            <ResultsButton
              customStyles={{ padding: "1rem 0", width: "15rem" }}
              onClick={() =>
                redirectStartSurvey({
                  checkIfThereAreTrialValues,
                  answerValues,
                  language,
                })
              }
              theme="transparent"
            >
              {copies.START_SURVEY_BUTTON}
            </ResultsButton>
          </div>
        ) : null}

        <div className="mb-4">
          <ResultsButton
            customStyles={{ padding: "1rem 0", width: "15rem" }}
            onClick={() => redirectToSignupEndpoint({})}
          >
            {copies.JOIN_NOW_BUTTON}
          </ResultsButton>
        </div>

        <div style={{ fontSize: "105%" }}>
          {copies.ALREADY_A_MEMBER}{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: MAIN_PURPLE,
            }}
            onClick={() => redirectToKeycloakEndpoint(language)}
          >
            {copies.LOGIN_BUTTON}
          </span>
        </div>
      </>
    ),
    large: (
      <>
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div
            className="col-6 offset-0 display-6"
            style={{ fontFamily: "PoppinsSemiBold", marginLeft: "4rem" }}
          >
            {copies.TITLE}
          </div>

          <div className="col-5 d-flex flex-column align-items-center">
            {getStyles[page].show && (
              <div>
                <ResultsButton
                  customStyles={{ padding: "1rem 0", width: "18rem" }}
                  onClick={() =>
                    redirectStartSurvey({
                      checkIfThereAreTrialValues,
                      answerValues,
                      language,
                    })
                  }
                  theme="transparent"
                >
                  {copies.START_SURVEY_BUTTON}
                </ResultsButton>
              </div>
            )}

            <div style={getStyles[page].joinNowButtonStyles}>
              <ResultsButton
                customStyles={{
                  padding: "1rem 0",
                  width: "18rem",
                  border: "2px solid var(--purple-1)",
                }}
                onClick={() => redirectToSignupEndpoint({})}
              >
                {copies.JOIN_NOW_BUTTON}
              </ResultsButton>
            </div>

            <div
              style={{
                fontSize: "105%",
                ...getStyles[page].callToActionStyles,
              }}
            >
              {copies.ALREADY_A_MEMBER}{" "}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: MAIN_PURPLE,
                }}
                onClick={() => redirectToKeycloakEndpoint(language)}
              >
                {copies.LOGIN_BUTTON}
              </span>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <div
      id="share-info"
      className="d-flex flex-column text-center flex-lg-row text-lg-start align-items-center"
      style={{
        backgroundColor: "#f2eff6",
        ...getStyles[page].mainPageStyles,
      }}
    >
      {width < 992 ? layouts.medium : layouts.large}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { answerValues: state.answerValues, language: state.language };
};

export default connect(mapStateToProps)(GuestVersion);
