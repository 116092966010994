import { useCallback, useEffect } from "react";

const KeyPanel = ({ events }) => {
  const escFunction = useCallback(
    (newEvent) => {
      const event = events.find((event) => {
        return event.key === newEvent.key;
      });
      if (event) event.callback();
    },
    [events]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return null;
};

export default KeyPanel;
