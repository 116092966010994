import backend from "../../../../core/apis/backend";
import login_backend from "../../../../core/apis/login_backend";
import {
  CLEAN_USERS_WITH_ALL_ATTRIBUTES,
  FETCH_USER_WITH_ALL_ATTRIBUTES,
} from "../../../../store/actions/types";

/**
 * joins data of an user from login database with the wellbeing app
 * @param {String} userId
 * @returns {Object}
 */
const fetchUserWithAllAttributes = (userId) => {
  return async (dispatch) => {
    console.log(15, 1267, userId);
    const { data } = await backend.get(`/wellbeing_users/${userId}`);

    const response = await login_backend.get(`/users/${userId}`);

    dispatch({
      type: FETCH_USER_WITH_ALL_ATTRIBUTES,
      payload: {
        user: response.data,
        wellbeing_user: data,
      },
    });
  };
};

/**
 * cleans userswithAllAttributes from the redux state
 * @returns {Object}
 */
const cleanUsersWithAllAttributes = () => {
  return {
    type: CLEAN_USERS_WITH_ALL_ATTRIBUTES,
  };
};

export { fetchUserWithAllAttributes, cleanUsersWithAllAttributes };
