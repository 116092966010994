import FLASH_TYPES from "../../../../components/flash/FLASH_TYPES";
import {
  SET_FLASH_PROPS,
  RESET_FLASH_PROPS,
} from "../../../../store/actions/types";

/**
 * reducer for flash alert action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const flashReducer = (state = { active: false }, action) => {
  switch (action.type) {
    case SET_FLASH_PROPS:
      return {
        ...FLASH_TYPES.SAMPLE,
        ...action.payload,
      };
    case RESET_FLASH_PROPS:
      return { active: false };
    default:
      return state;
  }
};

export { flashReducer };
