const SET_QUESTION_NUMBER = "SET_QUESTION_NUMBER";
const CLEAN_QUESTION_NUMBER = "CLEAN_QUESTION_NUMBER";
const SAVE_ANSWER_VALUE = "SAVE_ANSWER_VALUE";
const DROP_ANSWER_VALUE = "DROP_ANSWER_VALUE";
const CLEAN_ANSWER_VALUE = "CLEAN_ANSWER_VALUE";
const CREATE_RESULT = "CREATE_RESULT";
const SAVE_CHANGE_STYLE = "SAVE_CHANGE_STYLE";
const FETCH_SURVEY = "FETCH_SURVEY";
const CREATE_SURVEY = "CREATE_SURVEY";
const FETCH_RESULTS = "FETCH_RESULTS";
const INTERNAL_SIGN_IN = "INTERNAL_SIGN_IN";
const INTERNAL_SIGN_OUT = "INTERNAL_SIGN_OUT";
const FETCH_TEAMS = "FETCH_TEAMS";
const SAVE_RESULTS_BY_TEAM = "SAVE_RESULTS_BY_TEAM";
const FETCH_USERS_BY_TEAM = "FETCH_USERS_BY_TEAM";
const CLEAN_SURVEY = "CLEAN_SURVEY";
const SAVE_SURVEY_PROPERTIES = "SAVE_SURVEY_PROPERTIES";
const CLEAN_SURVEY_PROPERTIES = "CLEAN_SURVEY_PROPERTIES";
const FETCH_ORDERED_RESULTS = "FETCH_ORDERED_RESULTS";
const CLEAN_ORDERED_RESULTS = "CLEAN_ORDERED_RESULTS";
const FETCH_USER_WITH_ALL_ATTRIBUTES = "FETCH_USER_WITH_ALL_ATTRIBUTES";
const CLEAN_RESULTS_BY_TEAM = "CLEAN_RESULTS_BY_TEAM";

const FETCH_ALL_PROFILES = "FETCH_ALL_PROFILES";
const CLEAN_ALL_PROFILES = "CLEAN_ALL_PROFILES";

const CLEAN_USERS_WITH_ALL_ATTRIBUTES = "CLEAN_USERS_WITH_ALL_ATTRIBUTES";

const FETCH_TEMPORARY_SCORE = "FETCH_TEMPORARY_SCORE";
const CLEAN_TEMPORARY_SCORE = "CLEAN_TEMPORARY_SCORE";

const RANDOM_SURVEY = "RANDOM_SURVEY";

const SPECIFIC_SURVEY = "SPECIFIC_SURVEY";

const SAVE_ROLES = "SAVE_ROLES";

const SAVE_CORE_ANSWER = "SAVE_CORE_ANSWER";
const CLEAN_CORE_ANSWERS = "CLEAN_CORE_ANSWERS";
const SET_CORE_ANSWERS = "SET_CORE_ANSWERS";

const PRELIMINARY_RESULT_DATA = "PRELIMINARY_RESULT_DATA";
const ERASE_PRELIMINARY_RESULT_DATA = "ERASE_PRELIMINARY_RESULT_DATA";
const CORE_RESULT_DATA = "CORE_RESULT_DATA";
const SAVE_QUERY_PARAMS = "SAVE_QUERY_PARAMS";

const FETCH_DIMENSIONS = "FETCH_DIMENSIONS";
const CORE_OR_TRIAL = "CORE_OR_TRIAL";

const SAVE_REORDERED_QUESTIONS = "SAVE_REORDERED_QUESTIONS";

const SET_FLASH_PROPS = "SET_FLASH_PROPS";
const RESET_FLASH_PROPS = "RESET_FLASH_PROPS";
const LANGUAGE_SAVE = "LANGUAGE_SAVE";
const SAVE_SURVEY = "SAVE_SURVEY";
const SAVE_TRIAL = "SAVE_TRIAL";

export {
  SET_QUESTION_NUMBER,
  CLEAN_QUESTION_NUMBER,
  SAVE_ANSWER_VALUE,
  DROP_ANSWER_VALUE,
  CLEAN_ANSWER_VALUE,
  CREATE_RESULT,
  SAVE_CHANGE_STYLE,
  FETCH_SURVEY,
  CREATE_SURVEY,
  FETCH_RESULTS,
  INTERNAL_SIGN_IN,
  INTERNAL_SIGN_OUT,
  FETCH_TEAMS,
  SAVE_RESULTS_BY_TEAM,
  FETCH_USERS_BY_TEAM,
  CLEAN_SURVEY,
  SAVE_SURVEY_PROPERTIES,
  CLEAN_SURVEY_PROPERTIES,
  FETCH_ORDERED_RESULTS,
  CLEAN_ORDERED_RESULTS,
  FETCH_USER_WITH_ALL_ATTRIBUTES,
  CLEAN_RESULTS_BY_TEAM,
  FETCH_ALL_PROFILES,
  CLEAN_ALL_PROFILES,
  CLEAN_USERS_WITH_ALL_ATTRIBUTES,
  FETCH_TEMPORARY_SCORE,
  CLEAN_TEMPORARY_SCORE,
  RANDOM_SURVEY,
  SPECIFIC_SURVEY,
  SAVE_ROLES,
  SAVE_CORE_ANSWER,
  CLEAN_CORE_ANSWERS,
  SET_CORE_ANSWERS,
  PRELIMINARY_RESULT_DATA,
  ERASE_PRELIMINARY_RESULT_DATA,
  CORE_RESULT_DATA,
  SAVE_QUERY_PARAMS,
  FETCH_DIMENSIONS,
  CORE_OR_TRIAL,
  SAVE_REORDERED_QUESTIONS,
  SET_FLASH_PROPS,
  RESET_FLASH_PROPS,
  LANGUAGE_SAVE,
  SAVE_SURVEY,
  SAVE_TRIAL,
};
