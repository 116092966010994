import React from "react";
import "./CoreResultsTemplate.css";
//import { withResize } from "../../../../utils/addonsHandler";
import ZoneAndScore from "../../../../components/infoParts/ZoneAndScore";
import FocusAndEvolution from "./FocusAndEvolution";
import RadarGraphInfo from "../../../../components/infoParts/RadarGraphInfo";
import PurposeInfo from "./PurposeInfo";
import TipsInfo from "../../../../components/infoParts/TipsInfo";
import CompareWithOthers from "./CompareWithOthers";
import ShareInfo from "./ShareInfo";
import WelcomeBackZone from "./WelcomeBackZone";
import {
  MY_ANALYTICS_PAGE_ID,
  PRELIMINARY_RESULTS_PAGE_ID,
  RESULTS_PAGE_ID,
  SHARE_RESULTS_PAGE_ID,
  WELCOME_BACK_PAGE_ID,
} from "../../../../utils/constants/constants";
import ScrollToTopArrow from "../../../../components/infoParts/ScrollToTopArrow";

/**
 * Functional Component: CoreResultsTemplate
 * The template for every results page on violet
 *
 * @param {String} page
 * @param {Object} currentUser
 * @param {Function} fetchCurrentUser
 * @param {Object} preliminaryResultCopy
 * @param {String} name
 * @param {String} zone
 * @param {Object} results
 * @param {Int} score
 * @param {Object} progress
 * @param {String} dimensionOfFocus
 * @param {String} focusCopy
 * @param {Img} focusImage
 * @param {String} dimensionOfExcellence
 * @param {Object} tips
 * @param {String} share_url
 * @param {Object} preliminaryResult
 * @param {String} lastResultDate
 * @param {Object} preliminaryResultData
 * @param {Function} setPage
 * @param {String} language
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {String} shareResultGender
 * @returns {Element}
 * */
const CoreResultsTemplate = ({
  zone,
  colors,
  page,
  currentUser,
  fetchCurrentUser,
  preliminaryResultCopy,
  results,
  score,
  //
  focusCopy,
  focusImage,
  //
  tips,
  //
  share_url,
  lastResultDate,
  preliminaryResultData,
  setPage,
  setFlashProps,
  language,
  copies,
  variableCopies,
  shareResultGender,
  share_id,
  preliminaryTimer,
}) => {
  /**
   * @param {Int} index
   * @returns {Component}
   */
  const welcomeBackZone = (index) => (
    <WelcomeBackZone key={index} copies={copies.WELCOME_BACK_ZONE} />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const zoneAndScore = (index) => (
    <ZoneAndScore
      key={index}
      zone={zone}
      colors={colors}
      score={score}
      //
      focusCopy={focusCopy}
      //
      lastResultDate={lastResultDate}
      preliminaryResultCopy={preliminaryResultCopy}
      preliminaryResultData={preliminaryResultData}
      page={page}
      copies={copies.ZONE_AND_SCORE}
      variableCopies={variableCopies.ZONE_AND_SCORE}
      dimensions={copies.DIMENSIONS.ZONE_LAST_RESULT}
      benefits={copies.PRELIMINARY_BENEFITS}
      preliminaryTimer={preliminaryTimer}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const focusAndEvolution = (index) => (
    <FocusAndEvolution
      key={index}
      //
      focusCopy={focusCopy}
      focusImage={focusImage}
      //
      page={page}
      copies={copies.FOCUS_AND_PROGRESS}
      variableCopies={variableCopies.FOCUS_AND_PROGRESS}
      dimensions={copies.DIMENSIONS.FOCUS}
      shareResultGender={shareResultGender}
      language={language}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const radarGraphInfo = (index) => (
    <RadarGraphInfo
      key={index}
      results={results}
      preliminaryResultData={preliminaryResultData}
      page={page}
      copies={copies.RADAR_GRAPH_ZONE}
      variableCopies={variableCopies.RADAR_GRAPH_ZONE}
      dimensions={copies.DIMENSIONS.RADAR_GRAPH_ZONE}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const purposeInfo = (index) => (
    <PurposeInfo
      key={index}
      copies={copies.PURPOSE}
      variableCopies={variableCopies.PURPOSE}
      shareResultGender={shareResultGender}
      page={page}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const tipsInfo = (index) => (
    <TipsInfo
      key={index}
      tips={tips}
      copies={copies.TIPS_ZONE}
      dimensions={copies.DIMENSIONS.TIPS_ZONE}
      shareResultGender={shareResultGender}
      page={page}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const compareWithOthers = (index) => (
    <CompareWithOthers
      key={index}
      fetchUserWithAllAttributes={fetchCurrentUser}
      currentUser={currentUser}
      page={page}
      setPage={setPage}
      copies={copies.COMPARE_WITH_OTHERS}
      variableCopies={variableCopies.COMPARE_WITH_OTHERS}
      shareResultGender={shareResultGender}
      language={language}
      share_id={share_id}
    />
  );

  /**
   * @param {Int} index
   * @returns {Component}
   */
  const shareInfo = (index) => (
    <ShareInfo
      key={index}
      share_url={share_url}
      currentUser={currentUser}
      page={page}
      setFlashProps={setFlashProps}
      language={language}
      copies={copies.SHARE_INFO}
    />
  );

  //the pages to be shown depending on the page we are on
  const pages = {
    [SHARE_RESULTS_PAGE_ID]: [
      zoneAndScore,
      focusAndEvolution,
      radarGraphInfo,
      purposeInfo,
      tipsInfo,
      compareWithOthers,
      shareInfo,
    ],
    [WELCOME_BACK_PAGE_ID]: [
      welcomeBackZone,
      zoneAndScore,
      focusAndEvolution,
      radarGraphInfo,
      purposeInfo,
      tipsInfo,
      compareWithOthers,
      shareInfo,
    ],
    [RESULTS_PAGE_ID]: [
      zoneAndScore,
      focusAndEvolution,
      radarGraphInfo,
      purposeInfo,
      tipsInfo,
      compareWithOthers,
      shareInfo,
    ],
    [MY_ANALYTICS_PAGE_ID]: [
      zoneAndScore,
      radarGraphInfo,
      tipsInfo,
      compareWithOthers,
    ],
    [PRELIMINARY_RESULTS_PAGE_ID]: [zoneAndScore, radarGraphInfo],
  };

  return (
    <div id="new-results-page">
      {pages[page].map((zone, index) => zone(index))}
      <ScrollToTopArrow page={page} />
    </div>
  );
};

// withResize ??
export default CoreResultsTemplate;
