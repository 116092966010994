import React from "react";
import "./WelcomeBackZone.css";
import GreyBackgroundRectangle from "../../../../components/rectangles/GreyBackgroundRectangle";

/**
 * Functional Component: WelcomeBackZone
 * This zone is only part of the results on the welcome back page
 * @param {Object} copies
 * @returns {Element}
 * */
const WelcomeBackZone = ({ copies }) => {
  return (
    <div id="welcome-back-zone" className="text-center">
      <GreyBackgroundRectangle
        purpleBackground={true}
        customStyles={{
          backgroundImage:
            "linear-gradient(to bottom, #604586 0%, #cdbae7 100%)",
        }}
      >
        <h2
          id="purple-background-title"
          className="display-3 mx-auto ff-popp-semi-bold"
        >
          {copies.TITLE}
        </h2>

        <div id="purple-background-subtitle" className=" mx-auto mt-3">
          {copies.BODY}
        </div>
      </GreyBackgroundRectangle>
    </div>
  );
};

export default WelcomeBackZone;
