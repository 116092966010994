export function useAdminInterfaceStyles(width) {
  /**
   * returns the different dropdown styles for the filters
   * @param {String} value
   * @param {String} dropdownName
   * @returns {Object}
   * */
  const getCustomDropdownStyles = (value, dropdownName) => {
    if (width >= 992) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0px solid #c2c0c7",
          height: "3.5vw",
          minHeight: "3.5vw",
          width: "16vw", // BEFORE: 15vw
          fontSize: "0.8vw",
          display: "flex",
          alignItems: "center",
          borderRadius: "37.5px",
          backgroundColor: "#f2eff6",
        }),
        container: (provided) => ({
          ...provided,
          height: "3.5vw",
          minHeight: "3.5vw",
          fontSize: "0.8vw",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "3.5vw",
          minHeight: "3.5vw",
          display: "flex",
          alignItems: "center",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "3.5vw",
          minHeight: "3.5vw",
          display: "flex",
          alignItems: "center",
          fontSize: "0.8vw",
          paddingLeft: "2vw",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "3.5vw",
          minHeight: "3.5vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "0.5vw",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          display: "flex",
          alignItems: "center",
          fontSize: "0.8vw",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "1vw",
        }),
      };
    } else if (width >= 576) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0px solid #c2c0c7",
          height: "5vw",
          minHeight: "3vw",
          width: "20vw",
          fontSize: "1.3vw !important",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f2eff6",
          borderRadius: "37.5px",
        }),
        container: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          fontSize: "1.3vw !important",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          width: "5vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "1",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          fontSize: "1.3vw !important",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "4vw",
          minHeight: "4w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          height: "5vw",
          width: "2vw",
          minHeight: "5vw",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "4vw",
          minHeight: "4vw",
          fontSize: "1.3vw !important",
          display: "flex",
          alignItems: "center",
        }),
        multiValue: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
          marginTop: value && dropdownName === "country" ? "0.8vw" : null,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
        }),
        menuList: (base) => ({
          ...base,
          "::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
        }),
      };
    } else if (width >= 0) {
      return {
        control: (provided, state) => ({
          ...provided,
          border: "0px solid #c2c0c7",
          height: "6vw",
          minHeight: "6vw",
          width: "30vw",
          fontSize: "2vw",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f2eff6",
          borderRadius: "37.5px",
        }),
        container: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          display: "flex",
          alignItems: "center",
          paddingRight: "1",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          fontSize: "2vw",
          paddingLeft: "2vw",
          whiteSpace: "nowrap",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5w",
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "6vw",
          minHeight: "6vw",
          display: "flex",
          alignItems: "center",
        }),
        singleValue: (provided) => ({
          ...provided,
          height: "5vw",
          minHeight: "5vw",
          display: "flex",
          alignItems: "center",
        }),
        multiValue: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
          width: "9vw",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          height: "2.5vw",
          minHeight: "2.5vw",
          padding: "0 !important",
          display: "flex",
          alignItems: "center",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "2vw",
          "::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
          },
        }),
      };
    }
  };

  /**
   * returns the menu height depending on screen size
   * @returns {String}
   */
  const getCustomMenuHeight = () => {
    if (width > 2000) {
      return "15rem";
    } else if (width >= 1500) {
      return "9rem";
    } else if (width >= 576) {
      return "7rem";
    } else if (width >= 0) {
      return "5rem";
    }
  };

  /**
   * returns the styles for the birthdate input
   * @returns {Object}
   */
  const getNativePickerStyles = () => {
    if (width >= 992) {
      return {
        height: "3.5vw",
        minHeight: "3.5vw",
        width: "15vw",
        borderRadius: "37.5px",
        backgroundColor: "#f2eff6",
        fontSize: "0.8vw",
        border: "0 !important",
        display: "flex",
        alignItems: "center",
      };
    } else if (width >= 576) {
      return {
        height: "5vw",
        minHeight: "5vw",
        width: "20vw",
        borderRadius: "37.5px",
        backgroundColor: "#f2eff6",
        fontSize: "1.3vw",
        border: "0 !important",
        display: "flex",
        alignItems: "center",
      };
    } else if (width >= 0) {
      return {
        height: "6vw",
        minHeight: "6vw",
        width: "30vw",
        borderRadius: "37.5px",
        backgroundColor: "#f2eff6",
        fontSize: "2vw",
        border: "0 !important",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  /**
   * returns the style for the x icon
   * @returns {Object}
   */
  const getCustomXStyles = () => {
    if (width >= 576) {
      return { width: 13 };
    } else if (width >= 0) {
      return { width: 13, left: "98%" };
    }
  };

  return {
    getCustomDropdownStyles,
    getCustomMenuHeight,
    getNativePickerStyles,
    getCustomXStyles,
  };
}
