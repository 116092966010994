import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  canTrackIfUserIsRegistered,
  doesRegisteredUserCookieExist,
  setRegisteredUserCookie,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";

/**
 * Functional Component: ProtectedRoute
 * A protected route can only be accessed if a user has specific roles inside the app
 *
 * @param {Component} component
 * @param {Array} roles
 * @returns {Component}
 * */
const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  //checks if a user is allowed to access this route
  const isAllowed = () =>
    roles.includes("user") || roles.includes("wellbeing_user");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAllowed()) {
          if (!doesRegisteredUserCookieExist && canTrackIfUserIsRegistered())
            setRegisteredUserCookie();
          return <Component {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.roles,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
