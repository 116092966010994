import React from "react";
import "./PurposeInfo.css";
import PurposeGraph from "../../../../components/imageComponents/PurposeGraph";
import { SHARE_RESULTS_PAGE_ID } from "../../../../utils/constants/constants";

/**
 * Functional Component: PurposeInfo
 * This renders the purpose section of the results
 *
 * @param {Object} copies
 * @param {Object} variableCopies
 * @param {String} shareResultGender
 * @param {String} page
 * @returns {Element}
 * */
const PurposeInfo = ({ copies, variableCopies, shareResultGender, page }) => {
  //these ifs handle the copies manipulation depending on page and gender
  if (page === SHARE_RESULTS_PAGE_ID && shareResultGender) {
    if (shareResultGender === "M") {
      copies.TITLE = copies.TITLE.replace("do/a", "do");
    } else if (shareResultGender === "F") {
      copies.TITLE = copies.TITLE.replace("do/a", "da");
    }
  }

  return (
    <div
      className="d-flex align-items-center flex-column flex-lg-row"
      id="purpose-info"
    >
      <div className="col-12 col-sm-8 col-lg-6 d-flex justify-content-center align-items-center mb-5">
        <div className="w-75 mx-auto text-center text-lg-start">
          <h2 className="fw-bold purpose-info__title ff-popp-med">
            {copies.TITLE}
          </h2>
          <div className="purpose-info__text">
            {variableCopies.intro}
            {variableCopies.body}
          </div>
        </div>
      </div>

      <div
        id="purpose-info__purpose-graph"
        className="col-12 col-sm-8 col-md-7 col-lg-4 d-flex justify-content-center justify-content-lg-start flex-row"
      >
        <PurposeGraph purposeLevel={variableCopies.purpose_type} />
      </div>
    </div>
  );
};

export default PurposeInfo;
