import React from "react";
import { MAIN_TEXT } from "../../../../utils/constants/colorsConstants";

/**
 * Functional Component: ReasonToJoinViolet
 * @param {Img} image
 * @param {String} body
 * @returns {Element}
 */
const ReasonToJoinViolet = ({ image, body }) => {
  return (
    <div
      style={{ color: MAIN_TEXT }}
      className="col-12 col-sm-6 col-lg-3 text-center d-flex flex-column align-items-center my-4"
    >
      <div className="col-6 col-lg-6">
        <img src={image} alt={image.toString()} />
      </div>

      <div
        className="col-10 col-lg-9 my-4 ff-popp-med"
        style={{ fontSize: "105%" }}
      >
        {body}
      </div>
    </div>
  );
};

export default ReasonToJoinViolet;
