import { Router, Route, Switch } from "react-router-dom";
import history from "../../utils/history";
import LoginComponent from "../../modules/Auth/pages/LoginComponent";
import ShowResultContainer from "../../modules/Results/pages/ShowResultContainer";
import QuestionContainerDecider from "../../modules/Surveys/components/core/QuestionContainerDecider";
import SharedResult from "../../modules/Results/components/SharedResult";
import MeetViolet from "../../modules/Geral/pages/MeetViolet";
import AboutMe from "../../modules/Users/pages/AboutMe";
import WelcomeSurvey from "../../modules/Surveys/components/welcome/WelcomeSurvey";
import TrialSurvey from "../../modules/Surveys/components/trial/TrialSurvey";
import LandingPage from "../../modules/Geral/pages/LandingPage";
import "./AppContainer.css";
import ProtectedRoute from "../Routing/ProtectedRoute";
import ErrorPage from "../../modules/Geral/pages/ErrorPage";
import {
  ABOUT_ME_PAGE_ROUTE,
  ANALYTICS_PAGE_ROUTE,
  CONFIRM_PASSWORD_ROUTE,
  ERROR_PAGE_ROUTE,
  LANDING_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  MEET_VIOLET_PAGE_ROUTE,
  PRELIMINARY_RESULTS_PAGE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PROFILE_PAGE_ROUTE,
  redirectToLandingPage,
  RESULT_PAGE_ROUTE,
  SHARED_RESULT_PAGE_ROUTE,
  SURVEY_PAGE_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  TRIAL_SURVEY_PAGE_ROUTE,
  WELCOME_SURVEY_PAGE_ROUTE,
} from "./RoutesRedirects";
import MyAnalytics from "../../modules/Scores/pages/MyAnalytics";
import TermsOfService from "../../modules/Geral/pages/TermsOfService";
import PrivacyPolicy from "../../modules/Geral/pages/PrivacyPolicy";
import "bootstrap/dist/css/bootstrap.min.css";
import WelcomeBack from "../../modules/Users/pages/WelcomeBack";
import PreliminaryResults from "../../modules/Results/pages/PreliminaryResults";
import ConfirmPassword from "../../modules/Users/pages/ConfirmPassword";
import RestrictedAccess from "../../modules/Geral/pages/RestrictedAccess";
import CookiesInfo from "../../components/cookies/CookiesInfo";
import Flash from "../../components/flash/Flash";
import ScrollToTop from "./ScrollToTop";
import { useEffect } from "react";
import AdminInterface from "../../modules/Geral/pages/AdminInterface";
import { useLanguage } from "../../modules/Geral/hooks/useLanguage";
import ReactGA from "react-ga";
import {
  canTrackAnalytics,
  removeAnalyticsCookies,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";

//initializes google analytics
if (canTrackAnalytics()) ReactGA.initialize("UA-35311532-3");

const MAINTENANCE = false;

/**
 * Functional Component: App
 * Contains all the app routes
 * @returns {Component}
 */
const App = () => {
  //tracks all routes changes with google analytics
  useEffect(() => {
    if (canTrackAnalytics())
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!canTrackAnalytics()) removeAnalyticsCookies();
  }, []);

  useLanguage();

  return (
    <Router history={history}>
      <ScrollToTop />
      <CookiesInfo />
      <Flash />
      {MAINTENANCE ? <Maintenance /> : <Violet />}
    </Router>
  );
};

const Violet = () => {
  return (
    <Switch>
      <Route path={LANDING_PAGE_ROUTE} exact component={LandingPage} />
      <Route path={LOGIN_PAGE_ROUTE} component={LoginComponent} />
      <ProtectedRoute path={PROFILE_PAGE_ROUTE} component={WelcomeBack} />
      <ProtectedRoute
        path={SURVEY_PAGE_ROUTE}
        component={QuestionContainerDecider}
      />
      <ProtectedRoute
        path={RESULT_PAGE_ROUTE}
        component={ShowResultContainer}
      />
      <Route path={SHARED_RESULT_PAGE_ROUTE} component={SharedResult} />
      <Route path={MEET_VIOLET_PAGE_ROUTE} component={MeetViolet} />
      <ProtectedRoute path={ABOUT_ME_PAGE_ROUTE} component={AboutMe} />
      <Route path={TRIAL_SURVEY_PAGE_ROUTE} component={TrialSurvey} />
      <Route
        path={PRELIMINARY_RESULTS_PAGE_ROUTE}
        component={PreliminaryResults}
      />
      <Route path={WELCOME_SURVEY_PAGE_ROUTE} component={WelcomeSurvey} />
      <Route
        path={ERROR_PAGE_ROUTE}
        render={() => (
          <ErrorPage type="internal" buttonFunction={redirectToLandingPage} />
        )}
      />
      <ProtectedRoute path={ANALYTICS_PAGE_ROUTE} component={MyAnalytics} />
      <Route path={TERMS_OF_SERVICE_ROUTE} component={TermsOfService} />
      <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
      <Route path={CONFIRM_PASSWORD_ROUTE} component={ConfirmPassword} />
      {false && <Route path="/pilot/:code" component={RestrictedAccess} />}
      <ProtectedRoute path="/admInterface" component={AdminInterface} />
      <Route
        render={() => (
          <ErrorPage type="not-found" buttonFunction={history.goBack} />
        )}
      />
    </Switch>
  );
};

const Maintenance = () => {
  return (
    <Switch>
      <Route render={() => <ErrorPage type="maintenance" />} />
    </Switch>
  );
};

export default App;
