import React, { useEffect, useState } from "react";
import "./PreliminaryResults.css";
import { connect } from "react-redux";
import HeaderFirstVersion from "../../../components/header/HeaderFirstVersion";
import {
  cleanAnswerValue,
  setQuestionNumber,
  saveTrialReorderedQuestions,
  setFlashProps,
  fetchTrial,
} from "../../../store/actions";
import { PRELIMINARY_RESULTS_PAGE_ID } from "../../../utils/constants/constants";
import CoreResultsTemplate from "../components/infoParts/CoreResultsTemplate";
import { getPreliminaryResultHeader } from "../services/preliminaryResults.service";
import Footer from "../../../components/footer/Footer";
import {
  getResultsPageCopies,
  getStaticResultsPagesCopies,
} from "../../../utils/constants/copies/copies.helper";
import Loading from "../../../components/loading/Loading";
import { getZoneCopyFromLanguage } from "../../Surveys/services/questionContainer.service";
import { ZONE_COLORS } from "../../../utils/constants/colorsConstants";
import { formatZone } from "../../Users/services/welcomeBackPage.service";
import PreliminaryResultsModal from "../components/PreliminaryResultsModal";
import { fetchUsersNumber } from "../../../utils/helpers/geralHelpers/BackendHelper";
import { redirectToWelcomeSurveyPage } from "../../../core/Routing/RoutesRedirects";

/**
 * Functional Component: PreliminaryResults
 * The preliminary results page, it is used after trial survey completion
 * @param {Object} preliminaryResult
 * @param {Object} queryParams
 * @param {Function} saveTrialReorderedQuestions
 * @param {Function} cleanAnswerValue
 * @param {Function} setFlashProps
 * @param {String} language
 * @param {Object} survey
 * @param {Function} fetchTrial
 * @returns {Element}
 */
const PreliminaryResults = ({
  preliminaryResult,
  queryParams,
  saveTrialReorderedQuestions,
  cleanAnswerValue,
  setFlashProps,
  language,
  survey,
  fetchTrial,
}) => {
  const [usersNumber, setUsersNumber] = useState(0);
  const [copies, setCopies] = useState(null);
  const [canShowModal, setCanShowModal] = useState(true);
  const [showModal, changeModalVisbility] = useState(false);
  const [thisNow, setNow] = useState(0);

  useEffect(() => {
    cleanAnswerValue();
    saveTrialReorderedQuestions(null);
    fetchTrial();
  }, [cleanAnswerValue, fetchTrial, saveTrialReorderedQuestions]);

  useEffect(() => {
    let canMakeRequest = true;
    const getUsersCount = async () => {
      const usersCount = await fetchUsersNumber();
      if (canMakeRequest)
        setUsersNumber(Math.floor(usersCount.total / 10) * 10);
    };
    getUsersCount();

    return () => (canMakeRequest = false);
  }, []);

  useEffect(() => {
    let keepCycling = true;

    const wordsCycle = setInterval(() => {
      if (keepCycling) {
        setNow((t) => t + 1);
      } else clearInterval(wordsCycle);
    }, 3750);

    return () => (keepCycling = false);
  }, []);

  useEffect(() => {
    let keepCycling = true;

    if (thisNow === 3 && canShowModal) {
      setTimeout(() => {
        if (keepCycling) changeModalVisbility(true);
      }, 3750);
      setCanShowModal(false);
    }

    return () => (keepCycling = false);
  }, [thisNow, canShowModal]);

  useEffect(() => {
    if (!Object.keys(preliminaryResult).length) redirectToWelcomeSurveyPage();
    const makeAllCopies = async () => {
      const variableCopies = await getResultsPageCopies({
        language,
        page: PRELIMINARY_RESULTS_PAGE_ID,
        lastResultScore: preliminaryResult.total,
      });

      const staticCopies = getStaticResultsPagesCopies({
        language,
        page: PRELIMINARY_RESULTS_PAGE_ID,
        usersCount: usersNumber,
      });

      const copyZone = getZoneCopyFromLanguage({
        zone: preliminaryResult.zone,
        language,
      });

      const colorsZone = getZoneCopyFromLanguage({
        zone: preliminaryResult.zone,
        language: "en-GB",
      });

      const header = getPreliminaryResultHeader({
        language,
        survey,
        preliminaryResult,
      });

      setCopies({
        variableCopies,
        staticCopies,
        copyZone,
        colorsZone,
        preliminaryResultCopy: header,
      });
    };

    if (language && preliminaryResult && usersNumber) makeAllCopies();
  }, [language, preliminaryResult, survey, usersNumber]);

  //contains all static copies for the preliminary results page

  if (!Object.keys(preliminaryResult).length) redirectToWelcomeSurveyPage();
  if (!copies) return <Loading />;

  return (
    <div id="new-preliminary-results">
      <HeaderFirstVersion />
      <PreliminaryResultsModal
        open={showModal}
        setOpenStatus={changeModalVisbility}
        copies={copies.staticCopies.ZONE_AND_SCORE.PRELIMINARY_MODAL}
        preliminaryResultData={{ preliminaryResult, queryParams }}
      />
      <CoreResultsTemplate
        zone={formatZone(copies.copyZone)}
        colors={ZONE_COLORS[copies.colorsZone]}
        score={preliminaryResult.total}
        preliminaryResultCopy={copies.preliminaryResultCopy}
        results={{ physical: 75, mental: 50, social: 54, work: 54 }}
        preliminaryResultData={{ preliminaryResult, queryParams }}
        page={PRELIMINARY_RESULTS_PAGE_ID}
        setFlashProps={setFlashProps}
        language={language}
        copies={copies.staticCopies}
        variableCopies={copies.variableCopies}
        preliminaryTimer={thisNow}
      />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    survey: state.mainSurvey,
    preliminaryResult: state.preliminaryResult,
    queryParams: state.queryParams,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  cleanAnswerValue,
  setQuestionNumber,
  saveTrialReorderedQuestions,
  setFlashProps,
  fetchTrial,
})(PreliminaryResults);
