import { useLayoutEffect, useState } from "react";
import debounce from "../../../utils/helpers/geralHelpers/debounce";

//updates the page if the screen width or heigh is changed
export function useResize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    const debouncedUpdateSize = debounce(function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }, 10);

    window.addEventListener("resize", debouncedUpdateSize);

    return () => window.removeEventListener("resize", debouncedUpdateSize);
  }, []);

  return size;
}
