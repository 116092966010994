import React from "react";
import { connect } from "react-redux";
import {
  cleanAnswerValue,
  cleanQuestionNumber,
  fetchSurvey,
  saveAnswerValue,
  setQuestionNumber,
  fetchRandomizedCoreSurvey,
  fetchSpecificCoreSurvey,
  fetchTemporaryScore,
  cleanTemporaryScore,
  cleanCoreAnswers,
  setCoreAnswers,
} from "../../../../store/actions";
import {
  canUserAnswerTodayHandler,
  coreAnswersHandler,
} from "../../services/questionContainerDecider.service";
import { CORE_SURVEY_ID } from "../../../../utils/constants/constants";
import { fetchDimensions } from "../../../../utils/helpers/geralHelpers/BackendHelper";
import CoreSurvey from "./CoreSurvey";
import Loading from "../../../../components/loading/Loading";
import LOADING_TYPES from "../../../../components/loading/LOADING_TYPES";

//decides the component to render the questions depending on the survey type
class QuestionContainerDecider extends React.Component {
  state = { noResults: false };

  //cleans the answers, question number from redux, fetches the required survey, verifies if the user already answered today and
  //fetches saved partial scores if they exist
  componentDidMount = async () => {
    /**
     * @param {Function} cleanAnswerValue
     * @param {Function} cleanQuestionNumber
     * @param {Function} cleanTemporaryScore
     * @param {Function} cleanCoreAnswers
     * @param {Function} fetchTemporaryScore
     * @param {Object} currentUser
     * @param {Function} fetchSpecificCoreSurvey
     * @param {Function} fetchRandomizedCoreSurvey
     * @param {Function} setCoreAnswers
     * @param {Function} setQuestionNumber
     * @param {Function} saveAnswerValue
     */
    const {
      cleanAnswerValue,
      cleanQuestionNumber,
      cleanTemporaryScore,
      cleanCoreAnswers,
      fetchTemporaryScore,
      currentUser,
      fetchSpecificCoreSurvey,
      fetchRandomizedCoreSurvey,
      setCoreAnswers,
      setQuestionNumber,
      saveAnswerValue,
    } = this.props;

    cleanAnswerValue();
    cleanQuestionNumber();
    cleanTemporaryScore();
    cleanCoreAnswers();

    await fetchTemporaryScore(currentUser.attributes.user_id, CORE_SURVEY_ID);

    const { temporaryScore } = this.props;

    if (temporaryScore) {
      await fetchSpecificCoreSurvey(currentUser.attributes.user_id);
    } else {
      await fetchRandomizedCoreSurvey(
        CORE_SURVEY_ID,
        currentUser.attributes.user_id
      );
    }

    const { survey } = this.props;

    const dimensions = await fetchDimensions();

    coreAnswersHandler(
      temporaryScore,
      survey.questions,
      setCoreAnswers,
      setQuestionNumber,
      dimensions,
      saveAnswerValue
    );

    const noResults = await canUserAnswerTodayHandler(
      CORE_SURVEY_ID,
      currentUser.attributes.user_id
    );
    this.setState({ noResults });
  };

  /**
   * checks if survey exists on redux state
   * @returns {Object}
   */
  doesSurveyExist = () => {
    const { survey } = this.props;
    return survey.survey;
  };

  render = () => {
    if (!this.doesSurveyExist() || !this.state.noResults) {
      return <Loading type={LOADING_TYPES.BUILDING_SURVEY} />;
    }

    return <CoreSurvey />;
  };
}

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    currentUser: state.currentUser,
    temporaryScore: state.currentUserTemporaryScore,
    answerValues: state.answerValues,
  };
};

export default connect(mapStateToProps, {
  fetchTemporaryScore,
  fetchSurvey,
  cleanAnswerValue,
  cleanQuestionNumber,
  saveAnswerValue,
  setQuestionNumber,
  fetchRandomizedCoreSurvey,
  fetchSpecificCoreSurvey,
  cleanTemporaryScore,
  setCoreAnswers,
  cleanCoreAnswers,
})(QuestionContainerDecider);
