import { useEffect } from "react";

/**
 * Hook: useAnimation
 * A hook to trigger an animation
 * @param {Int} step: how long does a step take in milliseconds
 * @param {Int} duration: the duration of the animation in milliseconds
 * @param {BezierEasing} bezierAnimation: the bezier-curve that defines how the points move
 * @returns {Function}
 */
const useAnimation = ({ step, duration, bezierAnimation }) => {
  const maxFrames = duration / step;

  /**
   * Hook: useRunAnimation
   * A hook that runes the animation described in useAnimation
   * @param {Function} setAnimationFrame: the function that will be triggered in each step
   * @returns {Function}
   */
  const useRunAnimation = (setAnimationFrame) => {
    useEffect(() => {
      let isAnimationSubscribed = true;
      let currentStep = 0;
      let id = setInterval(frame, step);

      function frame() {
        if (!isAnimationSubscribed) return;

        if (currentStep === maxFrames) {
          clearInterval(id);
        } else {
          currentStep++;
          const newFrame = bezierAnimation(currentStep / maxFrames);
          setAnimationFrame(newFrame);
        }
      }

      return () => {
        isAnimationSubscribed = false;
      };
      //eslint-disable-next-line
    }, []);
  };

  return useRunAnimation;
};

export default useAnimation;
