import React, { useEffect, useState } from "react";
import "./PageNavbar.css";

const SELECTED_STYLES = { backgroundColor: "var(--purple-1)", color: "white" };

/**
 * this component is a toggle to change between my last results and progress overtime
 * @param {Object} purpleProps
 * @param {Object} whiteProps
 * @param {Function} onChange
 * @param {String} defaultValue
 * @param {Function} fireGaEvent
 * @returns {Element}
 */
const PageNavbar = ({
  purpleProps,
  whiteProps,
  onChange,
  defaultValue,
  fireGaEvent,
}) => {
  const [page, setPage] = useState(defaultValue ?? purpleProps.pageId);

  /**
   * handles when a different page is selected
   * @param {String} newPage
   */
  const handlePageChange = (newPage) => {
    setPage(newPage);
    onChange(newPage);
  };

  //sets the initial page default value
  useEffect(() => {
    setPage(defaultValue);
    onChange(defaultValue);

    //eslint-disable-next-line
  }, [defaultValue]);

  return (
    <div className="custom-box d-flex justify-content-center align-items-center">
      <div className="d-flex justify-content-center align-items-center">
        <div className="modal-guide">
          <div
            className="purple-section"
            onClick={() => handlePageChange(purpleProps.pageId)}
            style={page === purpleProps.pageId ? SELECTED_STYLES : {}}
          >
            <div className="navbar-text">{purpleProps.text}</div>
          </div>

          <div
            className="white-section"
            onClick={() => {
              handlePageChange(whiteProps.pageId);
              if (fireGaEvent) fireGaEvent();
            }}
            style={page === whiteProps.pageId ? SELECTED_STYLES : {}}
          >
            <div className="navbar-text">{whiteProps.text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNavbar;
