//contains all welcome survey questions translated to portuguese
const WELCOME_SURVEY_QUESTIONS = [
  {
    id: 1,
    description: "Como te sentes hoje?",
    answers: [
      {
        content: "Feliz",
      },
      {
        content: "Calmo/a",
      },
      {
        content: "Ok",
      },
      {
        content: "Stressado/a",
      },
      {
        content: "Triste",
      },
    ],
  },
  {
    id: 2,
    description: "O que mais está a contribuir para te sentires :feelholder:?",
    answers: [
      {
        content: "A minha energia e vigor",
      },
      {
        content: "As experiências e desafios que me esperam",
      },
      {
        content: "Todo o amor que tenho na minha vida",
      },
      {
        content: "Ser reconhecido/a pelo meu trabalho",
      },
      {
        content: "Estar confortável no meu ambiente",
      },
      {
        content: "Conseguir gerir bem as minhas emoções",
      },
      {
        content: "O meu círculo próximo de família e amigos",
      },
      {
        content: "Sentir-me produtivo/a no meu trabalho",
      },
      {
        content: "Sentir-me saudável",
      },
      {
        content: "Conseguir manter os meus sentimentos e pensamentos em ordem",
      },
      {
        content: "Sentir-me conectado/a com as pessoas que me rodeiam",
      },
      {
        content: "A minha rotina estável de trabalho",
      },
      {
        content: "Estar desconfortável no meu ambiente",
      },
      {
        content: "Não me compreender bem a mim mesmo/a",
      },
      {
        content: "Não falar abertamente sobre os meus problemas",
      },
      {
        content: "Sentir-me sobrecarregado/a pela minha rotina diária",
      },
      {
        content: "A minha fadiga e cansaço físico",
      },
      {
        content: "Sentir-me perdido/a emocionalmente",
      },
      {
        content: "A minha falta de contacto social",
      },
      {
        content: "Sentir-me pouco reconhecido/a pelos meus esforços",
      },
    ],
  },
];

//contains all core and trial survey questions translated to portuguese
const QUESTIONS = [
  {
    id: 3,
    description: "Tens conseguido manter hábitos saudáveis?",
    labels: {
      first: "não, é raro",
      last: "sim, o mais possível",
    },
  },
  {
    id: 4,
    description: "Quanto tempo do teu dia é passado num ambiente saudável?",
    labels: {
      first: "quase nenhum",
      last: "quase todo",
    },
  },
  {
    id: 5,
    description: "Sentes-te confortável a expressar as tuas emoções?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 6,
    description: "Tens conseguido pensar com clareza quando tomas decisões?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 7,
    description: "Tens passado tempo de qualidade com a tua família?",
    labels: {
      first: "não, é raro",
      last: "sim, o mais possível",
    },
  },
  {
    id: 8,
    description: "Sentes-te isolado/a ou com falta de conexão social?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 9,
    description: "Sentes-te confiante e produtivo/a no teu trabalho?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 10,
    description:
      "Estás otimista em relação ao futuro e às perspetivas de carreira?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 11,
    description: "Tens dedicado tempo suficiente àquilo que te apaixona?",
    labels: {
      first: "não, é raro",
      last: "sim, o mais possível",
    },
  },
  {
    id: 12,
    description: "Estás a lutar para alcançar os teus objetivos de vida?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 13,
    description: "Quando acordas, sentes-te com força e energia?",
    labels: {
      first: "não, é raro",
      last: "sim, sempre",
    },
  },
  {
    id: 14,
    description: "Cuidas de ti e da tua saúde com regularidade?",
    labels: {
      first: "não, de todo",
      last: "sim, o mais possível",
    },
  },
  {
    id: 15,
    description:
      "Ultimamente, tens conseguido descansar e recarregar energias?",
    labels: {
      first: "não, é raro",
      last: "sim, sempre",
    },
  },
  {
    id: 16,
    description:
      "Ultimamente, tens-te sentido cansado/a ou fisicamente desconfortável?",
    labels: {
      first: "não, nunca",
      last: "sim, muitas vezes",
    },
  },
  {
    id: 17,
    description: "Sentes-te confortável no teu espaço de trabalho?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 18,
    description: "Quando estás em casa, sentes-te seguro/a e protegido/a?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 19,
    description: "Sais para apanhar ar puro com frequência?",
    labels: {
      first: "não, é raro",
      last: "sim, o mais possível",
    },
  },
  {
    id: 20,
    description:
      "O ruído exterior ou outros fatores externos tendem a incomodar-te?",
    labels: {
      first: "não, é raro",
      last: "sim, muitas vezes",
    },
  },
  {
    id: 21,
    description:
      "Ajustas as tuas escolhas e estilo de vida àquilo em que acreditas?",
    labels: {
      first: "não, de todo",
      last: "sim, o mais possível",
    },
  },
  {
    id: 22,
    description:
      "Vives num ambiente no qual podes ser a melhor versão de ti mesmo/a?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 23,
    description:
      "Tens consciência das situações que afetam negativamente o teu estado de espírito?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 24,
    description: "Sentes-te preocupado/a ou ansioso/a ultimamente?",
    labels: {
      first: "sim, muitas vezes",
      last: "não, é raro",
    },
  },
  {
    id: 25,
    description: "Consegues gerir bem o stress?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 26,
    description:
      "Sentes-te bem contigo próprio/a e com uma autoestima positiva?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 27,
    description: "Tens-te sentido confiante a lidar com novos desafios?",
    labels: {
      first: "não, de todo",
      last: "sim, o mais possível",
    },
  },
  {
    id: 28,
    description: "Sentes-te conectado/a e inspirado/a pelo que te rodeia?",
    labels: {
      first: "não, é raro",
      last: "sim, sempre",
    },
  },
  {
    id: 29,
    description: "Tens-te sentido mentalmente desperto/a?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 30,
    description: "Tens conseguido lidar bem com os problemas?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 31,
    description: "Deixas-te guiar frequentemente pelas emoções?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 32,
    description:
      "Dedicas tempo a pensar no que é realmente importante para ti?",
    labels: {
      first: "não, quase nenhum",
      last: "sim, o mais possível",
    },
  },
  {
    id: 33,
    description: "Estás em contacto com os teus amigos regularmente?",
    labels: {
      first: "não, é raro",
      last: "sim, sempre",
    },
  },
  {
    id: 34,
    description:
      "Tens dificuldade em criar ligação com pessoas que não conheces?",
    labels: {
      first: "não, é raro",
      last: "sim, muitas vezes",
    },
  },
  {
    id: 35,
    description: "Tens conseguido manter relações próximas e de confiança?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 36,
    description: "Sentes-te verdadeiramente amado/a e acarinhado/a ?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 37,
    description:
      "Tendes a preocupar-te muito com aquilo que os outros pensam de ti?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 38,
    description: "Sentes-te parte da tua comunidade?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 39,
    description: "Consegues exprimir as tuas opiniões quando conversas?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 40,
    description:
      "Com que regularidade dás por ti preocupado/a com o bem-estar dos outros?",
    labels: {
      first: "nunca",
      last: "sempre",
    },
  },
  {
    id: 41,
    description:
      "O teu dia-a-dia é preenchido por pessoas que são importantes para ti?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 42,
    description:
      "Sentes que as tuas relações te têm ajudado a alcançar o teu propósito individual?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 43,
    description:
      "Sentes que o teu trabalho é reconhecido por aqueles que te rodeiam?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 44,
    description:
      "No trabalho, podes ser tu próprio/a e exprimir abertamente as tuas opiniões?",
    labels: {
      first: "não, nunca",
      last: "sim, sempre",
    },
  },
  {
    id: 45,
    description:
      "Sentes-te sobrecarregado/a por compromissos e obrigações profissionais?",
    labels: {
      first: "não, é raro",
      last: "sim, muitas vezes",
    },
  },
  {
    id: 46,
    description: "Tens-te perdido ou distraído facilmente no trabalho?",
    labels: {
      first: "não, é raro",
      last: "sim, muitas vezes",
    },
  },
  {
    id: 47,
    description: "Tens conseguido gerir bem o teu dinheiro?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 48,
    description:
      "Sentes que tens o controlo das tuas finanças pessoais (ou familiares)?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 49,
    description:
      "As questões financeiras têm sido um obstáculo para alcançares os teus objetivos?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 50,
    description: "És adequadamente recompensado/a pelo teu esforço e trabalho?",
    labels: {
      first: "não, creio que não",
      last: "sim, sem dúvida",
    },
  },
  {
    id: 51,
    description:
      "Estás satisfeito/a com as tuas conquistas no trabalho até ao momento?",
    labels: {
      first: "não, de todo",
      last: "sim, a 100%",
    },
  },
  {
    id: 52,
    description:
      "Tens traçado planos para alcançares as tuas ambições de futuro?",
    labels: {
      first: "não, de todo",
      last: "sim, o mais possível",
    },
  },
];

//contains all core survey possible results translated to portuguese
const CORE_SURVEY_POSSIBLE_RESULTS_CONTENT = [
  {
    id: 21,
    header: null,
    content: "zona vermelha",
  },
  {
    id: 22,
    header: null,
    content: "zona amarela",
  },
  {
    id: 23,
    header: null,
    content: "zona verde",
  },
  {
    id: 24,
    header: "Deves focar-te em trabalhar o teu bem-estar físico.",
    content:
      "O bem-estar físico é difícil de alcançar quando não dedicamos tempo a cuidar da nossa saúde, não mantemos hábitos de vida equilibrados, não paramos para ouvir o nosso corpo ou não prestamos atenção às condições do ambiente que nos rodeia e à forma como estas podem estar a afetar o nosso dia-a-dia.",
  },
  {
    id: 25,
    header: "Deves focar-te em trabalhar o teu bem-estar mental.",
    content:
      "O bem-estar mental é difícil de alcançar quando não paramos para reconhecer os nossos sentimentos, não  trabalhamos a nossa saúde emocional, não ouvimos a nossa mente ou não desenvolvemos estratégias para nos mantermos conscientes das nossas necessidades cognitivas e dos desafios que trazem à nossa vida diária.",
  },
  {
    id: 26,
    header: "Deves focar-te em trabalhar o teu bem-estar social.",
    content:
      "O bem-estar social é difícil de alcançar quando não investimos tempo a fomentar as nossas relações, a cuidar das pessoas à nossa volta, não nos abrimos aos outros ou não criamos ligações positivas e de confiança dentro da nossa comunidade, das quais podemos beneficiar no nosso dia-a-dia.",
  },
  {
    id: 27,
    header: "Deves focar-te em trabalhar o teu bem-estar no trabalho.",
    content:
      "O bem-estar no trabalho é difícil de alcançar quando não paramos para refletir e reconhecer como nos vemos nesse contexto, quando não estabelecemos expectativas para a nossa carreira, não encontramos o equilíbrio certo entre trabalho e vida pessoal ou não criamos estratégias para lidar com os desafios financeiros do nosso dia-a-dia.",
  },
];

//contains all trial survey possible results translated to portuguese
const TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT = [
  {
    id: 6,
    header: null,
    content: "zona vermelha",
  },
  {
    id: 7,
    header: null,
    content: "zona amarela",
  },
  {
    id: 8,
    header: null,
    content: "zona verde",
  },
  {
    id: 9,
    header:
      "e a dimensão física é a que se destaca pela negativa/ uma das que se destaca pela negativa.",
    content:
      "O bem-estar físico é difícil de alcançar quando não dedicamos tempo a cuidar da nossa saúde, não mantemos hábitos de vida equilibrados, não paramos para ouvir o nosso corpo ou não prestamos atenção às condições do ambiente que nos rodeia e à forma como estas podem estar a afetar o nosso dia-a-dia.",
  },
  {
    id: 10,
    header:
      "e a dimensão mental é a que se destaca pela negativa/ uma das que se destaca pela negativa.",
    content:
      "O bem-estar mental é difícil de alcançar quando não paramos para reconhecer os nossos sentimentos, não  trabalhamos a nossa saúde emocional, não ouvimos a nossa mente ou não desenvolvemos estratégias para nos mantermos conscientes das nossas necessidades cognitivas e dos desafios que trazem à nossa vida diária.",
  },
  {
    id: 11,
    header:
      "e a dimensão social é a que se destaca pela negativa/ uma das que se destaca pela negativa.",
    content:
      "O bem-estar social é difícil de alcançar quando não investimos tempo a fomentar as nossas relações, a cuidar das pessoas à nossa volta, não nos abrimos aos outros ou não criamos ligações positivas e de confiança dentro da nossa comunidade, das quais podemos beneficiar no nosso dia-a-dia.",
  },
  {
    id: 12,
    header:
      "e a dimensão trabalho é a que se destaca pela negativa/ uma das que se destaca pela negativa.",
    content:
      "O bem-estar no trabalho é difícil de alcançar quando não paramos para refletir e reconhecer como nos vemos nesse contexto, quando não estabelecemos expectativas para a nossa carreira, não encontramos o equilíbrio certo entre trabalho e vida pessoal ou não criamos estratégias para lidar com os desafios financeiros do nosso dia-a-dia.",
  },
  {
    id: 13,
    header:
      "e a dimensão física destaca-se pela negativa/ destaca-se pela positiva.",
    content:
      "Tens reservado tempo para cuidar da tua saúde? Para ouvir o teu corpo? Para escolher (e manter) hábitos de vida equilibrados? Tens prestado atenção às condições do ambiente que te rodeia e ao impacto que podem ter em ti? Continua a trabalhar no teu bem-estar físico, introduzindo ou reforçando estes hábitos no teu dia-a-dia.",
  },
  {
    id: 14,
    header:
      "e a dimensão mental destaca-se pela negativa/ destaca-se pela positiva.",
    content:
      "Tens reservado tempo para cuidar da tua saúde emocional? Parado para reconhecer os teus sentimentos? E para ouvir a tua mente? Tens adotado estratégias para te manteres consciente das tuas necessidades cognitivas e dos desafios que elas trazem? Continua a trabalhar no teu bem-estar mental, introduzindo ou reforçando estes hábitos no teu dia-a-dia.",
  },
  {
    id: 15,
    header:
      "e a dimensão social destaca-se pela negativa/ destaca-se pela positiva.",
    content:
      "Tens dedicado tempo a cultivar as tuas relações? A cuidar das pessoas que te rodeiam? A conectar-te com os outros? Tens criado ligações positivas e de confiança dentro da tua comunidade? Continua a trabalhar no teu bem-estar social, introduzindo ou reforçando estes hábitos no teu dia-a-dia.",
  },
  {
    id: 16,
    header:
      "e a dimensão trabalho destaca-se pela negativa/ destaca-se pela positiva.",
    content:
      "Tens reservado tempo para reconhecer como te vês no trabalho? Para definir objetivos para a tua carreira? Para encontrar o equilíbrio entre o trabalho e a vida pessoal? Tens adotado estratégias para lidar com os desafios financeiros de forma eficiente? Continua a trabalhar no teu bem-estar no trabalho, introduzindo ou reforçando estes hábitos no teu dia-a-dia.",
  },
  {
    id: 17,
    header:
      "e a dimensão física é a que se destaca pela positiva/ uma das que se destaca pela positiva.",
    content:
      "Encontrar o bem-estar físico é uma prioridade no teu dia-a-dia. Cuidar bem da tua saúde, ouvir o teu corpo, manter hábitos de vida equilibrados ou prestar atenção às condições do ambiente que te rodeia e à forma como estas afetam o teu bem-estar são provavelmente hábitos positivos que já conquistaste e manténs na tua vida.",
  },
  {
    id: 18,
    header:
      "e a dimensão mental é a que se destaca pela positiva/ uma das que se destaca pela positiva.",
    content:
      "Encontrar o bem-estar mental é uma prioridade no teu dia-a-dia. Parar para reconhecer sentimentos, trabalhar a tua saúde emocional, ouvir a tua mente ou manteres-te consciente das tuas necessidades cognitivas e dos desafios que estas te trazem são provavelmente hábitos positivos que já conquistaste e manténs na tua vida.",
  },
  {
    id: 19,
    header:
      "e a dimensão social é a que se destaca pela positiva/ uma das que se destaca pela positiva.",
    content:
      "Encontrar o bem-estar social é uma prioridade no teu dia-a-dia. Cultivar as tuas relações, cuidar das pessoas que te rodeiam, estar aberto/a aos outros ou manter ligações sólidas e de confiança dentro da tua comunidade são provavelmente hábitos positivos que já conquistaste e manténs na tua vida.",
  },
  {
    id: 20,
    header:
      "e a dimensão trabalho é a que se destaca pela positiva/ uma das que se destaca pela positiva.",
    content:
      "Encontrar o bem-estar no trabalho é uma prioridade no teu dia-a-dia. Parar para reconhecer como te vês nesse contexto, estabelecer expectativas de carreira, manter um equilíbrio positivo entre trabalho e vida pessoal ou saber como lidar com desafios financeiros são provavelmente hábitos positivos que já conquistaste e manténs na tua vida.",
  },
];

export {
  WELCOME_SURVEY_QUESTIONS,
  QUESTIONS,
  CORE_SURVEY_POSSIBLE_RESULTS_CONTENT,
  TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT,
};
