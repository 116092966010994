import React, { useEffect, useState } from "react";
import {
  getQuestionAnswers,
  renderLabels,
} from "../../services/optionsInput.service";
import "./OptionsInput.css";
import { connect } from "react-redux";

import SquareAnswer from "./SquareAnswer";
import WelcomeAnswer from "./WelcomeAnswer";
import { getDimensionForQuestion } from "../../services/questionContainer.service";
import { fetchDimensions } from "../../../../utils/helpers/geralHelpers/BackendHelper";
import { createAnalytic } from "../../../../utils/helpers/geralHelpers/BackendHelper";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Functional Component: OptionsInput
 * Renders the options answers
 *
 * @param {Int} numberOfOptions
 * @param {Array} externalAnswers
 * @param {Array} answers
 * @param {Function} isOptionClicked
 * @param {Object} answerValues
 * @param {Int} questionId
 * @param {Boolean} isWelcome
 * @param {String} questionType
 * @param {Int} questionNumber
 * @param {Boolean} isTrial
 * @param {Object} question
 * @param {Array} trialSurveyQuestions
 * @param {Boolean} isCore
 * @param {Object} coreSurvey
 * @returns {Element}
 */
const OptionsInput = ({
  numberOfOptions,
  externalAnswers,
  answers,
  isOptionClicked,
  answerValues,
  questionId,
  isWelcome,
  questionType,
  questionNumber,
  isTrial,
  question,
  trialSurveyQuestions,
  isCore,
  coreSurvey,
}) => {
  const [squareRefs, setSquareRefs] = useState([]);
  const [update, setUpdate] = useState(null);
  const [questionAnswers, setQuestionAnswers] = useState(null);
  const [dimensions, setDimensions] = useState(null);

  //sets all the squareRefs
  useEffect(() => {
    const newSquareRefs = [];
    for (let i = 0; i < numberOfOptions; i++) {
      newSquareRefs.push(React.createRef());
    }

    const getDimensions = async () => {
      let dimensions = await fetchDimensions();
      setDimensions(dimensions);
    };

    setSquareRefs(newSquareRefs);

    if (!dimensions) {
      getDimensions();
    }
    // eslint-disable-next-line
  }, [numberOfOptions]);

  //paints the answer squares
  useEffect(() => {
    for (let i = 0; i < squareRefs.length; i++) {
      if (squareRefs[i].current) {
        squareRefs[i].current.style.boxShadow =
          "0 4px 25px 0 rgba(0, 0, 0, 0.1)";
        squareRefs[i].current.style.backgroundColor = "rgb(255, 255, 255)";
        squareRefs[i].current.style.color = "var(--main-text-2)";
      }
    }
    // eslint-disable-next-line
  }, [questionNumber]);

  //repaints answers on welcome survey when a user goes back and forward with already selected answers
  useEffect(() => {
    if (
      questionAnswers &&
      isWelcome &&
      squareRefs.length === questionAnswers.length
    ) {
      if (answerValues.feel.feel_type && questionNumber === 0) {
        if (answerValues.feel.feel_type === "happy") {
          squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[0].current.style.color = "white";
        } else if (answerValues.feel.feel_type === "calm") {
          squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[1].current.style.color = "white";
        } else if (answerValues.feel.feel_type === "ok") {
          squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[2].current.style.color = "white";
        } else if (answerValues.feel.feel_type === "stressed") {
          squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[3].current.style.color = "white";
        } else if (answerValues.feel.feel_type === "sad") {
          squareRefs[4].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[4].current.style.color = "white";
        }
      }

      for (let dimension of answerValues.feel.dimensions_choosen) {
        for (let i = 0; i < questionAnswers.length; i++) {
          if (
            dimension.content === questionAnswers[i].attributes.content &&
            squareRefs[i].current
          ) {
            squareRefs[i].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[i].current.style.color = "white";
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [squareRefs, questionAnswers]);

  //repaints answers on trial survey when a user goes back and forward with already selected answers
  useEffect(() => {
    if (questionAnswers && isTrial && dimensions) {
      let dimension = getDimensionForQuestion(question, dimensions);

      let orientation;

      if (questionAnswers[0].attributes.value === 1) {
        orientation = "crescente";
      } else {
        orientation = "decrescente";
      }

      let savedValue;

      if (
        dimension.sub_type &&
        answerValues[dimension.dimension_type][dimension.sub_type][0] &&
        dimension.sub_type !== "general"
      ) {
        savedValue =
          answerValues[dimension.dimension_type][dimension.sub_type][0];
      } else if (!dimension.sub_type) {
        let allSocialQuestions = trialSurveyQuestions.filter((question) => {
          return question.attributes.dimension_id === 5;
        });

        if (
          question.attributes.description ===
            allSocialQuestions[0].attributes.description &&
          answerValues[dimension.dimension_type][0]
        ) {
          savedValue =
            allSocialQuestions[0].attributes.description &&
            answerValues[dimension.dimension_type][0];
        } else if (
          question.attributes.description ===
            allSocialQuestions[1].attributes.description &&
          answerValues[dimension.dimension_type][1]
        ) {
          savedValue =
            allSocialQuestions[1].attributes.description &&
            answerValues[dimension.dimension_type][1];
        }
      } else {
        let allPurposeGeneralQuestions = trialSurveyQuestions.filter(
          (question) => {
            return question.attributes.dimension_id === 12;
          }
        );

        if (
          question.attributes.description ===
            allPurposeGeneralQuestions[0].attributes.description &&
          answerValues[dimension.dimension_type][dimension.sub_type][0]
        ) {
          savedValue =
            allPurposeGeneralQuestions[0].attributes.description &&
            answerValues[dimension.dimension_type][dimension.sub_type][0];
        } else if (
          question.attributes.description ===
            allPurposeGeneralQuestions[1].attributes.description &&
          answerValues[dimension.dimension_type][dimension.sub_type][1]
        ) {
          savedValue =
            allPurposeGeneralQuestions[1].attributes.description &&
            answerValues[dimension.dimension_type][dimension.sub_type][1];
        }
      }

      switch (savedValue) {
        case 0.5:
          if (orientation === "crescente") {
            squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
          } else {
            squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
          }

          break;
        case 1:
          if (orientation === "crescente") {
            squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
          } else {
            squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
          }
          break;
        case 1.5:
          if (orientation === "crescente") {
            squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
          } else {
            squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
          }
          break;
        case 2:
          if (orientation === "crescente") {
            squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
          } else {
            squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
          }
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line
  }, [squareRefs, questionAnswers, questionNumber, isTrial, dimensions]);

  //repaints answers on core survey when a user goes back and forward with already selected answers
  useEffect(() => {
    if (isCore && questionAnswers && dimensions) {
      let dimension = getDimensionForQuestion(question, dimensions);

      let orientation;

      if (questionAnswers[0].attributes.value === 1) {
        orientation = "crescente";
      } else {
        orientation = "decrescente";
      }

      let savedValue;

      if (dimension.sub_type) {
        let questionsWithSameDimension = coreSurvey.questions.filter(
          (question) => {
            return question.attributes.dimension_id === dimension.id;
          }
        );

        if (questionsWithSameDimension.length === 1) {
          savedValue =
            answerValues[dimension.dimension_type][dimension.sub_type][0];
        } else {
          if (
            question.attributes.description ===
            questionsWithSameDimension[0].attributes.description
          ) {
            savedValue =
              answerValues[dimension.dimension_type][dimension.sub_type][0];
          } else {
            savedValue =
              answerValues[dimension.dimension_type][dimension.sub_type][1];
          }
        }
      } else {
        let questionsWithSameDimension = coreSurvey.questions.filter(
          (question) => {
            return question.attributes.dimension_id === 5;
          }
        );

        if (
          question.attributes.description ===
          questionsWithSameDimension[0].attributes.description
        ) {
          savedValue = answerValues[dimension.dimension_type][0];
        } else {
          savedValue = answerValues[dimension.dimension_type][1];
        }
      }

      switch (savedValue) {
        case 0.16:
          squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[0].current.style.color = "white";
          break;
        case 0.32:
          squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[1].current.style.color = "white";
          break;
        case 0.42:
          if (orientation === "crescente") {
            squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[0].current.style.color = "white";
          } else {
            squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[3].current.style.color = "white";
          }

          break;
        case 0.48:
          squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[2].current.style.color = "white";
          break;
        case 0.64:
          squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[3].current.style.color = "white";
          break;
        case 0.84:
          if (orientation === "crescente") {
            squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[1].current.style.color = "white";
          } else {
            squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[2].current.style.color = "white";
          }
          break;
        case 1:
          squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[0].current.style.color = "white";
          break;
        case 1.26:
          if (orientation === "crescente") {
            squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[2].current.style.color = "white";
          } else {
            squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[1].current.style.color = "white";
          }
          break;
        case 1.68:
          if (orientation === "crescente") {
            squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[3].current.style.color = "white";
          } else {
            squareRefs[0].current.style.backgroundColor = "rgb(205, 186, 231)";
            squareRefs[0].current.style.color = "white";
          }
          break;
        case 2:
          squareRefs[1].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[1].current.style.color = "white";
          break;
        case 3:
          squareRefs[2].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[2].current.style.color = "white";
          break;
        case 4:
          squareRefs[3].current.style.backgroundColor = "rgb(205, 186, 231)";
          squareRefs[3].current.style.color = "white";
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line
  }, [isCore, questionAnswers, dimensions, questionNumber]);

  /**
   * renders the answer squares
   * @returns {Array}
   */
  const renderSquares = () => {
    const thisQuestionAnswers = getQuestionAnswers(externalAnswers, answers);

    if (
      !questionAnswers ||
      JSON.stringify(thisQuestionAnswers) !== JSON.stringify(questionAnswers) ||
      thisQuestionAnswers[0].attributes.question_id !==
        questionAnswers[0].attributes.question_id
    ) {
      setQuestionAnswers(thisQuestionAnswers);
    }

    const renderedSquares = [];

    const answersNumber = externalAnswers
      ? externalAnswers.length
      : numberOfOptions;

    if (!isWelcome) {
      for (let i = 0; i < answersNumber; i++) {
        renderedSquares.push(
          <SquareAnswer
            key={i}
            id={i}
            content={thisQuestionAnswers[i].attributes.content}
            value={thisQuestionAnswers[i].attributes.value}
            onOptionClick={isOptionClicked}
            squareRef={squareRefs[i]}
            changeStyle={changeStyle}
            isSelected={checkIfSelected(
              thisQuestionAnswers[i].attributes.content
            )}
            startTrialOrCore={
              questionNumber === 0
                ? () => {
                    if (isTrial) {
                      createAnalytic(EVENTS.SURVEY_STARTED_TRIAL);
                    } else if (isCore) {
                      createAnalytic(EVENTS.SURVEY_STARTED_CORE);
                    }
                  }
                : null
            }
          />
        );
      }
    } else if (isWelcome) {
      for (let i = 0; i < answersNumber; i++) {
        renderedSquares.push(
          <WelcomeAnswer
            key={i}
            id={i}
            content={thisQuestionAnswers[i].attributes.content}
            value={thisQuestionAnswers[i].attributes.value}
            onOptionClick={isOptionClicked}
            squareRef={squareRefs[i]}
            changeStyle={changeStyle}
            isSelected={checkIfSelected(
              thisQuestionAnswers[i].attributes.welcoming_answer
                ? thisQuestionAnswers[i].attributes.welcoming_answer.attributes
                    .dimension_id
                : null,
              thisQuestionAnswers[i].attributes.content
            )}
          />
        );
      }
    }

    return renderedSquares;
  };

  /**
   * checks if an answer is selected
   * @param {String} answer
   * @returns {Boolean}
   */
  const checkIfSelected = (dimension_id, answer) => {
    if (dimension_id) {
      for (let choosen of answerValues.feel.dimensions_choosen) {
        if (choosen.dimension_id === dimension_id) {
          return true;
        }
      }
    } else {
      for (let choosen of answerValues.feel.dimensions_choosen) {
        if (choosen.content === answer) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * checks if we are on welcome survey first question
   * @returns {Boolean}
   */
  const isItFirstWelcomeQuestion = () => {
    return isWelcome && questionNumber === 0;
  };

  if (squareRefs.length < 1) {
    return null;
  }

  if (squareRefs.length > 1 && !squareRefs[0].current && !update) {
    setUpdate(true);
  }

  /**
   * changes the square answer style depending on certain conditions
   * @param {Int} id
   */
  const changeStyle = (id) => {
    if (
      !squareRefs[id].current.style.backgroundColor ||
      squareRefs[id].current.style.backgroundColor === "rgb(255, 255, 255)"
    ) {
      squareRefs[id].current.style.boxShadow =
        " 0 4px 25px 0 rgba(0, 0, 0, 0.1), inset 0 4px 10px 0 rgba(0, 0, 0, 0.1)";
      squareRefs[id].current.style.backgroundColor = "rgb(205, 186, 231)";
      squareRefs[id].current.style.color = "white";
    } else if (
      squareRefs[id].current.style.backgroundColor === "rgb(205, 186, 231)" &&
      !isItFirstWelcomeQuestion()
    ) {
      squareRefs[id].current.style.backgroundColor = "rgb(255, 255, 255)";
      squareRefs[id].current.style.color = "var(--main-text-2)";
      squareRefs[id].current.style.boxShadow =
        "0 4px 25px 0 rgba(0, 0, 0, 0.1)";
    }
    if (questionType !== "multi") {
      for (let i = 0; i < squareRefs.length; i++) {
        if (i.toString() !== id.toString()) {
          squareRefs[i].current.style.boxShadow =
            "0 4px 25px 0 rgba(0, 0, 0, 0.1)";
          squareRefs[i].current.style.backgroundColor = "rgb(255, 255, 255)";
          squareRefs[i].current.style.color = "var(--main-text-2)";
        }
      }
    }
  };

  return (
    <div id="options-input">
      {isWelcome ? (
        <div id="welcome-answer-options">{renderSquares()}</div>
      ) : (
        <div className="d-flex align-items-end" id="answer-options">
          {renderSquares()}
        </div>
      )}

      <div className={isWelcome ? null : "labels-container"}>
        {renderLabels(answers, questionId)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answerValues: state.answerValues,
    questionNumber: state.questionNumber,
    trialSurveyQuestions: state.reorderedTrialQuestions,
    coreSurvey: state.survey,
  };
};

export default connect(mapStateToProps)(OptionsInput);
