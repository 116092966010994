import { getTipImage } from "../../../modules/Users/services/welcomeBackPage.service";
import { CORE_SURVEY_ID } from "../constants";

/**
 * returns all tips in portuguese
 * @returns {Array}
 */
const getPTTips = () => {
  return [
    {
      id: 1,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Não deixes de te desafiar",
      intro: `Continua a reforçar o teu bem-estar físico, abraçando novos desafios.`,
      subtitle: "Desafia-te",
      action: `Sai da tua zona de conforto e complementa a tua prática física com uma nova atividade. 
      [line_break]Por exemplo, se habitualmente treinas mais força, experimenta treinar flexibilidade, para variar. Se tendes a privilegiar uma atividade física de alta intensidade, experimenta uma de baixa intensidade. Alternar estilos de atividade física vai ajudar-te a atingir o teu máximo desempenho, permitindo-te treinar corpo e mente. `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First green zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 2,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Traz o novo para a tua rotina",
      intro: `Continua a reforçar o teu bem-estar físico, experimentando algo novo. 
      [line_break]Manter práticas de atividade física regulares pode ser tão rico e desafiante como implementar novas.  `,
      subtitle: "Experimenta isto",
      action: `Cria pequenos novos objetivos na tua rotina física que te assegurem uma sensação de superação permanente e garantam o entusiasmo, foco e disciplina contínuos. 
      [line_break]Como complemento, nos dias em que colocas um novo desafio físico a ti mesmo/a, escreve num diário o antes e depois da prática, registando os pensamentos, emoções e sensações físicas presentes. Lê o que escreveste e observa, com curiosidade, o que muda em ti e de que maneira essa atividade física impacta positivamente o teu humor e energia.`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second green zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 3,
      dimension_id: 1,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Estabelece um plano e segue-o",
      intro: `Continua a trabalhar no teu bem-estar físico, planeando antecipadamente.  
      [line_break]Sentes que não consegues dedicar tanto tempo quanto gostarias a cuidar do teu bem-estar físico? A ausência de planeamento é frequentemente a razão por detrás disso. 
      [line_break]Se não planeares, não darás prioridade e, no final do dia, o que vai parecer é que não tens tempo suficiente.`,
      subtitle: "Desafia-te",
      action: `Define a frequência e a duração das atividades que queres reforçar ou introduzir na tua agenda para te ajudar a cuidar melhor do teu bem-estar físico. Em que dias, em que horários e onde é que esses momentos se irão concretizar? Regista esta informação na tua agenda e trata-a como qualquer outro compromisso importante.  
      [line_break]Ainda assim, não deixes de reconhecer o que já fazes. Os benefícios de uma mentalidade de gratidão são imensos, também ao nível da saúde física (sono de maior qualidade, menor perceção de dor, diminuição da pressão sanguínea e dos níveis de stress, reforço do sistema imunitário, entre outros). `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First green zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 4,
      dimension_id: 1,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Compromete-te com uma meta",
      intro: `Continua a trabalhar no teu bem-estar físico, mantendo-te verdadeiramente comprometido com os teus objetivos. 
      [line_break]Um dos elementos catalisadores da mudança passa por acreditares na tua capacidade de mudar o teu comportamento e comprometeres-te de forma séria e continuada em agir nessa direção. O compromisso pode ser um recurso muito poderoso.`,
      subtitle: "Experimenta isto",
      action: `Dedica alguns minutos a refletir sobre o que ganhas e o que perdes em cuidar melhor de ti, do teu corpo e do ambiente que te rodeia, em linha com aquilo que mais valorizas, e escreve as tuas conclusões num papel. Repete em voz alta: “Eu comprometo-me a...” e define com clareza os teus mínimos olímpicos semanais. Por exemplo: “Eu comprometo-me a dedicar 30 minutos, 5 vezes por semana, a exercitar-me em casa, em qualquer horário viável”.`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second green zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 5,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Deixa-te guiar pelas tuas crenças",
      intro: `Alcança o teu bem-estar físico, mantendo-te fiel a ti próprio/a. 
      [line_break]Os seres humanos têm uma necessidade universal de autonomia que, quando satisfeita, aumenta a motivação. A autonomia refere-se à satisfação que advém de uma perceção de controlo sobre a nossa vida, numa vivência harmoniosa com os nossos valores e desejos.  
      [line_break]Assim, é mais provável sentirmo-nos motivados a praticar exercício físico, quando tal se assume uma escolha nossa – não porque alguém nos obrigou ou sugeriu, mas porque é algo que valorizamos interiormente.`,
      subtitle: "Pensa sobre isto",
      action: `Se estabeleceres como objetivo adotar um estilo de vida mais ativo, porque queres sentir-te em controlo do teu corpo e da tua mente e suficientemente capaz de ter um dia-a-dia autónomo, para realizares as atividades e ações que desejas, provavelmente observarás um aumento da tua motivação intrínseca que, por consequência, favorecerá a tua adesão a essa mudança de rotina. 
      [line_break]Tendo isto em mente, começa por definir com clareza de que forma cuidar do teu corpo favorece bons níveis de autonomia na tua vida e o quão importante essa liberdade para te moveres, pensares e seres é para ti. `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First yellow zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 6,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Apoia-te nos recursos que tens à tua disposição",
      intro: `Alcança o teu bem-estar físico, expandindo os teus horizontes. `,
      subtitle: "Desafia-te",
      action: `Dedica alguns instantes a identificar potenciais recursos que podem ser úteis para te ajudar a alcançar aquele que poderá ser o teu objetivo de cuidares melhor do teu corpo e do teu bem-estar físico:
      [list_start]
      [list_item]Pessoas: quem pode constituir um apoio na minha rede familiar, social e profissional? 
      [list_item]Grupos: em que comunidades, presenciais ou online, me posso integrar para procurar motivação e suporte? 
      [list_item]Canais de informação: que blogues, sites, livros, conferências podem ter conteúdos impactantes e úteis para mim?
      [list_item]Locais: que espaços posso frequentar para me ajudar a implementar as mudanças desejadas? 
      [list_item]Atividades: que tipo de atividades posso introduzir na minha rotina que me aproximam da meta pretendida?
      [list_item]Modelos: que pessoas podem constituir uma fonte de inspiração para mim?
      [list_end]`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second yellow zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 7,
      dimension_id: 1,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Descobre o teu estímulo",
      intro: `O bem-estar físico é mais fácil de alcançar, quando sabes o que traz mais estímulo. 
      [line_break]Habitualmente o ser humano mobiliza-se, ou para se aproximar de prazer e ganhos, ou para se afastar de dor e perdas. 
      [line_break]Clarifica o que é mais gerador de ação para ti em relação ao cuidar do teu corpo e bem-estar físico: os ganhos percecionados ou os potenciais prejuízos e riscos?  
      `,
      subtitle: "Experimenta isto",
      action: `Identifica e escreve numa folha de papel que ganhos concretos são esses, que alavancam a ação, ou que perigos são esses, dos quais te queres afastar. 
      [line_break]Clarificar e ter presente a verdadeira alavanca de ação, em momentos de rotina, e especialmente em momentos de stress, falta de energia ou pouca motivação, poderá desempatar a tua ambivalência e procrastinação.  
      [line_break]Para colocares o teu plano em ação, há que identificar e usar o que realmente te motiva.
      `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First yellow zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 8,
      dimension_id: 1,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Procura o lado positivo",
      intro: `O bem-estar físico é mais fácil de alcançar, quando pesas bem os pratos da balança.`,
      subtitle: "Experimenta isto",
      action: `Dedica alguns minutos a refletir sobre os ganhos e perdas de implementares uma mudança específica importante para ti, de forma a cuidares melhor do teu corpo (por exemplo: exercitar-te mais, dormir melhor, comer de forma saudável). Regista esses prós e contras numa folha de papel. 
      [line_break]Como podes ver, quando te convidas a parar e a refletir de uma forma mais ponderada, percebes que todos os ganhos e perdas associados à mudança tornam-se mais claros e, assim, as resistências e ambivalências que tantas vezes surgem, tornam-se mais evidentes. 
      [line_break]Agora, procura ir mais fundo, e tenta acrescentar mais benefícios à possibilidade de mudança, fazendo com que as vantagens sejam claramente em maior número na tua lista. O objetivo é aumentar a tua perceção dos ganhos, ao torná-los mais significativos do que as perdas ou custos associados.  
      [line_break]Mantém a tua lista sempre à mão. Assim, será mais fácil agir de forma diferente.`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second yellow zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 9,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Alimenta o teu corpo e mente",
      intro: `Alcança o teu bem-estar físico, reservando tempo para cuidar dele. 
      [line_break]Cuidar do corpo é essencial para garantirmos a nossa saúde física e psicológica. Mas, muitas vezes, pode ser difícil encontrar tempo para o fazer.
      `,
      subtitle: "Experimenta isto",
      action: `Numa folha, organiza as 24 horas do teu dia, em torno de um círculo, e regista quanto tempo dedicas a quê.
      [line_break]Nota o tamanho da fatia do trabalho, da fatia da socialização e da família, da fatia do lazer, do descanso e do autocuidado.
      [line_break]Agora desenha o círculo do teu dia ideal, atribuindo a cada área da tua vida o tempo que sentes que lhe serviria melhor. Vê o que difere num e noutro círculo.
      [line_break] Define uma ação que podes começar hoje para te aproximares de um dia-a-dia mais equilibrado, com tempo para descansar, para te envolveres em atividades que te dão prazer e para cuidares do teu corpo.`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First red zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 10,
      dimension_id: 1,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Começa devagar, mas mantém o ritmo",
      intro: `Alcança o teu bem-estar físico, mantendo os pés bem assentes no chão.
      [line_break]O ser humano é motivado por três necessidades psicológicas universais: competência, relação e autonomia. Quando satisfeitas, estas três necessidades potenciam a auto-motivação.
      [line_break]Se nos centrarmos na competência, o desafio é iniciarmos a adoção de novos hábitos partindo de onde estamos e traçando metas ajustadas à nossa realidade e capacidade atuais.
      `,
      subtitle: "Desafia-te",
      action: `Qual o pequeno passo que podes dar nos próximos dias para cuidar melhor do teu corpo, seja a que nível for? Começa devagar, mas de forma consistente. Saltar logo para ações ambiciosas tenderá a gerar frustração e sentimentos de falha, quando o que se pretende é exatamente o oposto: que te sintas competente, mesmo a fazer algo que não é habitual (ainda).`,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second red zone physical higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 11,
      dimension_id: 1,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Descobre a tua motivação",
      intro: `O bem-estar físico é difícil de alcançar quando não te exercitas. 
      [line_break]Poucas escolhas no estilo de vida têm tanto impacto na saúde como a atividade física. Segundo o “Center for Disease Control and Prevention”, o exercício regular constitui uma prática eficaz na gestão de peso, na redução de riscos de doença cardiovascular e diabetes tipo 2 e na otimização da saúde psicológica, entre muitos outros benefícios.
      [line_break]Quando queremos tornar a atividade física um hábito, por vezes o mais difícil é dar o primeiro passo. Mais do que puxar pelo músculo da força de vontade, há que perceber como é que ele funciona e como é que pode ser potenciado. 
      `,
      subtitle: "Pensa sobre isto",
      action: `Cada um de nós motiva-se de uma forma diferente; contudo, existem algumas técnicas cientificamente validadas que podem ajudar-te a mobilizar a motivação necessária para criares um estilo de vida ativo:
      [list_start]
      [list_item]Pergunta a ti mesmo/a: quais são as tuas crenças em relação à atividade física e ao cuidar do corpo? As verdades que desenvolveste ao longo da tua vida podem estar a limitar a tua mudança de hábitos no presente. 
      [list_item]Descobre que crenças te podem estar a bloquear e flexibiliza-as. Por exemplo: "fazer exercício físico é só para quem tem muito tempo livre" ou "ou se é bom nos estudos e trabalho ou se é bom fisicamente".
      [list_item]Procura ligar os objetivos que defines para ti com as tuas crenças e valores sobre a prática de exercício. 
      [list_item]Privilegia mais ação e menos motivação. Mesmo quando não tens vontade, levanta-te e mexe-te. Desafia-te a realizar qualquer tipo de atividade física, por mais básica que seja, e observa o que acontece no teu corpo.
      [list_end]
      `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "First red zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 12,
      dimension_id: 1,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Adota hábitos mais saudáveis",
      intro: `O bem-estar físico é difícil de alcançar quando não descansas. 
      [line_break]Há quem considere dormir uma perda de tempo, mas na verdade dormir bem é dos melhores investimentos que podes fazer em ti.
      [line_break]As práticas de higiene do sono que privilegias na última hora do teu dia e a forma como geres o teu stress fazem a diferença na qualidade do teu descanso. 
      `,
      subtitle: "Desafia-te",
      action: `Organiza a tua agenda de forma a cativar um mínimo de 7h de sono. Ajuda o teu corpo a embalar, reduzindo os líquidos nas cinco horas anteriores ao deitar, assim como restringindo o consumo de álcool, açúcar, cafeína e alimentos mais processados. Além disso, certifica-te de que o espaço onde dormes é confortável a nível ergonómico, temperatura, cheiros e organização. 
      [line_break]Lembra-te sempre: pequenos ajustes poderão trazer grandes diferenças no teu sono. `,
      image: getTipImage({ dimension: "physical" }).image,
      //content: "Second red zone physical lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 13,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Olha para ti como um todo",
      intro: `Continua a reforçar o teu bem-estar mental, trabalhando no teu “eu” por inteiro. 
      [line_break]Desenvolve um olhar integrado de ti mesmo/a. Mais do que separar corpo de mente, dá prioridade às ações de autocuidado que respondam também às tuas outras necessidades. `,
      subtitle: "Desafia-te",
      action: `
      [list_start]
      [list_item]Ativa os teus sentidos em alguns momentos do dia: mexe as pernas, bebe água fresca, abre uma janela, cheira uma vela, escova os dentes, ouve uma música.
      [list_item]Privilegia, por exemplo, escolhas alimentares que potenciem os estados emocionais que desejas. Lembra-te, a tua saúde mental passa muito pelo teu segundo cérebro: os intestinos. Come várias vezes ao dia, sobretudo alimentos com um índice glicémico baixo ou moderado, de diversas cores e o menos processados possível, que constituam um bom combustível para o teu organismo. Quanto mais estáveis estiverem os níveis de açúcar na tua corrente sanguínea, mais estável estará o teu humor. De igual forma, alimentos mais naturais e menos refinados ajudam a garantir uma microbiota saudável, que segregará os neurotransmissores necessários a um estado emocional estável.
      [list_item]Após dias de excesso alimentar ou toma de antibióticos ou anti-inflamatórios, assegura a ingestão de probióticos, naturais ou sintetizados, para ajudar na recuperação da tua flora intestinal.
      [list_end]
      `,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First green zone mental higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 14,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Pratica a gratidão",
      intro: `Continua a reforçar o teu bem-estar mental, aprendendo a apreciar o que tens. 
      [line_break]Numa era acelerada, em que prolifera a ambição desmedida, somos treinados a destacar o que ainda não somos, não temos, não fazemos. Contudo, é fundamental desenvolvermos a competência de reconhecer o que já somos, já temos, já fazemos. Os benefícios são imensos, e em várias dimensões. A nível do bem-estar emocional, uma mentalidade de gratidão contribui para uma maior felicidade, menor humor deprimido, potenciação da resiliência e da autoestima. 
      `,
      subtitle: "Desafia-te",
      action: `Reserva um minuto por dia para agradecer três aspetos na tua vida. A cada dia, procura diversificar os teus focos de gratidão. Nota como poderão surgir resistências e pensamentos negativos, em jeito de nuvem negra. Reconhece simplesmente essa ambivalência e volta a focar-te naquilo que podes apreciar na tua vida atual. Desde teres um sítio a que podes chamar casa, capacidade de ver todas as cores à tua volta, de saber ler, de poder sair em liberdade para onde queres, de poderes escolher as tuas roupas, de conseguires escolher os alimentos que queres ingerir.  
      [line_break]Neste exercício, treina a capacidade de reconheceres aspetos tão fundamentais para a tua existência, mas que são tão fáceis de serem dados por adquiridos e deixarem de ser apreciados.`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second green zone mental higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 15,
      dimension_id: 3,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Pratica a respiração consciente",
      intro: `Continua a trabalhar no teu bem-estar mental, aprendendo como respirar bem. 
      [line_break]Já te deste conta de que a forma como respiras é determinante para a forma como pensas e te sentes? Com frequência, cerramos a mandíbula em tensão enquanto respiramos, o que não permite que expiremos como necessário.  
      `,
      subtitle: "Experimenta isto",
      action: `Senta-te por uns instantes, colocando a mão direita na zona do peito e a mão esquerda na zona da barriga. Atenta, com curiosidade, como estás a respirar. Nota o ar a entrar pelo nariz e a sair pela boca. Lentamente, procura inspirar brevemente pelo nariz, sentindo a tua barriga a inchar como um balão, e depois expira lentamente pela boca, o dobro do tempo, notando a tua barriga a esvaziar. Podes começar este exercício, fazendo 2 ou 3 segundos de inspiração e 4 ou 6 segundos de expiração. 
      [line_break]Nota que, enquanto colocas a tua atenção na respiração, vão surgindo pensamentos distrativos. Observa-os e deixa-os ir. 
      [line_break]Esta respiração consciente é uma ótima prática a repetir a cada hora do dia, para te conectares com o seu corpo e revigorares a tua energia física e mental.`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First green zone mental lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 16,
      dimension_id: 3,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Monitoriza o teu tempo em frente ao ecrã",
      intro: `Continua a trabalhar no teu bem-estar mental, aprendendo a desligar. 
      [line_break]Com frequência, queixamo-nos de falta de tempo e de cansaço psicológico. O tempo que despendemos online pode estar a contribuir para isso. No teu dia-a-dia, tenta fazer um uso regrado do telemóvel, para não viveres sempre através de um ecrã.`,
      subtitle: "Desafia-te",
      action: `[list_start]
      [list_item]Ao acordar, inicia o teu dia com um breve exercício de auto-observação, em que te focas na tua respiração e te convidas a identificar os pensamentos, emoções e sensações físicas presentes. Ao fazê-lo, conseguirás regular melhor algumas necessidades detetadas. Usa os primeiros momentos do dia para te sintonizares consigo, antes de consultar o telemóvel.
      [list_item]Define momentos do dia interditos à tecnologia, em que simplesmente ativas os teus sentidos, seja numa refeição, numa atividade física, num convívio social ou num evento cultural e de lazer.
      [list_item]Em eventos sociais, culturais ou desportivos, mesmo que em algum momento o telemóvel seja utilizado para filmar ou fotografar, restringe esse uso para poderes estar realmente onde estás.
      [list_end]`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second green zone mental  lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 17,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Sintoniza-te contigo próprio/a",
      intro: `Alcança o teu bem-estar mental, escutando o teu coração. 
      [line_break]Tal como limpas o teu corpo e rosto ao final do dia, há que limpar também a tua mente.  
      `,
      subtitle: "Desafia-te",
      action: `Faz um exercício de ‘heartfulness’ – meditação pelo coração:
      [list_start]
      [list_item]Senta-te confortavelmente no chão, numa cadeira ou na cama, distribuindo bem o peso do teu corpo. Encontra uma posição de verdadeiro equilíbrio.  
      [list_item]Fecha os olhos e começa por colocar a tua atenção no ar a entrar e no ar a sair do teu corpo. Nota os movimentos que acompanham a tua inspiração e expiração e procura estabilizar a cadência da tua respiração. 
      [list_item]Coloca agora a tua atenção em emoções e sensações desagradáveis. Podem ser pensamentos negativos, crenças que põem em causa o teu valor, medos que te bloqueiam, memórias que te pesam, falhas das quais te envergonhas, dores que te limitam. Imagina todos esses elementos tóxicos, que não te servem, a sair das tuas costas sob a forma de fumo cinzento, formando uma nuvem, mais ou menos espessa, mais ou menos escura. 
      [list_item]Quando sentires que já libertaste tudo o que estava a mais, imagina que o fumo se extingue e que nesse momento o teu coração se ilumina. Coloca o teu foco de atenção no teu coração, agora mais leve e forte, e entra em contacto com tudo o que te apaixona, o que te dá energia, o que te traz esperança. À medida que o fazes, visualiza o teu coração a ficar cada vez mais iluminado, como se a intensidade desse feixe de luz traduzisse a força da tua vontade em ser feliz.  
      [list_item]Nota agora como está o teu corpo, de uma forma global, e a tua respiração. Agradece a ti mesmo/a teres-te dado a oportunidade de te sintonizaras contigo próprio/a. 
      [list_end]`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First yellow zone mental higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 18,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Aprende a escutar as tuas emoções",
      intro: `Alcança o teu bem-estar mental, escutando as tuas emoções. 
      [line_break]A verdade é que não temos de viver reféns das nossas emoções. Para tal, importa que sejamos capazes de compreender os contextos que favorecem estados de desregulação emocional, as fragilidades de base que os promovem e os fatores que os prolongam. 
      [line_break]Uma das estratégias mais utilizadas é a “SiBerian North Rail Road” (SBNRR). 
      `,
      subtitle: "Desafia-te",
      action: `Quando estiveres perante uma qualquer emoção mais intensa e desafiante, tenta pôr em prática a estratégia SBNRR: 
      [list_start]
      [list_item]Parar: o primeiro passo é o mais importante de todos, e muitas vezes o mais desafiante. 
      [list_item]Respirar: o segundo passo é focar a nossa atenção na respiração, e inspirar e expirar mais profundamente duas ou três vezes. 
      [list_item]Notar: o terceiro passo é observar o que se está a passar a nível do nosso corpo (sensações físicas), das nossas emoções e dos nossos pensamentos, legendando-os corretamente, e assim ganhar alguma perspetiva (é apenas uma sensação física/ uma emoção/ um pensamento). 
      [list_item]Refletir: o quarto passo é explorar, de forma curiosa. O que nos leva a sentir-nos assim, qual o contexto? Se te fizer sentido, podes até tentar dar voz às tuas suas emoções ou sensações físicas, para melhor as compreenderes: se esta emoção pudesse falar, o que me estaria a dizer? De que se estaria a queixar? 
      [list_item]Responder: o último passo é escolher a resposta que nos trará maior benefício, a nós e à situação concreta que estamos a viver, nem que seja parar novamente e retomar todo o processo. 
      [list_end]
      Esta sequência de 5 passos pode ser realizada em segundos, mas, naturalmente, requer algum treino para se tornar eficaz. Nem sempre conseguirás concretizar o primeiro passo essencial – parar – mas sê tolerante contigo mesmo/a e persiste neste processo de aprendizagem. 
      `,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second yellow zone mental higher tip"
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 19,
      dimension_id: 3,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Menos destrutivo, mais produtivo",
      intro: `O bem-estar mental é mais fácil de alcançar, quando te ouves também a ti mesmo/a. 
      [line_break]Flexibiliza a tua voz crítica interior e ganha mais consciência de como é que ela dialoga contigo: o que é que te diz, em que tom, em que situações surge com mais força? Seres capaz de caracterizar a tua voz crítica é fundamental para te poderes libertar do seu poder.  
      [line_break]Experimenta, em tempo real, legendar a tua voz crítica, como se a estivesses efetivamente a ouvir. Por exemplo: “Estou a pensar que não sou capaz de falar em público” – “Aqui está! Esta é a minha voz crítica a falar. Estou a ouvi-la, mas é apenas um pensamento negativo. Estou consciente disso, por isso não vou deixar que me afete”.
      `,
      subtitle: "Desafia-te",
      action: `Lembrando-te a ti próprio/a que todas as partes de ti querem o melhor para ti, procura calibrar o tom destrutivo da tua voz crítica e transformá-lo numa mensagem produtiva. Por exemplo: “Tenho tido dificuldade em falar em público” – "Dificuldade é diferente de incapacidade. O que é que eu posso fazer para me preparar melhor e para me sentir mais confortável nestas situações?"  
      [line_break]Reconhecer que não se é muito bom numa determinada atividade não remete para incompetência ou incapacidade, mas sim para uma oportunidade de nos desafiarmos e melhorarmos.  
      [line_break]Outra distinção relevante a fazer é entre o tempo passado e o tempo presente: todas as coisas que não conseguiste fazer ontem, são coisas que ainda podes conseguir fazer hoje ou amanhã.
      `,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First yellow zone mental lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 20,
      dimension_id: 3,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Enfrenta os demónios do autojulgamento",
      intro: `O bem-estar mental é mais fácil de alcançar, quando te dás alguma folga.  
      [line_break]A culpa é uma emoção poderosa, que muitas vezes se expressa de formas misteriosas. Procura estar mais atento/a no teu dia-a-dia, para que a possas identificar. Quando o fizeres, tenta não deixar que mine o teu bem-estar psicológico.`,
      subtitle: "Desafia-te",
      action: `Põe em prática as seguintes estratégias para te ajudar a estar mais consciente e em controlo da tua culpa:
      [list_start]
      [list_item]Para de ampliar a realidade. Pergunta a ti mesmo/a se o teu nível de autopunição é proporcional à tua ação. Provavelmente, não é. 
      [list_item]Lembra-te: tu não és as tuas ações. És responsável pelos teus comportamentos, mas eles não ditam nada sobre ti em absoluto. Há que reconhecer as nossas falhas e pedir desculpa, mas não precisamos de nos julgar eternamente. 
      [list_item]Pratica a autocompaixão. Autocompaixão não é o mesmo que autocomiseração ou desresponsabilização. Permitires-te ter compaixão por ti é entrares em contacto com a tua natureza humana, aprenderes com os erros e perdoares-te. Pensares que és uma má pessoa e julgares-te continuamente apenas irá contribuir para agires pior. 
      [list_item]Pergunta a ti mesmo: que aprendizagem posso eu retirar daqui? Transforma as tuas falhas em lições construtivas, e não em castigos ou tortura. 
      [list_end]`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second yellow zone mental lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 21,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Controla as tuas emoções",
      intro: `Alcança o teu bem-estar mental, aceitando todas as tuas diferentes emoções. 
      [line_break]Mais do que julgar ou fugir do que estás a sentir, importa aprenderes a gerir a intensidade das tuas emoções, para depois melhor compreenderes as tuas necessidades.`,
      subtitle: "Experimenta isto",
      action: `Da próxima vez que experienciares uma emoção mais intensa, coloca em prática os seguintes passos:
      [list_start]
      [list_item]Para por um instante.
      [list_item]Respira! Inspira pelo nariz e expira o dobro do ar pela boca.
      [list_item]Observa-te com curiosidade. Nota o ar a entrar e a sair. Repara como o teu corpo está a reagir. Consciencializa os pensamentos presentes e algumas das emoções que estás a sentir. Que pensamentos estão a passar pela tua cabeça? Onde está o teu foco de atenção? Ao que é que estás a reagir? Que sensações notas no seu corpo?
      [list_item]Coloca-te em perspetiva. Imagina-te a olhar para ti mesmo com uma visão de helicóptero, com mais distanciamento de ti e da situação. Mantendo essa distância, pergunta à tua emoção: o que posso fazer, aqui e agora, para te acalmar e responder às tuas necessidades? 
      [list_item]Pratica o que funciona contigo. Pode passar por ações como hidratares-te, parares de alimentar um pensamento negativo, abraçares-te, distraíres-te por um momento, alongares o corpo, dares a ti mesmo uma palavra de conforto. Coloca em prática o que sentes que pode ser mais eficaz e apropriado para ti. 
      [list_end]`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First red zone mental higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 22,
      dimension_id: 3,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Enfrenta o teu medo",
      intro: `Alcança o teu bem-estar mental, aprendendo a identificar os teus verdadeiros medos.
      [line_break]O medo é uma emoção adaptativa. Como tal, quando sentires medo, procura identificar a sua verdadeira origem. Certifica-te de que está ligado a um perigo real, não a um perigo construído por ti.`,
      subtitle: "Desafia-te",
      action: `Da próxima vez que sentires medo, para por um minuto, fechar os olhos e respirar de forma mais profunda. Depois responde às seguintes questões:
      [list_start]
      [list_item]O que é que há de novo no meu dia-a-dia que possa estar a cativar a minha atenção? O que é que há de diferente que possa estar a agitar-me e a conduzir-me para uma zona de desafio, fora da minha zona de conforto?
      [list_item]Se o meu medo pudesse falar, o que me diria? Do que necessita para diminuir de força?
      [list_item]De que tem medo o meu medo? O que receia que me possa acontecer se relaxar? 
      [list_item]Se eu não tivesse medo de falhar, como é que eu seria? O que é que eu faria?
      [list_item]Mesmo com medos, que mudanças quero abraçar com coragem?
      [list_end]`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second red zone mental higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 23,
      dimension_id: 3,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Muda a tua perspetiva",
      intro: `O bem-estar mental é difícil de alcançar quando olhas para ti e para o mundo sempre através da mesma lente. 
      [line_break]A tua saúde psicológica é tão importante como a tua saúde física, e as duas estão interligadas. Para pensar diferente e sentir diferente, há que mudar as lentes com que te perspetivas a ti e ao mundo.`,
      subtitle: "Experimenta isto",
      action: `Dedica alguns minutos a refletir sobre o que te pesa neste momento e coloca por escrito todos esses pensamentos e tudo o que te gera preocupação.
      [line_break]Para cada pensamento negativo, para cada foco no problema, reescreve esse pensamento de uma forma focada na solução. Exemplo: em vez de ”não sou suficientemente bom/a” tenta antes ”o meu valor enquanto pessoa não depende dos resultados que obtenho, mas sim dos valores que pautam as minhas ações”; ou em vez de ”tudo me acontece, a minha vida são só problemas” tenta antes ”se em cada problema que identifico neste momento existir uma oportunidade disfarçada de desafio, para treinar forças em mim, o que posso desenvolver aqui e agora?" ".
      [line_break]Nunca te esqueças: a forma como te sentes tem muito mais a ver com as “legendas” que estão a passar na tua cabeça do que com os acontecimentos e circunstâncias que efetivamente te rodeiam.`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "First red zone mental lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 24,
      dimension_id: 3,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Estabelece contacto com as tuas emoções",
      intro: `O bem-estar mental é difícil de alcançar quando não (re)conheces os teus estados emocionais.
      [line_breakO nosso corpo comunica connosco através das emoções e sensações físicas que gera. Quando não as identifica ou reconhece, ficam necessidades psicológicas por satisfazer, o que nos gera desequilíbrio e mal-estar.`,
      subtitle: "Desafia-te",
      action: `Convida-te a desacelerar e a reconhecer que emoção está presente, neste momento, no teu corpo: será alegria? Será tristeza? Será zanga? Será medo? Em pequena, média ou grande intensidade?
      [line_break]Para te ajudar neste exercício, lembra-te que: na alegria, habitualmente o teu corpo está sereno e com energia. Na tristeza, podem existir pontos de tensão corporal, falta de energia e pensamentos negativos. Na zanga, notarás um aumento de temperatura do corpo e tensão muscular. No medo, tenderás a observar um aceleramento de pensamentos e do ritmo cardíaco e pontos de tensão e desconforto físico.
      [line_break]Qual a emoção que identificas e como se está a manifestar no teu corpo agora? Se essa emoção pudesse falar, o que te poderia estar a transmitir? Torna o teu corpo e as tuas emoções aliados, e não inimigos.`,
      image: getTipImage({ dimension: "mental" }).image,
      //content: "Second red zone mental lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 25,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Sê o mais social possível",
      intro: `Continua a reforçar o teu bem-estar social, pondo de lado a tecnologia.
      [line_break]Já te perguntaste quanto tempo passas rodeado de tecnologia, incluindo quando estás com outras pessoas? 
      [line_break]É suposto a tecnologia manter-nos ligados, mas na verdade o que cria é uma intimidade virtual, que é muito diferente de uma ligação real. Não estará a tecnologia então, na realidade, a impedir a verdadeira socialização? 
      [line_break]Está na altura de largares o smartphone, afastares-te do tablet, e desligares a televisão, pelo menos por um bocado. `,
      subtitle: "Experimenta isto",
      action: `Compromete-te com o seguinte desafio: da próxima vez que te reunires com a tua família ou amigos, ou qualquer pessoa do teu círculo social, deixa todo o equipamento eletrónico de lado, para garantires que todas as tuas conversas e interações não são mediadas por ecrãs. 
      [line_break]Notarás uma enorme diferença, já que conseguirás estar realmente presente, de uma forma muito mais satisfatória. 
      `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First green zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 26,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Estima as tuas pessoas",
      intro: `Continua a reforçar o teu bem-estar social, sendo mais grato. 
      [line_break]Numa era acelerada, onde se estimula a comparação e a competição desmedida, somos treinados a destacar o que ainda não somos, não temos, não fazemos.  
      [line_break]Contudo, é fundamental desenvolvermos a competência de reconhecer o que já somos, já temos, já fazemos. Os benefícios de uma mentalidade de gratidão são imensos, e em várias dimensões, também ao nível das relações interpessoais (aumento de empatia e maior disponibilidade para ligações autênticas, entre outras).`,
      subtitle: "Pensa sobre isto",
      action: `Quando foi a última vez que agradeceste às pessoas que tens na tua vida? Que te mostraste grato/a pelas relações que te completam?  
      [line_break]Quando foi a última vez que disseste a alguém "gosto de ti", “obrigado", "admiro-te"? Escolhe uma pessoa e começa hoje a verbalizar as tuas emoções. 
      [line_break]Sabias que existem diferentes linguagens de amor: palavras de afirmação, tempo de qualidade, toque físico ou atos? Tenta identificar a tua linguagem de amor por excelência e a da pessoa que escolheste. Sem ser com palavras, de que outra forma lhes podes expressar as tuas emoções? `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second green zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 27,
      dimension_id: 5,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Alimenta as relações interpessoais",
      intro: `Continua a trabalhar no teu bem-estar social, dando vida às tuas relações. 
      [line_break]O ser humano é um ser social, pelo que a necessidade de ligação e de pertença é constante.  
      [line_break]Vivemos em plena era digital, em que facilmente se trocam likes e emojis, e estamos todos contactáveis à distância de um clique.  
      [line_break]Contudo, as interações virtuais, tantas vezes superficiais, não se equiparam de forma alguma às interações ao vivo e a cores, com pessoas em carne e osso. É apenas neste contacto próximo que conseguimos abastecer os nossos níveis de conexão.  
      [line_break]Talvez isso nos ajude a compreender porque é que os níveis de solidão estão tão elevados numa sociedade em que nunca estamos sozinhos.  
      `,
      subtitle: "Experimenta isto",
      action: `Identifica a tua tribo do bem, ou seja, as pessoas que tens na tua vida, com as quais te identificas e com quem podes contar. Reserva tempo na tua agenda da próxima semana e marca hoje um encontro com alguém dessa rede.`,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First green zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 28,
      dimension_id: 5,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Pratica uma comunicação empática",
      intro: `Continua a trabalhar no teu bem-estar social, aprendendo como comunicar eficazmente. 
      [line_break]A comunicação é a base de qualquer relação. Quando estamos conscientes da forma como comunicamos, temos mais controlo sobre o impacto que as nossas palavras podem ter na outra pessoa. `,
      subtitle: "Experimenta isto",
      action: `Como estás a comunicar com os outros? Sentes que comunicas de um modo eficaz? 
      [line_break]Aqui estão algumas pequenas regras que podes começar a pôr em prática hoje:
      [list_start]
      [list_item]Numa próxima interação que prevejas que venha a ser mais desafiante, tenta usar as seguintes expressões no teu discurso: "na minha perspetiva", "para mim", "aos meus olhos", "na minha opinião".
      [list_item]Concentra-te em comunicar as tuas emoções, não a tua perspetiva da realidade: "sinto isto", "quando me disseste isto, o que eu senti foi ".
      [list_item]Quando fizeres perguntas, mantém a mente aberta e o coração aberto, mostrando curiosidade e atenção às respostas do outro, em vez de apenas tentar "ganhar" o argumento.
      [list_end]
      Pode não ser fácil ao início, mas se mantiveres estas regras em mente, estarás a reforçar uma comunicação mais eficaz e empática, e menos suscetível de gerar desacordos e conflitos. A adoção de um estilo de comunicação saudável pode facilitar a construção e manutenção de relações mais fortes e gratificantes. 
     `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second green zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 29,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: 'Explora os teus diferentes "eu’s"',
      intro: `Alcança o teu bem-estar social, explorando todas versões de ti próprio/a. 
      [line_break]Cada um de nós existe em tantas versões quantas as pessoas que conhecemos.  
      [line_break]Cada pessoa com a qual estabelecemos uma relação desenvolve uma perspetiva de nós mesmos, de acordo com os seus filtros e com as dinâmicas que se vão construindo. 
      [line_break]Importa então diferenciar o nosso eu real, o nosso eu ideal e o nosso eu social. Por vezes, é da diferença de perspetivas entre os três que surgem os maiores desafios.
      `,
      subtitle: "Pensa sobre isto",
      action: `Como é que descreverias o teu:
      [list_start]
      [list_item]Eu real: que palavras e adjetivos escolherias para caracterizar quem realmente és hoje, física e psicologicamente? Encontra exemplos concretos de como estas características tendem a manifestar-se na tua vida quotidiana.
      [list_item]Eu ideal: como gostarias de ser daqui a seis meses? Pensando de uma forma realista, que características gostarias de ver em ti próprio? Algumas delas podem ser idênticas às do teu eu real, outras podem ser diferentes.
      [list_item]Eu social: como achas que as pessoas que te conhecem te descrevem, física e psicologicamente? Existem diferenças de perceção entre as pessoas que te conhecem a nível pessoal e as que te conhecem apenas a nível profissional?
      [list_end]
      [subtitle_start]Desafia-te:[subtitle_end]
      [line_break]Observa as características que são idênticas na forma como te descreves hoje (eu real), na forma como gostaria de te descrever no futuro (eu ideal) e na forma como os outros te descrevem (eu social). Nota agora as características que são distintas.
      [line_break]Reflete sobre o que esta informação te suscita, guiando-te pelas seguintes questões:
      [list_start]
      [list_item]Tendes a ver-te a ti mesmo/a de uma forma mais negativa do que os outros?  
      [list_item]A tua versão ideal é motivadora ou excessivamente perfeita?  
      [list_item]Que escolhas estás a fazer para te aproximares da versão que queres ser no futuro?
      [list_end]`,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First yellow zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 30,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Começa por te conectares contigo próprio",
      intro: `Alcança o teu bem-estar social, reconhecendo que a tua relação contigo próprio/a é a base da tua relação com outros. Quando te conheces bem e cuidas bem de ti, estás a criar a base para manteres relações saudáveis com os outros.`,
      subtitle: "Desafia-te",
      action: `Para uns instantes e coloca o teu foco de atenção na tua respiração. Atenta nos pensamentos, emoções e sensações físicas presentes. 
      [line_break]Ao teu ritmo, tenta recordar uma situação recente mais desafiante para ti. Pode ter sido uma manhã menos boa, uma reunião mais difícil, uma discussão com alguém de quem gostas ou uma fila de trânsito interminável.  
      [line_break]Seja o que for, imagina-te nessa situação novamente e repara nos pensamentos críticos e de julgamento que surgem. Repara como o teu corpo se ativa com essa memória. 
      [line_break]Coloca agora uns novos óculos, com lentes mais transparentes, e volta a olhar para ti nessa situação, com curiosidade, atenção, respeito e compaixão. Nota como é que o teu corpo reage a essa nova perspetiva.  
      [line_break]Termina este exercício abraçando-te a ti mesmo/a. Envolve o teu ombro e braço esquerdo com a tua mão direita, e o teu ombro e braço direito com a tua mão esquerda. Fecha os olhos e saboreia durante um momento este gesto de afeto que estás a oferecer a ti mesmo/a.  
      [line_break]Repete este exercício (e o abraço) sempre que quiseres. 
      `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second yellow zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 31,
      dimension_id: 5,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Aprende a dizer Não",
      intro: `O bem-estar social é mais fácil de alcançar, se soubermos usar duas palavras muito importantes: “Sim” e “Não”. 
      [line_break]O teu bem mais valioso é o tempo, não o dinheiro. Como tal, há que saber geri-lo de forma sensata e produtiva. 
      [line_break]Para viveres de acordo com os teus princípios e objetivos, tens de aprender a dizer mais vezes Sim a ti mesmo e aos que amas, e Não a tudo o que te desvia do essencial.  
      [line_break]É uma escolha que está sempre disponível, e que implica ponderação, foco e contacto contigo mesmo/a. Exerce essa escolha, mesmo por entre as dificuldades que possam existir.  
      `,
      subtitle: "Desafia-te",
      action: `Tendo em consideração a tua última semana, reserva uns momentos para refletires sobre as seguintes questões: 
      [list_start]
      [list_item]Ao quê e a quem tens dito “Sim”? 
      [list_item]Ao quê e a quem tens dirigido os teus “Não’s”?  
      [list_item]Para a próxima semana, se decidisses fazer algumas alterações de forma a redirecionar os teus “Sim’s” e “Não’s” a outras coisas e/ou outras pessoas, o que mudarias? 
      [list_end]`,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First yellow zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 32,
      dimension_id: 5,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Mantém o teu espaço teu",
      intro: `O bem-estar social é mais fácil de alcançar, se te apropriares da tua liberdade. 
      [line_break]Lidarmos com pessoas e conseguirmos criar ligações saudáveis pode ser um desafio.  
      [line_break]Para aprenderes a regular a tua área de liberdade e poder, imagina que estás num campo de ténis. Estás de um lado da rede e as linhas à tua volta delimitam a tua área de jogo. Do outro lado, existe outra área, igualmente delimitada. A meio, há uma rede que separa as duas áreas.  
      [line_break]Imagina uma interação com outra pessoa, como se de um jogo de ténis se tratasse. Estás de um lado do campo e a outra pessoa está do outro. Quando te diz alguma coisa ou age de determinada forma, isso equivale a um serviço executado em que a bola segue na tua direção.  
      [line_break]Apropria-te da tua liberdade para escolher responder ou não às bolas que te são dirigidas. Não tens de devolver todos os serviços, mas evita deixar bolas perdidas na tua área de jogo. Certifica-te de que as removes para te conseguires movimentar em segurança. E podes sempre devolver uma bola que venha torta, com um serviço bem direcionado, imprimindo assim o tom do teu estilo de jogo.`,
      subtitle: "Pensa sobre isto",
      action: `Este mesmo princípio pode ser aplicado para lidares com pensamentos automáticos e invasivos ou emoções mais intensas e reativas: simplesmente coloca-os do outro lado da rede, ganha o teu espaço, deixa-os ocupar o espaço deles, e apropria-te da tua liberdade para lhes responderes da mesma forma ou não.  
      [line_break]Lembra-te: entre ti e os outros há sempre um espaço de liberdade para reclamares as tuas necessidades. `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second yellow zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 33,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Dá-te mais aos outros",
      intro: `Alcança o teu bem-estar social, fortalecendo a tua rede de relações. 
      [line_break]Estarmos abertos e disponíveis para os outros pode ser difícil no meio de dias longos e atarefados, mas fomentar as nossas relações e cuidar das pessoas à nossa volta deve ser uma prioridade na nossa vida diária, a fim de encontrarmos o bem-estar social.
      `,
      subtitle: "Desafia-te",
      action: `Quando foi a última vez que conheceste alguém novo na tua comunidade? Talvez uma nova cara no teu grupo de amigos, alguém no café onde vais frequentemente, ou apenas alguém com quem te tenhas cruzado?
      [line_break]E quando foi a última vez que descobriste algo novo sobre alguém que já conheces? Um colega de trabalho, um parente distante, um amigo de infância que já não vês há algum tempo?
      [line_break]Abre-te à possibilidade e às potenciais recompensas de fortaleceres a tua rede social. Está nas tuas mãos dar o primeiro passo: dá-te mais, expõe-te mais a quem está à tua volta, dedica-te a alimentar essas relações, com a mente aberta e o coração aberto.
      `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First red zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 34,
      dimension_id: 5,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Desenha o teu círculo social",
      intro: `Alcança o teu bem-estar social, ganhando consciência da força das tuas relações.
      [line_break]Tens conseguido desenvolver e manter ligações verdadeiramente positivas e de confiança? Qual é a dimensão do teu círculo social? E quão bom é, realmente?
      `,
      subtitle: "Experimenta isto",
      action: `Faz o seguinte exercício: o círculo social.
      [list_start]
      [list_item]Pega numa folha de papel e numa caneta. 
      [list_item]Desenha um primeiro círculo no meio e escreve o teu nome lá dentro.
      [list_item]Depois, desenha um novo círculo, perto do teu, e escreve o nome da primeira pessoa em quem pensas, aquela que está sempre presente e que te dá mais apoio.
      [list_item]Continua a adicionar novos círculos com os nomes de pessoas da tua rede de relações. Aproxima-os ou afasta-os do teu nome, com base na profundidade da ligação que tens com elas.
      [list_end]
      Quando terminares, poderás ver claramente quem tens na tua rede e quão perto ou longe essas pessoas estão do teu círculo mais próximo e, dessa forma, quão forte poderá ser a tua ligação com elas. Surpreendido/a?
      [line_break]Agora, pensa: com que frequência tens passado tempo de qualidade com as pessoas que estão no teu círculo social mais próximo? Estás satisfeito/a com a tua resposta?
      `,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second red zone social higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 35,
      dimension_id: 5,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Autorregulação é a chave",
      intro: `O bem-estar social é difícil de alcançar se não regulares as tuas emoções.
      [line_break]Já deste por ti a responder de forma impulsiva a familiares ou amigos? Ou a ser reativo/a com colegas no local de trabalho?
      [line_break]Podes não saber explicar ao certo o que está a acontecer, mas notas que quando alguém entra em desacordo contigo ou quando algo corre de forma distinta do expectável, imediatamente reages com intolerância, frustração e zanga, com uma expressão facial endurecida e num tom de voz mais elevado?`,
      subtitle: "Pensa sobre isto",
      action: `Esta falta de autocontrolo pode acarretar consequências negativas, tanto a nível pessoal, como profissional. É uma dificuldade que habitualmente se enquadra dentro de uma problemática de desregulação emocional, em que a ausência de capacidade para regular a experiência e a expressão emocional se traduz em emoções muito ativas e intensas, que podem minar as nossas interações e trazer-nos frustrações nas relações. 
      [line_break]Importa compreendermos os contextos que favorecem esta desregulação, as fragilidades de base que a promovem e os fatores que a prolongam.
      [line_break]O autoconhecimento é a chave para nos conseguirmos conectar connosco mesmos, mas também com os outros.`,
      image: getTipImage({ dimension: "social" }).image,
      //content: "First red zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 36,
      dimension_id: 5,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Desliga o modo piloto automático",
      intro: `O bem-estar social é difícil de alcançar se apenas te focares em fazer e te esqueceres de ser.
      [line_break]Com frequência, passamos os nossos dias em piloto automático, num estado em que a nossa atenção se divide entre várias tarefas em simultâneo e em que os nossos sentidos ficam reduzidos, resultando em ações sem intenção consciente e sem perceção sensorial do momento presente? A nossa mente pode vaguear, o nosso corpo pode emitir alertas e as pessoas à nossa volta podem comunicar, mas tudo é recebido de forma reduzida por nós.`,
      subtitle: "Pensa sobre isto",
      action: `Muito embora estar em piloto automático constitua uma vantagem evolucionária, que nos permite adaptar aos dias de hoje, estar sempre neste modo é uma vulnerabilidade potenciadora de mal-estar. Quando o modo “Fazer” predomina nas nossas vidas, é como se vivêssemos numa espécie de anestesia, emaranhados em pensamentos e em rotinas que não nos servem sem conseguirmos sair delas, sem uma ligação real e de qualidade ao que nos rodeia e, especialmente, a quem nos rodeia.
      [line_break]É importante garantirmos uma alternância entre o modo “Ser e Sentir”, e o modo “Fazer”. Apenas num modo “Ser” consciente nos apropriamos das nossas escolhas e ações e garantimos, assim, que estão alinhadas com aquilo que realmente queremos e valorizamos. Só assim somos verdadeiramente livres.`,
      image: getTipImage({ dimension: "social" }).image,
      //content: "Second red zone social lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 37,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Sê a tua própria inspiração",
      intro: `Continua a reforçar o teu bem-estar no trabalho, trazendo para o presente o teu futuro “eu”. `,
      subtitle: "Experimenta isto",
      action: `Entrevista por escrito o teu “eu futuro”. O objetivo é que possas aprender e inspirar-te com ele/ela. Assim, ficarás mais perto de conseguir garantir hoje passos alinhados com a versão que desejas para ti no futuro. Algumas sugestões de perguntas para incluíres no teu guião:
      [list_start]
      [list_item]Qual é a sensação de teres alcançado aquilo que pretendias? 
      [list_item]O que mudou no teu dia-a-dia com a concretização desses objetivos? 
      [list_item]Quando olhas para o último ano, o que se revelou mais decisivo para progredires? 
      [list_item]Que estratégias se revelaram mais eficazes para lidares com o medo de mudar? 
      [list_item]Quais as aprendizagens mais significativas destes últimos meses? 
      [list_item]Separa-nos um ano. Se pudesses dar-me algum conselho, qual seria? 
      [list_end]`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First green zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 38,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "green zone",
      title: "Pratica a inteligência emocional",
      intro: `Continua a reforçar o teu bem-estar no trabalho, observando-te mais a ti e às tuas emoções. 
      [line_break]Na era atual, mais do que competências técnicas, as competências pessoais e emocionais são as mais exigidas em contexto de trabalho. 
      [line_break]Assim, é importante treinarmos continuamente a nossa inteligência emocional, para garantirmos um desempenho ótimo e uma liderança consciente.  `,
      subtitle: "Experimenta isto",
      action: `Para te ajudar a treinar a tua inteligência emocional, experimenta manter um diário emocional. 
      [line_break]Durante algumas semanas, em alguns momentos do dia, faz uma auto-observação e regista por escrito a informação recolhida, de modo a ires extraindo informação útil para fortaleceres a tua capacidade de regular o que sentes.
      [line_break]Fica a sugestão para organizares o teu registo, considerando os seguintes campos:  
      [list_start]
      [list_item]Data | Contexto e situação (onde estou e o que está a acontecer à minha volta?) 
      [list_item]Que pensamentos, emoções e sensações físicas observo em mim? 
      [list_item]O que fiz/ estou a fazer para regular a intensidade das minhas emoções?
      [list_item]Que impacto tiveram/ estão a ter as minhas emoções e comportamentos nos outros? 
      [list_item]Se estas emoções pudessem falar, o que me estariam a dizer, a pedir, a sinalizar? 
      [list_item]O que posso aprender com esta experiência?
      [list_item]O que posso fazer diferente numa próxima situação em que volte a experienciar emoções semelhantes? 
      [list_end]`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second green zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 39,
      dimension_id: 6,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Valoriza os ganhos da aprendizagem",
      intro: `Continua a trabalhar no teu bem-estar no trabalho, aprendendo a lidar com as tuas próprias falhas. 
      [line_break]Quando conseguires encarar os teus erros, falhas e adversidades como oportunidades de aprendizagem, estarás muito mais próximo/a de construir uma vida bem-sucedida e feliz.  
      [line_break]Lembra-te sempre: "Falhar na vida é inevitável. É impossível viver sem falhar em alguma coisa, a não ser que se viva tão cautelosamente que se acabe por não viver de todo, e, nesse caso, falha-se por defeito".`,
      subtitle: "Desafia-te",
      action: `No trabalho, adota para ti, como mantra, a crença “falhar é uma aprendizagem essencial para o sucesso”. Este guia ajudar-te-á a tornares-te mais resiliente e corajoso/a perante novos desafios. E assim, em vez de evitares situações intimidantes ou personalizares os teus resultados, passarás a abraçar com naturalidade os momentos mais difíceis do teu dia-a-dia, mesmo que tal acarrete algum desconforto e insegurança`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First green zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 40,
      dimension_id: 6,
      category: "lower",
      zone: "green zone",
      topic: "Como melhorar?",
      title: "Mantém-te (mais) em controlo do teu tempo",
      intro: `Continua a trabalhar no teu bem-estar no trabalho, gerindo o teu tempo com mais critério. `,
      subtitle: "Experimenta isto",
      action: `Transforma a tua agenda de trabalho numa agenda “agile”. Com isso, conseguirás maximizar os teus níveis de produtividade e equilíbrio.
      [line_break]Começa por organizar o teu dia em blocos de atividades:
      [list_start]
      [list_item]tempo reativo – o tempo para responder a solicitações do dia, devolver e-mails e telefonemas;  
      [list_item]tempo de produção – o tempo para trabalhar em equipa ou de forma autónoma, mas conectado/a a outros;  
      [list_item]e tempo de alto foco – janelas temporais curtas (30 minutos) de elevada concentração, em que estás indisponível e em que reduzes ao máximo possíveis fontes distrativas.
      [list_end]
      [line_break]Ao final do dia, avalia a tua produtividade em função da qualidade da atenção que dedicaste a cada coisa, e não da tua lista de afazeres, que, como sabemos, tenderá sempre a aumentar.`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second green zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 41,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Encontra o teu eu",
      intro: `Alcança o teu bem-estar no trabalho, transformando-o numa extensão da pessoa que és.`,
      subtitle: "Desafia-te",
      action: `Tira alguns instantes para refletir sobre as seguintes questões:  
      [list_start]
      [list_item]Que competências estás a ter a oportunidade de desenvolver na tua atividade profissional atual?  
      [list_item]A tua realidade profissional potencia os teus talentos ou reforça as tuas vulnerabilidades?
      [list_item]O que fazes está alinhado com os valores que priorizas?
      [list_end]
      Toma consciência e apropria-te de onde estás para garantir que qualquer passo ou decisão que tomes é feito com consciência e alinhamento.
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First yellow zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 42,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "yellow zone",
      title: "Faz as tuas escolhas",
      intro: `Alcança o bem-estar no trabalho, trocando as obrigações por escolhas.   
      [line_break]Na nossa vida, quer pessoal quer profissional, os "devo’s" ou "tenho’s" tendem a pesar muito mais do que os "quero's" e "posso’s".`,
      subtitle: "Pensa sobre isto",
      action: `Estás consciente do poder que estás a dar aos outros no teu contexto profissional quando dizes a ti próprio "tenho de fazer algo"? 
      [line_break]Para encontrares o equilíbrio entre a vida pessoal e profissional e para manteres a tua motivação, troca as obrigações no teu vocabulário por escolhas com liberdade e consciência. 
      [line_break]Encara a tua realidade profissional como um jogo de ténis: tens o poder sobre o teu campo de ação, e outros estão do outro lado da rede, na sua própria área. Canaliza o teu foco apenas para os teus movimentos, para as tuas escolhas e para o que podes realmente controlar, para criar e manter a rotina profissional que melhor te serve.`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second yellow zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 43,
      dimension_id: 6,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Faz mais com menos",
      intro: `O bem-estar no trabalho é mais fácil de alcançar, se te focares no essencial. 
      [line_break]Com que frequência dás por ti a querer fazer tudo (e mais ainda) a toda a hora? Quantas vezes isso te faz sentir stressado/a, cansado/a e frustrado/a? 
      [line_break]Liberta-te do perfeccionista sabotador que existe em ti. No teu dia-a-dia, procura guiar-te pela regra 80/20: 80% dos teus resultados virão de apenas 20% do seu esforço.`,
      subtitle: "Desafia-te",
      action: `Considerando esta regra de ouro, identifica que comportamentos e ações têm verdadeiramente mais impacto na tua qualidade de vida no trabalho, para os poderes priorizar em detrimento de um conjunto de outras ações menos essenciais. 
      [line_break]Complementarmente, desafia-te a reconheceres mais vezes o que já és e o que já fazes – faz disso um hábito. Os benefícios de uma mentalidade de gratidão são imensos, incluindo ao nível do desenvolvimento profissional:  
      [list_start]
      [list_item]Maior capacidade de liderança e de tomada de decisão;  
      [list_item]Aumento de eficácia face aos objetivos traçados;  
      [list_item]Maiores níveis de criatividade e envolvimento;  
      [list_item]Entre outros.
      [list_end]
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First yellow zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 44,
      dimension_id: 6,
      category: "lower",
      zone: "yellow zone",
      topic: "Como melhorar?",
      title: "Desacelera e recarrega",
      intro: `O bem-estar no trabalho é mais fácil de alcançar, se souberes desacelerar. 
      [line_break]Introduz algumas pausas propositadas no teu dia de trabalho.`,
      subtitle: "Experimenta isto",
      action: `Na tua rotina diária, desacelera por breves instantes e foca a tua atenção num único estímulo. Por exemplo, enquanto estás a beber o teu café pela manhã, foca os teus pensamentos apenas nisso. Atenta no formato da caneca, no seu peso e textura, no cheiro, na temperatura e no sabor associado.  
      [line_break]Durante esses minutos, não acumules mais nenhuma outra tarefa. Pode parecer-te um exercício insignificante, mas se te comprometeres a multiplicar estes instantes estratégicos ao longo do dia, notarás uma grande diferença na tua concentração, produtividade e energia global. 
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second yellow zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 45,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Aprende a viver no presente",
      intro: `Alcança o teu bem-estar no trabalho, procurando por soluções, em vez de te concentrares nos problemas.`,
      subtitle: "Experimenta isto",
      action: `Faz uma pausa de 5 minutos e pega num papel e numa caneta. Durante dois minutos, regista por escrito todas as preocupações que te surgirem, escolhendo uma palavra-chave ou uma frase que sintetize o que representam para ti e para o teu trabalho. Simplesmente, escreve tudo aquilo que te vier à cabeça.  
      [line_breka]No final, olha para a tua lista de preocupações. De tudo o que registaste, o que é que diz respeito a algo que já está efetivamente a acontecer na tua vida, e o que é que se relaciona com possibilidades futuras? Assinala Presente e Futuro consoante o foco temporal.
      [line_break]Começa por refletir sobre a proporção das tuas preocupações que estão relacionadas com meras possibilidades futuras (confabulações) e a proporção das tuas preocupações que estão ligadas a situações reais, que já estão a acontecer. O que é que essa diferença te diz? Nota a quantidade de energia que é despendida com hipóteses que ainda não se concretizaram e que podem nunca vir a acontecer.
      [line_break]Agora, foca-te apenas nas preocupações associadas a situações reais e concretas do momento presente. Que recursos tens para lidar com esses desafios? Que aptidões necessitas de pôr em prática? 
      [line_break]Lembra-te desta regra de ouro: por norma, as dificuldades que nos surgem são diretamente proporcionais às nossas capacidades. 
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First red zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 46,
      dimension_id: 6,
      category: "higher",
      topic: "Como potenciar?",
      zone: "red zone",
      title: "Quebra as barreiras invisíveis",
      intro: `Alcança o teu bem-estar no trabalho, mantendo uma mente aberta. 
      [line_break]Pessoas diferentes que vivam uma mesma situação, qualquer que seja ela, tenderão a reportar experiências distintas entre si. Assim, mais do que aquilo que acontece de facto, importa aquilo que é percecionado, por nós ou por outros, sobre o que acontece. Esta é uma regra de ouro também no trabalho. 
      `,
      subtitle: "Desafia-te",
      action: `Mais do que imaginares o que os outros pensam sobre ti ou sentires-te perdido nas orientações que te são dadas, pede ativamente feedback. Cria momentos de diálogo com os teus pares e com as tuas chefias. Clarifica objetivos, define a tua missão. Pergunta, sempre que algo não for claro para ti.  
      [line_break]A opinião dos outros é habitualmente vista como algo a temer, mas é essencial quebrarmos esta barreira. Por que não começares por pedir opiniões construtivas às pessoas em quem confias? É um pequeno passo, mas poderá ajudar-te a ultrapassar a tua apreensão e os teus medos. 
      [line_break]Além disso, certifica-te de que distingues a tua identidade do resultado do teu trabalho.  
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second red zone work higher tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 47,
      dimension_id: 6,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Trabalha a tua autoconfiança",
      intro: `O bem-estar no trabalho é difícil de alcançar, quando não paras para reconhecer como te vês nesse contexto.
      [line_break]É comum dares por ti a recear que as pessoas à tua volta descubram que afinal não és tão bom quanto pareces? Já te sentiste uma espécie de fraude, que transparece uma imagem que não corresponde à realidade? Na tua vida profissional, tens dificuldade em aceitar elogios e em reconheceres o teu valor? 
      [line_break]Talvez possas sofrer da chamada Síndrome do Impostor, que se caracteriza por uma constante dúvida sobre ti próprio e insegurança sobre quem és, impossibilitando que te apropries do mérito que tens pelas tuas conquistas e destruindo a tua autoconfiança.
      [line_break]Muitos processos de mudança são minados por esta síndrome, que pode sabotar o lado empreendedor que há em ti, retirar-te liberdade nas tuas relações e formar uma nuvem escura permanente no teu dia-a-dia. Por isso, deteta esta armadilha interna com auto-observação, e liberta-te desse sentimento pesado.
      `,
      subtitle: "Desafia-te",
      action: `Troca as lentes críticas por lentes de autorreconhecimento e aprende a orgulhar-te de ti próprio/a e de quem já és hoje, independentemente do sítio onde estás:
      [list_start]
      [list_item]Fecha os olhos por um momento e pergunta-te a ti mesmo/a: consigo dar-me valor pelo caminho que tenho feito, e pelas aprendizagens e conquistas realizadas? Do que necessito para o fazer?
      [list_item]Como lido com elogios e com críticas, quer minhas quer de outras pessoas?
      [list_item]Que três forças/ competências destaco em mim, neste momento? 
      [list_end]`,
      image: getTipImage({ dimension: "work" }).image,
      //content: "First red zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
    {
      id: 48,
      dimension_id: 6,
      category: "lower",
      zone: "red zone",
      topic: "Como melhorar?",
      title: "Encontra o teu equilíbrio",
      intro: `O bem-estar no trabalho é difícil de alcançar, quando não o consegues equilibrar com o resto.
      [line_break]O equilíbrio não é algo que se encontra, é algo que se cria todos os dias.  
      [line_break]Considerando as tuas 24 horas diárias, procura contabilizar a média de horas que dedicas a cada dimensão da tua vida. Pensa nas principais áreas, além do trabalho (carreira e compromissos profissionais): autocuidado físico (todos os momentos dedicados ao cuidado do corpo), autocuidado mental (todos os momentos dedicados ao cuidado da mente), relações pessoais (tempo despendido com família, amigos, ou em atividades de socialização) – ou outras quaisquer que te façam sentido.`,
      subtitle: "Experimenta isto",
      action: `Tendo como referência o teu último mês, desenha numa folha de papel círculos, representando as várias dimensões, e regista o número de horas que dedicas a cada.
      [line_break]Quando olhas para os teus círculos, que dimensão ou dimensões surgem no topo? E quais são aqueles a que dedicas menos tempo? Tendo esta consciência, o que é que poderias fazer de diferente para equilibrar melhor as várias áreas da tua vida entre si, e assim alcançares uma maior sensação de bem-estar geral?
      `,
      image: getTipImage({ dimension: "work" }).image,
      //content: "Second red zone work lower tip",
      survey_id: CORE_SURVEY_ID,
    },
  ];
};

/**
 * translates a tip depending on the id
 * @param {Object} tip
 * @param {String} language
 * @returns {Object}
 */
const translateTipById = (tip, language, gender) => {
  if (language === "pt-PT") {
    const attributes = getPTTips().find(
      (ptTip) => ptTip.id === tip.data.attributes.id
    );

    if (gender) {
      if (gender === "M") {
        attributes.action = attributes.action.replaceAll("o/a", "o");
        attributes.intro = attributes.intro.replaceAll("o/a", "o");
        attributes.subtitle = attributes.subtitle.replaceAll("o/a", "o");
        attributes.title = attributes.title.replaceAll("o/a", "o");
      } else if (gender === "F") {
        attributes.action = attributes.action.replaceAll("o/a", "a");
        attributes.intro = attributes.intro.replaceAll("o/a", "a");
        attributes.subtitle = attributes.subtitle.replaceAll("o/a", "a");
        attributes.title = attributes.title.replaceAll("o/a", "a");
      }
    }

    return { data: { id: attributes.id, type: "tip", attributes } };
  } else return tip;
};

export { translateTipById };
