import {
  DROP_ANSWER_VALUE,
  SAVE_ANSWER_VALUE,
  CLEAN_ANSWER_VALUE,
  INTERNAL_SIGN_OUT,
} from "../../../../store/actions/types";

const INITIAL_STATE = {
  feel: {
    feel_type: null,
    dimensions_choosen: [],
  },
  physical: {
    "physical health": [],
    "environmental mastery": [],
  },
  mental: {
    feeling: [],
    thinking: [],
  },
  social: [],
  work: {
    "balance & recognition": [],
    reward: [],
  },
  purpose: {
    physical: [],
    mental: [],
    social: [],
    work: [],
    general: [],
  },
};

/**
 * reducer from answers actions
 * @param {Array} state
 * @param {Object} action
 * @returns {Array}
 */
const answersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_ANSWER_VALUE:
      return action.payload;
    case DROP_ANSWER_VALUE:
      let newState = [...state];
      newState.pop();
      return newState;
    case CLEAN_ANSWER_VALUE:
      return INITIAL_STATE;
    case INTERNAL_SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default answersReducer;
