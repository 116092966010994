import React from "react";
import "./WelcomeSurvey.css";
import HeaderFirstVersion from "../../../../components/header/HeaderFirstVersion";
import {
  CORE_SURVEY_ID,
  WELCOME_SURVEY_ID,
} from "../../../../utils/constants/constants";
import { connect } from "react-redux";
import {
  fetchSurvey,
  fetchTemporaryScore,
  saveAnswerValue,
  cleanTemporaryScore,
  setQuestionNumber,
  cleanQuestionNumber,
  savePreliminaryResultData,
  fetchTrial,
  saveTrialReorderedQuestions,
  setFlashProps,
  saveSurvey,
} from "../../../../store/actions";
import backend from "../../../../core/apis/backend";
import {
  isThereCoreSurveyResultToday,
  isThereWelcomingSurveyResultsToday,
} from "../../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import {
  redirectToProfilePage,
  redirectToSurveyPage,
  redirectToTrialSurveyPage,
} from "../../../../core/Routing/RoutesRedirects";
import {
  onWelcomingSurveySubmit,
  saveAnonymousWelcomingResult,
  saveTemporaryScore,
  translateBack,
} from "../../services/welcomeSurvey.service";
import {
  cancelOnWindowClose,
  onWindowClose,
} from "../../../../utils/helpers/geralHelpers/WindowHelper";
import NewQuestion from "../geral/NewQuestion";
import Loading from "../../../../components/loading/Loading";
import GlossaryButton from "../geral/GlossaryButton";
import Footer from "../../../../components/footer/Footer";
import PurpleProgressBar from "../../../../components/progressBar/PurpleProgressBar";
import DancingEllipses from "../../../../components/dancingEllipses/DancingEllipses";
import FLASH_TYPES from "../../../../components/flash/FLASH_TYPES";
import { WELCOME_SURVEY_QUESTIONS } from "../../../../utils/constants/copies/variableCopies.helper";
import { translateDefinitions } from "../../../../utils/constants/copies/glossaryCopies.helper";
import { getStaticWelcomeSurveyCopies } from "../../../../utils/constants/copies/copies.helper";
import {
  replacePronounDirectlyByGender,
  replacePronounsByGender,
} from "../../services/questionContainer.service";

/**
 * Class Component: Welcome Survey
 * This component represents the welcome survey
 * @returns {Element}
 */
class WelcomeSurvey extends React.Component {
  state = {
    feel: null,
    feel_id: null,
    feels: null,
    noResults: false,
    isTakingSurvey: true,
    answers_for_feel: null,
    translated: false,
    currentLanguage: null,
  };

  //contains all static copies for the welcome survey
  SELF_COPIES = getStaticWelcomeSurveyCopies({ language: this.props.language });

  //gets all required data for the survey to work, tries to get temporary score if it exists it also sets the callback
  //to save temporary score in case the survey is interrupted
  componentDidMount = async () => {
    /**
     * @param {Function} saveAnswerValue
     * @param {Function} cleanQuestionNumber
     * @param {Function} fetchSurvey
     * @param {Object} currentUser
     * @param {Function} fetchTemporaryScore
     * @param {Object} answerValues
     * @param {Function} setQuestionNumber
     * @param {Function} fetchTrial
     * @param {Function} saveTrialReorderedQuestions
     */
    const {
      saveAnswerValue,
      cleanQuestionNumber,
      fetchSurvey,
      currentUser,
      fetchTemporaryScore,
      answerValues,
      setQuestionNumber,
      fetchTrial,
      saveTrialReorderedQuestions,
    } = this.props;

    if (!currentUser) {
      fetchTrial();
      saveTrialReorderedQuestions(null);
    }

    await fetchSurvey(WELCOME_SURVEY_ID);
    const feels = await backend.get("/feels");

    if (!currentUser && answerValues.feel.feel_type) setQuestionNumber(1);
    if (currentUser) {
      cleanQuestionNumber();
      saveAnswerValue({
        feel: {
          feel_type: null,
          dimensions_choosen: [],
        },
        physical: {
          "physical health": [],
          "environmental mastery": [],
        },
        mental: {
          feeling: [],
          thinking: [],
        },
        social: [],
        work: {
          "balance & recognition": [],
          reward: [],
        },
        purpose: {
          physical: [],
          mental: [],
          social: [],
          work: [],
          general: [],
        },
      });
      await fetchTemporaryScore(
        currentUser.attributes.user_id,
        WELCOME_SURVEY_ID
      );

      const { temporaryScore, language } = this.props;

      if (temporaryScore)
        this.loadTemporaryScore(temporaryScore, feels.data.data);

      const welcomingResultToday = await isThereWelcomingSurveyResultsToday(
        currentUser.attributes.user_id
      );
      const coreSurveyResultToday = await isThereCoreSurveyResultToday(
        currentUser.attributes.user_id,
        CORE_SURVEY_ID
      );

      if (welcomingResultToday && coreSurveyResultToday) {
        redirectToProfilePage();
      } else if (welcomingResultToday) {
        redirectToSurveyPage();
      } else {
        this.setState({
          noResults: true,
          feels: feels.data.data,
          currentLanguage: language,
        });
      }

      const callback = () => {
        const {
          temporaryScore,
          answerValues,
          survey,
          currentUser,
          cleanTemporaryScore,
        } = this.props;

        saveTemporaryScore(
          temporaryScore,
          answerValues,
          survey,
          currentUser,
          cleanTemporaryScore,
          survey.questions
        );
      };

      onWindowClose(callback);
    } else {
      const { language } = this.props;

      this.setState({
        noResults: true,
        feels: feels.data.data,
        currentLanguage: language,
      });
    }
  };

  //if a user interrupts the survey this will save the temporary score
  componentWillUnmount = () => {
    /**
     * @param {Object} currentUser
     * @param {Object} temporaryScore
     * @param {Object} answerValues
     * @param {Object} survey
     * @param {Function} cleanTemporaryScore
     */
    const {
      currentUser,
      temporaryScore,
      answerValues,
      survey,
      cleanTemporaryScore,
    } = this.props;

    if (currentUser && this.state.isTakingSurvey) {
      saveTemporaryScore(
        temporaryScore,
        answerValues,
        survey,
        currentUser,
        cleanTemporaryScore,
        survey.questions
      );
    }
    cancelOnWindowClose();
  };

  //if the survey has ended redirect the user to trial or core depending if the user is logged in or not
  //also translates survey parts on language changing
  componentDidUpdate = async () => {
    /**
     * @param {String} language
     * @param {Object} survey
     * @param {Function} saveSurvey
     * @param {Function} fetchSurvey
     * @param {Object} answerValues
     * @param {Number} questionNumber
     */
    const {
      language,
      survey,
      saveSurvey,
      fetchSurvey,
      answerValues,
      questionNumber,
    } = this.props;

    if (this.state.currentLanguage !== language) {
      this.SELF_COPIES = getStaticWelcomeSurveyCopies({ language });
      let answers = this.state.answers_for_feel;

      if (questionNumber === 1 && this.state.feels) {
        let new_feel_id = null;

        if (questionNumber === 1 && !this.state.feel_id) {
          this.state.feels.forEach((feel) => {
            if (answerValues.feel.feel_type === feel.attributes.feel_type) {
              new_feel_id = feel.id;
            }
          });
        }

        answers = survey.questions[1].attributes.answers.filter((element) => {
          return (
            element.attributes.welcoming_answer.attributes.feel_id ===
            (new_feel_id ?? this.state.feel_id)
          );
        });
      }

      if (language === "en-GB") {
        await fetchSurvey(WELCOME_SURVEY_ID);

        this.setState({
          currentLanguage: language,
          feel: translateBack(this.state.feel, language, true),
          answers_for_feel: answers,
        });
      } else {
        const copy = { ...survey };

        copy.questions[0].attributes.description =
          WELCOME_SURVEY_QUESTIONS[0].description;
        copy.questions[1].attributes.description =
          WELCOME_SURVEY_QUESTIONS[1].description;

        copy.questions[0].attributes.answers.forEach((answer, index) => {
          answer.attributes.content =
            WELCOME_SURVEY_QUESTIONS[0].answers[index].content;
        });

        copy.questions[1].attributes.answers.forEach((answer, index) => {
          answer.attributes.content =
            WELCOME_SURVEY_QUESTIONS[1].answers[index].content;
        });

        saveSurvey(copy);
        this.setState({
          currentLanguage: language,
          feel: translateBack(answerValues.feel.feel_type, language, true),
          answers_for_feel: answers,
        });
      }
    }

    this.checkIfSurveyEnded();
    if (this.state.isTakingSurvey) return;
    const { currentUser } = this.props;
    currentUser ? redirectToSurveyPage() : redirectToTrialSurveyPage();
  };

  /**
   * loads an existing temporary score so a user can start where he left
   * @param {Object} temporary_score
   * @param {Array} feels
   */
  loadTemporaryScore = (temporary_score, feels) => {
    const { answerValues, survey, saveAnswerValue, setQuestionNumber } =
      this.props;

    let answersObj = { ...answerValues };

    const feelToSave =
      temporary_score.data.attributes.temporary_score_questions[0].attributes
        .value;

    answersObj.feel.feel_type = feelToSave;

    const feel_id = feels.find((feel) => {
      return feelToSave.toLowerCase() === feel.attributes.feel_type;
    }).id;

    this.setState({ feel: feelToSave, feel_id: feel_id });

    let answersForThisFeel = survey.questions[1].attributes.answers.filter(
      (answer) => {
        return (
          answer.attributes.welcoming_answer.attributes.feel_id === feel_id
        );
      }
    );

    let dimensions_choosen = [];

    temporary_score.data.attributes.temporary_score_questions.forEach(
      (temporary_score_question, index) => {
        if (index > 0) {
          answersForThisFeel.forEach((answer, index) => {
            if (
              answer.attributes.content ===
              temporary_score_question.attributes.value
            ) {
              dimensions_choosen.push({
                content: temporary_score_question.attributes.value,
                dimension_id:
                  answer.attributes.welcoming_answer.attributes.dimension_id,
              });
            }
          });
        }
      }
    );

    answersObj.feel.dimensions_choosen = dimensions_choosen;

    saveAnswerValue(answersObj);

    if (temporary_score.data.attributes.temporary_score_questions.length > 1) {
      setQuestionNumber(1);
    }
  };

  /**
   * handles when an answer is selected
   * @param {String} content
   * @param {Function} changeStyle
   * @param {Int} id
   */
  isOptionClicked = (content, changeStyle, id) => {
    const {
      answerValues,
      questionNumber,
      setQuestionNumber,
      saveAnswerValue,
      survey,
      language,
    } = this.props;

    let answersObj = { ...answerValues };

    if (questionNumber === 0) {
      let feel_id;
      let theContent =
        language !== "en-GB"
          ? translateBack(content.toLowerCase(), language)
          : content.toLowerCase();

      this.state.feels.forEach((feel) => {
        if (theContent === feel.attributes.feel_type) {
          feel_id = feel.id;
        }
      });

      if (
        answersObj.feel.feel_type &&
        answersObj.feel.feel_type !== theContent
      ) {
        answersObj.feel.dimensions_choosen = [];

        let answers = survey.questions[1].attributes.answers.filter(
          (element) => {
            return (
              element.attributes.welcoming_answer.attributes.feel_id === feel_id
            );
          }
        );

        this.setState({
          feel: content.toLowerCase(),
          feel_id: feel_id,
          answers_for_feel: answers,
        });
      } else {
        this.setState({ feel: content.toLowerCase(), feel_id: feel_id });
      }

      answersObj.feel.feel_type =
        language !== "en-GB"
          ? translateBack(content.toLowerCase(), language)
          : content.toLowerCase();

      changeStyle(id);

      setTimeout(() => {
        setQuestionNumber(questionNumber + 1);
      }, 300);
    } else {
      changeStyle(id);

      let choosenDimension;

      for (let answer of this.state.answers_for_feel) {
        if (answer.attributes.content === content) {
          choosenDimension =
            answer.attributes.welcoming_answer.attributes.dimension_id;
        }
      }

      let objToSave = { content: content, dimension_id: choosenDimension };
      let alreadyExists = false;
      let indexToRemove = 0;

      for (let element of answersObj.feel.dimensions_choosen) {
        if (element.dimension_id === objToSave.dimension_id) {
          alreadyExists = true;
          break;
        }
        indexToRemove += 1;
      }

      if (alreadyExists) {
        answersObj.feel.dimensions_choosen.splice(indexToRemove, 1);
      } else {
        answersObj.feel.dimensions_choosen.push(objToSave);
      }
    }

    saveAnswerValue(answersObj);
  };

  //checks if the survey ended , if it did it saves the welcome result
  checkIfSurveyEnded = async () => {
    /**
     * @param {Int} questionNumber
     * @param {Object} currentUser
     * @param {Object} answerValues
     * @param {Function} savePreliminaryResultData
     * @param {Function} setFlashProps
     */
    const {
      questionNumber,
      currentUser,
      answerValues,
      savePreliminaryResultData,
      setFlashProps,
    } = this.props;

    if (questionNumber >= 2 && this.state.isTakingSurvey && currentUser) {
      const isTakingSurvey = await onWelcomingSurveySubmit(
        answerValues,
        currentUser,
        this.state.feel_id
      );

      if (isTakingSurvey) setFlashProps(FLASH_TYPES.SURVEY_SUBMIT_ERROR);

      this.setState({ isTakingSurvey });

      await savePreliminaryResultData({
        zone: "",
        copyHeader: "",
        surveyCode: "",
        total: "",
        feel_id: this.state.feel_id,
      });
    } else if (
      questionNumber >= 2 &&
      this.state.isTakingSurvey &&
      !currentUser
    ) {
      const isTakingSurvey = await saveAnonymousWelcomingResult(
        answerValues,
        savePreliminaryResultData,
        this.state.feel_id
      );

      this.setState({ isTakingSurvey });
    }
  };

  /**
   * renders the question currently beeing displayed
   * @returns {Component}
   */
  renderCurrentQuestion = () => {
    /**
     * @param {Int} questionNumber
     * @param {Object} survey
     * @param {Object} answerValues
     * @param {Object} currentUser
     * @param {String} language
     */
    const { questionNumber, survey, answerValues, currentUser, language } =
      this.props;

    if (questionNumber < 2) {
      let answers = null;
      let new_feel_id = null;

      if (questionNumber === 1 && !this.state.feel_id) {
        this.state.feels.forEach((feel) => {
          if (answerValues.feel.feel_type === feel.attributes.feel_type) {
            new_feel_id = feel.id;
          }
        });
      }

      if (questionNumber === 1) {
        answers = survey.questions[1].attributes.answers.filter((element) => {
          return (
            element.attributes.welcoming_answer.attributes.feel_id ===
            (new_feel_id ?? this.state.feel_id)
          );
        });
      }

      if (
        questionNumber === 1 &&
        (!this.state.answers_for_feel || this.state.answers_for_feel.length < 1)
      ) {
        this.setState({ answers_for_feel: answers });
      }

      return (
        <NewQuestion
          isOptionClicked={this.isOptionClicked}
          question={
            currentUser && currentUser.attributes.gender && language === "pt-PT"
              ? replacePronounsByGender(
                  survey.questions[questionNumber],
                  currentUser.attributes.gender
                )
              : survey.questions[questionNumber]
          }
          surveyType={survey.survey.attributes.survey_type}
          externalAnswers={answers}
          feel={
            replacePronounDirectlyByGender(
              translateBack(this.state.feel, language, true),
              currentUser ? currentUser.attributes.gender : null
            ) ??
            replacePronounDirectlyByGender(
              translateBack(answerValues.feel.feel_type, language, true),
              currentUser ? currentUser.attributes.gender : null
            )
          }
          isWelcome
          currentUser={currentUser}
          questionNumber={questionNumber}
          greetingCopie={
            currentUser && currentUser.attributes.gender && language === "pt-PT"
              ? replacePronounDirectlyByGender(
                  this.SELF_COPIES.greeting,
                  currentUser.attributes.gender
                )
              : this.SELF_COPIES.greeting
          }
        />
      );
    }
  };

  /**
   * returns the current question
   * @returns {Object}
   */
  getCurrentQuestion = () => {
    /**
     * @param {Int} questionNumber
     * @param {Object} survey
     */
    const { questionNumber, survey } = this.props;
    return survey.questions[Math.min(questionNumber, 2 - 1)];
  };

  render() {
    /**
     * @param {Int} questionNumber
     * @param {Object} currentUser
     * @param {Function} setQuestionNumber
     * @param {Object} survey
     * @param {Object} temporaryScore
     * @param {Object} answerValues
     */
    const {
      questionNumber,
      currentUser,
      setQuestionNumber,
      survey,
      temporaryScore,
      answerValues,
    } = this.props;

    if (
      !survey.survey ||
      survey.survey.id !== WELCOME_SURVEY_ID ||
      (!this.state.noResults && !temporaryScore) ||
      !this.state.feels
    ) {
      return <Loading />;
    }

    const thisDefinitions = translateDefinitions(
      this.getCurrentQuestion().attributes.definitions,
      this.props.language,
      this.props.currentUser?.attributes?.gender
    );

    if (questionNumber < 2) {
      return (
        <div id="welcome-survey-container">
          <HeaderFirstVersion survey={true} />

          <div id="welcome-inside-container" style={{ position: "relative" }}>
            <DancingEllipses noAnimation={true} dontShowSmall={true} />

            <div>
              <div>
                <div id="question-container">
                  {this.renderCurrentQuestion()}
                </div>
              </div>

              <PurpleProgressBar
                forwardClickFunction={
                  (questionNumber === 0 && answerValues.feel.feel_type) ||
                  (questionNumber === 1 &&
                    answerValues.feel.dimensions_choosen.length > 0)
                    ? () => setQuestionNumber(questionNumber + 1)
                    : null
                }
                totalQuestions={currentUser ? 16 : 12}
                onWelcome={true}
                questionNumber={questionNumber}
                backClickFunction={() => {
                  setQuestionNumber(questionNumber - 1);
                }}
                canMoveForward={
                  (questionNumber === 0 && answerValues.feel.feel_type) ||
                  (questionNumber === 1 &&
                    answerValues.feel.dimensions_choosen.length > 0)
                }
              />

              <div>
                <div id="glossary">
                  <GlossaryButton
                    definitions={thisDefinitions}
                    questionNumber={questionNumber}
                    canShowTooltip
                    surveyType={currentUser ? "core" : "trial"}
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    }

    return (
      <Loading
        type="building-survey"
        message="Building your survey for today..."
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    survey: state.survey,
    questionNumber: state.questionNumber,
    temporaryScore: state.currentUserTemporaryScore,
    answerValues: state.answerValues,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchSurvey,
  saveAnswerValue,
  fetchTemporaryScore,
  cleanTemporaryScore,
  setQuestionNumber,
  cleanQuestionNumber,
  savePreliminaryResultData,
  fetchTrial,
  saveTrialReorderedQuestions,
  setFlashProps,
  saveSurvey,
})(WelcomeSurvey);
