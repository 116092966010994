import backend from "../../../core/apis/backend";
import { WELCOME_SURVEY_ID } from "../../../utils/constants/constants";
import { getMajorator } from "../../Results/services/preliminaryResults.service";
const { v4: uuidv4 } = require("uuid");

/**
 * saves the partial score if the survey is interrupted
 * @param {Object} temporaryScore
 * @param {Object} answerValues
 * @param {Object} survey
 * @param {Object} currentUser
 * @param {Function} cleanTemporaryScore
 * @param {Array} surveyQuestions
 */
const saveTemporaryScore = async (
  temporaryScore,
  answerValues,
  survey,
  currentUser,
  cleanTemporaryScore,
  surveyQuestions
) => {
  let current_results = { ...answerValues };

  let questions = [];

  if (!current_results.feel.feel_type) return;

  surveyQuestions.forEach((question, index) => {
    if (index === 0) {
      questions.push({
        question_id: question.id,
        value: current_results.feel.feel_type,
      });
    } else {
      for (let dimension of current_results.feel.dimensions_choosen) {
        questions.push({
          question_id: question.id,
          value: dimension.content,
        });
      }
    }
  });

  let temporaryScoreObject = {
    survey_id: survey.survey.id,
    wellbeing_user_id: currentUser.attributes.user_id,
    questions: questions,
  };

  try {
    if (!temporaryScore) {
      await backend.post("/temporary_scores", temporaryScoreObject);
    } else {
      await backend.put(
        `/temporary_scores/${temporaryScore.data.id}`,
        temporaryScoreObject
      );
    }

    await cleanTemporaryScore();
  } catch (error) {}
};

/**
 * saves the welcoming result
 * @param {Object} answerValues
 * @param {Object} currentUser
 * @param {Int} feel_id
 * @returns {Boolean}
 */
const onWelcomingSurveySubmit = async (answerValues, currentUser, feel_id) => {
  let currentResults = { ...answerValues.feel };

  let dimension_ids = [];

  for (let dimension of currentResults.dimensions_choosen) {
    dimension_ids.push(dimension.dimension_id);
  }

  let booleanToReturn = true;

  try {
    await backend.post("/welcoming_results", {
      wellbeing_user_id: currentUser.attributes.user_id,
      feel_id: feel_id,
      dimension_ids: dimension_ids,
    });

    booleanToReturn = false;

    const { data } = await backend.get(
      `/temporary_scores/${currentUser.attributes.user_id}/${WELCOME_SURVEY_ID}`
    );

    booleanToReturn = true;

    await backend.delete(`/temporary_scores/${data.data.id}`);

    return false;
  } catch (error) {
    return booleanToReturn;
  }
};

/**
 * saves the anonymous welcome result after the welcome survey is completed when a user is not logged in
 * @param {Object} answerValues
 * @param {Function} savePreliminaryResultData
 * @param {Int} feel_id
 * @returns {Boolean}
 */
const saveAnonymousWelcomingResult = async (
  answerValues,
  savePreliminaryResultData,
  feel_id
) => {
  let feels = await backend.get("/feels");

  const surveyCode = uuidv4();

  let majorator = getMajorator(feels.data.data, answerValues);

  try {
    await backend.post("/anonymous_welcoming_results", {
      survey_code: surveyCode,
      feel_id: majorator.feel_id,
      dimension_ids: answerValues.feel.dimensions_choosen.map((dimension) => {
        return dimension.dimension_id;
      }),
    });
  } catch (e) {
    return true;
  }

  await savePreliminaryResultData({
    zone: "",
    copyHeader: "",
    surveyCode,
    feel_id,
  });

  return false;
};

/**
 * translates feels between portuguese and english
 * @param {String} content
 * @param {String} language
 * @param {Boolean} changeLanguageSecondQuestion
 * @returns {String}
 */
const translateBack = (content, language, changeLanguageSecondQuestion) => {
  if (language === "pt-PT" && changeLanguageSecondQuestion) {
    switch (content) {
      case "happy":
        return "feliz";
      case "calm":
        return "calmo/a";
      case "ok":
        return "ok";
      case "stressed":
        return "stressado/a";
      case "sad":
        return "triste";
      default:
        return content;
    }
  } else if (
    language === "pt-PT" ||
    (language === "en-GB" && changeLanguageSecondQuestion)
  ) {
    switch (content) {
      case "feliz":
        return "happy";
      case "calmo/a":
      case "calmo":
      case "calma":
        return "calm";
      case "ok":
        return "ok";
      case "stressado/a":
      case "stressado":
      case "stressada":
        return "stressed";
      case "triste":
        return "sad";
      default:
        return content;
    }
  }
};

export {
  saveTemporaryScore,
  onWelcomingSurveySubmit,
  saveAnonymousWelcomingResult,
  translateBack,
};
