/**
 * inserts the user name into a question placeholder
 * @param {String} question
 * @param {String} name
 * @returns {String}
 */
const nameInserter = (question, name) => {
  return question.replaceAll(":placeholder:", name);
};

export { nameInserter };
