import {
  isThereCoreSurveyResultToday,
  isThereWelcomingSurveyResultsToday,
} from "../../../utils/helpers/resultsHelper/CheckTodayResultsHelper";
import { getDimensionForQuestion } from "./questionContainer.service";
import {
  redirectToProfilePage,
  redirectToWelcomeSurveyPage,
} from "../../../core/Routing/RoutesRedirects";
import { CORE_SURVEY_QUESTIONS_NUMBER } from "../../../utils/constants/constants";

/**
 * verifies if a user already answered today
 * @param {String} surveyId
 * @param {String} userId
 * @returns {Boolean or Null}
 */
const canUserAnswerTodayHandler = async (surveyId, userId) => {
  const welcomingResultToday = await isThereWelcomingSurveyResultsToday(userId);
  const coreSurveyResultToday = await isThereCoreSurveyResultToday(
    userId,
    surveyId
  );

  if (welcomingResultToday && coreSurveyResultToday) {
    redirectToProfilePage();
    return null;
  } else if (welcomingResultToday) {
    return true;
  } else {
    redirectToWelcomeSurveyPage();
    return null;
  }
};

/**
 * constructs the answer values object based on the temporary score
 * @param {Object} temporaryScore
 * @param {Array} dimensions
 * @param {Array} surveyQuestions
 * @returns
 */
const constructAnswerValues = (temporaryScore, dimensions, surveyQuestions) => {
  let answersObj = {
    feel: {
      feel_type: null,
      dimensions_choosen: [],
    },
    physical: {
      "physical health": [],
      "environmental mastery": [],
    },
    mental: {
      feeling: [],
      thinking: [],
    },
    social: [],
    work: {
      "balance & recognition": [],
      reward: [],
    },
    purpose: {
      physical: [],
      mental: [],
      social: [],
      work: [],
      general: [],
    },
  };

  for (let saved_question of temporaryScore.data.attributes
    .temporary_score_questions) {
    for (let question of surveyQuestions) {
      if (
        saved_question.attributes.question_id === question.id &&
        saved_question.attributes.value
      ) {
        const dimension = getDimensionForQuestion(question, dimensions);

        let value;

        if (!question.attributes.weight) {
          value = parseInt(saved_question.attributes.value);
        } else {
          value =
            saved_question.attributes.value *
            (question.attributes.weight / 100);
        }

        if (!dimension.sub_type) {
          answersObj[dimension.dimension_type].push(value);
        } else if (
          dimension.dimension_type === "purpose" &&
          dimension.sub_type !== "general"
        ) {
          answersObj[dimension.dimension_type][dimension.sub_type].push(value);
          answersObj[dimension.dimension_type][dimension.sub_type].push(
            (value * 100) / 16
          );
        } else {
          answersObj[dimension.dimension_type][dimension.sub_type].push(value);
        }
      }
    }
  }

  return answersObj;
};

/**
 * handles core answers creation
 * @param {Object} temporaryScore
 * @param {Array} surveyQuestions
 * @param {Function} setCoreAnswers
 * @param {Function} setQuestionNumber
 * @param {Array} dimensions
 * @param {Function} saveAnswerValue
 */
const coreAnswersHandler = (
  temporaryScore,
  surveyQuestions,
  setCoreAnswers,
  setQuestionNumber,
  dimensions,
  saveAnswerValue
) => {
  let currentAnswers = [];
  if (temporaryScore) {
    currentAnswers =
      temporaryScore.data.attributes.temporary_score_questions.map(
        ({ attributes }) => {
          return {
            question_id: attributes.question_id,
            value: attributes.value,
          };
        }
      );

    setQuestionNumber(
      currentAnswers.filter((question) => question.value !== null).length >=
        CORE_SURVEY_QUESTIONS_NUMBER
        ? CORE_SURVEY_QUESTIONS_NUMBER - 1
        : currentAnswers.filter((question) => question.value !== null).length
    );

    const newAnswerValues = constructAnswerValues(
      temporaryScore,
      dimensions,
      surveyQuestions
    );
    saveAnswerValue(newAnswerValues);
  } else {
    currentAnswers = surveyQuestions.map(({ attributes }) => {
      return { question_id: attributes.id, value: null };
    });
  }
  setCoreAnswers(currentAnswers);
};

export { canUserAnswerTodayHandler, constructAnswerValues, coreAnswersHandler };
