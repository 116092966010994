import countries from "../../../utils/helpers/usersHelpers/countries.json";
import portugueseCountries from "../../../utils/countriesPortugues.json";

/**
 * renders the countries dropdown data
 * @param {String} language
 * @returns {Array}
 */
const renderCountriesDropdown = (language) => {
  if (language === "pt-PT") {
    return portugueseCountries.map((country) => {
      return { value: country.alpha2Code, label: country.name };
    });
  } else {
    return countries.map((country) => {
      return { value: country.alpha2Code, label: country.name };
    });
  }
};

export { renderCountriesDropdown };
