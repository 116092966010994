import React from "react";
import "./CoreSurvey.css";
import { connect } from "react-redux";
import {
  saveAnswerValue,
  setQuestionNumber,
  cleanTemporaryScore,
  saveCoreResultData,
  setFlashProps,
} from "../../../../store/actions";
import {
  changeAlertDate,
  getAnswersObject,
  getDimensionForQuestion,
  onCoreSurveySubmit,
  replacePronounsByGender,
  saveTemporaryScore,
} from "../../services/questionContainer.service";
import {
  CORE_SURVEY_ID,
  CORE_SURVEY_QUESTIONS_NUMBER,
} from "../../../../utils/constants/constants";
import {
  cleanAnswerValue,
  cleanCoreAnswers,
  saveCoreAnswer,
  saveSurvey,
} from "../../store/actions/surveysActions";
import {
  createAnalytic,
  deleteTemporaryScores,
  fetchDimensions,
} from "../../../../utils/helpers/geralHelpers/BackendHelper";
import Loading from "../../../../components/loading/Loading";
import { redirectToResultPage } from "../../../../core/Routing/RoutesRedirects";
import GlossaryButton from "../geral/GlossaryButton";
import {
  cancelOnWindowClose,
  onWindowClose,
} from "../../../../utils/helpers/geralHelpers/WindowHelper";
import { doesThisSurveyExist } from "../geral/ExtraRenders";
import HeaderFirstVersion from "../../../../components/header/HeaderFirstVersion";
import Footer from "../../../../components/footer/Footer";
import PurpleProgressBar from "../../../../components/progressBar/PurpleProgressBar";
import NewQuestion from "../geral/NewQuestion";
import { MAIN_PURPLE } from "../../../../utils/constants/colorsConstants";
import DancingEllipses from "../../../../components/dancingEllipses/DancingEllipses";

import LOADING_TYPES from "../../../../components/loading/LOADING_TYPES";
import FLASH_TYPES from "../../../../components/flash/FLASH_TYPES";
import {
  CORE_SURVEY_POSSIBLE_RESULTS_CONTENT,
  QUESTIONS,
} from "../../../../utils/constants/copies/variableCopies.helper";
import { translateDefinitions } from "../../../../utils/constants/copies/glossaryCopies.helper";
import { getStaticSurveyButtonCopy } from "../../../../utils/constants/copies/copies.helper";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";

//this componenet represents the core survey, the main survey in violet
class CoreSurvey extends React.Component {
  state = {
    dimensions: null,
    isTakingSurvey: true,
    showGlossaryButton: false,
    allAnswers: false,
    currentLanguage: null,
    defaultSurvey: null,
  };

  /**
   * cheks if all questions have been answered
   * @returns {Array}
   */
  checkIfAllAnswers = () => {
    const { temporaryScore } = this.props;

    if (!temporaryScore) {
      return 0;
    }

    let currentAnswers =
      temporaryScore.data.attributes.temporary_score_questions.map(
        ({ attributes }) => {
          return {
            question_id: attributes.question_id,
            value: attributes.value,
          };
        }
      );

    return currentAnswers.filter((question) => question.value !== null).length;
  };

  //gets dimensions and sets the callback for saving temporary score
  componentDidMount = async () => {
    const { language, survey, saveSurvey } = this.props;
    let dimensions = await fetchDimensions();

    let stri = JSON.stringify(survey);
    let copy2 = JSON.parse(stri);

    this.setState({
      dimensions,
      currentLanguage: language,
      defaultSurvey: copy2,
    });

    //translates the questions when the survey begins
    if (language === "pt-PT") {
      let str = JSON.stringify(survey);
      let copy = JSON.parse(str);

      copy.questions.forEach((question) => {
        QUESTIONS.forEach((a) => {
          if (question.id === a.id) {
            question.attributes.description = a.description;
            question.attributes.answers[0].attributes.label = a.labels.first;
            question.attributes.answers[3].attributes.label = a.labels.last;
          }
        });
      });

      copy.possible_results.forEach((possible_result, index) => {
        possible_result.attributes.possible_result_content.attributes.header =
          CORE_SURVEY_POSSIBLE_RESULTS_CONTENT[index].header;
        possible_result.attributes.possible_result_content.attributes.content =
          CORE_SURVEY_POSSIBLE_RESULTS_CONTENT[index].content;
      });

      saveSurvey(copy);
    }

    onWindowClose(this.saveTemporaryScoreCallback);
  };

  //if the survey has ended redirect to results page
  //also translates the survey parts on language change
  componentDidUpdate = () => {
    /**
     * @param {String} language
     * @param {Function} saveSurvey
     * @param {Object} survey
     */
    const { language, saveSurvey, survey } = this.props;

    if (this.state.currentLanguage !== language) {
      if (language === "en-GB") {
        let copy = { ...survey };
        copy.questions.forEach((question) => {
          this.state.defaultSurvey.questions.forEach((a) => {
            if (question.id === a.id) {
              question.attributes.description = a.attributes.description;
              question.attributes.answers[0].attributes.label =
                a.attributes.answers[0].attributes.label;
              question.attributes.answers[3].attributes.label =
                a.attributes.answers[3].attributes.label;
            }
          });
        });

        copy.possible_results.forEach((possible_result) => {
          this.state.defaultSurvey.possible_results.forEach((a) => {
            if (possible_result.id === a.id) {
              possible_result.attributes.possible_result_content.attributes.header =
                a.attributes.possible_result_content.attributes.header;
              possible_result.attributes.possible_result_content.attributes.content =
                a.attributes.possible_result_content.attributes.content;
            }
          });
        });

        this.setState({
          currentLanguage: language,
        });

        saveSurvey(copy);
      } else {
        let copy = { ...survey };

        copy.questions.forEach((question) => {
          QUESTIONS.forEach((a) => {
            if (question.id === a.id) {
              question.attributes.description = a.description;
              question.attributes.answers[0].attributes.label = a.labels.first;
              question.attributes.answers[3].attributes.label = a.labels.last;
            }
          });
        });

        copy.possible_results.forEach((possible_result, index) => {
          possible_result.attributes.possible_result_content.attributes.header =
            CORE_SURVEY_POSSIBLE_RESULTS_CONTENT[index].header;
          possible_result.attributes.possible_result_content.attributes.content =
            CORE_SURVEY_POSSIBLE_RESULTS_CONTENT[index].content;
        });

        this.setState({
          currentLanguage: language,
        });

        saveSurvey(copy);
      }
    }

    if (!this.state.isTakingSurvey) setTimeout(redirectToResultPage, 4000);
  };

  //if the survey is interrupted it will save the partial score
  componentWillUnmount = () => {
    const { questionNumber, setQuestionNumber, cleanAnswerValue } = this.props;

    if (
      questionNumber !== CORE_SURVEY_QUESTIONS_NUMBER &&
      this.state.isTakingSurvey
    ) {
      this.saveTemporaryScoreCallback();
    }

    setQuestionNumber(0);
    cleanAnswerValue();
    cancelOnWindowClose();
  };

  //the function that saves the temporary score if the survey is interrupted
  saveTemporaryScoreCallback = () => {
    const {
      survey,
      currentUser,
      temporaryScore,
      cleanTemporaryScore,
      coreAnswers,
    } = this.props;

    saveTemporaryScore(
      survey,
      currentUser,
      temporaryScore,
      cleanTemporaryScore,
      coreAnswers
    );
  };

  /**
   * returns a specific question id
   * @param {Int} comparisonQuestionNumber
   * @returns {Int}
   */
  getQuestionId = (comparisonQuestionNumber) => {
    const { questionNumber } = this.props;
    return questionNumber === CORE_SURVEY_QUESTIONS_NUMBER
      ? comparisonQuestionNumber - 1
      : questionNumber;
  };

  /**
   * renders questions one by one
   * @returns {Component}
   */
  renderCurrentQuestion = () => {
    const { survey, currentUser, language } = this.props;
    if (Object.keys(survey).length === 0) return null;

    return (
      <NewQuestion
        isOptionClicked={this.isOptionClicked}
        question={
          currentUser.attributes.gender && language === "pt-PT"
            ? replacePronounsByGender(
                survey.questions[
                  this.getQuestionId(CORE_SURVEY_QUESTIONS_NUMBER)
                ],
                currentUser.attributes.gender
              )
            : survey.questions[this.getQuestionId(CORE_SURVEY_QUESTIONS_NUMBER)]
        }
        surveyType={survey.survey.attributes.survey_type}
        isCore={true}
      />
    );
  };

  /**
   * changes the style of a clicked answer and saves its value, also renders previous button after first question
   * @param {String} content
   * @param {Function} changeStyle
   * @param {Int} id
   */
  isOptionClicked = (content, changeStyle, id) => {
    /**
     * @param {Object} answerValues
     * @param {Object} survey
     * @param {Number} questionNumber
     * @param {Function} saveAnswerValue
     * @param {Function} saveCoreAnswer
     * @param {Function} setQuestionNumber
     */
    const {
      answerValues,
      survey,
      questionNumber,
      saveAnswerValue,
      saveCoreAnswer,
      setQuestionNumber,
    } = this.props;

    changeStyle(id);

    //gets the updated answer object after one answer is selected
    const answersObj = getAnswersObject(
      content,
      answerValues,
      survey,
      questionNumber,
      this.state.dimensions
    );

    const newCoreAnswer = {
      questionNumber: this.getQuestionId(questionNumber),
      answer: {
        question_id: survey.questions[this.getQuestionId(questionNumber)].id,
        value: content,
      },
    };

    saveAnswerValue(answersObj);
    saveCoreAnswer(newCoreAnswer);

    if (questionNumber !== CORE_SURVEY_QUESTIONS_NUMBER - 1) {
      setTimeout(() => {
        setQuestionNumber(questionNumber + 1);
      }, 300);
    } else {
      this.setState({ allAnswers: true });
    }
  };

  //checks if the survey has finished and saves the result if it does, also deletes existant temporary scores
  checkIfSurveyEnded = async () => {
    /**
     * @param {Object} answerValues
     * @param {Object} survey
     * @param {Object} currentUser
     * @param {Function} saveCoreResultData
     * @param {Function} cleanCoreAnswers
     * @param {Object} temporaryScore
     * @param {Function} cleanTemporaryScore
     * @param {Function} cleanAnswerValue
     * @param {Int} questionNumber
     * @param {Function} setFlashProps
     * @param {String} language
     */
    const {
      answerValues,
      survey,
      currentUser,
      saveCoreResultData,
      cleanCoreAnswers,
      temporaryScore,
      cleanTemporaryScore,
      cleanAnswerValue,
      questionNumber,
      setFlashProps,
      language,
    } = this.props;

    if (
      questionNumber === CORE_SURVEY_QUESTIONS_NUMBER - 1 &&
      this.state.isTakingSurvey
    ) {
      const { isTakingSurvey } = await onCoreSurveySubmit(
        this.state.dimensions,
        answerValues,
        survey,
        currentUser,
        saveCoreResultData,
        language
      );

      if (isTakingSurvey) {
        setFlashProps(FLASH_TYPES.SURVEY_SUBMIT_ERROR);
        document.getElementById(
          "submit-button-core-survey"
        ).style.backgroundColor = "#604586";

        createAnalytic(EVENTS.SURVEY_COMPLETED_CORE_FAILED);
      } else {
        createAnalytic(EVENTS.SURVEY_COMPLETED_CORE_SUCCESSFUL);
      }

      this.setState({ isTakingSurvey });

      cleanAnswerValue();

      await changeAlertDate(currentUser);
      await cleanCoreAnswers();

      if (temporaryScore) {
        await deleteTemporaryScores(temporaryScore.data.id);
        await cleanTemporaryScore();
      }
    }
  };

  /**
   * checks if user can use the right arrow to move forward into the survey
   * @returns {Boolean}
   */
  checkIfCanGoForward = () => {
    const { answerValues, survey, questionNumber } = this.props;

    if (
      this.state.allAnswers &&
      questionNumber >= CORE_SURVEY_QUESTIONS_NUMBER - 1
    ) {
      return false;
    }

    const dimension = getDimensionForQuestion(
      survey.questions[questionNumber],
      this.state.dimensions
    );

    if (dimension.sub_type) {
      const questionsWithThisDimension = survey.questions.filter((question) => {
        return question.attributes.dimension_id === dimension.id;
      });

      if (
        questionsWithThisDimension.length === 1 &&
        answerValues[dimension.dimension_type][dimension.sub_type][0]
      ) {
        return true;
      } else if (questionsWithThisDimension.length > 1) {
        if (
          survey.questions[questionNumber].attributes.description ===
            questionsWithThisDimension[0].attributes.description &&
          answerValues[dimension.dimension_type][dimension.sub_type][0]
        ) {
          return true;
        } else if (
          survey.questions[questionNumber].attributes.description ===
            questionsWithThisDimension[1].attributes.description &&
          answerValues[dimension.dimension_type][dimension.sub_type][1]
        ) {
          return true;
        }
      }
    } else {
      const questionsWithThisDimension = survey.questions.filter((question) => {
        return question.attributes.dimension_id === 5;
      });

      if (
        survey.questions[questionNumber].attributes.description ===
          questionsWithThisDimension[0].attributes.description &&
        answerValues[dimension.dimension_type][0]
      ) {
        return true;
      } else if (
        survey.questions[questionNumber].attributes.description ===
          questionsWithThisDimension[1].attributes.description &&
        answerValues[dimension.dimension_type][1]
      ) {
        return true;
      }
    }

    return false;
  };

  render() {
    /**
     * @param {Int} questionNumber
     * @param {Object} survey
     * @param {Function} setQuestionNumber
     * @param {Object} currentUser
     */
    const { questionNumber, survey, setQuestionNumber, currentUser } =
      this.props;

    if (!doesThisSurveyExist(survey, CORE_SURVEY_ID) || !this.state.dimensions)
      return <Loading type={LOADING_TYPES.BUILDING_SURVEY} />;

    if (!this.state.isTakingSurvey) {
      return <Loading type={LOADING_TYPES.PREPARING_RESULTS} />;
    }

    if (
      this.checkIfAllAnswers() >= CORE_SURVEY_QUESTIONS_NUMBER &&
      !this.state.allAnswers
    ) {
      this.setState({ allAnswers: true });
    }

    const thisDefinitions = translateDefinitions(
      survey.questions[this.getQuestionId(CORE_SURVEY_QUESTIONS_NUMBER)]
        .attributes.definitions,
      this.props.language,
      this.props.currentUser.attributes.gender
    );

    return (
      <div
        id="new-core-survey-page"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <HeaderFirstVersion survey={true} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
            overflow: "hidden",
          }}
        >
          <DancingEllipses dontShowSmall={true} noAnimation={true} />
        </div>

        <div className="container top-container">
          <div className="row">
            <div id="new-core-survey-container">
              {this.renderCurrentQuestion()}
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              style={{
                visibility:
                  questionNumber + 1 >= CORE_SURVEY_QUESTIONS_NUMBER
                    ? "visible"
                    : "hidden",
                backgroundColor: this.state.allAnswers
                  ? MAIN_PURPLE
                  : "#c2c0c7",
              }}
              onMouseOver={(e) => {
                if (this.state.allAnswers) {
                  e.currentTarget.style.backgroundColor = "#4C366B";
                }
              }}
              onMouseOut={(e) => {
                if (
                  this.state.allAnswers &&
                  document.getElementById("submit-button-core-survey") &&
                  document.getElementById("submit-button-core-survey").style
                    .backgroundColor !== "rgb(205, 186, 231)"
                ) {
                  e.currentTarget.style.backgroundColor = "#604586";
                }
              }}
              id="submit-button-core-survey"
              className="Submit-button"
              onClick={
                this.state.allAnswers
                  ? () => {
                      let button = document.getElementById(
                        "submit-button-core-survey"
                      );
                      button.disabled = true;
                      button.style.backgroundColor = "#CDBAE7";

                      setTimeout(() => {
                        if (
                          document.getElementById("submit-button-core-survey")
                        ) {
                          document.getElementById(
                            "submit-button-core-survey"
                          ).disabled = false;
                        }
                      }, 2000);
                      this.checkIfSurveyEnded();
                    }
                  : null
              }
            >
              {
                getStaticSurveyButtonCopy({ language: this.props.language })
                  .text
              }
            </div>
          </div>
          <PurpleProgressBar
            forwardClickFunction={
              this.checkIfCanGoForward() &&
              questionNumber < CORE_SURVEY_QUESTIONS_NUMBER - 1
                ? () => {
                    setQuestionNumber(questionNumber + 1);
                  }
                : null
            }
            backClickFunction={() => {
              if (questionNumber > 0) {
                setQuestionNumber(questionNumber - 1);
              }
            }}
            totalQuestions={16}
            onCore={true}
            questionNumber={questionNumber}
            canMoveForward={this.checkIfCanGoForward()}
          />
        </div>

        <div>
          <GlossaryButton
            definitions={thisDefinitions}
            questionNumber={questionNumber}
            place={"Core"}
            user_id={currentUser.attributes.user_id}
            language={this.props.language}
          />
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionNumber: state.questionNumber,
    answerValues: state.answerValues,
    survey: state.survey,
    currentUser: state.currentUser,
    temporaryScore: state.currentUserTemporaryScore,
    coreAnswers: state.coreAnswers,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  setQuestionNumber,
  saveAnswerValue,
  cleanTemporaryScore,
  saveCoreAnswer,
  saveCoreResultData,
  cleanCoreAnswers,
  cleanAnswerValue,
  setFlashProps,
  saveSurvey,
})(CoreSurvey);
