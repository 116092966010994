import backend from "../../../core/apis/backend";
import {
  CORE_SURVEY_ID,
  CORE_SURVEY_QUESTIONS_NUMBER,
  DIMENSIONS_TO_EVALUATE,
  MAX_POINTS_TARGET,
} from "../../../utils/constants/constants";
import {
  fetchFeels,
  fetchLastWelcomingResult,
  fetchTipByAttributes,
} from "../../../utils/helpers/geralHelpers/BackendHelper";
import { getResultShareURL } from "../../../utils/helpers/geralHelpers/RandomHelper";
import { formatTip } from "../../Users/services/welcomeBackPage.service";

/**
 * saves the partial score if the survey is interrupted
 * @param {Object} survey
 * @param {Object} currentUser
 * @param {Object} temporaryScoreProps
 * @param {Function} cleanTemporaryScore
 * @param {Array} questions
 */
const saveTemporaryScore = async (
  survey,
  currentUser,
  temporaryScoreProps,
  cleanTemporaryScore,
  questions
) => {
  const temporaryScore = {
    survey_id: survey.survey.id,
    wellbeing_user_id: currentUser.attributes.user_id,
    questions,
  };

  try {
    if (!temporaryScoreProps) {
      await backend.post("/temporary_scores", temporaryScore);
    } else if (temporaryScoreProps.data.attributes) {
      await backend.put(
        `/temporary_scores/${temporaryScoreProps.data.id}`,
        temporaryScore
      );
    }
    cleanTemporaryScore();
  } catch (error) {}
};

/**
 * returns the dimension for a specific question
 * @param {Object} question
 * @param {Array} dimensions
 * @returns {Object}
 */
const getDimensionForQuestion = (question, dimensions) => {
  let finalObj = {
    dimension_type: null,
    sub_type: null,
    id: null,
  };

  for (let i = 0; i < dimensions.length; i++) {
    if (dimensions[i].ids.includes(question.attributes.dimension_id)) {
      finalObj.dimension_type = dimensions[i].dimension_type;

      for (let j = 0; j < Object.keys(dimensions[i].sub_types).length; j++) {
        if (
          dimensions[i].sub_types[Object.keys(dimensions[i].sub_types)[j]] ===
          question.attributes.dimension_id
        ) {
          finalObj.sub_type = Object.keys(dimensions[i].sub_types)[j];
          finalObj.id =
            dimensions[i].sub_types[Object.keys(dimensions[i].sub_types)[j]];
        }
      }
    }
  }

  return finalObj;
};

/**
 * returns the updated answers object
 * @param {String} content
 * @param {Object} answerValues
 * @param {Object} survey
 * @param {Int} questionNumber
 * @param {Array} dimensions
 * @returns {Object}
 */
const getAnswersObject = (
  content,
  answerValues,
  survey,
  questionNumber,
  dimensions
) => {
  let answersObj = { ...answerValues };

  let dimension = getDimensionForQuestion(
    survey.questions[
      questionNumber === CORE_SURVEY_QUESTIONS_NUMBER
        ? questionNumber - 1
        : questionNumber
    ],
    dimensions
  );

  const value =
    parseInt(content) *
    (survey.questions[
      questionNumber === CORE_SURVEY_QUESTIONS_NUMBER
        ? questionNumber - 1
        : questionNumber
    ].attributes.weight /
      100);

  if (
    dimension.dimension_type === "purpose" &&
    dimension.sub_type !== "general"
  ) {
    answersObj[dimension.dimension_type][dimension.sub_type][0] = value;
    answersObj[dimension.dimension_type][dimension.sub_type][1] =
      parseInt(content);
  } else if (dimension.sub_type) {
    let otherQuestionsWithSameDimension = survey.questions.filter(
      (question) => {
        return question.attributes.dimension_id === dimension.id;
      }
    );

    if (otherQuestionsWithSameDimension.length === 1) {
      answersObj[dimension.dimension_type][dimension.sub_type][0] =
        dimension.sub_type === "general" ? parseInt(content) : value;
    } else {
      if (
        survey.questions[questionNumber].attributes.description ===
        otherQuestionsWithSameDimension[0].attributes.description
      ) {
        answersObj[dimension.dimension_type][dimension.sub_type][0] =
          dimension.sub_type === "general" ? parseInt(content) : value;
      } else if (
        survey.questions[questionNumber].attributes.description ===
        otherQuestionsWithSameDimension[1].attributes.description
      ) {
        answersObj[dimension.dimension_type][dimension.sub_type][1] =
          dimension.sub_type === "general" ? parseInt(content) : value;
      }
    }
  } else if (!dimension.sub_type) {
    let otherQuestionsWithSameDimension = survey.questions.filter(
      (question) => {
        return question.attributes.dimension_id === 5;
      }
    );

    if (otherQuestionsWithSameDimension.length === 1) {
      answersObj[dimension.dimension_type][0] = value;
    } else {
      if (
        survey.questions[questionNumber].attributes.description ===
        otherQuestionsWithSameDimension[0].attributes.description
      ) {
        answersObj[dimension.dimension_type][0] = value;
      } else if (
        survey.questions[questionNumber].attributes.description ===
        otherQuestionsWithSameDimension[1].attributes.description
      ) {
        answersObj[dimension.dimension_type][1] = value;
      }
    }
  }

  return answersObj;
};

/**
 * handles the submission of a core survey
 * @param {Array} dimensions
 * @param {Object} answerValues
 * @param {Object} survey
 * @param {Object} currentUser
 * @param {Function} saveCoreResultData
 * @param {String} language
 * @returns {Object}
 */
const onCoreSurveySubmit = async (
  dimensions,
  answerValues,
  survey,
  currentUser,
  saveCoreResultData,
  language
) => {
  const feels = await fetchFeels();
  let lastWelcomingResult = await fetchLastWelcomingResult(
    currentUser.attributes.user_id
  );

  const {
    copyContent,
    zone,
    dimensionValues,
    total,
    purposeResult,
    purposePercentage,
    highestDimension,
    lowestDimension,
  } = getResultReadyToRender(
    feels,
    dimensions,
    answerValues,
    DIMENSIONS_TO_EVALUATE,
    survey,
    MAX_POINTS_TARGET,
    lastWelcomingResult
  );

  const tips = await getTips({
    highestDimension,
    lowestDimension,
    zone: getZoneCopyFromLanguage({ zone, language: "en-GB" }),
    userId: currentUser.attributes.user_id,
  });

  const result = await saveResult(
    currentUser,
    dimensionValues,
    total,
    purposeResult,
    highestDimension,
    lowestDimension,
    tips.highestTip.id,
    tips.lowestTip.id
  );

  const url = getResultShareURL(
    result.data.data,
    language,
    currentUser.attributes.gender
  );

  await saveCoreResultData({
    copyContent,
    zone,
    dimensionValues,
    total,
    purposeResult,
    url,
    purposePercentage,
    highestDimension,
    lowestDimension,
    share_id: result.data.data.attributes.share_id,
    tips: { highestTip: tips.highestTip, lowestTip: tips.lowestTip },
  });

  return { isTakingSurvey: false };
};

const getZoneCopyFromLanguage = ({ zone, language }) => {
  const ZONES = {
    red_zone: { "en-GB": "red zone", "pt-PT": "zona vermelha" },
    yellow_zone: { "en-GB": "yellow zone", "pt-PT": "zona amarela" },
    green_zone: { "en-GB": "green zone", "pt-PT": "zona verde" },
  };
  const thisZone = translateZone(zone);
  return ZONES[thisZone][language];
};

/**
 * Translates zone to english
 * @param {String} zone
 * @returns {String}
 */
const translateZone = (zone) => {
  switch (zone) {
    case "zona vermelha":
    case "red zone":
      return "red_zone";
    case "zona amarela":
    case "yellow zone":
      return "yellow_zone";
    case "zona verde":
    case "green zone":
      return "green_zone";
    default:
      return "";
  }
};

/**
 * readies all the required result info to be displayed after the survey ended
 * @param {Array} feels
 * @param {Array} dimensions
 * @param {Object} answerValues
 * @param {Int} dimensionsToEvaluate
 * @param {Object} survey
 * @param {Int} max_target
 * @param {Object} lastWelcomingResult
 * @returns {Object}
 */
const getResultReadyToRender = (
  feels,
  dimensions,
  answerValues,
  dimensionsToEvaluate,
  survey,
  max_target,
  lastWelcomingResult
) => {
  let majorator = getMajorator(feels, lastWelcomingResult);
  let dimensionsToMajorate = getDimensionsToMajorate(
    dimensions,
    lastWelcomingResult
  );

  let result = calculateResult(
    majorator.weight,
    dimensionsToMajorate,
    answerValues,
    dimensionsToEvaluate,
    max_target
  );

  let possibleResultsForThisScore = getThisScorePossibleResults(
    result.finalResult,
    survey
  );

  let zone = getScoreZone(possibleResultsForThisScore);

  let dimensionForCopy = getDimensionForCopy(result.sortedDimensionsValues);
  let highestDimension = getHigherDimension(
    result.sortedDimensionsValues,
    dimensionForCopy
  );

  let copyContent = getCopyContentFromPossibleResults(
    dimensionForCopy,
    dimensions,
    possibleResultsForThisScore
  );

  return {
    copyContent,
    zone,
    dimensionValues: result.sortedDimensionsValues,
    total: result.finalResult,
    feel_id: majorator.feel_id,
    purposeResult: result.purposeResult,
    purposePercentage: result.purposePercentage,
    highestDimension,
    lowestDimension: dimensionForCopy,
  };
};

/**
 * gets the tips for the highest and lowest dimension
 * @param {Object} highestDimension
 * @param {Object} lowestDimension
 * @param {String} zone
 * @param {String} userId
 * @returns {Object}
 */
const getTips = async ({ highestDimension, lowestDimension, zone, userId }) => {
  let higherDimensionTip = await fetchTipByAttributes({
    dimensionId: highestDimension.dimension_id,
    zone,
    category: "higher",
    userId,
  });

  let lowerDimensionTip = await fetchTipByAttributes({
    dimensionId: lowestDimension.dimension_id,
    zone,
    category: "lower",
    userId,
  });

  return {
    highestTip: {
      id: higherDimensionTip.data.attributes.id,
      ...formatTip({
        tip: higherDimensionTip,
        dimension: highestDimension.dimension,
      }),
    },
    lowestTip: {
      id: lowerDimensionTip.data.attributes.id,
      ...formatTip({
        tip: lowerDimensionTip,
        dimension: lowestDimension.dimension,
      }),
    },
  };
};

/**
 * gets the majorator depending on welcome survey answers
 * @param {Array} feels
 * @param {Object} lastWelcomingResult
 * @returns {Object}
 */
const getMajorator = (feels, lastWelcomingResult) => {
  let majorator = {
    weight: null,
    feel_id: null,
  };

  for (let feel of feels) {
    if (feel.attributes.id === lastWelcomingResult.attributes.feel_id) {
      majorator.weight = feel.attributes.weight;
      majorator.feel_id = feel.attributes.id;
      break;
    }
  }

  return majorator;
};

/**
 * gets the dimensions to majorate depending on welcome survey answers
 * @param {Array} dimensions
 * @param {Object} lastWelcomingResult
 * @returns {Array}
 */
const getDimensionsToMajorate = (dimensions, lastWelcomingResult) => {
  let dimensionsToMajorate = [];

  for (let dimension of dimensions) {
    for (let welcoming_result_value of lastWelcomingResult.attributes
      .welcoming_result_values) {
      if (
        dimension.ids.includes(welcoming_result_value.attributes.dimension_id)
      ) {
        dimensionsToMajorate.push(dimension.dimension_type);
      }
    }
  }

  return dimensionsToMajorate;
};

/**
 * gets the values of the result for each dimension
 * @param {Object} answerValues
 * @returns {Object}
 */
const getDimensionSubTypesValues = (answerValues) => {
  let finalObj = {
    physical: {
      first_value: null,
      second_value: null,
      third_value: answerValues.purpose.physical[0],
    },
    mental: {
      first_value: null,
      second_value: null,
      third_value: answerValues.purpose.mental[0],
    },
    social: {
      first_value: answerValues.social[0],
      second_value: answerValues.social[1],
      third_value: answerValues.purpose.social[0],
    },
    work: {
      first_value: null,
      second_value: null,
      third_value: answerValues.purpose.work[0],
    },
  };

  let dimensionSubTypes = [
    ["physical", "physical health", "environmental mastery"],
    ["mental", "feeling", "thinking"],
    ["work", "balance & recognition", "reward"],
  ];

  for (let i = 0; i < dimensionSubTypes.length; i++) {
    if (
      answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][0] &&
      answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][1]
    ) {
      finalObj[dimensionSubTypes[i][0]].first_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][0];

      finalObj[dimensionSubTypes[i][0]].second_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][1];
    } else if (
      answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][0] &&
      answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][2]][0]
    ) {
      finalObj[dimensionSubTypes[i][0]].first_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][1]][0];

      finalObj[dimensionSubTypes[i][0]].second_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][2]][0];
    } else {
      finalObj[dimensionSubTypes[i][0]].first_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][2]][0];
      finalObj[dimensionSubTypes[i][0]].second_value =
        answerValues[dimensionSubTypes[i][0]][dimensionSubTypes[i][2]][1];
    }
  }

  return finalObj;
};

/**
 * calculates each dimension result
 * @param {Int} first_value
 * @param {Int} second_value
 * @param {Int} third_value
 * @param {Int} majorator
 * @param {Int} max_target
 * @returns {Int}
 */
const calculateDimensionResult = (
  first_value,
  second_value,
  third_value,
  majorator,
  max_target
) => {
  let total;

  if (majorator) {
    total =
      first_value +
      second_value +
      third_value +
      (first_value + second_value + third_value) * (majorator / 100);
  } else {
    total = first_value + second_value + third_value;
  }

  //localização do valor antes de ser percentagem

  return (total * 100) / max_target;
};

/**
 * sorts dimensions by their score
 * @param {Array} resultsByDimension
 * @returns {Array}
 */
const sortDimensions = (resultsByDimension) => {
  return resultsByDimension.sort((a, b) => {
    if (a.value > b.value) {
      return -1;
    }
    if (a.value < b.value) {
      return 1;
    }
    return 0;
  });
};

/**
 * calculates the final result total
 * @param {Int} majorator
 * @param {Array} dimensionsToMajorate
 * @param {Object} answerValues
 * @param {Int} dimensionsToEvaluate
 * @param {Int} max_target
 * @returns {Object}
 */
const calculateResult = (
  majorator,
  dimensionsToMajorate,
  answerValues,
  dimensionsToEvaluate,
  max_target
) => {
  let dimensionSubTypesValues = getDimensionSubTypesValues(answerValues);

  let resultsByDimension = [
    {
      dimension: "physical",
      dimension_id: 1,
      value: calculateDimensionResult(
        dimensionSubTypesValues.physical.first_value,
        dimensionSubTypesValues.physical.second_value,
        dimensionSubTypesValues.physical.third_value,
        dimensionsToMajorate.includes("physical") ? majorator : null,
        max_target
      ),
    },
    {
      dimension: "mental",
      dimension_id: 3,
      value: calculateDimensionResult(
        dimensionSubTypesValues.mental.first_value,
        dimensionSubTypesValues.mental.second_value,
        dimensionSubTypesValues.mental.third_value,
        dimensionsToMajorate.includes("mental") ? majorator : null,
        max_target
      ),
    },
    {
      dimension: "social",
      dimension_id: 5,
      value: calculateDimensionResult(
        dimensionSubTypesValues.social.first_value,
        dimensionSubTypesValues.social.second_value,
        dimensionSubTypesValues.social.third_value,
        dimensionsToMajorate.includes("social") ? majorator : null,
        max_target
      ),
    },
    {
      dimension: "work",
      dimension_id: 6,
      value: calculateDimensionResult(
        dimensionSubTypesValues.work.first_value,
        dimensionSubTypesValues.work.second_value,
        dimensionSubTypesValues.work.third_value,
        dimensionsToMajorate.includes("work") ? majorator : null,
        max_target
      ),
    },
  ];

  let purposeResult = {
    dimension: "purpose",
    dimension_id: 12,
    value: Math.round(
      (answerValues.purpose.physical[1] +
        answerValues.purpose.mental[1] +
        answerValues.purpose.social[1] +
        answerValues.purpose.work[1] +
        answerValues.purpose.general[0] +
        answerValues.purpose.general[1]) /
        6
    ),
  };

  let purposePercentage = (purposeResult.value * 100) / 4;

  return {
    finalResult: Math.round(
      (resultsByDimension[0].value +
        resultsByDimension[1].value +
        resultsByDimension[2].value +
        resultsByDimension[3].value) /
        dimensionsToEvaluate
    ),
    sortedDimensionsValues: sortDimensions(resultsByDimension),
    purposeResult: purposeResult,
    purposePercentage,
  };
};

/**
 * gets the possible results for the final score
 * @param {Int} finalResult
 * @param {Object} survey
 * @returns {Array}
 */
const getThisScorePossibleResults = (finalResult, survey) => {
  return survey.possible_results.filter((possible_result) => {
    return (
      (possible_result.attributes.min_value <= finalResult &&
        possible_result.attributes.max_value >= finalResult) ||
      (possible_result.attributes.min_value === 0 &&
        possible_result.attributes.max_value === 0)
    );
  });
};

/**
 * gets the zone for the final score
 * @param {Array} possibleResults
 * @returns {String}
 */
const getScoreZone = (possibleResults) => {
  return possibleResults.filter((possible_result) => {
    return !possible_result.attributes.possible_result_content.attributes
      .dimension_id;
  })[0].attributes.possible_result_content.attributes.content;
};

/**
 * gets the dimension to be used for the focus copy
 * @param {Array} sortedDimensionsValues
 * @returns {Object}
 */
const getDimensionForCopy = (sortedDimensionsValues) => {
  let lowerDimensions = [];

  lowerDimensions.push(
    sortedDimensionsValues[sortedDimensionsValues.length - 1]
  );

  for (let i = sortedDimensionsValues.length - 2; i >= 0; i--) {
    if (sortedDimensionsValues[i].value === lowerDimensions[0].value) {
      lowerDimensions.push(sortedDimensionsValues[i]);
    } else {
      break;
    }
  }

  return lowerDimensions[Math.floor(Math.random() * lowerDimensions.length)];
};

/**
 * returns the highest value dimension
 * @param {Array} sortedDimensionsValues
 * @param {Object} lowerDimension
 * @returns {Object}
 */
const getHigherDimension = (sortedDimensionsValues, lowerDimension) => {
  let higherDimensions = [];

  higherDimensions.push(sortedDimensionsValues[0]);

  for (let i = 0; i < sortedDimensionsValues.length; i++) {
    if (sortedDimensionsValues[i].value === higherDimensions[0].value) {
      higherDimensions.push(sortedDimensionsValues[i]);
    } else {
      break;
    }
  }

  if (higherDimensions.length === 1) {
    return higherDimensions[0];
  } else {
    let random = Math.floor(Math.random() * higherDimensions.length);
    let higher = higherDimensions[random];

    while (
      higherDimensions[random].dimension_id === lowerDimension.dimension_id
    ) {
      random = Math.floor(Math.random() * higherDimensions.length);
      higher = higherDimensions[random];
    }

    return higher;
  }
};

/**
 * replaces all pronouns on a question by gender in portuguese
 * @param {Object} question
 * @param {String} gender
 * @returns {Object}
 */
const replacePronounsByGender = (question, gender) => {
  if (gender === "M") {
    question.attributes.description =
      question.attributes.description.replaceAll("o/a", "o");
    question.attributes.answers.map((a) => {
      return (a.attributes.content = a.attributes.content.replace("o/a", "o"));
    });
  } else if (gender === "F") {
    question.attributes.description =
      question.attributes.description.replaceAll("o/a", "a");
    question.attributes.answers.map((a) => {
      return (a.attributes.content = a.attributes.content.replace("o/a", "a"));
    });
  }

  return question;
};

/**
 * replaces pronouns directly on a string in portuguese
 * @param {String} text
 * @param {String} gender
 * @returns {String}
 */
const replacePronounDirectlyByGender = (text, gender) => {
  if (!text) {
    return null;
  }

  if (gender === "M") {
    return text.replace("o/a", "o");
  } else if (gender === "F") {
    return text.replace("o/a", "a");
  }

  return text;
};

/**
 * returns the copy for the selected possible results
 * @param {Object} dimensionForCopy
 * @param {Array} dimensions
 * @param {Array} possibleResults
 * @returns {Object}
 */
const getCopyContentFromPossibleResults = (
  dimensionForCopy,
  dimensions,
  possibleResults
) => {
  let dimensionIdsForDimensionForCopy = [];

  for (let dimension of dimensions) {
    if (dimensionForCopy.dimension === dimension.dimension_type) {
      dimensionIdsForDimensionForCopy = dimension.ids;
    }
  }

  let possibleResult = possibleResults.filter((possible_result) => {
    return dimensionIdsForDimensionForCopy.includes(
      possible_result.attributes.possible_result_content.attributes.dimension_id
    );
  });

  return {
    header:
      possibleResult[0].attributes.possible_result_content.attributes.header,
    content:
      possibleResult[0].attributes.possible_result_content.attributes.content,
    id: possibleResult[0].id,
  };
};

//returns the days period for each alert
const daysForPeriodicity = {
  daily: 1,
  weekly: 7,
  biweekly: 14,
  monthly: 30,
};

/**
 * returns the next alert email date
 * @param {String} periodicity
 * @returns {Date}
 */
const getNextEmailDate = (periodicity) => {
  if (periodicity === "don't receive reminders") return null;
  const today = new Date();

  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysForPeriodicity[periodicity],
    3
  );
};

/**
 * changes the alert date after the user answered a survey
 * @param {Object} currentUser
 */
const changeAlertDate = async (currentUser) => {
  const periodicity = currentUser.attributes.Alert[0].periodicity;

  await backend.put(`/alerts/${currentUser.attributes.user_id}`, {
    next_email: getNextEmailDate(periodicity),
  });
};

/**
 * saves the user result when survey ends
 * @param {Object} currentUser
 * @param {Array} dimensionValues
 * @param {Int} total
 * @param {Object} purposeResult
 * @param {Object} highestDimension
 * @param {Object} lowestDimension
 * @param {Int} highestTip
 * @param {Int} lowestTip
 * @returns {Object}
 */
const saveResult = async (
  currentUser,
  dimensionValues,
  total,
  purposeResult,
  highestDimension,
  lowestDimension,
  highestTip,
  lowestTip
) => {
  let resultValues = dimensionValues.map((value) => {
    return {
      dimension_id: value.dimension_id,
      value: Math.round(value.value),
    };
  });

  resultValues.push({
    dimension_id: purposeResult.dimension_id,
    value: purposeResult.value,
  });

  try {
    return await backend.post("/results", {
      survey_id: CORE_SURVEY_ID,
      wellbeing_user_id: currentUser.attributes.user_id,
      total: total,
      result_values: resultValues,
      highestDimension: highestDimension.dimension_id,
      lowestDimension: lowestDimension.dimension_id,
      highestTip: highestTip,
      lowestTip: lowestTip,
    });
  } catch (error) {}
};

export {
  saveTemporaryScore,
  getDimensionForQuestion,
  getAnswersObject,
  onCoreSurveySubmit,
  changeAlertDate,
  getTips,
  replacePronounsByGender,
  replacePronounDirectlyByGender,
  getZoneCopyFromLanguage,
};
