import React from "react";
import "./ErrorPage.css";
import Footer from "../../../components/footer/Footer";
import logo from "../../../assets/svg/logo.svg";
import yoga_girl from "../../../assets/svg/yoga_girl.svg";
import { onLogoClick } from "../../../core/Routing/RoutesRedirects";
import ResultsButton from "../../Results/components/newComponents/ResultsButton";
import { MAIN_TEXT } from "../../../utils/constants/colorsConstants";
import { getStaticErrorPageCopies } from "../../../utils/constants/copies/copies.helper";
import { connect } from "react-redux";

/**
 * Functional Component: ErrorPage
 * this is the error page, is show to people if there errors in a backend request required for other page loading
 *
 * @param {String} firstTitle
 * @param {Function} buttonFunction
 * @param {String} type
 * @param {String} language
 * @returns {Element}
 * */
const ErrorPage = ({ buttonFunction, type, language }) => {
  //contains all copies for the Error page
  const SELF_COPIES = getStaticErrorPageCopies({
    language,
    type,
  });

  return (
    <div id="new-error-page">
      <div className="container">
        <div className="row-12 d-flex justify-content-center py-5">
          <img onClick={onLogoClick} src={logo} alt="logo" className="logo" />
        </div>

        <div
          className="row justify-content-center"
          id="main-container-error-page"
        >
          <div className="card-container">
            <div className="card">
              <div className="card-body">
                <div className="card-title d-flex flex-column align-items-center text-center">
                  <div className="display-6">{SELF_COPIES.TITLE}</div>
                </div>

                <div
                  className="card-subtitle d-flex justify-content-center text-muted text-center mx-auto"
                  style={{ width: "80%", color: MAIN_TEXT, fontSize: "105%" }}
                >
                  {SELF_COPIES.DESCRIPTION}
                </div>

                {buttonFunction ? (
                  <div
                    style={{ marginBottom: "5%" }}
                    className="d-flex justify-content-center"
                  >
                    <ResultsButton onClick={buttonFunction}>
                      {SELF_COPIES.BUTTON_TEXT}
                    </ResultsButton>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ marginTop: buttonFunction ? null : "10%" }}
          className="d-flex justify-content-center justify-content-lg-start"
        >
          <img src={yoga_girl} className="yogaGirl" alt="yoga_girl" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(ErrorPage);
