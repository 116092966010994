import React from "react";
import { connect } from "react-redux";
import { getStaticLoadingCopies } from "../../utils/constants/copies/copies.helper";
import LOADING_TYPES from "./LOADING_TYPES";

/**
 * Functional Component: Loading
 * Renders a loading animation
 *
 * @param {String or Int} height
 * @param {String} message
 * @param {String} position
 * @param {String} language
 * @returns {Element}
 * */
const Loading = ({
  height,
  position,
  type = LOADING_TYPES.WARMING_UP,
  language,
}) => {
  //returns all the copies for the loading component
  const SELF_COPIES = getStaticLoadingCopies({ language, type });

  return (
    <div
      style={{
        height: height || "100%",
        position: position ?? null,
        backgroundColor: "#B49DD6",
      }}
      className="ui active dimmer"
    >
      <div className="ui big text loader">{SELF_COPIES.COPY}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(Loading);
