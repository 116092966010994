import React from "react";

// LikeSVG Themes
const colors = {
  lowest: {
    main: "#F98F73",
    secondary: "#E87557",
  },
  highest: {
    main: "#7DBA61",
    secondary: "#69A24E",
  },
};

/**
 * Functional Component: LikeSVG
 * The icon for an upward/downward like present on the radar section of results' pages
 *
 * @param {String} type: lowest/highest: the theme/the icon that will be rendered
 * @returns {Element}: an svg
 */
const LikeSVG = ({ type }) => {
  return type === "lowest" ? (
    <svg
      width="88"
      height="90"
      viewBox="0 0 88 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.6959 40.2545C87.6959 62.4893 69.6722 80.5124 47.4394 80.5124C25.2087 80.5124 7.18359 62.4893 7.18359 40.2545C7.18359 18.0216 25.2087 0 47.4394 0C52.8934 0 58.0927 1.08352 62.8353 3.05007C62.9198 3.0844 63.0035 3.11873 63.0859 3.15513C75.9193 8.57205 85.3565 20.4558 87.3176 34.7222C87.3176 34.7284 87.3196 34.7338 87.3196 34.74C87.5675 36.5418 87.6959 38.3834 87.6959 40.2545Z"
        fill={colors[type].main}
      />
      <path
        d="M87.3198 34.7393C87.3198 34.7338 87.3178 34.7284 87.3178 34.7222C85.3567 20.4558 75.9195 8.57205 63.0861 3.15444C63.0037 3.11805 62.92 3.0844 62.8355 3.04938C58.0929 1.08352 52.8936 0 47.4396 0C45.129 0 42.8645 0.197753 40.6597 0.571287C43.6548 1.08009 46.5373 1.91711 49.2688 3.04938C49.3526 3.0844 49.437 3.11805 49.5188 3.15444C62.3521 8.57205 71.79 20.4558 73.7504 34.7222C73.7504 34.7284 73.7524 34.7338 73.7524 34.7393C74.001 36.5418 74.1287 38.3834 74.1287 40.2545C74.1287 60.1767 59.6591 76.7152 40.6562 79.9404C42.8624 80.3146 45.1277 80.5124 47.4396 80.5124C69.6731 80.5124 87.6961 62.4887 87.6961 40.2545C87.6961 38.3834 87.5677 36.5418 87.3198 34.7393Z"
        fill={colors[type].secondary}
      />
      <path
        d="M2.32304 43.7818L25.1924 28.4477C26.8211 27.3546 28.7945 26.9213 30.724 27.2331C32.6576 27.5448 34.6365 27.1177 36.2693 26.0225L39.3304 23.9688L56.4697 49.551L52.2647 67.9448C51.9378 69.3772 51.7765 70.8287 51.7765 72.2741C51.7765 76.0143 52.8565 79.7063 54.9282 82.8745C55.4376 83.6545 55.6814 84.5355 55.6814 85.4062C55.6814 86.8941 54.968 88.3532 53.651 89.2363C51.8753 90.4283 49.5222 90.2065 47.9937 88.7055L45.2691 86.0338C42.1538 82.9727 39.7506 79.2463 38.2413 75.125L36.7644 71.0986L21.5518 81.2753C19.1225 82.904 15.8355 82.1913 14.2844 79.672C12.7882 77.2447 13.5565 74.0374 15.9138 72.4574C15.9419 72.4382 15.944 72.4073 15.9344 72.384C15.9213 72.3592 15.8925 72.3414 15.8602 72.3551C15.2594 72.59 14.6304 72.7039 14.007 72.7039C13.0106 72.7039 12.0239 72.4128 11.1801 71.8607C10.5985 71.4797 10.0835 70.9736 9.6763 70.3515C8.89147 69.1506 8.65732 67.7416 8.91413 66.4328C9.31719 64.3688 10.7983 63.3065 11.0887 63.106C11.1382 63.0717 11.1519 63.0024 11.1176 62.9502C11.1176 62.9481 11.1155 62.9467 11.1134 62.9467C11.0812 62.9001 11.0146 62.887 10.9672 62.9179C10.5525 63.0195 10.1309 63.0676 9.70858 63.0676C8.67792 63.0676 7.65345 62.7716 6.78279 62.2093C6.19365 61.8282 5.67249 61.3235 5.26943 60.7062C3.68809 58.2886 4.34177 55.0297 6.73129 53.4278C6.79034 53.3873 6.84939 53.3468 6.90844 53.3083C6.94278 53.2816 6.95239 53.2376 6.93522 53.2067C6.9311 53.1992 6.92355 53.1909 6.916 53.1875C6.88922 53.1662 6.84733 53.1641 6.813 53.1909C5.32848 53.7032 3.71281 53.4704 2.4123 52.6176C1.81355 52.2289 1.28346 51.705 0.863238 51.0623C-0.718788 48.6419 -0.065104 45.3831 2.32304 43.7818Z"
        fill="#FDD7BD"
      />
      <path
        d="M87.3203 34.7385L70.8807 46.0475L61.0398 52.6455C59.9068 53.4063 58.3804 53.0904 57.6292 51.9423L38.4169 22.5705C37.6664 21.4197 37.9775 19.8741 39.1125 19.1119L48.7605 12.6437L63.0866 3.1543C75.92 8.57122 85.3572 20.455 87.3183 34.7213C87.3183 34.7275 87.3203 34.733 87.3203 34.7385Z"
        fill="#9373BF"
      />
      <path
        d="M39.113 19.1141L48.7603 12.6445L70.8812 46.049L61.0409 52.6477C59.908 53.4078 58.3795 53.0926 57.629 51.9432L38.4167 22.5713C37.6662 21.4219 37.978 19.8763 39.113 19.1141Z"
        fill="#7652AA"
      />
      <path
        d="M11.1816 71.8599L18.791 67.7366C19.8711 67.1529 21.2094 67.4571 21.9358 68.4527L15.936 72.3831C15.9222 72.3584 15.8941 72.3405 15.8618 72.3542C15.261 72.5891 14.632 72.7031 14.0085 72.7031C13.0122 72.7024 12.0255 72.4119 11.1816 71.8599Z"
        fill="#FAC5AA"
      />
      <path
        d="M6.78516 62.2096L14.2297 58.179C15.3091 57.5939 16.6474 57.8981 17.3739 58.8931L11.374 62.8241L11.1158 62.947C11.0835 62.901 11.0169 62.8873 10.9689 62.9182C10.5548 63.0205 10.1325 63.0686 9.71094 63.0686C8.68029 63.0686 7.65582 62.7719 6.78516 62.2096Z"
        fill="#FAC5AA"
      />
      <path
        d="M2.41406 52.6176L8.97082 49.0663C10.0488 48.4834 11.3871 48.7869 12.115 49.7825L6.91775 53.1876C6.89097 53.1663 6.84909 53.1649 6.81476 53.1917C5.33024 53.7032 3.71456 53.4705 2.41406 52.6176Z"
        fill="#FAC5AA"
      />
    </svg>
  ) : (
    <svg
      width="88"
      height="90"
      viewBox="0 0 89 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.3142 49.3434C81.3142 71.7974 63.1098 89.9998 40.6564 89.9998C35.1481 89.9998 29.8973 88.9046 25.108 86.9195C12.0177 81.497 2.3758 69.4333 0.382463 54.9313C0.12909 53.1048 0 51.2392 0 49.3434C0 26.8879 18.2024 8.68555 40.6564 8.68555C63.1098 8.68555 81.3142 26.8879 81.3142 49.3434Z"
        fill={colors[type].main}
      />
      <path
        d="M40.6552 8.68555C38.5053 8.68555 36.3952 8.85378 34.3359 9.17513C53.7894 12.2108 68.6752 29.0371 68.6752 49.3434C68.6752 69.6469 53.7907 86.4725 34.3387 89.5095C36.3973 89.8309 38.506 89.9998 40.6545 89.9998C63.1086 89.9998 81.313 71.7974 81.313 49.3434C81.313 26.8879 63.1086 8.68555 40.6552 8.68555Z"
        fill={colors[type].secondary}
      />
      <path
        d="M86.3908 46.3384L63.1745 61.7117C61.5204 62.8076 59.5168 63.2423 57.5585 62.9292C55.5953 62.6167 53.5869 63.0452 51.9286 64.1432L48.8215 66.2024L31.4219 40.554L35.6908 22.112C36.0224 20.6763 36.1865 19.2212 36.1865 17.7717C36.1865 14.0219 35.0899 10.3209 32.9867 7.14379C32.4697 6.36239 32.2225 5.47867 32.2225 4.60594C32.2225 3.11454 32.9462 1.6513 34.2838 0.765519C36.0863 -0.428562 38.4751 -0.206774 40.0269 1.29836L42.7928 3.97697C45.9555 7.0456 48.3951 10.7817 49.927 14.9139L51.4267 18.9514L66.8701 8.7478C69.3365 7.11495 72.673 7.82907 74.2481 10.3552C75.767 12.7887 74.987 16.0043 72.594 17.5884C72.5651 17.6076 72.5631 17.6385 72.5727 17.6619C72.5864 17.6873 72.6153 17.7044 72.6475 17.6907C73.258 17.4559 73.8966 17.3419 74.5296 17.3419C75.5404 17.3419 76.5422 17.633 77.3992 18.1872C77.9897 18.5689 78.5129 19.0764 78.9256 19.6998C79.7228 20.9042 79.9604 22.3167 79.6994 23.6295C79.2902 25.6977 77.7871 26.7634 77.4918 26.9639C77.4417 26.9989 77.428 27.0683 77.463 27.1204C77.463 27.1218 77.4651 27.1239 77.4664 27.1239C77.4994 27.1706 77.5667 27.1836 77.6148 27.1534C78.0357 27.0511 78.4641 27.0023 78.8926 27.0023C79.9384 27.0023 80.9786 27.2997 81.8631 27.8634C82.4611 28.2459 82.9898 28.7512 83.3991 29.3706C85.0045 31.7945 84.3412 35.0615 81.9146 36.6676C81.8548 36.7081 81.7951 36.7486 81.7353 36.7871C81.701 36.8145 81.6907 36.8585 81.7086 36.8894C81.712 36.8969 81.7202 36.9052 81.7278 36.9086C81.7546 36.9299 81.7971 36.932 81.8322 36.9052C83.3393 36.3916 84.9797 36.625 86.2995 37.4799C86.9072 37.8699 87.4462 38.3952 87.8726 39.0393C89.4787 41.4652 88.8147 44.7323 86.3908 46.3384Z"
        fill="#FDD7BD"
      />
      <path
        d="M49.0428 71.0715L39.2485 77.5576L25.1083 86.9214C12.0181 81.4983 2.37615 69.4366 0.382812 54.9332L16.7923 44.0663L26.7817 37.4505C27.9318 36.6883 29.4837 37.0048 30.2458 38.157L49.7494 67.6053C50.5116 68.7575 50.195 70.3066 49.0428 71.0715Z"
        fill="#9273BF"
      />
      <path
        d="M49.0435 71.0715L39.2491 77.5576L16.793 44.0663L26.7823 37.4505C27.9325 36.6883 29.4843 37.0048 30.2465 38.157L49.75 67.6046C50.5122 68.7575 50.1957 70.3066 49.0435 71.0715Z"
        fill="#7652AA"
      />
      <path
        d="M77.3994 18.1883L69.674 22.3226C68.5781 22.9077 67.2192 22.6021 66.4824 21.6044L72.573 17.6637C72.5867 17.6891 72.6156 17.7063 72.6478 17.6926C73.2583 17.4577 73.8968 17.3438 74.5299 17.3438C75.5414 17.3438 76.5425 17.6349 77.3994 18.1883Z"
        fill="#FAC5AA"
      />
      <path
        d="M81.8628 27.865L74.3055 31.9059C73.2096 32.493 71.8507 32.1881 71.1133 31.1904L77.2039 27.249L77.4668 27.1254C77.4998 27.1721 77.5671 27.1852 77.6152 27.155C78.0361 27.0527 78.4645 27.0039 78.893 27.0039C79.9388 27.0039 80.979 27.3012 81.8628 27.865Z"
        fill="#FAC5AA"
      />
      <path
        d="M86.2998 37.4818L79.6434 41.0428C78.5489 41.6271 77.1907 41.3222 76.4512 40.3245L81.7274 36.9105C81.7548 36.9318 81.7974 36.9339 81.8317 36.9064C83.3389 36.3935 84.9793 36.6269 86.2998 37.4818Z"
        fill="#FAC5AA"
      />
    </svg>
  );
};

export default LikeSVG;
