import history from "../../utils/history";
import {
  //dispatchCookiesEvent,
  doesAcceptanceCookieExist,
  hasUserAuthenticated,
} from "../../utils/helpers/cookiesHelpers/cookiesHelper";
import { parser } from "../../utils/helpers/geralHelpers/ObjectHelper";
import { scrollToElement } from "../../utils/helpers/geralHelpers/RandomHelper";

// Contains all possible route redirects inside the app

const LANDING_PAGE_ROUTE = "/";
const LOGIN_PAGE_ROUTE = "/login";
const PROFILE_PAGE_ROUTE = "/profile";
const SURVEY_PAGE_ROUTE = "/survey";
const RESULT_PAGE_ROUTE = "/result";
const SHARED_RESULT_PAGE_ROUTE = "/sharedResult/:share_result_id";
const MEET_VIOLET_PAGE_ROUTE = "/meetViolet";
const ABOUT_ME_PAGE_ROUTE = "/aboutme";
const TRIAL_SURVEY_PAGE_ROUTE = "/trialSurvey";
const PRELIMINARY_RESULTS_PAGE_ROUTE = "/preliminaryResults";
const WELCOME_SURVEY_PAGE_ROUTE = "/welcome";
const TRANSITION_PAGE_ROUTE = "/transition";
const ERROR_PAGE_ROUTE = "/error";
const ANALYTICS_PAGE_ROUTE = "/analytics";
const TERMS_OF_SERVICE_ROUTE = "/terms-of-service";
const PRIVACY_POLICY_ROUTE = "/privacy-policy";
const CONFIRM_PASSWORD_ROUTE = "/confirmPassword";

const KEYCLOAK_PAGE_ENDPOINT = `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/auth/realms/PurpleProjects/protocol/openid-connect/auth?client_id=wellbeing_front&response_type=code&redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_ENDPOINT}${LOGIN_PAGE_ROUTE}&code_challenge=-sUEoAV-txYvhniiuJ4-gwNCtsiD2XiIPvLQYm-sUsE&code_challenge_method=S256`;
const CHANGE_PASSWORD_ENDPOINT = `${process.env.REACT_APP_LOGIN_FRONTEND}/changePassword?aboutme=true&e=:currentUserEmail&id=:currentUserId`;

/**
 * @param {String} route
 */
const redirectToPageInsideDomain = (route) => {
  const redirect = () => history.push(route);
  if (doesAcceptanceCookieExist) redirect();
  //else dispatchCookiesEvent(redirect);
};

/**
 * @param {String} url
 */
const redirectToEndpointOutsideDomain = (url) => {
  const redirect = () => window.location.assign(url);

  if (doesAcceptanceCookieExist) redirect();
  //else dispatchCookiesEvent(redirect);
};

const redirectToCookiePolicy = () => {
  window.open(TERMS_OF_SERVICE_ROUTE + "#cookie-policy", "_blank");
};

const redirectToLandingPage = () =>
  redirectToPageInsideDomain(LANDING_PAGE_ROUTE);
const redirectToProfilePage = () => {
  redirectToPageInsideDomain(PROFILE_PAGE_ROUTE);
};
const redirectToSurveyPage = () =>
  redirectToPageInsideDomain(SURVEY_PAGE_ROUTE);
const redirectToResultPage = () =>
  redirectToPageInsideDomain(RESULT_PAGE_ROUTE);
const redirectToSharedResultPage = (share_result_id) => {
  const route = SHARED_RESULT_PAGE_ROUTE.replace(
    ":share_result_id",
    share_result_id
  );
  redirectToPageInsideDomain(route);
};
const redirectToMeetVioletPage = () =>
  redirectToPageInsideDomain(MEET_VIOLET_PAGE_ROUTE);
const redirectToAboutMePage = () =>
  redirectToPageInsideDomain(ABOUT_ME_PAGE_ROUTE);
const redirectToTrialSurveyPage = () =>
  redirectToPageInsideDomain(TRIAL_SURVEY_PAGE_ROUTE);
const redirectToPreliminaryResultsPage = () =>
  redirectToPageInsideDomain(PRELIMINARY_RESULTS_PAGE_ROUTE);
const redirectToWelcomeSurveyPage = () =>
  redirectToPageInsideDomain(WELCOME_SURVEY_PAGE_ROUTE);
const redirectToTransitionPage = () =>
  redirectToPageInsideDomain(TRANSITION_PAGE_ROUTE);
const redirectToErrorPage = () => redirectToPageInsideDomain(ERROR_PAGE_ROUTE);
const redirectToAnalyticsPage = () =>
  redirectToPageInsideDomain(ANALYTICS_PAGE_ROUTE);
const redirectToProgressOvertimePage = () => {
  redirectToPageInsideDomain(ANALYTICS_PAGE_ROUTE + "#progress-overtime");
  window.location.reload();
};
const redirectToTermsOfServicePage = () =>
  redirectToPageInsideDomain(TERMS_OF_SERVICE_ROUTE);
const redirectToPrivacyPolicyPage = () =>
  redirectToPageInsideDomain(PRIVACY_POLICY_ROUTE);
const redirectToConfirmPasswordPage = () =>
  redirectToPageInsideDomain(CONFIRM_PASSWORD_ROUTE);

/**
 * @param {String} password
 */
const redirectToConfirmPasswordPageWithProps = (password) => {
  if (doesAcceptanceCookieExist) {
    history.push({
      pathname: ABOUT_ME_PAGE_ROUTE,
      state: {
        confirmed: true,
        oldPassword: password,
      },
    });
    setTimeout(() => scrollToElement(".about-me__email-input"), 10);
  } else {
    // dispatchCookiesEvent();
  }
};

const onLogoClick = () => redirectToPageInsideDomain("/?checkSurvey=true");

const redirectToKeycloakEndpoint = (language) =>
  redirectToEndpointOutsideDomain(KEYCLOAK_PAGE_ENDPOINT + `&lang=${language}`);

/**
 * @param {Object} currentUser
 */
const redirectToChangePasswordEndpoint = (currentUser) => {
  const route = CHANGE_PASSWORD_ENDPOINT.replace(
    ":currentUserEmail",
    parser(currentUser.attributes.email)
  ).replace(":currentUserId", parser(currentUser.attributes.user_id));
  redirectToEndpointOutsideDomain(route);
};

/**
 * @param {Object} preliminaryResult
 * @param {Object} queryParams
 */
const redirectToSignupEndpoint = ({ preliminaryResult, queryParams }) => {
  let final = process.env.REACT_APP_LOGIN_FRONTEND;

  if (
    (preliminaryResult && preliminaryResult.surveyCode) ||
    (queryParams && queryParams.r) ||
    (queryParams && queryParams.toFill)
  ) {
    final += "?";
  }

  if (preliminaryResult && preliminaryResult.surveyCode) {
    final += `TR=${parser(preliminaryResult.surveyCode)}`;
  }
  if (queryParams && queryParams.r) {
    final += `&r=${queryParams.r}`;
  }
  if (queryParams && queryParams.toFill) {
    final += `&toFill=${queryParams.toFill}`;
  }

  redirectToEndpointOutsideDomain(final);
};

const isUserInCookiePolicy =
  window.location.hash === "#cookie-policy" &&
  window.location.pathname === TERMS_OF_SERVICE_ROUTE;

/**
 * @param {Function} checkIfThereAreTrialValues
 * @param {Object} answerValues
 */
const redirectStartSurvey = ({
  checkIfThereAreTrialValues,
  answerValues,
  language,
}) => {
  if (hasUserAuthenticated) {
    redirectToKeycloakEndpoint(language);
  } else {
    if (checkIfThereAreTrialValues(answerValues)) {
      redirectToTrialSurveyPage();
    } else {
      redirectToWelcomeSurveyPage();
    }
  }
};

export {
  LANDING_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
  SURVEY_PAGE_ROUTE,
  RESULT_PAGE_ROUTE,
  SHARED_RESULT_PAGE_ROUTE,
  MEET_VIOLET_PAGE_ROUTE,
  ABOUT_ME_PAGE_ROUTE,
  TRIAL_SURVEY_PAGE_ROUTE,
  PRELIMINARY_RESULTS_PAGE_ROUTE,
  WELCOME_SURVEY_PAGE_ROUTE,
  TRANSITION_PAGE_ROUTE,
  ERROR_PAGE_ROUTE,
  KEYCLOAK_PAGE_ENDPOINT,
  ANALYTICS_PAGE_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  CONFIRM_PASSWORD_ROUTE,
  redirectToLandingPage,
  redirectToProfilePage,
  redirectToSurveyPage,
  redirectToResultPage,
  redirectToSharedResultPage,
  redirectToMeetVioletPage,
  redirectToAboutMePage,
  redirectToTrialSurveyPage,
  redirectToPreliminaryResultsPage,
  redirectToWelcomeSurveyPage,
  redirectToTransitionPage,
  redirectToErrorPage,
  redirectToAnalyticsPage,
  redirectToProgressOvertimePage,
  redirectToTermsOfServicePage,
  redirectToCookiePolicy,
  redirectToPrivacyPolicyPage,
  redirectToConfirmPasswordPage,
  redirectToKeycloakEndpoint,
  redirectToChangePasswordEndpoint,
  redirectToSignupEndpoint,
  onLogoClick,
  redirectToConfirmPasswordPageWithProps,
  isUserInCookiePolicy,
  redirectStartSurvey,
};
