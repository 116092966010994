import React, { useEffect } from "react";
import TextSection from "../components/TextSection";
import UpperParagraph from "../components/UpperParagraph";
import InfoTemplate from "../components/InfoTemplate";
import { scrollToElement } from "../../../utils/helpers/geralHelpers/RandomHelper";
import { isUserInCookiePolicy } from "../../../core/Routing/RoutesRedirects";
import { connect } from "react-redux";
import { getStaticTermsOfServiceCopies } from "../../../utils/constants/copies/copies.helper";

/**
 * @param {String} children
 * @returns {Element}
 */
const Bolder = ({ children }) => {
  return <span className="ff-popp-med">{children}</span>;
};

/**
 * @param {String} children
 * @returns {Element}
 */
const Paragraph = ({ children }) => {
  return <div style={{ marginBottom: "1rem" }}>{children}</div>;
};

/**
 * @param {String} children
 * @param {Object} customStyles
 * @returns {Element}
 */
const Title = ({ children, customStyles }) => {
  return (
    <h4
      style={{
        fontFamily: "PoppinsSemiBold",
        marginBottom: "0.6rem",
        ...customStyles,
      }}
    >
      {children}
    </h4>
  );
};

/**
 * Functional Component: TermsOfService
 * Renders the terms of service page
 * @param {String} language
 * @returns {Component}
 */
const TermsOfService = ({ language }) => {
  //contains all the copies for the terms of service component
  const SELF_COPIES = getStaticTermsOfServiceCopies({ language });

  useEffect(() => {
    if (isUserInCookiePolicy)
      scrollToElement(".terms-of-service__cookie-policy");
  }, []);

  return (
    <InfoTemplate title={SELF_COPIES.mainTitle} id="new-terms-of-service">
      <UpperParagraph>
        {SELF_COPIES.first_sentence}
        <Bolder>PUR'PLE</Bolder>
        {SELF_COPIES.second_sentence}
      </UpperParagraph>

      <UpperParagraph>
        {SELF_COPIES.third_sentence}
        <Bolder>{SELF_COPIES.site}</Bolder>
        {SELF_COPIES.fourth_sentence}
        <Bolder>{SELF_COPIES.terms}</Bolder>
        {SELF_COPIES.fifth_sentence}
        <Bolder>{SELF_COPIES.user}</Bolder>
        {SELF_COPIES.sixth_sentence}
        <Bolder>{SELF_COPIES.policy}</Bolder>
        {SELF_COPIES.seventh_sentence}
        <Bolder>{SELF_COPIES.client}</Bolder>
        {SELF_COPIES.eight_sentence}
      </UpperParagraph>

      <UpperParagraph>{SELF_COPIES.ninth_sentence}</UpperParagraph>

      <Title>{SELF_COPIES.first_title}</Title>

      <Title customStyles={{ paddingLeft: "2rem" }}>
        {SELF_COPIES.second_title}
      </Title>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.third_title}
      >
        {SELF_COPIES.tenth_sentence}
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.fourth_title}
      >
        <Paragraph>{SELF_COPIES.eleventh_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twelveth_sentence}</Paragraph>
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.fifth_title}
      >
        <Paragraph>{SELF_COPIES.thirteen_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.fourteen_sentence}</Paragraph>
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.sixth_title}
      >
        {SELF_COPIES.fifteen_sentence}
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.seventh_title}
      >
        <Paragraph>{SELF_COPIES.sixteen_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.seventeen_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.eighteen_sentence}</Paragraph>
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.eight_title}
        customClassName="terms-of-service__cookie-policy"
      >
        <Paragraph>{SELF_COPIES.nineteen_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twenty_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentyone_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentytwo_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentythree_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentyfour_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentyfive_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentysix_sentence}</Paragraph>

        <Paragraph>{SELF_COPIES.twentyseven_sentence}</Paragraph>
      </TextSection>

      <TextSection
        customStyles={{ title: { paddingLeft: "3rem" } }}
        title={SELF_COPIES.nineth_title}
      >
        {SELF_COPIES.twentyeight_sentence}
      </TextSection>
    </InfoTemplate>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(TermsOfService);
