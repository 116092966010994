import React from "react";
import { connect } from "react-redux";
import "./TrialSurvey.css";
import {
  redirectToPreliminaryResultsPage,
  redirectToWelcomeSurveyPage,
} from "../../../../core/Routing/RoutesRedirects";
import {
  cleanAnswerValue,
  fetchSurvey,
  saveAnswerValue,
  savePreliminaryResultData,
  setQuestionNumber,
  fetchTrial,
  saveTrialReorderedQuestions,
  saveTrial,
} from "../../../../store/actions";
import {
  createAnalytic,
  fetchDimensions,
} from "../../../../utils/helpers/geralHelpers/BackendHelper";
import {
  checkIfThereAreTrialValues,
  onTrialSurveySubmit,
  reOrderQuestions,
  getAnswersObject,
} from "../../services/trialSurvey.service";
import {
  TRIAL_SURVEY_ID,
  TRIAL_SURVEY_QUESTIONS_NUMBER,
} from "../../../../utils/constants/constants";
import NewQuestion from "../geral/NewQuestion";
import { saveAnonymousScore } from "../../../Results/services/preliminaryResults.service";
import { doesThisSurveyExist } from "../geral/ExtraRenders";
import Loading from "../../../../components/loading/Loading";
import GlossaryButton from "../geral/GlossaryButton";
import HeaderFirstVersion from "../../../../components/header/HeaderFirstVersion";
import { getDimensionForQuestion } from "../../services/questionContainer.service";
import Footer from "../../../../components/footer/Footer";
import PurpleProgressBar from "../../../../components/progressBar/PurpleProgressBar";
import { MAIN_PURPLE } from "../../../../utils/constants/colorsConstants";
import DancingEllipses from "../../../../components/dancingEllipses/DancingEllipses";

import LOADING_TYPES from "../../../../components/loading/LOADING_TYPES";
import {
  QUESTIONS,
  TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT,
} from "../../../../utils/constants/copies/variableCopies.helper";
import { translateDefinitions } from "../../../../utils/constants/copies/glossaryCopies.helper";
import { getStaticSurveyButtonCopy } from "../../../../utils/constants/copies/copies.helper";
import { EVENTS } from "../../../../utils/helpers/geralHelpers/EventsHelper";

/**
 * Class Component: TrialSurvey
 * This component represents the trial survey
 * @returns {Element}
 */
class TrialSurvey extends React.Component {
  state = {
    feel: null,
    dimensions: null,
    isTakingSurvey: true,
    allAnswers: false,
    currentLanguage: null,
    defaultSurvey: null,
  };

  //gets all required data and organizes questions before survey starting
  componentDidMount = async () => {
    /**
     * @param {Object} answerValues
     * @param {Object} survey
     * @param {Function} fetchTrial
     * @param {Function} setQuestionNumber
     * @param {Array} reorderedTrialQuestions
     * @param {Function} saveTrialReorderedQuestions
     * @param {String} language
     * @param {Function} saveTrial
     */
    const {
      answerValues,
      survey,
      fetchTrial,
      setQuestionNumber,
      reorderedTrialQuestions,
      saveTrialReorderedQuestions,
      language,
      saveTrial,
    } = this.props;

    if (!answerValues.feel.feel_type) {
      redirectToWelcomeSurveyPage();
      return;
    }

    if (!survey) await fetchTrial();
    if (!checkIfThereAreTrialValues(answerValues)) setQuestionNumber(0);

    let stri = JSON.stringify(survey);
    let copy2 = JSON.parse(stri);
    let dimensions = await fetchDimensions();

    if (!reorderedTrialQuestions) {
      let reOrderedQuestions = reOrderQuestions(
        dimensions,
        answerValues,
        survey
      );

      //translates questions if the language is not english
      if (language === "pt-PT") {
        let str = JSON.stringify(reOrderedQuestions);
        let copy = JSON.parse(str);
        let surveyStr = JSON.stringify(survey);
        let surveyCopy = JSON.parse(surveyStr);

        copy.forEach((question) => {
          QUESTIONS.forEach((a) => {
            if (question.id === a.id) {
              question.attributes.description = a.description;
              question.attributes.answers[0].attributes.label = a.labels.first;
              question.attributes.answers[3].attributes.label = a.labels.last;
            }
          });
        });

        reOrderedQuestions = copy;

        surveyCopy.possible_results.forEach((possible_result, index) => {
          possible_result.attributes.possible_result_content.attributes.header =
            TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT[index].header;
          possible_result.attributes.possible_result_content.attributes.content =
            TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT[index].content;
        });

        saveTrial(surveyCopy);
      }

      saveTrialReorderedQuestions(reOrderedQuestions);
    }

    this.setState({
      dimensions,
      currentLanguage: language,
      defaultSurvey: copy2,
    });
  };

  //if the survey ends redirects to preliminary results page
  //also translates the survey parts on language changing
  componentDidUpdate = async () => {
    const {
      language,
      survey,
      reorderedTrialQuestions,
      saveTrialReorderedQuestions,
      saveTrial,
    } = this.props;

    if (
      this.state.currentLanguage !== language &&
      reorderedTrialQuestions &&
      survey &&
      this.state.defaultSurvey
    ) {
      if (language === "en-GB") {
        let copy = [...reorderedTrialQuestions];
        let surveyCopy = { ...survey };

        copy.forEach((question) => {
          this.state.defaultSurvey.questions.forEach((a) => {
            if (question.id === a.id) {
              question.attributes.description = a.attributes.description;
              question.attributes.answers[0].attributes.label =
                a.attributes.answers[0].attributes.label;
              question.attributes.answers[3].attributes.label =
                a.attributes.answers[3].attributes.label;
            }
          });
        });

        surveyCopy.possible_results.forEach((possible_result, index) => {
          possible_result.attributes.possible_result_content.attributes.header =
            this.state.defaultSurvey.possible_results[
              index
            ].attributes.possible_result_content.attributes.header;
          possible_result.attributes.possible_result_content.attributes.content =
            this.state.defaultSurvey.possible_results[
              index
            ].attributes.possible_result_content.attributes.content;
        });

        saveTrialReorderedQuestions(copy);
        saveTrial(surveyCopy);

        this.setState({
          currentLanguage: language,
        });
      } else {
        let copy = [...reorderedTrialQuestions];
        let surveyCopy = { ...survey };

        copy.forEach((question) => {
          QUESTIONS.forEach((a) => {
            if (question.id === a.id) {
              question.attributes.description = a.description;
              question.attributes.answers[0].attributes.label = a.labels.first;
              question.attributes.answers[3].attributes.label = a.labels.last;
            }
          });
        });

        surveyCopy.possible_results.forEach((possible_result, index) => {
          possible_result.attributes.possible_result_content.attributes.header =
            TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT[index].header;
          possible_result.attributes.possible_result_content.attributes.content =
            TRIAL_SURVEY_POSSIBLE_RESULTS_CONTENT[index].content;
        });

        saveTrialReorderedQuestions(copy);
        saveTrial(surveyCopy);

        this.setState({
          currentLanguage: language,
        });
      }
    }

    if (!this.state.isTakingSurvey)
      setTimeout(redirectToPreliminaryResultsPage, 4000);
  };

  /**
   * handles when a answer is selected
   * @param {String} content
   * @param {Function} changeStyle
   * @param {Int} id
   */
  isOptionClicked = (content, changeStyle, id) => {
    const {
      answerValues,
      questionNumber,
      saveAnswerValue,
      setQuestionNumber,
      reorderedTrialQuestions,
    } = this.props;

    changeStyle(id);

    let answersObject = {};

    answersObject = getAnswersObject(
      content,
      answerValues,
      questionNumber,
      this.state.dimensions,
      reorderedTrialQuestions
    );

    saveAnswerValue(answersObject);

    if (questionNumber !== TRIAL_SURVEY_QUESTIONS_NUMBER - 1) {
      setTimeout(() => setQuestionNumber(questionNumber + 1), 300);
    } else {
      this.setState({ allAnswers: true });
    }
  };

  /**
   * returns the current question beeing displayed
   * @returns {Object}
   */
  getCurrentQuestion = () => {
    const { questionNumber, reorderedTrialQuestions } = this.props;
    return reorderedTrialQuestions[
      Math.min(questionNumber, TRIAL_SURVEY_QUESTIONS_NUMBER - 1)
    ];
  };

  /**
   * renders the current question
   * @returns {Component}
   */
  renderCurrentQuestion = () => {
    const { survey } = this.props;

    return (
      <NewQuestion
        isOptionClicked={this.isOptionClicked}
        question={this.getCurrentQuestion()}
        surveyType={survey.survey.attributes.survey_type}
        feel={this.state.feel}
        isTrial={true}
      />
    );
  };

  //checks if the survey has ended and saves the anonymous result
  checkIfSurveyEnded = async () => {
    /**
     * @param {Int} questionNumber
     * @param {Object} answerValues
     * @param {Object} preliminaryResult
     * @param {Object} survey
     * @param {Function} savePreliminaryResultData
     * @param {Function} cleanAnswerValue
     * @param {Function} setQuestionNumber
     */
    const {
      questionNumber,
      answerValues,
      preliminaryResult,
      survey,
      savePreliminaryResultData,
      cleanAnswerValue,
      setQuestionNumber,
    } = this.props;

    if (
      questionNumber === TRIAL_SURVEY_QUESTIONS_NUMBER - 1 &&
      this.state.isTakingSurvey
    ) {
      const {
        isTakingSurvey,
        zone,
        surveyCode,
        total,
        copyHeader,
        numberOfDimensionsThatContribute,
        dimensionValues,
        highestDimension,
        lowestDimension,
        highestTip,
        lowestTip,
        copyId,
      } = await onTrialSurveySubmit(
        preliminaryResult.surveyCode,
        answerValues,
        survey
      );

      this.setState({ isTakingSurvey });

      if (isTakingSurvey) {
        createAnalytic(EVENTS.SURVEY_COMPLETED_TRIAL_FAILED);
      } else {
        createAnalytic(EVENTS.SURVEY_COMPLETED_TRIAL_SUCCESSFUL);
      }

      await savePreliminaryResultData({
        zone,
        surveyCode,
        total,
        copyHeader,
        numberOfDimensionsThatContribute,
        mood: answerValues.feel.feel_type,
        date: new Date(),
        copyId,
      });

      if (answerValues.feel.feel_type) {
        await cleanAnswerValue();
        await saveAnonymousScore(
          surveyCode,
          total,
          dimensionValues,
          highestDimension,
          lowestDimension,
          highestTip,
          lowestTip
        );
        await setQuestionNumber(0);
      }
    }
  };

  /**
   * checks if a user can use the right arrow to move forward on the survey depending if answers have already been provided or not
   * @returns {Boolean}
   */
  checkIfCanGoForward = () => {
    const { answerValues, questionNumber, reorderedTrialQuestions } =
      this.props;

    if (this.state.allAnswers && questionNumber > 8) {
      return false;
    }

    let dimension = getDimensionForQuestion(
      reorderedTrialQuestions[
        questionNumber === 10 ? questionNumber - 1 : questionNumber
      ],
      this.state.dimensions
    );

    if (
      dimension.sub_type &&
      answerValues[dimension.dimension_type][dimension.sub_type][0] &&
      dimension.dimension_type !== "purpose"
    ) {
      return true;
    } else if (!dimension.sub_type) {
      let socialQuestions = reorderedTrialQuestions.filter((question) => {
        return question.attributes.dimension_id === 5;
      });

      if (
        this.getCurrentQuestion().attributes.description ===
          socialQuestions[0].attributes.description &&
        answerValues.social[0]
      ) {
        return true;
      } else if (
        this.getCurrentQuestion().attributes.description ===
          socialQuestions[1].attributes.description &&
        answerValues.social[1]
      ) {
        return true;
      }
    } else if (dimension.dimension_type === "purpose") {
      let purposeQuestions = reorderedTrialQuestions.filter((question) => {
        return question.attributes.dimension_id === 12;
      });

      if (
        this.getCurrentQuestion().attributes.description ===
          purposeQuestions[0].attributes.description &&
        answerValues.purpose.general[0]
      ) {
        return true;
      } else if (
        this.getCurrentQuestion().attributes.description ===
          purposeQuestions[1].attributes.description &&
        answerValues.purpose.general[1]
      ) {
        return true;
      }
    }

    return false;
  };

  /**
   * checks if all questions have been answered
   * @returns {Boolean}
   */
  checkIfAllAnswersFilled = () => {
    const { answerValues } = this.props;

    return (
      answerValues.physical["physical health"][0] &&
      answerValues.physical["environmental mastery"][0] &&
      answerValues.mental.feeling[0] &&
      answerValues.mental.thinking[0] &&
      answerValues.social[0] &&
      answerValues.social[1] &&
      answerValues.work["balance & recognition"][0] &&
      answerValues.work.reward[0] &&
      answerValues.purpose.general[0] &&
      answerValues.purpose.general[1]
    );
  };

  render() {
    /**
     * @param {Int} questionNumber
     * @param {Object} survey
     * @param {Function} setQuestionNumber
     * @param {Array} reorderedTrialQuestions
     */
    const {
      questionNumber,
      survey,
      setQuestionNumber,
      reorderedTrialQuestions,
    } = this.props;

    if (
      !this.state.isTakingSurvey ||
      !doesThisSurveyExist(survey, TRIAL_SURVEY_ID) ||
      !reorderedTrialQuestions ||
      !this.state.dimensions
    )
      return <Loading type={LOADING_TYPES.PREPARING_RESULTS} />;

    const thisDefinitions = translateDefinitions(
      this.getCurrentQuestion().attributes.definitions,
      this.props.language,
      this.props.currentUser?.attributes?.gender
    );

    return (
      <div
        id="new-trial-survey-page"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <HeaderFirstVersion survey={true} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          <DancingEllipses dontShowSmall={true} noAnimation={true} />
        </div>

        <div className="container top-container">
          <div className="row">
            <div id="new-trial-survey-container">
              {this.renderCurrentQuestion()}
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              style={{
                visibility:
                  questionNumber + 1 === TRIAL_SURVEY_QUESTIONS_NUMBER
                    ? "visible"
                    : "hidden",
                backgroundColor: this.checkIfAllAnswersFilled()
                  ? MAIN_PURPLE
                  : "#c2c0c7",
              }}
              className="Submit-button"
              onClick={
                this.state.allAnswers ? () => this.checkIfSurveyEnded() : null
              }
            >
              {
                getStaticSurveyButtonCopy({ language: this.props.language })
                  .text
              }
            </div>
          </div>
          <PurpleProgressBar
            forwardClickFunction={
              this.checkIfCanGoForward() && questionNumber <= 8
                ? () => setQuestionNumber(questionNumber + 1)
                : null
            }
            backClickFunction={() => {
              if (questionNumber > 0) {
                setQuestionNumber(questionNumber - 1);
              }
            }}
            totalQuestions={12}
            onTrial={true}
            questionNumber={questionNumber}
            canMoveForward={
              this.checkIfCanGoForward() && questionNumber <= 8 ? true : false
            }
          />
        </div>
        <div>
          <div id="glossary">
            <GlossaryButton
              definitions={thisDefinitions}
              questionNumber={questionNumber}
              place={"Trial"}
              language={this.props.language}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    survey: state.mainSurvey,
    questionNumber: state.questionNumber,
    answerValues: state.answerValues,
    preliminaryResult: state.preliminaryResult,
    reorderedTrialQuestions: state.reorderedTrialQuestions,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchSurvey,
  saveAnswerValue,
  setQuestionNumber,
  cleanAnswerValue,
  savePreliminaryResultData,
  fetchTrial,
  saveTrialReorderedQuestions,
  saveTrial,
})(TrialSurvey);
