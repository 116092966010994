/**
 * generates a random string token
 * @param {Int} length
 * @returns {String}
 */
const generate_token = (length = 10) => {
  //edit the token allowed characters
  let a =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
  let b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};

export { generate_token };
